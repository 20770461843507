import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useCallback, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "react-modern-calendar-datepicker";
import { CalenderIcon } from "../../PricingRecomendation";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../../sdk";

export const AddRoomModal = ({ open, handleClose, GetBudget }) => {
  const parsedDate = new Date();
  const defaultValue = {
    year: parsedDate.getFullYear(),
    month: parsedDate.getMonth() + 1,
    day: parsedDate.getDate(),
  };

  const [adjustFromDate, setAdjustFromDate] = useState(defaultValue);
  const [adjustToDate, setAdjustToDate] = useState(defaultValue);
  const [modalData, setModalData] = useState({
    availableRooms: null,
  });

  const { hotelId } = useParams();
  const { authFetch } = useAuth();

  const minimumDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };

  const formatInputValueDate = (date) => {
    if (date) {
      const value =
        `${date.day < 10 ? "0" + date.day : date.day}` +
        "/" +
        `${date.month < 10 ? "0" + date.month : date.month}` +
        "/" +
        `${date.year}`;
      return value;
    }
  };

  const renderCustomFromInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValueDate(adjustFromDate)}
      style={{
        textAlign: "left",
        fontSize: "14px",
        borderRadius: "10px",
        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );
  const renderCustomToInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValueDate(adjustToDate)}
      style={{
        textAlign: "left",
        fontSize: "14px",
        borderRadius: "10px",
        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );

  const handleModalDataChange = useCallback((event, field) => {
    const { value } = event.target;

    if (field === "reason") {
      setModalData((prev) => ({
        ...prev,
        [field]: value,
      }));
    } else {
      const numericValue = parseInt(value, 10);

      if (isNaN(numericValue)) {
        setModalData((prev) => ({
          ...prev,
          [field]: "",
        }));
      } else {
        setModalData((prev) => ({
          ...prev,
          [field]: numericValue,
        }));
      }
    }
  }, []);

  const handleSaveModalData = useCallback(async () => {
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/monthly-room-adjustmentv2`,
    });

    const fromBody = {
      startdate: `${
        adjustFromDate.year
      }-${adjustFromDate.month
        ?.toString()
        ?.padStart(2, "0")}-${adjustFromDate.day
        ?.toString()
        ?.padStart(2, "0")}T00:00:00Z`,
      endDate: `${adjustToDate.year}-${adjustToDate.month
        ?.toString()
        ?.padStart(2, "0")}-${adjustToDate.day
        ?.toString()
        ?.padStart(2, "0")}T00:00:00Z`,
      roomsAvailable: modalData?.availableRooms,
      hotelId: parseInt(hotelId),
    };

    const { data, response } = await post(fromBody);
    if (data && response.status === 200) {
      GetBudget();
      handleClose();
    } else {
      handleClose();
    }
  }, [
    GetBudget,
    adjustFromDate.day,
    adjustFromDate.month,
    adjustFromDate.year,
    adjustToDate.day,
    adjustToDate.month,
    adjustToDate.year,
    authFetch,
    handleClose,
    hotelId,
    modalData.availableRooms,
  ]);

  return (
    <StyledChannelDialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className="content">Add Available Rooms</DialogTitle>
      <CloseIcon className="closeButton" onClick={handleClose} />
      <DialogContent className="content">
        <Stack direction="row" gap="40px" padding="32px">
          <Stack alignItems="center" justifyContent="space-between">
            <Typography className="heading">Start Date</Typography>
            <label>
              <StyledCalenderWrapper>
                <DatePicker
                  value={adjustFromDate}
                  onChange={setAdjustFromDate}
                  renderInput={renderCustomFromInput}
                  calendarClassName="myCustomCalendar"
                />
                <CalenderIcon />
              </StyledCalenderWrapper>
            </label>
          </Stack>
          <Stack alignItems="center" justifyContent="space-between">
            <Typography className="heading">End Date</Typography>
            <label>
              <StyledCalenderWrapper>
                <DatePicker
                  value={adjustToDate}
                  onChange={setAdjustToDate}
                  renderInput={renderCustomToInput}
                  calendarClassName="myCustomCalendar"
                />
                <CalenderIcon />
              </StyledCalenderWrapper>
            </label>
          </Stack>

          <Stack justifyContent="space-between">
            <Typography className="heading">Available rooms</Typography>
            <TextField
              variant="standard"
              type="number"
              value={modalData.availableRooms}
              placeholder="0"
              onChange={(e) => handleModalDataChange(e, "availableRooms")}
            />
          </Stack>
        </Stack>
        <Button
          disabled={!modalData.availableRooms}
          onClick={handleSaveModalData}
          variant="contained"
        >
          Save
        </Button>
      </DialogContent>
    </StyledChannelDialog>
  );
};

const StyledChannelDialog = styled(Dialog)(() => ({
  ".MuiPaper-root": {
    overflow: "visible",
  },
  ".closeButton": {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
  },
  ".content": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "visible",
  },
  ".heading": {
    fontSize: "20px",
    fontWeight: 600,
    color: "#306FBC",
  },
}));

const StyledCalenderWrapper = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
  "&:hover>*": {
    color: "#ffffff",
    ">input": {
      backgroundColor: "#306fbc",
      color: "#ffffff !important",
    },
  },
}));
