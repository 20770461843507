import {
  Dialog,
  DialogActions,
  DialogTitle,
  Snackbar,
  SnackbarContent,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DatePicker, PrimaryButton, useAuth } from "../sdk";
import { getISODate } from "../sdk/common-utils";
import {
  ArrDone,
  ArrLabel,
  ArrSection,
  ArrUploadSection,
  Cross,
  DateLabel,
  DialogLabel,
  Forecast,
  ModelHead,
} from "./Styles";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

function getModalStyle() {
  const top = 9;
  const left = 46;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}, -${left})`,
  };
}
const ClassWrapper = styled(Box)((theme) => ({
  ".paper": {
    position: "absolute",
    width: 462,
    height: 240,
    backgroundColor: "#fff",
    boxShadow: 5,
  },
}));
export function ModelBody({ setOpenSelectBar, setErrorMsg }) {
  const prev_date = new Date();
  prev_date.setDate(prev_date.getDate() - 1);
  const [date, setdate] = useState(prev_date);
  const [modalStyle] = useState(getModalStyle);
  const { hotelId } = useParams();
  const { authFetch } = useAuth();
  const [ARR, setARR] = useState({
    actualRoomsBooked: "",
    revenue: "",
  });

  const onChange = (key, value) => {
    if (key === "date") {
      setdate(value);
    }
    setARR({
      ...ARR,

      [key]: value,
    });
  };

  useEffect(() => {
    getARR();
  }, [date]);

  async function getARR() {
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/average-room-rate/${
        getISODate(date).split("T")[0]
      }`,
    });
    const { data, error } = await get();
    if (data && Object.keys(data)?.length) {
      setAskconfirmation(true);
    }
  }

  async function submitARR() {
    setOpenSelectBar(false);
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/average-room-rate`,
    });
    const { data, response, error } = await post({
      ...ARR,
      date: getISODate(date),
    });
    if (response) {
      if (response?.ok) {
        console.log("ADR Sent");
      } else {
        setErrorMsg(data?.messageToUser || "Something Went Wrong");
      }
    } else {
      console.log(error);
    }
  }
  const [askconfirmation, setAskconfirmation] = useState(false);

  return (
    <>
      <ClassWrapper>
        <ArrSection style={modalStyle} className="paper">
          <Dialog
            open={askconfirmation}
            onClose={() => setAskconfirmation(false)}
          >
            <DialogTitle>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <DialogLabel style={{ textAlign: "center" }}>
                  Last Night Actuals Already Exist for Selected Date.
                  <br />
                  Do you wish to override?
                </DialogLabel>
                <Cross
                  style={{ marginTop: "6px", marginLeft: "20px" }}
                  onClick={() => setAskconfirmation(false)}
                >
                  &times;
                </Cross>
              </div>{" "}
            </DialogTitle>
            <DialogActions
              style={{ justifyContent: "space-evenly", paddingBottom: 16 }}
            >
              <PrimaryButton
                autoFocus
                next
                onClick={() => {
                  setAskconfirmation(false);
                }}
              >
                Yes
              </PrimaryButton>
              <PrimaryButton
                autoFocus
                next
                onClick={() => {
                  setAskconfirmation(false);
                  setOpenSelectBar(false);
                }}
              >
                No
              </PrimaryButton>
            </DialogActions>
          </Dialog>
          <ModelHead>
            <Forecast>Last Night Actuals</Forecast>
            <Cross onClick={() => setOpenSelectBar(false)}>&#x274C;</Cross>
          </ModelHead>
          <ArrUploadSection>
            <DateLabel htmlFor="picker">
              Select Date
              <DatePicker
                id="picker"
                date={date}
                onChange={(date) => {
                  onChange("date", date);
                }}
                disableFromCurrent={true}
              />
            </DateLabel>
            <ArrLabel htmlFor="rooms">
              Rooms Sold
              <TextField
                variant="standard"
                value={ARR.actualRoomsBooked}
                id="rooms"
                type="number"
                onChange={(e) =>
                  onChange(
                    "actualRoomsBooked",
                    e.target.value && parseInt(e.target.value)
                  )
                }
              />
            </ArrLabel>
            <ArrLabel htmlFor="revenue">
              Revenue
              <TextField
                variant="standard"
                value={ARR.revenue}
                id="revenue"
                type="number"
                onChange={(e) =>
                  onChange(
                    "revenue",
                    e.target.value && parseFloat(e.target.value)
                  )
                }
              />
            </ArrLabel>
          </ArrUploadSection>

          <ArrDone next onClick={submitARR} type="button">
            Send
          </ArrDone>
        </ArrSection>
      </ClassWrapper>
    </>
  );
}
