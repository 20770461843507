import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../sdk";
import { getISODate } from "../../sdk/common-utils";
export default function usePace({
  fromDate1,
  toDate1,
  fromDate2,
  toDate2,
  fromDatePeriod1,
  toDatePeriod1,
  fromDatePeriod2,
  toDatePeriod2,
  selectedPickup,
  monthChangeDetector2,
  monthChangeDetector1,
}) {
  const { token, authFetch } = useAuth();
  const { hotelId } = useParams();
  const [monthlyBookingsOccu1, setMonthlyBookingsOccu1] = useState([]);
  const [monthlyBookingsOccu2, setMonthlyBookingsOccu2] = useState([]);
  const [monthlyBookingsRev1, setMonthlyBookingsRev1] = useState([]);
  const [monthlyBookingsRev2, setMonthlyBookingsRev2] = useState([]);
  const [monthlyBookingsArr1, setMonthlyBookingsArr1] = useState([]);
  const [monthlyBookingsArr2, setMonthlyBookingsArr2] = useState([]);
  const [selectedBookingPeriod1, setSelectedBookingPeriod1] = useState([]);
  const [selectedBookingPeriod2, setSelectedBookingPeriod2] = useState([]);
  const [networkMsg, setnetworkMsg] = useState();
  const [open, setOpen] = useState();
  const [Loading, setLoading] = useState(false);
  const [Error, setError] = useState(false);
  const [variance, setVariance] = useState([]);
  const [toggleToRerender, setToggleToRerender] = useState(false);

  useEffect(() => {
    if (!token) {
      return;
    }
    async function makeSequential1() {
      await fetchBookingData();
      setToggleToRerender(!toggleToRerender);
    }
    makeSequential1();
  }, [
    token,
    fromDate1,
    toDate1,
    fromDate2,
    toDate2,
    hotelId,
    monthChangeDetector1,
    monthChangeDetector2,
  ]);
  useEffect(() => {
    if (!token) {
      return;
    }
    async function makeSequential1() {
      await fetchBookingData();
      setToggleToRerender(!toggleToRerender);
    }
    makeSequential1();
  }, []);

  useEffect(() => {
    if (selectedPickup === "occPickup") {
      const filterBooking1 = filterBooking(
        toDatePeriod1,
        fromDatePeriod1,
        monthlyBookingsOccu1
      );
      const filterBooking2 = filterBooking(
        toDatePeriod2,
        fromDatePeriod2,
        monthlyBookingsOccu2
      );

      setSelectedBookingPeriod1(filterBooking1);
      setSelectedBookingPeriod2(filterBooking2);
      setVariance(calculateVariance(filterBooking1, filterBooking2));
    }
    if (selectedPickup === "revPickup") {
      const filterBooking1 = filterBooking(
        toDatePeriod1,
        fromDatePeriod1,
        monthlyBookingsRev1
      );
      const filterBooking2 = filterBooking(
        toDatePeriod2,
        fromDatePeriod2,
        monthlyBookingsRev2
      );

      setSelectedBookingPeriod1(filterBooking1);
      setSelectedBookingPeriod2(filterBooking2);
      setVariance(calculateVariance(filterBooking1, filterBooking2));
    }
    if (selectedPickup === "arrPickup") {
      const filterBookingRev1 = filterBooking(
        toDatePeriod1,
        fromDatePeriod1,
        monthlyBookingsRev1
      );
      const filterBookingRev2 = filterBooking(
        toDatePeriod2,
        fromDatePeriod2,
        monthlyBookingsRev2
      );

      const filterBookingOccu1 = filterBooking(
        toDatePeriod1,
        fromDatePeriod1,
        monthlyBookingsOccu1
      );
      const filterBookingOccu2 = filterBooking(
        toDatePeriod2,
        fromDatePeriod2,
        monthlyBookingsOccu2
      );

      const filterBooking1 = filterBooking(
        toDatePeriod1,
        fromDatePeriod1,
        monthlyBookingsArr1
      );
      filterBooking1.pop();
      const filterBooking2 = filterBooking(
        toDatePeriod2,
        fromDatePeriod2,
        monthlyBookingsArr2
      );
      filterBooking2.pop();

      {
        let totalFromActual;
        if (
          filterBookingRev1[filterBookingRev1.length - 1].totalFromActual ===
            -1 ||
          filterBookingOccu1[filterBookingOccu1.length - 1].totalFromActual ===
            -1
        ) {
          totalFromActual = -1;
        } else {
          let numerator =
            filterBookingRev1[filterBookingRev1.length - 1].totalFromActual + 1;
          let denominator =
            filterBookingOccu1[filterBookingOccu1.length - 1].totalFromActual +
              1 ===
            0
              ? 1
              : filterBookingOccu1[filterBookingOccu1.length - 1]
                  .totalFromActual + 1;
          totalFromActual = (numerator / denominator).toFixed(2);
        }

        let totalToActual;

        if (
          filterBookingRev1[filterBookingRev1.length - 1].totalToActual ===
            -1 ||
          filterBookingOccu1[filterBookingOccu1.length - 1].totalToActual === -1
        ) {
          totalToActual = -1;
        } else {
          let numerator =
            filterBookingRev1[filterBookingRev1.length - 1].totalToActual + 1;
          let denominator =
            filterBookingOccu1[filterBookingOccu1.length - 1].totalToActual +
              1 ===
            0
              ? 1
              : filterBookingOccu1[filterBookingOccu1.length - 1]
                  .totalToActual + 1;
          totalToActual = (numerator / denominator).toFixed(2);
        }

        let totalPickup;

        if (
          filterBookingRev1[filterBookingRev1.length - 1].totalPickup === -1 ||
          filterBookingOccu1[filterBookingOccu1.length - 1].totalPickup === -1
        ) {
          totalPickup = -1;
        } else {
          let numerator =
            filterBookingRev1[filterBookingRev1.length - 1].totalPickup + 1;
          let denominator =
            filterBookingOccu1[filterBookingOccu1.length - 1].totalPickup +
              1 ===
            0
              ? 1
              : filterBookingOccu1[filterBookingOccu1.length - 1].totalPickup +
                1;
          totalPickup = (numerator / denominator).toFixed(2);
        }

        filterBooking1.push({
          totalFromActual,
          totalToActual,
          totalPickup,
        });
      }
      {
        let totalFromActual;
        if (
          filterBookingRev2[filterBookingRev2.length - 1].totalFromActual ===
            -1 ||
          filterBookingOccu2[filterBookingOccu2.length - 1].totalFromActual ===
            -1
        ) {
          totalFromActual = -1;
        } else {
          let numerator =
            filterBookingRev2[filterBookingRev2.length - 1].totalFromActual + 1;
          let denominator =
            filterBookingOccu2[filterBookingOccu2.length - 1].totalFromActual +
              1 ===
            0
              ? 1
              : filterBookingOccu2[filterBookingOccu2.length - 1]
                  .totalFromActual + 1;
          totalFromActual = (numerator / denominator).toFixed(2);
        }

        let totalToActual;

        if (
          filterBookingRev2[filterBookingRev2.length - 1].totalToActual ===
            -1 ||
          filterBookingOccu2[filterBookingOccu2.length - 1].totalToActual === -1
        ) {
          totalToActual = -1;
        } else {
          let numerator =
            filterBookingRev2[filterBookingRev2.length - 1].totalToActual + 1;
          let denominator =
            filterBookingOccu2[filterBookingOccu2.length - 1].totalToActual +
              1 ===
            0
              ? 1
              : filterBookingOccu2[filterBookingOccu2.length - 1]
                  .totalToActual + 1;
          totalToActual = (numerator / denominator).toFixed(2);
        }

        let totalPickup;

        if (
          filterBookingRev2[filterBookingRev2.length - 1].totalPickup === -1 ||
          filterBookingOccu2[filterBookingOccu2.length - 1].totalPickup === -1
        ) {
          totalPickup = -1;
        } else {
          let numerator =
            filterBookingRev2[filterBookingRev2.length - 1].totalPickup + 1;
          let denominator =
            filterBookingOccu2[filterBookingOccu2.length - 1].totalPickup +
              1 ===
            0
              ? 1
              : filterBookingOccu2[filterBookingOccu2.length - 1].totalPickup +
                1;
          totalPickup = (numerator / denominator).toFixed(2);
        }

        filterBooking2.push({
          totalFromActual,
          totalToActual,
          totalPickup,
        });
      }

      setSelectedBookingPeriod1(filterBooking1);
      setSelectedBookingPeriod2(filterBooking2);
      setVariance(calculateVariance(filterBooking1, filterBooking2));
    }
  }, [
    fromDatePeriod1,
    toDatePeriod1,
    fromDatePeriod2,
    toDatePeriod2,
    selectedPickup,
    toggleToRerender,
  ]);

  function calculateVariance(paceArray1, paceArray2) {
    const lengthOfLargestArray = Math.max(
      paceArray1.length - 1,
      paceArray2.length - 1
    );
    const lengthOfSmallestArray = Math.min(
      paceArray1.length - 1,
      paceArray2.length - 1
    );

    const VarianceArray = [];
    for (let i = 0; i < lengthOfSmallestArray; i++) {
      if (paceArray1[i] && paceArray2[i]) {
        if (paceArray1[i].toActuals !== -1 && paceArray2[i].toActuals !== -1) {
          VarianceArray.push(paceArray1[i].toActuals - paceArray2[i].toActuals);
        } else {
          VarianceArray.push("-");
        }
      } else {
        VarianceArray.push("-");
      }
    }

    for (let i = 0; i < lengthOfLargestArray - lengthOfSmallestArray; i++) {
      VarianceArray.push("-");
    }

    if (paceArray1.length === paceArray2.length && paceArray2.length > 0) {
      if (
        paceArray1[paceArray1.length - 1].totalToActual !== -1 &&
        paceArray2[paceArray2.length - 1].totalToActual !== -1
      ) {
        const diff =
          paceArray1[paceArray1.length - 1].totalToActual -
          paceArray2[paceArray2.length - 1].totalToActual;

        VarianceArray.push(diff);
      } else {
        VarianceArray.push("-");
      }
    } else {
      VarianceArray.push("-");
    }

    return VarianceArray;
  }

  async function fetchData() {
    setLoading(true);

    async function PickupDateset1() {
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/pickup`,
      });
      const { data, error, response } = await post({
        fromDate: getISODate(fromDate1),
        toDate: getISODate(toDate1),
      });
      if (response?.ok) {
        setMonthlyBookingsOccu1(data);
      } else {
        setnetworkMsg(data?.messageToUser || `Can't Fetch Data`);
        setOpen(true);
        setError(true);
        setMonthlyBookingsOccu1([]);
      }
      if (error) {
        console.log(error);
      }
    }

    async function PickupDateset2() {
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/pickup`,
      });
      const { data, error, response } = await post({
        fromDate: getISODate(fromDate2),
        toDate: getISODate(toDate2),
      });
      if (response?.ok) {
        setMonthlyBookingsOccu2(data);
      } else {
        setnetworkMsg(data?.messageToUser || `Can't Fetch Data`);
        setOpen(true);
        setError(true);
        setMonthlyBookingsOccu2([]);
      }
      if (error) {
        console.log(error);
      }
    }
    async function ARRDataset1() {
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/arrpickup`,
      });
      const { data, error, response } = await post({
        fromDate: getISODate(fromDate1),
        toDate: getISODate(toDate1),
      });
      if (response?.ok) {
        setMonthlyBookingsArr1(data);
      } else {
        setnetworkMsg(data?.messageToUser || `Can't Fetch Data`);
        setOpen(true);
        setError(true);
        setMonthlyBookingsArr1([]);
      }
    }

    async function ARRDataset2() {
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/arrpickup`,
      });
      const { data, error, response } = await post({
        fromDate: getISODate(fromDate2),
        toDate: getISODate(toDate2),
      });
      if (response?.ok) {
        setMonthlyBookingsArr2(data);
      } else {
        setMonthlyBookingsArr2([]);
        setnetworkMsg(data?.messageToUser || `Can't Fetch Data`);
        setOpen(true);
        setError(true);
      }
    }
    async function RevenueDataset1() {
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/revenuepickup`,
      });
      const { data, error, response } = await post({
        fromDate: getISODate(fromDate1),
        toDate: getISODate(toDate1),
      });
      if (response?.ok) {
        setMonthlyBookingsRev1(data);
      } else {
        setMonthlyBookingsRev1([]);
        setnetworkMsg(data?.messageToUser || `Can't Fetch Data`);
        setOpen(true);
        setError(true);
      }
    }

    async function RevenueDataset2() {
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/revenuepickup`,
      });
      const { data, error, response } = await post({
        fromDate: getISODate(fromDate2),
        toDate: getISODate(toDate2),
      });
      if (response?.ok) {
        setMonthlyBookingsRev2(data);
      } else {
        setnetworkMsg(data?.messageToUser || `Can't Fetch Data`);
        setOpen(true);
        setError(true);
        setMonthlyBookingsRev2([]);
      }
    }

    PickupDateset1();
    RevenueDataset1();

    PickupDateset2();
    RevenueDataset2();

    await ARRDataset1();

    await ARRDataset2();

    setLoading(false);
  }
  async function fetchBookingData() {
    setLoading(true);

    async function PickupDateset1() {
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/booking-pickup`,
      });
      const { data, error, response } = await post({
        bookingFromDate: getISODate(fromDatePeriod1),
        pickupFromDate: getISODate(fromDate1),
        pickupToDate: getISODate(toDate1),
      });
      if (response?.ok) {
        setMonthlyBookingsOccu1(data);
      } else {
        setnetworkMsg(data?.messageToUser || `Can't Fetch Data`);
        setOpen(true);
        setError(true);
        setMonthlyBookingsOccu1([]);
      }
      if (error) {
        console.log(error);
      }
    }

    async function PickupDateset2() {
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/booking-pickup`,
      });
      const { data, error, response } = await post({
        bookingFromDate: getISODate(fromDatePeriod2),
        pickupFromDate: getISODate(fromDate2),
        pickupToDate: getISODate(toDate2),
      });
      if (response?.ok) {
        setMonthlyBookingsOccu2(data);
      } else {
        setnetworkMsg(data?.messageToUser || `Can't Fetch Data`);
        setOpen(true);
        setError(true);
        setMonthlyBookingsOccu2([]);
      }
      if (error) {
        console.log(error);
      }
    }
    async function ARRDataset1() {
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/booking-arr-pickup`,
      });
      const { data, error, response } = await post({
        bookingFromDate: getISODate(fromDatePeriod1),
        pickupFromDate: getISODate(fromDate1),
        pickupToDate: getISODate(toDate1),
      });
      if (response?.ok) {
        setMonthlyBookingsArr1(data);
      } else {
        setnetworkMsg(data?.messageToUser || `Can't Fetch Data`);
        setOpen(true);
        setError(true);
        setMonthlyBookingsArr1([]);
      }
    }

    async function ARRDataset2() {
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/booking-arr-pickup`,
      });
      const { data, error, response } = await post({
        bookingFromDate: getISODate(fromDatePeriod2),
        pickupFromDate: getISODate(fromDate2),
        pickupToDate: getISODate(toDate2),
      });
      if (response?.ok) {
        setMonthlyBookingsArr2(data);
      } else {
        setMonthlyBookingsArr2([]);
        setnetworkMsg(data?.messageToUser || `Can't Fetch Data`);
        setOpen(true);
        setError(true);
      }
    }
    async function RevenueDataset1() {
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/booking-revenue-pickup`,
      });
      const { data, error, response } = await post({
        bookingFromDate: getISODate(fromDatePeriod1),
        pickupFromDate: getISODate(fromDate1),
        pickupToDate: getISODate(toDate1),
      });
      if (response?.ok) {
        setMonthlyBookingsRev1(data);
      } else {
        setnetworkMsg(data?.messageToUser || `Can't Fetch Data`);
        setOpen(true);
        setError(true);
        setMonthlyBookingsRev1([]);
      }
    }

    async function RevenueDataset2() {
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/booking-revenue-pickup`,
      });
      const { data, error, response } = await post({
        bookingFromDate: getISODate(fromDatePeriod2),
        pickupFromDate: getISODate(fromDate2),
        pickupToDate: getISODate(toDate2),
      });
      if (response?.ok) {
        setMonthlyBookingsRev2(data);
      } else {
        setMonthlyBookingsRev2([]);
        setnetworkMsg(data?.messageToUser || `Can't Fetch Data`);
        setOpen(true);
        setError(true);
      }
    }

    PickupDateset1();
    RevenueDataset1();

    PickupDateset2();
    RevenueDataset2();

    await ARRDataset1();

    await ARRDataset2();

    setLoading(false);
  }

  function filterBooking(sd, ed, array) {
    const numberOfDaysFromStartToEndDate =
      (new Date(sd).getTime() - new Date(ed).getTime()) / (1000 * 3600 * 24);
    const indexOfFirstMatchingDate = array?.findIndex(
      (val) => val.date.split("T")[0] === getISODate(ed).split("T")[0]
    );
    const indexOfLastMatchingDate = array?.findIndex(
      (val) => val.date.split("T")[0] === getISODate(sd).split("T")[0]
    );

    const numberOfDaysFromStartToEndDateINT = Math.round(
      numberOfDaysFromStartToEndDate
    );
    let newSlicedArray = [];

    if (indexOfFirstMatchingDate === -1 && indexOfLastMatchingDate === -1) {
      newSlicedArray = [];
    } else if (indexOfFirstMatchingDate === -1) {
      newSlicedArray = array.slice(0, indexOfLastMatchingDate + 1);
    } else if (indexOfLastMatchingDate === -1) {
      newSlicedArray = array.slice(
        indexOfFirstMatchingDate,
        indexOfFirstMatchingDate + numberOfDaysFromStartToEndDateINT
      );
    } else {
      newSlicedArray = array.slice(
        indexOfFirstMatchingDate,
        indexOfLastMatchingDate + 1
      );
    }

    let totalFromActual = -1;
    let totalToActual = -1;
    let totalPickup = -1;
    let totalOccu = "";

    for (let i = 0; i < newSlicedArray.length; i++) {
      if (newSlicedArray[i].fromActuals !== -1) {
        totalFromActual += newSlicedArray[i].fromActuals;
      } else {
        totalFromActual += 0;
      }

      if (newSlicedArray[i].toActuals !== -1) {
        totalToActual += newSlicedArray[i].toActuals;
      } else {
        totalToActual += 0;
      }

      if (newSlicedArray[i].pickup !== -1) {
        totalPickup += newSlicedArray[i].pickup;
      } else {
        totalPickup += 0;
      }
    }

    if (selectedPickup === "occPickup") {
      newSlicedArray.push({
        totalFromActual,
        totalToActual,
        totalPickup,
        totalOccu,
      });
    } else {
      newSlicedArray.push({
        totalFromActual,
        totalToActual,
        totalPickup,
      });
    }

    return newSlicedArray;
  }

  function handleCloseStatus() {
    setOpen(false);
  }
  return {
    monthlyBookings: [selectedBookingPeriod1, selectedBookingPeriod2],
    open,
    handleCloseStatus,
    Loading,
    networkMsg,
    Error,
    variance,
  };
}
