import AdapterDateFns from "@mui/lab/AdapterDateFns";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/system";
import {
  CityDemandLevelCard,
  DayOfWeekCard,
  DemandLevelCard,
  LeadTimeCard,
  ModelBody,
  OccupancyCard,
  PaceCard,
  YOYVarianceCard,
} from "./components";
import {
  useDayOfWeek,
  useDemandLevel,
  useLeadTime,
  useOccupancy,
  usePace,
  useYOYVariance,
} from "./hooks";
import { Container, PageContainer } from "./Styles";
import { Modal, Snackbar, SnackbarContent } from "@mui/material";
import { Prompt } from "react-router";
import { useAuth, usePrevious } from "../sdk";

const initialDLF = {
  isYoy: false,
  isPace: false,
};

const DLFToUpdate = {
  DayOfWeek: false,
  HotelDemandLevel: false,
  CityDemandLevel: false,
  LeadTime: false,
  Occupancy: false,
  Pace: false,
  YOY: false,
};
const DemandsLevels = [
  "DayOfWeek",
  "HotelDemandLevel",
  "CityDemandLevel",
  "LeadTime",
  "Occupancy",
  "Pace",
  "YOY",
];
export default function ({ openSelectbar, setOpenSelectBar }) {
  const { hotelId } = useParams();
  const { token, authFetch, permission, hotels } = useAuth();
  const [copyFromHotelId, setCopyFromHotelId] = useState(null);
  const [copyHotelName, setCopyHotelName] = useState("");
  const prevCopyFromHotelId = usePrevious(copyFromHotelId, null);
  const [isDLF, setIsDLF] = useState(initialDLF);
  const [updateCheckbox, setUpdateCheckbox] = useState(false);
  const [updateSelectedDLF, setUpdateSelectedDLF] = useState(DLFToUpdate);
  const [error, setError] = useState("");
  const [selectDLF, setSelectDLF] = useState([]);
  const [allHotels, setAllHotels] = useState();
  let isAdmin = false;
  let isManual = true;
  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "acrossHotel") {
        isAdmin = true;
      }
    }
  }

  useEffect(() => {
    if (token) {
      getAllHotels();
    } else {
      return;
    }
  }, [token]);

  async function getAllHotels() {
    if (isAdmin) {
      const { get } = await authFetch({
        path: `/hotel`,
      });
      const { data, error } = await get();
      if (data) {
        setAllHotels(data);
      } else {
        console.log(error);
      }
    } else {
      setAllHotels(hotels);
    }
  }

  const handleDLFChange = (val) => {
    setSelectDLF(val);
  };

  const handleDLF = (key, value) => {
    setIsDLF({
      ...isDLF,
      [key]: value,
    });
    setUpdateCheckbox((prev) => !prev);
  };

  useEffect(() => {
    async function GetDLF() {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/dlf-selection`,
      });
      const { data, error } = await get();
      if (data) {
        setIsDLF(data);
      } else {
        console.log(error);
      }
    }
    if (token) {
      GetDLF();
    }
  }, [token]);

  useEffect(() => {
    if (isDLF.id) {
      async function UpdateDLF() {
        const { put } = await authFetch({
          path: `/hotel/${hotelId}/dlf-selection`,
        });
        const { data, response, error } = await put(isDLF);
        if (response?.ok) {
          setIsDLF(isDLF);
        } else {
          setError(data?.messageToUser || "Something went wrong");
          console.log(error);
        }
      }
      UpdateDLF();
    }
  }, [updateCheckbox]);

  const {
    demandLevels: manualDemandLevels,
    dlIsInEditMode: manualDLIsInEditMode,
    setDlIsInEditMode: setManualDLIsInEditMode,
    dlIsCancelled: manualDLIsCancelled,
    dlSetIsCancelled: setManualDLIsCancelled,
    dlIsSaved: manualDLIsSaved,
    dlSetIsSaved: setManualDLIsSaved,
    dlChangeHandler: manualDLChangeHandler,
    dlopen: manualDLopen,
    dlhandleCloseStatus: manualDLhandleCloseStatus,
    dlnetworkMsg: manualDLnetworkMsg,
    dlLoading: manualDLLoading,
    editCityDemandLevel,
    handleEditCityDLChange,
    isCityDLEditMode,
    setIsCityDLInEditMode,
    checkCityDLValidation,
  } = useDemandLevel(
    hotelId,
    copyFromHotelId,
    updateSelectedDLF.CityDemandLevel,
    (isManual = true)
  );

  const {
    demandLevels: cityDemandLevels,
    dlIsInEditMode: cityDLIsInEditMode,
    setDlIsInEditMode: setCityDLIsInEditMode,
    dlIsCancelled: cityDLIsCancelled,
    dlSetIsCancelled: setCityDLIsCancelled,
    dlIsSaved: cityDLIsSaved,
    dlSetIsSaved: setCityDLIsSaved,
    dlChangeHandler: cityDLChangeHandler,
    dlopen: cityDLopen,
    dlhandleCloseStatus: cityDLhandleCloseStatus,
    dlnetworkMsg: cityDLnetworkMsg,
    dlLoading: cityDLLoading,
    editHotelDemandLevel,
    handleEditChange,

    isHotelDLEditMode,
    setIsHotelDLInEditMode,
    checkHotelDLValidation,
  } = useDemandLevel(
    hotelId,
    copyFromHotelId,
    updateSelectedDLF.HotelDemandLevel,
    (isManual = false)
  );

  const {
    daysOfWeek,
    dowIsInEditMode,
    setDowIsInEditMode,
    dowIsCancelled,
    dowSetIsCancelled,
    dowIsSaved,
    dowSetIsSaved,
    dowChangeHandler,
    dowopen,
    dowhandleCloseStatus,
    downetworkMsg,
    dowLoading,
    isEditDaysofWeek,
    handleEditDaysOfWeekChange,
  } = useDayOfWeek(hotelId, copyFromHotelId, updateSelectedDLF.DayOfWeek);
  const {
    leadTimes,
    ltIsInEditMode,
    setLtIsInEditMode,
    ltIsCancelled,
    ltSetIsCancelled,
    ltIsSaved,
    ltSetIsSaved,
    ltChangeHandler,
    ltopen,
    lthandleCloseStatus,
    ltnetworkMsg,
    ltaddRow,
    ltonRemoveRowHandler,
    ltonAddRowHandler,
    ltLoading,
    editLeadTimes,
    handleEditIconChange,
    checkLeadTimeValidation,
  } = useLeadTime(hotelId, copyFromHotelId, updateSelectedDLF.LeadTime);

  const {
    occupancies,
    oIsInEditMode,
    setOIsInEditMode,
    oIsCancelled,
    oSetIsCancelled,
    oIsSaved,
    oSetIsSaved,
    oChangeHandler,
    oopen,
    ohandleCloseStatus,
    onetworkMsg,
    OonRemoveRowHandler,
    OonAddRowHandler,
    OLoading,
    editOccupancies,
    handleEditOccupancyChange,
    checkOccupancyValidation,
  } = useOccupancy(hotelId, copyFromHotelId, updateSelectedDLF.Occupancy);
  const {
    variances,
    vIsInEditMode,
    setVIsInEditMode,
    vIsCancelled,
    vSetIsCancelled,
    vIsSaved,
    vSetIsSaved,
    vChangeHandler,
    vopen,
    vhandleCloseStatus,
    vnetworkMsg,
    yonRemoveRowHandler,
    yonAddRowHandler,
    yLoading,
    editYOYVariance,
    handleEditYOYVarianceChange,
    checkYOYVarianceValidation,
  } = useYOYVariance(hotelId, copyFromHotelId, updateSelectedDLF.YOY);
  const {
    paces,
    pIsInEditMode,
    setPIsInEditMode,
    pIsCancelled,
    pSetIsCancelled,
    pIsSaved,
    pSetIsSaved,
    pChangeHandler,
    popen,
    phandleCloseStatus,
    pnetworkMsg,
    ponRemoveRowHandler,
    ponAddRowHandler,
    pLoading,
    editPace,
    handleEditPaceChange,
    checkPaceValidation,
  } = usePace(hotelId, copyFromHotelId, updateSelectedDLF.Pace);

  const updateDLF = () => {
    setOpenSelectBar(false);
    setUpdateSelectedDLF({
      DayOfWeek: selectDLF.some((val) => val === "DayOfWeek"),
      HotelDemandLevel: selectDLF.some((val) => val === "HotelDemandLevel"),
      CityDemandLevel: selectDLF.some((val) => val === "CityDemandLevel"),
      LeadTime: selectDLF.some((val) => val === "LeadTime"),
      Occupancy: selectDLF.some((val) => val === "Occupancy"),
      Pace: selectDLF.some((val) => val === "Pace"),
      YOY: selectDLF.some((val) => val === "YOY"),
    });
  };
  useEffect(() => {
    if (openSelectbar) setUpdateSelectedDLF(DLFToUpdate);
  }, [openSelectbar]);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);

  useEffect(() => {
    setShouldBlockNavigation(
      pIsInEditMode ||
        vIsInEditMode ||
        oIsInEditMode ||
        ltIsInEditMode ||
        dowIsInEditMode ||
        manualDLIsInEditMode ||
        cityDLIsInEditMode
    );
  }, [
    pIsInEditMode,
    vIsInEditMode,
    oIsInEditMode,
    ltIsInEditMode,
    dowIsInEditMode,
    manualDLIsInEditMode,
    cityDLIsInEditMode,
  ]);
  return (
    <PageContainer>
      <Prompt
        when={shouldBlockNavigation}
        message="You have unsave changes, are you sure want to leave"
      />
      {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
      <Modal
        open={openSelectbar}
        onClose={() => {
          setOpenSelectBar(false);
        }}
      >
        <div>
          <ModelBody
            copyFromHotelId={copyFromHotelId}
            setCopyFromHotelId={setCopyFromHotelId}
            copyHotelName={copyHotelName}
            setCopyHotelName={setCopyHotelName}
            handleDLFChange={handleDLFChange}
            selectDLF={selectDLF}
            updateDLF={updateDLF}
            setOpenSelectBar={setOpenSelectBar}
            allHotels={allHotels}
            hotelId={hotelId}
          />
        </div>
      </Modal>
      <Container>
        <DemandLevelCard
          demandLevels={manualDemandLevels}
          isInEditMode={manualDLIsInEditMode}
          setIsInEditMode={setManualDLIsInEditMode}
          isCancelled={manualDLIsCancelled}
          setIsCancelled={setManualDLIsCancelled}
          isSaved={manualDLIsSaved}
          setIsSaved={setManualDLIsSaved}
          onChange={manualDLChangeHandler}
          open={manualDLopen}
          handleCloseStatus={manualDLhandleCloseStatus}
          networkMsg={manualDLnetworkMsg}
          dlLoading={manualDLLoading}
          editCityDemandLevel={editCityDemandLevel}
          handleEditCityDLChange={handleEditCityDLChange}
          isCityDLEditMode={isCityDLEditMode}
          setIsCityDLInEditMode={setIsCityDLInEditMode}
          checkCityDLValidation={checkCityDLValidation}
        />
        <CityDemandLevelCard
          demandLevels={cityDemandLevels}
          isInEditMode={cityDLIsInEditMode}
          setIsInEditMode={setCityDLIsInEditMode}
          isCancelled={cityDLIsCancelled}
          setIsCancelled={setCityDLIsCancelled}
          isSaved={cityDLIsSaved}
          setIsSaved={setCityDLIsSaved}
          onChange={cityDLChangeHandler}
          open={cityDLopen}
          handleCloseStatus={cityDLhandleCloseStatus}
          networkMsg={cityDLnetworkMsg}
          dlLoading={cityDLLoading}
          editHotelDemandLevel={editHotelDemandLevel}
          handleEditChange={handleEditChange}
          isHotelDLEditMode={isHotelDLEditMode}
          setIsHotelDLInEditMode={setIsHotelDLInEditMode}
          checkHotelDLValidation={checkHotelDLValidation}
        />
        <OccupancyCard
          occupancies={occupancies}
          isInEditMode={oIsInEditMode}
          setIsInEditMode={setOIsInEditMode}
          isCancelled={oIsCancelled}
          setIsCancelled={oSetIsCancelled}
          isSaved={oIsSaved}
          setIsSaved={oSetIsSaved}
          onChange={oChangeHandler}
          open={oopen}
          handleCloseStatus={ohandleCloseStatus}
          networkMsg={onetworkMsg}
          OonRemoveRowHandler={OonRemoveRowHandler}
          OonAddRowHandler={OonAddRowHandler}
          OLoading={OLoading}
          editOccupancies={editOccupancies}
          handleEditOccupancyChange={handleEditOccupancyChange}
          checkOccupancyValidation={checkOccupancyValidation}
        />

        <LeadTimeCard
          handleEditIconChange={handleEditIconChange}
          editLeadTimes={editLeadTimes}
          leadTimes={leadTimes}
          isInEditMode={ltIsInEditMode}
          setIsInEditMode={setLtIsInEditMode}
          isCancelled={ltIsCancelled}
          setIsCancelled={ltSetIsCancelled}
          isSaved={ltIsSaved}
          setIsSaved={ltSetIsSaved}
          onChange={ltChangeHandler}
          open={ltopen}
          handleCloseStatus={lthandleCloseStatus}
          networkMsg={ltnetworkMsg}
          addRow={ltaddRow}
          ltonAddRowHandler={ltonAddRowHandler}
          ltonRemoveRowHandler={ltonRemoveRowHandler}
          ltLoading={ltLoading}
          checkLeadTimeValidation={checkLeadTimeValidation}
        />
        <DayOfWeekCard
          daysOfWeek={daysOfWeek}
          isInEditMode={dowIsInEditMode}
          setIsInEditMode={setDowIsInEditMode}
          isCancelled={dowIsCancelled}
          setIsCancelled={dowSetIsCancelled}
          isSaved={dowIsSaved}
          setIsSaved={dowSetIsSaved}
          onChange={dowChangeHandler}
          open={dowopen}
          handleCloseStatus={dowhandleCloseStatus}
          networkMsg={downetworkMsg}
          dowLoading={dowLoading}
          isEditDaysofWeek={isEditDaysofWeek}
          handleEditDaysOfWeekChange={handleEditDaysOfWeekChange}
        />
        <YOYVarianceCard
          variances={variances}
          isInEditMode={vIsInEditMode}
          setIsInEditMode={setVIsInEditMode}
          isCancelled={vIsCancelled}
          setIsCancelled={vSetIsCancelled}
          isSaved={vIsSaved}
          setIsSaved={vSetIsSaved}
          onChange={vChangeHandler}
          open={vopen}
          handleCloseStatus={vhandleCloseStatus}
          networkMsg={vnetworkMsg}
          yonRemoveRowHandler={yonRemoveRowHandler}
          yonAddRowHandler={yonAddRowHandler}
          yLoading={yLoading}
          isDLF={isDLF}
          handleDLF={handleDLF}
          editYOYVariance={editYOYVariance}
          handleEditYOYVarianceChange={handleEditYOYVarianceChange}
          checkYOYVarianceValidation={checkYOYVarianceValidation}
        />
        <PaceCard
          paces={paces}
          isInEditMode={pIsInEditMode}
          setIsInEditMode={setPIsInEditMode}
          isCancelled={pIsCancelled}
          setIsCancelled={pSetIsCancelled}
          isSaved={pIsSaved}
          setIsSaved={pSetIsSaved}
          onChange={pChangeHandler}
          open={popen}
          handleCloseStatus={phandleCloseStatus}
          networkMsg={pnetworkMsg}
          ponRemoveRowHandler={ponRemoveRowHandler}
          ponAddRowHandler={ponAddRowHandler}
          pLoading={pLoading}
          isDLF={isDLF}
          handleDLF={handleDLF}
          editPace={editPace}
          handleEditPaceChange={handleEditPaceChange}
          checkPaceValidation={checkPaceValidation}
        />
      </Container>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={error}
        autoHideDuration={3000}
        onClose={() => setError(false)}
      >
        {error && (
          <SnackbarContent
            style={{
              backgroundColor: "#CA3433",
            }}
            message={error}
          />
        )}
      </Snackbar>
      {/* </LocalizationProvider> */}
    </PageContainer>
  );
}
