import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  SnackbarContent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import useRoomConfig from "../hooks/useRoomConfig";
import { LoadingButton } from "@mui/lab";
import { LoadingPage } from "../../sdk";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import DeleteIcon from "@mui/icons-material/Delete";

const RoomConfig = () => {
  const {
    mappingData,
    saveMappingData,
    handleRoomChange,
    snackBar,
    setSnackBar,
    isLoading,
    formEdit,
    hotelId,
    handleRedirect,
    openWarningModal,
    setOpenWarningModal,
    handleClearMapping,
    includedChange,
    excludedChange,
    saveExcludedData,
    handleExcludeRoomChange,
    handleClearExcludedMapping,
  } = useRoomConfig();
  console.log({ mappingData });

  const includedData = mappingData?.map((hotel) => {
    return {
      ...hotel,
      roomType: hotel?.roomType?.filter((room) => !room.isExcluded),
    };
  });

  return isLoading.getApi ? (
    <LoadingPage />
  ) : (
    <RoomConfigPageStack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography fontSize="18px" fontWeight="500" mb={1}>
          Included Rooms
        </Typography>
        <Button
          className="disableMapping"
          variant="contained"
          onClick={() => setOpenWarningModal(true)}
        >
          Disable Mapping
        </Button>
      </Stack>

      {includedData?.map((item) => {
        return (
          <Stack
            key={item?.rateMetricHotelCode}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="50%"
          >
            <Typography width="40%">{item?.name}</Typography>
            <FormControl sx={{ width: "40%" }}>
              <InputLabel id="demo-simple-select-label">Select room</InputLabel>
              <Select
                sx={{ paddingRight: "32px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                multiple
                value={
                  item?.roomType
                    ?.filter((room) => room?.mapped === true)
                    ?.map((item) => item.roomDetailsId) || []
                }
                label="Select room"
                renderValue={(selected) => {
                  const arr = item?.roomType
                    ?.filter((val) => selected?.includes(val.roomDetailsId))
                    ?.map((item) => item.roomType);
                  return arr.join(", ");
                }}
                onChange={(e) => handleRoomChange(e, item?.rateMetricHotelCode)}
              >
                {item?.roomType?.map((room, idx) => {
                  return (
                    <MenuItem key={idx} value={room?.roomDetailsId}>
                      <Typography
                        width="300px"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {room?.roomType}
                      </Typography>
                      <Checkbox checked={room?.mapped} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Tooltip title="Remove room">
              <IconButton
                onClick={() => {
                  handleClearMapping(item?.rateMetricHotelCode);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      })}

      <Box display="flex" justifyContent="flex-end" gap={4}>
        <LoadingButton
          variant="contained"
          onClick={() => saveMappingData({ clear: false })}
          loading={isLoading.saveApi}
          disabled={includedChange || !formEdit}
        >
          save
        </LoadingButton>
        <Button
          variant="contained"
          onClick={() => handleRedirect(`/hotel/${hotelId}/setup/competitors`)}
        >
          Next
        </Button>
      </Box>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snackBar.open}
        autoHideDuration={3000}
        onClose={() => {
          setSnackBar({
            open: false,
            msg: "",
          });
        }}
      >
        <SnackbarContent
          style={{
            backgroundColor: snackBar.color,
          }}
          message={snackBar.msg}
        />
      </Snackbar>
      <StyledDialog
        open={openWarningModal}
        onClose={() => setOpenWarningModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className="content">
          <Stack direction="row" alignItems="center" gap="8px">
            <Typography fontWeight="600" fontSize="24px">
              Warning
            </Typography>
            <WarningIcon sx={{ color: "#a90f0f" }} />
          </Stack>
        </DialogTitle>

        <CloseIcon
          className="closeButton"
          onClick={() => setOpenWarningModal(false)}
        />
        <DialogContent className="content">
          <Typography textAlign="center">
            Are you sure you want to disable the room mapping? If yes, then
            lowest rates of your hotel will be compared with the competitor's
            lowest rates.
          </Typography>
          <Stack direction="row" gap="40px" padding="24px">
            <Button
              variant="contained"
              onClick={() => setOpenWarningModal(false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                saveMappingData({ clear: true });
                setOpenWarningModal(false);
              }}
            >
              Yes
            </Button>
          </Stack>
        </DialogContent>
      </StyledDialog>
      <Typography fontSize="18px" fontWeight="500" mb={1}>
        Excluded Rooms
      </Typography>
      {mappingData?.map((item, index) => {
        return (
          <Stack
            key={index}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="50%"
          >
            <Typography width="40%">{item?.name}</Typography>
            <FormControl sx={{ width: "40%" }}>
              <InputLabel id="demo-simple-select-label">Select room</InputLabel>
              <Select
                sx={{ paddingRight: "32px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                multiple
                value={
                  item?.roomType
                    ?.filter((room) => room?.isExcluded === true)
                    ?.map((item) => item.roomDetailsId) || []
                }
                label="Select room"
                renderValue={(selected) => {
                  const arr = item?.roomType
                    ?.filter((val) => selected?.includes(val.roomDetailsId))
                    ?.map((item) => item.roomType);
                  return arr.join(", ");
                }}
                onChange={(e) => handleExcludeRoomChange(e, index)}
              >
                {item?.roomType?.map((room, idx) => {
                  return (
                    <MenuItem key={idx} value={room?.roomDetailsId}>
                      <Typography
                        width="300px"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {room?.roomType}
                      </Typography>
                      <Checkbox checked={room?.isExcluded} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Tooltip title="Remove room">
              <IconButton
                onClick={() => {
                  handleClearExcludedMapping(index);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      })}

      <Box display="flex" justifyContent="flex-end" gap={4}>
        <LoadingButton
          variant="contained"
          onClick={() => saveExcludedData()}
          loading={isLoading.saveApi}
          disabled={excludedChange || !formEdit}
        >
          save
        </LoadingButton>
        <Button
          variant="contained"
          onClick={() => handleRedirect(`/hotel/${hotelId}/setup/competitors`)}
        >
          Next
        </Button>
      </Box>
    </RoomConfigPageStack>
  );
};

const RoomConfigPageStack = styled(Stack)(() => ({
  width: " 100%",
  background: "#ffffff",
  border: "none",
  padding: "20px",
  outline: "none",
  height: "calc(100vh - 160px)",
  overflowY: "scroll",
  overflowX: "hidden",
  boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
  gap: "16px",
  ".borderStack": {
    gap: "16px",
    padding: "24px",
    boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
  },
  ".disableMapping": {
    width: "160px",
    backgroundColor: "#a90f0f",
    "&:hover": {
      backgroundColor: "#c30909",
    },
  },
}));

const StyledDialog = styled(Dialog)(() => ({
  ".closeButton": {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
  },
  ".content": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export default RoomConfig;
