import {
  Box,
  Table as TB,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Delete as DEL } from "@mui/icons-material";
import React, { memo } from "react";
import { styled } from "@mui/system";

const Table = styled(TB)`
  margin: auto;
  font-family: Roboto;
`;

const Th = styled(TableCell)`
  &.MuiTableCell-head {
    vertical-align: top;
    text-align: left;
    font: normal normal bold 12px/15px Roboto;
    letter-spacing: 0px;
    color: #110641;
    border: none;
    padding: 0px;
  }
`;

const Td = styled(TableCell)`
  &.MuiTableCell-body {
    text-align: left;
    font: normal normal normal 12px/15px Roboto;
    letter-spacing: 0px;
    color: #333333;
    border: none;
    padding: 0px;
    padding-top: 13px;
  }
`;

const Delete = styled(Td)`
  :hover {
    cursor: pointer;
  }
`;

const Header = styled(Box)`
  width: 100%;
  padding: 5px 0;
  margin-right: 10px;
`;
const Text = styled(Typography)`
  font: normal normal bold 12px/15px Roboto;
  letter-spacing: 0px;
  color: #0a0a0a;
`;
const UpperText = styled(Typography)`
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #130453;
  margin-bottom: 8px;
`;

const DateHeader = styled(Box)`
  font: normal normal normal 12px/15px Roboto;
  letter-spacing: 0px;
  color: #0a0a0a;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 22px;
  margin-top: 10px;
`;

const Tr = styled(TableRow)(({ theme }) => ({}));

const Thead = styled(TableHead)``;
const Tbody = styled(TableBody)``;

function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  if (parseInt(hours) <= 9) {
    hours = "0" + hours;
  }
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime.toUpperCase();
}

const Days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const Popover = memo(
  ({
    header,
    name,
    logs,
    setShouldBlockNavigation,
    setFile,
    fileUploadedDelete,
    setFileType,
    file,
    filetype,
  }) => {
    setFileType(filetype);
    setFile(file);
    return (
      <>
        <Header>
          <UpperText>{name}</UpperText>

          <Text>{header}</Text>
        </Header>

        <TableContainer style={{ marginBottom: "10px" }}>
          <Table>
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Time</Th>
                <Th>User</Th>
              </Tr>
            </Thead>
            <Tbody>
              {logs.map((log) => (
                <Tr>
                  <Td>{new Date(log.time).toLocaleDateString("en-GB")}</Td>
                  <Td>{formatAMPM(new Date(log.time))}</Td>
                  <Td>{log.username}</Td>
                  {fileUploadedDelete ? (
                    <Delete>
                      <DEL
                        style={{ fontSize: 18, marginLeft: 2 }}
                        onClick={() => {
                          setFile(log);
                          setShouldBlockNavigation(true);
                        }}
                      />
                    </Delete>
                  ) : (
                    ""
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </>
    );
  }
);
export default Popover;
