import {
  Button,
  Select as SL,
  Input,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { GhostButton } from "./../sdk";

export const CancelButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #306fbc;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(3, 4, 94, 0.2);
  border-radius: 8px;
  padding: 6px 12px 6px 12px;
  color: #306fbc;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;

  height: 20px;
  &:hover {
    background: #ffffff;
  }
`;
export const SaveButton = styled(Button)`
  background: #757575;
  box-shadow: 0px 4px 4px rgba(3, 4, 94, 0.2);
  border-radius: 8px;
  padding: 6px 12px 6px 12px;
  color: #ffffff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;

  height: 20px;
  &:hover {
    background: #757575;
  }
`;
export const PageContainer = styled(Box)`
  height: calc(100vh - 240px);

  width: 100%;
  padding: 0px 20px;
  overflow: scroll;
  overflow-x: hidden;
  .MuiGridList-root {
    flex-wrap: nowrap;
  }
  .head-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

export const Heading = styled(Box)`
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #130453;
`;
export const CardContainer = styled(Box)`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
  padding: 16px;
  .title {
    text-align: left;

    letter-spacing: 0px;

    opacity: 1;
    padding: 0 20px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: #000000;
  }
  button {
    font: 500 12px/15px Roboto;
    margin: 5px 11px;
  }
`;
export const Container = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 20px;
`;
export const TCell = styled(TableCell)`
  &.MuiTableCell-root {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #000000;
    letter-spacing: 0px;

    padding: 5px 10px;
    border: none;
    text-align: ${(props) => (props.isInEditMode ? "left" : "center")};
    text-transform: capitalize;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
`;
export const Tabhead = styled(TableCell)`
  &.MuiTableCell-root {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: #ffffff;
    letter-spacing: 0px;

    padding: 5px 8px;

    background: #306fbc;

    width: 100px;
    text-align: ${(props) => (props.isInEditMode ? "left" : "center")};
    text-transform: capitalize;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    &:first-child {
      border-radius: 4px 0px 0px 0px;
    }
    &:last-child {
      border-radius: 0px 4px 0px 0px;
    }
  }
`;

export const TRow = styled(TableRow)`
  &.MuiTableRow-root {
    margin-left: 32px;
  }
`;
export const THead = styled(TableHead)`
  &.MuiTableHead-root {
    tr.MuiTableRow-root {
      background: transparent;
      width: 100%;
    }
  }
`;

export const TContainer = styled(TableContainer)`
  &.MuiPaper-elevation1 {
    box-shadow: none;
  }
  width: 100%;
`;

export const TextFieldSmall = styled(Input)`
  input.MuiInputBase-input {
    width: 40px;
    padding: 5px 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #333333;
    text-align: center;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const UploadBtn = styled(GhostButton)`
  text-align: right;
  font: Bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #343434;
`;

export const Head = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 20px;
`;
export const Select = styled(SL)`
  font: Bold 12px/15px Roboto;
  letter-spacing: 0px;
  color: #838383;
`;
export const Header = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;
