import { Box, Snackbar, SnackbarContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../sdk";
import LoginForm from "./components/LoginForm";
import { Main } from "./components/Styles";

const LoginPage = () => {
  const {
    isLoggedIn,
    login,
    isLoading,
    hotels,
    permissions,
    inValidLogin,
  } = useAuth();
  const [networkMsg, setnetworkMsg] = useState(null);
  const [open, setOpen] = useState(false);
  const handleCloseStatus = () => {
    setOpen(false);
  };
  const history = useHistory();
  useEffect(() => {
    if (inValidLogin) {
      setnetworkMsg("Invalid Login Credentials,Try Again");
      setOpen(true);
    }
  }, [inValidLogin]);
  return (
    <Main>
      <Box>
        <LoginForm login={login} isLoading={isLoading} />
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseStatus}
      >
        <SnackbarContent
          style={{ backgroundColor: "#CA3433" }}
          message={networkMsg}
        />
      </Snackbar>
    </Main>
  );
};

export default LoginPage;
