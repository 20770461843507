import {
  Checkbox,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
} from "@mui/material";
import React, { useState } from "react";
import { SecondaryButton, TableLoading } from "../../sdk";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import {
  CancelButton,
  CardContainer,
  Header,
  SaveButton,
  Tabhead,
  TCell,
  TContainer,
  TextFieldSmall,
  THead,
  TRow,
} from "../Styles";

import { Add, Remove, Edit } from "@mui/icons-material";
import { styled } from "@mui/system";

const RemoveButton = styled(Remove)`
  color: #306fbc;
  cursor: pointer;
  width: 16px;
  height: 16px;
`;
const AddButton = styled(Add)`
  color: #306fbc;
  cursor: pointer;
  width: 16px;
`;
const EditButton = styled(Edit)`
  height: 18px;
  width: 18px;
  color: #306fbc;
`;

export function YOYVarianceCard({
  variances,
  isInEditMode,
  setIsInEditMode,
  isCancelled,
  setIsCancelled,
  isSaved,
  setIsSaved,
  onChange,
  open,
  handleCloseStatus,
  networkMsg,
  yonRemoveRowHandler,
  yonAddRowHandler,
  yLoading,
  isDLF,
  handleDLF,
  editYOYVariance,
  handleEditYOYVarianceChange,
  checkYOYVarianceValidation,
}) {
  const [openAddDialog, setOpenAddDialog] = useState(false);
  return (
    <>
      <CardContainer>
        <Header>
          <div className="title">YOY Variance</div>
          <Checkbox
            checked={isDLF.isYoy}
            onChange={(e) => handleDLF("isYoy", e.target.checked)}
            style={{ color: "rgb(48,111,188)", width: "30px", height: "30px" }}
          />
          {!isInEditMode ? (
            <SecondaryButton style={{ border: "none" }}>
              {/* Edit */}
            </SecondaryButton>
          ) : (
            <div>
              <CancelButton
                onClick={() => {
                  setIsCancelled(!isCancelled);
                  setIsInEditMode(!isInEditMode);
                }}
              >
                Cancel
              </CancelButton>
              <SaveButton
                onClick={() => {
                  setIsSaved(!isSaved);
                  setIsInEditMode(!isInEditMode);
                }}
                disabled={checkYOYVarianceValidation}
              >
                Save
              </SaveButton>
            </div>
          )}
        </Header>
        {!yLoading ? (
          <TContainer style={{ maxHeight: "50vh" }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{
                width: "100%",
                border: "1px solid rgba(0, 0, 0, 0.12)",

                borderRadius: "5px",
              }}
            >
              <THead style={{ height: "1px", padding: "0px" }}>
                <TRow>
                  <Tabhead $isineditmode={isInEditMode}>Min</Tabhead>
                  <Tabhead $isineditmode={isInEditMode}>Max</Tabhead>
                  <Tabhead $isineditmode={isInEditMode}>Value</Tabhead>
                  <Tabhead>Actions</Tabhead>
                </TRow>
              </THead>
              <TableBody>
                {variances.map((row, idx) => (
                  <TRow key={idx}>
                    <TCell $isineditmode={isInEditMode}>
                      {editYOYVariance[idx] ? (
                        <TextFieldSmall
                          type="number"
                          InputProps={{ inputProps: { min: -100, max: 100 } }}
                          value={row.min}
                          onChange={(e) =>
                            onChange(
                              idx,
                              "min",
                              e.target.value && parseInt(e.target.value)
                            )
                          }
                        />
                      ) : !row.min && row.min !== 0 ? (
                        ""
                      ) : (
                        row.min
                      )}
                    </TCell>
                    <TCell $isineditmode={isInEditMode}>
                      {editYOYVariance[idx] ? (
                        <TextFieldSmall
                          type="number"
                          InputProps={{ inputProps: { min: -100, max: 100 } }}
                          value={row.max}
                          onChange={(e) =>
                            onChange(
                              idx,
                              "max",
                              e.target.value && parseInt(e.target.value)
                            )
                          }
                        />
                      ) : !row.max && row.max !== 0 ? (
                        ""
                      ) : (
                        row.max
                      )}
                    </TCell>
                    <TCell $isineditmode={isInEditMode}>
                      {editYOYVariance[idx] ? (
                        <TextFieldSmall
                          type="number"
                          value={row.value}
                          onChange={(e) =>
                            onChange(
                              idx,
                              "value",
                              e.target.value && parseFloat(e.target.value)
                            )
                          }
                        />
                      ) : !row.value && row.value !== 0 ? (
                        ""
                      ) : (
                        row.value
                      )}
                    </TCell>

                    <TCell
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        height: "40px",
                      }}
                    >
                      <AddButton
                        onClick={() => {
                          yonAddRowHandler(idx);
                          if (variances.length === 1) setOpenAddDialog(true);
                        }}
                      />
                      <RemoveButton onClick={() => yonRemoveRowHandler(idx)} />
                      <EditButton
                        onClick={() => handleEditYOYVarianceChange(idx)}
                      />
                    </TCell>
                  </TRow>
                ))}
              </TableBody>
            </Table>
          </TContainer>
        ) : (
          <TableLoading />
        )}
      </CardContainer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseStatus}
      >
        <SnackbarContent
          style={{ backgroundColor: "#CA3433" }}
          message={networkMsg}
        />
      </Snackbar>

      <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
        <DialogContent>
          Please edit the values for first row as well.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddDialog(false)}>Okay</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
