import UserUpdate from "./components/ProfileUpdate";
import React, { useEffect, useState } from "react";
import { Snackbar, SnackbarContent } from "@mui/material";

export default function UpdateProfile({ setPageHeader }) {
  const [open, setOpen] = useState(false);
  const [networkMsg, setNetworkMsg] = useState("");
  const handleCloseStatus = () => {
    setOpen(false);
  };
  useEffect(() => {
    setPageHeader("Update Profile");
    return () => {
      setPageHeader("");
    };
  }, []);
  return (
    <>
      <UserUpdate setNetworkMsg={setNetworkMsg} setOpen={setOpen} />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseStatus}
      >
        {networkMsg !== "User profile updated successfully" ? (
          <SnackbarContent
            style={{ backgroundColor: "#CA3433" }}
            message={networkMsg}
          />
        ) : (
          <SnackbarContent
            style={{ backgroundColor: "#228b22" }}
            message={networkMsg}
          />
        )}
      </Snackbar>
    </>
  );
}
