import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getISODate } from "../../sdk";
import { useAuth } from "../../sdk/hooks/useAuth";
export default function useBudgetAllocation() {
  const { token, authFetch } = useAuth();
  const { hotelId } = useParams();
  const [marketData, setMarketData] = useState(null);
  const [networkMsg, setnetworkMsg] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [segmentId, setSegmentId] = useState("none");
  const [splitTotal, setSplitTotal] = useState(0);
  const [revenueSplitTotal, setRevenueSplitTotal] = useState(0);
  const [daySplit, setDaySplit] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [revenueDaySplit, setRevenueDaySplit] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [room, setRoom] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [ADR, setADR] = useState(0);
  const [countDOW, setCountDow] = useState([]);
  const [comparitiveDate, setComparitiveDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [dowTotal, setDowTotal] = useState(0);
  const [allocationDowTotal, setAllocationDowTotal] = useState(0);
  const [totalRoomsPerDow, setTotalRoomsPerDow] = useState(null);
  const [revenueDowTotal, setRevenueDowTotal] = useState(0);
  const [allocationDow, setAllocationDOW] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [revenueAllocationDow, setRevenueAllocationDow] = useState([
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
  ]);
  const [isCalc, setIsCalc] = useState(false);
  const [suggestions, setSuggestions] = useState({
    rooms: null,
    revenue: null,
    adr: null,
  });
  const [monthDays, setMonthDays] = useState(
    new Date(
      comparitiveDate.getFullYear(),
      comparitiveDate.getMonth() + 1,
      0
    ).getDate()
  );
  const [upArrow, setUpArrow] = useState(true);
  const [inputLabel, setInputLable] = useState("Market Segment");
  async function occurrenceDays(n, firstday) {
    let days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    let count = [];
    for (let i = 0; i < 7; i++) count[i] = 4;

    let pos = 0;
    for (let i = 0; i < 7; i++) {
      if (firstday == days[i]) {
        pos = i;
        break;
      }
    }

    let inc = n - 28;

    for (let i = pos; i < pos + inc; i++) {
      if (i > 6) count[i % 7] = 5;
      else count[i] = 5;
    }

    setCountDow([...count]);
  }
  async function GetMarket() {
    setIsLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/market-segments`,
    });
    const { data, error } = await get();
    if (data.Message !== "Market Segment Data not available") {
      setMarketData([
        ...data.map((val) => {
          return {
            ...val,
          };
        }),
      ]);
      setSegmentId(data[0]?.segmentId);
    } else {
      console.log(error);
      setMarketData([]);
      setSegmentId("");
    }
    setIsLoading(false);
  }

  useEffect(() => {
    console.log(segmentId);
  }, [segmentId]);

  function calculateTotalRevenue() {
    const result = revenueDaySplit.reduce((a, b) => (a = a + b), 0);
    if (result >= 99.9 && result <= 101) setRevenueSplitTotal(100);
    else setRevenueSplitTotal(result);
  }

  function calculateTotal() {
    const result = daySplit.reduce((a, b) => (a = a + b), 0);
    if (result >= 99.9 && result <= 101) setSplitTotal(100);
    else setSplitTotal(result);
  }
  function calculateADR() {
    if (room === 0 || revenue === 0) setADR(0);
    else {
      const result = revenue / room;
      setADR(Math.round((result + Number.EPSILON) * 100) / 100);
    }
  }
  function calculateDOW() {
    const firstDay = new Date(
      comparitiveDate.getFullYear(),
      comparitiveDate.getMonth(),
      1
    );
    const s = new Date(
      comparitiveDate.getFullYear(),
      comparitiveDate.getMonth() + 1,
      0
    );
    const FirstDay = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    occurrenceDays(s.getDate(), FirstDay[firstDay.getDay()]);
  }

  function totalDoW() {
    let totalDOW = 0;
    for (let j = 0; j < 7; j++)
      totalDOW = totalDOW + allocationDow[j] * countDOW[j];
    setDowTotal(totalDOW);
  }
  function totalAllocationDow() {
    const totalAllocationDOW = allocationDow?.reduce((a, b) => (a = a + b), 0);
    setAllocationDowTotal(totalAllocationDOW);
  }
  function totalRevenueDow() {
    let totalRevenueDOW = 0;
    for (let j = 0; j < 7; j++)
      totalRevenueDOW =
        totalRevenueDOW + Math.round((revenueDaySplit[j] * revenue) / 100);
    setRevenueDowTotal(totalRevenueDOW);
  }

  function showDropdown() {
    setUpArrow(!upArrow);
    setInputLable("Market Segment");
  }
  function handleSplitChange(index, e) {
    const newlist = [...daySplit];
    newlist[index] = e === "" || isNaN(e) ? 0 : e;
    setDaySplit([...newlist]);
  }

  function handleSplitChangeRevenue(index, e) {
    const newlist = [...revenueDaySplit];
    newlist[index] = e === "" || isNaN(e) ? 0 : e;
    setRevenueDaySplit([...newlist]);
  }

  function handleChange(index, value, segmentId) {
    setInputLable(value);
    setUpArrow(!upArrow);
    const list = [0, 0, 0, 0, 0, 0, 0];
    setDaySplit([...list]);
    setRevenueDaySplit([...list]);
    setRevenue(0);
    setRoom(0);
    setIsCalc(false);
  }
  async function calcDOW() {
    const list = [...allocationDow];
    for (let j = 0; j < 7; j++) {
      list[j] =
        Math.round((daySplit[j] * room) / 100) === 0 || countDOW[j] === 0
          ? 0
          : Math.round(Math.round((daySplit[j] * room) / 100) / countDOW[j]);
    }
    setAllocationDOW([...list]);
  }

  async function calcDOWRevenue() {
    const list = [...revenueAllocationDow];
    for (let j = 0; j < 7; j++) {
      list[j] =
        Math.round((revenueDaySplit[j] * revenue) / 100) === 0 ||
        countDOW[j] === 0
          ? 0
          : Math.round(
              Math.round((revenueDaySplit[j] * revenue) / 100) / countDOW[j]
            );
    }
    setRevenueAllocationDow([...list]);
  }

  function resetSplit() {
    setIsCalc(false);
    const list = [0, 0, 0, 0, 0, 0, 0];
    setDaySplit([...list]);
    setRevenueDaySplit([...list]);
    setRevenue(0);
    setRoom(0);
    setIsCalc(false);
    setMonthDays(
      new Date(
        comparitiveDate.getFullYear(),
        comparitiveDate.getMonth() + 1,
        0
      ).getDate()
    );
  }
  useEffect(() => {
    if (hotelId && token) {
      GetMarket();
    }
  }, [token, hotelId]);

  useEffect(() => {
    totalDoW();
    totalAllocationDow();
  }, [allocationDow]);

  useEffect(() => {
    if (room > 0 && !isCalc) calcDOW();
  }, [countDOW, room, daySplit]);

  useEffect(() => {
    if (revenue > 0 && !isCalc) calcDOWRevenue();
  }, [countDOW, revenue, revenueDaySplit]);

  useEffect(() => {
    calculateTotal();
    calculateTotalRevenue();
    totalDoW();
    totalRevenueDow();
  }, [daySplit, revenueDaySplit]);

  useEffect(() => {
    calculateDOW();
    totalAllocationDow();
    resetSplit();
    getDatabudget();
  }, [comparitiveDate, segmentId]);

  useEffect(() => {
    calculateADR();

    totalRevenueDow();
  }, [room, revenue]);

  function editRooms(e) {
    setRoom(e === "" ? 0 : e);
  }

  function editRevenue(e) {
    setRevenue(e === "" ? 0 : e);
  }

  async function getDatabudget() {
    setIsLoading(true);
    if (segmentId) {
      if (segmentId === "none") {
        setIsLoading(false);
        return;
      }
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/get-budget-split`,
      });

      const payload = {
        segmentId: segmentId,
        month: getISODate(comparitiveDate),
      };
      const { data } = await post(payload);

      if (data && Object.keys(data).length) {
        setSuggestions({
          rooms: data?.monthlyBudget?.rooms,
          revenue: data?.monthlyBudget?.revenue,
          adr: data?.monthlyBudget?.arr,
        });
        setDowTotal(data.roomsSug);
        setRoom(data?.monthlyBudget?.rooms);
        setADR(data?.monthlyBudget?.arr);
        setRevenue(data?.monthlyBudget?.revenue);
        if (data?.budgetSplit?.segmentId) {
          const allocationDow = [];
          allocationDow.push(data?.budgetSplit?.roomMonday);
          allocationDow.push(data?.budgetSplit?.roomTuesday);
          allocationDow.push(data?.budgetSplit?.roomWednesday);
          allocationDow.push(data?.budgetSplit?.roomThursday);
          allocationDow.push(data?.budgetSplit?.roomFriday);
          allocationDow.push(data?.budgetSplit?.roomSaturday);
          allocationDow.push(data?.budgetSplit?.roomSunday);
          setAllocationDOW(allocationDow);

          const revenueAllocationDow = [];
          revenueAllocationDow.push(data?.budgetSplit?.revenueMonday);
          revenueAllocationDow.push(data?.budgetSplit?.revenueTuesday);
          revenueAllocationDow.push(data?.budgetSplit?.revenueWednesday);
          revenueAllocationDow.push(data?.budgetSplit?.revenueThursday);
          revenueAllocationDow.push(data?.budgetSplit?.revenueFriday);
          revenueAllocationDow.push(data?.budgetSplit?.revenueSaturday);
          revenueAllocationDow.push(data?.budgetSplit?.revenueSunday);

          setRevenueAllocationDow(revenueAllocationDow);
          setIsCalc(true);
          splitDayPercentage(allocationDow);
          splitDayPercentageRevenue(revenueAllocationDow);
        }
      } else {
        setSuggestions({
          rooms: null,
          revenue: null,
          adr: null,
        });
        setOpen(true);
        setnetworkMsg(data?.messageToUser || "No Budget Allocation");
      }
    }
    setIsLoading(false);
  }
  function handleAllocationChange(index, e) {
    const list = [...allocationDow];
    list[index] = e === "" || isNaN(e) ? 0 : e;
    setAllocationDOW([...list]);
    const newlist = [...daySplit];
    let DOWTotal = 0;
    for (let j = 0; j < 7; j++) DOWTotal = DOWTotal + list[j] * countDOW[j];

    for (let j = 0; j < 7; j++) {
      newlist[j] =
        Math.round(
          ((list[j] * countDOW[j] * 100) / DOWTotal + Number.EPSILON) * 100
        ) / 100;
    }
    setDaySplit([...newlist]);
  }

  function splitDayPercentage(allocationDow) {
    const list = [...allocationDow];
    const newlist = [0, 0, 0, 0, 0, 0, 0];
    let DOWTotal = 0;
    for (let j = 0; j < 7; j++) DOWTotal = DOWTotal + list[j] * countDOW[j];

    for (let j = 0; j < 7; j++) {
      newlist[j] =
        Math.round(
          ((allocationDow[j] === "" || isNaN(allocationDow[j])
            ? 0
            : allocationDow[j] * countDOW[j] * 100) /
            DOWTotal +
            Number.EPSILON) *
            100
        ) / 100;
    }
    setDaySplit([...newlist]);
  }

  function splitDayPercentageRevenue(revenueAllocationDow) {
    const list = [...revenueAllocationDow];
    const newlist = [0, 0, 0, 0, 0, 0, 0];
    let DOWTotal = 0;
    for (let j = 0; j < 7; j++) DOWTotal = DOWTotal + list[j] * countDOW[j];

    for (let j = 0; j < 7; j++) {
      newlist[j] =
        Math.round(
          ((revenueAllocationDow[j] === "" || isNaN(revenueAllocationDow[j])
            ? 0
            : revenueAllocationDow[j] * countDOW[j] * 100) /
            DOWTotal +
            Number.EPSILON) *
            100
        ) / 100;
    }
    setRevenueDaySplit([...newlist]);
  }

  async function handleSaveData() {
    if (splitTotal === 100) {
      setBtnLoading(true);

      const PAYLOAD = {
        segmentId: segmentId,
        month: getISODate(comparitiveDate),
        rooms: room,
        adr: ADR,
        revenue,
        roomMonday: allocationDow[0],
        roomTuesday: allocationDow[1],
        roomWednesday: allocationDow[2],
        roomThursday: allocationDow[3],
        roomFriday: allocationDow[4],
        roomSaturday: allocationDow[5],
        roomSunday: allocationDow[6],
        revenueMonday: revenueAllocationDow[0],
        revenueTuesday: revenueAllocationDow[1],
        revenueWednesday: revenueAllocationDow[2],
        revenueThursday: revenueAllocationDow[3],
        revenueFriday: revenueAllocationDow[4],
        revenueSaturday: revenueAllocationDow[5],
        revenueSunday: revenueAllocationDow[6],
      };

      const { post } = await authFetch({
        path: `/hotel/${hotelId}/post-budget-split`,
      });
      const { data, error, response } = await post(PAYLOAD);
      if (response?.ok) {
        setOpen(true);
        setnetworkMsg("Budget Allocated Successfully");
        if (room !== totalRoomsPerDow) {
          setIsDialogOpen(true);
        }
      } else {
        console.log(error);
        setnetworkMsg(data?.messageToUser || "Something Went Wrong");
        setOpen(true);
      }
      setBtnLoading(false);
    } else {
      setOpen(true);
      setnetworkMsg("Split Total Percentage Should be 100");
    }
  }
  function totalRoomsPerDOW() {
    let total = 0;
    for (let i = 0; i < 7; i++) {
      total += allocationDow[i].toFixed(0) * countDOW[i];
      console.log(total);
    }
    setTotalRoomsPerDow(total);
  }
  useEffect(() => {
    totalRoomsPerDOW();
  }, [allocationDow]);
  return {
    hotelId,
    setRevenueDaySplit,
    revenueDaySplit,
    marketData,
    setDaySplit,
    daySplit,
    splitTotal,
    revenueSplitTotal,
    editRooms,
    editRevenue,
    ADR,
    room,
    revenue,
    occurrenceDays,
    comparitiveDate,
    setComparitiveDate,
    countDOW,
    dowTotal,
    revenueDowTotal,
    showDropdown,
    handleSplitChange,
    handleChange,
    upArrow,
    inputLabel,
    allocationDow,
    revenueAllocationDow,
    handleAllocationChange,
    isCalc,
    setIsCalc,
    setRoom,
    monthDays,
    handleSaveData,
    setSegmentId,
    segmentId,
    isLoading,
    networkMsg,
    open,
    setOpen,
    btnLoading,
    suggestions,
    totalRoomsPerDow,
    setTotalRoomsPerDow,
    handleSplitChangeRevenue,
    isDialogOpen,
    setIsDialogOpen,
  };
}
