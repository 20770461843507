import {
  Box,
  CircularProgress,
  Grid,
  Snackbar,
  SnackbarContent,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { AdjustRoomsModal } from "./AdjustRoomsModal";

export const RoomAdjustmentCalender = ({
  budgetInfo,
  GetBudget,
  isLoading,
  selectedMonthYear,
}) => {
  const [selectedMonth, setSelectedMonth] = useState([]);

  useEffect(() => {
    setSelectedMonth(
      budgetInfo?.find((item) => item.month === selectedMonthYear)
        ?.monthlyRoomAdjustment
    );
  }, [budgetInfo, selectedMonthYear]);

  return (
    <StyledStack direction="row">
      {isLoading ? (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box width="100%%">
          <Grid container justifyContent="space-around" className="weekdays">
            <Grid item>Monday</Grid>
            <Grid item>Tuesday</Grid>
            <Grid item>Wednesday</Grid>
            <Grid item>Thursday</Grid>
            <Grid item>Friday</Grid>
            <Grid item>Saturday</Grid>
            <Grid item>Sunday</Grid>
          </Grid>
          <Grid container>
            {Array(
              selectedMonth?.[0]?.date &&
                new Date(selectedMonth?.[0]?.date)?.getDay() === 0
                ? 6
                : parseInt(new Date(selectedMonth?.[0]?.date)?.getDay() - 1) ||
                    0
            )
              .fill()
              .map((data, idx) => (
                <Grid
                  item
                  key={idx}
                  width="calc(100% / 7 - 10px)"
                  margin="5px"
                ></Grid>
              ))}
            {selectedMonth?.map((item, index) => (
              <Grid item key={index} className="weekCells">
                <Stack>
                  <Typography paddingBottom="4px" fontWeight={500}>
                    {format(new Date(item.date), "dd MMM")}
                  </Typography>
                  <Typography fontSize="12px">
                    Rooms Available: {item?.roomsAvailable}
                  </Typography>
                  <Typography fontSize="12px">
                    Out of order: {item?.adjustment}
                  </Typography>
                  <Typography fontSize="12px">
                    Actual Sellable rooms : {item?.roomsToSell}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </StyledStack>
  );
};

const StyledStack = styled(Stack)(() => ({
  width: " 100%",
  background: "#ffffff",
  border: "none",
  padding: "20px",
  outline: "none",
  height: "calc(100vh - 250px)",
  borderRadius: "8px",
  overflowY: "scroll",
  overflowX: "hidden",
  gap: "24px",
  marginTop: "16px",
  boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",

  ".weekdays": {
    width: "100%",
    background: "rgb(48, 111, 188)",
    padding: "8px",
    borderRadius: "8px",
    color: "#fff",
    fontWeight: 600,
  },

  ".weekCells": {
    borderRadius: "8px",
    width: "calc(100% / 7 - 10px)",
    height: "120px",
    margin: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#c0cbe6",
    boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
  },

  ".sideDays": {
    width: "100%",
    padding: "4px",
    borderRadius: "8px",
    fontWeight: 600,
    color: "#000",
  },

  ".sideCells": {
    borderRadius: "8px",
    width: "calc(100% / 7 - 4px)",
    height: "40px",
    margin: "2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#fff",
    boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
  },
}));
