import React from "react";
import { styled } from "@mui/system";
import { Row, Table as T } from "../../sdk";
import { Td, Th } from "../Styles.js";

const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const H = styled(Th)`
  text-align: center;
  font: normal normal normal 16px/20px Roboto;
  letter-spacing: 0px;
  color: #000000;
  padding: 0px 0 16px 0;
`;

const Table = styled(T)`
  background-color: white;
  width: 32%;
  margin: 0px;
`;

export default function DOWTable({
  selectedDate,
  comparitiveDate,
  selectedData,
  comparitiveData,
}) {
  function DayNames(day) {
    switch (day) {
      case "monday":
        return "Mon";
        break;
      case "tuesday":
        return "Tue";
        break;
      case "wednesday":
        return "Wed";
        break;
      case "thursday":
        return "Thu";
        break;
      case "friday":
        return "Fri";
        break;
      case "saturday":
        return "Sat";
        break;
      default:
        return "Sun";
    }
  }
  return (
    Object.entries(selectedData).length !== 0 &&
    Object.entries(comparitiveData).length !== 0 && (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Table style={{ paddingTop: "10px" }}>
          <thead>
            <Row>
              <H>Occupancy%</H>
            </Row>
          </thead>
          <tbody>
            <Row>
              <Th>Day</Th>
              <Th>
                {Months[selectedDate?.getMonth()]} {selectedDate?.getFullYear()}
              </Th>
              <Th>
                {Months[comparitiveDate?.getMonth()]}{" "}
                {comparitiveDate?.getFullYear()}
              </Th>
              <Th>Variance</Th>
            </Row>
            {Object.keys(selectedData?.occupancy).map((key, index) => (
              <Row
                key={index}
                style={
                  index % 2 === 0
                    ? { backgroundColor: "rgba(48, 81, 221,0.09)" }
                    : {}
                }
              >
                <Td>{DayNames(key)}</Td>
                <Td>{parseFloat(selectedData?.occupancy[key]).toFixed(2)}%</Td>
                <Td>
                  {parseFloat(comparitiveData?.occupancy[key]).toFixed(2)}%
                </Td>
                <Td
                  style={{
                    color:
                      selectedData?.occupancy[key] -
                        comparitiveData?.occupancy[key] <
                      0
                        ? "red"
                        : "",
                  }}
                >
                  {selectedData?.occupancy[key] !== -1 &&
                  comparitiveData?.occupancy[key] !== -1
                    ? parseFloat(
                        selectedData?.occupancy[key] -
                          comparitiveData?.occupancy[key]
                      ).toFixed(2) + "%"
                    : "-"}
                </Td>
              </Row>
            ))}
          </tbody>
        </Table>
        <Table style={{ paddingTop: "10px" }}>
          <thead>
            <Row>
              <H>ADR</H>
            </Row>
          </thead>
          <tbody>
            <Row>
              <Th>Day</Th>
              <Th>
                {Months[selectedDate?.getMonth()]} {selectedDate?.getFullYear()}
              </Th>
              <Th>
                {Months[comparitiveDate?.getMonth()]}
                {comparitiveDate?.getFullYear()}
              </Th>
              <Th>Variance</Th>
            </Row>
            {Object.keys(selectedData?.occupancy).map((key, index) => (
              <Row
                key={index}
                style={
                  index % 2 === 0
                    ? { backgroundColor: "rgba(48, 81, 221,0.09)" }
                    : {}
                }
              >
                <Td>{DayNames(key)}</Td>
                <Td>{parseFloat(selectedData?.arr[key]).toFixed(2)}</Td>
                <Td>{parseFloat(comparitiveData?.arr[key]).toFixed(2)}</Td>
                <Td
                  style={{
                    color:
                      selectedData?.arr[key] - comparitiveData?.arr[key] < 0
                        ? "red"
                        : "",
                  }}
                >
                  {parseFloat(
                    selectedData?.arr[key] - comparitiveData?.arr[key]
                  ).toFixed(2)}
                </Td>
              </Row>
            ))}
          </tbody>
        </Table>
        <Table style={{ paddingTop: "10px" }}>
          <thead>
            <Row>
              <H>RevPAR</H>
            </Row>
          </thead>
          <tbody>
            <Row>
              <Th>Day</Th>
              <Th>
                {Months[selectedDate?.getMonth()]} {selectedDate?.getFullYear()}
              </Th>
              <Th>
                {Months[comparitiveDate?.getMonth()]}{" "}
                {comparitiveDate?.getFullYear()}
              </Th>
              <Th>Variance</Th>
            </Row>
            {Object.keys(selectedData?.occupancy).map((key, index) => (
              <Row
                key={index}
                style={
                  index % 2 === 0
                    ? { backgroundColor: "rgba(48, 81, 221,0.09)" }
                    : {}
                }
              >
                <Td>{DayNames(key)}</Td>
                <Td>{parseFloat(selectedData?.revpar[key]).toFixed(2)}</Td>
                <Td>{parseFloat(comparitiveData?.revpar[key]).toFixed(2)}</Td>
                <Td
                  style={{
                    color:
                      selectedData?.revpar[key] - comparitiveData?.revpar[key] <
                      0
                        ? "red"
                        : "",
                  }}
                >
                  {parseFloat(
                    selectedData?.revpar[key] - comparitiveData?.revpar[key]
                  ).toFixed(2)}
                </Td>
              </Row>
            ))}
          </tbody>
        </Table>
      </div>
    )
  );
}
