import { CalendarToday } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { useParams } from "react-router-dom";
import { styled } from "@mui/system";
import {
  ChooseBtn,
  Cross,
  CrossSmall,
  Filename,
  ModelHead,
  Section,
  Title,
  UploadSection,
} from "../../BusinessOnBooks/Styles";
import { useAuth } from "../../sdk";
function getModalStyle() {
  const top = 9;
  const right = 18;

  return {
    top: `${top}%`,
    right: `${right}%`,
    transform: `translate(-${top}, -${right})`,
  };
}

export const Done = styled(Button)`
  width: 80px;
  height: 24px;
  background: 0% 0% no-repeat padding-box;
  // border: 1px solid #130453;
  border-radius: 5px;
  font: normal normal bold 12px/15px Roboto;
  letter-spacing: 0px;
`;

export const CalenderIcon = styled(CalendarToday)`
  position: absolute;
  width: 20px;
  height: 22px;
  color: #306fbc;
  z-index: 101;
  right: 60px;

  margin-top: -3px;
  cursor: pointer;
`;
const ClassWrapper = styled(Box)(({ theme }) => ({
  ".paper": {
    position: "absolute",
    width: 300,
    height: 200,
    backgroundColor: "#fff",
    borderRadius: '8px',
    boxShadow: 5,
  },
}));
export function ModelBody({
  fileHandler,
  fileGetter,
  setOpenSelectBar,
  removeFile,
  filename,
  toggle,
  YEAR
}) {
  const [modalStyle] = useState(getModalStyle);
  return (
    <ClassWrapper>
      <Section style={modalStyle} className="paper">
        <ModelHead>
          <Title>Upload Budget Sheet</Title>
          <Cross onClick={() => setOpenSelectBar(false)}>&times;</Cross>
        </ModelHead>
        <Typography>Year : {YEAR}</Typography>
        <ChooseBtn
          style={toggle ? { display: "none" } : { display: "block" }}
          htmlFor="upload"
        >
          Choose File
          <input
            type="file"
            id="upload"
            value={""}
            onChange={(e) => fileGetter(e.target.files)}
            style={{ display: "none" }}
          />
        </ChooseBtn>

        <UploadSection
          style={toggle ? { display: "flex" } : { display: "none" }}
        >
          <img
            src="/assets/excel.svg"
            alt="Excel Icon"
            width="36px"
            height="36px"
          />
          <Filename>{filename}</Filename>
          <CrossSmall
            onClick={() => removeFile()}
            style={toggle ? { display: "block" } : { display: "none" }}
          >
            &times;
          </CrossSmall>
        </UploadSection>
        <Done
          disabled={!filename}
          next
          onClick={() => {
            fileHandler(YEAR);
          }}
          type="button"
          variant="contained"
          style={{ color: "white", backgroundColor: "#306fbc" }}
        >
          Upload
        </Done>
      </Section>
    </ClassWrapper>
  );
}
