import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Rating,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../sdk";
import { RatingContainer } from "./Styles";

export default function AvailableChannels({
  open,
  handleClose,
  values,
  selectedSources,
  allSources,
}) {
  const { authFetch } = useAuth();
  const [ischannelSearching, setIsChannelSearching] = useState(false);
  const [availableChannels, setAvailableChannels] = useState([]);
  const allSortedSources = allSources
    .filter((source) => source.sourceId !== 8)
    .sort((a, b) => a.sourceId - b.sourceId);
  const sortedSources = selectedSources.sort((a, b) => a.sourceId - b.sourceId);

  const getAvailableChannels = useCallback(async () => {
    try {
      setIsChannelSearching(true);
      const { post } = await authFetch({
        path: `/rate-metric-hotel-info?hotelRateMetricCode=${values?.rateMetricHotelCode}`,
      });
      const { data, response } = await post();
      if (data && response.status === 200) {
        setAvailableChannels(data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsChannelSearching(false);
    }
  }, [authFetch, values.rateMetricHotelCode]);

  useEffect(() => {
    getAvailableChannels();
  }, [getAvailableChannels]);

  const zippedArray = allSortedSources.map((source) => {
    const matchingSortedSource = sortedSources.find(
      (item) => item.id === source.id
    );
    const matchingAvailableChannel = availableChannels?.sources?.find(
      (item) => item.id === source.id
    );
    console.log({ matchingSortedSource, matchingAvailableChannel });
    return {
      sortedSource: matchingSortedSource
        ? {
            name: matchingSortedSource.name,
            isMobile: matchingSortedSource.isMobile,
          }
        : { name: "-", isMobile: "-" },
      availableChannel: matchingAvailableChannel
        ? {
            name: matchingAvailableChannel.name,
            isMobile: matchingAvailableChannel.isMobile,
          }
        : { name: "-", isMobile: "-" },
    };
  });

  return (
    <StyledChannelDialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className="content">Available Channels</DialogTitle>

      <CloseIcon className="closeButton" onClick={handleClose} />

      <DialogContent className="content">
        {ischannelSearching ? (
          <CircularProgress />
        ) : (
          <>
            <Stack className="styledStack" textAlign="left" paddingLeft={16}>
              <Stack width="100%" textAlign="left">
                <Typography className="hotelDetail">
                  <strong> Hotel name:</strong>{" "}
                  {availableChannels?.HotelDetails?.hotelname}
                </Typography>
                <Typography>
                  <strong> Address:</strong>{" "}
                  {availableChannels?.HotelDetails?.address}
                </Typography>
                <Typography>
                  <strong> City: </strong>{" "}
                  {availableChannels?.HotelDetails?.city}
                </Typography>
                <Typography>
                  <strong> Postal Code:</strong>{" "}
                  {availableChannels?.HotelDetails?.zip}
                </Typography>
              </Stack>
              <Stack width="100%" textAlign="left">
                <Typography>
                  <strong>State:</strong>{" "}
                  {availableChannels?.HotelDetails?.state}
                </Typography>
                <Typography>
                  <strong>Country:</strong>{" "}
                  {availableChannels?.HotelDetails?.country}
                </Typography>
                <Typography>
                  <strong>Coordinates: </strong>
                  {availableChannels?.HotelDetails?.lat !== undefined
                    ? Number(availableChannels.HotelDetails.lat).toFixed(6)
                    : ""}
                  ,{" "}
                  {availableChannels?.HotelDetails?.lng !== undefined
                    ? Number(availableChannels.HotelDetails.lng).toFixed(6)
                    : ""}
                </Typography>

                <Stack flexDirection="row">
                  <Typography>
                    <strong> Ratings:</strong>
                  </Typography>
                  <RatingContainer>
                    <Rating
                      label="Stars"
                      style={{
                        color: "#306FBC",
                        margin: "0 16px 0 16px",
                      }}
                      value={
                        parseInt(availableChannels?.HotelDetails?.starrating) ||
                        null
                      }
                    />
                  </RatingContainer>
                </Stack>
              </Stack>
            </Stack>

            <Stack className="styledStack">
              <Stack width="100%">
                <Typography
                  sx={{ textDecoration: "underline", padding: "10px" }}
                >
                  {`Selected Channels`}
                </Typography>
              </Stack>
              <Stack width="100%">
                <Typography
                  sx={{ textDecoration: "underline", padding: "10px" }}
                >
                  {`Available Channels`}
                </Typography>
              </Stack>
            </Stack>
            <Box width="100%" textAlign="center">
              {zippedArray?.map((item, index) =>
                !(
                  item.sortedSource.name === "-" &&
                  item.availableChannel.name === "-"
                ) ? (
                  <Stack key={index} className="styledStack">
                    <Stack width="100%">
                      <Typography>
                        {" "}
                        {item.sortedSource.name}
                        {item.sortedSource.name !== "-" && (
                          <>
                            {" "}
                            ({item.sortedSource.isMobile ? "Mobile" : "Desktop"}
                            )
                          </>
                        )}
                      </Typography>
                    </Stack>
                    <Stack width="100%">
                      <Typography>
                        {item.availableChannel.name}
                        {item.availableChannel.name !== "-" && (
                          <>
                            {" "}
                            (
                            {item.availableChannel.isMobile
                              ? "Mobile"
                              : "Desktop"}
                            )
                          </>
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                ) : null
              )}
            </Box>
          </>
        )}
      </DialogContent>
    </StyledChannelDialog>
  );
}

const StyledChannelDialog = styled(Dialog)(() => ({
  ".closeButton": {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
  },
  ".content": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  ".styledStack": {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
  },
}));
