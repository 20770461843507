import {
  MenuItem,
  Snackbar,
  SnackbarContent,
  Tooltip,
  Modal,
  Tab,
} from "@mui/material";
import {
  Publish,
  Schedule,
  ViewList,
  ViewModule,
  CalendarToday,
} from "@mui/icons-material";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { styled } from "@mui/system";
import { ErrorPage, LoadingPage, NoAccess, Nodata, useAuth } from "../sdk";
import { ModelBody as ARRModel } from "./ARRModel";
import { ModelBody } from "./BusinessModel";
import PricingRecommends from "./components/PricingRecommends";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { getISODate } from "../sdk/common-utils";

import {
  BusinessPage,
  Div2,
  GhostButton,
  Header,
  HeaderCard,
  ScheduleIconDisabled,
  Select,
  UploadBtn,
  ClassWrapper,
} from "./Styles";
import { PreciumDisabledPage } from "../sdk/components/PreciumDiabledPage";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Button = styled(GhostButton)`
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: red;
  box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
`;

export const CalenderIcon = styled(CalendarToday)`
  position: absolute;
  width: 20px;
  height: 22px;
  color: #306fbc;
  z-index: 101;
  margin-left: -32px;
  margin-top: 5px;
  cursor: pointer;
`;
const Business = memo(({ setPageHeader }) => {
  const [open, setOpen] = useState(false);
  const [allFilesForDay, setAllFilesForDay] = useState([]);
  const [haveData, setHaveData] = useState(true);
  const { DATE, FILEID } = useParams();
  const [toggle, setToggle] = useState(false);
  const [Loading, setLoading] = useState(false);
  const { permission, authFetch, token, currentHotel } = useAuth();
  const { hotelId } = useParams();
  const [view, setView] = useState("module");
  const maximumDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  const defaultValue = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  const handleChangeNew = (event, nextView) => {
    setView(nextView);
  };
  const [fileId, setFileId] = useState(() => {
    if (FILEID) {
      return parseInt(FILEID);
    } else {
      return 0;
    }
  });
  useEffect(() => {
    setPageHeader("Business on Books");
    return () => {
      setPageHeader("");
    };
  }, []);
  const [selectedDate, setSelectedDate] = useState(() => {
    if (!isNaN(new Date(DATE).getTime())) {
      const [year, mon, day] = DATE.split("-");

      if (year && mon && day) {
        if (day && day.length === 1) {
          const validDate = [year, mon, "0" + day].join("/");
          return new Date(validDate);
        } else {
          return new Date(DATE);
        }
      } else {
        return new Date();
      }
    } else {
      return new Date();
    }
  });

  const [selectedDateNew, setSelectedDateNew] = useState(defaultValue);

  const formatInputValue = () => {
    if (selectedDateNew) {
      const value =
        `${
          selectedDateNew.day < 10
            ? "0" + selectedDateNew.day
            : selectedDateNew.day
        }` +
        "/" +
        `${
          selectedDateNew.month < 10
            ? "0" + selectedDateNew.month
            : selectedDateNew.month
        }` +
        "/" +
        `${selectedDateNew.year}`;
      return value;
    } else {
      if (!isNaN(new Date(DATE).getTime())) {
        const [year, mon, day] = DATE.split("-");

        if (year && mon && day) {
          if (day && day.length === 1) {
            const validDate = "0" + day + "/" + mon + "/" + year;
            return validDate;
          } else {
            const validDate = day + "/" + mon + "/" + year;
            return validDate;
          }
        }
      }
    }
  };

  useEffect(() => {
    if (selectedDateNew) {
      setSelectedDate(
        new Date(
          [
            selectedDateNew.year,
            selectedDateNew.month,
            selectedDateNew.day.length === 1
              ? "0" + selectedDateNew.day
              : selectedDateNew.day,
          ].join("/")
        )
      );
    }
  }, [selectedDateNew]);

  const history = useHistory();
  let forecastUpload = null,
    forecastView = null,
    actualsUpload = null;

  useEffect(() => {
    if (token && hotelId) {
      window.scrollTo(0, 0);
    } else {
      return;
    }
  }, [token, hotelId]);

  useEffect(() => {
    history.replace(
      `/hotel/${hotelId}/business_on_books/${
        getISODate(selectedDate).split("T")[0]
      }/${fileId}`
    );
  }, [selectedDate, fileId]);

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "forecastUpload") {
        forecastUpload = permission[key];
      }
      if (permission[key].name === "forecastView") {
        forecastView = permission[key];
      }
      if (permission[key].name === "actualsUpload") {
        actualsUpload = permission[key];
      }
    }
  }

  const handleChange = (newValue) => {
    setFileId(newValue);
  };
  async function fetchAllFilesForDay() {
    setLoading(true);
    setHaveData(true);

    let year = selectedDate.getFullYear();
    let month = selectedDate.getMonth() + 1;
    let dt = selectedDate.getDate();
    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    const yyyymmdd = year + "-" + month + "-" + dt;
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/bookings/file/${yyyymmdd}`,
    });
    const { data } = await get();

    if (data != null && data.length !== 0) {
      setAllFilesForDay(
        [...data]
          .sort((a, b) => new Date(b.uploadedOn) - new Date(a.uploadedOn))
          .map((file) => {
            let hrs = new Date(new Date(file.uploadedOn).getTime()).getHours();
            let mins = new Date(
              new Date(file.uploadedOn).getTime()
            ).getMinutes();
            if (mins < 10) {
              mins = "0" + mins;
            }
            if (hrs / 12 > 1) {
              return {
                ...file,
                time: (hrs % 12) + ":" + mins + " pm ",
              };
            } else if (hrs / 12 === 1) {
              return {
                ...file,
                time: hrs + ":" + mins + " pm ",
              };
            } else {
              return {
                ...file,
                time: hrs + ":" + mins + " am ",
              };
            }
          })
      );

      if (!data.some((file) => file.id === parseInt(FILEID))) {
        setFileId(
          [...data].sort(
            (a, b) => new Date(b.uploadedOn) - new Date(a.uploadedOn)
          )[0].id
        );
      } else {
        setFileId(parseInt(FILEID));
      }
    } else {
      setHaveData(false);
      setBooking([]);
      setFileId(0);
    }
    setLoading(false);
  }

  async function fetchAllFilesForDayOnUpload() {
    if (token) {
      setLoading(true);
      setHaveData(true);

      let year = selectedDate.getFullYear();
      let month = selectedDate.getMonth() + 1;
      let dt = selectedDate.getDate();
      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }

      const yyyymmdd = year + "-" + month + "-" + dt;
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/bookings/file/${yyyymmdd}`,
      });
      const { data } = await get();

      if (data != null && data.length !== 0) {
        setAllFilesForDay(
          [...data]
            .sort((a, b) => new Date(b.uploadedOn) - new Date(a.uploadedOn))
            .map((file) => {
              let hrs = new Date(
                new Date(file.uploadedOn).getTime()
              ).getHours();

              let mins = new Date(
                new Date(file.uploadedOn).getTime()
              ).getMinutes();
              if (mins < 10) {
                mins = "0" + mins;
              }
              if (hrs / 12 > 1) {
                return {
                  ...file,
                  time: (hrs % 12) + ":" + mins + " pm ",
                };
              } else if (hrs / 12 === 1) {
                return {
                  ...file,
                  time: hrs + ":" + mins + " pm ",
                };
              } else {
                return {
                  ...file,
                  time: hrs + ":" + mins + " am ",
                };
              }
            })
        );

        setFileId(
          [...data].sort(
            (a, b) => new Date(b.uploadedOn) - new Date(a.uploadedOn)
          )[0].id
        );
      } else {
        setHaveData(false);
        setBooking([]);
        setFileId(0);
      }
      setLoading(false);
    }
  }
  const handleCloseStatus = () => {
    setOpen(false);
  };
  const [openSelectbar, setOpenSelectBar] = useState(false);
  const [openSelectbarARR, setOpenSelectBarARR] = useState(false);
  const [networkMsg, setnetworkMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [booking, setBooking] = useState([]);
  const [dateForARR, setDateForARR] = useState(new Date());
  const [filename, setFileName] = useState("");
  const formdata = useRef(new FormData());
  const [uploadActualDate, setUploadActualDate] = useState(new Date());
  const [Error, setError] = useState(false);
  const [dateToggle, setDateToggle] = useState(1);

  const renderCustomInput = ({ ref }) => (
    <>
      <input
        readOnly
        ref={ref}
        value={formatInputValue()}
        style={{
          textAlign: "left",
          fontSize: "14px",
          borderRadius: "10px",
          color: "#000000",
          outline: "none",
          height: "30px",
          border: "none",
          font: "bold 16px / 20px Roboto",
          cursor: "pointer",
          width: "140px",
          paddingLeft: "14px",
        }}
      ></input>
    </>
  );

  useEffect(() => {
    if (token) refreshData();
  }, [token, fileId, hotelId]);

  useEffect(() => {
    if (token) {
      fetchAllFilesForDay();
    }
  }, [token, selectedDate, hotelId]);

  async function refreshData() {
    if (fileId) {
      setLoading(true);
      setHaveData(true);
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/bookings/by-file/${fileId}`,
      });
      const { data } = await get();
      const bookingData = data.sort((a, b) => a.date - b.date);

      var currMonth = new Date(bookingData[0].date).getMonth();
      var bookingArray = [];
      var i = 0;
      bookingArray.push([]);
      let totalRooms = 0;
      let totalArr = 0;
      let noRooms = true;
      bookingData.forEach((element, index) => {
        if (new Date(element.date).getMonth() === currMonth) {
          bookingArray[i].push(element);
          totalRooms =
            totalRooms +
            (element.noOfRoomsBooked === -1 ? 0 : element.noOfRoomsBooked);
          totalArr =
            totalArr +
            (element.averageRoomRate === -1 || element.noOfRoomsBooked === -1
              ? 0
              : element.averageRoomRate * element.noOfRoomsBooked);
          if (element.noOfRoomsBooked !== -1) {
            noRooms = false;
          }
          if (bookingData.length - 1 === index) {
            bookingArray[i].push({
              totalRooms: noRooms === true ? -1 : totalRooms,
              totalArr: noRooms === true ? -1 : totalArr,
            });
          }
        } else {
          bookingArray[i].push({
            totalRooms: noRooms === true ? -1 : totalRooms,
            totalArr: noRooms === true ? -1 : totalArr,
          });

          currMonth = new Date(element.date).getMonth();
          bookingArray.push([]);
          i++;
          totalRooms = 0;
          totalArr = 0;
          totalRooms =
            totalRooms +
            (element.noOfRoomsBooked === -1 ? 0 : element.noOfRoomsBooked);
          totalArr =
            totalArr +
            (element.averageRoomRate === -1 || element.noOfRoomsBooked === -1
              ? 0
              : element.averageRoomRate * element.noOfRoomsBooked);
          bookingArray[i].push(element);
          if (bookingData.length - 1 === index) {
            bookingArray[i].push({
              totalRooms: noRooms === true ? -1 : totalRooms,
              totalArr: noRooms === true ? -1 : totalArr,
            });
          }
        }
      });
      setBooking(bookingArray);
      setLoading(false);
    } else {
      setBooking([]);
    }
  }

  const fileGetter = (file) => {
    setFileName(file[0].name);

    handleToggle();
    formdata.current.append("file", file[0]);
    formdata.current.append("description", "XLSX File");
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  async function fileHandler() {
    setOpenSelectBar(false);
    let year = uploadActualDate.getFullYear();
    let month = uploadActualDate.getMonth() + 1;
    let dt = uploadActualDate.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    formdata.current.append("uploadedFor", year + "-" + month + "-" + dt);

    const { post } = await authFetch({
      path: `/hotel/${hotelId}/file`,
    });

    const { data, response, error } = await post(formdata.current);
    if (
      data &&
      (response.status === 500 ||
        response.status === 400 ||
        response.status === 501)
      // (Object.keys(data).length === 1 || Object.keys(data).length === 0)
    ) {
      setnetworkMsg(data?.messageToUser || "Invalid File");
      setOpen(true);
      setError(false);
    } else if (response.status === 200) {
      setnetworkMsg("Upload Successful");
      setOpen(true);
      fetchAllFilesForDayOnUpload();
      setToggle(false);
      setError(false);
    } else {
      console.log(error);
    }
    formdata.current = new FormData();
  }

  console.log({ errorMsg });
  const removeFile = () => {
    setFileName("");
    formdata.current.delete("file");
    formdata.current.delete("description");
    formdata.current.delete("uploadedFor");
    setToggle(!toggle);
  };

  const StyledCalenderWrapper = styled(Box)((theme) => ({
    borderRadius: "8px",
    boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
    "&:hover>*": {
      color: "#ffffff",
      ">input": {
        backgroundColor: "#306fbc",
        color: "#ffffff !important",
      },
    },
  }));

  const inputRef = useRef();
  return currentHotel?.isPreciumEnabled ? (
    !Error && forecastView ? (
      <ClassWrapper>
        <BusinessPage>
          <Header>
            <HeaderCard>
              <label>
                <StyledCalenderWrapper>
                  <DatePicker
                    ref={inputRef}
                    value={selectedDateNew}
                    onChange={setSelectedDateNew}
                    renderInput={renderCustomInput}
                    calendarClassName="myCustomCalendar"
                    maximumDate={maximumDate}
                  ></DatePicker>
                  <CalenderIcon />
                </StyledCalenderWrapper>
              </label>
            </HeaderCard>

            <HeaderCard
              style={{
                boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                borderRadius: "8px",
                height: "32px",
                marginLeft: "10px",
                marginRight: "10px",
                flex: 0.5,
              }}
            >
              {booking.length > 0 ? (
                <Select
                  disableUnderline
                  variant="standard"
                  IconComponent="none"
                  value={fileId}
                  onChange={(e) => handleChange(e.target.value)}
                >
                  {allFilesForDay.map((file, idx) => (
                    <MenuItem key={idx} value={file.id}>
                      <Tab
                        label={file.time}
                        value={file.id}
                        {...a11yProps(file.id)}
                      />
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <div style={{ width: "56px" }}></div>
              )}
              {booking.length > 0 ? (
                <Schedule
                  style={{
                    width: "20px",
                    height: "20px",
                    margin: "-20px",
                    color: "306FBC",
                  }}
                />
              ) : (
                <ScheduleIconDisabled
                  style={{
                    color: "#6f6f6f",
                    width: "20px",
                    height: "20px",
                  }}
                />
              )}
            </HeaderCard>

            <HeaderCard>
              {actualsUpload ? (
                <GhostButton
                  style={{
                    boxShadow: "rgb(48 111 188 / 20%) 0px 4px 4px",
                    borderRadius: "8px",
                    backgroundColor: "rgb(48, 111, 188)",
                    color: "rgb(255, 255, 255)",
                    textTransform: "none",
                  }}
                  next
                  onClick={() => setOpenSelectBarARR(true)}
                >
                  Last Night Actuals
                </GhostButton>
              ) : (
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title={"Access Denied"}
                  placement="right-end"
                  arrow
                >
                  <GhostButton
                    next
                    style={{
                      opacity: "0.38",
                      cursor: "not-allowed",
                      boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                      borderRadius: "8px",
                      textTransform: "none",
                    }}
                  >
                    Last Night Actuals
                  </GhostButton>
                </Tooltip>
              )}
            </HeaderCard>

            <HeaderCard>
              {forecastUpload ? (
                <UploadBtn
                  style={{
                    boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                    borderRadius: "8px",
                    background: "#306FBC",
                    color: "#FFFFFF",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setOpenSelectBar(true);
                    setUploadActualDate(new Date());
                    setToggle(false);
                  }}
                >
                  Upload Forecast
                  <Publish />
                </UploadBtn>
              ) : (
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title={"Access Denied"}
                  placement="right-end"
                  arrow
                >
                  <UploadBtn
                    next
                    style={{
                      opacity: "0.38",
                      cursor: "not-allowed",
                      boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                      borderRadius: "8px",
                      background: "#306FBC",
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}
                  >
                    Upload Forecast
                    <Publish />
                  </UploadBtn>
                </Tooltip>
              )}
            </HeaderCard>
            <HeaderCard>
              <ToggleButtonGroup
                value={view}
                exclusive
                onChange={handleChangeNew}
                color="primary"
                style={{
                  marginTop: "0px",
                  marginLeft: "4px",
                  height: "32px",
                  boxShadow: "0px 4px 4px rgba(3, 4, 94, 0.2)",
                  borderRadius: "8px",
                }}
              >
                <ToggleButton value="list" aria-label="list">
                  <ViewList style={{ color: "#306fbc" }} />
                </ToggleButton>
                <ToggleButton value="module" aria-label="module">
                  <ViewModule style={{ color: "#306fbc" }} />
                </ToggleButton>
              </ToggleButtonGroup>
            </HeaderCard>
          </Header>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseStatus}
          >
            {networkMsg !== "Upload Successful" ? (
              <SnackbarContent
                style={{ backgroundColor: "#CA3433" }}
                message={networkMsg}
              />
            ) : (
              <SnackbarContent
                style={{ backgroundColor: "#228b22" }}
                message={networkMsg}
              />
            )}
          </Snackbar>
          <Modal
            open={openSelectbarARR}
            onClose={() => setOpenSelectBarARR(false)}
          >
            <div>
              <ARRModel
                fileId={fileId}
                setOpenSelectBar={setOpenSelectBarARR}
                token={token}
                dateForARR={dateForARR}
                setDateForARR={setDateForARR}
                errorMsg={errorMsg}
                setErrorMsg={setErrorMsg}
                r
              />
            </div>
          </Modal>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={errorMsg}
            autoHideDuration={3000}
            onClose={() => setErrorMsg("")}
          >
            {errorMsg && (
              <SnackbarContent
                style={{
                  backgroundColor: "#CA3433",
                }}
                message={errorMsg}
              />
            )}
          </Snackbar>
          <Modal open={openSelectbar} onClose={() => setOpenSelectBar(false)}>
            <div>
              <ModelBody
                fileHandler={fileHandler}
                setUploadActualDate={setUploadActualDate}
                uploadActualDate={uploadActualDate}
                fileGetter={fileGetter}
                setOpenSelectBar={setOpenSelectBar}
                removeFile={removeFile}
                filename={filename}
                handleToggle={handleToggle}
                toggle={toggle}
                setDateToggle={setDateToggle}
                dateToggle={dateToggle}
              />
            </div>
          </Modal>

          {!Loading ? (
            haveData ? (
              <>
                <Div2>
                  <PricingRecommends
                    bookings={booking}
                    refreshData={refreshData}
                    fileId={fileId}
                    handleChange={handleChange}
                    allFilesForDay={allFilesForDay}
                    fetchAllFilesForDay={fetchAllFilesForDay}
                    toggle={toggle}
                    selectedDate={selectedDate}
                    view={view}
                    Loading={Loading}
                  />
                </Div2>
              </>
            ) : (
              <Nodata />
            )
          ) : (
            <LoadingPage />
          )}
        </BusinessPage>
      </ClassWrapper>
    ) : !forecastView ? (
      <NoAccess />
    ) : (
      <ErrorPage />
    )
  ) : (
    <PreciumDisabledPage />
  );
});
export default Business;
