import { styled } from "@mui/system";
import { Box, Tooltip } from "@mui/material";
import { CallReceived } from "@mui/icons-material";
import React, { useCallback, useState } from "react";
import {
  Bar as BarNew,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line as LineNew,
  ResponsiveContainer,
  Tooltip as TooltipNew,
  XAxis,
  YAxis,
} from "recharts";

function getModalStyle() {
  const top = 60;
  const left = 60;

  return {
    top: `80px`,
    left: `80px`,
    transform: `translate(-${top}, -${left})`,
  };
}
const ClassWrapper = styled(Box)(({ theme }) => ({
  ".paper": {
    position: "absolute",
    backgroundColor: "#fff",
    boxShadow: 5,
  },
}));

const Section = styled(Box)`
  height: 85%;
  width: 92%;
  padding: 30px;
`;
const Cross = styled(CallReceived)`
  background-color: #e0e0e0;

  :hover {
    cursor: pointer;
  }
`;

export function ModelBody({
  data,
  options,
  setOpenSelectBar,
  datasevenDays,
  pricesevenDays,
  check,
  setCheck,
  datafourteenDays,
  pricefourteenDays,
  dataTwentyOneDays,
  priceTwentyOneDays,
  comp1,
  priceColorPalette,
  hotelName,
  opacityThirtyDays,
  setOpacityThirtyDays,
  dataKeysThirtyDays,
  setDataKeysThirtyDays,
}) {
  const [modalStyle] = useState(getModalStyle);
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleColorChange = useCallback(
    (entry, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  const handleColor = useCallback(
    (entry, index) => {
      setActiveIndex(-1);
    },
    [setActiveIndex]
  );
  const handleClickChange = useCallback(
    (o) => {
      const { dataKey } = o;
      if (
        dataKeysThirtyDays[dataKey] === dataKey &&
        opacityThirtyDays[dataKey] === 1
      ) {
        setOpacityThirtyDays({ ...opacityThirtyDays, [dataKey]: 0 });
        setDataKeysThirtyDays({
          ...dataKeysThirtyDays,
          [dataKey]: dataKeysThirtyDays[dataKey],
        });
      } else {
        setOpacityThirtyDays({ ...opacityThirtyDays, [dataKey]: 1 });
        setDataKeysThirtyDays({
          ...dataKeysThirtyDays,
          [dataKey]: dataKeysThirtyDays[dataKey],
        });
      }
    },
    [
      opacityThirtyDays,
      setOpacityThirtyDays,
      setDataKeysThirtyDays,
      dataKeysThirtyDays,
    ]
  );

  return (
    <ClassWrapper>
      <Section style={modalStyle} className="paper">
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <Tooltip
            disableFocusListener
            interactive
            disableTouchListener
            arrow
            title={"Close"}
          >
            <Cross
              onClick={() => {
                setOpenSelectBar(false);
              }}
            >
              &#x274C;
            </Cross>
          </Tooltip>
        </Box>

        <ResponsiveContainer width="100%" height="95%">
          <ComposedChart data={data}>
            <CartesianGrid stroke="#FFFFFF" />
            <XAxis
              dataKey="date"
              style={{ fontFamily: "Roboto", fontSize: "10px" }}
            />
            <YAxis
              yAxisId="left"
              orientation="left"
              stroke=" #777777"
              style={{ fontFamily: "Roboto", fontSize: "13px" }}
              tickFormatter={(tick) => {
                return `${tick}%`;
              }}
              type="number"
              domain={[0, 100]}
              label={{
                value: "Occupancy%",
                angle: -90,
                position: "insideLeft",
                fontFamily: "Roboto",
                fontSize: "14px",
                color: "#777777",
              }}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              stroke=" #777777"
              style={{ fontFamily: "Roboto", fontSize: "13px" }}
            />
            <TooltipNew
              formatter={(label, name) =>
                label === 0 && name !== `${hotelName}` + " (Occ%)"
                  ? "Sold Out"
                  : label <= 110
                  ? label + "%"
                  : label
              }
              wrapperStyle={{ fontSize: "14px", fontFamily: "Roboto" }}
            />
            <Legend onClick={handleClickChange} />
            <BarNew
              yAxisId="left"
              dataKey={`${hotelName}` + " (Occ%)"}
              fill={
                opacityThirtyDays[`${hotelName}` + " (Occ%)"] === 1
                  ? "#AAAAAA"
                  : "rgba(220, 220, 220)"
              }
              fontColor="#000000"
              opacity={
                opacityThirtyDays[`${hotelName}` + " (Occ%)"] === 1 ? 1 : 0
              }
              onMouseOver={handleColorChange}
              onMouseOut={handleColor}
            >
              {data.map((entry, index) => (
                <Cell
                  cursor="pointer"
                  fill={index === activeIndex ? "#306FBC" : "#EBEBEB"}
                  key={`cell-${index}`}
                />
              ))}
            </BarNew>

            {comp1.map((res, idx1) => (
              <React.Fragment key={idx1}>
                <LineNew
                  yAxisId="right"
                  type="monotone"
                  dataKey={res?.name}
                  stroke={
                    opacityThirtyDays[res?.name] === 1
                      ? priceColorPalette[idx1]
                      : "rgba(220, 220, 220)"
                  }
                  activeDot={{ r: 4 }}
                  strokeWidth={2}
                  dot={{ r: 0 }}
                  strokeOpacity={opacityThirtyDays[res?.name]}
                />
              </React.Fragment>
            ))}
            <LineNew
              yAxisId="right"
              type="monotone"
              dataKey={`${hotelName}` + " (Recommended Price)"}
              stroke={
                opacityThirtyDays[`${hotelName}` + " (Recommended Price)"] === 1
                  ? priceColorPalette[priceColorPalette.length - 1]
                  : "rgba(220, 220, 220, 0.5)"
              }
              activeDot={{ r: 4 }}
              strokeWidth={2}
              dot={{ r: 0 }}
              strokeOpacity={
                opacityThirtyDays[`${hotelName}` + " (Recommended Price)"]
              }
            />
            <LineNew
              yAxisId="right"
              type="monotone"
              dataKey={`${hotelName}` + " (Current Price)"}
              stroke={
                opacityThirtyDays[`${hotelName}` + " (Current Price)"] === 1
                  ? "#808080"
                  : "rgba(220, 220, 220)"
              }
              activeDot={{ r: 4 }}
              strokeWidth={2}
              dot={{ r: 0 }}
              strokeOpacity={
                opacityThirtyDays[`${hotelName}` + " (Current Price)"]
              }
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Section>
    </ClassWrapper>
  );
}
