import { useEffect, useState } from "react";
import { useAuth } from "../../sdk";

const EMPTY_ROW = {
  min: 0,
  max: 100,
  mwValue: null,
  weValue: null,
};
export function usePace(hotelId, copyFromHotelId, PC) {
  const [paces, setPaces] = useState([]);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [editPace, setEditPace] = useState([]);
  const [checkPaceValidation, setCheckPaceValidation] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const saveButtonClicked = false;
  const [open, setOpen] = useState(false);
  const [Loading, setLoading] = useState(false);

  const handleCloseStatus = () => {
    setOpen(false);
  };

  const [networkMsg, setnetworkMsg] = useState(null);
  const { token, authFetch } = useAuth();
  useEffect(() => {
    if (!token) return;
    refreshPaces();
  }, [token, hotelId]);
  useEffect(() => {
    if (isInEditMode) {
      const allValidEntries = paces.every(isValid);
      if (allValidEntries) setCheckPaceValidation(false);
      else setCheckPaceValidation(true);
      if (allValidEntries) {
        if (paces.length && paces[paces.length - 1].max < 100) {
          setPaces([
            ...paces,
            {
              min: paces.length ? paces[paces.length - 1].max + 1 : 0,
              max: 100,
              mwValue: null,
              weValue: null,
            },
          ]);
        } else if (paces.length === 0) {
          setPaces([
            ...paces,
            {
              min: 0,
              max: 100,
              mwValue: null,
              weValue: null,
            },
          ]);
        }
        setEditPace([...editPace, (editPace[paces.length] = true)]);
      }
    } else if (isSaved) {
      updatePaces();
    } else if (isCancelled) {
      refreshPaces();
    }
  }, [paces, isInEditMode]);
  useEffect(() => {
    if (copyFromHotelId === null && !PC) return;
    if (PC) refreshPaces(copyFromHotelId);
  }, [PC]);

  function isValid(lT) {
    return (
      lT.min !== null &&
      lT.min !== "" &&
      lT.max !== null &&
      lT.max !== "" &&
      lT.mwValue !== null &&
      lT.mwValue !== "" &&
      lT.weValue !== null &&
      lT.weValue !== "" &&
      lT.max >= lT.min
    );
  }
  async function refreshPaces(anotherHotelId = false) {
    const hotelIdToUse = anotherHotelId ? anotherHotelId : hotelId;
    setLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelIdToUse}/pace-score`,
    });
    const { data, error } = await get();
    if (error) {
      setnetworkMsg("Can't Fetch Pace");
      setOpen(true);
      console.log(error);
    }
    setIsCancelled(false);
    if (data) {
      setPaces(data.sort((a, b) => a.min - b.min));
      setEditPace([...new Array(data?.length)].map(() => false));
      if (data.length === 0) {
        setPaces([EMPTY_ROW]);
        setEditPace([false]);
        setIsInEditMode(false);
      }
    } else {
      setPaces([]);
    }
    if (anotherHotelId) {
      setIsInEditMode(true);
    }
    setLoading(false);
  }
  async function updatePaces() {
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/pace-score/all`,
    });
    const { error, response, data } = await post(paces.filter(isValid));
    if (!response?.ok) {
      setnetworkMsg(data?.messageToUser || "Can't Update Pace");
      setOpen(true);
      console.log(error);
    }
    setIsSaved(false);
    refreshPaces();
  }
  function changeHandler(index, key, value) {
    setPaces((prevState) => {
      return prevState.map((row, idx) =>
        idx === index
          ? {
              ...row,
              [key]: value,
            }
          : row
      );
    });
  }

  const onRemoveRowHandler = (index) => {
    setPaces((prevState) => {
      return prevState.filter((_, idx) => idx !== index);
    });
    setEditPace((prevState) => {
      return prevState.filter((_, idx) => idx !== index);
    });
  };

  const onAddRowHandler = (index) => {
    setPaces([
      ...paces.filter((_, idx) => idx <= index),
      {
        min: "",
        max: "",
        mwValue: "",
        weValue: "",
      },
      ...paces.filter((_, idx) => idx > index),
    ]);

    setEditPace([
      ...editPace.slice(0, index + 1),
      true,
      ...editPace.slice(index + 1),
    ]);
    setIsInEditMode(true);
  };
  const handleEditPaceChange = (index) => {
    const result = editPace.map((data, pos) => {
      if (pos === index) {
        return (editPace[index] = !editPace[index]);
      } else {
        return editPace[pos];
      }
    });
    const checkEditMode = result.every((d) => d === false);
    if (checkEditMode) setIsInEditMode(false);
    else setIsInEditMode(true);
    setEditPace(result);
  };
  return {
    paces,
    pIsInEditMode: isInEditMode,
    setPIsInEditMode: setIsInEditMode,
    pIsCancelled: isCancelled,
    pSetIsCancelled: setIsCancelled,
    pIsSaved: isSaved,
    pSetIsSaved: setIsSaved,
    pChangeHandler: changeHandler,
    popen: open,
    phandleCloseStatus: handleCloseStatus,
    pnetworkMsg: networkMsg,
    ponRemoveRowHandler: onRemoveRowHandler,
    ponAddRowHandler: onAddRowHandler,
    pLoading: Loading,
    editPace,
    handleEditPaceChange: handleEditPaceChange,
    checkPaceValidation,
  };
}
