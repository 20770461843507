import { ArrowRight } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { memo } from "react";
import { useParams } from "react-router";

const DatePopOver = memo(({ eventArray }) => {
  const { hotelId } = useParams();
  return (
    <>
      {eventArray.map((data, idx) => (
        <Box key={data}>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginRight: "0px",
            }}
          >
            <ArrowRight
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                color: "#fffff",

                marginLeft: "-8px",
              }}
            />
            <Box
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                color: "#ffffff",
                fontSize: "14px",
              }}
            >
              {data}
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
});
export default DatePopOver;
