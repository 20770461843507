import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getISODate, useAuth, usePrevious } from "../../sdk";
const EMPTY_ROW = {
  startDate: new Date(),
  endDate: new Date(new Date().setDate(new Date().getDate() + 1)),
  season: null,
};

export function useSeasonDates(copyFromHotelId, Dates, openSelectbar) {
  const [seasonDetails, setSeasonDetails] = useState([]);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [open, setOpen] = useState(false);
  const [Loading, setLoading] = useState(false);

  const { hotelId } = useParams();
  const { token, authFetch } = useAuth();
  const saveClicked = usePrevious(isInEditMode);
  const [Error, setError] = useState(false);

  const [networkMsg, setnetworkMsg] = useState(null);

  useEffect(() => {
    if (!token) return;
    refreshSeasonDetails();
  }, [token, hotelId]);

  useEffect(() => {
    if (isInEditMode) {
      const allValidEntries = seasonDetails.every(isValid);
      if (allValidEntries) {
        setSeasonDetails((prevState) =>
          prevState.length === 0
            ? [EMPTY_ROW]
            : [
                ...prevState,
                {
                  ...EMPTY_ROW,
                  startDate: new Date(
                    new Date(prevState[prevState.length - 1].endDate).setDate(
                      new Date(
                        prevState[prevState.length - 1].endDate
                      ).getDate() + 1
                    )
                  ),
                  endDate: new Date(
                    new Date(prevState[prevState.length - 1].endDate).setDate(
                      new Date(
                        prevState[prevState.length - 1].endDate
                      ).getDate() + 2
                    )
                  ),
                },
              ]
        );
      }
    } else if (isSaved) {
      updateSeasonDetails();
    } else if (isCancelled) {
      refreshSeasonDetails();
    }
  }, [seasonDetails, isInEditMode]);

  useEffect(() => {
    if (copyFromHotelId === null && !Dates) return;
    if (!openSelectbar) refreshSeasonDetails(copyFromHotelId);
  }, [Dates]);

  function isValid(seasonDetails) {
    return (
      seasonDetails.startDate !== null &&
      seasonDetails.endDate !== null &&
      seasonDetails.season !== null &&
      seasonDetails.endDate > seasonDetails.startDate
    );
  }

  async function updateSeasonDetails() {
    const seasons = seasonDetails.filter(isValid).map((row) => {
      const season = row.season.toLowerCase();
      return {
        ...row,
        startDate: getISODate(row.startDate),
        endDate: getISODate(row.endDate),
        season,
      };
    });
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/season-setup/all`,
    });
    const { response, data } = await post(seasons);
    if (!response?.ok) {
      setnetworkMsg(data?.messageToUser || "can't update Season Setup");
      setOpen(true);
    }
    setIsSaved(false);
    refreshSeasonDetails();
  }

  async function refreshSeasonDetails(anotherHotelId = false) {
    const hotelIdToUse = anotherHotelId ? anotherHotelId : hotelId;
    setLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelIdToUse}/season-setup/all`,
    });
    const { data } = await get();

    if (data && data.length > 0) {
      setSeasonDetails(
        data.map((data) => ({
          ...data,
          startDate: new Date(data.startDate),
          endDate: new Date(data.endDate),
          season: data.season,
        }))
      );
    }

    if (isCancelled) {
      if (data.length === 0) setSeasonDetails([]);
    }

    setIsCancelled(false);
    if (anotherHotelId) {
      setIsInEditMode(true);
    }
    setLoading(false);
  }

  function onChange(index, key, value) {
    setSeasonDetails((prevState) => {
      return prevState.map((seasonDetail, idx) =>
        idx === index
          ? {
              ...seasonDetail,
              [key]: value,
            }
          : seasonDetail
      );
    });
  }

  function toggleEdit() {
    setIsInEditMode(!isInEditMode);
  }

  function addSeason() {
    setSeasonDetails((prevState) =>
      prevState.length === 0
        ? [EMPTY_ROW]
        : [
            ...prevState,
            {
              ...EMPTY_ROW,
              startDate: new Date(
                new Date(prevState[prevState.length - 1].endDate).setDate(
                  new Date(prevState[prevState.length - 1].endDate).getDate() +
                    1
                )
              ),
              endDate: new Date(
                new Date(prevState[prevState.length - 1].endDate).setDate(
                  new Date(prevState[prevState.length - 1].endDate).getDate() +
                    2
                )
              ),
            },
          ]
    );
  }

  function handleCloseStatus() {
    setOpen(false);
  }

  const sonRemoveRowHandler = (index) => {
    setSeasonDetails((prevState) => {
      return prevState.filter((_, idx) => idx !== index);
    });
  };

  const sonAddRowHandler = (index) => {
    setSeasonDetails((prevState) => [
      ...seasonDetails.filter((_, idx) => idx <= index),
      {
        startDate: new Date(
          new Date(prevState[prevState.length - 1].endDate).setDate(
            new Date(prevState[prevState.length - 1].endDate).getDate() + 1
          )
        ),
        endDate: new Date(
          new Date(prevState[prevState.length - 1].endDate).setDate(
            new Date(prevState[prevState.length - 1].endDate).getDate() + 2
          )
        ),
        season: null,
      },
      ...seasonDetails.filter((_, idx) => idx > index),
    ]);
  };

  return {
    seasonDetails,
    isInEditMode,
    setIsInEditMode,
    isCancelled,
    setIsCancelled,
    isSaved,
    setIsSaved,
    onChange,
    toggleEdit,
    addSeason,
    dOpen: open,
    dHandleCloseStatus: handleCloseStatus,
    dNetworkMsg: networkMsg,
    sonRemoveRowHandler,
    sonAddRowHandler,
    SeasonDatesError: Error,
    Loading,
  };
}
