import { TableContainer } from "@mui/material";
import React, { memo } from "react";
import { useParams } from "react-router";
import {
  CalcHeader,
  DayText,
  Price,
  PriceText,
  Table,
  Tbody,
  Tdata,
  Text,
  Thead,
  Theader,
  Tr,
} from "../Styles";

const Days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const CalcPopOver = memo(
  ({
    calc,
    reccPrice,
    date,
    day,
    baseMinPrice,
    checkOverridePrice,
    maxThresholdPrice,
  }) => {
    const { hotelId } = useParams();
    return (
      <>
        <CalcHeader>
          <Text>{date}</Text>
          <DayText>{day}</DayText>
        </CalcHeader>
        <CalcHeader>
          <PriceText>Base Price</PriceText>
          <Price>
            {calc.basePrice && calc.basePrice.appliedValue
              ? calc.basePrice.appliedValue
              : "-"}
          </Price>
        </CalcHeader>
        <CalcHeader>
          <PriceText>
            Automated&nbsp;Price&nbsp;without&nbsp;Min.&nbsp;Threshold
          </PriceText>
          <Price>{reccPrice === 1 ? "-" : reccPrice}</Price>
        </CalcHeader>
        {checkOverridePrice && maxThresholdPrice !== null ? (
          <CalcHeader>
            <PriceText>Max Threshold Price</PriceText>
            <Price>{maxThresholdPrice}</Price>
          </CalcHeader>
        ) : null}
        {reccPrice < baseMinPrice ? (
          <CalcHeader style={{ marginBottom: "12px" }}>
            <PriceText>
              Automated&nbsp;Price&nbsp;with&nbsp;Min.&nbsp;Threshold
            </PriceText>
            <Price>
              {baseMinPrice}
              <span
                style={{
                  color: "red",
                  whiteSpace: "nowrap",
                }}
              >
                *
              </span>
            </Price>
          </CalcHeader>
        ) : (
          ""
        )}

        <TableContainer style={{ marginBottom: "10px" }}>
          <Table>
            <Thead>
              <Tr>
                <Theader style={{ width: "130px" }}>
                  Demand Level Factors
                </Theader>
                <Theader>{}</Theader>
                <Theader style={{ textAlign: "center" }}>Value</Theader>
              </Tr>
            </Thead>
            <Tbody>
              {calc.demandLevelDLF && (
                <Tr>
                  <Tdata>Hotel Demand Level</Tdata>
                  <Tdata>{calc.demandLevelDLF.symbol}</Tdata>
                  <Tdata style={{ textAlign: "center" }}>
                    {calc.demandLevelDLF.appliedValue}
                  </Tdata>
                </Tr>
              )}
              {calc.cityDemandLevelDLF && (
                <Tr>
                  <Tdata>City Demand Level</Tdata>
                  <Tdata>{calc.cityDemandLevelDLF.symbol}</Tdata>
                  <Tdata style={{ textAlign: "center" }}>
                    {calc.cityDemandLevelDLF.appliedValue}
                  </Tdata>
                </Tr>
              )}

              {calc.dayOfWeekDLF && (
                <Tr>
                  <Tdata>Day Of Week</Tdata>
                  <Tdata>{Days[calc.dayOfWeekDLF.day]}</Tdata>
                  <Tdata style={{ textAlign: "center" }}>
                    {calc.dayOfWeekDLF.appliedValue}
                  </Tdata>
                </Tr>
              )}
              {calc.occupancyDLF && (
                <Tr>
                  <Tdata>Occupancy</Tdata>
                  <Tdata>
                    {calc.occupancyDLF.max === -1 ? (
                      <>above {calc.occupancyDLF.min}</>
                    ) : (
                      <>
                        {calc.occupancyDLF.min} {`${` to `}`}{" "}
                        {calc.occupancyDLF.max}
                      </>
                    )}
                  </Tdata>
                  <Tdata style={{ textAlign: "center" }}>
                    {calc.occupancyDLF.appliedValue}
                  </Tdata>
                </Tr>
              )}
              {calc.paceCalculationDLF && (
                <Tr>
                  <Tdata>Pace</Tdata>
                  <Tdata>
                    {calc.paceCalculationDLF.max === -1 ? (
                      <>above {calc.paceCalculationDLF.min}</>
                    ) : (
                      <>
                        {calc.paceCalculationDLF.min} to{" "}
                        {calc.paceCalculationDLF.max}
                      </>
                    )}
                  </Tdata>
                  <Tdata style={{ textAlign: "center" }}>
                    {calc.paceCalculationDLF.appliedValue}
                  </Tdata>
                </Tr>
              )}
              {calc.leadTimeDLF && (
                <Tr>
                  <Tdata>Lead Time</Tdata>
                  <Tdata>
                    {calc.leadTimeDLF.max === -1 ? (
                      <>above {calc.leadTimeDLF.min}</>
                    ) : (
                      <>
                        {calc.leadTimeDLF.min} to {calc.leadTimeDLF.max}
                      </>
                    )}
                  </Tdata>
                  <Tdata style={{ textAlign: "center" }}>
                    {calc.leadTimeDLF.appliedValue}
                  </Tdata>
                </Tr>
              )}
              {calc.YOYVarianceDLF && (
                <Tr>
                  <Tdata>YOY Variance</Tdata>
                  <Tdata>
                    {calc.YOYVarianceDLF.max === -1 ? (
                      <>above {calc.YOYVarianceDLF.min}</>
                    ) : (
                      <>
                        {calc.YOYVarianceDLF.min} to {calc.YOYVarianceDLF.max}
                      </>
                    )}
                  </Tdata>
                  <Tdata style={{ textAlign: "center" }}>
                    {calc.YOYVarianceDLF.appliedValue}
                  </Tdata>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Theader style={{ width: "130px" }}>Multipliers</Theader>
                <Theader>{}</Theader>
                <Theader style={{ textAlign: "center" }}>Value</Theader>
              </Tr>
            </Thead>
            <Tbody>
              {calc.actualDemandLvlMultiplier && (
                <Tr>
                  <Tdata>Actual Demand Lvl</Tdata>
                  <Tdata>{calc.actualDemandLvlMultiplier.symbol}</Tdata>
                  <Tdata style={{ textAlign: "center" }}>
                    {calc.actualDemandLvlMultiplier.appliedValue}
                  </Tdata>
                </Tr>
              )}
              {calc.dayOfWeekMultiplier && (
                <Tr>
                  <Tdata>Day Of Week</Tdata>
                  <Tdata>{Days[calc.dayOfWeekMultiplier.day]}</Tdata>
                  <Tdata style={{ textAlign: "center" }}>
                    {calc.dayOfWeekMultiplier.appliedValue}
                  </Tdata>
                </Tr>
              )}
              {calc.leadTimeMultiplier && (
                <Tr>
                  <Tdata>Lead Time</Tdata>
                  <Tdata>
                    {calc.leadTimeMultiplier.max === -1 ? (
                      <>above {calc.leadTimeMultiplier.min}</>
                    ) : (
                      <>
                        {calc.leadTimeMultiplier.min} to{" "}
                        {calc.leadTimeMultiplier.max}
                      </>
                    )}
                  </Tdata>

                  <Tdata style={{ textAlign: "center" }}>
                    {Math.round(
                      (calc.leadTimeMultiplier.appliedValue + Number.EPSILON) *
                        100
                    ) / 100}
                  </Tdata>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </>
    );
  }
);
export default CalcPopOver;
