import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getISODate, useAuth } from "../../sdk";

export default function usePace({
  fromDate,
  toDate,
  selectedPickup,
  fromFileId,
  toFileId,
}) {
  const { token, hotelSelect, authFetch } = useAuth();
  const { hotelId } = useParams();
  const [monthlyBookings, setMonthlyBookings] = useState([]);
  const [monthlyRevenue, setMonthlyReveue] = useState([]);
  const [monthlyArr, setMonthlyArr] = useState([]);
  const [monthlyOccu, setMonthlyOccu] = useState([]);
  const [networkMsg, setnetworkMsg] = useState();
  const [open, setOpen] = useState();
  const [Loading, setLoading] = useState(false);
  const [Error, setError] = useState(false);
  let revenueMonthlyArray = [];
  let OccuMonthlyArray = [];

  useEffect(() => {
    if (!token || !fromFileId || !toFileId) {
      return;
    }
    async function fetchData() {
      setLoading(true);
      fetchRevData();
      await fetchOccData();
      await fetchARRData();
      setLoading(false);
    }
    fetchData();
  }, [token, fromFileId, toFileId, hotelId]);

  useEffect(() => {
    if (
      monthlyArr.length <= 0 ||
      monthlyOccu.length <= 0 ||
      monthlyRevenue.length <= 0
    ) {
      return;
    } else {
      if (selectedPickup === "occPickup") {
        setMonthlyBookings(monthlyOccu);
      } else if (selectedPickup === "arrPickup") {
        setMonthlyBookings(monthlyArr);
      } else if (selectedPickup === "revPickup") {
        setMonthlyBookings(monthlyRevenue);
      }
    }
  }, [
    selectedPickup,
    monthlyOccu,
    monthlyArr,
    monthlyRevenue,
    fromFileId,
    toFileId,
    hotelId,
  ]);

  useEffect(() => {
    setMonthlyBookings([]);
    setMonthlyReveue([]);
    setMonthlyArr([]);
  }, [fromFileId, toFileId, fromDate, toDate]);

  async function fetchRevData() {
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/file-revenue-pickup`,
    });
    const { data, error, response } = await post({
      fromFileId,
      toFileId,
    });

    if (response?.ok) {
      revenueMonthlyArray = monthlyBooking(data, "revPickup");
    } else {
      setError(true);
      setnetworkMsg(data?.messageToUser || "Something went wrong");
      revenueMonthlyArray = monthlyBooking([], "revPickup");
    }
  }

  async function fetchOccData() {
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/file-pickupv2`,
    });
    const { data, response } = await post({
      fromFileId,
      toFileId,
    });

    if (response?.ok) {
      setMonthlyOccu(data);
      OccuMonthlyArray = data;
    } else {
      setnetworkMsg(data?.messageToUser || "Something went wrong");
      setError(true);
      setMonthlyOccu([]);
    }
  }

  async function fetchARRData() {
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/file-arr-pickup`,
    });
    const { data, response } = await post({
      fromFileId,
      toFileId,
    });
    if (response?.ok) {
      monthlyBooking(data, "arrPickup", revenueMonthlyArray, OccuMonthlyArray);
    } else {
      setnetworkMsg(data?.messageToUser || "Something went wrong");
      setError(true);
      monthlyBooking([], "arrPickup");
    }
  }

  function monthlyBooking(
    bookings = [],
    type,
    revenueMonthlyArray = [],
    OccuMonthlyArray = []
  ) {
    let monthlyArray = [];
    let totalFromActuals = 0;
    let totalToActuals = 0;
    let totalPickup = 0;
    let noValueFromActuals = true;
    let noValueToActuals = true;
    const totalRooms = getTotalRooms();

    let i = 0;
    let count = 0;
    monthlyArray.push([]);
    if (bookings && bookings?.length > 0) {
      bookings.forEach((booking, index) => {
        const data = {
          ...booking,
          fromActuals: booking.fromActuals,
          toActuals: booking.toActuals,
          pickup: booking.pickup,
        };
        let pastMonth;

        if (
          new Date(bookings[index].date).getMonth() ===
          new Date(fromDate).getMonth()
        ) {
          pastMonth = new Date(bookings[index]?.date)?.getMonth();
        } else {
          pastMonth = new Date(bookings[index - 1]?.date)?.getMonth();
        }

        if (new Date(booking.date).getMonth() === pastMonth) {
          monthlyArray[i].push(data);
          totalFromActuals =
            totalFromActuals +
            (booking.fromActuals === -1 ? 0 : booking.fromActuals);
          totalToActuals =
            totalToActuals + (booking.toActuals === -1 ? 0 : booking.toActuals);
          totalPickup =
            totalPickup +
            (booking.fromActuals === -1 || booking.toActuals === -1
              ? 0
              : booking.pickup);

          if (booking.fromActuals !== -1) {
            noValueFromActuals = false;
          }
          if (booking.toActuals !== -1) {
            noValueToActuals = false;
          }
          if (bookings.length - 1 === index) {
            let lastDate = new Date(bookings[index - 1]?.date)?.getDate();

            if (type === "arrPickup") {
              monthlyArray[i].push({
                totalFromActuals:
                  noValueFromActuals === true
                    ? -1
                    : parseFloat(
                        revenueMonthlyArray[i][
                          revenueMonthlyArray[i].length - 1
                        ].totalFromActuals /
                          (OccuMonthlyArray[i]?.totalFromActuals === 0
                            ? 1
                            : OccuMonthlyArray[i]?.totalFromActuals)
                      ).toFixed(2),
                totalToActuals:
                  noValueToActuals === true
                    ? -1
                    : parseFloat(
                        revenueMonthlyArray[i][
                          revenueMonthlyArray[i].length - 1
                        ].totalToActuals /
                          (OccuMonthlyArray[i]?.totalToActuals === 0
                            ? 1
                            : OccuMonthlyArray[i]?.totalToActuals)
                      ).toFixed(2),
                totalPickup:
                  noValueToActuals === true || noValueFromActuals === true
                    ? -1
                    : (
                        parseFloat(
                          revenueMonthlyArray[i][
                            revenueMonthlyArray[i].length - 1
                          ].totalToActuals /
                            (OccuMonthlyArray[i]?.totalToActuals === 0
                              ? 1
                              : OccuMonthlyArray[i]?.totalToActuals)
                        ).toFixed(2) -
                        parseFloat(
                          revenueMonthlyArray[i][
                            revenueMonthlyArray[i].length - 1
                          ].totalFromActuals /
                            (OccuMonthlyArray[i]?.totalFromActuals === 0
                              ? 1
                              : OccuMonthlyArray[i]?.totalFromActuals)
                        ).toFixed(2)
                      ).toFixed(2),
              });
            } else {
              monthlyArray[i].push({
                totalFromActuals:
                  noValueFromActuals === true ? -1 : totalFromActuals,
                totalToActuals: noValueToActuals === true ? -1 : totalToActuals,
                totalPickup: totalPickup,
              });
            }
          }
        } else {
          let lastDate = new Date(bookings[index - 1]?.date)?.getDate();
          if (type === "arrPickup") {
            monthlyArray[i].push({
              totalFromActuals:
                noValueFromActuals === true
                  ? -1
                  : parseFloat(
                      revenueMonthlyArray[i][revenueMonthlyArray[i].length - 1]
                        .totalFromActuals /
                        (OccuMonthlyArray[i]?.totalFromActuals === 0
                          ? 1
                          : OccuMonthlyArray[i]?.totalFromActuals)
                    ).toFixed(2),
              totalToActuals:
                noValueToActuals === true
                  ? -1
                  : parseFloat(
                      revenueMonthlyArray[i][revenueMonthlyArray[i].length - 1]
                        .totalToActuals /
                        (OccuMonthlyArray[i]?.totalToActuals === 0
                          ? 1
                          : OccuMonthlyArray[i]?.totalToActuals)
                    ).toFixed(2),
              totalPickup:
                noValueToActuals === true || noValueFromActuals === true
                  ? -1
                  : (
                      parseFloat(
                        revenueMonthlyArray[i][
                          revenueMonthlyArray[i].length - 1
                        ].totalToActuals /
                          (OccuMonthlyArray[i]?.totalToActuals === 0
                            ? 1
                            : OccuMonthlyArray[i]?.totalToActuals)
                      ).toFixed(2) -
                      parseFloat(
                        revenueMonthlyArray[i][
                          revenueMonthlyArray[i].length - 1
                        ].totalFromActuals /
                          (OccuMonthlyArray[i]?.totalFromActuals === 0
                            ? 1
                            : OccuMonthlyArray[i]?.totalFromActuals)
                      ).toFixed(2)
                    ).toFixed(2),
            });
          } else {
            monthlyArray[i].push({
              totalFromActuals:
                noValueFromActuals === true ? -1 : totalFromActuals,
              totalToActuals: noValueToActuals === true ? -1 : totalToActuals,
              totalPickup: totalPickup,
            });
          }

          pastMonth = new Date(booking.date).getMonth();
          monthlyArray.push([]);
          i++;
          totalFromActuals = 0;
          totalToActuals = 0;
          totalPickup = 0;
          noValueFromActuals = true;
          noValueToActuals = true;
          monthlyArray[i].push(data);
          totalFromActuals =
            totalFromActuals +
            (booking.fromActuals === -1 ? 0 : booking.fromActuals);
          totalToActuals =
            totalToActuals + (booking.toActuals === -1 ? 0 : booking.toActuals);
          totalPickup =
            totalPickup +
            (booking.fromActuals === -1 || booking.toActuals === -1
              ? 0
              : booking.pickup);
        }
      });
      if (type === "arrPickup") {
        setMonthlyArr(monthlyArray);
      } else if (type === "revPickup") {
        setMonthlyReveue(monthlyArray);
      }
    } else {
      setMonthlyBookings([]);
    }
    return monthlyArray;
  }

  function getTotalRooms() {
    return hotelSelect?.rooms;
  }

  function handleCloseStatus() {
    setOpen(false);
  }
  return {
    monthlyBookings,
    setMonthlyBookings,
    open,
    setOpen,
    setnetworkMsg,
    handleCloseStatus,
    Loading,
    networkMsg,
    Error,
  };
}
