import {
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  TextField,
  FormControl,
  Select,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/system";
import React, { useState } from "react";
import {
  CancelBtnMB as CancelBtn,
  CrossMB as Cross,
  FooterMB as Footer,
  HeadMB as Head,
  MybtnMB as Mybtn,
  WrapperMB as Wrapper,
} from "./Styles";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const StyledWrapper = styled(Wrapper)(({ theme }) => ({
  outline: 0,
  position: "absolute",
  width: 400,
  height: 250,
  backgroundColor: "#fff",
  boxShadow: 5,
  padding: 16,
}));

const PricingSetup = [
  "LowPricingSetup",
  "HighPricingSetup",
  "SeasonSetup",
  "HotelDLMidweek",
  "HotelDLWeekend",
];

export default function ModelBody({
  copyFromHotelId,
  setCopyFromHotelId,
  copyHotelName,
  setCopyHotelName,
  handlePricingChange,
  selectPricingSetup,
  updatePricingSetup,
  setOpenSelectBar,
  allHotels,
  hotelId,
}) {
  const [modalStyle] = useState(getModalStyle);
  const defaultHotelProps = {
    options: [
      ...allHotels
        .filter((hotel) => hotel.id !== parseInt(hotelId))
        .map((hotelNew) => {
          return hotelNew.name + " (" + hotelNew.organizationName + ")";
        }),
    ],
    getOptionLabel: (option) => option,
  };
  const handleHotelChange = (value) => {
    if (value) {
      const hotelName = value.split("(")[0].trim();
      const selectedHotel = allHotels.find((hotel) => hotel.name === hotelName);
      if (selectedHotel) {
        setCopyFromHotelId(selectedHotel.id);
        setCopyHotelName(selectedHotel.name);
      } else {
        setCopyFromHotelId(null);
      }
    } else setCopyFromHotelId(null);
  };
  return (
    <StyledWrapper style={modalStyle}>
      <Head>
        <Cross
          onClick={() => {
            setOpenSelectBar(false);
          }}
        >
          &#x274C;
        </Cross>
      </Head>
      <Autocomplete
        {...defaultHotelProps}
        value={copyHotelName}
        style={{ width: "65%", font: "Roboto", paddingBottom: "20px" }}
        freeSolo={true}
        onChange={(event, newValue) => {
          handleHotelChange(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder="Search Hotel" />
        )}
      />
      <FormControl variant="outlined">
        <InputLabel>Setup</InputLabel>

        <Select
          multiple
          value={selectPricingSetup}
          label="Setup"
          onChange={(e) => handlePricingChange(e.target.value)}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Placeholder</em>;
            }

            return <em>{selected.join(", ")}</em>;
          }}
          MenuProps={{ getContentAnchorEl: null }}
          style={{ width: "200px" }}
        >
          {PricingSetup.map((val, idx) => (
            <MenuItem key={idx} value={val}>
              <ListItemText primary={val} />
              <Checkbox checked={selectPricingSetup.indexOf(val) > -1} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Footer>
        <CancelBtn
          onClick={() => {
            setOpenSelectBar(false);
          }}
        >
          Cancel
        </CancelBtn>
        <Mybtn
          disabled={copyFromHotelId === null ? true : false}
          next
          style={
            copyFromHotelId === null
              ? { color: "#ffffff", cursor: "not-allowed" }
              : { color: "#ffffff" }
          }
          onClick={updatePricingSetup}
        >
          Confirm
        </Mybtn>
      </Footer>
    </StyledWrapper>
  );
}
