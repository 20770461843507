import React from "react";
import { styled } from "@mui/system";
import { Button } from "@mui/material";

const Btn = styled(Button)`
  background-color: white;
  border: none;
  &:hover {
    cursor: pointer;
  }
  outline: none;
`;
export const NormalButton = ({ children, ...props }) => {
  return (
    <Btn type="button" {...props}>
      {children}
    </Btn>
  );
};
