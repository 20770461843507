import {
  MenuItem,
  IconButton,
  Select,
  Box,
  Popover,
  Typography,
} from "@mui/material";
import { ChevronLeft, ChevronRight, CalendarToday } from "@mui/icons-material";
import { styled } from "@mui/system";
import React from "react";
import { GhostButton as GS } from "./Button";
const GhostButton = styled(GS)`
  font: normal normal normal 18px/20px Roboto;
  cursor: pointer;
  min-width: 170px;
`;

const YearSwitch = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: normal normal normal 18px/20px Roboto;
`;

const MonthRow = styled(Box)`
  display: flex;
  justify-content: flex-start;
  padding: 0 8px 0;
  font: normal normal normal 18px/20px Roboto;
`;
const MonthCellGhost = styled(GS)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  font: normal normal normal 18px/20px Roboto;
`;
const MonthCellPrimary = styled(MonthCellGhost)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  font: normal normal normal 18px/20px Roboto;
  &&&& {
    background-color: rgb(17, 6, 65);
    color: white;
    &:hover {
      border-radius: 2px;
      opacity: 0.9;
    }
    &:disabled {
      background-color: transparent;
      cursor: not-allowed;
      color: ${({ disabled }) => (disabled ? "#6f6f6f" : "#ffffff")};
    }

    &:active {
      background-color: ${({ disabled }) =>
        disabled ? "transparent" : "#f1f2fe"};
    }
  }
`;

const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: 4,
  font: "normal normal normal 14px/20px Roboto",
}));

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function MonthPicker({
  value,
  onChange,
  disabled = false,
  disableFutureMonths = false,
  showYearDropdown = false,
  disablePastMonths = false, // New prop to disable past months
}) {
  const [month, year] = React.useMemo(() => {
    return [value.getMonth(), value.getFullYear()];
  }, [value]);

  const [currentMonth] = React.useState(value.getMonth());
  const [currentYear] = React.useState(value.getFullYear());

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const monthInChunks = React.useMemo(() => {
    const chunkSize = 4;
    return months
      .map(function (item, i) {
        return i % chunkSize ? [] : months.slice(i, i + chunkSize);
      })
      .filter((arr) => arr.length);
  }, [months]);

  const handleChange = React.useCallback(
    (MM, yyyy) => {
      const date = new Date();
      date.setDate(1);
      date.setMonth(MM);
      date.setFullYear(yyyy);

      const currentMonthDate = new Date();
      currentMonthDate.setDate(1);

      console.log({ date, currentMonthDate });
      if (disablePastMonths && date < currentMonthDate) {
        console.log("heree");
        return; // Do not update if past month is disabled
      }

      onChange(date);
    },
    [disablePastMonths, onChange]
  );

  return (
    <div style={showYearDropdown ? { backgroundColor: "#ffffff" } : {}}>
      <GhostButton
        textTransform="none"
        variant="contained"
        color="primary"
        disabled={disabled}
        onClick={handleClick}
        style={
          showYearDropdown
            ? { backgroundColor: "#ffffff" }
            : { backgroundColor: "#ffffff" }
        }
      >
        <Typography textTransform="none">{`${months[month]} ${year}`}</Typography>
        <CalendarToday
          sx={{
            cursor: "pointer",
            color: "rgb(48,111,188)",
            marginLeft: "8px",
            fontSize: "21px",
          }}
          onClick={handleClick}
        />
      </GhostButton>
      <Popover
        id={"MonthPicker"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box p={1}>
          {showYearDropdown ? (
            <YearSwitch>
              <StyledSelect
                value={year}
                onChange={(e) => handleChange(month, e.target.value)}
                style={{ minWidth: "240px", textAlign: "center" }}
                disableUnderline
                required={true}
              >
                <MenuItem
                  style={{ font: "normal normal normal 16px/20px Roboto" }}
                  value={year - 3}
                >
                  {year - 3}
                </MenuItem>
                <MenuItem
                  style={{ font: "normal normal normal 16px/20px Roboto" }}
                  value={year - 2}
                >
                  {year - 2}
                </MenuItem>
                <MenuItem
                  style={{ font: "normal normal normal 16px/20px Roboto" }}
                  value={year - 1}
                >
                  {year - 1}
                </MenuItem>
                <MenuItem
                  style={{ font: "normal normal normal 16px/20px Roboto" }}
                  value={year}
                >
                  {year}
                </MenuItem>

                <MenuItem
                  style={{ font: "normal normal normal 16px/20px Roboto" }}
                  value={year + 1}
                >
                  {year + 1}
                </MenuItem>

                {year + 2 <= currentYear && (
                  <MenuItem
                    style={{ font: "normal normal normal 16px/20px Roboto" }}
                    value={year + 2}
                  >
                    {year + 2}
                  </MenuItem>
                )}
                {year + 3 <= currentYear && (
                  <MenuItem
                    style={{ font: "normal normal normal 16px/20px Roboto" }}
                    value={year + 3}
                  >
                    {year + 3}
                  </MenuItem>
                )}
              </StyledSelect>
            </YearSwitch>
          ) : (
            <YearSwitch>
              {disablePastMonths && year === currentYear ? (
                <IconButton disabled>
                  <ChevronLeft />
                </IconButton>
              ) : (
                <IconButton onClick={() => handleChange(month, year - 1)}>
                  <ChevronLeft />
                </IconButton>
              )}
              {year}
              {disableFutureMonths && year === currentYear ? (
                <IconButton disabled>
                  <ChevronRight />
                </IconButton>
              ) : (
                <IconButton onClick={() => handleChange(month, year + 1)}>
                  <ChevronRight />
                </IconButton>
              )}
            </YearSwitch>
          )}

          {monthInChunks.map((monthArray, rowIndex) => (
            <MonthRow key={rowIndex}>
              {monthArray.map((mon, colIndex) => {
                const displayName = mon.slice(0, 3);
                const monthIndex = rowIndex * monthArray.length + colIndex;

                if (
                  (disablePastMonths &&
                    monthIndex < currentMonth &&
                    year === currentYear) || // Check if past months should be disabled
                  (disableFutureMonths &&
                    monthIndex > currentMonth &&
                    year === currentYear) // Check if future months should be disabled
                ) {
                  return (
                    <MonthCellPrimary key={displayName} disabled>
                      <Typography textTransform="none">
                        {displayName}
                      </Typography>
                    </MonthCellPrimary>
                  );
                }
                if (month === monthIndex) {
                  return (
                    <MonthCellPrimary
                      key={displayName}
                      onClick={() => {
                        handleChange(monthIndex, year);
                        handleClose();
                      }}
                    >
                      <Typography textTransform="none">
                        {displayName}
                      </Typography>
                    </MonthCellPrimary>
                  );
                }
                return (
                  <MonthCellGhost
                    key={displayName}
                    onClick={() => {
                      handleChange(monthIndex, year);
                      handleClose();
                    }}
                  >
                    <Typography textTransform="none">{displayName}</Typography>
                  </MonthCellGhost>
                );
              })}
            </MonthRow>
          ))}
        </Box>
      </Popover>
    </div>
  );
}
