import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../../sdk";
import {
  CancelBtn,
  Cross,
  Div,
  Div1,
  Head,
  Mybtn,
  ClassWrapper,
} from "./Styles";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export function ModelBody({
  setOpen,
  setOpenModel,
  hotelList,
  setLoading,
  hotelListCloned,
  setnetworkMsg,
}) {
  const [modalStyle] = useState(getModalStyle);
  const { authFetch } = useAuth();
  const [hotelId, setHotelId] = useState(null);
  const [hotelIdCloned, setHotelIdCloned] = useState(null);
  const [networkMsg, setNetworkMsg] = useState();
  const defaultHotelProps = {
    options: [
      ...hotelList.map((hotel) => {
        return hotel.name + " (" + hotel.organizationName + ")";
      }),
    ],
    getOptionLabel: (option) => option,
  };
  const defaultHotelPropsCloned = {
    options: [
      ...hotelListCloned.map((hotel) => {
        return hotel.name;
      }),
    ],
    getOptionLabel: (option) => option,
  };
  const handleHotelChange = (value) => {
    const hotelname = value?.split("(")[0].trim();
    const organizationname = value?.split("(")[1].split(")")[0];
    if (value) {
      const selectedHotelId = hotelList.find(
        (hotel) =>
          hotel.name == hotelname && hotel.organizationName === organizationname
      )?.id;
      setHotelId(selectedHotelId);
    }
  };
  const handleHotelChangeCloned = (value) => {
    const hotelname = value?.split("(")[0];
    if (value) {
      const selectedHotelId = hotelListCloned.find(
        (hotel) => hotel.name == hotelname
      )?.id;
      setHotelIdCloned(selectedHotelId);
    }
  };

  const cloneHotel = async () => {
    setOpenModel(false);
    setLoading(true);
    const { post } = await authFetch({
      path: `/clone-setup-hotel`,
    });
    const { error, data } = await post({
      cloneHotelId: hotelId,
      setupHotelId: hotelIdCloned,
    });
    const { Message } = data;
    if (error) {
      setnetworkMsg("Something went wrong");
      setOpen(true);
    }
    if (Message) {
      setnetworkMsg(Message);
      setOpen(true);
    }

    setLoading(false);
  };
  return (
    <ClassWrapper>
      <Div style={modalStyle} className="paper">
        <Head>
          <Cross
            onClick={() => {
              setOpenModel(false);
            }}
          >
            &#x274C;
          </Cross>
        </Head>
        <Autocomplete
          {...defaultHotelProps}
          value={""}
          style={{ width: "65%", font: "Roboto", paddingBottom: "20px" }}
          freeSolo={true}
          onChange={(event, newValue) => {
            handleHotelChange(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Search Hotel" />
          )}
        />
        <Autocomplete
          {...defaultHotelPropsCloned}
          value={""}
          style={{ width: "65%", font: "Roboto", paddingBottom: "20px" }}
          freeSolo={true}
          onChange={(event, newValue) => {
            handleHotelChangeCloned(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Search Clone Hotel" />
          )}
        />

        <Div1>
          <CancelBtn
            onClick={() => {
              setOpenModel(false);
            }}
          >
            Cancel
          </CancelBtn>
          <Mybtn next style={{ color: "#ffffff" }} onClick={cloneHotel}>
            Update
          </Mybtn>
        </Div1>
      </Div>
    </ClassWrapper>
  );
}
