import React from "react";
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
`;
const Sorry = styled(Typography)`
  width: 73px;
  height: 26px;
  text-align: left;
  font: normal normal 300 18px/26px Roboto;
  letter-spacing: 0px;
  color: #322277;
  margin: 18px 0px 10px 0px;
`;
const Message = styled(Typography)`
  width: 310px;
  height: 59px;
  text-align: center;
  font: normal normal 300 18px/21px Roboto;
  letter-spacing: 0px;
  color: #322277;
`;
export const ErrorPage = () => {
  return (
    <Wrapper>
      <img src="/assets/Error.svg" alt="Logo" width="261px" height="200px" />
      <Sorry>Sorry :(</Sorry>
      <Message>We are taking care of this issue Come back in some time</Message>
    </Wrapper>
  );
};
