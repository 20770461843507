import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { searchParamsToUrlQuery } from "../common-utils";

export const useQueryParam = () => {
  const { search } = useLocation();
  return useMemo(() => searchParamsToUrlQuery(new URLSearchParams(search)), [
    search,
  ]);
};
