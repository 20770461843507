import React, { useEffect } from "react";
import { BookingPage } from "../styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useBookingsList } from "../hooks/useBookingList";
import { LoadingPage, Nodata } from "../../sdk";

export const BookingsList = ({ setPageHeader }) => {
  const {
    rows,
    columns,
    isLoading,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalCount
  } = useBookingsList();
  useEffect(() => {
    setPageHeader("Bookings");
    return () => {
      setPageHeader("");
    };
  }, []);

  return (
    <BookingPage>
      {isLoading ? (
        <LoadingPage />
      ) : rows.length === 0 ? (
        <Nodata />
      ) : (
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "80vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#306fbc" }}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        top: 0,
                        minWidth: column.minWidth,
                        background: "#306fbc",
                        color: "#fff",
                      }}
                    >
                      {column.label}
                      {column.id === "total_price" && (
                        <Typography component='span'> (in &#8377;)</Typography>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow hover role="checkbox" key={row.booking_code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            rowsPerPage={rowsPerPage}
            count= {totalCount}
            page={page}
            onPageChange={(_, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(+event.target.value);
              setPage(0);
            }}
          />
        </Paper>
      )}
    </BookingPage>
  );
};
