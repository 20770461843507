import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "react-modern-calendar-datepicker";
import { CalenderIcon } from "../../PricingRecomendation";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../../sdk";

export const AdjustRoomsModal = ({
  open,
  handleClose,
  GetBudget,
  setApiResponse,
  setOpen,
}) => {
  const parsedDate = new Date();
  const defaultValue = {
    year: parsedDate.getFullYear(),
    month: parsedDate.getMonth() + 1,
    day: parsedDate.getDate(),
  };

  const [adjustFromDate, setAdjustFromDate] = useState(defaultValue);
  const [adjustToDate, setAdjustToDate] = useState(defaultValue);

  const [modalData, setModalData] = useState({
    outOfOrder: null,
    reason: "",
  });

  const [checked, setChecked] = React.useState(false);

  const { hotelId } = useParams();
  const { authFetch } = useAuth();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const minimumDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };

  useEffect(() => {
    if (adjustFromDate) {
      setAdjustToDate(adjustFromDate);
    }
  }, [adjustFromDate]);

  const formatInputValueDate = (date) => {
    if (date) {
      const value =
        `${date.day < 10 ? "0" + date.day : date.day}` +
        "/" +
        `${date.month < 10 ? "0" + date.month : date.month}` +
        "/" +
        `${date.year}`;
      return value;
    }
  };

  const renderCustomFromInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValueDate(adjustFromDate)}
      style={{
        textAlign: "left",
        fontSize: "14px",
        borderRadius: "10px",
        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );
  const renderCustomToInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValueDate(adjustToDate)}
      style={{
        textAlign: "left",
        fontSize: "14px",
        borderRadius: "10px",
        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );

  const handleModalDataChange = useCallback((event, field) => {
    const { value } = event.target;

    if (field === "reason") {
      setModalData((prev) => ({
        ...prev,
        [field]: value,
      }));
    } else {
      const numericValue = parseInt(value, 10);

      if (isNaN(numericValue)) {
        setModalData((prev) => ({
          ...prev,
          [field]: "",
        }));
      } else {
        setModalData((prev) => ({
          ...prev,
          [field]: numericValue,
        }));
      }
    }
  }, []);

  const handleSaveModalData = useCallback(async () => {
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/monthly-room-adjustmentv2`,
    });
    const formBody = {
      startdate: `${
        adjustFromDate.year
      }-${adjustFromDate.month
        ?.toString()
        ?.padStart(2, "0")}-${adjustFromDate.day
        ?.toString()
        ?.padStart(2, "0")}T00:00:00Z`,
      endDate: `${adjustToDate.year}-${adjustToDate.month
        ?.toString()
        ?.padStart(2, "0")}-${adjustToDate.day
        ?.toString()
        ?.padStart(2, "0")}T00:00:00Z`,
      // roomsAvailable: modalData?.availableRooms,
      adjustment: modalData?.outOfOrder,
      reason: modalData?.reason,
      hotelId: parseInt(hotelId),
    };
    const { data, response } = await post(formBody);
    if (data && response.status === 200) {
      GetBudget();
      setApiResponse({ msg: data, color: "green" });
      handleClose();
      setOpen(true);
    } else {
      setApiResponse({ msg: data.messageToUser, color: "red" });
      setOpen(true);
      handleClose();
    }
  }, [
    GetBudget,
    adjustFromDate.day,
    adjustFromDate.month,
    adjustFromDate.year,
    adjustToDate.day,
    adjustToDate.month,
    adjustToDate.year,
    authFetch,
    handleClose,
    hotelId,
    modalData.outOfOrder,
    modalData.reason,
    setApiResponse,
    setOpen,
  ]);

  return (
    <StyledChannelDialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className="content">Rooms out of order</DialogTitle>
      <CloseIcon className="closeButton" onClick={handleClose} />
      <DialogContent className="content">
        <Stack direction="row" gap="40px" padding="32px">
          <Stack alignItems="center" justifyContent="space-between">
            <Typography className="heading">Start Date</Typography>
            <label>
              <StyledCalenderWrapper>
                <DatePicker
                  value={adjustFromDate}
                  onChange={setAdjustFromDate}
                  renderInput={renderCustomFromInput}
                  calendarClassName="myCustomCalendar"
                />
                <CalenderIcon />
              </StyledCalenderWrapper>
            </label>
          </Stack>
          <Stack alignItems="center" justifyContent="space-between">
            <Typography className="heading">End Date</Typography>
            <label>
              <StyledCalenderWrapper>
                <DatePicker
                  value={adjustToDate}
                  onChange={setAdjustToDate}
                  renderInput={renderCustomToInput}
                  calendarClassName="myCustomCalendar"
                />
                <CalenderIcon />
              </StyledCalenderWrapper>
            </label>
          </Stack>

          <Stack justifyContent="space-between">
            <Typography className="heading">Out of Order</Typography>
            <Stack direction="row">
              <TextField
                variant="standard"
                type="number"
                value={modalData.outOfOrder}
                placeholder="0"
                onChange={(e) => handleModalDataChange(e, "outOfOrder")}
              />
              <Tooltip
                title="Check to add reason"
                sx={{
                  paddingBottom: 0,
                }}
              >
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Tooltip>
            </Stack>
          </Stack>
          {checked && (
            <Stack justifyContent="space-between">
              <Typography className="heading">Reason</Typography>
              <TextField
                variant="standard"
                label="Reason"
                value={modalData.reason}
                onChange={(e) => handleModalDataChange(e, "reason")}
              />
            </Stack>
          )}
        </Stack>
        <Button
          disabled={
            modalData.outOfOrder === null || modalData.outOfOrder === ""
          }
          onClick={handleSaveModalData}
          variant="contained"
        >
          Save
        </Button>
      </DialogContent>
    </StyledChannelDialog>
  );
};

const StyledChannelDialog = styled(Dialog)(() => ({
  ".MuiPaper-root": {
    overflow: "visible",
  },
  ".closeButton": {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
  },
  ".content": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "visible",
  },
  ".heading": {
    fontSize: "20px",
    fontWeight: 600,
    color: "#306FBC",
  },
}));

const StyledCalenderWrapper = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
  "&:hover>*": {
    color: "#ffffff",
    ">input": {
      backgroundColor: "#306fbc",
      color: "#ffffff !important",
    },
  },
}));
