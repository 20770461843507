import {
  Input as Inpt,
  MenuItem,
  Snackbar,
  SnackbarContent,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Tooltip,
  TextField,
  Box,
  Typography,
  Button,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import MuiAlert from "@mui/material/Alert";
import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/system";
import { LoadingPage, MonthPicker, PrimaryButton } from "../../sdk";
import useBudgetAllocation from "../hooks/useBudgetAllocation";
import {
  InputLabelPDF as InputLabel,
  NextBtnContainer,
  Select,
  SelectDiv,
} from "./Styles";

import { Paper, Stack } from "@mui/material";
import { Table } from "../../CompetitorPricing/Styles";
import { BudgetRedirectingDialog } from "./BudgetRedirectingDialog";
import { useHistory } from "react-router-dom";
import { ArrowRightAlt } from "@mui/icons-material";
const ClassWrapper = styled(Box)(({ theme }) => ({
  ".root": {
    width: "100%",
  },
  ".dropdownStyle": {
    borderRadius: 4,
    font: "normal normal normal 14px/20px Roboto",
  },
  ".underline": {
    width: "100%",
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  ".inputCenter": {
    textAlign: "center",
    font: "normal normal normal 14px/28px Roboto",
  },
}));
export const CustomeTextField = styled(TextField)`
  label {
    letter-spacing: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    height: 24px;
  }
  label.MuiFormLabel-root.Mui-focused {
    font-family: Roboto;
    letter-spacing: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
  }
  input {
    font-family: Roboto;
    letter-spacing: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;

    color: #110641;
    text-align: left;
    background: #ffffff;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: #110641 !important;
  }
`;
const TextOnlyTooltip = styled(Tooltip)(({ theme }) => ({
  ".tooltip": {
    color: "#ffffff",
    font: "normal normal normal 14px/20px Roboto",

    boxShadow: "0px 4px 8px #00000035",
    background: "#110641 0% 0% no-repeat padding-box",
    minWidth: 400,
    textAlign: "center",
  },
  ".arrow": {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "#110641",
  },
}));

export const Input = styled(Inpt)`
  input.MuiInputBase-input {
    padding: 0;
    font: 500 16px/20px Roboto;
    letter-spacing: 0px;
    color: #333333;
    font: normal normal normal 14px/28px Roboto;
  }
`;

export const InputCenter = styled(Inpt)`
  input.MuiInputBase-input {
    padding: 0;
    font: 500 16px/20px Roboto;
    letter-spacing: 0px;
    color: #333333;
    font: normal normal normal 14px/28px Roboto;
    text-align: center;
  }
`;

export const Container = styled(Box)`
  height: calc(100vh - 270px);
  margin-bottom: 20px;
`;
export const AppBar = styled(Box)`
  top: 76px;
  left: 80px;
  height: 52px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  margin-bottom: 40px;
`;

export const MarketSegmentContainer = styled(SelectDiv)`
  margin-left: 194px;
  text-align: left;
  height: 52px;
  opacity: 1;
  display: flex;
  align-items: center;
`;

export const Label = styled(InputLabel)`
  text-align: left;
  font: normal normal bold 14px/28px Roboto;
  letter-spacing: 0px;
  color: #110641;
  opacity: 1;
  margin-top: 12px;
  width: 150px;
  height: 28px;
`;
export const DIV1 = styled(Box)`
  margin-top: 40px;
  width: 1340px;
  height: 40px;

  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 0.06;
  display: flex;
`;

export const Heading = styled(Box)`
  text-align: left;
  font: normal normal bold 14px/28px Roboto;
  letter-spacing: 0px;
  color: #110641;
  opacity: 1;
`;

export const Day = styled(Typography)`
  text-align: left;
  font: normal normal normal 14px/28px Roboto;
  letter-spacing: 0px;
  color: #110641;
  opacity: 1;
`;
export const TRow = styled(TableRow)`
  &.MuiTableRow-root {
    padding: 4px 0;
    &:nth-of-type(odd) {
      background: #f1f2fe;
    }
  }
`;
export const TCell = styled(TableCell)`
  &.MuiTableCell-root {
    font: normal normal normal 14px Roboto;
    letter-spacing: 0px;
    color: #110641;
    padding: 0px 10px;
    border: none;
    text-align: ${(props) => (props.isInEditMode ? "left" : "left")};
    text-transform: capitalize;
    white-space: nowrap;
  }
`;
export const Tabhead = styled(TableCell)`
  &.MuiTableCell-root {
    font: normal normal bold 12px/15px Roboto;
    letter-spacing: 0px;
    color: #130453;
    padding: 0px 8px;
    border: none;
    width: 100px;
    text-align: ${(props) => (props.isInEditMode ? "left" : "left")};
    text-transform: capitalize;
  }
`;
export const THead = styled(TableHead)`
  &.MuiTableHead-root {
    tr.MuiTableRow-root {
      background: transparent;
      width: 100%;
    }
  }
`;

export const TContainer = styled(TableContainer)`
  &.MuiPaper-elevation1 {
    box-shadow: none;
  }
  width: 100%;
`;

export const TextFieldSmall = styled(Input)`
  input.MuiInputBase-input {
    padding: 5px 0;
    font: 500 1px/20px Roboto;
    letter-spacing: 0px;
    color: #333333;
  }
`;

export const TOTAL = styled(Typography)`
  height: 28px;
  text-align: left;
  font: normal normal bold 14px/28px Roboto;
  letter-spacing: 0px;
  color: #110641;
  opacity: 1;

  margin-top: 6px;
`;
export const Options = styled(Select)`
  .MuiSelect-select.MuiSelect-select {
    padding-left: 8px;
  }
`;

export const MonthPickerContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal bold 14px/28px Roboto;
`;

export const RoomNightContainer = styled(Box)`
  width: 70%;
  margin: auto;
`;

export const AppContainer = styled(Box)`
  overflow-y: auto;
  height: 100%;
`;
export const NetworkPrompt = styled(Box)`
  margin-left: 100px;
`;
export const Select1 = styled(Label)``;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function BudgetAllocation() {
  const {
    hotelId,
    marketData,
    setDaySplit,
    daySplit,
    splitTotal,
    editRooms,
    editRevenue,
    ADR,
    room,
    revenue,
    comparitiveDate,
    setComparitiveDate,
    countDOW,
    dowTotal,
    revenueDowTotal,
    handleSplitChange,
    allocationDow,
    handleAllocationChange,
    isCalc,
    setIsCalc,
    setRoom,
    monthDays,
    handleSaveData,
    setSegmentId,
    segmentId,
    isLoading,
    networkMsg,
    open,
    setOpen,
    btnLoading,
    suggestions,
    totalRoomsPerDow,
    handleChange,
    handleSplitChangeRevenue,
    revenueSplitTotal,
    revenueDaySplit,
    revenueAllocationDow,
    isDialogOpen,
    setIsDialogOpen,
  } = useBudgetAllocation();

  const handleShowTable = () => {
    setIsCalc(true);
    const newlist = [...daySplit];
    for (let j = 0; j < 7; j++) {
      newlist[j] =
        Math.round(
          ((allocationDow[j] * countDOW[j] * 100) / dowTotal + Number.EPSILON) *
            100
        ) / 100;
    }
    setDaySplit([...newlist]);
  };
  const [openSnackBar, setOpenSnackBar] = useState(true);

  useEffect(() => {
    console.log(splitTotal, revenueDowTotal, isCalc);
  }, [splitTotal, revenueDowTotal, isCalc]);

  useEffect(() => {
    if (splitTotal <= 100 && revenueSplitTotal <= 100) {
      setOpenSnackBar(false);
    } else {
      setOpenSnackBar(true);
    }
  }, [splitTotal, revenueSplitTotal]);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const history = useHistory();

  const handleRedirect = useCallback(() => {
    history.push(`/hotel/${hotelId}/forecast/forecast_sheet_daily`);
  }, [history, hotelId]);

  return (
    <ClassWrapper>
      <Container>
        <AppBar
          style={{
            background: "none",
          }}
        >
          <Paper
            elevation={1}
            style={{
              height: "52px",
              width: "230px",
              marginLeft: "40px",
              padding: "10px 0 0 10px",
            }}
          >
            <MonthPickerContainer>
              <MonthPicker
                value={comparitiveDate}
                onChange={setComparitiveDate}
              />
            </MonthPickerContainer>
          </Paper>
          <MarketSegmentContainer
            style={{
              marginLeft: "16px",
            }}
          >
            <Paper
              elevation={1}
              style={{
                height: "52px",
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
              }}
            >
              <Options
                disableUnderline
                value={segmentId ?? "none"}
                onChange={(e) => setSegmentId(e.target.value)}
                style={{
                  fontFamily: "Roboto",
                  width: "200px",
                  fontSize: "14px",
                }}
              >
                <MenuItem
                  style={{
                    fontFamily: "Roboto",
                    width: "200px",
                    fontSize: "14px",
                  }}
                  value={"none"}
                  disabled
                >
                  Select Market Segment
                </MenuItem>

                {marketData?.map((segment, index) => (
                  <MenuItem
                    style={{
                      fontFamily: "Roboto",
                      width: "200px",
                      fontSize: "14px",
                    }}
                    value={segment.segmentId}
                    key={index}
                  >
                    {segment.systemName}
                  </MenuItem>
                ))}
              </Options>
            </Paper>
          </MarketSegmentContainer>
          <Button
            sx={{ mr: 2, width: "250px" }}
            variant="contained"
            onClick={handleRedirect}
          >
            <ArrowRightAlt />
            <Typography textTransform="capitalize">Daily Budgeting</Typography>
          </Button>
        </AppBar>
        {!isLoading && marketData ? (
          <AppContainer style={{ marginTop: "-12px" }}>
            <Paper
              variant="outlined"
              sx={{ borderRadius: "10px", overflow: "hidden" }}
            >
              <Table
                style={{
                  height: "80px",
                  background: "#ffffff",
                }}
              >
                <THead
                  style={{
                    backgroundColor: "#306fbc",
                    height: "50px",
                  }}
                >
                  <TRow>
                    <Tabhead>
                      <Heading
                        style={{ color: "#ffffff", textAlign: "center" }}
                      >
                        DOW
                      </Heading>
                    </Tabhead>
                    <Tabhead>
                      <Day
                        style={{
                          color: "#ffffff",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Monday
                      </Day>
                    </Tabhead>
                    <Tabhead>
                      <Day
                        style={{
                          color: "#ffffff",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Tuesday
                      </Day>
                    </Tabhead>
                    <Tabhead>
                      <Day
                        style={{
                          color: "#ffffff",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Wednesday
                      </Day>
                    </Tabhead>
                    <Tabhead>
                      <Day
                        style={{
                          color: "#ffffff",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Thursday
                      </Day>
                    </Tabhead>
                    <Tabhead>
                      <Day
                        style={{
                          color: "#ffffff",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Friday
                      </Day>
                    </Tabhead>
                    <Tabhead>
                      <Day
                        style={{
                          color: "#ffffff",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Saturday
                      </Day>
                    </Tabhead>
                    <Tabhead>
                      <Day
                        style={{
                          color: "#ffffff",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Sunday
                      </Day>
                    </Tabhead>
                    <Tabhead
                      style={{
                        borderRadius: "0 10px 0 0",
                      }}
                    >
                      <TOTAL
                        style={{
                          color: "#ffffff",
                          fontWeight: 500,
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        Total
                      </TOTAL>
                    </Tabhead>
                  </TRow>
                </THead>
                <TableBody>
                  <TRow
                    style={{
                      background: "#ffffff",
                      height: "50px",
                    }}
                  >
                    <TCell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Heading
                          style={{
                            width: "52px",
                            color: "#000",
                          }}
                        >
                          Room Split
                        </Heading>
                      </div>
                    </TCell>
                    {[...Array(7)].map((c, pos) => (
                      <React.Fragment key={pos}>
                        <TCell>
                          <Input
                            classes={{
                              underline: "underline",
                              input: "inputCenter",
                            }}
                            disableUnderline
                            type="numeric"
                            value={
                              Number.isNaN(daySplit[pos]) ? 0 : daySplit[pos]
                            }
                            onChange={(e) => {
                              handleSplitChange(pos, parseInt(e.target.value));
                            }}
                          ></Input>
                        </TCell>
                      </React.Fragment>
                    ))}

                    <TCell
                      style={{
                        textAlign: "center",
                        font: "normal normal bold 14px/28px Roboto",
                        color: "#000",
                      }}
                    >
                      {Math.round(splitTotal) <= 100
                        ? Math.round(splitTotal) + "%"
                        : 0 + "%"}
                    </TCell>
                  </TRow>
                </TableBody>
              </Table>
            </Paper>
            <Paper
              variant="outlined"
              sx={{
                borderRadius: "10px",
                overflow: "hidden",
                marginTop: "20px",
              }}
            >
              <Table
                style={{
                  height: "80px",
                  background: "#ffffff",
                }}
              >
                <THead
                  style={{
                    backgroundColor: "#306fbc",
                    height: "50px",
                  }}
                >
                  <TRow>
                    <Tabhead>
                      <Heading
                        style={{ color: "#ffffff", textAlign: "center" }}
                      >
                        DOW
                      </Heading>
                    </Tabhead>
                    <Tabhead>
                      <Day
                        style={{
                          color: "#ffffff",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Monday
                      </Day>
                    </Tabhead>
                    <Tabhead>
                      <Day
                        style={{
                          color: "#ffffff",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Tuesday
                      </Day>
                    </Tabhead>
                    <Tabhead>
                      <Day
                        style={{
                          color: "#ffffff",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Wednesday
                      </Day>
                    </Tabhead>
                    <Tabhead>
                      <Day
                        style={{
                          color: "#ffffff",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Thursday
                      </Day>
                    </Tabhead>
                    <Tabhead>
                      <Day
                        style={{
                          color: "#ffffff",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Friday
                      </Day>
                    </Tabhead>
                    <Tabhead>
                      <Day
                        style={{
                          color: "#ffffff",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Saturday
                      </Day>
                    </Tabhead>
                    <Tabhead>
                      <Day
                        style={{
                          color: "#ffffff",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Sunday
                      </Day>
                    </Tabhead>
                    <Tabhead
                      style={{
                        borderRadius: "0 10px 0 0",
                      }}
                    >
                      <TOTAL
                        style={{
                          color: "#ffffff",
                          fontWeight: 500,
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        Total
                      </TOTAL>
                    </Tabhead>
                  </TRow>
                </THead>
                <TableBody>
                  <TRow
                    style={{
                      background: "#ffffff",
                      height: "50px",
                    }}
                  >
                    <TCell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Heading
                          style={{
                            width: "52px",
                            color: "#000",
                          }}
                        >
                          Revenue Split
                        </Heading>
                      </div>
                    </TCell>
                    {[...Array(7)].map((c, pos) => (
                      <React.Fragment key={pos}>
                        <TCell>
                          <Input
                            classes={{
                              underline: "underline",
                              input: "inputCenter",
                            }}
                            disableUnderline
                            type="numeric"
                            value={
                              Number.isNaN(revenueDaySplit[pos])
                                ? 0
                                : revenueDaySplit[pos]
                            }
                            onChange={(e) => {
                              handleSplitChangeRevenue(
                                pos,
                                parseInt(e.target.value)
                              );
                            }}
                          ></Input>
                        </TCell>
                      </React.Fragment>
                    ))}

                    <TCell
                      style={{
                        textAlign: "center",
                        font: "normal normal bold 14px/28px Roboto",
                        color: "#000",
                      }}
                    >
                      {Math.round(revenueSplitTotal) <= 100
                        ? Math.round(revenueSplitTotal) + "%"
                        : 0 + "%"}
                    </TCell>
                  </TRow>
                </TableBody>
              </Table>
            </Paper>

            <Stack
              direction="row"
              spacing={8}
              style={{
                marginTop: "22px",
              }}
            >
              <CustomeTextField
                variant="outlined"
                label="Room Night Split"
                value={isNaN(room) ? 0 : room}
                style={{
                  background: "#ffffff",
                  color: "black",
                }}
              />
              <CustomeTextField
                variant="outlined"
                label="Revenue to Split"
                value={isNaN(revenue) ? 0 : revenue}
                style={{
                  background: "#ffffff",
                }}
              />
              <CustomeTextField
                variant="outlined"
                label="ADR"
                value={isNaN(ADR) ? 0 : ADR}
                style={{
                  background: "#ffffff",
                }}
              />
            </Stack>

            <div>
              <NextBtnContainer>
                <PrimaryButton
                  type="submit"
                  sx={{
                    textTransform: "none",
                    backgroundColor:
                      splitTotal !== 100 && revenueSplitTotal !== 100
                        ? "#808080 !important"
                        : "#130453",
                    font: "normal normal bold 12px/15px Roboto",
                    color: "white",
                  }}
                  onClick={handleShowTable}
                  disabled={splitTotal !== 100 && revenueSplitTotal !== 100}
                >
                  Split
                </PrimaryButton>
              </NextBtnContainer>
            </div>
            {splitTotal > 0 && revenueSplitTotal > 0 && isCalc && (
              <Paper
                elevation={0}
                style={{
                  marginTop: "10px",
                  overflow: "hidden",
                  borderRadius: "10px",
                }}
              >
                <Table
                  style={{
                    height: "60px",
                    background: "#ffffff",
                  }}
                >
                  <THead>
                    <TRow
                      style={{
                        height: "50px",
                        backgroundColor: "rgba(48, 111, 188, 1)",
                      }}
                    >
                      <Tabhead
                        style={{
                          font: "normal normal bold 14px/28px Roboto",
                          width: "12%",
                          backgroundColor: "rgba(48, 111, 188, 1)",
                          color: "#ffffff",
                          borderRadius: "10px 0 0 0",
                          textAlign: "center",
                        }}
                      >
                        Room Nights
                      </Tabhead>
                      <Tabhead
                        style={{
                          backgroundColor: "rgba(48, 111, 188, 1)",
                        }}
                      >
                        <Day
                          style={{
                            color: "#ffffff",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          Mon
                        </Day>
                      </Tabhead>
                      <Tabhead
                        style={{
                          backgroundColor: "rgba(48, 111, 188, 1)",
                        }}
                      >
                        <Day
                          style={{
                            color: "#ffffff",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          Tue
                        </Day>
                      </Tabhead>
                      <Tabhead
                        style={{
                          backgroundColor: "rgba(48, 111, 188, 1)",
                        }}
                      >
                        <Day
                          style={{
                            color: "#ffffff",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          Wed
                        </Day>
                      </Tabhead>
                      <Tabhead
                        style={{
                          backgroundColor: "rgba(48, 111, 188, 1)",
                        }}
                      >
                        <Day
                          style={{
                            color: "#ffffff",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          Thu
                        </Day>
                      </Tabhead>
                      <Tabhead
                        style={{
                          backgroundColor: "rgba(48, 111, 188, 1)",
                        }}
                      >
                        <Day
                          style={{
                            color: "#ffffff",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          Fri
                        </Day>
                      </Tabhead>
                      <Tabhead
                        style={{
                          backgroundColor: "rgba(48, 111, 188, 1)",
                        }}
                      >
                        <Day
                          style={{
                            color: "#ffffff",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          Sat
                        </Day>
                      </Tabhead>
                      <Tabhead
                        style={{
                          backgroundColor: "rgba(48, 111, 188, 1)",
                        }}
                      >
                        <Day
                          style={{
                            color: "#ffffff",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          Sun
                        </Day>
                      </Tabhead>
                      <Tabhead
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgba(48, 111, 188, 1)",
                        }}
                      >
                        <Day
                          style={{
                            color: "#ffffff",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          Total
                        </Day>
                      </Tabhead>
                      <Tabhead
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgba(48, 111, 188, 1)",
                          borderRadius: "0 10px 0 0",
                        }}
                      >
                        <Day
                          style={{
                            color: "#ffffff",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          Edit
                        </Day>
                      </Tabhead>
                    </TRow>
                  </THead>

                  <TableBody>
                    <TRow
                      style={{
                        textAlign: "left",
                        height: "50px",
                        background: "#ffffff",
                        color: "#000",
                        borderBottom: "2px solid rgb(224,224,224,1)",
                        borderLeft: "2px solid rgb(224,224,224,1)",
                        borderRight: "2px solid rgb(224,224,224,1)",
                        borderTop: "none",
                      }}
                    >
                      <TCell>
                        <Heading
                          style={{
                            fontWeight: "400",
                            color: "#000",
                            textAlign: "center",
                          }}
                        >
                          Number of DOW
                        </Heading>
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {countDOW[0]}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {countDOW[1]}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {countDOW[2]}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {countDOW[3]}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {countDOW[4]}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {countDOW[5]}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {countDOW[6]}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        <Heading
                          style={{
                            color: "#000",
                            textAlign: "center",
                          }}
                        >
                          {monthDays}
                        </Heading>
                      </TCell>
                      <TCell
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <RemoveIcon
                          style={{
                            color: "#000",
                            textAlign: "center",
                          }}
                        />
                      </TCell>
                    </TRow>
                    <TRow
                      style={{
                        textAlign: "left",
                        height: "50px",
                        background: "#ffffff",
                        color: "#000",
                        border: "2px solid rgb(224,224,224)",
                      }}
                    >
                      <TCell>
                        <Heading
                          style={{
                            fontWeight: "400",
                            color: "#000",
                            textAlign: "center",
                          }}
                        >
                          Total Rooms Per DOW
                        </Heading>
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {((daySplit[0] * room + 49) / 100).toFixed(0)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {((daySplit[1] * room + 49) / 100).toFixed(0)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {((daySplit[2] * room + 49) / 100).toFixed(0)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {((daySplit[3] * room + 49) / 100).toFixed(0)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {((daySplit[4] * room + 49) / 100).toFixed(0)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {((daySplit[5] * room + 49) / 100).toFixed(0)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {((daySplit[6] * room + 49) / 100).toFixed(0)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        <Heading
                          style={{
                            color: room !== totalRoomsPerDow ? "red" : "#000",
                            textAlign: "center",
                          }}
                        >
                          {totalRoomsPerDow}
                        </Heading>
                      </TCell>
                      <TCell
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <RemoveIcon
                          style={{
                            color: "#000",
                          }}
                        />
                      </TCell>
                    </TRow>
                    <TRow
                      style={{
                        textAlign: "left",
                        height: "50px",
                        background: "#ffffff",
                        color: "#000",
                        border: "2px solid rgb(224,224,224)",
                      }}
                    >
                      <TCell
                        style={{
                          height: "50px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Heading
                          style={{
                            fontWeight: "400",
                            color: "#000",
                            textAlign: "center",
                          }}
                        >
                          Room Allocation by DOW
                        </Heading>
                      </TCell>
                      {[...Array(7)].map((c, pos) => (
                        <React.Fragment key={pos}>
                          <TCell>
                            <Input
                              classes={{
                                underline: "underline",
                                input: "inputCenter",
                              }}
                              disableUnderline
                              type="numeric"
                              value={(
                                Math.round(
                                  ((daySplit[pos] * room) /
                                    (countDOW[pos] * 100)) *
                                    100
                                ) / 100
                              ).toFixed(0)}
                              onChange={(e) =>
                                handleAllocationChange(
                                  pos,
                                  parseInt(e.target.value)
                                )
                              }
                            ></Input>
                          </TCell>
                        </React.Fragment>
                      ))}

                      <TCell>
                        <Heading>{}</Heading>
                      </TCell>
                      <TCell
                        style={{
                          color: "rgba(48, 111, 188, 1)",
                          fontWeight: "700",
                          textAlign: "center",
                        }}
                      >
                        edit
                      </TCell>
                    </TRow>
                    <TRow
                      style={{
                        textAlign: "left",
                        height: "50px",
                        background: "#ffffff",
                        color: "#000",
                        border: "2px solid rgb(224,224,224)",
                      }}
                    >
                      <TCell>
                        <Heading
                          style={{
                            fontWeight: "400",
                            color: "#000",
                            textAlign: "center",
                          }}
                        >
                          Total Revenue Per DOW{" "}
                        </Heading>
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {Math.round((revenueDaySplit[0] * revenue) / 100)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {Math.round((revenueDaySplit[1] * revenue) / 100)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {Math.round((revenueDaySplit[2] * revenue) / 100)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {Math.round((revenueDaySplit[3] * revenue) / 100)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {Math.round((revenueDaySplit[4] * revenue) / 100)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {Math.round((revenueDaySplit[5] * revenue) / 100)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {Math.round((revenueDaySplit[6] * revenue) / 100)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        <Heading
                          style={{
                            color: "#000",
                            textAlign: "center",
                          }}
                        >
                          {revenueDowTotal}
                        </Heading>
                      </TCell>
                      <TCell
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <RemoveIcon
                          style={{
                            color: "#000",
                          }}
                        />
                      </TCell>
                    </TRow>

                    <TRow
                      style={{
                        textAlign: "left",
                        height: "50px",
                        background: "#ffffff",
                        color: "#000",
                        border: "2px solid rgb(224,224,224)",
                        borderRadius: "0 0 0 10px",
                      }}
                    >
                      <TCell>
                        <Heading
                          style={{
                            fontWeight: "400",
                            color: "#000",
                            textAlign: "center",
                          }}
                        >
                          Revenue Allocation by DOW
                        </Heading>{" "}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {Math.round(
                          (revenueDaySplit[0] * revenue) / (countDOW[0] * 100)
                        )}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {Math.round(
                          (revenueDaySplit[1] * revenue) / (countDOW[1] * 100)
                        )}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {Math.round(
                          (revenueDaySplit[2] * revenue) / (countDOW[2] * 100)
                        )}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {Math.round(
                          (revenueDaySplit[3] * revenue) / (countDOW[3] * 100)
                        )}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {Math.round(
                          (revenueDaySplit[4] * revenue) / (countDOW[4] * 100)
                        )}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {Math.round(
                          (revenueDaySplit[5] * revenue) / (countDOW[5] * 100)
                        )}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {Math.round(
                          (revenueDaySplit[6] * revenue) / (countDOW[6] * 100)
                        )}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {}
                      </TCell>
                      <TCell
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <RemoveIcon
                          style={{
                            color: "#000",
                          }}
                        />
                      </TCell>
                    </TRow>

                    <TRow
                      style={{
                        textAlign: "left",
                        height: "50px",
                        background: "#ffffff",
                        color: "#000",
                        border: "2px solid rgb(224,224,224)",
                        borderRadius: "0 0 0 10px",
                      }}
                    >
                      <TCell>
                        <Heading
                          style={{
                            fontWeight: "400",
                            color: "#000",
                            textAlign: "center",
                          }}
                        >
                          ADR by DOW
                        </Heading>{" "}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {(
                          Math.round((revenueDaySplit[0] * revenue) / 100) /
                          ((daySplit[0] * room + 49) / 100).toFixed(0)
                        ).toFixed(2)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {(
                          Math.round((revenueDaySplit[1] * revenue) / 100) /
                          ((daySplit[1] * room + 49) / 100).toFixed(0)
                        ).toFixed(2)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {(
                          Math.round((revenueDaySplit[2] * revenue) / 100) /
                          ((daySplit[2] * room + 49) / 100).toFixed(0)
                        ).toFixed(2)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {(
                          Math.round((revenueDaySplit[3] * revenue) / 100) /
                          ((daySplit[3] * room + 49) / 100).toFixed(0)
                        ).toFixed(2)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {(
                          Math.round((revenueDaySplit[4] * revenue) / 100) /
                          ((daySplit[4] * room + 49) / 100).toFixed(0)
                        ).toFixed(2)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {(
                          Math.round((revenueDaySplit[5] * revenue) / 100) /
                          ((daySplit[5] * room + 49) / 100).toFixed(0)
                        ).toFixed(2)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {(
                          Math.round((revenueDaySplit[6] * revenue) / 100) /
                          ((daySplit[6] * room + 49) / 100).toFixed(0)
                        ).toFixed(2)}
                      </TCell>
                      <TCell
                        style={{
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        <Heading
                          style={{
                            color: "#000",
                            textAlign: "center",
                          }}
                        >
                          {(revenueDowTotal / totalRoomsPerDow).toFixed(2)}
                        </Heading>
                      </TCell>
                      <TCell
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <RemoveIcon
                          style={{
                            color: "#000",
                          }}
                        />
                      </TCell>
                    </TRow>
                  </TableBody>
                </Table>
              </Paper>
            )}
            <Snackbar
              open={openSnackBar}
              autoHideDuration={2000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="error">
                Value can't be grater than 100
              </Alert>
            </Snackbar>
            {isCalc && (
              <NextBtnContainer>
                <TextOnlyTooltip
                  disableFocusListener
                  disableTouchListener
                  title={
                    room !== totalRoomsPerDow
                      ? "Total Rooms Per DOW Should match Room Nights"
                      : ""
                  }
                  placement="right-center"
                  arrow
                >
                  <PrimaryButton
                    type="submit"
                    style={{
                      textTransform: "none",
                      backgroundColor: "#130453",
                      font: "normal normal bold 12px/15px Roboto",
                      color: "white",

                      cursor: room !== totalRoomsPerDow ? "not-allowed" : "",
                    }}
                    onClick={handleSaveData}
                    loading={btnLoading ? "true" : undefined}
                  >
                    Save
                  </PrimaryButton>
                </TextOnlyTooltip>
              </NextBtnContainer>
            )}
            <BudgetRedirectingDialog
              open={isDialogOpen}
              handleClose={() => setIsDialogOpen(false)}
            />
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={open}
              autoHideDuration={3000}
              onClose={() => setOpen(false)}
            >
              {networkMsg !== "Budget Allocated Successfully" ? (
                <SnackbarContent
                  style={{ backgroundColor: "#CA3433" }}
                  message={networkMsg}
                />
              ) : (
                <SnackbarContent
                  style={{ backgroundColor: "#228b22" }}
                  message={networkMsg}
                />
              )}
            </Snackbar>
          </AppContainer>
        ) : (
          <LoadingPage />
        )}
      </Container>
    </ClassWrapper>
  );
}
