import {
  Box,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import React from "react";
import { styled } from "@mui/system";
import { SecondaryButton, TableLoading } from "./../../sdk";

import { useParams } from "react-router";
import {
  CancelButton,
  CardContainer,
  Header,
  SaveButton,
  Tabhead,
  TCell,
  TContainer,
  TextFieldSmall,
  THead,
  TRow,
} from "./../Styles";

const TableHead = styled(THead)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ColorCode = styled(Typography)``;
export const TCellNew = styled(TCell)`
  &.MuiTableCell-root {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const TCellNew1 = styled(TCell)`
  &.MuiTableCell-root {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
`;
const EditButton = styled(Edit)`
  height: 18px;
  width: 18px;
  color: #306fbc;
`;
export function DemandLevelCard({
  demandLevels,
  isInEditMode,
  setIsInEditMode,
  isCancelled,
  setIsCancelled,
  isSaved,
  setIsSaved,
  onChange,
  open,
  handleCloseStatus,
  networkMsg,
  dlLoading,
  editCityDemandLevel,
  handleEditCityDLChange,
  isCityDLEditMode,
  setCityDemandLevel,
  setIsCityDLInEditMode,
  checkCityDLValidation,
}) {
  const { hotelId } = useParams();

  return (
    <>
      <CardContainer>
        <Header>
          <Box className="title">City Demand Level</Box>
          {!isCityDLEditMode ? (
            <SecondaryButton style={{ border: "none" }}></SecondaryButton>
          ) : (
            <Box>
              <CancelButton
                onClick={() => {
                  setIsCancelled(!isCancelled);
                  setIsInEditMode(!isInEditMode);
                  setIsCityDLInEditMode(!isCityDLEditMode);
                }}
              >
                Cancel
              </CancelButton>
              <SaveButton
                onClick={() => {
                  setIsSaved(!isSaved);
                  setIsInEditMode(!isInEditMode);
                  setIsCityDLInEditMode(!isCityDLEditMode);
                }}
                disabled={checkCityDLValidation}
              >
                Save
              </SaveButton>
            </Box>
          )}
        </Header>
        {!dlLoading ? (
          <TContainer style={{ maxHeight: "50vh" }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{
                width: "100%",
                border: "1px solid rgba(0, 0, 0, 0.12)",

                borderRadius: "5px",
              }}
            >
              <THead style={{ height: "1px", padding: "0px" }}>
                <TRow>
                  <Tabhead $isineditmode={isInEditMode}>Symbol</Tabhead>
                  <Tabhead $isineditmode={isInEditMode}>Value</Tabhead>
                  <Tabhead $isineditmode={isInEditMode}>Actions</Tabhead>
                </TRow>
              </THead>
              <TableBody>
                {demandLevels.map((row, idx) => (
                  <TRow key={idx}>
                    <TCellNew $isineditmode={isInEditMode}>
                      <ColorCode
                        style={{
                          color: row.color,
                        }}
                      >
                        {row.symbol}
                      </ColorCode>
                    </TCellNew>
                    <TCellNew1 $isineditmode={isInEditMode}>
                      {editCityDemandLevel[idx] ? (
                        <TextFieldSmall
                          type="number"
                          value={row.value}
                          onChange={(e) =>
                            onChange(
                              idx,
                              "value",
                              e.target.value && parseFloat(e.target.value)
                            )
                          }
                        />
                      ) : !row.value ? (
                        <ColorCode style={{ color: row.color }}>0</ColorCode>
                      ) : (
                        <ColorCode style={{ color: row.color }}>
                          {row.value}
                        </ColorCode>
                      )}
                    </TCellNew1>
                    <TCellNew>
                      <EditButton
                        onClick={() => {
                          handleEditCityDLChange(idx);
                        }}
                      />
                    </TCellNew>
                  </TRow>
                ))}
              </TableBody>
            </Table>
          </TContainer>
        ) : (
          <TableLoading />
        )}
      </CardContainer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseStatus}
      >
        <SnackbarContent
          style={{ backgroundColor: "#CA3433" }}
          message={networkMsg}
        />
      </Snackbar>
    </>
  );
}
