import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "../sdk";

export default function useKeyword() {
  const [searchInput, setSearchInput] = useState("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [error, setError] = useState("");
  const [text, setText] = useState("");
  const [type, setType] = useState("generic");
  const [city, setCity] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [networkMsg, setnetworkMsg] = useState("");
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const { authFetch } = useAuth();

  const getKeywords = useCallback(async () => {
    try {
      const body = {
        limit: rowsPerPage,
        page: page,
        search: searchInput,
      };
      const { get } = await authFetch({
        path: `/keyword`,
      });
      const { data, response, error } = await get(
        `?${new URLSearchParams(body)}`
      );
      if (response.status === 400) {
        setError(data.messageToUser);
      }
      if (data) {
        setKeywords(data?.keywordDetails);
        setCount(data?.totalCount);
      }
    } catch (err) {
      console.log(err);
    }
  }, [rowsPerPage, page, searchInput, authFetch]);

  const getCities = useCallback(async () => {
    try {
      const { get } = await authFetch({
        path: `/all-cities`,
      });
      const { data, response } = await get();

      if (response?.ok) {
        setCityList(data);
      } else {
        setnetworkMsg(data?.messageToUser || "Something Went Wrong");
      }
    } catch (err) {
      console.log(err);
    }
  }, [authFetch]);

  useEffect(() => {
    if (type === "city_based") getCities();
  }, [getCities, type]);

  const handleAdd = useCallback(async () => {
    try {
      setLoading(true);
      const { post } = await authFetch({
        path: `/keyword`,
      });
      const { data, response } = await post({
        city: city,
        keyword: text,
      });
      if (response.status === 200) {
        setnetworkMsg("Keyword Added Successfully");
        getKeywords();
      } else {
        setnetworkMsg(data?.messageToUser || "Something Went Wrong");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setText("");
    setCity(null);
    setOpenModal(false);
  }, [authFetch, city, getKeywords, text]);

  const handleDelete = useCallback(
    async (id) => {
      try {
        const { del } = await authFetch({
          path: `/keyword?id=${id}`,
        });

        const { data, response } = await del();
        if (response.status === 200) {
          setnetworkMsg("Keyword Deleted Successfully");
          getKeywords();
        } else {
          setnetworkMsg(data?.messageToUser || "Something Went Wrong");
        }
      } catch (err) {
        console.log(err);
      }
      setConfirmationDialog(null);
    },
    [authFetch, getKeywords]
  );

  useEffect(() => {
    getKeywords();
  }, [getKeywords]);
  return {
    searchInput,
    setSearchInput,
    page,
    count,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    setPage,
    keywords,
    openModal,
    setOpenModal,
    handleAdd,
    text,
    setText,
    handleDelete,
    networkMsg,
    setnetworkMsg,
    setConfirmationDialog,
    confirmationDialog,
    setCity,
    city,
    type,
    setType,
    cityList,
    loading,
  };
}
