import { useEffect, useState } from "react";
import { onUniqueProp, useAuth } from "../../sdk";

const initalState = [
  {
    day: 0,
    value: 0,
  },
  {
    day: 1,
    value: 0,
  },
  {
    day: 2,
    value: 0,
  },
  {
    day: 3,
    value: 0,
  },
  {
    day: 4,
    value: 0,
  },
  {
    day: 5,
    value: 0,
  },
  {
    day: 6,
    value: 0,
  },
];

const initialValue = [false, false, false, false, false, false, false];
export function useDayOfWeek(hotelId, copyFromHotelId, DOW) {
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [isEditDaysofWeek, setEditDaysOfWeek] = useState(initialValue);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleCloseStatus = () => {
    setOpen(false);
  };

  const [networkMsg, setnetworkMsg] = useState(null);
  const { token, authFetch } = useAuth();
  useEffect(() => {
    if (!token) return;
    refreshDaysOfWeek();
  }, [token, hotelId]);

  function isValid(dow) {
    return dow.value;
  }

  useEffect(() => {
    if (isInEditMode) {
      const allValidEntries = daysOfWeek.every(isValid);
      if (allValidEntries) {
        setDaysOfWeek([...daysOfWeek]);
      }
    } else if (isSaved) {
      updateDaysOfWeek();
    } else if (isCancelled) {
      refreshDaysOfWeek();
    }
  }, [isInEditMode]);
  useEffect(() => {
    if (copyFromHotelId === null && !DOW) return;
    if (DOW) refreshDaysOfWeek(copyFromHotelId);
  }, [DOW]);

  function isIterable(obj) {
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === "function";
  }

  async function refreshDaysOfWeek(anotherHotelId = false) {
    const hotelIdToUse = anotherHotelId ? anotherHotelId : hotelId;
    setLoading(true);
    const initialValueNew = [false, false, false, false, false, false, false];
    const { get } = await authFetch({
      path: `/hotel/${hotelIdToUse}/day-of-week`,
    });
    const { data, error } = await get();
    if (error) {
      setnetworkMsg("Can't Fetch Day of Week Data");
      setOpen(true);
      console.log(error);
    }
    setIsCancelled(false);
    if (data && isIterable(data)) {
      setDaysOfWeek([...data, ...initalState].filter(onUniqueProp("day")));
    } else {
      setDaysOfWeek([...initalState].filter(onUniqueProp("day")));
    }
    setEditDaysOfWeek(initialValueNew);
    if (anotherHotelId) {
      setIsInEditMode(true);
    }
    setLoading(false);
  }
  async function updateDaysOfWeek() {
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/day-of-week/all`,
    });
    const { data, response } = await post(
      daysOfWeek.map((dow) => {
        if (!Boolean(dow.value)) {
          return {
            ...dow,
            value: null,
          };
        } else {
          return dow;
        }
      })
    );
    if (!response?.ok) {
      setnetworkMsg(data?.messageToUser || "Can't Update Day of Week");
      setOpen(true);
    }
    setIsSaved(false);
    refreshDaysOfWeek();
  }
  const changeHandler = (index, key, value) => {
    setDaysOfWeek((prevState) => {
      return prevState.map((row, idx) =>
        idx === index
          ? {
              ...row,
              [key]: value,
            }
          : row
      );
    });
  };
  const handleEditDaysOfWeekChange = (index) => {
    const result = isEditDaysofWeek.map((data, pos) => {
      if (pos === index) {
        return (isEditDaysofWeek[index] = !isEditDaysofWeek[index]);
      } else {
        return isEditDaysofWeek[pos];
      }
    });
    const checkEditMode = result.every((d) => d === false);
    if (checkEditMode) setIsInEditMode(false);
    else setIsInEditMode(true);
    setEditDaysOfWeek(result);
  };

  return {
    daysOfWeek,
    dowIsInEditMode: isInEditMode,
    setDowIsInEditMode: setIsInEditMode,
    dowIsCancelled: isCancelled,
    dowSetIsCancelled: setIsCancelled,
    dowIsSaved: isSaved,
    dowSetIsSaved: setIsSaved,
    dowChangeHandler: changeHandler,
    dowopen: open,
    dowhandleCloseStatus: handleCloseStatus,
    downetworkMsg: networkMsg,
    dowLoading: Loading,
    isEditDaysofWeek,
    handleEditDaysOfWeekChange: handleEditDaysOfWeekChange,
  };
}
