import React, { useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { useHistory, useParams } from "react-router-dom";
import { getISODate, LoadingPage, MonthPicker, Nodata, useAuth } from "../sdk";
import LeadTimevsPickupTable from "./components/LeadTimevsPickupTable";
import {
  Body,
  Graph,
  Header,
  Label,
  Page,
  SubHeader,
  TableCard,
} from "./Styles";
import { PreciumDisabledPage } from "../sdk/components/PreciumDiabledPage";
const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export default function LeadTimevsPickup({ setPageHeader }) {
  const { token, authFetch, currentHotel } = useAuth();
  const { hotelId, DATE } = useParams();

  const [selectedData, setSelectedData] = useState([]);
  const [comparitiveData, setComparitiveData] = useState([]);
  const [graphData, setGraphData] = useState({ datasets: [], labels: [] });
  const [Loading, setLoading] = useState();

  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState(() => {
    if (!isNaN(new Date(DATE).getTime())) {
      const [year, mon, day] = DATE.split("-");
      if (year && mon && day) {
        if (day && day.length === 1) {
          const validDate = [year, mon, "0" + day].join("-");
          return new Date(validDate);
        } else {
          return new Date(DATE);
        }
      } else {
        return new Date(new Date().setDate(new Date().getDate() - 1));
      }
    } else {
      return new Date(new Date().setDate(new Date().getDate() - 1));
    }
  });
  const [isComparitive, setIsComparitive] = useState(false);
  const [comparitiveDate, setComparitiveDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 8))
  );

  useEffect(() => {
    if (!token && !hotelId) {
      return;
    } else {
      window.scrollTo(0, 0);
    }
  }, [token, hotelId]);

  useEffect(() => {
    setPageHeader("Lead Time - Pick-Up Monthly");
    return () => {
      setPageHeader("");
    };
  }, []);

  useEffect(() => {
    if (!token && !hotelId) {
      return;
    } else {
      fetchData();
    }
  }, [token, hotelId, selectedDate]);

  useEffect(() => {
    if (!token) {
      return;
    } else {
      if (isComparitive && comparitiveDate) {
        fetchComparitiveData();
      } else {
        setComparitiveData([]);
      }
    }
  }, [token, hotelId, comparitiveDate, isComparitive]);

  useEffect(() => {
    if (!token && !hotelId) {
      return;
    }
    history.replace(
      `/hotel/${hotelId}/analytics/lead_time_vs_pickup_dow/${
        getISODate(selectedDate).split("T")[0]
      }`
    );
  }, [selectedDate, hotelId, token]);

  useEffect(() => {
    if (!token && !hotelId) {
      return;
    } else {
      if (selectedData) dataForGraph();
    }
  }, [token, hotelId, selectedData]);

  useEffect(() => {
    if (!token && !hotelId) {
      return;
    } else {
      if (comparitiveData) dataForGraph();
    }
  }, [token, hotelId, comparitiveData]);

  const graphOptions = useMemo(() => {
    return {
      maintainAspectRatio: false,
      title: {
        text: "Lead Time - Pick-Up Monthly",
        display: true,
        fontFamily: "Roboto",
        fontSize: 20,
        fontColor: "black",
        fontStyle: "normal",
      },
      legend: {
        position: "bottom",
        align: "left",
        labels: {
          fontFamily: "Roboto",
          fontSize: 14,
          boxWidth: 14,
          filter: function (item, chart) {
            return !item.text.includes("no_label");
          },
        },
      },
      tooltips: {
        mode: "index",
        bodyFontFamily: "Roboto",
        backgroundColor: "black",
        callbacks: {
          label: function (tooltipItem) {
            let array = [];
            array.push(
              `${weekDays[tooltipItem.datasetIndex]} : ${tooltipItem.yLabel}`
            );

            return array;
          },
        },
      },
      elements: {
        line: {
          fill: false,
          borderColor: "#FBBC05",
          pointColor: "#FBBC05",
          backgroundColor: "#FBBC05",
          tension: 0,
        },
        point: {
          borderColor: "#FBBC05",
          backgroundColor: "#FBBC05",
        },
      },
      scales: {
        xAxes: [
          {
            offset: true,
            display: true,
            gridLines: {
              display: true,
              drawTicks: true,
              drawOnChartArea: false,
            },
            ticks: {
              fontFamily: "Roboto",
              fontColor: "black",
              fontSize: 10,
            },
            scaleLabel: {
              display: true,
              labelString: "Lead Time",
              fontColor: "black",
              fontFamily: "Roboto",
            },
          },
        ],
        yAxes: [
          {
            type: "linear",
            display: true,
            position: "left",

            gridLines: {
              display: true,
              drawTicks: true,
              drawOnChartArea: true,
            },
            ticks: {
              beginAtZero: true,
              fontFamily: "Roboto",
              fontColor: "black",
              fontSize: 10,
              suggestedMin: 0,
              suggestedMax: 20,
            },
            scaleLabel: {
              display: true,
              labelString: "Pick-Up",
              fontColor: "black",
              fontFamily: "Roboto",
            },
          },
        ],
      },
    };
  }, [selectedData, comparitiveData]);

  function generateData(data) {
    if (data.details !== null) {
      const details = data.details;
      let prev = -1;
      let generatedData = [];
      for (let i = 0; i < details.length; i++) {
        let diff = details[i].leadTime - prev - 1;
        generatedData.push({
          ...details[i],
          pickup: Math.round(details[i].pickup),
        });

        while (diff > 0) {
          generatedData.push({
            leadTime: details[i].leadTime - diff,
            pickup: 0,
          });

          --diff;
        }

        prev = details[i].leadTime;
      }

      if (generateData.length == 31) {
        generateData.push({
          leadTime: -1,
          pickup: 0,
        });
      }

      generatedData.sort((data1, data2) => data1.leadTime - data2.leadTime);
      return {
        ...data,
        details: generatedData,
      };
    }
  }

  function calculateDataSummary(rawData) {
    const continousRawData = rawData.map((data) => generateData(data));
    const actual = {
      LeadTime: "Actual",
      mon: continousRawData[1]?.details[0]?.pickup,
      tue: continousRawData[2]?.details[0]?.pickup,
      wed: continousRawData[3]?.details[0]?.pickup,
      thu: continousRawData[4]?.details[0]?.pickup,
      fri: continousRawData[5]?.details[0]?.pickup,
      sat: continousRawData[6]?.details[0]?.pickup,
      sun: continousRawData[0]?.details[0]?.pickup,
    };
    const D0 = {
      LeadTime: "D0",
      mon: continousRawData[1]?.details[1]?.pickup,
      tue: continousRawData[2]?.details[1]?.pickup,
      wed: continousRawData[3]?.details[1]?.pickup,
      thu: continousRawData[4]?.details[1]?.pickup,
      fri: continousRawData[5]?.details[1]?.pickup,
      sat: continousRawData[6]?.details[1]?.pickup,
      sun: continousRawData[0]?.details[1]?.pickup,
    };
    const D1 = {
      LeadTime: "D1",
      mon: continousRawData[1]?.details[2]?.pickup,
      tue: continousRawData[2]?.details[2]?.pickup,
      wed: continousRawData[3]?.details[2]?.pickup,
      thu: continousRawData[4]?.details[2]?.pickup,
      fri: continousRawData[5]?.details[2]?.pickup,
      sat: continousRawData[6]?.details[2]?.pickup,
      sun: continousRawData[0]?.details[2]?.pickup,
    };
    const D2 = {
      LeadTime: "D2",
      mon: continousRawData[1]?.details[3]?.pickup,
      tue: continousRawData[2]?.details[3]?.pickup,
      wed: continousRawData[3]?.details[3]?.pickup,
      thu: continousRawData[4]?.details[3]?.pickup,
      fri: continousRawData[5]?.details[3]?.pickup,
      sat: continousRawData[6]?.details[3]?.pickup,
      sun: continousRawData[0]?.details[3]?.pickup,
    };
    const D3 = {
      LeadTime: "D3",
      mon: continousRawData[1]?.details[4]?.pickup,
      tue: continousRawData[2]?.details[4]?.pickup,
      wed: continousRawData[3]?.details[4]?.pickup,
      thu: continousRawData[4]?.details[4]?.pickup,
      fri: continousRawData[5]?.details[4]?.pickup,
      sat: continousRawData[6]?.details[4]?.pickup,
      sun: continousRawData[0]?.details[4]?.pickup,
    };
    const D4_7 = {
      LeadTime: "4-7 Days",
      mon:
        continousRawData[1]?.details.slice(5, 9).length === 0
          ? NaN
          : continousRawData[1]?.details
              ?.slice(5, 9)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 4,
      tue:
        continousRawData[2]?.details.slice(5, 9).length === 0
          ? NaN
          : continousRawData[2]?.details
              ?.slice(5, 9)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 4,
      wed:
        continousRawData[3]?.details.slice(5, 9).length === 0
          ? NaN
          : continousRawData[3]?.details
              ?.slice(5, 9)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 4,
      thu:
        continousRawData[4]?.details.slice(5, 9).length === 0
          ? NaN
          : continousRawData[4]?.details
              ?.slice(5, 9)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 4,
      fri:
        continousRawData[5]?.details.slice(5, 9).length === 0
          ? NaN
          : continousRawData[5]?.details
              ?.slice(5, 9)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 4,
      sat:
        continousRawData[6]?.details.slice(5, 9).length === 0
          ? NaN
          : continousRawData[6]?.details
              ?.slice(5, 9)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 4,
      sun:
        continousRawData[0]?.details.slice(5, 9).length === 0
          ? NaN
          : continousRawData[0]?.details
              ?.slice(5, 9)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 4,
    };
    const D8_14 = {
      LeadTime: "8-14 Days",
      mon:
        continousRawData[1]?.details.slice(9, 16).length === 0
          ? NaN
          : continousRawData[1]?.details
              ?.slice(9, 16)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 7,
      tue:
        continousRawData[2]?.details.slice(9, 16).length === 0
          ? NaN
          : continousRawData[2]?.details
              ?.slice(9, 16)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 7,
      wed:
        continousRawData[3]?.details.slice(9, 16).length === 0
          ? NaN
          : continousRawData[3]?.details
              ?.slice(9, 16)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 7,
      thu:
        continousRawData[4]?.details.slice(9, 16).length === 0
          ? NaN
          : continousRawData[4]?.details
              ?.slice(9, 16)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 7,
      fri:
        continousRawData[5]?.details.slice(9, 16).length === 0
          ? NaN
          : continousRawData[5]?.details
              ?.slice(9, 16)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 7,
      sat:
        continousRawData[6]?.details.slice(9, 16).length === 0
          ? NaN
          : continousRawData[6]?.details
              ?.slice(9, 16)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 7,
      sun:
        continousRawData[0]?.details.slice(9, 16).length === 0
          ? NaN
          : continousRawData[0]?.details
              ?.slice(9, 16)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 7,
    };
    const D15_21 = {
      LeadTime: "15-21 Days",
      mon:
        continousRawData[1]?.details.slice(16, 23).length === 0
          ? NaN
          : continousRawData[1]?.details
              .slice(16, 23)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 7,
      tue:
        continousRawData[2]?.details.slice(16, 23).length === 0
          ? NaN
          : continousRawData[2]?.details
              .slice(16, 23)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 7,
      wed:
        continousRawData[3]?.details.slice(16, 23).length === 0
          ? NaN
          : continousRawData[3]?.details
              .slice(16, 23)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 7,
      thu:
        continousRawData[4]?.details.slice(16, 23).length === 0
          ? NaN
          : continousRawData[4]?.details
              .slice(16, 23)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 7,
      fri:
        continousRawData[5]?.details.slice(16, 23).length === 0
          ? NaN
          : continousRawData[5]?.details
              .slice(16, 23)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 7,
      sat:
        continousRawData[6]?.details.slice(16, 23).length === 0
          ? NaN
          : continousRawData[6]?.details
              .slice(16, 23)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 7,
      sun:
        continousRawData[0]?.details.slice(16, 23).length === 0
          ? NaN
          : continousRawData[0]?.details
              .slice(16, 23)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 7,
    };
    const D22_30 = {
      LeadTime: "22-30 Days",
      mon:
        continousRawData[1]?.details.slice(23).length === 0
          ? NaN
          : continousRawData[1]?.details
              .slice(23)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 9,
      tue:
        continousRawData[2]?.details.slice(23).length === 0
          ? NaN
          : continousRawData[2]?.details
              .slice(23)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 9,
      wed:
        continousRawData[3]?.details.slice(23).length === 0
          ? NaN
          : continousRawData[3]?.details
              .slice(23)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 9,
      thu:
        continousRawData[4]?.details.slice(23).length === 0
          ? NaN
          : continousRawData[4]?.details
              .slice(23)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 9,
      fri:
        continousRawData[5]?.details.slice(23).length === 0
          ? NaN
          : continousRawData[5]?.details
              .slice(23)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 9,
      sat:
        continousRawData[6]?.details.slice(23).length === 0
          ? NaN
          : continousRawData[6]?.details
              .slice(23)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 9,
      sun:
        continousRawData[0]?.details.slice(23).length === 0
          ? NaN
          : continousRawData[0]?.details
              .slice(23)
              .reduce((acc, prev) => acc + prev.pickup, 0) / 9,
    };

    return [actual, D0, D1, D2, D3, D4_7, D8_14, D15_21, D22_30];
  }

  async function fetchData() {
    setLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/lead-time-vs-pickup/month/${
        getISODate(selectedDate).split("T")[0]
      }`,
    });
    const { data, error } = await get();
    if (data && data.length) {
      const sortedData = data
        .slice()
        .sort((data1, data2) => data1.day - data2.day);
      const summarizedData = calculateDataSummary(sortedData);
      setSelectedData(summarizedData);
    } else {
      setSelectedData([]);
    }
    setLoading(false);
  }
  async function fetchComparitiveData() {
    setLoading(true);

    const { get } = await authFetch({
      path: `/hotel/${hotelId}/lead-time-vs-pickup/month/${
        getISODate(comparitiveDate).split("T")[0]
      }`,
    });
    const { data, error } = await get();
    if (data && data.length) {
      const sortedData = data
        .slice()
        .sort((data1, data2) => data1.day - data2.day);

      const summarizedData = calculateDataSummary(sortedData);

      setComparitiveData(summarizedData);
    } else {
      setComparitiveData([]);
    }
    setLoading(false);
  }

  const handleSelectedDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleComparitiveDateChange = (date) => {
    setComparitiveDate(date);
  };
  function dataForGraph() {
    if (selectedData) {
      const comperativedata =
        comparitiveData.length > 0
          ? [
              {
                label: "no_label",
                data: comparitiveData.map((data) => Math.round(data.mon)),
                borderColor: "#F09152",
                pointBorderColor: "#F09152",
                pointBackgroundColor: "#F09152",
              },
              {
                label: "no_label",

                data: comparitiveData.map((data) => Math.round(data.tue)),
                borderColor: "#4472C4",
                pointBorderColor: "#4472C4",
                pointBackgroundColor: "#4472C4",
              },
              {
                label: "no_label",

                data: comparitiveData.map((data) => Math.round(data.wed)),
                borderColor: "#B3B3B3",
                pointBorderColor: "#B3B3B3",
                pointBackgroundColor: "#B3B3B3",
              },
              {
                label: "no_label",

                data: comparitiveData.map((data) => Math.round(data.thu)),
                borderColor: "#FEC134",
                pointBorderColor: "#FEC134",
                pointBackgroundColor: "#FEC134",
              },
              {
                label: "no_label",

                data: comparitiveData.map((data) => Math.round(data.fri)),
                borderColor: "#5B9BD5",
                pointBorderColor: "#5B9BD5",
                pointBackgroundColor: "#5B9BD5",
              },
              {
                label: "no_label",

                data: comparitiveData.map((data) => Math.round(data.sat)),
                borderColor: "#87BA64",
                pointBorderColor: "#87BA64",
                pointBackgroundColor: "#87BA64",
              },
              {
                label: "no_label",

                data: comparitiveData.map((data) => Math.round(data.sun)),
                borderColor: "#254378",
                pointBorderColor: "#254378",
                pointBackgroundColor: "#254378",
              },
            ]
          : [undefined];
      const array = {
        labels: selectedData.map((data) => data?.LeadTime),
        datasets: [
          {
            label: `Monday`,
            data: selectedData.map((data) => Math.round(data.mon)),
            borderColor: "#0D2667",
            pointBorderColor: "#0D2667",
            pointBackgroundColor: "#0D2667",
          },
          {
            label: `Tuesday`,
            data: selectedData.map((data) => Math.round(data.tue)),
            borderColor: "#1068FA",
            pointBorderColor: "#1068FA",
            pointBackgroundColor: "#1068FA",
          },
          {
            label: `Wednesday`,
            data: selectedData.map((data) => Math.round(data.wed)),
            borderColor: "#97BEE0",
            pointBorderColor: "#97BEE0",
            pointBackgroundColor: "#97BEE0",
          },
          {
            label: `Thursday`,
            data: selectedData.map((data) => Math.round(data.thu)),
            borderColor: "#FBBD33",
            pointBorderColor: "#FBBD33",
            pointBackgroundColor: "#FBBD33",
          },
          {
            label: `Friday`,
            data: selectedData.map((data) => Math.round(data.fri)),
            borderColor: "#B68D40",
            pointBorderColor: "#B68D40",
            pointBackgroundColor: "#B68D40",
          },
          {
            label: `Saturday`,
            data: selectedData.map((data) => Math.round(data.sat)),
            borderColor: "#73E831",
            pointBorderColor: "#73E831",
            pointBackgroundColor: "#73E831",
          },
          {
            label: `Sunday`,
            data: selectedData.map((data) => Math.round(data.sun)),
            borderColor: "#5B306F",
            pointBorderColor: "#5B306F",
            pointBackgroundColor: "#5B306F",
          },
          ...comperativedata,
        ].filter(Boolean),
      };
      setGraphData(array);
    }
  }
  return currentHotel?.isPreciumEnabled ? (
    <Page>
      <Body>
        <Header>
          <SubHeader>
            <Label>Lead Time For</Label>
            <MonthPicker value={selectedDate} onChange={setSelectedDate} />
          </SubHeader>
        </Header>
        {!Loading ? (
          selectedData.length > 0 ? (
            <div style={{ height: "85%" }}>
              <TableCard style={{ height: "70%", marginBottom: "20px" }}>
                <Graph>
                  <Line data={graphData} options={graphOptions} />
                </Graph>
              </TableCard>
              <TableCard>
                <LeadTimevsPickupTable selectedData={selectedData} />
              </TableCard>
            </div>
          ) : (
            <Nodata />
          )
        ) : (
          <LoadingPage />
        )}
      </Body>
    </Page>
  ) : (
    <PreciumDisabledPage />
  );
}
