import { MenuItem } from "@mui/material";
import { styled } from "@mui/system";
import { Close } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useAuth } from "../../sdk";
import { DLTypography, DemandLevelContainer, Select } from "../Styles";
import {
  AddContact,
  ButtonSection,
  Cross,
  DatePickerSection,
  DayBDL as Day,
  DeleteEvent,
  Dot,
  EventContainer,
  EventSection,
  EventWrapper,
  Header,
  Label,
  Model,
  P,
  SingleEventContainer,
  SingleEventContainerSelected,
  Text,
  TextArea,
  Wrapper,
} from "./style";
const ClassWrapper = styled(Model)(({ theme }) => ({
  ".formControl": {
    minWidth: 280,
    height: 36,
  },
  ".select": {
    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
    height: 25,
  },
  ".dropdownStyle": {
    background: "#160E39",
    borderRadius: 4,
    color: "white",
  },
  ".icon": {
    fill: "black",
  },
  ".input": {
    font: "normal normal normal 16px/20px Roboto",
    color: "#000000",
  },
}));

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const AddEventsModel = ({
  setPopOverIdx,
  item,
  demandLevels,
  generateBatchBaseDemandLevels,
  addDemandLevel,
  addCityDemandLevel,
  isApiHit,
  Loading,
  getData,
}) => {
  const desc =
    item?.manualEvents?.map((val) => ({
      name: val,
    })) ?? [];
  const arr = item?.events ?? [];
  const eventArray = [...desc, ...arr];
  const [events, setEvents] = useState(() => eventArray);
  const [selectedEvent, setSelectedEvent] = useState(
    Array(eventArray.length).fill(false)
  );
  const [demandLevelSymbol, setdemandLevelSymbol] = useState(
    item.demandLevelSymbol
  );
  const [hotelDemandLevelSymbol, sethotelDemandLevelSymbol] = useState(
    item.hotelDemandLevelSymbol
  );

  const [cityDemandLevelSymbol, setCityDemandLevelSymbol] = useState(
    item.cityDemandLevelSymbol || ""
  );
  const [goingToAddEvent, setGoingToAddEvent] = useState(false);
  const [goingToEditEvent, setGoingToEditEvent] = useState(false);

  // const [startDate, setStartDate] = useState(new Date(item.date));
  // const [endDate, setEndDate] = useState(new Date(item.date));

  const startDate = useMemo(() => {
    return new Date(item.date);
  }, [item.date]);

  const endDate = useMemo(() => {
    return new Date(item.date);
  }, [item.date]);

  const handleDeatils = (index, value) => {
    setEvents([
      ...events.filter((_, idx) => idx < index),
      {
        name: value,
      },
      ...events.filter((_, idx) => idx > index),
    ]);
  };
  const deleteEvent = (index) => {
    setEvents([
      ...events.filter((_, idx) => idx < index),
      ...events.filter((_, idx) => idx > index),
    ]);

    if (!goingToEditEvent) {
      setGoingToEditEvent(true);
    }
  };
  const addContactRow = (e) => {
    e.preventDefault();
    if (!events.every(isvalidEvent)) {
      return;
    }
    setEvents([...events, ""]);
    setSelectedEvent(Array(events.length + 1).fill(false));
    setGoingToAddEvent(true);
  };
  const onSelectEventHandler = (index) => {
    setSelectedEvent([
      ...selectedEvent.filter((_, idx) => idx < index),
      !selectedEvent[index],
      ...selectedEvent.filter((_, idx) => idx > index),
    ]);
  };
  const isvalidEvent = (cont) => {
    return cont !== "" && cont !== null;
  };

  function withValidId(demandLevel) {
    return !!demandLevel.id;
  }

  const saveEvent = async () => {
    const calendarEvents = events.filter((item) => item.id);
    const manual = events.filter((item) => !item.id).map((val) => val?.name);
    console.log({ manual, events });
    await generateBatchBaseDemandLevels(
      [
        {
          manualEvents: manual ?? [],
          events: calendarEvents,
          hotelDemandLevelSymbol: hotelDemandLevelSymbol,
          cityDemandLevelSymbol: cityDemandLevelSymbol,
          startDate: startDate,
          endDate: endDate,
          days: [0, 1, 2, 3, 4, 5, 6],
          overrideSymbol: true,
          cityOverrideSymbol: true,
        },
      ],
      true
    );
    await getData({
      val: true,
    });
    setPopOverIdx(-1);
    setGoingToAddEvent(false);
  };

  useEffect(() => {
    if (isApiHit) {
      setPopOverIdx(-1);
    }
  }, [isApiHit]);

  console.log({ events });

  return (
    <ClassWrapper>
      <Header>
        <Wrapper>
          <P>
            {item.displayDate !== undefined
              ? ` ${item.displayDate?.split("/")[0]}/${
                  item.displayDate?.split("/")[1]
                }`
              : `${new Date(item?.date).getDate()}/${
                  new Date(item?.date).getMonth() + 1
                }`}
          </P>
          <Day style={{ marginTop: "5px" }}>
            {days[new Date(item.date).getDay()].substring(0, 3)}
          </Day>
        </Wrapper>
        <DemandLevelContainer>
          <Day style={{ marginRight: 10, marginTop: "5px" }}>Hotel DL</Day>
          <DLTypography>
            {hotelDemandLevelSymbol ? hotelDemandLevelSymbol : "N/A"}
          </DLTypography>
        </DemandLevelContainer>
        <DemandLevelContainer>
          <Day style={{ marginRight: 10 }}>City DL</Day>
          <Select
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                outline: "none !important",
                border: "none !important",
              },
            }}
            disabled={addDemandLevel ? false : true}
            value={cityDemandLevelSymbol}
            disableUnderline
            inputProps={{
              classes: {
                icon: "icon",
              },
            }}
            MenuProps={{ getContentAnchorEl: null }}
            onChange={(e) => {
              setGoingToEditEvent(true);
              return setCityDemandLevelSymbol(e.target.value);
            }}
          >
            {demandLevels.filter(withValidId).map((dL) => (
              <MenuItem value={dL.symbol} key={dL.id}>
                {dL.symbol}
              </MenuItem>
            ))}
          </Select>
        </DemandLevelContainer>

        <Cross onClick={() => setPopOverIdx(-1)}>
          <Close style={{ color: "rgba(0,0,0,0.3)" }} />
        </Cross>
      </Header>
      <EventSection>
        {events.map((detail, index) =>
          events.length === index + 1 && goingToAddEvent ? (
            <EventContainer key={index}>
              <Label>Event</Label>
              <TextField
                variant="standard"
                value={detail.name}
                onChange={(e) => handleDeatils(index, e.target.value)}
              />
            </EventContainer>
          ) : (
            <EventWrapper onClick={() => onSelectEventHandler(index)}>
              {!selectedEvent[index] ? (
                <SingleEventContainer>
                  <Dot></Dot>
                  <TextArea>
                    <Text>{detail.name}</Text>
                  </TextArea>
                  <DeleteEvent>
                    <Cross>
                      <Close style={{ visibility: "hidden" }} />
                    </Cross>
                  </DeleteEvent>
                </SingleEventContainer>
              ) : (
                <SingleEventContainerSelected>
                  <Dot></Dot>
                  <TextArea>
                    <Text>{detail.name}</Text>
                  </TextArea>
                  <DeleteEvent>
                    <Cross onClick={() => deleteEvent(index)}>
                      <Close
                        style={{
                          color: "rgba(0,0,0,0.3)",
                          width: "14px",
                          height: "14px",
                        }}
                      />
                    </Cross>
                  </DeleteEvent>
                </SingleEventContainerSelected>
              )}
            </EventWrapper>
          )
        )}
      </EventSection>
      {/* {goingToAddEvent && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePickerSection>
            <Label>From</Label>
            <DatePicker
              InputProps={{
                disableUnderline: true,
              }}
              format="mm/dd/yyyy"
              value={startDate}
              onChange={(date) => setStartDate(new Date(date))}
              renderInput={(params) => (
                <TextField
                  sx={{
                    svg: { color: "rgb(48,111,188)" },
                    maxWidth: "150px",
                  }}
                  variant="standard"
                  {...params}
                />
              )}
              style={{ width: "150px" }}
            />

            <Label>To</Label>
            <DatePicker
              InputProps={{
                disableUnderline: true,
              }}
              format="mm/dd/yyyy"
              value={endDate}
              renderInput={(params) => (
                <TextField
                  sx={{
                    svg: { color: "rgb(48,111,188)" },
                    maxWidth: "150px",
                  }}
                  variant="standard"
                  {...params}
                />
              )}
              onChange={(date) => setEndDate(new Date(date))}
              style={{ width: "150px" }}
            />
          </DatePickerSection>
        </LocalizationProvider>
      )} */}

      <ButtonSection>
        <AddContact onClick={addContactRow}>+ ADD</AddContact>
        {goingToAddEvent || goingToEditEvent ? (
          <Stack direction={"row"} alignItems="center" spacing={2}>
            <LoadingButton
              loading={Loading}
              variant="outlined"
              onClick={saveEvent}
              disabled={
                Loading ||
                (goingToAddEvent && events[events.length - 1] === "") ||
                (!goingToAddEvent && !goingToEditEvent)
              }
            >
              Save
            </LoadingButton>
          </Stack>
        ) : (
          <Stack direction={"row"} alignItems="center" spacing={2}>
            <LoadingButton
              loading={Loading}
              variant="outlined"
              disabled={Loading || !goingToAddEvent || !goingToEditEvent}
            >
              Save
            </LoadingButton>
          </Stack>
        )}
      </ButtonSection>
    </ClassWrapper>
  );
};
export default AddEventsModel;
