import React, { useEffect, useState } from "react";
import {
  Section,
  ChooseBtn,
  Filename,
  Title,
  Cross,
  CrossSmall,
  ModelHead,
  Label,
  DateSection,
  UploadSection,
  Done,
} from "../../BusinessOnBooks/Styles";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import { CalendarToday } from "@mui/icons-material";
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import { StyledCalenderWrapper } from "../../CompetitorPricing";

function getModalStyle() {
  const top = 8;
  const right = 4;

  return {
    top: `${top}%`,
    right: `${right}%`,
    transform: `translate(-${top}, -${right})`,
  };
}

export const CalenderIcon = styled(CalendarToday)`
  position: absolute;
  width: 20px;
  height: 22px;
  color: #306fbc;
  cursor: pointer;
  z-index: 101;
`;
const ClassWrapper = styled(Box)(({ theme }) => ({
  ".paper": {
    position: "absolute",
    width: 370,
    height: 240,
    backgroundColor: "#fff",
    boxShadow: 5,
  },
}));

export function ModelBody({
  fileHandler,
  setUploadActualDate,
  uploadActualDate,
  fileGetter,
  setOpenSelectBar,
  removeFile,
  filename,
  toggle,
}) {
  const [modalStyle] = useState(getModalStyle);
  const defaultValue = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  const maximumDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  const [selectedDay, setSelectedDay] = useState(defaultValue);

  useEffect(() => {
    if (selectedDay) {
      setUploadActualDate(
        new Date(
          [
            selectedDay?.year,
            selectedDay?.month,
            selectedDay?.day.length === 1
              ? "0" + selectedDay?.day
              : selectedDay?.day,
          ].join("-")
        )
      );
    }
  }, [selectedDay]);
  const value =
    `${selectedDay.day < 10 ? "0" + selectedDay.day : selectedDay.day}` +
    "/" +
    `${selectedDay.month < 10 ? "0" + selectedDay.month : selectedDay.month}` +
    "/" +
    `${selectedDay.year}`;

  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={value}
      style={{
        textAlign: "center",
        fontSize: "14px",
        borderRadius: "10px",
        boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
        color: "#000000",
        outline: "none",
        width: "160px",
        height: "30px",
        border: "none",
        paddingRight: "35px",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
      }}
    />
  );
  return (
    <ClassWrapper>
      <Section style={modalStyle} className="paper">
        <ModelHead>
          <Title>Upload Forecast</Title>
          <Cross onClick={() => setOpenSelectBar(false)}>&times;</Cross>
        </ModelHead>
        <DateSection sx={{ marginLeft: "93px" }}>
          <Label htmlFor="picker">Date</Label>
          <StyledCalenderWrapper>
            <DatePicker
              id="picker"
              value={selectedDay}
              onChange={setSelectedDay}
              maximumDate={maximumDate}
              calendarClassName="myCustomCalendar"
              renderInput={renderCustomInput}
              className="DatePicker"
            />
            <CalenderIcon sx={{ mt: "5px", ml: "-34px" }} />
          </StyledCalenderWrapper>
        </DateSection>
        <ChooseBtn
          style={toggle ? { display: "none" } : { display: "block" }}
          htmlFor="upload"
        >
          Choose File
          <input
            type="file"
            id="upload"
            onChange={(e) => fileGetter(e.target.files)}
            style={{ display: "none" }}
          />
        </ChooseBtn>

        <UploadSection
          style={toggle ? { display: "flex" } : { display: "none" }}
        >
          <img
            src="/assets/excel.svg"
            alt="Excel Icon"
            width="36px"
            height="36px"
          />

          <Filename>{filename}</Filename>
          <CrossSmall
            onClick={() => removeFile()}
            style={toggle ? { display: "block" } : { display: "none" }}
          >
            &times;
          </CrossSmall>
        </UploadSection>
        <Done
          disabled={!toggle}
          next
          onClick={fileHandler}
          type="button"
          style={{ color: "white", backgroundColor: "#306fbc" }}
        >
          Upload
        </Done>
      </Section>
    </ClassWrapper>
  );
}
