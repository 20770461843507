import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "../sdk";

export default function useBroadcastDetail({ Id }) {
  const { authFetch } = useAuth();

  const [details, setDetails] = useState([]);
  console.log({ Id });
  const getMessageDetails = useCallback(async () => {
    try {
      const { get } = await authFetch({
        path: `/broadcast-message-detail?id=${Id}`,
      });
      const { data } = await get();
      if (data) {
        setDetails(data);
      }
    } catch (err) {
      console.log(err);
    }
  }, [Id, authFetch]);

  useEffect(() => {
    getMessageDetails();
  }, [getMessageDetails]);
  return {
    details,
  };
}
