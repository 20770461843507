import React from "react";
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 120px;
`;

const Message = styled(Typography)`
  width: 310px;
  text-align: center;
  font: normal normal 300 18px/21px Roboto;
  letter-spacing: 0px;
  color: #322277;
  margin-top: 32px;
`;
export const Nodata = () => {
  return (
    <Wrapper>
      <img src="/assets/Nodata.svg" alt="Logo" width="220px" height="91px" />
      <Message>There is no data available for this selection</Message>
    </Wrapper>
  );
};
