import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Popover,
  Snackbar,
  SnackbarContent,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import {
  ColorContainer,
  ColorText,
  Heading,
  KeyButton,
  SaveButton,
  TB,
  TableWrapper,
  Td,
  Th,
} from "./styles";
import useColors from "./useColors";
import { SketchPicker } from "react-color";
import {
  Add,
  Close,
  CloseRounded,
  KeyboardBackspace,
  ModeEditOutline,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { PrimaryButton, useAuth } from "../sdk";
import { DialogLabel } from "../BusinessOnBooks/Styles";
import { GetColorName } from "hex-color-to-color-name";

export const Colors = () => {
  const {
    categories,
    anchorEl,
    setAnchorEl,
    handleClick,
    color,
    setColor,
    networkMsg,
    setnetworkMsg,
    handleAddColor,
    handleDelete,
    selectedCategory,
    setSelectedCategory,
    confirmationDialog,
    setConfirmationDialog,
  } = useColors();
  const history = useHistory();
  const { permission } = useAuth();
  let editEventColours = null;

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "editEventColor") {
        editEventColours = permission[key];
      }
    }
  }

  return (
    <>
      <Box
        sx={{
          padding: "10px 10px 10px 10px",
          height: "79vh",
          backgroundColor: "#fff",
        }}
      >
        <ColorContainer>
          <Heading>Event Colours</Heading>
          <TableWrapper>
            <TB
              stickyHeader
              aria-label="sticky table"
              style={{
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <TableHead
                sx={{
                  borderRadius: "8px 8px 0px 0px",
                }}
              >
                <TableRow>
                  <Th
                    style={{
                      borderRadius: "8px 0px 0px 0px",
                      textAlign: "left",
                      paddingLeft: "15px",
                    }}
                  >
                    Category
                  </Th>
                  <Th
                    style={{
                      borderRadius: "0px 8px 0px 0px",
                    }}
                  >
                    Colours
                  </Th>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories?.length === 0 && (
                  <TableRow
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height={"200px"}
                  >
                    <TableCell
                      colSpan={12}
                      sx={{
                        textAlign: "center",
                        border: "0px",
                      }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        width="100%"
                      >
                        <Typography ml={1}>
                          No Event Categories Found
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                {categories.length > 0 &&
                  categories?.map((category) => (
                    <TableRow key={category.id}>
                      <Td
                        sx={{
                          width: "85%",
                          textAlign: "left !important",
                          paddingLeft: "20px",
                        }}
                      >
                        {category?.name}
                      </Td>
                      <Td>
                        {category?.color ? (
                          <Stack
                            gap={"3px"}
                            justifyContent={"space-between"}
                            direction="row"
                            alignItems={"center"}
                          >
                            <Stack
                              gap={"3px"}
                              justifyContent={"start"}
                              direction="row"
                              alignItems={"center"}
                            >
                              <Box
                                height={15}
                                width={15}
                                bgcolor={category?.color}
                              />
                              <ColorText>
                                {GetColorName(category.color)}
                              </ColorText>
                            </Stack>

                            {editEventColours && (
                              <Stack direction={"row"}>
                                <IconButton
                                  disableRipple
                                  sx={{
                                    padding: "8px 0px",
                                  }}
                                  onClick={(event) =>
                                    handleClick(event, category.id)
                                  }
                                >
                                  <ModeEditOutline
                                    sx={{
                                      color: "#306FBC",
                                    }}
                                  />
                                </IconButton>
                                <IconButton
                                  disableRipple
                                  sx={{
                                    padding: "8px 0px",
                                  }}
                                  onClick={() =>
                                    setConfirmationDialog(category?.id)
                                  }
                                >
                                  <Close
                                    sx={{
                                      color: "#306FBC",
                                    }}
                                  />
                                </IconButton>
                              </Stack>
                            )}
                          </Stack>
                        ) : (
                          <KeyButton
                            onClick={(event) => handleClick(event, category.id)}
                            disabled={!editEventColours}
                          >
                            <Add /> Add Colour
                          </KeyButton>
                        )}

                        <Popover
                          open={selectedCategory === category.id}
                          anchorEl={anchorEl}
                          onClose={() => {
                            setAnchorEl(null);
                            setSelectedCategory(null);
                          }}
                          transitionDuration={100}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Stack
                            sx={{
                              background: "#fff",
                            }}
                            gap={1}
                            p={2}
                          >
                            <SketchPicker
                              color={color || "#000"}
                              disableAlpha={true}
                              onChange={(color) => setColor(color.hex)}
                            />
                            <SaveButton
                              sx={{
                                padding: "4px 12px !important",
                              }}
                              onClick={() => {
                                handleAddColor(category.id);
                              }}
                            >
                              Save
                            </SaveButton>
                          </Stack>
                        </Popover>
                      </Td>
                    </TableRow>
                  ))}
              </TableBody>
            </TB>
          </TableWrapper>
        </ColorContainer>
      </Box>
      <Dialog
        open={confirmationDialog}
        onClose={() => setConfirmationDialog(null)}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginTop: "20px",
            }}
          >
            <DialogLabel style={{ textAlign: "center" }}>
              Are you sure you want to remove this color ?
            </DialogLabel>
            <Cross
              style={{ position: "absolute", top: "10px", right: "10px" }}
              onClick={() => setConfirmationDialog(null)}
            >
              &times;
            </Cross>
          </div>{" "}
        </DialogTitle>
        <DialogActions
          style={{
            justifyContent: "center",
            gap: "16px",
            paddingBottom: 16,
            marginBottom: "20px",
          }}
        >
          <PrimaryButton
            autoFocus
            next
            onClick={() => {
              handleDelete(confirmationDialog);
            }}
          >
            Yes
          </PrimaryButton>
          <PrimaryButton
            autoFocus
            next
            onClick={() => {
              setConfirmationDialog(null);
            }}
          >
            No
          </PrimaryButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={networkMsg}
        autoHideDuration={3000}
        onClose={() => setnetworkMsg(false)}
      >
        {networkMsg && (
          <SnackbarContent
            style={{
              backgroundColor: "#CA3433",
            }}
            message={networkMsg}
          />
        )}
      </Snackbar>
    </>
  );
};

const Cross = styled(CloseRounded)`
  width: 20px;
  height: 20px;
  :hover {
    cursor: pointer;
  }
`;
