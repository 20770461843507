import { Box, TableCell, TableHead, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const Page = styled(Box)`
  padding: 56px 0px 22px 76px;
  height: 100%;
`;

export const Head = styled(Box)`
  position: fixed;
  top: 13px;
  left: 80px;
  color: white;
  z-index: 999;
  display: flex;
  align-items: center;
`;

export const H = styled(Typography)`
  font: normal normal bold 24px/30px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  padding-right: 8px;
`;

export const Body = styled(Box)`
  width: inherit;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const Title = styled(Typography)`
  text-align: left;
  font: normal normal normal 20px/22px Roboto;
  letter-spacing: 0px;
  color: #000000;
  padding-bottom: 10px;
`;
export const Date = styled(Typography)`
  text-align: left;
  font: normal normal normal 24px/30px Roboto;
  letter-spacing: 0px;
  color: #110641;
  opacity: 1;
  margin-right: 10px;
`;

export const MessageBox = styled(Box)`
  background: #806dd1 0% 0% no-repeat padding-box;
  border-radius: 8px;
  width: auto;
  height: 35px;
  padding: 10px;
  margin-left: 4px;
`;

export const Message = styled(Typography)`
  text-align: left;
  font: normal normal normal 12px/15px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
`;

export const TopCard = styled(Box)`
  width: 30%;
  height: 190px;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin: 20px 20px 20px 0;
  padding: 1vw;
`;
export const TopRow = styled(Box)`
  display: flex;
  flex-direction: columns;
  justify-content: flex-start;
  align-items: center;
  width: inherit;
`;
export const MiddleRow = styled(Box)`
  display: flex;
  flex-direction: columns;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  width: 98%;
  height: 500px;
`;

export const HybridGraphCard = styled(Box)`
  width: 70%;

  background: #ffffff 0% 0% no-repeat padding-box;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  padding: 1vw;
`;

export const SimpleGraphCard = styled(Box)`
  width: 30%;

  background: #ffffff 0% 0% no-repeat padding-box;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  padding: 1vw;
`;

export const GraphHeader = styled(Box)`
  display: flex;
  align-items: baseline;
`;

export const DateRange = styled(Box)`
  text-align: left;
  font: normal normal normal 18px/20px Roboto;
  letter-spacing: 0px;
  color: #000000;
  padding: 0 0 10px 16px;
`;

export const BottomRow = styled(Box)`
  height: 85%;
`;
export const WeeklyCard = styled(Box)`
  width: 98.5%;
  height: 70%;
  margin: 20px 0 20px 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 1vw;
  display: flex;
`;

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98.5%;
  height: 6%;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  margin: 20px 0 0;
`;
export const SubHeader = styled(Box)`
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 79px;
`;

export const WeeklyBody = styled(Box)`
  display: flex;
  width: 100%;
  height: 90%;
  justify-content: space-between;
`;

export const CardName = styled(Typography)`
  text-align: left;
  font: normal normal normal 18px/20px Roboto;
  letter-spacing: 0px;
  color: #000000;
  width: 50%;
`;

export const TableCard = styled(Box)`
  display: flex;
  flex-wrap: no-wrap;
  width: 98.5%;
  background: none;
`;

export const Th = styled(TableHead)`
  flex: 1;
  padding-bottom: 20px;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #130453;
  width: 100px;
`;
export const Td = styled(TableCell)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
`;
