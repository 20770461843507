import {
  Button,
  Input,
  InputLabel as IL,
  Select as SL,
  TableCell,
  TableHead,
  TableRow,
  TextField as TF,
  TableContainer,
  Box,
  Typography,
} from "@mui/material";
import {
  Add,
  AddCircleRounded,
  CloseRounded,
  Edit,
  Remove,
  RemoveCircleRounded,
} from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/system";
import {
  Card,
  GhostButton,
  PrimaryButton,
  SecondaryButton as SB,
} from "./../../sdk";

export const Wrapper = styled(Box)`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const TextFieldN = styled(TF)`
  input.MuiInputBase-input {
    width: 100px;
    padding: 5px 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #333333;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export const CancelButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #306fbc;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(3, 4, 94, 0.2);
  border-radius: 8px;
  padding: 6px 12px 6px 12px;
  color: #306fbc;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;

  height: 20px;
  &:hover {
    background: #ffffff;
  }
`;
export const SaveButton = styled(Button)`
  background: #757575;
  box-shadow: 0px 4px 4px rgba(3, 4, 94, 0.2);
  border-radius: 8px;
  padding: 6px 12px 6px 12px;
  color: #ffffff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  margin-left: 8px;

  height: 20px;
  &:hover {
    background: #757575;
  }
`;
export const EditButtonIcon = styled(Edit)`
  color: #306fbc;
  height: 18px;
  width: 18px;
`;

export const RemoveButtonIcon = styled(Remove)`
  color: #306fbc;
  cursor: pointer;
  width: 16px;
`;
export const AddButtonIcon = styled(Add)`
  color: #306fbc;
  cursor: pointer;
  width: 16px;
  height: 16px;
`;

export const RemoveButton = styled(RemoveCircleRounded)`
  color: #210d79;
  cursor: pointer;
`;
export const AddButton = styled(AddCircleRounded)`
  color: #210d79;
  cursor: pointer;
`;

export const TextField = styled(TF)`
  text-decoration: none;
  &.MuiFormControl-root {
    margin-right: 40px;
    width: 50%;
    margin-bottom: 10px;
  }
  label {
    font: normal normal bold 16px/20px Roboto;
    letter-spacing: 0px;
    color: #110641;
    padding-left: 6px;
    width: 240px;
    min-width: 50px;
    color: #0a0a0a;
  }
  label.MuiFormLabel-root.Mui-focused {
    font: Bold 16px/20px Roboto;
    letter-spacing: 0px;
    color: #0a0a0a;
  }
  input,
  .MuiAutocomplete-inputRoot[class*="MuiInput-root"]
    .MuiAutocomplete-input:first-child {
    font: normal normal normal 16px/20px Roboto;
    letter-spacing: 0px;
    color: #110641;
    min-width: 232px;
    background-color: rgba(200, 200, 244, 0.4);
    padding-left: 8px;
    margin-top: 4px;
  }

  &.invalid {
    label {
      color: red;
    }
    label.MuiFormLabel-root.Mui-focused {
      color: red;
    }
  }
`;
export const AddressField = styled(TextField)`
  &.MuiFormControl-root {
    width: 100%;
  }
`;

export const Select = styled(SL)`
  padding-left: 8px;
  width: 100%;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #000000;
`;
export const SelectContainer = styled(Box)`
  display: flex;
  align-items: center;
  font: Bold 12px/15px Roboto;
  letter-spacing: 0px;
  color: #838383;
  label {
    letter-spacing: 0px;
  }
  label.MuiFormLabel-root.Mui-focused {
    font: Bold 16px/20px Roboto;
    letter-spacing: 0px;
  }
  width: 100%;
`;

export const PageContainer = styled(Box)`
  width: 100%;
  overflow: scroll;
  height: calc(100vh - 160px);
  overflow-x: hidden;
`;
export const Mycard = styled(Card)`
  width: 100%;
  height: 70vh;
  height: auto;
`;
export const FormWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.pricing ? "flex-start" : "space-around"};
  align-items: flex-start;
  width: 1120px;
  padding: 20px;
  padding-bottom: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
`;

export const Index = styled(Box)`
  text-align: left;
  font: 400 20px/25px Roboto;
  letter-spacing: 0px;
  color: #000000;
  margin-right: 25px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const NextBtnContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 14px;
`;
export const ButtonContainer = styled(Box)`
  padding: 20px 0px 60px 0px;
`;

export const ContactHeader = styled(Box)`
  letter-spacing: 0px;
  color: #130453;
  margin-top: 50px;
  margin-bottom: 20px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
`;

export const SelectDiv = styled(Box)`
  padding-left: 0px;
  width: 100%;
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    font-family: "Roboto";

    color: #000000;
  }
`;
export const Text = styled(Typography)`
  text-align: right;
  font: Bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #343434;
`;

export const UploadContainer = styled(Box)`
  margin-left: 162px;
  margin-bottom: 41px;
  width: 1116px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/**
 *
 *
 * Pricing form Styles
 *
 */
export const Page = styled(Box)`
  display: flex;
  flex-direction: column;
  .page-footer {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    margin: 20px;
  }
`;

export const CopySetting = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  align-items: center;
  width: 100%;
  height: 52px;
  background: #ffffff 0% 0% no-repeat padding-box;
`;
export const HeaderText = styled(Box)`
  font: normal normal normal 16px/20px Roboto;
  letter-spacing: 0px;
  color: #000000;
`;
export const UploadBtn = styled(PrimaryButton)`
  text-align: center;
  width: 140px;
  height: 36px;
  font: Bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #343434;
  box-shadow: none;

  border: 1px solid #322277;
  border-radius: 5px;
`;
export const AddContact = styled(GhostButton)`
  font-family: Roboto;
  letter-spacing: 0px;

  margin-left: -15px;
  padding-left: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #ffffff;
`;

export const FormPageContainer = styled(Box)`
  display: flex;
  width: 100%;
  margin-top: 20px;
  justify-content: space-between;
  .pricing-setup {
    width: 55%;
    .card-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding-left: 0px;
    }
  }
  .season-setup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
    .card-container {
      padding-right: 0px;
      width: 100%;
      .title {
        display: flex;
        width: 100%;
        padding-left: 20px;
        justify-content: space-between;
        .text {
          font: normal normal normal 16px/20px Roboto;
          letter-spacing: 0px;
          color: #130453;
        }
        background: #ffffff 0% 0% no-repeat padding-box;
      }
    }
  }
  .pricing-setup,
  .season-setup {
    .card-container {
      padding-left: 0px;
      width: 100%;
    }
    .title {
      padding: 13px 44px;
      font: normal normal normal 20px/25px Roboto;
      letter-spacing: 0px;
      color: #130453;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .card {
      background: #ffffff 0% 0% no-repeat padding-box;
      .title {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .text {
          font: normal normal normal 16px/20px Roboto;
          letter-spacing: 0px;
          color: #130453;
        }
      }
    }
    .season-card {
      background: #ffffff 0% 0% no-repeat padding-box;
      margin: 0 20px;
      width: 100%;
      .title {
        display: flex;
        width: 380px;
        justify-content: space-between;
        .text {
          font: normal normal bold 16px/20px Roboto;
          letter-spacing: 0px;
          color: #130453;
        }
      }
      .footer {
        padding: 10px;
        padding-right: 15px;
        width: 100%;
      }
      .dynamic-footer {
        width: 100%;
        padding: 10px;
        display: flex;
        direction: column;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
`;

export const TableHeadContainer = styled(TableHead)`
  &&&.MuiTableHead-root {
    .MuiTableRow-root {
      .MuiTableCell-root {
        padding: 6px 0;
        &:first-child {
          padding-left: 48px;
        }
        &:last-child {
          padding-right: 48px;
        }
      }
      background: #ffffff;
    }
    margin: auto;
  }
`;

export const TableMainHeader = styled(TableCell)`
  &.MuiTableCell-root {
    font: normal normal normal 16px/20px Roboto;
    letter-spacing: 0px;
    color: #130453;
    border: none;
  }
`;

export const TableDatesHeader = styled(TableMainHeader)`
  &.MuiTableCell-root {
    font: normal normal bold 16px/20px Roboto;
    color: #130453;
    padding-top: 30px;
  }
`;

export const TableSubHeader = styled(TableCell)`
  &.MuiTableCell-root {
    font: normal normal bold 12px/15px Roboto;
    letter-spacing: 0px;
    color: #130453;
    border: none;
  }
`;
export const TableRowContainer = styled(TableRow)`
  &.MuiTableRow-root {
    .MuiTableCell-root {
      &:first-child {
        padding-left: 48px;
      }
      &:last-child {
        padding-right: 48px;
      }
    }
    &:nth-child(odd) {
      background: #f1f2fe;
    }
  }
`;

export const TableDatesRow = styled(TableRow)`
  &.MuiTableRow-root {
    padding: 40px 12px;
  }
  &:nth-child(odd) {
    background: #f1f2fe;
  }
`;

export const TableCellContainer = styled(TableCell)`
  &.MuiTableCell-root {
    font: normal normal normal 16px/20px Roboto;
    letter-spacing: 0px;
    color: #000000;
    padding: 0;
    padding-top: 4px;
    padding-bottom: 4px;
    border: none;
    height: 100%;
    margin-right: 10px;
  }
  .index {
    margin-left: 10px;
  }
`;

export const TextFieldSmall = styled(TF)`
  input.MuiInputBase-input {
    padding-left: 8px;
    width: 48px;
    height: 32px;
    background: rgba(231, 232, 244, 0.75) 0% 0% no-repeat padding-box;
    opacity: 1;
    font: normal normal normal 16px/20px Roboto;
    letter-spacing: 0px;
    color: #110641;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export const TextFieldMedium = styled(Input)`
  input.MuiInputBase-input {
    width: 56px;
    padding: 5px 0;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #000000;
    letter-spacing: 0px;
    color: #000000;
    text-align: center;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const DynamicButton = styled(SB)`
  background: #280e98 0% 0% no-repeat padding-box;
  border: 1px solid #280e98;
  border-radius: 5px;
  color: #ffffff;
  font: normal bold 12px/15px Roboto;
`;

export const GhostButtonSmall = styled(GhostButton)`
  font: normal normal bold 12px/15px Roboto;
  text-align: left;
  color: #343434;
`;

export const Instruction = styled(Text)`
  text-align: left;
  text-align: left;
  font: italic normal normal 12px/16px Roboto;
  color: #4e4e4e;
  padding-right: 50px;
`;

export const ContactDetails = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 15px;
  width: 100%;
  grid-gap: 24px;
`;
export const HotelDetails = styled(Box)`
  width: 100%;
  min-height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 16px 16px 24px;
  box-shadow: 1px 4px 10px rgba(48, 111, 188, 0.2);
`;
export const CardContainer = styled(Box)`
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 16px;
  button {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    color: #000000;
    margin: 5px 0px;
  }
`;

export const TCell = styled(TableCell)`
  &.MuiTableCell-root {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #000000;
    letter-spacing: 0px;

    padding: 5px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    text-align: ${(props) => (props.$isineditmode ? "left" : "center")};
    text-transform: capitalize;
  }
`;

export const TableHeader = styled(TableCell)`
  &.MuiTableCell-root {
    font: 500 16px/20px Roboto;
    letter-spacing: 0px;
    color: #333333;
    border: none;
    padding: ${(props) => (props.isInEditMode ? "0" : "5px 0")};
    max-width: 300px;
    font: normal normal bold 12px/15px Roboto;
    letter-spacing: 0px;
    color: #130453;
    &:first-child,
    :last-child {
      padding: ${(props) => (props.isInEditMode ? "0 20px" : "5px 20px")};
    }
    text-transform: capitalize;
  }
`;

export const TRow = styled(TableRow)`
  &.MuiTableRow-root {
  }
`;
export const THead = styled(TableHead)`
  &.MuiTableHead-root {
    tr.MuiTableRow-root {
      padding: 5px 10px;
      background: transparent;
    }
  }
`;

export const TContainer = styled(TableContainer)`
  &.MuiPaper-elevation1 {
    box-shadow: none;
  }
`;

export const Tabhead = styled(TableCell)`
  &.MuiTableCell-root {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    height: 56px;
    color: #ffffff;
    background: #306fbc;
    letter-spacing: 0px;

    padding: 5px 8px;
    border: none;
    width: 100px;
    text-align: ${(props) => (props.$isineditmode ? "center" : "center")};
    text-transform: capitalize;

    &:first-child {
      border-radius: 4px 0px 0px 0px;
    }
    &:last-child {
      border-radius: 0px 4px 0px 0px;
    }
  }
`;

export const Label = styled(Box)`
  font-family: Roboto;
`;
export const CompetitorsRemoveButton = styled(RemoveCircleRounded)`
  color: #210d79;
  cursor: pointer;
  margin-top: 15px;
  margin-left: 8px;
`;
export const CompetitorsTextField = styled(TextField)`
  &.MuiFormControl-root {
    margin-right: 0;
    width: 20%;
    margin: 0 16px;
  }
  label {
    font: normal normal bold 16px/20px Roboto;
    letter-spacing: 0px;
    color: #110641;
    text-align: left;
  }
`;
export const RatingContainer = styled(Box)`
  text-align: left;
  font: Bold 12px/15px Roboto;
  letter-spacing: 0px;
  color: #838383;
  opacity: 1;
  margin: 0 0 0 16px;
  display: flex;
  align-items: center;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  outline: none;
`;

export const P = styled(Typography)`
  font: normal normal normal 12px/15px Roboto;
  letter-spacing: 0px;
  color: #1d1d1d;
  padding-bottom: 10px;
  outline: none;
`;

export const CompetitorPage = styled(Box)`
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: none;
  padding: 20px;
  outline: none;
  height: calc(100vh - 160px);
  overflow: scroll;
  overflow-x: hidden;
`;
export const Cross = styled(CloseRounded)`
  width: 20px;
  height: 20px;
  margin-left: 15px;
  :hover {
    cursor: pointer;
  }
`;
export const Competitors = styled(Box)`
  width: 100%;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  outline: none;
`;
export const Fields = styled(Box)`
  width: 100%;
  background: transparent;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  outline: none;
`;
export const StarLabel = styled(Typography)`
  font: normal normal bold 12px/15px Roboto;
  letter-spacing: 0px;
  color: #110641;
  padding-left: 16px;
`;

export const SecondaryButton = styled(SB)`
  width: 120px;
  height: 28px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #322277;
  border-radius: 5px;
`;

export const TabNav = styled(NavLink)`
  &:-webkit-any-link {
    text-decoration: none;
  }
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #727272;
  &:hover {
    cursor: pointer;
  }

  width: 100%;
  height: 100%;
`;
export const AlgorithmHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 52px;
  font: normal normal bold 16px/20px Roboto;

  margin: auto;
  margin-bottom: 20px;
`;
export const HeaderCard = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  height: 52px;
  width: 48%;
`;
export const Heading = styled(Box)`
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
`;
export const Padding = styled(Box)`
  padding: 0px 30px 0px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  height: 52px;
`;

export const WrapperMB = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;
export const FooterMB = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 15px;
`;
export const MybtnMB = styled(PrimaryButton)`
  box-shadow: 0px 3px 6px #0000002f;
  border-radius: 4px;
  width: 88px;
  height: 28px;
  font: normal normal 600 12px/15px Roboto;
  color: #ffffff;
`;
export const CancelBtnMB = styled(PrimaryButton)`
  background: #d9d9d9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000019;
  border-radius: 4px;
  border: none;
  width: 88px;
  height: 28px;
  margin-right: 25px;
  font: normal normal 600 12px/15px Roboto;
  color: #575757;
`;
export const HeadMB = styled(Box)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 20px;
`;
export const CrossMB = styled(CloseRounded)`
  width: 16px;
  height: 16px;
  :hover {
    cursor: pointer;
  }
`;

export const TabNavPS = styled(NavLink)`
  &:-webkit-any-link {
    text-decoration: none;
  }
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #727272;
  &:hover {
    cursor: pointer;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
export const HeaderPS = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 52px;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  margin: auto;
  margin-bottom: 20px;
`;
export const HeaderCardPS = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 52px;
  width: 260px;
`;

export const InputLabelPDF = styled(IL)`
  font: Bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #0a0a0a;
`;
export const RemoveButtonPDF = styled(RemoveCircleRounded)`
  color: #210d79;
  cursor: pointer;
  margin-top: 15px;
`;

export const DataRangeContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 24px;
  margin-bottom: 50px;
  box-shadow: 1px 4px 10px rgba(48, 111, 188, 0.2);
  padding: 16px 16px 16px 24px;
`;

export const ChooseAppContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
  box-shadow: 1px 4px 10px rgba(48, 111, 188, 0.2);
  padding: 16px 16px 16px 24px;
`;

export const InfoHeader = styled(Typography)`
  letter-spacing: 0px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
`;
export const Gradient = styled(Box)`
  width: 498px;
  height: 24px;
  background: transparent
    linear-gradient(270deg, #16bc3c 0%, #f5ff71 50%, #b70404 100%) 0% 0%
    no-repeat padding-box;
  border: 1px solid #1106415a;
  margin-top: 23px;
  border-radius: 12.5px;
`;
export const Range = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 498px;
  padding-top: 23px;
`;
export const RangeFields = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 80px;
`;
export const RangeText = styled(Typography)`
  font: normal normal bold 12px/15px Roboto;
  letter-spacing: 0px;
  color: #110641;
  padding-top: 13px;
`;

export const NetworkPrompt = styled(Typography)`
  margin-left: 100px;
`;

export const SeasonContainer = styled(Box)`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
`;
export const CardPricingForm = styled(Box)`
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 32%;
  display: table;
`;
export const TitlePricingForm = styled(Box)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #000000;
  letter-spacing: 0px;
`;
export const CardHeader = styled(Box)`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
`;

export const Header = styled(Box)`
  top: 76px;
  left: 80px;

  height: 46px;
  background: 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  margin-right: 4px;
`;

export const Market = styled(Box)`
  width: 140px;
  height: 90px;
  background: #ffffff 0% 0% no-repeat padding-box;
  text-align: center;
  font: normal normal bold 16px/23px Roboto;
  border-right: 1px solid #1106411a;
  letter-spacing: 0px;
  color: #110641;
  opacity: 1;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 12px;
`;
export const TextNew = styled(Typography)`
  top: 171px;
  height: 90px;
  padding-left: 4px;
  padding-right: 41px;
  padding-top: 16px;
  width: 90px;
  background: #ffffff 0% 0% no-repeat padding-box;
  text-align: left;
  border-right: 1px solid #1106411a;
  font: normal normal bold 16px/23px Roboto;
  letter-spacing: 0px;
  color: #110641;
  opacity: 1;
`;

export const DATE = styled(Typography)`
  font: normal normal normal 12px/20px Roboto;
  letter-spacing: 0px;
  color: #110641;
  opacity: 1;
  margin-top: -2px;
`;
export const DATE1 = styled(Typography)`
  font: normal normal normal 12px/20px Roboto;
  letter-spacing: 0px;
  color: #110641;
  opacity: 1;
  margin-top: 15px;
`;

export const Entry = styled(Box)`
  width: 140px;

  text-align: center;
  font: normal normal bold 18px/23px Roboto;
  letter-spacing: 0px;
  color: #110641;
  opacity: 1;
  margin-top: 30px;
`;

export const TEXT1 = styled(Typography)`
  height: 25px;
  text-align: center;
  font: normal normal bold 14px/18px Roboto;
  letter-spacing: 0px;
  color: #110641;
  opacity: 1;
  border: 1px solid #e7e6ec;
  padding-top: 8px;
  padding-left: 4px;
`;
export const INPUT = styled(Input)`
  height: 25px;
  text-align: left;
  font: normal normal bold 14px/18px Roboto;
  letter-spacing: 0px;
  color: #110641;
  opacity: 1;
  border: none;
  padding-left: 4px;
`;

export const SECTION = styled(Box)`
  width: 225px;
  margin-left: 64px;
  display: flex;
`;
export const TEXT2 = styled(Box)`
  margin-left: 24px;
  margin-top: 13px;
  width: 101px;
  height: 28px;
  text-align: left;
  font: normal normal bold 18px/28px Roboto;
  letter-spacing: 0px;
  color: #110641;
  opacity: 1;
  text-align: center;
`;

export const Empty = styled(Typography)`
  width: 19px;
  height: 19px;
  opacity: 1;
  text-align: center;
  margin-left: 70px;
`;
export const Message = styled(Box)`
  width: 176px;
  height: 20px;
  text-align: center;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #110641;
  opacity: 1;
  margin-top: 16px;
  margin-left: 14px;
`;
export const TITLE = styled(Box)`
  width: 86px;
  height: 30px;
  text-align: left;
  font: normal normal 900 18px/30px Roboto;
  letter-spacing: 0px;
  color: #110641;
  opacity: 1;
  margin-top: 8px;
`;

export const BODY = styled(Box)``;
