import { useEffect, useState } from "react";
import { useAuth, usePrevious } from "../../sdk";

const initalState = [
  {
    min: 3,
    max: 30,
    mwValue: 3,
    weValue: 6,
  },
  {
    min: 3,
    max: 30,
    mwValue: 3,
    weValue: 6,
  },
  {
    min: 3,
    max: 30,
    mwValue: 3,
    weValue: 6,
  },
  {
    min: 3,
    max: 30,
    mwValue: 3,
    weValue: 6,
  },
];

const EMPTY_ROW = {
  min: 0,
  max: 180,
  mwValue: null,
  weValue: null,
};
export function useLeadTime(hotelId, copyFromHotelId, LT) {
  const [leadTimes, setLeadTimes] = useState([]);
  const [editLeadTimes, setEditLeadTimes] = useState([]);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [checkLeadTimeValidation, setCheckLeadTimeValidation] = useState(false);
  const saveButtonClicked = usePrevious(isInEditMode);
  const [open, setOpen] = useState(false);
  const [Loading, setLoading] = useState(false);

  const handleCloseStatus = () => {
    setOpen(false);
  };

  const [networkMsg, setnetworkMsg] = useState(null);
  const { token, authFetch } = useAuth();
  useEffect(() => {
    if (!token) return;
    refreshLeadTimes();
  }, [token, hotelId]);

  useEffect(() => {
    if (isInEditMode) {
      const allValidEntries = leadTimes.every(isValid);
      if (!allValidEntries) setCheckLeadTimeValidation(true);
      else setCheckLeadTimeValidation(false);
      if (allValidEntries) {
        if (
          leadTimes.length &&
          leadTimes[leadTimes.length - 1].max < 180 &&
          leadTimes[leadTimes.length - 1].max > 0
        ) {
          setLeadTimes([
            ...leadTimes,
            {
              min: leadTimes.length
                ? leadTimes[leadTimes.length - 1].max + 1
                : 0,
              max: 180,
              mwValue: null,
              weValue: null,
            },
          ]);
        } else if (leadTimes.length === 0) {
          setLeadTimes([
            ...leadTimes,
            {
              min: 0,
              max: 180,
              mwValue: null,
              weValue: null,
            },
          ]);
        }
      }
    } else if (isSaved) {
      updateLeadTimes();
    } else if (isCancelled) {
      refreshLeadTimes();
    }
  }, [leadTimes, isInEditMode]);
  useEffect(() => {
    if (copyFromHotelId === null && !LT) return;
    if (LT) refreshLeadTimes(copyFromHotelId);
  }, [LT]);

  function isValid(lT) {
    return (
      lT.min !== null &&
      lT.min !== "" &&
      lT.max !== null &&
      lT.max !== "" &&
      lT.mwValue !== null &&
      lT.mwValue !== "" &&
      lT.weValue !== null &&
      lT.weValue !== "" &&
      (lT.max >= lT.min || lT.max === -1)
    );
  }
  async function refreshLeadTimes(anotherHotelId = false) {
    const hotelIdToUse = anotherHotelId ? anotherHotelId : hotelId;
    setLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelIdToUse}/lead-time-score`,
    });
    const { data, error } = await get();
    if (error) {
      setnetworkMsg("Can't Fetch Lead Time");
      setOpen(true);
      console.log(error);
    }
    setIsCancelled(false);
    if (data) {
      setLeadTimes(data.sort((a, b) => a.min - b.min));
      setEditLeadTimes([...new Array(data?.length)].map(() => false));
      if (data.length === 0) {
        setLeadTimes([EMPTY_ROW]);
        setEditLeadTimes([false]);
        setIsInEditMode(false);
      }
    } else {
      setLeadTimes([]);
    }

    if (anotherHotelId) {
      setIsInEditMode(true);
    }
    setLoading(false);
  }

  async function updateLeadTimes() {
    const validLeadRows = maxLimit();
    const validLeadTime = validLeadRows
      .map((row, index) => {
        if (index === validLeadRows.length - 1) {
          const { max } = row;
          return !max && max !== 0
            ? {
                ...row,
                max: -1,
              }
            : row;
        }
        return row;
      })
      .filter(isValid);

    const { post } = await authFetch({
      path: `/hotel/${hotelId}/lead-time-score/all`,
    });
    const { error, response, data } = await post(validLeadTime);
    if (!response?.ok) {
      setnetworkMsg(data?.messageToUser || "Can't Update Lead Time");
      setOpen(true);
      console.log(error);
    }
    setIsSaved(false);
    refreshLeadTimes();
  }

  function maxLimit() {
    let validLeadRows = leadTimes;
    leadTimes.map((row, idx) => {
      const { max } = row;
      if (!max && max !== 0) validLeadRows = leadTimes.slice(0, idx + 1);
    });
    return validLeadRows;
  }

  const changeHandler = (index, key, value) => {
    setLeadTimes((prevState) => {
      return prevState.map((row, idx) =>
        idx === index
          ? {
              ...row,
              [key]: value,
            }
          : row
      );
    });
  };

  const onRemoveRowHandler = (index) => {
    setLeadTimes((prevState) => {
      return prevState.filter((_, idx) => idx !== index);
    });
    setEditLeadTimes((prevState) => {
      return prevState.filter((_, idx) => idx !== index);
    });
  };

  const onAddRowHandler = (index) => {
    setLeadTimes([
      ...leadTimes.filter((_, idx) => idx <= index),
      {
        min: "",
        max: "",
        mwValue: "",
        weValue: "",
      },
      ...leadTimes.filter((_, idx) => idx > index),
    ]);

    setEditLeadTimes([
      ...editLeadTimes.slice(0, index + 1),
      true,
      ...editLeadTimes.slice(index + 1),
    ]);
    setIsInEditMode(true);
  };

  const handleEditIconChange = (index) => {
    const result = editLeadTimes.map((data, pos) => {
      if (pos === index) {
        return (editLeadTimes[index] = !editLeadTimes[index]);
      } else {
        return editLeadTimes[pos];
      }
    });
    const checkEditMode = result.every((d) => d === false);
    if (checkEditMode) setIsInEditMode(false);
    else setIsInEditMode(true);
    setEditLeadTimes(result);
  };

  return {
    leadTimes,
    ltIsInEditMode: isInEditMode,
    setLtIsInEditMode: setIsInEditMode,
    ltIsCancelled: isCancelled,
    ltSetIsCancelled: setIsCancelled,
    ltIsSaved: isSaved,
    ltSetIsSaved: setIsSaved,
    ltChangeHandler: changeHandler,
    ltopen: open,
    lthandleCloseStatus: handleCloseStatus,
    ltnetworkMsg: networkMsg,
    ltonRemoveRowHandler: onRemoveRowHandler,
    ltonAddRowHandler: onAddRowHandler,
    ltLoading: Loading,
    editLeadTimes,
    handleEditIconChange: handleEditIconChange,
    checkLeadTimeValidation,
  };
}
