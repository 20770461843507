import { styled } from "@mui/system";
import { PrimaryButton } from "../../sdk";
import { Box, FormControl, TextField as TF, Typography } from "@mui/material";

export const FormContainerBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;

  justify-content: center;
  margin-top: -14px;
`;

export const Container = styled(Box)`
  width: 326px;
  height: 330px;
  padding-left: 20px;
  padding-right: 20px;

  background: #ffffff;
  box-shadow: 12px 4px 36px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
export const TextBox = styled(Box)`
  margin-top: 0px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #212121;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const FormC = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 524px;
  margin-top: 5px;
`;
export const Form = styled(FormControl)`
  height: 100%;
`;

export const TextField = styled(TF)`
  &.MuiFormControl-root {
    outline: none;
    background: transparent;
    margin: 0px;
    border-bottom: none;
  }

  &.MuiFormControl-root {
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    outline: none;
    margin: 0px;
    color: #191919;
    padding: 6px;
    width: 90%;
  }
  &.MuiFormControl-root::-webkit-input-placeholder {
    font: italic normal 300 18px/22px Roboto;
    letter-spacing: 0px;
    color: #191919;
    z-index: 999;
    padding-bottom: 8px;
  }
  input-placeholder {
    letter-spacing: 0px;
    color: #191919;
    z-index: 999;
    padding-bottom: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    text-align: center;

    color: #757575;
  }

  &.MuiFormControl-root:-webkit-autofill,
  &.MuiFormControl-root:-webkit-autofill:hover,
  &.MuiFormControl-root:-webkit-autofill:focus,
  &.MuiFormControl-root:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }
  &.MuiFormControl-root:-webkit-autofill {
    -webkit-text-fill-color: #191919 !important;
  }

  input {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    color: #000000;

    padding-top: 10px;
    background-color: #ffffff;
  }
`;

export const Text = styled(Typography)`
  letter-spacing: 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #306fbc;

  margin-bottom: 8px;
  padding: 10px 0px;
`;
export const ForgotPasswordContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
export const PassWordContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  flex-direction: row;
  box-sizing: border-box;
  border-radius: 8px;
  width: 288px;
  height: 48px;
  margin-top: 44px;
`;
export const PasswordLookup = styled(Box)`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 5px;
  position: relative;
`;
export const Eye = styled(Box)`
  position: absolute;
  margin-bottom: -6px;
  z-index: 999;
  right: 30px;
  &:hover {
    cursor: pointer;
  }
`;
export const Precium = styled(Typography)`
  font-size: 48px;
  width: 156px;
  font-family: Cormorant Garamond;
  line-height: 58px;
  letter-spacing: 0px;
  color: #ffffff;
`;
export const LogoHeader = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: center;
`;
export const Logo = styled(Box)`
  margin: 0 11px 0 0;
  &:hover {
    cursor: pointer;
  }
`;
export const Tagline = styled(Typography)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;

  color: #ffffff;
`;
export const ErrorInfo = styled(Typography)`
  width: 440px;
  height: 57px;
  text-align: center;
  font: normal normal normal 16px/20px Roboto;
  letter-spacing: 0px;
  color: #f6f5f8;
  margin-bottom: 19px;
`;
export const Header = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
`;
export const LoginMessage = styled(Box)`
  letter-spacing: 0px;
  margin-bottom: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;

  color: #ffffff;
`;
export const Email = styled(Box)`
  width: 260px;
  margin-bottom: 20px;
`;
export const Main = styled(Box)`
  background: #163a90;
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled(PrimaryButton)`
  margin-bottom: 16px;
  height: 45px;
  text-align: center;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #f6f5f8;
  border: none;
  border-radius: 0px;
  margin-top: 4px;
  background: #306fbc;
  border-radius: 8px;
`;
