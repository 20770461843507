import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { Gradient } from "../Styles";

const Footer = () => {
  return (
    <Stack direction="row" justifyContent={"left"} ml={5} mb={3}>
      <Stack>
        <Typography
          sx={{ fontSize: "16px", fontWeight: 600, lineHeight: "20px" }}
        >
          Price Comparison
        </Typography>
        <Gradient></Gradient>
        <Stack direction="row" justifyContent={"space-between"} mt={1}></Stack>
        <Stack direction="row" justifyContent={"space-between"}>
          <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
            Higher vs competitors
          </Typography>
          <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
            Lower vs competitors
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;
