import React from "react";

export const ChannelCompare = ({ color = "white", props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="29"
      fill={color}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={color}
        d="M10 18H6v-2h4v2zm0-4H6v-2h4v2zm0-13v1H6c-1.11 0-2 .89-2 2v16a2 2 0 002 2h4v1h2V1h-2zm10 7v12c0 1.11-.89 2-2 2h-4v-2h4v-9h-4V9h4.5L14 4.5V2l6 6zm-4 6h-2v-2h2v2zm0 4h-2v-2h2v2z"
      ></path>
    </svg>
  );
};
