import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ArrowRightAlt } from "@mui/icons-material";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

export const BudgetRedirectingDialog = ({ open, handleClose }) => {
  const history = useHistory();
  const { hotelId } = useParams();

  const handleRedirect = useCallback(() => {
    history.push(`/hotel/${hotelId}/forecast/forecast_sheet_daily`);
  }, [history, hotelId]);

  return (
    <StyledDialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle className="content">Suggestion</DialogTitle>

      <CloseIcon className="closeButton" onClick={handleClose} />

      <DialogContent className="content">
        <Typography textAlign="center">
          Total rooms have exceeded and ADR is lower, go to daily budgeting{" "}
          <br /> sheet and adjust this.
        </Typography>
        <Stack direction="row" gap={2}>
          <Button variant="contained" onClick={handleClose}>
            Ok
          </Button>
          <Button variant="contained" onClick={handleRedirect}>
            <ArrowRightAlt /> Go to Daily Budgeting
          </Button>
        </Stack>
      </DialogContent>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)(() => ({
  ".closeButton": {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
  },
  ".content": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
  },
}));
