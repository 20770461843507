import React, { useState } from "react";
import { styled } from "@mui/system";
import { Box, TextField as TF } from "@mui/material";
import { PrimaryButton, useAuth } from "../../sdk";

const InputDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 16px;
`;

const Input = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 8px;
  width: 100%;
`;

const TextField = styled(TF)`
  text-decoration: none;
  &.MuiFormControl-root {
    margin-bottom: 10px;
  }
  label {
    font: normal normal bold 16px/20px Roboto;
    letter-spacing: 0px;
    padding-left: 6px;
    width: 240px;
    min-width: 50px;
    color: #0a0a0a;
  }
  label.MuiFormLabel-root.Mui-focused {
    font: Bold 16px/20px Roboto;
    letter-spacing: 0px;
    color: #0a0a0a;
  }
  input {
    font: normal normal normal 16px/20px Roboto;
    letter-spacing: 0px;
    color: #110641;
    background-color: rgba(200, 200, 244, 0.4);
    width: 300px;
    padding: 10px;
  }

  &.invalid {
    label {
      color: red;
    }
    label.MuiFormLabel-root.Mui-focused {
      color: red;
    }
  }
  &.MuiFormControl-root {
  }
  label {
    font: normal normal bold 16px/20px Roboto;
    letter-spacing: 0px;
    color: #110641;
    text-align: left;
  }
`;

const AddBtn = styled(PrimaryButton)`
  background: #130453 0% 0% no-repeat padding-box;
  border: 1px solid #130453;
  border-radius: 5px;
  font: normal normal medium 12px/18px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
`;
const Container = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
`;

const reName = /^[a-zA-Z ]*$/;
const rePass = /^[a-zA-Z0-9 ]*$/;
const reEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EMPTY_DETAILS = {
  name: "",
  email: "",
  username: "",
  password: "",
  con_password: "",
  invalidName: false,
  invalidEmail: false,
  invalidUsername: false,
  invalidPassword: false,
  invalidConPassword: false,
};

export default function UserUpdate({
  setNetworkMsg,
  setOpen,
  isInUpdateMode,
  updateData,
  // selectedChain,
  // allRoles,
}) {
  const [isLoading, setLoading] = useState(false);
  const UPDATE_DETAILS = {
    name: updateData?.name,
    email: updateData?.email,
    username: updateData?.username,
    invalidName: false,
    invalidEmail: false,
    invalidUsername: false,
  };

  // function getRoleName(value) {
  //   const role = allRoles?.find((role) => role.id === value);
  //   return role?.name;
  // }

  // function getRoleId(value) {
  //   const role = allRoles?.find((role) => role.name === value);
  //   return role?.id;
  // }

  const { token, authFetch } = useAuth();
  const [user, setUser] = useState(EMPTY_DETAILS);
  const [updateUser, setUpdateUser] = useState(UPDATE_DETAILS);
  // const { token, hotelSelect, authFetch, isAdmin } = useAuth();
  // const organizationId = hotelSelect?.organizationId;
  // const [selectedHotel, setSelectedHotel] = useState(
  //   isInUpdateMode
  //     ? updateData.hotels
  //       ? updateData.hotels.split(", ")
  //       : []
  //     : []
  // );
  // const [selectedRole, setSelectedRole] = useState(
  //   isInUpdateMode ? getRoleName(updateData.roleID) : ""
  // );

  // let addedHotel = [];

  function dataCompilation() {
    const data = {
      name: user.name?.trim(),
      email: user.email,
      username: user.username,
    };
    return data;
  }

  // const isAllValid = (data) => {
  //   return data.name !== "" && data.email !== "" && data.username !== "";
  // };

  async function handleUpdateUserSubmit() {
    setLoading(true);
    const dataCompiled = dataCompilation();
    // const id = isAdmin ? selectedChain : organizationId;
    if (token) {
      const { put } = await authFetch({
        path: `/user-profile`,
      });
      const { data } = await put({
        ...dataCompiled,
      });
      if (data?.message) {
        setNetworkMsg(data.message);
        setOpen(true);
      } else if (data?.messageToUser) {
        setNetworkMsg(data.messageToUser);
        setOpen(true);
      }
    }
    setLoading(false);
  }

  const handleUserInfo = (key, value) => {
    let isValid = true;
    if (key === "name") {
      isValid = value.length > 0 && reName.test(String(value).trim());
    } else if (key === "username") {
      isValid = value.length > 0 && rePass.test(String(value).trim());
    } else if (key === "email") {
      isValid = value.length > 0 && reEmail.test(String(value).trim());
    }

    if (key === "name") {
      isInUpdateMode === true
        ? setUpdateUser({ ...updateUser, [key]: value, invalidName: !isValid })
        : setUser({ ...user, [key]: value, invalidName: !isValid });
    }
    if (key === "username") {
      isInUpdateMode === true
        ? setUpdateUser({
            ...updateUser,
            [key]: value.trim(),
            invalidUsername: !isValid,
          })
        : setUser({ ...user, [key]: value.trim(), invalidUsername: !isValid });
    }
    if (key === "email") {
      isInUpdateMode === true
        ? setUpdateUser({
            ...updateUser,
            [key]: value.trim(),
            invalidEmail: !isValid,
          })
        : setUser({ ...user, [key]: value.trim(), invalidEmail: !isValid });
    }
  };

  return (
    <Container>
      <InputDetails>
        <Input>
          <TextField
            className={user.invalidName ? "invalid" : ""}
            error={user.invalidName}
            name="name"
            value={user.name}
            onChange={(e) => handleUserInfo("name", e.target.value)}
            label="Name"
            helperText={user.invalidName ? "Invalid Entry" : ""}
          />
        </Input>
        <Input>
          <TextField
            className={user.invalidUsername ? "invalid" : ""}
            error={user.invalidUsername}
            name="username"
            value={user.username}
            onChange={(e) => {
              handleUserInfo("username", e.target.value);
            }}
            label="Username"
            helperText={user.invalidUsername ? "Invalid Entry" : ""}
          />
        </Input>
        <Input>
          <TextField
            className={user.invalidEmail ? "invalid" : ""}
            error={user.invalidEmail}
            name="email"
            value={user.email}
            onChange={(e) => handleUserInfo("email", e.target.value)}
            label="Email"
            helperText={user.invalidEmail ? "Invalid Entry" : ""}
          />
        </Input>
      </InputDetails>
      <AddBtn
        onClick={() => handleUpdateUserSubmit()}
        loading={isLoading}
        disabled={isLoading}
      >
        UPDATE
      </AddBtn>
    </Container>
  );
}
