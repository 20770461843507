import { Box, Button, Table, TableCell, styled } from "@mui/material";
import { height, padding, width } from "@mui/system";

export const TableWrapper = styled("div")(({ theme }) => ({
  borderRadius: "8px",
  overflow: "hidden",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  marginTop: "16px",
  height: "auto",
  overflowY: "auto",
  maxHeight: "100%",
}));
export const KeyButton = styled(Button)((theme) => ({
  backgroundColor: "#306FBC",
  color: "#fff",
  fontSize: "16px",
  fontWeight: 500,
  textTransform: "capitalize",
  borderRadius: "8px",
  padding: "6px 10px",
  fontFamily: "Roboto",
  "&:hover": {
    backgroundColor: "#163A90",
  },
  "&:disabled": {
    cursor: "auto",
    backgroundColor: "#adaaaa",
  },
}));
export const TB = styled(Table)`
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.25;
`;

export const Th = styled(TableCell)`
  &.MuiTableCell-head {
    font: normal normal bold 14px/20px Roboto;
    color: #281e53;
    background: #eaecf0;
    border: none;
    padding: 12px 0px;
    text-align: center;
  }
`;

export const Td = styled(TableCell)`
  &.MuiTableCell-body {
    font: normal normal normal 14px/20px Roboto;
    color: #333333;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    height: 50px;
    padding: 8px 15px;
    text-align: center;
  }
`;
export const MainContainer = styled(Box)((theme) => ({
  padding: "80px 16px 16px 16px",
  position: "absolute",
  width: "100%",
  height: "100%",
  ".contentContainer": {
    background: "#ffffff 0% 0% no-repeat padding-box",
    padding: "16px 20px",
    height: "100%",
    ".heading": {
      color: "#163A90",
      fontFamily: "Roboto",
    },
  },
}));
