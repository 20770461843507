import { styled } from "@mui/system";
import { Card } from "../../sdk";
import {
  Box,
  Table as TB,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export const PageContainer = styled(Box)`
  height: 100%;
  width: 100%;
  padding: 0px 20px;
`;

export const CardContainer = styled(Card)`
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 16px;
`;
export const DateSection = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: end;
  width: 100%;
`;

export const HeadSection = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const H = styled(Typography)`
  font: normal normal bold 24px/30px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  position: fixed;
  top: 13px;
  left: 80px;
  color: white;
  z-index: 999;
`;

export const DisplayDate = styled(Box)`
  width: 154px;
  font: normal normal normal 20px/25px Roboto;
  letter-spacing: 0px;
  color: #110641;
  margin-right: 33px;
  display: flex;
`;
export const Toggle = styled(Box)`
  width: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ToggelLeft = styled(Box)`
  &:hover {
    cursor: pointer;
  }
  color: #110641;
`;
export const ToggleRight = styled(Box)`
  &:hover {
    cursor: pointer;
  }
  color: #110641;
`;
export const ToggleRightDisabled = styled(Box)`
  &:hover {
    cursor: not-allowed;
  }
  color: grey;
`;

export const TableSection = styled(Box)``;

export const Table = styled(TB)`
  width: 100%;
`;
export const Thead = styled(TableHead)`
  width: 100%;
`;
export const Tbody = styled(TableBody)`
  width: 100%;
`;
export const Tr = styled(TableRow)`
  display: flex;
  width: 100%;
`;
export const Th = styled(TableHead)`
  text-align: left;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #130453;
  padding: 16px;
  width: 25%;
`;
export const Td = styled(TableCell)`
  text-align: left;
  font: normal normal normal 14px/18px Roboto;
  letter-spacing: 0px;
  color: #333333;
  display: flex;
  padding: 0 16px;

  width: 25%;
`;
export const Label = styled(Box)`
  font-family: Roboto;
`;
