import React from "react";
import { styled } from "@mui/system";
import { Box } from "@mui/material";
const Div = styled(Box)`
  width: 384px;
  height: 380px;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-right: 20px;
  margin-top: 16px;
`;

export const Card = ({ children, className }) => {
  return <Div className={className}>{children}</Div>;
};
