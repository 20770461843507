import { RemoveCircleRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogTitle,
  FormHelperText,
  IconButton,
  Input,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/system";
import { TContainer, TextFieldSmall } from "../../EventCalender/Styles";
import { DatePicker, getISODate } from "../../sdk";

export const PreciumModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    padding: 25px 40px;
    outline: 0;
    .header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .title {
        font: normal normal 600 16px/20px Roboto;
        letter-spacing: 0px;
        color: #333333;
      }
      button {
        color: #969696;
        font-size: 18px;
      }
    }
    .body {
      margin-top: 20px;
    }
    .footer {
      margin-top: 30px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const DmdLevel = styled(Box)`
  width: 80px;
`;

export const TextFieldLarge = styled(TextFieldSmall)`
  &&&.MuiInputBase-root.MuiInput-root {
    width: 100%;
    &&&:disabled {
      cursor: not-allowed;
    }
  }
`;

export const RemoveButton = styled(RemoveCircleRounded)`
  color: #210d79;
  cursor: pointer;
  margin-top: 4px;
`;

export const TableNew = styled(Table)`
  thead {
    th {
      font: normal normal bold 16px/20px Roboto;
      letter-spacing: 0px;
      color: rgb(48, 111, 188);
      border-right: 18px solid transparent;
    }
  }
  tbody {
    td {
      text-align: left;
      font: normal normal normal 16px/20px Roboto;
      letter-spacing: 0px;
      color: #000000;
      > div {
        margin: 15px 20px 15px 0;
      }
    }
  }
`;

const DAYS = [
  {
    name: "All",
    value: -1,
  },
  {
    name: "Sunday",
    value: 0,
  },
  {
    name: "Monday",
    value: 1,
  },
  {
    name: "Tuesday",
    value: 2,
  },
  {
    name: "Wednesday",
    value: 3,
  },
  {
    name: "Thursday",
    value: 4,
  },
  {
    name: "Friday",
    value: 5,
  },
  {
    name: "Saturday",
    value: 6,
  },
];

export const NewCalendarCityDemandModal = ({
  isOpen,
  setSetModalOpen,
  demandLevels,
  onSave,
  addDemandLevel,
  addCityDemandLevel,
  getData,
  selectedDate,
}) => {
  const { hotelId } = useParams();
  const [optionsArray, setOptionsArray] = useState([]);
  const [allEntriesValid, setAllEntriesValid] = useState(false);
  const [editingIndex, setEditingIndex] = useState(0);
  const scrollRef = useRef(null);
  const [demandLoader, setDemandLoader] = useState(false);
  const [cityDemand, setCityDemand] = useState(false);
  const [cityEvent, setCityEvent] = useState(false);
  const [demandError, setDemandError] = useState(true);
  const [cityEventError, setCityEventError] = useState(true);

  useEffect(() => {
    const today = new Date(
      selectedDate?.getFullYear(),
      selectedDate?.getMonth(),
      new Date().getDate()
    );
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    setOptionsArray([
      {
        startDate: yesterday.toString(),
        endDate: today.toString(),
        days: [0, 1, 2, 3, 4, 5, 6],
        hotelDemandLevelSymbol: "",
        cityDemandLevelSymbol: "",
        manualEvents: "",
        overrideSymbol: false,
        cityOverrideSymbol: false,
        overrideEvent: false,
      },
    ]);
  }, []);
  useEffect(() => {
    const allValidEntries = optionsArray.every(isValid);
    setAllEntriesValid(allValidEntries);
    if (optionsArray.length === 0) {
      setAllEntriesValid(true);
    }
  }, [optionsArray]);
  function isValid(option) {
    const {
      startDate,
      endDate,
      days,
      demandLevelSymbol,
      hotelDemandLevelSymbol,
      manualEvents,
      cityDemandLevelSymbol,
      cityOverrideSymbol,
    } = option;

    return (
      startDate &&
      endDate &&
      days &&
      new Date(endDate).setHours(0, 0, 0) >=
        new Date(startDate).setHours(0, 0, 0)
    );
  }

  function onValueUpdate(index, key, value) {
    if (key === "cityOverrideSymbol") {
      setCityDemand(value.checked);
    } else if (key === "overrideEvent") {
      setCityEvent(value.checked);
    } else if (key === "cityDemandLevelSymbol") {
      setDemandError(false);
    } else if (key === "manualEvents") {
      setCityEventError(false);
      if (value === "") {
        setCityEventError(true);
      }
    }
    if (key === "startDate" || key === "endDate") {
      value = getISODate(value);
    }
    value =
      key === "overrideSymbol" ||
      key === "overrideEvent" ||
      key === "cityOverrideSymbol"
        ? value.checked
        : value;

    setOptionsArray((prevState) =>
      prevState.map((option, idx) =>
        idx === index
          ? {
              ...option,
              [key]: value,
            }
          : option
      )
    );
  }
  function onAddClick() {
    if (allEntriesValid && optionsArray.length > 0) {
      setOptionsArray((prevState) => {
        const { endDate } = prevState[prevState.length - 1];
        const nextStartDate = new Date(Date.parse(endDate));
        nextStartDate.setDate(nextStartDate.getDate() + 1);
        const nextEndDate = new Date(Date.parse(nextStartDate));
        nextEndDate.setDate(nextEndDate.getDate() + 1);
        return [
          ...prevState,
          {
            startDate: nextStartDate.toString(),
            endDate: nextEndDate.toString(),
            days: [0, 1, 2, 3, 4, 5, 6],
            hotelDemandLevelSymbol: "",
            cityDemandLevelSymbol: "",
            manualEvents: "",
            overrideSymbol: false,
            cityOverrideSymbol: false,
            overrideEvent: false,
          },
        ];
      });
      setEditingIndex(optionsArray.length);
    } else if (allEntriesValid && optionsArray.length === 0) {
      const today = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        new Date().getDate()
      );
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      setOptionsArray([
        {
          startDate: yesterday.toString(),
          endDate: today.toString(),
          days: [0, 1, 2, 3, 4, 5, 6],
          hotelDemandLevelSymbol: "",
          cityDemandLevelSymbol: "",
          manualEvents: "",
          overrideSymbol: false,
          cityOverrideSymbol: false,
          overrideEvent: false,
        },
      ]);
      setEditingIndex(optionsArray.length);
    }
  }
  const shouldSaveDisable = useCallback(() => {
    if (
      cityDemand === true &&
      demandError === false &&
      cityEvent === true &&
      cityEventError == true
    ) {
      return true;
    } else if (cityDemand === true && demandError === false) {
      return false;
    } else if (
      cityEvent === true &&
      cityEventError === false &&
      cityDemand == true &&
      demandError === true
    ) {
      return true;
    } else if (cityEvent === true && cityEventError === false) {
      return false;
    } else if (
      cityDemand === true &&
      demandError == false &&
      cityEvent == true &&
      cityEventError === false
    ) {
      return false;
    } else if (optionsArray.length == 0) {
      return true;
    } else {
      return true;
    }
  }, [cityDemand, cityEvent, cityEventError, demandError, optionsArray.length]);

  async function onSaveClick() {
    const newArray = optionsArray.map((data, idx) => {
      const manual = data?.manualEvents ? [data?.manualEvents] : [];
      return {
        startDate: data.startDate,
        endDate: data.endDate,
        days: data.days,
        hotelDemandLevelSymbol: data.hotelDemandLevelSymbol,
        cityDemandLevelSymbol: data.cityOverrideSymbol
          ? data.cityDemandLevelSymbol
          : "",
        manualEvents: data.overrideEvent ? manual : [],
        overrideSymbol: data.overrideSymbol,
        cityOverrideSymbol: data.cityOverrideSymbol,
        overrideEvent: data.overrideEvent,
      };
    });
    await onSave(newArray.filter(isValid));
    const today = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      new Date().getDate()
    );
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate());
    setOptionsArray([
      {
        startDate: yesterday.toString(),
        endDate: today.toString(),
        days: [0, 1, 2, 3, 4, 5, 6],
        hotelDemandLevelSymbol: "",
        cityDemandLevelSymbol: "",
        manualEvents: "",
        overrideSymbol: false,
        cityOverrideSymbol: false,
        overrideEvent: false,
      },
    ]);
    let shouldSet = { val: true };
    await getData(shouldSet);
    setSetModalOpen(false);
    setDemandLoader(false);
  }

  function getClose() {
    const today = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      new Date().getDate()
    );
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    setOptionsArray([
      {
        startDate: yesterday.toString(),
        endDate: today.toString(),
        days: [0, 1, 2, 3, 4, 5, 6],
        hotelDemandLevelSymbol: "",
        cityDemandLevelSymbol: "",
        manualEvents: "",
        overrideSymbol: false,
        cityOverrideSymbol: false,
        overrideEvent: false,
      },
    ]);
    setCityDemand(false);
    setCityEvent(false);
    setCityEventError(true);
    setDemandError(true);
    setSetModalOpen(false);
  }

  const onDeleteClick = (index) => {
    setOptionsArray([
      ...optionsArray.filter((_, idx) => idx < index),
      ...optionsArray.filter((_, idx) => idx > index),
    ]);
    setEditingIndex(optionsArray.length - 1);
  };

  return (
    <PreciumModal
      aria-labelledby="Set City Demand Level"
      aria-describedby="Utility to set city demand level in batches by selecting dates and days"
      open={isOpen}
      onClose={getClose}
    >
      <div className="container">
        Set City Demand Levels
        <DialogTitle className="header">
          <Typography className="title">Set City Demand Levels</Typography>
          <IconButton onClick={getClose}>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "900", color: "#000" }}
            >
              X
            </Typography>
          </IconButton>
        </DialogTitle>
        <div className="body" style={{ maxHeight: "240px", overflowX: "auto" }}>
          <TContainer component={Paper}>
            <TableNew stickyHeader>
              <thead>
                <tr
                  style={
                    optionsArray?.length === 0
                      ? {
                          display: "flex",
                          justifyContent: "space-between",
                        }
                      : {}
                  }
                >
                  <th></th>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Days Selection</th>
                  {/* <th style={{ textAlign: "left" }}>Hotel Demand Level</th> */}
                  {/* {hotelId === "111" && (
                      <th style={{ textAlign: "left" }}>City Demand Level</th>
                    )} */}
                  <th style={{ textAlign: "left" }}>City Demand Level</th>
                  {hotelId !== "" ? (
                    <th style={{ paddingRight: "18px" }}> City Event</th>
                  ) : (
                    <th style={{ paddingRight: "18px" }}> City Event</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {optionsArray.map((row, idx) => (
                  <tr style={{ height: "90px" }} key={idx}>
                    <td>{idx + 1}</td>
                    <td>
                      <DatePicker
                        date={new Date(row.startDate)}
                        onChange={(startDate) =>
                          onValueUpdate(idx, "startDate", startDate)
                        }
                      />
                    </td>
                    <td>
                      <DatePicker
                        date={new Date(row.endDate)}
                        onChange={(endDate) =>
                          onValueUpdate(idx, "endDate", endDate)
                        }
                      />
                    </td>
                    <td>
                      <Select
                        label="Days"
                        multiple
                        value={row.days}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value.indexOf(-1) !== -1) {
                            onValueUpdate(idx, "days", [0, 1, 2, 3, 4, 5, 6]);
                            return;
                          }
                          onValueUpdate(idx, "days", value);
                        }}
                        input={<Input />}
                        MenuProps={{ getContentAnchorEl: null }}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>Select Days</em>;
                          }
                          return DAYS.filter(
                            (day) => selected.indexOf(day.value) !== -1
                          )
                            .map((day) => day.name.substring(0, 3))
                            .join(", ");
                        }}
                        style={{ width: "130px" }}
                      >
                        {DAYS.map(({ name, value }) => (
                          <MenuItem key={name} value={value}>
                            <ListItemText primary={name} />
                            {value !== -1 && (
                              <Checkbox
                                checked={row.days.indexOf(value) > -1}
                              />
                            )}
                          </MenuItem>
                        ))}
                      </Select>
                    </td>

                    <td style={{ paddingTop: "0px" }}>
                      <div style={{ display: "flex", alignItems: "start" }}>
                        {addCityDemandLevel ? (
                          row.cityOverrideSymbol ? (
                            <DmdLevel
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Select
                                label="Demand Level"
                                value={row.cityDemandLevelSymbol}
                                onChange={(e) => {
                                  onValueUpdate(
                                    idx,
                                    "cityDemandLevelSymbol",
                                    e.target.value
                                  );
                                }}
                                input={<Input />}
                                MenuProps={{ getContentAnchorEl: null }}
                                style={{ width: "50px" }}
                                error={row.cityDemandLevelSymbol === ""}
                                helperText={true ? "Invalid Entry" : ""}
                              >
                                {demandLevels.map(({ symbol }) => (
                                  <MenuItem key={symbol} value={symbol}>
                                    <ListItemText primary={symbol} />
                                  </MenuItem>
                                ))}
                              </Select>
                              {row.cityDemandLevelSymbol === "" && (
                                <FormHelperText error={true}>
                                  <p style={{ whiteSpace: "nowrap" }}>
                                    {" "}
                                    Demand Level Not Selected
                                  </p>
                                </FormHelperText>
                              )}
                            </DmdLevel>
                          ) : (
                            <DmdLevel>
                              <Select
                                input={<Input />}
                                disabled
                                style={{ cursor: "not-allowed" }}
                              />
                            </DmdLevel>
                          )
                        ) : (
                          <DmdLevel>
                            <Select
                              input={<Input />}
                              disabled
                              style={{ cursor: "not-allowed" }}
                            />
                          </DmdLevel>
                        )}
                        <div style={{ paddingLeft: "18px" }}>
                          {addCityDemandLevel ? (
                            <Checkbox
                              checked={row.cityOverrideSymbol}
                              onChange={(e) =>
                                onValueUpdate(
                                  idx,
                                  "cityOverrideSymbol",
                                  e.target
                                )
                              }
                              style={{ color: "rgb(48,111,188)" }}
                            />
                          ) : (
                            <Checkbox
                              disabled
                              checked={row.cityOverrideSymbol}
                            />
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div style={{ display: "flex", alignItems: "start" }}>
                        {row.overrideEvent ? (
                          <TextFieldLarge
                            variant="standard"
                            multiline
                            value={row.manualEvents}
                            onChange={(e) =>
                              onValueUpdate(idx, "manualEvents", e.target.value)
                            }
                            error={row.manualEvents === ""}
                            helperText={
                              row.manualEvents === "" ? "Event Required" : ""
                            }
                          />
                        ) : (
                          <TextFieldLarge
                            variant="standard"
                            multiline
                            value={""}
                            disabled
                          />
                        )}
                        <Checkbox
                          checked={row.overrideEvent}
                          onChange={(e) =>
                            onValueUpdate(idx, "overrideEvent", e.target)
                          }
                          style={{ color: "rgb(48,111,188)" }}
                        />
                      </div>
                    </td>
                    <td>
                      <RemoveButton
                        onClick={() => onDeleteClick(idx)}
                        style={{
                          color: "rgb(48,111,188)",
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </TableNew>
          </TContainer>
          <div ref={scrollRef} />
        </div>
        <div className="footer">
          <Button
            disabled={!allEntriesValid}
            variant="contained"
            onClick={async () => {
              await onAddClick();
              scrollRef.current.scrollIntoView({ behavior: "smooth" });
            }}
          >
            + Add
          </Button>
          <Button
            variant="contained"
            // disabled={optionsArray.length === 0 ? true : false}
            disabled={shouldSaveDisable()}
            onClick={() => {
              setDemandLoader(true);
              onSaveClick();
            }}
          >
            {demandLoader === true ? (
              <CircularProgress style={{ color: "white" }} />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>
    </PreciumModal>
  );
};
