import {
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  TextField,
  FormControl,
  Select,
  Box,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { CloseRounded } from "@mui/icons-material";
import React from "react";
import { styled } from "@mui/system";
import { PrimaryButton } from "../../sdk";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ClassWrapper = styled(Box)(({ theme }) => ({
  ".paper": {
    position: "absolute",
    outline: 0,
    width: 400,
    height: 250,
    backgroundColor: "#fff",
    boxShadow: 5,
    padding: 16,
  },
}));
const Div = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;
const Div1 = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 15px;
`;
const Mybtn = styled(PrimaryButton)`
  box-shadow: 0px 3px 6px #0000002f;
  border-radius: 4px;
  width: 88px;
  height: 28px;
  font: normal normal 600 12px/15px Roboto;
  font-color: #ffffff;
`;
const CancelBtn = styled(PrimaryButton)`
  background: #d9d9d9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000019;
  border-radius: 4px;
  border: none;
  width: 88px;
  height: 28px;
  margin-right: 25px;
  font: normal normal 600 12px/15px Roboto;
  font-color: #575757;
`;

const Head = styled(Box)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 20px;
`;
const Cross = styled(CloseRounded)`
  width: 16px;
  height: 16px;
  :hover {
    cursor: pointer;
  }
`;

const MUL = ["ActualDemandLevel", "DayOfWeek", "LeadTime"];

export function ModelBody({
  setCopyFromHotelId,
  copyFromHotelId,
  copyHotelName,
  setCopyHotelName,
  handleMULChange,
  selectMUL,
  updateDLF,
  setOpenSelectBar,
  allHotels,
  hotelId,
}) {
  const [modalStyle] = React.useState(getModalStyle);
  const defaultHotelProps = {
    options: [
      ...allHotels
        .filter((hotel) => hotel.id !== parseInt(hotelId))
        .map((hotelNew) => {
          return hotelNew.name + " (" + hotelNew.organizationName + ")";
        }),
    ],
    getOptionLabel: (option) => option,
  };
  const handleHotelChange = (value) => {
    if (value) {
      const hotelName = value.split("(")[0].trim();
      const selectedHotel = allHotels.find((hotel) => hotel.name === hotelName);
      if (selectedHotel) {
        setCopyFromHotelId(selectedHotel.id);
        setCopyHotelName(selectedHotel.name);
      } else {
        setCopyFromHotelId(null);
      }
    } else setCopyFromHotelId(null);
  };

  return (
    <ClassWrapper>
      <Div style={modalStyle} className="paper">
        <Head>
          <Cross
            onClick={() => {
              setOpenSelectBar(false);
            }}
          >
            &#x274C;
          </Cross>
        </Head>
        <Autocomplete
          {...defaultHotelProps}
          value={copyHotelName}
          style={{ width: "65%", font: "Roboto", paddingBottom: "20px" }}
          freeSolo={true}
          onChange={(event, newValue) => {
            handleHotelChange(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Search Hotel" />
          )}
        />
        <FormControl variant="outlined" className="formControl">
          <InputLabel>Multipliers</InputLabel>

          <Select
            multiple
            value={selectMUL}
            label="Multipliers"
            onChange={(e) => handleMULChange(e.target.value)}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>Placeholder</em>;
              }

              return selected.join(", ");
            }}
            MenuProps={{ getContentAnchorEl: null }}
            style={{ width: "200px" }}
          >
            {MUL.map((val, idx) => (
              <MenuItem key={idx} value={val}>
                <ListItemText primary={val} />
                <Checkbox checked={selectMUL.indexOf(val) > -1} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Div1>
          <CancelBtn
            onClick={() => {
              setOpenSelectBar(false);
            }}
          >
            Cancel
          </CancelBtn>
          <Mybtn
            next
            disabled={copyFromHotelId === null ? true : false}
            style={
              copyFromHotelId === null
                ? { color: "#ffffff", cursor: "not-allowed" }
                : { color: "#ffffff" }
            }
            onClick={updateDLF}
          >
            Confirm
          </Mybtn>
        </Div1>
      </Div>
    </ClassWrapper>
  );
}
