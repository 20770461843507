import {
  Table as TB,
  TableBody,
  TableCell,
  TableHead,
  Box,
  TableRow,
  Typography,
  InputLabel,
} from "@mui/material";
import { CalendarToday, CloseRounded } from "@mui/icons-material";
import { styled } from "@mui/system";
import { Card, PrimaryButton } from "../sdk";

export const CalenderIcon = styled(CalendarToday)`
  position: absolute;
  width: 20px;
  height: 22px;
`;
export const MonthPickerContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;

  justify-content: flex-start;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
  padding-left: 18px;
  padding-right: 18px;
`;

export const PRWrapper = styled(Box)`
  display: flex;
  width: 100%;
  margin: 0px;
  padding: 0px;
  height: 92vh;
`;

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 52px;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  position: fixed;
  margin-top: 20px;
`;
export const H = styled(Typography)`
  font: normal normal bold 24px/30px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
`;
export const Head = styled(Box)`
  position: fixed;
  top: 13px;
  left: 80px;
  color: white;
  z-index: 999;
`;

export const ActualsPage = styled(Box)`
  padding: 40px 0px 0px 60px;
  width: 99%;
`;

export const UploadContainer = styled(Box)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  margin-right: 100px;
  margin-top: 15px;
  cursor: pointer;
  border-radius: 20px;
  span {
    display: none;
    white-space: nowrap;
    overflow: hidden;
  }
  &:hover {
    transition: width 0.1s ease-out 0.1s;
    width: 204px;
    border-radius: 0;
    span {
      display: block;
    }
  }
`;

export const Section = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 8px 24px 8px 24px;
  height: 200px;
`;
export const ChooseBtn = styled(InputLabel)`
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;

  line-height: 1.25;

  color: #000000;
  &:hover {
    cursor: pointer;
  }
  height: 24px;
  border: 1px solid #130453;
  border-radius: 5px;
  color: #130453;
  padding: 4px 10px;
`;
export const Filename = styled(Typography)`
  font: normal normal normal 12px/15px Roboto;
  margin-left: 10px;
`;
export const Title = styled(Typography)`
  font: normal normal normal 20px/25px Roboto;
  letter-spacing: 0px;
  color: #333333;
`;
export const Cross = styled(CloseRounded)`
  width: 16px;
  height: 16px;
  :hover {
    cursor: pointer;
  }
`;

export const CrossSmall = styled(CloseRounded)`
  width: 12px;
  height: 12px;
  margin-top: -30px;
  margin-left: 10px;
  :hover {
    cursor: pointer;
  }
`;

export const HeadUM = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const UploadSection = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Done = styled(PrimaryButton)`
  width: 80px;
  height: 24px;
  background: #130453 0% 0% no-repeat padding-box;
  border: 1px solid #130453;
  border-radius: 5px;
  font: normal normal bold 12px/15px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
`;

export const Table = styled(TB)`
  margin: auto;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.25;
  overflow: scroll;
`;

export const Th = styled(TableCell)`
  &.MuiTableCell-head {
    vertical-align: bottom;
    font: normal normal bold 16px/20px Roboto;
    color: #130453;
    background-color: white;
    border: none;
    text-align: center;
    padding: 10px 0px;
  }
`;
export const Td = styled(TableCell)`
  &.MuiTableCell-body {
    font: normal normal normal 16px/20px Roboto;
    color: #333333;
    border: none;
    height: 32px;
    padding: 0 15px;
    text-align: center;
    max-width: 100px;
    width: 100px;
  }
`;

export const Tr = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "rgba(48, 81, 221,0.09)",
  },
}));

export const Thead = styled(TableHead)``;
export const Tbody = styled(TableBody)``;
export const ClassWrapper = styled(Box)(() => ({
  ".container": {
    height: "calc(100% - 40px)",
    width: 450,
  },
  ".arrow": {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  ".rowHover": {
    "&:hover": {
      color: "red",
      backgroundColor: "rgba(48, 81, 221,0.4) !important",
    },
  },
}));

export const MyCard = styled(Card)`
  width: 490px;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 20px;
  padding-top: 10px;
  height: 96%;
`;

export const Hpr = styled(Typography)`
  text-align: center;
  font: normal normal normal 20px/25px Roboto;
  letter-spacing: 0px;
  color: #000000;
  padding-bottom: 8px;
`;
export const Wrapper = styled(Box)`
  display: flex;
  width: 100%;
  padding: 12px 0 0 20px;
  height: calc(100vh - 140px);
  margin-top: 40px;
`;
export const DATE = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 110px;
  padding-left: 8px;
`;
export const DAY = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 70px;
  padding-left: 16px;
`;
