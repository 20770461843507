import { CalendarToday } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { styled } from "@mui/system";
import { StyledCalenderWrapper } from "..";
import {
  ChooseBtn,
  Cross,
  CrossSmall,
  DateSection,
  ExpediaSection,
  Head,
  Label,
  OTASection,
  RatepingSection,
  SectionWrapper,
  Title,
  UploadDone,
  UploadFile,
  UploadFilename,
  UploadHeader,
  UploadSectionModel,
} from "../Styles";

export const CalenderIcon = styled(CalendarToday)`
  position: absolute;
  width: 20px;
  height: 22px;
  color: #306fbc;
  z-index: 101;
  margin-left: -32px;
  margin-top: 5px;
  cursor: pointer;
`;
function getModalStyle() {
  const top = 9;
  const right = 34;

  return {
    top: `${top}%`,
    right: `${right}%`,
    transform: `translate(-${top}, -${right})`,
  };
}

export function ModelBody({
  hotelSelect,
  fileHandler,
  ratepingFileGetter,
  expediaFileGetter,
  travelClickGetter,
  otaFileGetter,
  setOpenSelectBar,
  removeRatepingFile,
  removeExpediaFile,
  removeOtaFile,
  removeTravelClickFile,
  ratepingFilename,
  expediaFilename,
  otaFilename,
  travelClickFileName,
  travelClickFileToggle,
  ratepingToggle,
  expediaToggle,
  otaToggle,
  rategainToggle,
  rateGainFileName,
  rateGainGetter,
  removeRateGainFile,
  genericToggle,
  genericFileName,
  genericFileGetter,
  removeGenericFile,
  uploadActualDate,
  setUploadActualDate,
  compPriceUploadForSpecificDate,
}) {
  const [modalStyle] = useState(getModalStyle);

  const defaultValue = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  const maximumDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  const [selectedDay, setSelectedDay] = useState(defaultValue);
  useEffect(() => {
    if (selectedDay) {
      setUploadActualDate(
        new Date(
          [
            selectedDay?.year,
            selectedDay?.month,
            selectedDay?.day.length === 1
              ? "0" + selectedDay?.day
              : selectedDay?.day,
          ].join("-")
        )
      );
    }
  }, [selectedDay]);
  const value =
    `${selectedDay.day < 10 ? "0" + selectedDay.day : selectedDay.day}` +
    "/" +
    `${selectedDay.month < 10 ? "0" + selectedDay.month : selectedDay.month}` +
    "/" +
    `${selectedDay.year}`;

  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={value}
      style={{
        textAlign: "center",
        fontSize: "14px",
        borderRadius: "10px",
        boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
        color: "#000000",
        outline: "none",
        width: "160px",
        height: "30px",
        border: "none",
        paddingRight: "14px",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
      }}
    />
  );

  return (
    <SectionWrapper
      position="absolute"
      backgroundColor="#fff"
      style={modalStyle}
      compPriceUploadForSpecificDate
    >
      <Head>
        <Title>Upload Competitor Pricing</Title>
        <Cross onClick={() => setOpenSelectBar(false)}>&times;</Cross>
      </Head>

      {compPriceUploadForSpecificDate && (
        <DateSection>
          <Label htmlFor="picker">Date</Label>
          <StyledCalenderWrapper>
            <DatePicker
              id="picker"
              value={selectedDay}
              onChange={setSelectedDay}
              maximumDate={maximumDate}
              calendarClassName="myCustomCalendar"
              renderInput={renderCustomInput}
              className="DatePicker"
            />
            <CalenderIcon />
          </StyledCalenderWrapper>
        </DateSection>
      )}
      <UploadSectionModel
        style={
          hotelSelect?.competitorPricingFileTypeID === 1 ||
          hotelSelect?.competitorPricingFileTypeID === 2 ||
          hotelSelect?.competitorPricingFileTypeID === 4 ||
          hotelSelect?.competitorPricingFileTypeID === 5 ||
          hotelSelect?.competitorPricingFileTypeID === 6 ||
          hotelSelect?.competitorPricingFileTypeID === 7
            ? { justifyContent: "center" }
            : {}
        }
      >
        {(hotelSelect?.competitorPricingFileTypeID === 1 ||
          hotelSelect?.competitorPricingFileTypeID === 3) && (
          <ExpediaSection>
            <ChooseBtn
              style={expediaToggle ? { display: "none" } : { display: "block" }}
              htmlFor="ex_upload"
            >
              Choose Expedia File
              <input
                type="file"
                id="ex_upload"
                onChange={(e) => expediaFileGetter(e.target.files)}
                style={{ display: "none" }}
              />
            </ChooseBtn>
            <UploadFile
              style={expediaToggle ? { display: "flex" } : { display: "none" }}
            >
              <UploadHeader>
                <img
                  src="/assets/excel.svg"
                  alt="Excel Icon"
                  width="36px"
                  height="36px"
                />
                <UploadFilename>{expediaFilename}</UploadFilename>
              </UploadHeader>
              <CrossSmall
                onClick={() => removeExpediaFile()}
                style={
                  expediaToggle ? { display: "block" } : { display: "none" }
                }
              >
                &times;
              </CrossSmall>
            </UploadFile>
          </ExpediaSection>
        )}

        {(hotelSelect?.competitorPricingFileTypeID === 2 ||
          hotelSelect?.competitorPricingFileTypeID === 3) && (
          <RatepingSection>
            <ChooseBtn
              style={
                ratepingToggle ? { display: "none" } : { display: "block" }
              }
              htmlFor="rp_upload"
            >
              Choose Rateping File
              <input
                type="file"
                id="rp_upload"
                onChange={(e) => ratepingFileGetter(e.target.files)}
                style={{ display: "none" }}
              />
            </ChooseBtn>
            <UploadFile
              style={ratepingToggle ? { display: "flex" } : { display: "none" }}
            >
              <UploadHeader>
                <img
                  src="/assets/excel.svg"
                  alt="Excel Icon"
                  width="36px"
                  height="36px"
                />
                <UploadFilename>{ratepingFilename}</UploadFilename>
              </UploadHeader>
              <CrossSmall
                onClick={() => removeRatepingFile()}
                style={
                  ratepingToggle ? { display: "block" } : { display: "none" }
                }
              >
                &times;
              </CrossSmall>
            </UploadFile>
          </RatepingSection>
        )}

        {hotelSelect?.competitorPricingFileTypeID === 6 && (
          <OTASection
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100% ",
            }}
          >
            <ChooseBtn
              style={
                travelClickFileToggle
                  ? { display: "none" }
                  : { display: "block" }
              }
              htmlFor="travelClick_upload"
            >
              Choose Travel Click File
              <input
                type="file"
                id="travelClick_upload"
                onChange={(e) => travelClickGetter(e.target.files)}
                style={{ display: "none" }}
              />
            </ChooseBtn>
            <UploadFile
              style={
                travelClickFileToggle
                  ? { display: "flex" }
                  : { display: "none" }
              }
            >
              <UploadHeader>
                <img
                  src="/assets/excel.svg"
                  alt="Excel Icon"
                  width="36px"
                  height="36px"
                />
                <UploadFilename>{travelClickFileName}</UploadFilename>
              </UploadHeader>
              <CrossSmall
                onClick={() => removeTravelClickFile()}
                style={
                  travelClickFileToggle
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                &times;
              </CrossSmall>
            </UploadFile>
          </OTASection>
        )}

        {hotelSelect.competitorPricingFileTypeID === 5 && (
          <OTASection>
            <ChooseBtn
              style={otaToggle ? { display: "none" } : { display: "block" }}
              htmlFor="ota_upload"
            >
              Choose OTA Insight File
              <input
                type="file"
                id="ota_upload"
                onChange={(e) => otaFileGetter(e.target.files)}
                style={{ display: "none" }}
              />
            </ChooseBtn>
            <UploadFile
              style={otaToggle ? { display: "flex" } : { display: "none" }}
            >
              <UploadHeader>
                <img
                  src="/assets/excel.svg"
                  alt="Excel Icon"
                  width="36px"
                  height="36px"
                />
                <UploadFilename>{otaFilename}</UploadFilename>
              </UploadHeader>
              <CrossSmall
                onClick={() => removeOtaFile()}
                style={otaToggle ? { display: "block" } : { display: "none" }}
              >
                &times;
              </CrossSmall>
            </UploadFile>
          </OTASection>
        )}

        {hotelSelect.competitorPricingFileTypeID === 7 && (
          <OTASection>
            <ChooseBtn
              style={
                rategainToggle ? { display: "none" } : { display: "block" }
              }
              htmlFor="rategain_upload"
            >
              Choose RateGain File
              <input
                type="file"
                id="rategain_upload"
                onChange={(e) => rateGainGetter(e.target.files)}
                style={{ display: "none" }}
              />
            </ChooseBtn>
            <UploadFile
              style={rategainToggle ? { display: "flex" } : { display: "none" }}
            >
              <UploadHeader>
                <img
                  src="/assets/excel.svg"
                  alt="Excel Icon"
                  width="36px"
                  height="36px"
                />
                <UploadFilename>{rateGainFileName}</UploadFilename>
              </UploadHeader>
              <CrossSmall
                onClick={() => removeRateGainFile()}
                style={
                  rategainToggle ? { display: "block" } : { display: "none" }
                }
              >
                &times;
              </CrossSmall>
            </UploadFile>
          </OTASection>
        )}

        {hotelSelect?.competitorPricingFileTypeID === 4 && (
          <OTASection>
            <ChooseBtn
              style={genericToggle ? { display: "none" } : { display: "block" }}
              htmlFor="generic_upload"
            >
              Choose Generic File
              <input
                type="file"
                id="generic_upload"
                onChange={(e) => genericFileGetter(e.target.files)}
                style={{ display: "none" }}
              />
            </ChooseBtn>
            <UploadFile
              style={genericToggle ? { display: "flex" } : { display: "none" }}
            >
              <UploadHeader>
                <img
                  src="/assets/excel.svg"
                  alt="Excel Icon"
                  width="36px"
                  height="36px"
                />
                <UploadFilename>{genericFileName}</UploadFilename>
              </UploadHeader>
              <CrossSmall
                onClick={() => removeGenericFile()}
                style={
                  genericToggle ? { display: "block" } : { display: "none" }
                }
              >
                &times;
              </CrossSmall>
            </UploadFile>
          </OTASection>
        )}
      </UploadSectionModel>
      <UploadDone
        sx={{ marginTop: "10px" }}
        next
        type="button"
        onClick={() => fileHandler()}
      >
        Upload
      </UploadDone>
    </SectionWrapper>
  );
}
