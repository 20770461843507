import { IconButton, Box, Popover } from "@mui/material";
import {
  ChevronLeft,
  ChevronRight,
  CalendarToday,
  DoubleArrow,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { GhostButton as GS } from "./Button";

const GhostButton = styled(GS)`
  font: normal normal bold 16px/20px Roboto;
  color: #281e53;
  cursor: pointer;
  min-width: 160px;
  padding: 5 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Main = styled(Box)`
  width: 290px;
  height: 320px;
`;

const Header = styled(Box)`
  font-size: 18px;
  font-weight: bold;
  padding: 10px 10px 5px 10px;
  display: flex;
  justify-content: space-between;
  background-color: #f5f6fa;
`;

const Body = styled(Box)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const Switch = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: normal normal normal 18px/20px Roboto;
`;

const Row = styled(Box)`
  display: flex;
  padding: 16px;
  font: normal normal normal 18px/20px Roboto;
  width: 14.2%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const CellGhost = styled(GS)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  font: normal normal normal 18px/20px Roboto;
  &&&& {
    min-width: 40px;
    &:hover {
      border-radius: 2px;
      opacity: 0.9;
    }
    &:disabled {
      background-color: transparent;
      cursor: not-allowed;
      color: ${({ disabled }) => (disabled ? "#6f6f6f" : "#ffffff")};
    }
  }
`;
const CellPrimary = styled(CellGhost)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  font: normal normal normal 18px/20px Roboto;
  &&&& {
    min-width: 40px;
    background-color: rgb(17, 6, 65);
    color: white;
    &:hover {
      border-radius: 2px;
      opacity: 0.9;
    }
    &:disabled {
      background-color: transparent;
      cursor: not-allowed;
      color: ${({ disabled }) => (disabled ? "#6f6f6f" : "#ffffff")};
    }

    &:active {
      background-color: ${({ disabled }) =>
        disabled ? "transparent" : "#f1f2fe"};
    }
  }
`;
const DAYS_OF_THE_WEEK = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
const MONTHS = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

export function DatePicker({
  date,
  onChange,
  disabled = false,
  disableFutureDates = false,
  disableFromCurrent = false,
  minWidth = "160px",
}) {
  const [currentDate, setCurrentDate] = useState(date.getDate());
  const [month, setMonth] = useState(date.getMonth());
  const [year, setYear] = useState(date.getFullYear());
  const [currentYear] = useState(new Date().getFullYear());
  const [currentMonth] = useState(new Date().getMonth());
  const [currentDay] = useState(new Date().getDate());
  const [anchorEl, setAnchorEl] = useState(null);

  const [startDay, setStartDay] = useState(getStartDayOfMonth(date));

  useEffect(() => {
    setCurrentDate(date.getDate());
    setMonth(date.getMonth());
    setYear(date.getFullYear());
  }, [date]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const prevYear = React.useCallback(() => {
    const prevYearDays = new Date(year - 1, month + 1, 0).getDate();
    if (prevYearDays < currentDate)
      setStartDay(getStartDayOfMonth(new Date(year - 1, month, prevYearDays)));
    else
      setStartDay(getStartDayOfMonth(new Date(year - 1, month, currentDate)));
    setYear(year - 1);
  }, [year, month, currentDate]);

  const nextYear = React.useCallback(() => {
    setYear(year + 1);
    const nextYearDays = new Date(year + 1, month + 1, 0).getDate();
    if (nextYearDays < currentDate)
      setStartDay(getStartDayOfMonth(new Date(year + 1, month, nextYearDays)));
    else
      setStartDay(getStartDayOfMonth(new Date(year + 1, month, currentDate)));
  }, [year, month, currentDate]);

  const prevMonth = React.useCallback(() => {
    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
      setStartDay(getStartDayOfMonth(new Date(year - 1, 11, currentDate)));
    } else {
      setMonth(month - 1);

      const prevmonthDay = new Date(year, month, 0).getDate();
      if (prevmonthDay < currentDate)
        setStartDay(
          getStartDayOfMonth(new Date(year, month - 1, prevmonthDay))
        );
      else
        setStartDay(getStartDayOfMonth(new Date(year, month - 1, currentDate)));
    }
  }, [year, month, currentDate]);

  const nextMonth = React.useCallback(() => {
    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
      setStartDay(getStartDayOfMonth(new Date(year + 1, 0, currentDate)));
    } else {
      setMonth(month + 1);

      const nextMonthDays = new Date(year, month + 2, 0).getDate();
      if (currentDate > nextMonthDays)
        setStartDay(
          getStartDayOfMonth(new Date(year, month + 1, nextMonthDays))
        );
      else
        setStartDay(getStartDayOfMonth(new Date(year, month + 1, currentDate)));
    }
  }, [year, month, currentDate]);

  const makeMonth = React.useMemo(() => {
    return Array(new Date(year, month + 1, 0).getDate() + (startDay - 1)).fill(
      null
    );
  }, [year, month, startDay]);

  function getStartDayOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay() === 0
      ? 7
      : new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  }
  return (
    <div>
      <GhostButton
        style={{ minWidth: `${minWidth}` }}
        variant="contained"
        color="primary"
        disabled={disabled}
        onClick={handleClick}
      >
        {new Date(date).toLocaleDateString("en-GB")}
        {!disabled && (
          <CalendarToday
            style={{
              color: "rgb(48,111,188)",
              fontSize: "23px",
              marginRight: "17px",
            }}
          />
        )}
      </GhostButton>
      <Popover
        sx={{ marginLeft: "14px" }}
        id={"DatePicker"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box p={"10px"}>
          <Main>
            <Switch>
              <div>
                <IconButton
                  onClick={() => prevYear()}
                  style={{ transform: "rotate(180deg)", padding: "0px" }}
                >
                  <DoubleArrow />
                </IconButton>
                <IconButton onClick={() => prevMonth()}>
                  <ChevronLeft />
                </IconButton>
              </div>
              <div>
                {MONTHS[month]} {year}
              </div>
              <div>
                <IconButton
                  disabled={
                    (disableFutureDates || disableFromCurrent) &&
                    currentYear === year &&
                    currentMonth === month
                  }
                  onClick={() => nextMonth()}
                >
                  <ChevronRight />
                </IconButton>
                <IconButton
                  disabled={
                    (disableFutureDates || disableFromCurrent) &&
                    (currentYear === year ||
                      (currentYear === year + 1 && currentMonth <= month))
                  }
                  onClick={() => nextYear()}
                >
                  <DoubleArrow />
                </IconButton>
              </div>
            </Switch>
            <Body>
              {DAYS_OF_THE_WEEK.map((weekDay) => (
                <Row key={weekDay}>{weekDay}</Row>
              ))}

              {makeMonth.map((_, index) => {
                const actualDate = index - (startDay - 2);
                const isDisabled =
                  (disableFutureDates &&
                    actualDate > currentDay &&
                    currentYear === year &&
                    currentMonth === month) ||
                  (disableFromCurrent &&
                    actualDate >= currentDay &&
                    currentYear === year &&
                    currentMonth === month);

                return (
                  <Row key={index}>
                    {actualDate === currentDate &&
                    date.getMonth() === month &&
                    date.getFullYear() === year ? (
                      <CellPrimary
                        onClick={() => {
                          onChange(new Date(year, month, actualDate));
                          handleClose();
                        }}
                      >
                        {actualDate > 0 ? actualDate : ""}
                      </CellPrimary>
                    ) : (
                      <CellGhost
                        onClick={() => {
                          onChange(new Date(year, month, actualDate));
                          handleClose();
                        }}
                        disabled={isDisabled}
                      >
                        {actualDate > 0 ? actualDate : ""}
                      </CellGhost>
                    )}
                  </Row>
                );
              })}
            </Body>
          </Main>
        </Box>
      </Popover>
    </div>
  );
}
