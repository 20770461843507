import {
  Button,
  Snackbar,
  SnackbarContent,
  Modal,
  Box as BX,
} from "@mui/material";
import {
  CalendarToday,
  ChevronLeft,
  ChevronRight,
  InfoOutlined,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Prompt, useParams } from "react-router";
import { styled } from "@mui/system";
import { LoadingPage, NoAccess, Nodata, useAuth } from "../sdk";
import {
  BaseDemandLevelCard,
  BatchDemandLevelModal,
  CopyFromHotelModal,
  IndexModal,
} from "./components";
import { useBaseDemandLevel } from "./hooks";
import {
  BtnContainer,
  DateSection,
  DisplayDate,
  HeadSection,
  Index,
  IndexContainer,
  PageContainer,
  Today,
  UploadBtn,
  Wrapper,
} from "./Styles";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const initialDL = {
  CityDemandLevel: false,
  CityEvent: false,
};
export const Box = styled(BX)`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 9px 0px 9px 12px;
  margin-right: 16px;
`;
export const Text = styled(BX)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
  margin: auto;
  margin-left: 12px;
  padding-top: 2px;
  white-space: nowrap;
  width: 100%;
`;
export const ToggleBox = styled(BX)`
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;
  box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  color: #306fbc;
`;

export const TodayNew = styled(Today)`
  width: 124px;
  height: 40px;
  text-align: center;

  margin-right: 16px;
  background-color: #ffffff;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
  padding: 0px 12px 0px 12px;
`;

export const Container = styled(Button)`
  background: #306fbc;
  box-shadow: 0px 4px 4px rgba(3, 4, 94, 0.2);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  height: 40px;
  border: none;
`;

export const CopyButton = styled(Button)`
  color: #ffffff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
`;

export const ContainerNew = styled(Button)`
  background: #306fbc;
  box-shadow: 0px 4px 4px rgba(3, 4, 94, 0.2);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  height: 40px;
  border: none;
  '&:hover': {
    background: "red",
 },
`;
export default function ({ setPageHeader }) {
  const { permission, hotels, token, authFetch } = useAuth();
  const { hotelId } = useParams();
  const [haveData, setHaveData] = useState(true);
  const [Loading, setLoading] = useState(false);
  const [isSetModalOpen, setSetModalOpen] = useState(false);
  const [isCopyModalOpen, setCopyModalOpen] = useState(false);
  const [isIndexModalOpen, setIndexModalOpen] = useState(false);
  const [copyFromHotelId, setCopyFromHotelId] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [selectDL, setSelectDL] = useState([]);
  const [endDate, setEndDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  const [checkbox, setCheckbox] = useState(false);
  const [month, setMonth] = useState(0);
  const [isLeftDisabled, setIsLeftDisabled] = useState(false);
  const [isRightDisabled, setIsRightDisabled] = useState(false);
  const [perMonthEvents, setPerMonthsEvents] = useState([[]]);
  const [maxMonth, setmaxMonth] = useState(0);
  const [firstRender, setFirstRender] = useState(true);
  const [allHotels, setAllHotels] = useState();
  const [updateSelectedDL, setUpdateSelectedDL] = useState(initialDL);
  const [dLCheckbox, setDLCheckbox] = useState(false);
  const [isChangeDL, setIsChangeDl] = useState(false);

  const handleDLChange = (val) => {
    setSelectDL(val);
    const checkCityDL = val.some((value) => value === "CityDemandLevel");

    const checkCityEvent = val.some((value) => value === "CityEvent");
    setUpdateSelectedDL({
      CityDemandLevel: checkCityDL,
      CityEvent: checkCityEvent,
    });
    setDLCheckbox(checkCityEvent || checkCityDL ? true : false);
  };

  let eventsCalendarView = null,
    addEvent = null,
    addDemandLevel = null,
    addCityDemandLevel = null,
    eventCalendarCopy = null,
    isAdmin = false;
  const {
    currentHotelId,
    open,
    handleCloseStatus,
    networkMsg,
    baseDemandLevels,
    demandLevels,
    isInEditMode,
    toggleEdit,
    onChange,
    generateBatchBaseDemandLevels,
  } = useBaseDemandLevel(
    setCopyFromHotelId,
    setCheckbox,
    setIsChangeDl,
    isChangeDL,
    dLCheckbox,
    updateSelectedDL,
    selectDL,
    startDate,
    endDate,
    checkbox,
    copyFromHotelId,
    setCopyModalOpen,
    setHaveData,
    setLoading,
    setFirstRender
  );
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);

  useEffect(() => {
    if (token && hotelId) {
      getAllHotels();
      window.scrollTo(0, 0);
    } else {
      return;
    }
  }, [token, isAdmin, hotelId]);

  async function getAllHotels() {
    if (isAdmin) {
      const { get } = await authFetch({
        path: `/hotel`,
      });
      const { data, error } = await get();
      if (data) {
        setAllHotels(data);
      } else {
        console.log(error);
      }
    } else {
      setAllHotels(hotels);
    }
  }

  const toggleleft = () => {
    setMonth((prev) => {
      if (prev > 0) {
        setIsLeftDisabled(false);
        setIsRightDisabled(false);
        return prev - 1;
      } else {
        setIsLeftDisabled(true);
        setIsRightDisabled(false);
        return prev;
      }
    });
  };
  const toggleright = () => {
    setMonth((prev) => {
      if (prev < maxMonth) {
        setIsRightDisabled(false);
        setIsLeftDisabled(false);
        return prev + 1;
      } else {
        setIsRightDisabled(true);
        setIsLeftDisabled(false);
        return prev;
      }
    });
  };

  useEffect(() => {
    setPageHeader("Events Calendar");
    return () => {
      setPageHeader("");
    };
  }, []);

  const togglecurrent = () => {
    setFirstRender(true);
    setIsLeftDisabled(false);
    setIsRightDisabled(false);
  };

  const monthwiseEvents = (baseDemandLevels) => {
    var monthwiseEventArray = [];
    let refreshMonthIndex = 0;
    if (baseDemandLevels?.length) {
      setLoading(true);
      var currMonth = baseDemandLevels[0].month;
      var i = 0;

      monthwiseEventArray.push([]);

      baseDemandLevels.forEach((element) => {
        if (element.month === currMonth) {
          monthwiseEventArray[i].push(element);
          if (
            currMonth === new Date().getMonth() &&
            element.year === new Date().getFullYear()
          ) {
            refreshMonthIndex = i;
          }
        } else {
          currMonth = element.month;
          monthwiseEventArray.push([]);
          i++;
          monthwiseEventArray[i].push(element);
        }
      });
      setmaxMonth(i);
    }
    if (firstRender === true) {
      setMonth(refreshMonthIndex);
    }
    setFirstRender(false);
    setPerMonthsEvents(monthwiseEventArray);
    setLoading(false);
  };

  useEffect(() => {
    if (baseDemandLevels?.length) {
      monthwiseEvents(baseDemandLevels);
    }
  }, [baseDemandLevels, firstRender]);

  useEffect(() => {
    setShouldBlockNavigation(isInEditMode);
  }, [isInEditMode]);

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "eventsCalendarView") {
        eventsCalendarView = permission[key];
      }
      if (permission[key].name === "addEvent") {
        addEvent = permission[key];
      }
      if (permission[key].name === "addDemandLevel") {
        addDemandLevel = permission[key];
      }
      if (permission[key].name === "addCityDemandLevel") {
        addCityDemandLevel = permission[key];
      }
      if (permission[key].name === "eventCalendarCopy") {
        eventCalendarCopy = permission[key];
      }
      if (permission[key].name === "acrossHotel") {
        isAdmin = true;
      }
    }
  }

  useEffect(() => {
    console.log(perMonthEvents);
  }, [perMonthEvents]);

  return !Loading && eventsCalendarView ? (
    <Wrapper>
      <PageContainer>
        <Prompt
          when={shouldBlockNavigation}
          message="You have unsave changes, are you sure want to leave"
        />
        <HeadSection style={{ justifyContent: "flex-start" }}>
          {haveData ? (
            <DateSection>
              <DisplayDate>
                <Box>
                  <CalendarToday sx={{ color: "#306fbc" }} />

                  {perMonthEvents[month] && perMonthEvents[month][0] ? (
                    <Text>
                      {
                        months[
                          parseInt(
                            perMonthEvents[month][0].displayDate.split("/")[1]
                          ) - 1
                        ]
                      }
                      {"  "}
                      {perMonthEvents[month][0].displayDate.split("/")[2]}
                    </Text>
                  ) : (
                    ""
                  )}
                </Box>
              </DisplayDate>
              <ToggleBox>
                {!isLeftDisabled ? (
                  <ChevronLeft
                    onClick={toggleleft}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <ChevronLeft
                    style={{ cursor: "not-allowed" }}
                    color="disabled"
                  />
                )}
                {!isRightDisabled ? (
                  <ChevronRight
                    onClick={toggleright}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <ChevronRight
                    style={{ cursor: "not-allowed" }}
                    color="disabled"
                  />
                )}
              </ToggleBox>

              <TodayNew type="submit" onClick={() => togglecurrent()}>
                Current Month
              </TodayNew>
              <IndexContainer
                style={{
                  boxShadow: "0px 4px 4px rgb(48 111 188 / 20%)",
                  borderRadius: "8px",
                  height: "40px",
                  marginRight: "16px",
                }}
                onClick={() => setIndexModalOpen(true)}
              >
                <InfoOutlined
                  style={{ width: "20px", height: "20px", color: "#306fbc" }}
                />
                <Index>Index</Index>
              </IndexContainer>
            </DateSection>
          ) : (
            <DateSection></DateSection>
          )}
          <BtnContainer>
            {eventCalendarCopy && (
              <Container onClick={setCopyModalOpen}>
                <img
                  style={{
                    width: "19px",
                    height: "22px",
                    color: "#FFFFFF",
                    filter: "brightness(0) invert(1)",
                    cursor: "pointer",
                  }}
                  src="/assets/ContentCopyIcon.svg"
                  alt="copy icon "
                />
                <CopyButton>copy</CopyButton>
              </Container>
            )}
            {addEvent && (
              <UploadBtn
                style={{
                  marginLeft: "16px",
                  boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                  borderRadius: "8px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "Roboto",
                  fontstyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "16px",

                  color: "#000000",
                  padding: "0px 16px 0px 16px",
                }}
                onClick={setSetModalOpen}
              >
                Set Demand Levelsssssssssss
              </UploadBtn>
            )}
          </BtnContainer>
        </HeadSection>
        {haveData ? (
          <BaseDemandLevelCard
            baseDemandLevels={perMonthEvents[month]}
            demandLevels={demandLevels}
            isInEditMode={isInEditMode}
            toggleEdit={toggleEdit}
            onChange={onChange}
            setLoading={setLoading}
            setSetModalOpen={setSetModalOpen}
            generateBatchBaseDemandLevels={generateBatchBaseDemandLevels}
            addEvent={addEvent}
            addDemandLevel={addDemandLevel}
            addCityDemandLevel={addCityDemandLevel}
          />
        ) : (
          <Nodata></Nodata>
        )}

        <BatchDemandLevelModal
          isOpen={isSetModalOpen}
          demandLevels={demandLevels}
          onSave={generateBatchBaseDemandLevels}
          setSetModalOpen={setSetModalOpen}
          addDemandLevel={addDemandLevel}
          addCityDemandLevel={addCityDemandLevel}
        />
        <Modal
          open={isCopyModalOpen}
          onClose={() => {
            setCopyModalOpen(false);
          }}
          style={{ height: "300px" }}
        >
          <CopyFromHotelModal
            copyFromHotelId={copyFromHotelId}
            setCopyFromHotelId={setCopyFromHotelId}
            isOpen={isCopyModalOpen}
            setOpen={setCopyModalOpen}
            allHotels={allHotels}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setCheckbox={setCheckbox}
            selectDL={selectDL}
            handleDLChange={handleDLChange}
            dLCheckbox={dLCheckbox}
            setIsChangeDl={setIsChangeDl}
            currentHotelId={currentHotelId}
          />
        </Modal>
        <Modal
          open={isIndexModalOpen}
          onClose={() => {
            setIndexModalOpen(false);
          }}
        >
          <IndexModal isOpen={isIndexModalOpen} setOpen={setIndexModalOpen} />
        </Modal>
      </PageContainer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseStatus}
      >
        {networkMsg !== "Copied successfully" ? (
          <SnackbarContent
            style={{ backgroundColor: "#CA3433" }}
            message={networkMsg}
          />
        ) : (
          <SnackbarContent
            style={{ backgroundColor: "#228b22" }}
            message={networkMsg}
          />
        )}
      </Snackbar>
    </Wrapper>
  ) : !eventsCalendarView ? (
    <NoAccess />
  ) : (
    <LoadingPage />
  );
}
