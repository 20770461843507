import React from "react";
import { styled } from "@mui/system";
import { Row, Table as T } from "../../sdk";
import { Td, Th } from "../Styles.js";

const Table = styled(T)`
  background-color: white;
  width: 100%;
  margin: 0px;
`;

const Days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export default function LeadTimevsBoBTable({ selectedData }) {
  const competitors = selectedData.find((data) => data.compSet);
  let competitorDetails;
  if (competitors) {
    let { compSet } = competitors;
    competitorDetails = compSet;
  }
  return (
    <Table>
      <thead>
        <Row>
          <Th>Lead Time</Th>
          {Days.map((data, index) => (
            <Th key={index}>{data}</Th>
          ))}
        </Row>
      </thead>
      <tbody>
        {selectedData.map((data, idx) => (
          <Row
            key={idx}
            style={
              idx % 2 === 0 ? { backgroundColor: "rgba(48, 81, 221,0.09)" } : {}
            }
          >
            <Td style={{ textAlign: "left" }}>{data.LeadTime}</Td>
            <Td style={{ color: Math.round(data.mon) <= -1 ? "red" : "" }}>
              {data.mon || data.mon === 0 ? Math.round(data.mon) : "-"}
            </Td>
            <Td style={{ color: Math.round(data.tue) <= -1 ? "red" : "" }}>
              {data.tue || data.tue === 0 ? Math.round(data.tue) : "-"}
            </Td>
            <Td style={{ color: Math.round(data.wed) <= -1 ? "red" : "" }}>
              {data.wed || data.wed === 0 ? Math.round(data.wed) : "-"}
            </Td>
            <Td style={{ color: Math.round(data.thu) <= -1 ? "red" : "" }}>
              {data.thu || data.thu === 0 ? Math.round(data.thu) : "-"}
            </Td>
            <Td style={{ color: Math.round(data.fri) <= -1 ? "red" : "" }}>
              {data.fri || data.fri === 0 ? Math.round(data.fri) : "-"}
            </Td>
            <Td style={{ color: Math.round(data.sat) <= -1 ? "red" : "" }}>
              {data.sat || data.sat === 0 ? Math.round(data.sat) : "-"}
            </Td>
            <Td style={{ color: Math.round(data.sun) <= -1 ? "red" : "" }}>
              {data.sun || data.sun === 0 ? Math.round(data.sun) : "-"}
            </Td>
          </Row>
        ))}
      </tbody>
    </Table>
  );
}
