import { Box, styled } from "@mui/material";
import React from "react";
import { PrimarySetup } from "./PrimarySetup";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import Keywords from "../Keyword";
import AdminEvents from "../AdminEvents";
import { Colors } from "../Colors";
import { Redirect, Switch } from "react-router-dom/cjs/react-router-dom";

const Wrapper = styled(Box)``;
const SetupPage = styled(Box)`
  padding: 70px 0px 0px 16px;
  width: 99%;
  position: fixed;
`;
export default function EventSetup() {
  return (
    <SetupPage>
      <PrimarySetup />
      <Wrapper>
        <Switch>
          <Route exact path="/event-setup/keywords" component={Keywords} />
          <Route exact path="/event-setup/all-events" component={AdminEvents} />
          <Route exact path="/event-setup/event-colours" component={Colors} />
          <Redirect to="/event-setup/keywords" />
        </Switch>
      </Wrapper>
    </SetupPage>
  );
}
