import {
  Box,
  Select as SL,
  Table as TB,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { Card } from "../../sdk";
import { Schedule } from "@mui/icons-material";

export const Wrapper = styled(Box)`
  display: flex;
  margin: 0px;
  padding: 0px;
  overflow-y: hidden;
  height: calc(100vh - 132px);
`;

export const MonthCard = styled(Card)`
  width: 520px;
  padding: 20px;
  padding-top: 10px;
  height: 100%;
`;

export const MonthHeader = styled(Typography)`
  text-align: center;
  font: normal normal normal 20px/25px Roboto;
  letter-spacing: 0px;
  color: #000000;
  padding: 0px 0 8px 0;
`;
export const DATE = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90px;
  padding: 8px;
`;
export const DAY = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 64px;
  padding-left: 16px;
`;

export const TableCard = styled(Box)`
  display: flex;
  flex-wrap: no-wrap;
`;
export const PacePage = styled(Box)`
  padding: 80px 20px 0px 80px;
  width: 100%;
`;
export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 52px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  margin: auto;
`;
export const SubHeader = styled(Box)`
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 79px;
`;

export const PageTitle = styled(Typography)`
  font: normal normal bold 24px/30px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  position: fixed;
  top: 13px;
  left: 80px;
  color: white;
  z-index: 999;
`;

export const HeaderCard = styled(Box)`
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: #281e53;
`;

export const ScheduleIconDisabled = styled(Schedule)`
  &:hover {
    cursor: not-allowed;
  }
`;

export const Label = styled(Typography)`
  font: normal normal normal 16px/20px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  padding-right: 22px;
`;
export const ClassWrapper = styled(Box)(({ theme }) => ({
  ":-moz-focusring": {
    background: "White",
    color: "black",
  },
  ".MuiMenuItem": {
    ".root": {
      fontSize: 12,
    },
  },
  ".icon": {
    fill: "white",
    display: "none",
  },
  ".input": {
    font: "normal normal bold 16px / 20px Roboto",
    color: "#281E53",
    margin: 0,
  },
}));

export const Select = styled(SL)`
  .MuiSelect-root {
    color: #281e53;
    width: 56px;
    font: bold 16px/20px Roboto;
    height: 30px;
    padding-right: 88px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px;
    outline: none;
  }
  .MuiSelect-selectMenu {
    font: normal normal bold 16px/20px Roboto;
    letter-spacing: 0px;
    color: #281e53;
  }
`;

export const Options = styled(SL)`
  .MuiSelect-select.MuiSelect-select {
    padding-left: 8px;
  }
`;

export const Table = styled(TB)`
  margin: auto;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.25;
`;

export const Th = styled(TableCell)`
  &.MuiTableCell-head {
    vertical-align: bottom;
    font: normal normal bold 16px/20px Roboto;
    color: #130453;
    background-color: white;
    border: none;
    text-align: center;
    padding: 10px 0px;
  }
`;
export const Td = styled(TableCell)`
  &.MuiTableCell-body {
    font: normal normal normal 16px/20px Roboto;
    color: #333333;
    border: none;
    max-height: 10px;
    padding: 0 16px;
    text-align: center;
    max-width: 100px;
    width: 70px;
  }
`;

export const Tr = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "rgba(48, 81, 221,0.09)",
  },
}));

export const Thead = styled(TableHead)``;
export const Tbody = styled(TableBody)``;
