import { styled } from "@mui/system";
import { GhostButton, SecondaryButton } from "./../sdk";
import {
  Box,
  Button,
  Modal,
  Select as SL,
  TableContainer,
  TextField as TF,
  Typography,
} from "@mui/material";

export const TextField = styled(TF)`
  input {
    font: normal normal normal 14px/15px Roboto;
    letter-spacing: 0px;
    color: #000000;
    width: 400px;
  }
`;

export const Head = styled(Box)`
  position: fixed;
  top: 13px;
  left: 80px;
  color: white;
  z-index: 999;
`;

export const H = styled(Typography)`
  font: normal normal bold 24px/30px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
`;

export const HeadSection = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 84vw;
  padding-top: 16px;
  padding-bottom: 16px;
`;
export const DateSection = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const DisplayDate = styled(Box)`
  width: 175px;
  font: normal normal normal 20px/25px Roboto;
  letter-spacing: 0px;
  color: #110641;
`;
export const Toggle = styled(Box)`
  width: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 52px;
`;
export const Toggelleft = styled(Box)`
  &:hover {
    cursor: pointer;
  }

  color: #110641;
`;
export const ToggleRight = styled(Box)`
  &:hover {
    cursor: pointer;
  }
  color: #110641;
`;
export const NotAllowed = styled(Box)`
  &:hover {
    cursor: not-allowed;
  }
`;
export const Today = styled(Button)`
  width: 90px;
  height: 20px;
  text-align: center;
  font: normal normal normal 12px/15px Roboto;
  border: 1px solid #110641;
  margin-right: 48px;
  background-color: #ffffff;
  cursor: pointer;
`;

export const PageContainer = styled(Box)`
  width: 100%;

  overflow-y: scroll;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .head-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .page-title {
    font: normal normal normal 24px/30px Roboto;
    letter-spacing: 0px;
    color: #0d0d0d;
  }
`;

export const Wrapper = styled(Box)`
  padding: 70px 20px 20px 70px;
  position: absolute;
  width: 100%;
`;

export const TContainer = styled(TableContainer)`
  &.MuiPaper-elevation1 {
    box-shadow: none;
  }
`;

export const UploadBtn = styled(GhostButton)`
  text-align: center;
  font: Bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #343434;
  margin-left: 80px;
`;

export const CopyBtn = styled(SecondaryButton)`
  width: 80px;
  height: 24px;
  font-size: 14px;
  color:#FFFFFF,
  background:#306fbc;
`;

export const CardContainer = styled(Box)`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  width: max-content;
  height: calc(100% - 30px);
  margin-top: 18px;
  .header {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 24px;
    .top-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      button {
        margin-top: -7px;
      }
    }
    .bottom-row {
      margin-left: -9px;
      margin-top: -7px;
    }
    .title {
      font: normal normal 500 16px/20px Roboto;
      letter-spacing: 0px;
      color: #333333;
    }
    button {
      font: normal normal 12px/15px Roboto;
      letter-spacing: 0px;
      color: #838383;
    }
  }
`;

export const TextFieldSmall = styled(TF)`
  input.MuiInputBase-input {
    width: 30px;
    padding: 5px 0;
    font: normal normal normal 14px/15px Roboto;
    letter-spacing: 0px;
    color: #000000;
  }

  input {
    font: normal normal normal 14px/15px Roboto;
    letter-spacing: 0px;
    color: #000000;
  }
`;

export const HeaderRow = styled(Box)`
  display: flex;
  width: 600px;
  align-items: center;
  padding: 5px;
  margin: 0;
  text-transform: capitalize;
  .column {
    margin: 0;
    text-align: left;
    font: 500 16px/20px Roboto;
    letter-spacing: 0px;
    color: #333333;
    flex: 0.2 0.8 100px;
    &:last-child {
      flex: 0.8 0.2 100px;
    }
    &:nth-child(2) {
      text-align: center;
      padding: 5px 0px;
    }
    padding: 5px 20px;
  }
`;

export const TableRow = styled(Box)`
  padding: 5px;
  margin: 0;
  display: flex;
  .column {
    text-overflow: inherit;
    white-space: inherit;
    flex: 0.2 0.8 100px;
    margin: 0;
    text-align: left;
    font: normal normal normal 18px/22px Roboto;
    letter-spacing: 0px;
    color: #333333;
    &:last-child {
      flex: 0.8 0.2 100px;
    }
    &:nth-child(2) {
      text-align: center;
      padding: 5px 0px;
    }
    padding: 5px 20px;
  }
`;

export const TextFieldLarge = styled(TextFieldSmall)`
  &&&.MuiInputBase-root.MuiInput-root {
    width: 100%;
    &&&:disabled {
      cursor: not-allowed;
    }
  }
`;
export const TextFielddisabled = styled(TextFieldSmall)`
  &&&.MuiInputBase-root.MuiInput-root {
    width: 50px;
  }
`;

/**
 * Had to copy this over as styled are not written with sharing in mind
 * it had component based margin padding in the SDK declaration
 *
 */
export const SaveNEditBtn = styled(Button)`
  &&& {
    width: auto;
    border: none;
    background-color: transparent;
    color: #343434;
    padding: 7px 10px;
    box-shadow: none;
  }
  &:hover {
    border-radius: 2px;
    cursor: pointer;
  }
  background-color: ${(props) => (props.isInEditMode ? "#210D79" : "")};
  border-radius: ${(props) => (props.isInEditMode ? "4px" : "")};
  color: ${(props) => (props.isInEditMode ? "white" : "")};
  padding: ${(props) => (props.isInEditMode ? "2px 4px" : "")};
  margin: ${(props) => (props.isInEditMode ? "5px 0 0 10px" : "")};
  font-weight: ${(props) => (props.isInEditMode ? "bold" : "")};
  font-family: ${(props) => (props.isInEditMode ? "Roboto" : "")};
  outline: none;
`;

/**
 * Modal related styles
 */
export const PreciumModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    padding: 25px 40px;
    outline: 0;
    .header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .title {
        font: normal normal 600 16px/20px Roboto;
        letter-spacing: 0px;
        color: #333333;
      }
      button {
        color: #969696;
        font-size: 18px;
      }
    }
    .body {
      margin-top: 20px;
    }
    .footer {
      margin-top: 30px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const Select = styled(SL)`
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #231464;
  &&&:disabled {
    cursor: not-allowed;
  }
`;

export const DLTypography = styled(Typography)`
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #231464;
`;

export const Selectdisabled = styled(SL)`
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: grey;
`;

export const BtnContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export const IndexContainer = styled(GhostButton)`
  display: flex;
  align-items: center;
`;

export const Index = styled(Box)`
  text-align: left;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

export const DemandLevelContainer = styled(Box)`
  display: flex;
  align-items: center;
`;
