import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useWarning } from "../../Provider/context";
import { useAuth } from "../../sdk";

const nights = ["1 Night", "2 Nights", "3 Nights"];
const guests = ["1 Guest", "2 Guests"];
const week = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default function useScheduling() {
  const { authFetch, currentHotel } = useAuth();
  const { formEdit, setformEdit, handleRedirect } = useWarning();
  const [changedRateShops, setChangedRateShops] = useState([]);
  const { hotelId } = useParams();

  const [openSnackbar, setOpenSnackbar] = useState(null);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [competitors, setCompetitors] = useState([]);
  const [myError, setMyError] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [scheduleError, setScheduleError] = useState(false);
  const [rateshopError, setRateshopError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rateshopAlerts, setRateshopAlerts] = useState([]);
  const [allSources, setAllSources] = useState([]);
  const [SchedulingTime, setSchedulingTime] = useState("");
  const [confirmationDialog, setConfirmationDialog] = useState(null);
  const [rateshopName, setRateshopName] = useState([
    {
      rateShopId: "",
      name: "",
      occupancy: "",
      los: "",
      sources: [],
      days: 0,
    },
  ]);

  const [schedulingName, setSchedulingName] = useState([
    {
      scheduledId: "",
      rateShopId: "",
      rateshop: "",
      emails: [],
      hour: "",
      minute: "",
      dow: [],
    },
  ]);

  const getSources = useCallback(async () => {
    try {
      setIsLoading(true);
      const { get } = await authFetch({
        path: `/sources`,
      });
      const { data } = await get();
      if (data) {
        const finalSources = currentHotel?.sources
          .map((id) => {
            return data.find((item) => item.id === id && !item.isBrandWebsite);
          })
          .filter((item) => item !== undefined);
        setAllSources(finalSources);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [authFetch, currentHotel]);

  useEffect(() => {
    if (currentHotel === null) return;
    getSources();
  }, [currentHotel, getSources]);

  const getRateShopName = useCallback(async () => {
    try {
      setIsLoading(true);
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/rate-shop-scheduling`,
      });
      const { data } = await get();
      if (data && data.rateShops.length > 0) {
        const lastValues = data?.rateShops?.map((item) => ({
          ...item,
          los: `${item?.los} ${item?.los > 1 ? "Nights" : "Night"}`,
          occupancy: `${item?.occupancy} ${
            item?.occupancy > 1 ? "Guests" : "Guest"
          }`,
          sources: item?.sources?.map((item) =>
            allSources?.find((source) => source?.sourceId === item)
          ),
        }));

        setRateshopName(lastValues);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [allSources, authFetch, hotelId]);

  useEffect(() => {
    if (allSources?.length > 0) {
      getRateShopName();
    }
  }, [allSources.length, getRateShopName]);

  const getAlerts = useCallback(async () => {
    try {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/get-alert-list`,
      });
      const { data } = await get();
      if (data) {
        setRateshopAlerts(data);
      }
    } catch (err) {
      console.log(err);
    }
  }, [authFetch, hotelId]);

  useEffect(() => {
    getAlerts();
  }, [getAlerts]);

  const getCompetitors = useCallback(async () => {
    try {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/competitors`,
      });
      const { data } = await get();
      if (data) {
        const competitor = data?.filter((item) => item?.rateMetricHotelCode);
        setCompetitors(competitor);
      }
    } catch (err) {
      console.log(err);
    }
  }, [authFetch, hotelId]);

  useEffect(() => {
    getCompetitors();
  }, [getCompetitors]);

  const getScheduling = useCallback(async () => {
    try {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/rate-shop/schedule`,
      });
      const { data } = await get();
      if (data && data?.scheduledRateShops.length > 0) {
        const schedules = data?.scheduledRateShops?.map((item) => ({
          ...item,
          rateshop: item?.rateShopName,
          hour: `${String(item.hour).padStart(2, "0")}:00`,
          minute: `00:${String(item.minute).padStart(2, "0")}`,
          dow: item.dow,
        }));
        setSchedulingName(schedules);
      } else {
        setSchedulingName([
          {
            scheduledId: "",
            rateShopId: "",
            rateshop: "",
            emails: [],
            hour: "",
            minute: "",
            dow: [],
          },
        ]);
      }
    } catch (err) {
      console.log(err);
    }
  }, [authFetch, hotelId]);

  useEffect(() => {
    getScheduling();
  }, [getScheduling]);

  const handleChange = (event, changeField, index) => {
    setformEdit(true);
    const { value } = event.target;
    const updatedRateshopName = [...rateshopName];

    switch (changeField) {
      case "sources":
        updatedRateshopName[index].sources = allSources.filter((source) =>
          value.includes(source?.id)
        );
        break;
      case "los":
        updatedRateshopName[index].los = value;
        break;
      case "occupancy":
        updatedRateshopName[index].occupancy = value;
        break;
      case "name":
        setRateshopName((prev) => {
          const updatedRateshopName = [...prev];
          updatedRateshopName[index].name = event.target.value;
          return updatedRateshopName;
        });
        break;
      case "days":
        setRateshopName((prev) => {
          const updatedRateshopName = [...prev];
          updatedRateshopName[index].days = event.target.value;
          return updatedRateshopName;
        });
        break;
      default:
        break;
    }

    setRateshopName(updatedRateshopName);
    setChangedRateShops((prevChangedRateShops) => {
      if (!prevChangedRateShops.includes(index)) {
        return [...prevChangedRateShops, index];
      }
      return prevChangedRateShops;
    });
  };

  const handleSchedulingChange = (e, changeField, index) => {
    setformEdit(true);
    let value = e.target.value;
    switch (changeField) {
      case "rateshop":
        value = e.target.value.name;
        setSchedulingName((prev) => {
          const updatedSchedulingName = [...prev];
          updatedSchedulingName[index].rateshop = value;
          updatedSchedulingName[index].rateShopId = e.target.value.rateShopId;
          return updatedSchedulingName;
        });
        break;
      case "emails":
        const emailArray = value.split(",").map((email) => email.trim());
        setSchedulingName((prev) => {
          const updatedSchedulingName = [...prev];
          updatedSchedulingName[index].emails = emailArray;
          return updatedSchedulingName;
        });
        break;
      case "hour":
        setSchedulingName((prev) => {
          const updatedSchedulingName = [...prev];
          updatedSchedulingName[index].hour = value;
          return updatedSchedulingName;
        });
        break;
      case "minute":
        setSchedulingName((prev) => {
          const updatedSchedulingName = [...prev];
          updatedSchedulingName[index].minute = value;
          return updatedSchedulingName;
        });
        break;
      case "dow":
        setSchedulingName((prev) => {
          const updatedSchedulingName = [...prev];
          updatedSchedulingName[index].dow = value;
          return updatedSchedulingName;
        });

        break;
      default:
        break;
    }
  };
  const handlePriceAlertChange = (idx, field, value) => {
    setformEdit(true);

    const processedValue =
      field === "alterPrice"
        ? value === ""
          ? ""
          : isNaN(Number(value))
          ? 1
          : value < 1
          ? 1
          : Number(value)
        : value;

    setRateshopAlerts((prev) => {
      const updatedAlerts = prev?.competitorPriceAlerts?.map((alert, index) =>
        index === idx ? { ...alert, [field]: processedValue } : alert
      );
      return { ...prev, competitorPriceAlerts: updatedAlerts };
    });
  };

  const handleSoldOutAlertChange = (idx, field, value) => {
    setformEdit(true);
    setRateshopAlerts((prev) => {
      const updatedAlerts = prev.competitorSoldOutAlerts.map((alert, index) =>
        index === idx ? { ...alert, [field]: value } : alert
      );
      return { ...prev, competitorSoldOutAlerts: updatedAlerts };
    });
  };
  const handleContactAlertChange = (idx, field, value) => {
    setformEdit(true);
    setRateshopAlerts((prev) => {
      const updatedContacts = prev.contactForAlerts.map((contact, index) =>
        index === idx ? { ...contact, [field]: value } : contact
      );
      return { ...prev, contactForAlerts: updatedContacts };
    });
  };
  const checkIsValidRateshop = useCallback((values) => {
    if (
      values?.sources?.length > 0 &&
      values?.los &&
      values?.occupancy &&
      values?.days &&
      values?.name
    ) {
      return true;
    } else {
      return false;
    }
  }, []);
  const checkIsValidAlertsData = useCallback((data) => {
    // Check competitor price alerts
    const isValidCompetitorPriceAlerts =
      !data?.competitorPriceAlerts?.length ||
      data?.competitorPriceAlerts?.every(
        (alert) =>
          alert.competitorId !== "" &&
          alert.alterPrice !== undefined &&
          alert.alterPrice !== "" &&
          alert.priceAlertEnabled !== undefined
      );

    const isValidCompetitorSoldOutAlerts =
      !data?.competitorSoldOutAlerts?.length ||
      data?.competitorSoldOutAlerts?.every(
        (alert) =>
          alert.competitorId !== "" && alert.soldOutAlertEnabled !== undefined
      );

    const isValidContactForAlerts =
      !data?.contactForAlerts?.length ||
      data?.contactForAlerts?.every((contact) => contact.email !== "");

    return (
      isValidCompetitorPriceAlerts &&
      isValidCompetitorSoldOutAlerts &&
      isValidContactForAlerts
    );
  }, []);

  const handleSaveRateShop = useCallback(
    async (index) => {
      try {
        setMyError(true);
        setformEdit(false);
        setRateshopError(true);
        const rateshopID = rateshopName?.[index]?.rateShopId;
        const values = {
          name: rateshopName?.[index]?.name,
          days: parseInt(rateshopName?.[index]?.days, 10),
          los: parseInt(rateshopName?.[index]?.los?.[0], 10),
          occupancy: parseInt(rateshopName?.[index]?.occupancy?.[0], 10),
          sources: rateshopName?.[index]?.sources?.map(
            (item) => item?.sourceId
          ),
        };
        const isValid = await checkIsValidRateshop(values);
        if (!isValid) {
          setSnackBarMsg("Fields cant be empty");
          setOpenSnackbar(true);
          return;
        }
        const requestBody = values;

        if (!rateshopID) {
          const { post } = await authFetch({
            path: `/hotel/${hotelId}/rate-shop`,
          });

          const { response, data } = await post(requestBody);
          if (response.status === 200) {
            getRateShopName();
            setSnackBarMsg("Saved Successfully");
            setOpenSnackbar(true);
          } else {
            setSnackBarMsg(data?.messageToUser || "Something went wrong");
            setOpenSnackbar(true);
          }
        } else {
          const { put } = await authFetch({
            path: `/hotel/${hotelId}/rate-shop/${rateshopID}`,
          });
          const { response, data } = await put(requestBody);
          if (response.status === 204) {
            getRateShopName();

            setSnackBarMsg("Edited Successfully");
            setOpenSnackbar(true);
          } else {
            setSnackBarMsg(data?.messageToUser || "Something went wrong");
            setOpenSnackbar(true);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setRateshopError(false);
      }
    },
    [
      authFetch,
      checkIsValidRateshop,
      getRateShopName,
      hotelId,
      rateshopName,
      setformEdit,
    ]
  );
  const handleSaveAlert = useCallback(async () => {
    try {
      setFieldError(true);
      setformEdit(false);
      const cleanedData = {
        ...rateshopAlerts,
        competitorPriceAlerts: rateshopAlerts?.competitorPriceAlerts?.map(
          ({ isNew, ...rest }) => rest
        ),
        contactForAlerts: rateshopAlerts?.contactForAlerts?.map(
          ({ id, ...rest }) => rest
        ),
      };
      const isValid = await checkIsValidAlertsData(cleanedData);
      if (!isValid) {
        setSnackBarMsg("Fields cant be empty");
        setOpenSnackbar(true);
        return;
      }
      const requestBody = cleanedData;

      const { post } = await authFetch({
        path: `/hotel/${hotelId}/upsert-alert-info`,
      });

      const { response, data } = await post(requestBody);
      if (response.status === 200) {
        getAlerts();
        setSnackBarMsg("Saved Successfully");
        setOpenSnackbar(true);
      } else {
        setSnackBarMsg(data?.messageToUser || "Something went wrong");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error(error);
    }
    setFieldError(false);
  }, [
    authFetch,
    checkIsValidAlertsData,
    getAlerts,
    hotelId,
    rateshopAlerts,
    setformEdit,
  ]);

  const handleDeleteContact = useCallback(
    async (id) => {
      try {
        const { del } = await authFetch({
          path: `/hotel/${hotelId}/delete-alert-contact?alertContactId=${id}`,
        });

        const { data, response } = await del();
        if (response.status === 200) {
          getAlerts();
        } else {
          setSnackBarMsg(data?.messageToUser || "Something went wrong");
          setOpenSnackbar(true);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [authFetch, getAlerts, hotelId]
  );
  const handleDeletePriceAlert = useCallback(
    async (competitorId, alterPrice, alterPriceEnable) => {
      try {
        const { del } = await authFetch({
          path: `/hotel/${hotelId}/delete-alert-info`,
        });

        const { data, response } = await del({
          competitorId,
          alterPrice,
          alterPriceEnable,
        });
        if (response.status === 200) {
          getAlerts();
        } else {
          setSnackBarMsg(data?.messageToUser || "Something went wrong");
          setOpenSnackbar(true);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [authFetch, getAlerts, hotelId]
  );

  const checkIsValidScheduling = useCallback((values) => {
    if (
      values?.rateShopId &&
      values?.emails?.length > 0 &&
      values?.hour &&
      (values?.minute !== undefined || values?.minute !== null) &&
      values?.dow?.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }, []);

  const isValidEmail = useCallback((email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  }, []);

  const handleSaveScheduling = useCallback(
    async (index) => {
      try {
        setScheduleError(true);
        setformEdit(false);
        const { post } = await authFetch({
          path: `/hotel/${hotelId}/rate-shop/schedule`,
        });

        const hourString = schedulingName?.[index]?.hour;
        const minuteString = schedulingName?.[index]?.minute;
        const hours = parseInt(hourString.split(":")[0]);
        const minutes = parseInt(minuteString.split(":")[1]);

        const values = {
          rateShopId: schedulingName?.[index]?.rateShopId,
          rateShopName: schedulingName?.[index]?.rateshop,
          emails: schedulingName?.[index]?.emails,
          hour: hours,
          minute: minutes,
          dow: schedulingName?.[index]?.dow,
        };

        const isValidEmails = values.emails.every((email) =>
          isValidEmail(email)
        );

        if (!isValidEmails) {
          setSnackBarMsg("Invalid email address");
          setOpenSnackbar(true);
          return;
        }

        const isValid = await checkIsValidScheduling(values);

        if (!isValid) {
          setSnackBarMsg("Fields can't be empty");
          setOpenSnackbar(true);
          return;
        }

        const requestBody = values;
        const { response, data } = await post(requestBody);
        if (response.status === 200) {
          getScheduling();
          setSnackBarMsg("Saved Successfully");
          setOpenSnackbar(true);
        } else {
          setSnackBarMsg(data?.messageToUser || "Something went wrong");
          setOpenSnackbar(true);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setScheduleError(false);
      }
    },
    [
      setformEdit,
      authFetch,
      hotelId,
      schedulingName,
      checkIsValidScheduling,
      isValidEmail,
      getScheduling,
    ]
  );

  const handleDeleteScheduling = useCallback(
    async (id) => {
      try {
        const { del } = await authFetch({
          path: `/hotel/${hotelId}/rate-shop/schedule/${id}`,
        });

        const { response } = await del();
        if (response.status === 200) {
          getScheduling();
        }
      } catch (err) {
        console.log(err);
      }
      setConfirmationDialog(null);
    },
    [authFetch, getScheduling, hotelId]
  );

  const handleAddRateShop = useCallback(async () => {
    setRateshopName((prev) => [
      ...prev,
      {
        name: "",
        occupancy: "",
        los: "",
        sources: [],
        days: 0,
      },
    ]);
  }, []);

  const handleAddPriceAlert = useCallback(() => {
    setRateshopAlerts((prev) => ({
      ...prev,
      competitorPriceAlerts: [
        ...(prev?.competitorPriceAlerts || []),
        {
          competitorId: "",
          alterPrice: 1,
          priceAlertEnabled: false,
          isNew: true,
        },
      ],
    }));
  }, [setRateshopAlerts]);
  const handleRemoveNewPriceAlert = useCallback(
    (index) => {
      setRateshopAlerts((prev) => ({
        ...prev,
        competitorPriceAlerts: prev.competitorPriceAlerts.filter(
          (val, i) => i !== index
        ),
      }));
    },
    [setRateshopAlerts]
  );
  const handleAddSoldOutAlert = useCallback(() => {
    setRateshopAlerts((prev) => ({
      ...prev,
      competitorSoldOutAlerts: [
        ...(prev?.competitorSoldOutAlerts || []),
        {
          competitorId: "",
          soldOutAlertEnabled: false,
        },
      ],
    }));
  }, [setRateshopAlerts]);
  const handleAddContactForAlert = useCallback(() => {
    setRateshopAlerts((prev) => ({
      ...prev,
      contactForAlerts: [
        ...(prev?.contactForAlerts || []),
        {
          name: "",
          email: "",
          contactNumber: "",
          countryCode: "",
        },
      ],
    }));
  }, [setRateshopAlerts]);

  const handleAddScheduling = useCallback(async () => {
    setSchedulingName((prev) => [
      ...prev,
      {
        rateshop: "",
        emails: [],
        hour: "",
        minute: "",
        dow: [],
      },
    ]);
  }, []);

  return {
    hotelId,
    formEdit,
    allSources,
    handleChange,
    nights,
    guests,
    handleRedirect,
    handleSaveRateShop,
    handleSaveScheduling,
    SchedulingTime,
    setSchedulingTime,
    openSnackbar,
    setOpenSnackbar,
    snackBarMsg,
    setSnackBarMsg,
    myError,
    setMyError,
    scheduleError,
    setScheduleError,
    rateshopError,
    setRateshopError,
    rateshopName,
    setRateshopName,
    handleAddRateShop,
    schedulingName,
    setSchedulingName,
    handleAddScheduling,
    handleSchedulingChange,
    handleDeleteScheduling,
    isLoading,
    week,
    rateshopAlerts,
    competitors,
    handleAddContactForAlert,
    handleAddPriceAlert,
    handleAddSoldOutAlert,
    handlePriceAlertChange,
    handleContactAlertChange,
    handleSoldOutAlertChange,
    handleSaveAlert,
    fieldError,
    handleDeleteContact,
    handleDeletePriceAlert,
    handleRemoveNewPriceAlert,
    setConfirmationDialog,
    confirmationDialog,
    changedRateShops,
  };
}
