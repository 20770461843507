import React, { useCallback, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import { addDays, format } from "date-fns";
import { useParams } from "react-router-dom";
import { LoadingPage, useAuth } from "../../sdk";

export default function RateshopLogs() {
  const { hotelId } = useParams();
  const { authFetch } = useAuth();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [sourcesData, setSourcesData] = useState([]);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0)

  const getSources = useCallback(async () => {
    setIsLoading(true);
    const { get } = await authFetch({
      path: `/sources`,
    });
    const { data, error } = await get();
    if (data) {
      setSourcesData(data);
    } else {
      console.log(error);
    }
    setIsLoading(false);
  }, [authFetch]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getRateShopLogs = useCallback(async () => {
    setIsLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/rate-shop-hotel-logs?page=${
        page
      }&limit=${rowsPerPage}`,
    });
    const { data, error } = await get();
    if (data) {
      setData(data?.rateShopLogDetails ?? []);
      setCount(data?.totalCount)
    } else {
      console.log(error);
    }
    setIsLoading(false);
  }, [authFetch, hotelId, page, rowsPerPage]);

  useEffect(() => {
    getSources();
  }, [getSources]);

  useEffect(() => {
    getRateShopLogs();
  }, [getRateShopLogs]);

  const searchTime = useCallback((createdAt, successAt) => {
    const startTime = format(new Date(createdAt), "dd-MM-yyyy(HH:mm)");
    const successTime = successAt
      ? format(new Date(successAt), "dd-MM-yyyy (HH:mm)")
      : "In progress";
    return `${startTime}-${successTime}`;
  }, []);

  const sourceName = useCallback(
    (sources) => {
      const names = sources.map((sourceId) => {
        const source = sourcesData?.find((item) => item?.sourceId === sourceId);
        return source?.name;
      });
      return names.map((item) => <Typography>{item}</Typography>);
    },
    [sourcesData]
  );

  const losAndGuests = useCallback((requestString, cell) => {
    const requestObject = JSON.parse(requestString);
    console.log(requestObject);
    if (cell === "los") {
      return requestObject?.los;
    } else {
      return requestObject?.occupancy;
    }
  }, []);

  const compititors = useCallback((requestString) => {
    const requestObject = JSON.parse(requestString);
    return requestObject?.hotelcodes?.length > 1 ? "Yes" : "No";
  }, []);

  const rangeDate = useCallback((createdAt, requestString) => {
    const horizonexpression = JSON.parse(requestString).horizonexpression;
    const [startRange, endRange] = horizonexpression.split("-");
    const initialDate = addDays(new Date(createdAt), startRange);
    const finalDate = addDays(new Date(createdAt), endRange);
    const startDate = format(initialDate, "dd-MM-yyyy");
    const endDate = format(finalDate, "dd-MM-yyyy");
    return `${startDate}-${endDate}`;
  }, []);

  return isLoading ? (
    <LoadingPage />
  ) : (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Queue Id</StyledTableCell>
              <StyledTableCell>Search Time</StyledTableCell>
              <StyledTableCell>OTA</StyledTableCell>
              <StyledTableCell>LOS</StyledTableCell>
              <StyledTableCell>Guest</StyledTableCell>
              <StyledTableCell>Requested By</StyledTableCell>
              <StyledTableCell>Date Range</StyledTableCell>
              <StyledTableCell>Competitors</StyledTableCell>
              <StyledTableCell>RateShop Type</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{item?.queue_id}</StyledTableCell>
                <StyledTableCell>
                  {searchTime(item?.createdAt, item?.successAt)}
                </StyledTableCell>
                <StyledTableCell>{sourceName(item?.Sources)}</StyledTableCell>
                <StyledTableCell>
                  {losAndGuests(item?.requestString, "los")}
                </StyledTableCell>
                <StyledTableCell>
                  {losAndGuests(item?.requestString, "guests")}
                </StyledTableCell>
                <StyledTableCell>
                  {item?.createdBy && item?.email
                    ? `${item?.createdBy}\n(${item?.email})`
                    : "--"}
                </StyledTableCell>

                <StyledTableCell>
                  {rangeDate(item?.createdAt, item?.requestString)}
                </StyledTableCell>
                <StyledTableCell>
                  {compititors(item?.requestString)}
                </StyledTableCell>
                <StyledTableCell>
                  {item?.isScheduled ? "Scheduled" : "On demand"}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={({ from, to }) => null}
        nextIconButtonProps={{
          disabled: data.length < rowsPerPage,
        }}
      />
    </>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#130453",
    textAlign: "center",
    font: "normal normal bold 16px/20px Roboto",
    padding: "16px",
    letterSpacing: "0px",
    width: "11%",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "center",
    width: "11%",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
