import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CalendarToday } from "@mui/icons-material";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { styled } from "@mui/system";
import {
  ChooseBtn,
  Cross,
  CrossSmall,
  DateSection,
  Done,
  Filename,
  Label,
  ModelHead,
  Section,
  Title,
  UploadSection,
} from "./Styles";

function getModalStyle() {
  const top = 9;
  const right = 18;

  return {
    top: `${top}%`,
    right: `${right}%`,
    transform: `translate(-${top}, -${right})`,
  };
}

export const CalenderIcon = styled(CalendarToday)`
  position: absolute;
  width: 20px;
  height: 22px;
  color: #306fbc;
  z-index: 101;
  margin-left: -32px;
  margin-top: 4px;
  cursor: pointer;
`;

const ClassWrapper = styled(Box)(({ theme }) => ({
  ".paper": {
    position: "absolute",
    width: "370px",
    height: "300px",
    backgroundColor: "#fff",
    boxShadow: 5,
  },
}));

export function ModelBody({
  fileHandler,
  setUploadActualDate,
  uploadActualDate,
  fileGetter,
  setOpenSelectBar,
  removeFile,
  filename,
  toggle,
  setDateToggle,
  dateToggle,
}) {
  const [modalStyle] = useState(getModalStyle);
  const defaultValue = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  const maximumDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  const [selectedDay, setSelectedDay] = useState(defaultValue);

  useEffect(() => {
    if (selectedDay) {
      setUploadActualDate(
        new Date(
          [
            selectedDay?.year,
            selectedDay?.month,
            selectedDay?.day.length === 1
              ? "0" + selectedDay?.day
              : selectedDay?.day,
          ].join("-")
        )
      );
    }
  }, [selectedDay]);
  const value =
    `${selectedDay.day < 10 ? "0" + selectedDay.day : selectedDay.day}` +
    "/" +
    `${selectedDay.month < 10 ? "0" + selectedDay.month : selectedDay.month}` +
    "/" +
    `${selectedDay.year}`;

  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={value}
      style={{
        textAlign: "left",
        fontSize: "14px",
        borderRadius: "10px",
        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        maxWidth: "140px",
        paddingLeft: "14px",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
      }}
      className="my-custom-input-class"
    />
  );

  const StyledCalenderWrapper = styled(Box)((theme) => ({
    borderRadius: "8px",
    boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
    "&:hover>*": {
      color: "#ffffff",
      ">input": {
        backgroundColor: "#306fbc",
        color: "#ffffff !important",
      },
    },
  }));

  return (
    <ClassWrapper>
      <Section style={modalStyle} className="paper">
        <ModelHead>
          <Title>Upload Forecast</Title>
          <Cross onClick={() => setOpenSelectBar(false)}>&times;</Cross>
        </ModelHead>
        <DateSection>
          <Label htmlFor="picker" style={{ marginLeft: "40px" }}>
            Date
          </Label>
          <label>
            <StyledCalenderWrapper>
              <DatePicker
                id="picker"
                value={selectedDay}
                onChange={setSelectedDay}
                maximumDate={maximumDate}
                calendarClassName="myCustomCalendar"
                renderInput={renderCustomInput}
              />
              <CalenderIcon />
            </StyledCalenderWrapper>
          </label>
        </DateSection>
        <ChooseBtn
          style={toggle ? { display: "none" } : { display: "block" }}
          htmlFor="upload"
        >
          Choose File
          <input
            type="file"
            id="upload"
            onChange={(e) => fileGetter(e.target.files)}
            style={{ display: "none" }}
          />
        </ChooseBtn>

        <UploadSection
          style={toggle ? { display: "flex" } : { display: "none" }}
        >
          <img
            src="/assets/excel.svg"
            alt="Excel Icon"
            width="36px"
            height="36px"
          />
          <Filename>{filename}</Filename>
          <CrossSmall
            onClick={() => removeFile()}
            style={toggle ? { display: "block" } : { display: "none" }}
          >
            &times;
          </CrossSmall>
        </UploadSection>
        <Done
          disabled={!toggle}
          next
          onClick={fileHandler}
          type="button"
          style={{
            background: "#306fbc 0% 0% no-repeat padding-box",
            color: " #ffffff",
            cursor: "pointer",
          }}
        >
          Upload
        </Done>
      </Section>
    </ClassWrapper>
  );
}
