import {
  Input as Inpt,
  MenuItem,
  Select,
  Snackbar,
  SnackbarContent,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Tooltip,
  Box,
} from "@mui/material";
import { ArrowDropDownCircle, FilterList } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Menu,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { styled } from "@mui/system";
import { Trow } from "../../PricingRecomendation/Styles";
import { getDateDDMM, getDay, LoadingPage, MonthPicker } from "../../sdk";
import useForecastSheet from "../hooks/useForecastSheetDaily";

export const TRow = styled(TableRow)`
  &.MuiTableRow-root {
    padding: 10px 0;
    height: 56px;
    &:nth-of-type(odd) {
      background: #f1f2fe;
    }
  }
`;
export const TCell = styled(TableCell)`
  &.MuiTableCell-root {
    font: normal normal normal 14px/28px Roboto;
    letter-spacing: 0px;
    color: #000;
    padding: 0px 0px;
    border: none;
    text-align: ${(props) => (props.isInEditMode ? "left" : "left")};
    text-transform: capitalize;
    white-space: nowrap;
  }
`;
export const Tabhead = styled(TableCell)`
  &.MuiTableCell-root {
    text-align: left;
    font: normal normal bold 14px/23px Roboto;
    letter-spacing: 0px;
    color: #000;
    padding: 0px 0px;
    border: none;
    text-transform: capitalize;
    background: rgba(48, 111, 188, 1);
  }
`;
export const THead = styled(TableHead)`
  &.MuiTableHead-root {
    tr.MuiTableRow-root {
      background: transparent;
      width: 100%;
    }
  }
`;
const DateContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 56px;
  border: none;
`;
const Container = styled(Box)`
  padding: 80px 20px 0px 80px;
  width: 100%;
`;
const Header = styled(Box)`
  font: normal normal bold 14px/18px Roboto;
  font-weight: 400;
  padding: 1px 5px;
  height: 20px;
  border-bottom: 0.1px solid #1106411a;
  display: flex;
  min-width: 90px;
  display: flex;
  align-items: center;
  justify-content: start;
  svg.MuiSelect-icon {
    color: white;
  }
`;
const Cell = styled(Box)`
  font: normal normal normal 14px/20px Roboto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-bottom: 1.5px solid #1106411a;
  width: 82px;
  text-align: center;
`;
const MonthPickerContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal bold 22px/28px Roboto;
`;
export const AppBar = styled(Box)`
  top: 76px;
  left: 80px;
  height: 52px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  margin-bottom: 20px;
  padding: 0 80px;
  display: flex;
  align-items: center;
`;

export const TableWrapper = styled(Box)`
  height: calc(100vh - 180px);
  margin-bottom: 10px;
  overflow: auto;
  background-color: #ffffff;
`;

export const HorizontalLine = styled(Box)`
  background-color: #110641;
  width: 100%;
`;

export const PickupContainer = styled(Box)`
  display: flex;
  align-items: center;
  outline: 1px solid #1106411a;
`;
export const PickupName = styled(Box)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  white-space: wrap;
  font: normal normal bold 12px/20px Roboto;
`;
export const HeaderContainer = styled(Box)`
  flex: 1;
  outline: 1px solid #1106411a;
`;
const Input = styled(Inpt)`
  input.MuiInputBase-input {
    padding: 0;
    font: 410 16px/20px Roboto;
    letter-spacing: 0px;
    color: #000;
    text-align: center;
    font: normal normal normal 15px/20px Roboto;

    border: none;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &&&.MuiInput-underline::before {
    border-bottom: none;
  }
  &&&.MuiInput-underline::after {
    border-bottom: none;
  }
`;
export const Options = styled(Select)`
  .MuiSelect-select.MuiSelect-select {
    font: normal normal bold 12px/18px Roboto;
    min-width: 180px;
    text-align: center;
    color: white;
  }
`;
export const TextOnlyTooltip = styled(Tooltip)(({ theme }) => ({
  ".tooltip": {
    font: "normal normal normal 12px/16px Roboto",
  },
}));

const TextOnlyTooltipSegment = styled(Tooltip)(({ theme }) => ({
  ".tooltip": {
    color: "#fff",
    font: "normal normal normal 16px/20px Roboto",
    boxShadow: "0px 4px 8px #00000035",
    background: "#110641 0% 0% no-repeat padding-box",
    textAlign: "left",
    marginLeft: "-10px",
    marginTop: "-10px",
  },
  ".arrow": {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "#110641",
  },
}));

const typesOfPickup = [
  {
    name: "Select PickUp",
    value: 0,
  },
  {
    name: "Pickup : 1",
    value: 1,
  },
  {
    name: "Pickup : 2",
    value: 2,
  },
  {
    name: "Pickup : 3",
    value: 3,
  },
  {
    name: "Pickup : 4",
    value: 4,
  },
  {
    name: "Pickup : 5",
    value: 5,
  },
  {
    name: "Pickup : 6",
    value: 6,
  },
  {
    name: "Pickup : 7",
    value: 7,
  },
  {
    name: "Pickup : 8",
    value: 8,
  },
  {
    name: "Pickup : 9",
    value: 9,
  },
  {
    name: "Pickup : 10",
    value: 10,
  },
];
const StyledContainer = styled(Container)(() => ({
  ".sticky": {
    position: "sticky",
    left: 0,
    background: "#f1f2fe",
    zIndex: "8",
  },
  ".stickyRight": {
    position: "sticky",
    left: "53.5px",
    background: "#f1f2fe",
    zIndex: "8",
  },
}));

export const ForecastSheetDaily = ({ setPageHeader }) => {
  const {
    forecastSheetData,
    comparitiveDate,
    setComparitiveDate,
    handleActualChange,
    handleRevenueChange,
    showPickupRequireToAchieve,
    handleShowPickupRequiredRow,
    postForecastData,
    selectedPickup,
    Loading,
    setPickup,
    competitorData,
    dates,
    setOpen,
    networkMsg,
    open,
    handleFilterDialogBox,
    filterDialogBox,
    handleFilter,
    filterArray,
    filterAll,
  } = useForecastSheet();
  useEffect(() => {
    setPageHeader("Forecast Sheet Daily");
    return () => {
      setPageHeader("");
    };
  }, [setPageHeader]);

  return (
    <StyledContainer>
      <Stack direction={"row"} justifyContent="space-between">
        <Stack direction={"row"} spacing={4}>
          <Paper
            elevation={1}
            style={{
              width: "200px",
              height: "40px",
              marginLeft: "40px",
              marginBottom: "16px",
            }}
          >
            <Stack
              direction="row"
              style={{
                padding: "5px",
              }}
            >
              <MonthPickerContainer>
                <MonthPicker
                  value={comparitiveDate}
                  onChange={setComparitiveDate}
                />
              </MonthPickerContainer>
            </Stack>
          </Paper>
          <Paper
            elevation={1}
            style={{
              marginBottom: "16px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              id="account-menu"
              onClick={() => {
                handleFilterDialogBox();
              }}
            >
              <Stack direction={"row"} spacing={2}>
                <FilterList />
                <Typography>Filters</Typography>
              </Stack>
            </Button>
            <Menu
              id="account-menu"
              open={filterDialogBox}
              onClose={handleFilterDialogBox}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "left", vertical: "top" }}
              style={{
                left: "300px",
                top: "100px",
              }}
            >
              <MenuItem>
                <FormGroup>
                  <Stack
                    direction={"Column"}
                    spacing={2}
                    justifyContent="center"
                    alignItems="start"
                  >
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Last Year"
                      style={{ marginLeft: "0" }}
                      name="Last Year"
                      checked={filterArray[0]}
                      onChange={(e) => {
                        handleFilter(e.target.checked, e.target.name);
                      }}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Budget"
                      name="Budget"
                      checked={filterArray[1]}
                      onChange={(e) => {
                        handleFilter(e.target.checked, e.target.name);
                      }}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Locked Forecast"
                      name="Locked Forecast"
                      checked={filterArray[2]}
                      onChange={(e) => {
                        handleFilter(e.target.checked, e.target.name);
                      }}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="BOB"
                      name="BOB"
                      checked={filterArray[3]}
                      onChange={(e) => {
                        handleFilter(e.target.checked, e.target.name);
                      }}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Actual/Forecast"
                      name="Actual"
                      checked={filterArray[4]}
                      onChange={(e) => {
                        handleFilter(e.target.checked, e.target.name);
                      }}
                    />
                  </Stack>
                </FormGroup>
              </MenuItem>
            </Menu>
          </Paper>
          <Stack direction={"row"}>
            <Button
              label="All"
              onClick={() => {
                handleFilter(true, "All");
              }}
              size="small"
              style={{
                width: "100px",
                height: "45px",
                background: filterAll ? "rgb(48,111,188)" : "#fff",
                color: filterAll ? "#fff" : "rgb(48,111,188)",
                font: "normal normal bold 14px/15px Roboto",
                fontWeight: "600",
              }}
            >
              All
            </Button>
          </Stack>
        </Stack>

        <Button
          type="submit"
          style={{
            height: "45px",
            backgroundColor: "rgb(48,111,188)",
            font: "normal normal bold 14px/15px Roboto",
            color: "white",
          }}
          onClick={postForecastData}
        >
          Save
        </Button>
      </Stack>

      {!Loading ? (
        <>
          <TableWrapper>
            <Table stickyHeader>
              <THead>
                <TRow>
                  <Tabhead
                    style={{
                      padding: "4px 8px",
                      zIndex: "10",
                      visibility: forecastSheetData ? "visible" : "hidden",
                      background: "rgba(48, 111, 188, 1)",
                      color: "#ffffff",
                      borderRight: "1.5px solid rgba(48, 111, 188, 1)",
                    }}
                    rowSpan={2}
                    className="sticky"
                  >
                    Items
                  </Tabhead>
                  <Tabhead
                    style={{
                      padding: "4px 8px",
                      zIndex: "10",
                      visibility: forecastSheetData ? "visible" : "hidden",
                      textAlign: "center",
                      background: "rgba(48, 111, 188, 1)",
                      color: "#ffffff",
                    }}
                    rowSpan={2}
                    className="stickyRight"
                  >
                    KPI
                    <br /> (Key Performance Indicators)
                  </Tabhead>

                  {dates?.map((date, index) => {
                    return (
                      <Tabhead key={index}>
                        <DateContainer>
                          <TCell
                            style={{
                              font: "normal normal 14px/28px Roboto",
                              textAlign: "center",
                              width: "80px",
                              border: "none",
                              background: "rgba(48, 111, 188, 1)",
                              color: "#ffffff",
                            }}
                          >
                            {getDateDDMM(date)}
                            <br />
                            {getDay(date)}
                          </TCell>
                        </DateContainer>
                      </Tabhead>
                    );
                  })}
                  {forecastSheetData && (
                    <Tabhead
                      style={{
                        textAlign: "center",
                        color: "#ffffff",
                      }}
                    >
                      Total
                    </Tabhead>
                  )}
                </TRow>
              </THead>
              <TableBody>
                {forecastSheetData?.map((item, segmentIndex) => {
                  return (
                    <React.Fragment key={segmentIndex}>
                      <TRow key={segmentIndex}>
                        <TextOnlyTooltipSegment
                          disableFocusListener
                          disableTouchListener
                          title={item.description ? item.description : ""}
                          placement="right"
                          arrow
                          style={{ cursor: "default" }}
                        >
                          <TCell
                            style={{
                              padding: "0 10px",
                              font: "normal normal bold 14px/23px Roboto",
                              background:
                                item.systemName !== "Total"
                                  ? "#fff"
                                  : "#306fbc",
                              color:
                                item.systemName !== "Total" ? "#000" : "#fff",
                            }}
                            className="sticky"
                          >
                            <p
                              style={{
                                writingMode: "tb-rl",
                                transform: "rotate(-180deg)",
                                margin: "auto",
                                cursor: "default",
                              }}
                            >
                              {item.systemName}
                            </p>
                          </TCell>
                        </TextOnlyTooltipSegment>
                        <TCell className="stickyRight">
                          {filterAll === true ? (
                            <Header
                              style={{
                                background:
                                  item.systemName !== "Total"
                                    ? "#fff"
                                    : "#306fbc",
                                color:
                                  item.systemName !== "Total" ? "#000" : "#fff",
                                height: "30px",
                                borderRight: "1.5px solid #1106411a",
                                borderLeft: "1.5px solid #1106411a",
                                borderBottom: "1.5px solid #1106411a",
                              }}
                            >
                              Room Nights LY
                            </Header>
                          ) : (
                            filterArray[0] === true && (
                              <Header
                                style={{
                                  background:
                                    item.systemName !== "Total"
                                      ? "#fff"
                                      : "#306fbc",
                                  color:
                                    item.systemName !== "Total"
                                      ? "#000"
                                      : "#fff",
                                  height: "30px",
                                  borderRight: "1.5px solid #1106411a",
                                  borderLeft: "1.5px solid #1106411a",
                                  borderBottom: "1.5px solid #1106411a",
                                }}
                              >
                                Room Nights LY
                              </Header>
                            )
                          )}

                          {filterAll === true ? (
                            <Header
                              style={{
                                background:
                                  item.systemName !== "Total"
                                    ? "#fff"
                                    : "#306fbc",
                                color:
                                  item.systemName !== "Total" ? "#000" : "#fff",
                                height: "30px",
                                borderRight: "1.5px solid #1106411a",
                                borderLeft: "1.5px solid #1106411a",
                                borderBottom: "1.5px solid #1106411a",
                              }}
                            >
                              Room Nights Budget
                            </Header>
                          ) : (
                            filterArray[1] === true && (
                              <Header
                                style={{
                                  background:
                                    item.systemName !== "Total"
                                      ? "#fff"
                                      : "#306fbc",
                                  color:
                                    item.systemName !== "Total"
                                      ? "#000"
                                      : "#fff",
                                  height: "30px",
                                  borderRight: "1.5px solid #1106411a",
                                  borderLeft: "1.5px solid #1106411a",
                                  borderBottom: "1.5px solid #1106411a",
                                }}
                              >
                                Room Nights Budget
                              </Header>
                            )
                          )}
                          {filterAll === true ? (
                            <Header
                              style={{
                                background:
                                  item.systemName !== "Total"
                                    ? "#fff"
                                    : "#306fbc",
                                color:
                                  item.systemName !== "Total" ? "#000" : "#fff",
                                height: "30px",
                                borderRight: "1.5px solid #1106411a",
                                borderLeft: "1.5px solid #1106411a",
                                borderBottom: "1.5px solid #1106411a",
                              }}
                            >
                              Room Nights Locked Forecast
                            </Header>
                          ) : (
                            filterArray[2] === true && (
                              <Header
                                style={{
                                  background:
                                    item.systemName !== "Total"
                                      ? "#fff"
                                      : "#306fbc",
                                  color:
                                    item.systemName !== "Total"
                                      ? "#000"
                                      : "#fff",
                                  height: "30px",
                                  borderRight: "1.5px solid #1106411a",
                                  borderLeft: "1.5px solid #1106411a",
                                  borderBottom: "1.5px solid #1106411a",
                                }}
                              >
                                Room Nights Locked Forecast
                              </Header>
                            )
                          )}

                          {filterAll === true ? (
                            <Header
                              style={{
                                background:
                                  item.systemName !== "Total"
                                    ? "#fff"
                                    : "#306fbc",
                                color:
                                  item.systemName !== "Total" ? "#000" : "#fff",
                                height: "30px",
                                borderRight: "1.5px solid #1106411a",
                                borderLeft: "1.5px solid #1106411a",
                                borderBottom: "1.5px solid #1106411a",
                              }}
                            >
                              Room Nights BOB
                            </Header>
                          ) : (
                            filterArray[3] === true && (
                              <Header
                                style={{
                                  background:
                                    item.systemName !== "Total"
                                      ? "#fff"
                                      : "#306fbc",
                                  color:
                                    item.systemName !== "Total"
                                      ? "#000"
                                      : "#fff",
                                  height: "30px",
                                  borderRight: "1.5px solid #1106411a",
                                  borderLeft: "1.5px solid #1106411a",
                                  borderBottom: "1.5px solid #1106411a",
                                }}
                              >
                                Room Nights BOB
                              </Header>
                            )
                          )}
                          {filterAll === true ? (
                            <Header
                              style={{
                                background:
                                  item.systemName !== "Total"
                                    ? "#fff"
                                    : "#306fbc",
                                color:
                                  item.systemName !== "Total" ? "#000" : "#fff",
                                height: "30px",
                                borderRight: "1.5px solid #1106411a",
                                borderLeft: "1.5px solid #1106411a",
                                borderBottom: "1.5px solid #1106411a",
                              }}
                            >
                              Room Nights Actual / Forecast{" "}
                            </Header>
                          ) : (
                            filterArray[4] === true && (
                              <Header
                                style={{
                                  background:
                                    item.systemName !== "Total"
                                      ? "#fff"
                                      : "#306fbc",
                                  color:
                                    item.systemName !== "Total"
                                      ? "#000"
                                      : "#fff",
                                  height: "30px",
                                  borderRight: "1.5px solid #1106411a",
                                  borderLeft: "1.5px solid #1106411a",
                                  borderBottom: "1.5px solid #1106411a",
                                }}
                              >
                                Room Nights Actual / Forecast{" "}
                              </Header>
                            )
                          )}

                          {item.systemName !== "Total" && (
                            <Header
                              style={{
                                background:
                                  "rgba(48, 111, 188, 1) 0% 0% no-repeat padding-box",
                                color: "white",
                                height: "30px",
                                borderRight: "1.5px solid #1106411a",
                                borderLeft: "1.5px solid #1106411a",
                                borderBottom: "1.5px solid #1106411a",
                              }}
                            >
                              <Options
                                disableUnderline
                                defaultValue={0}
                                variant="standard"
                                value={
                                  (selectedPickup[segmentIndex] &&
                                    selectedPickup[segmentIndex][0]) ??
                                  1
                                }
                                onChange={(e) =>
                                  setPickup(segmentIndex, 0, e.target.value)
                                }
                                style={{
                                  fontFamily: "Roboto",
                                  marginLeft: "63px",
                                }}
                              >
                                {typesOfPickup.map((pickup, index) => (
                                  <MenuItem
                                    style={{
                                      fontFamily: "Roboto",
                                      height: "30px",
                                    }}
                                    value={pickup.value}
                                    key={index}
                                  >
                                    {pickup.name}
                                  </MenuItem>
                                ))}
                              </Options>
                            </Header>
                          )}

                          {filterAll === true ? (
                            <Header
                              style={{
                                background:
                                  item.systemName !== "Total"
                                    ? "#fff"
                                    : "#306fbc",
                                color:
                                  item.systemName !== "Total" ? "#000" : "#fff",
                                height: "30px",
                                borderRight: "1.5px solid #1106411a",
                                borderLeft: "1.5px solid #1106411a",
                                borderBottom: "1.5px solid #1106411a",
                              }}
                            >
                              ADR LY
                            </Header>
                          ) : (
                            filterArray[0] === true && (
                              <Header
                                style={{
                                  background:
                                    item.systemName !== "Total"
                                      ? "#fff"
                                      : "#306fbc",
                                  color:
                                    item.systemName !== "Total"
                                      ? "#000"
                                      : "#fff",
                                  height: "30px",
                                  borderRight: "1.5px solid #1106411a",
                                  borderLeft: "1.5px solid #1106411a",
                                  borderBottom: "1.5px solid #1106411a",
                                }}
                              >
                                ADR LY
                              </Header>
                            )
                          )}

                          {filterAll === true ? (
                            <Header
                              style={{
                                background:
                                  item.systemName !== "Total"
                                    ? "#fff"
                                    : "#306fbc",
                                color:
                                  item.systemName !== "Total" ? "#000" : "#fff",
                                height: "30px",
                                borderRight: "1.5px solid #1106411a",
                                borderLeft: "1.5px solid #1106411a",
                                borderBottom: "1.5px solid #1106411a",
                              }}
                            >
                              ADR Budget
                            </Header>
                          ) : (
                            filterArray[1] === true && (
                              <Header
                                style={{
                                  background:
                                    item.systemName !== "Total"
                                      ? "#fff"
                                      : "#306fbc",
                                  color:
                                    item.systemName !== "Total"
                                      ? "#000"
                                      : "#fff",
                                  height: "30px",
                                  borderRight: "1.5px solid #1106411a",
                                  borderLeft: "1.5px solid #1106411a",
                                  borderBottom: "1.5px solid #1106411a",
                                }}
                              >
                                ADR Budget
                              </Header>
                            )
                          )}

                          {filterAll === true ? (
                            <Header
                              style={{
                                background:
                                  item.systemName !== "Total"
                                    ? "#fff"
                                    : "#306fbc",
                                color:
                                  item.systemName !== "Total" ? "#000" : "#fff",
                                height: "30px",
                                borderRight: "1.5px solid #1106411a",
                                borderLeft: "1.5px solid #1106411a",
                                borderBottom: "1.5px solid #1106411a",
                              }}
                            >
                              ADR Locked Forecast
                            </Header>
                          ) : (
                            filterArray[2] === true && (
                              <Header
                                style={{
                                  background:
                                    item.systemName !== "Total"
                                      ? "#fff"
                                      : "#306fbc",
                                  color:
                                    item.systemName !== "Total"
                                      ? "#000"
                                      : "#fff",
                                  height: "30px",
                                  borderRight: "1.5px solid #1106411a",
                                  borderLeft: "1.5px solid #1106411a",
                                  borderBottom: "1.5px solid #1106411a",
                                }}
                              >
                                ADR Locked Forecast
                              </Header>
                            )
                          )}

                          {filterAll === true ? (
                            <Header
                              style={{
                                background:
                                  item.systemName !== "Total"
                                    ? "#fff"
                                    : "#306fbc",
                                color:
                                  item.systemName !== "Total" ? "#000" : "#fff",
                                height: "30px",
                                borderRight: "1.5px solid #1106411a",
                                borderLeft: "1.5px solid #1106411a",
                                borderBottom: "1.5px solid #1106411a",
                              }}
                            >
                              ADR BOB
                            </Header>
                          ) : (
                            filterArray[3] === true && (
                              <Header
                                style={{
                                  background:
                                    item.systemName !== "Total"
                                      ? "#fff"
                                      : "#306fbc",
                                  color:
                                    item.systemName !== "Total"
                                      ? "#000"
                                      : "#fff",
                                  height: "30px",
                                  borderRight: "1.5px solid #1106411a",
                                  borderLeft: "1.5px solid #1106411a",
                                  borderBottom: "1.5px solid #1106411a",
                                }}
                              >
                                ADR BOB
                              </Header>
                            )
                          )}

                          {filterAll === true ? (
                            <Header
                              style={{
                                background:
                                  item.systemName !== "Total"
                                    ? "#fff"
                                    : "#306fbc",
                                color:
                                  item.systemName !== "Total" ? "#000" : "#fff",
                                height: "30px",
                                borderRight: "1.5px solid #1106411a",
                                borderLeft: "1.5px solid #1106411a",
                                borderBottom: "1.5px solid #1106411a",
                              }}
                            >
                              ADR Actual / Forecast
                            </Header>
                          ) : (
                            filterArray[4] === true && (
                              <Header
                                style={{
                                  background:
                                    item.systemName !== "Total"
                                      ? "#fff"
                                      : "#306fbc",
                                  color:
                                    item.systemName !== "Total"
                                      ? "#000"
                                      : "#fff",
                                  height: "30px",
                                  borderRight: "1.5px solid #1106411a",
                                  borderLeft: "1.5px solid #1106411a",
                                  borderBottom: "1.5px solid #1106411a",
                                }}
                              >
                                ADR Actual / Forecast
                              </Header>
                            )
                          )}

                          {item.systemName !== "Total" && (
                            <Header
                              style={{
                                background:
                                  "rgba(48, 111, 188, 1) 0% 0% no-repeat padding-box",
                                color: "white",
                                height: "30px",
                                borderRight: "1.5px solid #1106411a",
                                borderLeft: "1.5px solid #1106411a",
                                borderBottom: "1.5px solid #1106411a",
                              }}
                            >
                              <Options
                                disableUnderline
                                defaultValue={0}
                                variant="standard"
                                value={
                                  (selectedPickup[segmentIndex] &&
                                    selectedPickup[segmentIndex][1]) ??
                                  1
                                }
                                onChange={(e) =>
                                  setPickup(segmentIndex, 1, e.target.value)
                                }
                                style={{
                                  fontFamily: "Roboto",
                                  marginLeft: "63px",
                                }}
                              >
                                {typesOfPickup.map((pickup, index) => (
                                  <MenuItem
                                    style={{
                                      fontFamily: "Roboto",
                                      height: "30px",
                                    }}
                                    value={pickup.value}
                                    key={index}
                                  >
                                    {pickup.name}
                                  </MenuItem>
                                ))}
                              </Options>
                            </Header>
                          )}

                          {filterAll === true ? (
                            <Header
                              style={{
                                background:
                                  item.systemName !== "Total"
                                    ? "#fff"
                                    : "#306fbc",
                                color:
                                  item.systemName !== "Total" ? "#000" : "#fff",
                                height: "30px",
                                borderRight: "1.5px solid #1106411a",
                                borderLeft: "1.5px solid #1106411a",
                                borderBottom: "1.5px solid #1106411a",
                              }}
                            >
                              Room Revenue LY
                            </Header>
                          ) : (
                            filterArray[0] === true && (
                              <Header
                                style={{
                                  background:
                                    item.systemName !== "Total"
                                      ? "#fff"
                                      : "#306fbc",
                                  color:
                                    item.systemName !== "Total"
                                      ? "#000"
                                      : "#fff",
                                  height: "30px",
                                  borderRight: "1.5px solid #1106411a",
                                  borderLeft: "1.5px solid #1106411a",
                                  borderBottom: "1.5px solid #1106411a",
                                }}
                              >
                                Room Revenue LY
                              </Header>
                            )
                          )}
                          {filterAll === true ? (
                            <Header
                              style={{
                                background:
                                  item.systemName !== "Total"
                                    ? "#fff"
                                    : "#306fbc",
                                color:
                                  item.systemName !== "Total" ? "#000" : "#fff",
                                height: "30px",
                                borderRight: "1.5px solid #1106411a",
                                borderLeft: "1.5px solid #1106411a",
                                borderBottom: "1.5px solid #1106411a",
                              }}
                            >
                              Room Revenue Budget
                            </Header>
                          ) : (
                            filterArray[1] === true && (
                              <Header
                                style={{
                                  background:
                                    item.systemName !== "Total"
                                      ? "#fff"
                                      : "#306fbc",
                                  color:
                                    item.systemName !== "Total"
                                      ? "#000"
                                      : "#fff",
                                  height: "30px",
                                  borderRight: "1.5px solid #1106411a",
                                  borderLeft: "1.5px solid #1106411a",
                                  borderBottom: "1.5px solid #1106411a",
                                }}
                              >
                                Room Revenue Budget
                              </Header>
                            )
                          )}

                          {filterAll === true ? (
                            <Header
                              style={{
                                background:
                                  item.systemName !== "Total"
                                    ? "#fff"
                                    : "#306fbc",
                                color:
                                  item.systemName !== "Total" ? "#000" : "#fff",
                                height: "30px",
                                borderRight: "1.5px solid #1106411a",
                                borderLeft: "1.5px solid #1106411a",
                                borderBottom: "1.5px solid #1106411a",
                              }}
                            >
                              Room Revenue Locked Forecast
                            </Header>
                          ) : (
                            filterArray[2] === true && (
                              <Header
                                style={{
                                  background:
                                    item.systemName !== "Total"
                                      ? "#fff"
                                      : "#306fbc",
                                  color:
                                    item.systemName !== "Total"
                                      ? "#000"
                                      : "#fff",
                                  height: "30px",
                                  borderRight: "1.5px solid #1106411a",
                                  borderLeft: "1.5px solid #1106411a",
                                  borderBottom: "1.5px solid #1106411a",
                                }}
                              >
                                Room Revenue Locked Forecast
                              </Header>
                            )
                          )}

                          {filterAll === true ? (
                            <Header
                              style={{
                                background:
                                  item.systemName !== "Total"
                                    ? "#fff"
                                    : "#306fbc",
                                color:
                                  item.systemName !== "Total" ? "#000" : "#fff",
                                height: "30px",
                                borderRight: "1.5px solid #1106411a",
                                borderLeft: "1.5px solid #1106411a",
                                borderBottom: "1.5px solid #1106411a",
                              }}
                            >
                              Room Revenue BOB
                            </Header>
                          ) : (
                            filterArray[3] === true && (
                              <Header
                                style={{
                                  background:
                                    item.systemName !== "Total"
                                      ? "#fff"
                                      : "#306fbc",
                                  color:
                                    item.systemName !== "Total"
                                      ? "#000"
                                      : "#fff",
                                  height: "30px",
                                  borderRight: "1.5px solid #1106411a",
                                  borderLeft: "1.5px solid #1106411a",
                                  borderBottom: "1.5px solid #1106411a",
                                }}
                              >
                                Room Revenue BOB
                              </Header>
                            )
                          )}

                          {filterAll === true ? (
                            <Header
                              style={{
                                background:
                                  item.systemName !== "Total"
                                    ? "#fff"
                                    : "#306fbc",
                                color:
                                  item.systemName !== "Total" ? "#000" : "#fff",
                                height: "30px",
                                borderRight: "1.5px solid #1106411a",
                                borderLeft: "1.5px solid #1106411a",
                                borderBottom: "1.5px solid #1106411a",
                              }}
                            >
                              Room Revenue Actual / Forecast{" "}
                            </Header>
                          ) : (
                            filterArray[4] === true && (
                              <Header
                                style={{
                                  background:
                                    item.systemName !== "Total"
                                      ? "#fff"
                                      : "#306fbc",
                                  color:
                                    item.systemName !== "Total"
                                      ? "#000"
                                      : "#fff",
                                  height: "30px",
                                  borderRight: "1.5px solid #1106411a",
                                  borderLeft: "1.5px solid #1106411a",
                                  borderBottom: "1.5px solid #1106411a",
                                }}
                              >
                                Room Revenue Actual / Forecast{" "}
                              </Header>
                            )
                          )}

                          {item.systemName !== "Total" && (
                            <Header
                              style={{
                                background:
                                  "rgba(48, 111, 188, 1) 0% 0% no-repeat padding-box",
                                color: "white",
                                height: "30px",
                                borderRight: "1.5px solid #1106411a",
                                borderLeft: "1.5px solid #1106411a",
                                borderBottom: "1.5px solid #1106411a",
                              }}
                            >
                              <Options
                                disableUnderline
                                defaultValue={0}
                                variant="standard"
                                value={
                                  (selectedPickup[segmentIndex] &&
                                    selectedPickup[segmentIndex][2]) ??
                                  1
                                }
                                onChange={(e) =>
                                  setPickup(segmentIndex, 2, e.target.value)
                                }
                                style={{
                                  fontFamily: "Roboto",
                                  marginLeft: "63px",
                                }}
                              >
                                {typesOfPickup.map((pickup, index) => (
                                  <MenuItem
                                    style={{ fontFamily: "Roboto" }}
                                    value={pickup.value}
                                    key={index}
                                  >
                                    {pickup.name}
                                  </MenuItem>
                                ))}
                              </Options>
                            </Header>
                          )}
                        </TCell>
                        {item.budgetModel.map((budget, index) => {
                          return (
                            <TCell
                              key={index}
                              style={{
                                background:
                                  item.systemName !== "Total"
                                    ? "#fff"
                                    : "#306fbc",
                                color:
                                  item.systemName !== "Total" ? "#000" : "#fff",
                                borderLeft: "1px solid #e7e6ec",
                              }}
                            >
                              {filterAll === true ? (
                                <TransFormInt
                                  data={budget.roomsLY}
                                  style={{
                                    background: "#fff",
                                    height: "30px",
                                    width: "82px",
                                  }}
                                />
                              ) : (
                                filterArray[0] === true && (
                                  <TransFormInt
                                    data={budget.roomsLY}
                                    style={{
                                      background: "#fff",
                                      height: "30px",
                                      width: "82px",
                                    }}
                                  />
                                )
                              )}

                              {/* adding input field in Room Night Budget. */}

                              {filterAll === true ? (
                                item?.systemName !== "Total" ? (
                                  <Cell
                                    style={{
                                      background: "#fff",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Input
                                      value={
                                        budget.roomsBudget
                                          ? budget.roomsBudget
                                          : ""
                                      }
                                      min="0"
                                      type="number"
                                      onChange={(e) => {
                                        if (e.target.value >= 0) {
                                          handleRevenueChange(
                                            index,
                                            segmentIndex,
                                            "roomsBudget",
                                            parseInt(e.target.value)
                                          );
                                        }
                                      }}
                                      style={{ fontSize: "14px" }}
                                    />
                                  </Cell>
                                ) : (
                                  <TransFormInt
                                    style={{ background: "#fff" }}
                                    data={budget?.roomsBudget}
                                  />
                                )
                              ) : (
                                filterArray[1] === true &&
                                (item?.systemName !== "Total" ? (
                                  <Cell
                                    style={{
                                      background: "#fff",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Input
                                      value={
                                        budget.roomsBudget
                                          ? budget.roomsBudget
                                          : ""
                                      }
                                      min="0"
                                      type="number"
                                      onChange={(e) => {
                                        if (e.target.value >= 0) {
                                          handleRevenueChange(
                                            index,
                                            segmentIndex,
                                            "roomsBudget",
                                            parseInt(e.target.value)
                                          );
                                        }
                                      }}
                                      style={{ fontSize: "14px" }}
                                    />
                                  </Cell>
                                ) : (
                                  <TransFormInt
                                    style={{ background: "#fff" }}
                                    data={budget?.roomsBudget}
                                  />
                                ))
                              )}

                              {filterAll === true ? (
                                <TransFormInt data={budget.roomsLocked} />
                              ) : (
                                filterArray[2] === true && (
                                  <TransFormInt data={budget.roomsLocked} />
                                )
                              )}

                              {filterAll === true ? (
                                <TransFormInt data={budget.roomsOTB} />
                              ) : (
                                filterArray[3] === true && (
                                  <TransFormInt data={budget.roomsOTB} />
                                )
                              )}

                              {filterAll === true ? (
                                item.systemName !== "Total" ? (
                                  <Cell
                                    style={{
                                      background: "#fff",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Input
                                      defaultValue={
                                        budget.roomsActual
                                          ? budget.roomsActual
                                          : ""
                                      }
                                      min="0"
                                      type="number"
                                      onChange={(e) => {
                                        if (e.target.value >= 0) {
                                          console.log(e.target.value);
                                          handleActualChange(
                                            index,
                                            segmentIndex,
                                            "roomsActual",
                                            parseInt(e.target.value)
                                          );
                                        }
                                      }}
                                      style={{ fontSize: "14px" }}
                                    />
                                  </Cell>
                                ) : (
                                  <TransFormInt
                                    style={{ background: "#fff" }}
                                    data={budget.roomsActual}
                                  />
                                )
                              ) : (
                                filterArray[4] === true &&
                                (item.systemName !== "Total" ? (
                                  <Cell
                                    style={{
                                      background: "#fff",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Input
                                      defaultValue={
                                        budget.roomsActual
                                          ? budget.roomsActual
                                          : ""
                                      }
                                      min="0"
                                      type="number"
                                      onChange={(e) => {
                                        if (e.target.value >= 0) {
                                          console.log(e.target.value);
                                          handleActualChange(
                                            index,
                                            segmentIndex,
                                            "roomsActual",
                                            parseInt(e.target.value)
                                          );
                                        }
                                      }}
                                      style={{ fontSize: "14px" }}
                                    />
                                  </Cell>
                                ) : (
                                  <TransFormInt
                                    style={{ background: "#fff" }}
                                    data={budget.roomsActual}
                                  />
                                ))
                              )}

                              {item.systemName !== "Total" && (
                                <Cell style={{ background: "#fff" }}>{}</Cell>
                              )}

                              {filterAll === true ? (
                                <TransForm
                                  style={{ background: "rgba(48,111,188,0.2)" }}
                                  data={budget.arrLY}
                                />
                              ) : (
                                filterArray[0] === true && (
                                  <TransForm
                                    style={{
                                      background: "rgba(48,111,188,0.2)",
                                    }}
                                    data={budget.arrLY}
                                  />
                                )
                              )}

                              {filterAll === true ? (
                                item.systemName !== "Total" ? (
                                  <Cell
                                    style={{
                                      background: "rgba(48,111,188,0.2)",
                                      fontWeight: "bold",
                                      borderRight: "solid 1px rgb(231,230,236)",
                                    }}
                                  >
                                    {
                                      <Input
                                        value={
                                          budget.arrBudget
                                            ? budget.arrBudget
                                            : ""
                                        }
                                        type="number"
                                        onChange={(e) => {
                                          handleRevenueChange(
                                            index,
                                            segmentIndex,
                                            "arrBudget",
                                            parseInt(e.target.value)
                                          );
                                        }}
                                      />
                                    }
                                  </Cell>
                                ) : (
                                  <TransForm
                                    style={{
                                      background: "rgba(48,111,188,0.2)",
                                    }}
                                    data={budget.arrBudget}
                                  />
                                )
                              ) : (
                                filterArray[1] === true &&
                                (item.systemName !== "Total" ? (
                                  <Cell
                                    style={{
                                      background: "rgba(48,111,188,0.2)",
                                      fontWeight: "bold",
                                      borderRight: "solid 1px rgb(231,230,236)",
                                    }}
                                  >
                                    {
                                      <Input
                                        value={
                                          budget.arrBudget
                                            ? budget.arrBudget
                                            : ""
                                        }
                                        type="number"
                                        onChange={(e) => {
                                          handleRevenueChange(
                                            index,
                                            segmentIndex,
                                            "arrBudget",
                                            parseInt(e.target.value)
                                          );
                                        }}
                                      />
                                    }
                                  </Cell>
                                ) : (
                                  <TransForm
                                    style={{
                                      background: "rgba(48,111,188,0.2)",
                                    }}
                                    data={budget.arrBudget}
                                  />
                                ))
                              )}

                              {filterAll === true ? (
                                <TransForm
                                  style={{ background: "rgba(48,111,188,0.2)" }}
                                  data={budget.arrLocked}
                                />
                              ) : (
                                filterArray[2] === true && (
                                  <TransForm
                                    style={{
                                      background: "rgba(48,111,188,0.2)",
                                    }}
                                    data={budget.arrLocked}
                                  />
                                )
                              )}

                              {filterAll === true ? (
                                <TransForm
                                  style={{ background: "rgba(48,111,188,0.2)" }}
                                  data={budget.arrOTB}
                                />
                              ) : (
                                filterArray[3] === true && (
                                  <TransForm
                                    style={{
                                      background: "rgba(48,111,188,0.2)",
                                    }}
                                    data={budget.arrOTB}
                                  />
                                )
                              )}

                              {filterAll === true ? (
                                item.systemName !== "Total" ? (
                                  <Cell
                                    style={{
                                      background: "rgba(48,111,188,0.2)",
                                      fontWeight: "bold",
                                      borderRight: "solid 1px rgb(231,230,236)",
                                    }}
                                  >
                                    {
                                      <Input
                                        defaultValue={
                                          budget.arrActual
                                            ? budget.arrActual
                                            : ""
                                        }
                                        type="number"
                                        onChange={(e) =>
                                          handleActualChange(
                                            index,
                                            segmentIndex,
                                            "arrActual",
                                            parseInt(e.target.value)
                                          )
                                        }
                                      />
                                    }
                                  </Cell>
                                ) : (
                                  <TransForm
                                    style={{
                                      background: "rgba(48,111,188,0.2)",
                                    }}
                                    data={budget.arrActual}
                                  />
                                )
                              ) : (
                                filterArray[4] === true &&
                                (item.systemName !== "Total" ? (
                                  <Cell
                                    style={{
                                      background: "rgba(48,111,188,0.2)",
                                      fontWeight: "bold",
                                      borderRight: "solid 1px rgb(231,230,236)",
                                    }}
                                  >
                                    {
                                      <Input
                                        defaultValue={
                                          budget.arrActual
                                            ? budget.arrActual
                                            : ""
                                        }
                                        type="number"
                                        onChange={(e) =>
                                          handleActualChange(
                                            index,
                                            segmentIndex,
                                            "arrActual",
                                            parseInt(e.target.value)
                                          )
                                        }
                                      />
                                    }
                                  </Cell>
                                ) : (
                                  <TransForm
                                    style={{
                                      background: "rgba(48,111,188,0.2)",
                                    }}
                                    data={budget.arrActual}
                                  />
                                ))
                              )}

                              {item.systemName !== "Total" && (
                                <Cell style={{ background: "#fff" }}>{""}</Cell>
                              )}

                              {filterAll === true ? (
                                <TransFormInt data={budget.revenueLY} />
                              ) : (
                                filterArray[0] === true && (
                                  <TransFormInt data={budget.revenueLY} />
                                )
                              )}

                              {filterAll === true ? (
                                <TransFormInt data={budget.revenueBudget} />
                              ) : (
                                filterArray[1] === true && (
                                  <TransFormInt data={budget.revenueBudget} />
                                )
                              )}

                              {filterAll === true ? (
                                <TransFormInt data={budget.revenueLocked} />
                              ) : (
                                filterArray[2] === true && (
                                  <TransFormInt data={budget.revenueLocked} />
                                )
                              )}

                              {filterAll === true ? (
                                <TransFormInt data={budget.revenueOTB} />
                              ) : (
                                filterArray[3] === true && (
                                  <TransFormInt data={budget.revenueOTB} />
                                )
                              )}

                              {filterAll === true ? (
                                item.systemName !== "Total" ? (
                                  <Cell>
                                    {budget.roomsActual && budget.arrActual
                                      ? Math.round(
                                          (budget.roomsActual *
                                            budget.arrActual +
                                            Number.EPSILON) *
                                            100
                                        ) / 100
                                      : budget.revenueActual
                                      ? budget.revenueActual
                                      : ""}
                                  </Cell>
                                ) : (
                                  <TransFormInt data={budget.revenueActual} />
                                )
                              ) : (
                                filterArray[4] === true &&
                                (item.systemName !== "Total" ? (
                                  <Cell>
                                    {budget.roomsActual && budget.arrActual
                                      ? Math.round(
                                          (budget.roomsActual *
                                            budget.arrActual +
                                            Number.EPSILON) *
                                            100
                                        ) / 100
                                      : budget.revenueActual
                                      ? budget.revenueActual
                                      : ""}
                                  </Cell>
                                ) : (
                                  <TransFormInt data={budget.revenueActual} />
                                ))
                              )}

                              {item.systemName !== "Total" && (
                                <Cell style={{ background: "#fff" }}>{}</Cell>
                              )}
                            </TCell>
                          );
                        })}
                      </TRow>
                      {item.systemName !== "Total" && (
                        <TRow
                          style={{
                            background: "rgba(48, 111, 188, 1)",
                            height: "8px",
                          }}
                        >
                          <TCell
                            style={{
                              zIndex: "9",
                              background: "rgba(48, 111, 188, 1)",
                            }}
                            className="sticky"
                          >
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                position: "relative",
                                background: "white",
                              }}
                            >
                              <ArrowDropDownCircle
                                style={{
                                  fontSize: "22px",
                                  position: "absolute",
                                  cursor: "pointer",
                                  background: "white",
                                  color: "rgba(48, 111, 188, 1)",
                                  left: "0",
                                  zIndex: "1",
                                  transform: showPickupRequireToAchieve[
                                    segmentIndex
                                  ]
                                    ? "rotate(180deg)"
                                    : "none",
                                }}
                                onClick={() =>
                                  handleShowPickupRequiredRow(segmentIndex)
                                }
                              />
                            </span>
                          </TCell>
                          <TCell
                            style={{ background: "rgba(48, 111, 188, 1)" }}
                            colSpan="100%"
                          ></TCell>
                        </TRow>
                      )}
                      {showPickupRequireToAchieve[segmentIndex] && (
                        <>
                          <TRow
                            key={segmentIndex}
                            style={{
                              background: "white",
                            }}
                          >
                            <TCell
                              style={{
                                padding: "0 10px",
                                font: "normal normal bold 14px/23px Roboto",
                                background: "#ffffff",
                              }}
                              className="sticky"
                            >
                              <p
                                style={{
                                  writingMode: "tb-rl",
                                  transform: "rotate(-180deg)",
                                  margin: "auto",
                                }}
                              >
                                Pick up Required to Achieve
                              </p>
                            </TCell>
                            <TCell
                              className="stickyRight"
                              style={{
                                background: "rgba(241, 246, 252, 1)",
                              }}
                            >
                              <PickupContainer
                                style={{ display: "flex", height: "108px" }}
                              >
                                <PickupName>LY</PickupName>
                                <HeaderContainer style={{ height: "108px" }}>
                                  <Header style={{ height: "36px" }}>
                                    Room Nights
                                  </Header>
                                  <Header style={{ height: "36px" }}>
                                    ADR
                                  </Header>
                                  <Header style={{ height: "36px" }}>
                                    Room Revenue
                                  </Header>
                                </HeaderContainer>
                              </PickupContainer>
                              <PickupContainer
                                style={{ display: "flex", height: "108px" }}
                              >
                                <PickupName style={{ height: "108px" }}>
                                  Budget
                                </PickupName>
                                <HeaderContainer>
                                  <Header style={{ height: "36px" }}>
                                    Room Nights
                                  </Header>
                                  <Header style={{ height: "36px" }}>
                                    ADR
                                  </Header>
                                  <Header style={{ height: "36px" }}>
                                    Room Revenue
                                  </Header>
                                </HeaderContainer>
                              </PickupContainer>
                              <PickupContainer
                                style={{ display: "flex", height: "108px" }}
                              >
                                <PickupName style={{ height: "108px" }}>
                                  Locked <br /> Forecast
                                </PickupName>
                                <HeaderContainer>
                                  <Header style={{ height: "36px" }}>
                                    Room Nights
                                  </Header>
                                  <Header style={{ height: "36px" }}>
                                    ADR
                                  </Header>
                                  <Header style={{ height: "36px" }}>
                                    Room Revenue
                                  </Header>
                                </HeaderContainer>
                              </PickupContainer>
                              <PickupContainer
                                style={{ display: "flex", height: "108px" }}
                              >
                                <PickupName style={{ height: "108px" }}>
                                  Current <br /> Forecast
                                </PickupName>
                                <HeaderContainer>
                                  <Header style={{ height: "36px" }}>
                                    Room Nights
                                  </Header>
                                  <Header style={{ height: "36px" }}>
                                    ADR
                                  </Header>
                                  <Header style={{ height: "36px" }}>
                                    Room Revenue
                                  </Header>
                                </HeaderContainer>
                              </PickupContainer>
                            </TCell>
                            {item.budgetModel.map((budget, index) => {
                              return (
                                <TCell
                                  key={index}
                                  style={{
                                    borderLeft: "1px solid #e7e6ec",
                                  }}
                                >
                                  <TransFormPickup
                                    data={budget.roomsLY - budget.roomsOTB}
                                  />
                                  <TransFormPickup
                                    data={
                                      (budget.revenueLY - budget.revenueOTB) /
                                      (budget.roomsLY - budget.roomsOTB)
                                    }
                                  />
                                  <TransFormPickup
                                    data={budget.revenueLY - budget.revenueOTB}
                                  />

                                  <TransFormPickup
                                    data={budget.roomsBudget - budget.roomsOTB}
                                  />
                                  <TransFormPickup
                                    data={
                                      (budget.revenueBudget -
                                        budget.revenueOTB) /
                                      (budget.roomsBudget - budget.roomsOTB)
                                    }
                                  />
                                  <TransFormPickup
                                    data={
                                      budget.revenueBudget - budget.revenueOTB
                                    }
                                  />

                                  <TransFormPickup
                                    data={budget.roomsLocked - budget.roomsOTB}
                                  />
                                  <TransFormPickup
                                    data={budget.revenueBudget}
                                  />
                                  <TransFormPickup
                                    data={
                                      budget.revenueLocked - budget.revenueOTB
                                    }
                                  />

                                  <TransFormPickup
                                    data={budget.roomsActual - budget.roomsOTB}
                                  />
                                  <TransFormPickup
                                    data={
                                      (budget.revenueActual -
                                        budget.revenueOTB) /
                                      (budget.roomsActual - budget.roomsOTB)
                                    }
                                  />
                                  <TransFormPickup
                                    data={
                                      budget.revenueActual - budget.revenueOTB
                                    }
                                  />
                                </TCell>
                              );
                            })}
                          </TRow>
                          <TRow
                            style={{
                              background: "rgba(48, 111, 188, 1)",
                              height: "8px",
                            }}
                          >
                            <td colSpan="100%"></td>
                          </TRow>
                        </>
                      )}
                    </React.Fragment>
                  );
                })}

                {/*
                 will be added later after discussion
                 
                 {competitorData && (
                  <>
                    <Trow style={{ height: "50px", background: "white" }}>
                      <TCell
                        colSpan="100%"
                        style={{
                          background: "white",
                          border: "none",
                          zIndex: "1",
                        }}
                      ></TCell>
                    </Trow>
                    <TRow style={{ background: "white", border: "none" }}>
                      <TCell
                        className="sticky"
                        style={{
                          background: "white",
                          border: "none",
                          zIndex: "1",
                        }}
                      ></TCell>
                      <TCell className="stickyRight" style={{ zIndex: "1" }}>
                        <Header style={{ borderBottom: "none" }}>
                          Occupancy %
                        </Header>
                      </TCell>
                      {competitorData.map((item, index) => {
                        return (
                          <TCell key={index}>
                            <TransFormOcuupancy
                              data={item.occupancyPercentage}
                              color={item?.color}
                            />
                          </TCell>
                        );
                      })}
                    </TRow>
                    <TRow>
                      <TCell
                        className="sticky"
                        style={{
                          background: "white",
                          border: "none",
                          zIndex: "1",
                        }}
                      ></TCell>
                      <TCell className="stickyRight" style={{ zIndex: "1" }}>
                        <Header style={{ borderBottom: "none" }}>
                          Average Room Rate
                        </Header>
                      </TCell>
                      {competitorData?.map((item) => {
                        return (
                          <TCell key={item.averageRoomRate}>
                            <TransFormBottomTableData
                              data={item.averageRoomRate}
                            />
                          </TCell>
                        );
                      })}
                    </TRow>
                    <TRow>
                      <TCell
                        className="sticky"
                        style={{
                          background: "white",
                          border: "none",
                          zIndex: "1",
                        }}
                      ></TCell>
                      <TCell className="stickyRight" style={{ zIndex: "1" }}>
                        <Header style={{ borderBottom: "none" }}>
                          Rooms Revenue
                        </Header>
                      </TCell>
                      {competitorData?.map((item) => {
                        return (
                          <TCell key={item.revenue}>
                            <TransFormBottomTableData data={item.revenue} />
                          </TCell>
                        );
                      })}
                    </TRow>
                    <TRow>
                      <TCell
                        className="sticky"
                        style={{
                          background: "white",
                          border: "none",
                          zIndex: "1",
                        }}
                      ></TCell>
                      <TCell className="stickyRight" style={{ zIndex: "1" }}>
                        <Header style={{ borderBottom: "none" }}>RevPAR</Header>
                      </TCell>
                      {competitorData?.map((item) => {
                        return (
                          <TCell key={item.revPAR}>
                            <TransFormBottomTableData data={item.revPAR} />
                          </TCell>
                        );
                      })}
                    </TRow>
                  </>
                )}

                <Trow style={{ height: "50px", background: "white" }}>
                  <TCell
                    colSpan="100%"
                    style={{ background: "white", border: "none" }}
                  ></TCell>
                </Trow>

                <TRow>
                  <TCell
                    className="sticky"
                    style={{ background: "white", border: "none", zIndex: "1" }}
                  ></TCell>
                  <TCell
                    className="stickyRight"
                    style={{ zIndex: "1", background: "#ccccff" }}
                  >
                    <Header style={{ borderBottom: "none" }}>
                      {competitorData && competitorData[0].hotelName
                        ? competitorData[0].hotelName
                        : "-"}
                    </Header>
                  </TCell>
                  {competitorData?.map((item, index) => {
                    return (
                      <TCell key={index}>
                        <TransFormBottomTableData data={item.currentPrice} />
                      </TCell>
                    );
                  })}
                </TRow>
                {competitorData &&
                  competitorData[0]?.competitorDetails?.map((comp, index) => (
                    <TRow key={index}>
                      <TCell
                        className="sticky"
                        style={{
                          background: "white",
                          border: "none",
                          zIndex: "1",
                        }}
                      ></TCell>
                      <TCell className="stickyRight" style={{ zIndex: "1" }}>
                        <Header style={{ borderBottom: "none" }}>
                          {comp?.competitorName}
                        </Header>
                      </TCell>
                      {competitorData?.map((item, id) => {
                        return (
                          <TCell key={id + 1}>
                            <TransFormCompetitorsPrice
                              data={
                                item.competitorDetails &&
                                item.competitorDetails[index]?.price
                              }
                            />
                          </TCell>
                        );
                      })}
                    </TRow>
                  ))}
                <Trow style={{ height: "50px", background: "white" }}>
                  <TCell
                    colSpan="100%"
                    style={{ background: "white", border: "none" }}
                  ></TCell>
                </Trow>

                <TRow>
                  <TCell
                    className="sticky"
                    style={{ background: "white", border: "none", zIndex: "1" }}
                  ></TCell>
                  <TCell className="stickyRight">
                    <Header style={{ borderBottom: "none", zIndex: "1" }}>
                      Manual Demand Level
                    </Header>
                  </TCell>
                  {competitorData?.map((item, index) => {
                    return (
                      <TCell key={index}>
                        <TransFormBottomTableData
                          data={item?.calculationSettings?.demandLevelDLF}
                        />
                      </TCell>
                    );
                  })}
                </TRow>
                <TRow>
                  <TCell
                    className="sticky"
                    style={{ background: "white", border: "none", zIndex: "1" }}
                  ></TCell>
                  <TCell className="stickyRight" style={{ zIndex: "1" }}>
                    <Header style={{ borderBottom: "none" }}>
                      System Demand Level
                    </Header>
                  </TCell>
                  {competitorData?.map((item, index) => {
                    return (
                      <TCell key={index}>
                        <TransFormBottomTableData
                          data={item?.calculationSettings}
                        />
                      </TCell>
                    );
                  })}
                </TRow>
                <TRow>
                  <TCell
                    className="sticky"
                    style={{ background: "white", border: "none", zIndex: "1" }}
                  ></TCell>
                  <TCell className="stickyRight">
                    <Header style={{ borderBottom: "none", zIndex: "1" }}>
                      Price point Recommendation
                    </Header>
                  </TCell>
                  {competitorData?.map((item, index) => {
                    return (
                      <TCell key={index}>
                        <TransFormBottomTableData data={item?.calculatePrice} />
                      </TCell>
                    );
                  })}
                </TRow>
                <TRow>
                  <TCell
                    className="sticky"
                    style={{ background: "white", border: "none", zIndex: "1" }}
                  ></TCell>
                  <TCell className="stickyRight" style={{ zIndex: "1" }}>
                    <Header style={{ borderBottom: "none" }}>
                      Override Y/N
                    </Header>
                  </TCell>
                  {competitorData?.map((item, index) => {
                    return (
                      <TCell key={index}>
                        <Cell style={{ borderBottom: "none" }}>
                          {item?.isOverridePrice ? "Yes" : "NO"}
                        </Cell>
                      </TCell>
                    );
                  })}
                </TRow>
                <TRow>
                  <TCell
                    className="sticky"
                    style={{ background: "white", border: "none", zIndex: "1" }}
                  ></TCell>
                  <TCell className="stickyRight" style={{ zIndex: "1" }}>
                    <Header style={{ borderBottom: "none" }}>
                      Override Price Point
                    </Header>
                  </TCell>
                  {competitorData?.map((item, index) => {
                    return (
                      <TCell key={index}>
                        <TransFormBottomTableData data={item?.overridePrice} />
                      </TCell>
                    );
                  })}
                </TRow>
                <TRow>
                  <TCell
                    className="sticky"
                    style={{ background: "white", border: "none", zIndex: "1" }}
                  ></TCell>
                  <TCell className="stickyRight" style={{ zIndex: "1" }}>
                    <Header style={{ borderBottom: "none" }}>
                      Override Reason
                    </Header>
                  </TCell>
                  {competitorData?.map((item) => {
                    return (
                      <TextOnlyTooltip
                        disableFocusListener
                        disableTouchListener
                        title={item?.overrideReason}
                        arrow
                      >
                        <TCell
                          key={
                            item?.overrideReason ? item?.overrideReason : "-"
                          }
                        >
                          <Cell
                            style={{
                              height: "20px",
                              maxWidth: "200px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              lineHeight: "20px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item?.overrideReason ? item?.overrideReason : "-"}
                          </Cell>
                        </TCell>
                      </TextOnlyTooltip>
                    );
                  })}
                </TRow> */}
              </TableBody>
            </Table>
          </TableWrapper>

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={open}
            autoHideDuration={3000}
            onClose={() => setOpen(false)}
          >
            {
              <SnackbarContent
                style={{ backgroundColor: "#CA3433" }}
                message={networkMsg}
              />
            }
          </Snackbar>
        </>
      ) : (
        <LoadingPage />
      )}
    </StyledContainer>
  );
};

function TransForm({ data, style }) {
  if (isNaN(data)) {
    return <Cell style={style}>-</Cell>;
  }
  return <Cell style={style}>{parseFloat(data).toFixed(2)}</Cell>;
}
function TransFormCompetitorsPrice({ data }) {
  if (isNaN(data)) {
    return <Cell>-</Cell>;
  }
  if (data === -1) {
    return <Cell style={{ borderBottom: "none" }}>Sold Out</Cell>;
  }
  return (
    <Cell style={{ borderBottom: "none" }}>{parseFloat(data).toFixed(2)}</Cell>
  );
}
function TransFormBottomTableData({ data }) {
  if (isNaN(data)) {
    return <Cell style={{ borderBottom: "none" }}>-</Cell>;
  }
  return (
    <Cell style={{ borderBottom: "none" }}>{parseFloat(data).toFixed(2)}</Cell>
  );
}
function TransFormOcuupancy({ data, color }) {
  if (isNaN(data)) {
    return <Cell style={{ borderBottom: "none" }}>-</Cell>;
  }
  return (
    <Cell
      style={{
        borderBottom: "none",
        background: `linear-gradient(to bottom right,${color},#FFFFFF)`,
      }}
    >
      {parseInt(data)}%
    </Cell>
  );
}

function TransFormInt({ data }) {
  if (isNaN(data)) {
    return <Cell>-</Cell>;
  }
  return <Cell>{parseInt(data)}</Cell>;
}

function TransFormPickup({ data }) {
  if (isNaN(data) || data === Infinity || data === -Infinity) {
    return <Cell>-</Cell>;
  }

  if (data < 0) {
    return <Cell style={{ color: "red" }}>{parseInt(data)}</Cell>;
  }
  return <Cell style={{ color: "green" }}>{parseInt(data)}</Cell>;
}
