import React, { useEffect } from "react";
import { NavLink, Route, useHistory, useParams } from "react-router-dom";
import { styled } from "@mui/system";
import { useAuth } from "../sdk";
import DailyUploadinfo from "./DailyDataUploadInformation/index";
import FileUploads from "./FileUploads/index";
import MonthlyUploadinfo from "./MonthlyDataUploadInformation/index";
import { Box, Typography } from "@mui/material";
import RateshopLogs from "./RateshopLogs";

const TabNav = styled(NavLink)`
  &:-webkit-any-link {
    text-decoration: none;
  }
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #727272;
  &:hover {
    cursor: pointer;
  }

  width: 100%;
  height: 100%;
`;
const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 52px;
  font: normal normal bold 16px/20px Roboto;
  padding: 0px 20px;
  margin-bottom: 20px;
`;
const H = styled(Typography)`
  font: normal normal bold 24px/30px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  position: fixed;
  top: 13px;
  left: 80px;
  color: white;
  z-index: 999;
`;
const Container = styled(Box)`
  padding: 80px 0px 0px 60px;
  width: 100%;
`;
const HeaderCard = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  height: 52px;
  width: 48%;
`;
const Heading = styled(Box)`
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
`;
const Padding = styled(Box)`
  padding: 0px 30px 0px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  height: 52px;
`;
export const Uploadinfo = () => {
  const { hotelId } = useParams();
  const { token, permission, rateShopFeature } = useAuth();
  const history = useHistory();
  let monthlyLogs = null,
    dailyLogs = null;
  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "monthlyLogs") {
        monthlyLogs = permission[key];
      }
      if (permission[key].name === "dailyLogs") {
        dailyLogs = permission[key];
      }
    }
  }
  useEffect(() => {
    if (token && hotelId) {
      window.scrollTo(0, 0);
    } else {
      return;
    }
  }, [token, hotelId]);
  useEffect(() => {
    if (!permission) {
      return;
    }
    if (monthlyLogs) {
      history.replace(`/hotel/${hotelId}/data_upload_information/monthly`);
    } else if (dailyLogs) {
      history.replace(`/hotel/${hotelId}/data_upload_information/daily`);
    } else {
      history.replace(
        `/hotel/${hotelId}/data_upload_information/uploaded-files`
      );
    }
  }, [dailyLogs, history, hotelId, monthlyLogs, permission]);
  return (
    <Container>
      <H>Data Upload Information</H>

      <Header>
        {monthlyLogs && (
          <HeaderCard>
            <TabNav
              exact
              to={`/hotel/${hotelId}/data_upload_information/monthly`}
              activeStyle={{
                color: "#322277",
                borderLeft: "4px solid #322277",
              }}
            >
              <Padding>
                <Heading>Monthly Logs</Heading>

                <div></div>
              </Padding>
            </TabNav>
          </HeaderCard>
        )}
        {dailyLogs && (
          <HeaderCard>
            <TabNav
              exact
              to={`/hotel/${hotelId}/data_upload_information/daily`}
              activeStyle={{
                color: "#322277",
                borderLeft: "4px solid #322277",
              }}
            >
              <Padding>
                <Heading>Daily Uploads</Heading>
                <div></div>
              </Padding>
            </TabNav>
          </HeaderCard>
        )}

        <HeaderCard>
          <TabNav
            exact
            to={`/hotel/${hotelId}/data_upload_information/uploaded-files`}
            activeStyle={{
              color: "#322277",
              borderLeft: "4px solid #322277",
            }}
          >
            <Padding>
              <Heading>File Uploads</Heading>
              <div></div>
            </Padding>
          </TabNav>
        </HeaderCard>
        {rateShopFeature && (
          <HeaderCard>
            <TabNav
              exact
              to={`/hotel/${hotelId}/data_upload_information/rateshop-logs`}
              activeStyle={{
                color: "#322277",
                borderLeft: "4px solid #322277",
              }}
            >
              <Padding>
                <Heading>Rateshop Logs</Heading>
                <div></div>
              </Padding>
            </TabNav>
          </HeaderCard>
        )}
      </Header>
      <div>
        <Route
          path="/hotel/:hotelId/data_upload_information/monthly"
          component={MonthlyUploadinfo}
        />
        <Route
          path="/hotel/:hotelId/data_upload_information/daily"
          component={DailyUploadinfo}
        />
        <Route
          path="/hotel/:hotelId/data_upload_information/uploaded-files"
          component={FileUploads}
        />
        <Route
          path="/hotel/:hotelId/data_upload_information/rateshop-logs"
          component={RateshopLogs}
        />
      </div>
    </Container>
  );
};
