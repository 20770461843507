import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { useAuth } from "../../sdk/hooks/useAuth";
import { useWarning } from "../../Provider/context";
export default function useBudgetSheet() {
  const { token, authFetch, permission } = useAuth();
  const { hotelId } = useParams();
  const { formEdit, setformEdit } = useWarning();
  const [budgetSheetInfo, setBudgetSheetInfo] = useState([]);
  const [pastdata, setPastData] = useState([]);
  const [budgetingPeriod, setBudgetingPeriod] = useState(null);
  const [openSelectbar, setOpenSelectBar] = useState(false);
  const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [isLeftDisabled, setIsLeftDisabled] = useState(false);
  const [isRightDisabled, setIsRightDisabled] = useState(false);
  const [YEAR, setSelectYear] = useState(new Date().getFullYear());
  const maxYEAR = new Date().getFullYear();
  const [filename, setFileName] = useState("");
  const formdata = useRef(new FormData());
  const [toggle, setToggle] = useState(false);
  const [stateArray, setstateArray] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [initialState, setList] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [newList, setNewList] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  const [lockBudget, setLockBudget] = useState(false);
  const [haveData, setHaveData] = useState(true);
  const [budgetData, setBudgetData] = useState([]);
  const [roomAdjustmentData, setRoomAdjustmentData] = useState([]);
  const [pastbudgetData, setPastBudgetData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [totalRooms, setTotalRooms] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalRoomsToSell, setTotalRoomsToSell] = useState(0);
  const [checked, setChecked] = useState(false);
  const [networkMsg, setnetworkMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState(false);
  const [optionValue, setOptionValue] = useState(0);
  const [totalShow, setTotalShow] = useState(false);
  const [occTotal, setOccTotal] = useState([]);
  const totalMonths = 12;
  const history = useHistory();
  const [isSaved, setIsSaved] = useState(true);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [dialogSave, setDialogSave] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isBudgetFound, setIsBudgetFound] = useState(false);

  const calculateSum = useCallback(async () => {
    setTotalRooms(budgetSheetInfo?.reduce((a, b) => (a = a + b.rooms), 0));
    setTotalRevenue(budgetSheetInfo?.reduce((a, b) => (a = a + b.revenue), 0));
  }, [budgetSheetInfo]);

  const toggleLeft = () => {
    setSelectYear((prev) => {
      if (prev !== maxYEAR - 1) {
        setIsLeftDisabled(false);
        setIsRightDisabled(false);
        return prev - 1;
      } else {
        setIsLeftDisabled(true);
        setIsRightDisabled(false);
        return prev;
      }
    });
  };
  const toggleRight = () => {
    setSelectYear((prev) => {
      if (prev < maxYEAR + 1) {
        setIsRightDisabled(false);
        setIsLeftDisabled(false);
        return prev + 1;
      } else {
        setIsRightDisabled(true);
        setIsLeftDisabled(false);
        return prev;
      }
    });
  };

  const handleToggle = useCallback(() => {
    setToggle(!toggle);
  }, [toggle]);

  const fileGetter = useCallback(
    (file) => {
      setFileName(file[0].name);
      handleToggle();
      formdata.current.append("file", file[0]);
      formdata.current.append("description", "XLSX File");
    },
    [handleToggle]
  );

  const removeFile = useCallback(() => {
    setFileName("");
    formdata.current.delete("file");
    formdata.current.delete("description");
    formdata.current.delete("uploadedFor");
    handleToggle();
  }, [handleToggle]);

  const fileHandler = useCallback(
    async (year) => {
      setOpenSelectBar(false);
      formdata.current.append("year", year);
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/upload-budget-sheet-monthly`,
      });
      const { data, response } = await post(formdata.current);
      if (response.status === 200) {
        setSnackBarColor(true);
        setnetworkMsg("Uploaded Successfully");
        setOpen(true);
      } else {
        setSnackBarColor(false);
        setOpen(true);
        setnetworkMsg(data?.messageToUser || "Invalid File");
      }

      formdata.current = new FormData();
    },
    [hotelId, authFetch]
  );

  const GetBudgetSheet = useCallback(async () => {
    setLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/budget-sheet-monthly/${YEAR}`,
    });
    const { data } = await get();
    if (data[0] === undefined) {
      setHaveData(false);
    } else {
      setBudgetSheetInfo(
        data?.filter((filterValue) => filterValue?.monthlyReport?.length === 12)
      );
      setLockBudget(data[0]?.isLocked ?? false);
      if (
        !data?.filter(
          (filterValue) => filterValue?.monthlyReport?.length === 12
        )
      ) {
        setHaveData(false);
      }
      setTotalShow(true);
    }

    setLoading(false);
  }, [YEAR, authFetch, hotelId]);

  const setPastYearValue = useCallback((val) => {
    setOptionValue(val);
  }, []);

  useEffect(() => {
    if (budgetSheetInfo.length > 0) {
      const budgetSheetInfoSegId = budgetSheetInfo.map((x) => x.segmentId);
    }
  }, [budgetSheetInfo]);

  const GetPastData = useCallback(async () => {
    setLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/monthly-forecast-by-year/${
        YEAR - optionValue ?? 1
      }`,
    });
    const { data, response } = await get();

    if (
      response.status === 200 &&
      data?.Message !== "Past Data not available"
    ) {
      const budgetSheetInfoSegIds = budgetSheetInfo?.map(
        (item) => item?.segmentId
      );

      const pastDataFilteredBySegId = data?.filter((item) => {
        if (budgetSheetInfoSegIds.includes(item.segmentId)) {
          return true;
        }
        return false;
      });

      setPastData(pastDataFilteredBySegId);
    }
    setLoading(false);
  }, [YEAR, authFetch, hotelId, optionValue, budgetSheetInfo]);

  useEffect(() => {}, [pastdata]);

  const GetBudget = useCallback(async () => {
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/room-adjustmentv2/${YEAR}`,
    });
    const { data } = await get();
    if (data) {
      setBudgetData(data?.data);
      setBudgetingPeriod(data?.budgetingPeriod);
      setTotalRoomsToSell(
        data?.data.reduce((a, b) => (a = a + b.roomsToSell), 0)
      );
    }
  }, [YEAR, authFetch, hotelId]);

  const GetPastYearBudget = useCallback(async () => {
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/room-adjustmentv2/${YEAR - 1}`,
    });
    const { data } = await get();
    if (data) {
      setPastBudgetData(data?.data);
    }
  }, [YEAR, authFetch, hotelId]);

  const GetRoomAdustment = useCallback(async () => {
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/room-adjustmentv2/${YEAR - optionValue ?? 1}`,
    });
    const { data } = await get();
    if (data) {
      setRoomAdjustmentData(data?.data);
    }
  }, [YEAR, authFetch, hotelId, optionValue]);

  const totalRows = budgetSheetInfo.length / 12;

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) setstateArray([...newList]);
    else setstateArray([...initialState]);
  };
  const handleClick = (id) => {
    const newlist = [...stateArray];
    newlist[id] = !stateArray[id];
    setstateArray([...newlist]);
  };
  const handleEdit = useDebouncedCallback(
    (index, pos, name, e) => {
      setIsSaved(false);
      setformEdit(true);
      if (e === "") {
        e = 0;
      }

      let revenue;

      if (name === "rooms") {
        revenue =
          e === 0 ||
          e === "" ||
          budgetSheetInfo[index].monthlyReport[pos].arr === 0
            ? 0
            : parseInt(e * budgetSheetInfo[index].monthlyReport[pos].arr);
      } else {
        revenue =
          e === 0 ||
          e === "" ||
          budgetSheetInfo[index].monthlyReport[pos].rooms === 0
            ? 0
            : parseInt(e * budgetSheetInfo[index].monthlyReport[pos].rooms);
      }
      setBudgetSheetInfo([
        ...budgetSheetInfo.slice(0, index),
        {
          ...budgetSheetInfo[index],
          monthlyReport: [
            ...budgetSheetInfo[index].monthlyReport.slice(0, pos),
            {
              ...budgetSheetInfo[index].monthlyReport[pos],
              revenue: revenue,
              [name]: e,
            },
            ...budgetSheetInfo[index].monthlyReport.slice(pos + 1, 12),
          ],
        },
        ...budgetSheetInfo.slice(index + 1, budgetSheetInfo.length),
      ]);
    },
    0,
    [budgetSheetInfo]
  );

  const HandlePastData = useCallback((pos) => {
    return pastdata
      .filter((v) => v?.month === pastdata[pos].month)
      .reduce((a, b) => (a = a + b.rooms), 0);
  });
  const HandlePastRevenue = useCallback((pos) => {
    return pastdata
      .filter((v) => v?.month === pastdata[pos].month)
      .reduce((a, b) => (a = a + b.revenue), 0);
  });
  const HandleBudgetMonth = useCallback((pos) => {
    const result = budgetSheetInfo
      .filter((v) => v?.month === budgetSheetInfo[pos].month)
      .reduce((a, b) => (a = a + b.rooms), 0);
    return Math.round((result + Number.EPSILON) * 100) / 100;
  });
  const HandleBudgetRevenue = useCallback((pos) => {
    const result = budgetSheetInfo
      .filter((v) => v?.month === budgetSheetInfo[pos].month)
      .reduce((a, b) => (a = a + b.revenue), 0);
    return Math.round((result + Number.EPSILON) * 100) / 100;
  });
  const PresentOccupancy = useCallback((pos) => {
    const first = budgetData[pos]?.roomsToSell;
    const second = budgetSheetInfo
      .filter((v) => v?.month === budgetSheetInfo[pos].month)
      .reduce((a, b) => (a = a + b.rooms), 0);

    if (second === 0 || first === 0) return 0;
    else {
      const result = (second / first) * 100;
      return Math.round((result + Number.EPSILON) * 100) / 100;
    }
  });
  const PastOccupancy = useCallback((pos) => {
    const first = pastbudgetData[pos]?.roomsToSell;
    const second = pastdata
      .filter((v) => v?.month === pastdata[pos].month)
      .reduce((a, b) => (a = a + b.rooms), 0);

    if (second === 0 || first === 0) return 0;
    else {
      const result = (second / first) * 100;
      return Math.round((result + Number.EPSILON) * 100) / 100;
    }
  });
  const HandleBudgetADR = useCallback((pos) => {
    const first = HandleBudgetRevenue(pos);
    const second = HandleBudgetMonth(pos);

    if (second === 0 || first === 0) return 0;
    else {
      const result = first / second;

      return Math.round((result + Number.EPSILON) * 100) / 100;
    }
  });

  const HandlePastDataADR = useCallback((pos) => {
    const first = HandlePastRevenue(pos);
    const second = HandlePastData(pos);

    if (second === 0 || first === 0) return 0;
    else {
      const result = first / second;

      return Math.round((result + Number.EPSILON) * 100) / 100;
    }
  });

  const HandleBudgetRevPAR = (pos) => {
    const first = HandleBudgetADR(pos);
    const second = PresentOccupancy(pos);
    const result = first * second;
    return Math.round((result + Number.EPSILON) * 100) / 100;
  };
  const HandlePastRevPAR = (pos) => {
    const first = HandlePastDataADR(pos);
    const second = PastOccupancy(pos);
    const result = first * second;
    return Math.round((result + Number.EPSILON) * 100) / 100;
  };

  const calculateSumRoom = useCallback((value) => {
    const result = 0;
    return result + value;
  });
  const calculateSumRevenue = useCallback((index) => {
    const result = budgetSheetInfo
      .filter((v) => v?.segmentID === budgetSheetInfo[index * 12]?.segmentID)
      .reduce((a, b) => (a = a + b.revenue), 0);
    return Math.round((result + Number.EPSILON) * 100) / 100;
  });
  const calculateSumADR = (index) => {
    const first = calculateSumRoom(index);
    const second = calculateSumRevenue(index);
    if (first === 0 || second === 0) return 0;
    else {
      const result = second / first;
      return Math.round((result + Number.EPSILON) * 100) / 100;
    }
  };

  const totalRevPAR = () => {
    const first =
      totalRooms === 0 || totalRevenue === 0
        ? 0
        : Math.round((totalRevenue / totalRooms + Number.EPSILON) * 100) / 100;
    const second =
      totalRooms === 0 || totalRoomsToSell === 0
        ? 0
        : Math.round(
            ((totalRevenue / totalRoomsToSell) * 100 + Number.EPSILON) * 100
          ) / 100;
    const result = second * first;

    return Math.round((result + Number.EPSILON) * 100) / 100;
  };

  const saveBudgetSheetData = useCallback(async () => {
    setformEdit(false);
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/budget-sheet-monthly/${YEAR}`,
    });
    const { data, error } = await post(budgetSheetInfo);
    if (data) {
      setIsSaved(true);
      setSnackBarColor(true);
      setnetworkMsg("Saved Successful");
      setOpen(true);
      if (dialogSave) {
        history.push(`/hotel/${hotelId}/setup/budget-allocation`);
      }
    } else {
      setnetworkMsg(
        data?.messageToUser || "Something went wrong, Please try again"
      );
      setSnackBarColor(false);
      setOpen(true);
    }
  }, [authFetch, hotelId, YEAR, budgetSheetInfo, dialogSave, Loading]);

  const handleCloseStatus = () => {
    setOpen(false);
  };

  const handleLeavePage = useCallback(() => {
    history.push(`/hotel/${hotelId}/setup/budget-allocation`);
  }, []);

  const handleDialogSave = useCallback(() => {
    setDialogSave(true);
  }, [dialogSave]);

  useEffect(() => {
    if (dialogSave) {
      saveBudgetSheetData();
    }
  }, [dialogSave]);

  const handleCloseDialog = useCallback(() => {
    setIsOpenDialog(false);
  }, [isOpenDialog]);

  const handleNext = useCallback(() => {
    if (!isSaved) {
      setIsOpenDialog(true);
    } else {
      history.push(`/hotel/${hotelId}/setup/budget-allocation`);
    }
  }, [isSaved, isOpenDialog]);

  useEffect(() => {
    if (token) {
      GetBudgetSheet();
      GetBudget();
      GetPastYearBudget();
    }
  }, [token, hotelId, YEAR, GetBudget, GetBudgetSheet, GetPastYearBudget]);

  useEffect(() => {
    if (token) {
      GetPastData();
      GetRoomAdustment();
    }
  }, [optionValue, token]);

  useEffect(() => {
    if (token && pastdata?.length === 0) GetPastData();
  }, [GetPastData, token]);

  useEffect(() => {
    if (token) {
      calculateSum();
    }
  }, [token, budgetSheetInfo, calculateSum]);

  // const calculateTotal = useCallback(
  //   (index, tag) => {
  //     if (tag === "rooms") {
  //       let value = budgetSheetInfo[index]?.monthlyReport?.reduce(
  //         (prev, next) => prev + (next.rooms ?? 0),
  //         0
  //       );
  //       return value;
  //     } else if (tag === "revenue") {
  //       let value = budgetSheetInfo[index].monthlyReport?.reduce(
  //         (prev, next) => prev + (next.revenue ?? 0),
  //         0
  //       );
  //       return value;
  //     } else if (tag === "actual_revenue") {
  //       if (
  //         pastdata[index].monthlyReport.every(
  //           (everyValue) => everyValue?.revenue === -1
  //         )
  //       ) {
  //         return "-";
  //       }

  //       console.log(index, pastdata)
  //       let value = pastdata[index].monthlyReport?.reduce(
  //         (prev, next) => prev + (next.revenue > 0 ? next.revenue : 0 ),
  //         0
  //       );
  //       return value;
  //     } else if (tag === "actual_arr") {
  //       if (
  //         pastdata[index].monthlyReport.every(
  //           (everyValue) => everyValue?.arr === -1
  //         )
  //       ) {
  //         return "-";
  //       }

  //       let value = pastdata[index].monthlyReport?.reduce(
  //         (prev, next) => prev + (next.arr > 0 ? next.arr : 0),
  //         0
  //       );
  //       return value;
  //     } else if (tag === "actual_rooms") {
  //       if (
  //         pastdata[index].monthlyReport.every(
  //           (everyValue) => everyValue?.noOfRoomsBooked === -1
  //         )
  //       ) {
  //         return "-";
  //       }

  //       let value = budgetSheetInfo[index].monthlyReport?.reduce(
  //         (prev, next) =>
  //           prev + (next.noOfRoomsBooked > 0 ? next.noOfRoomsBooked : 0),
  //         0
  //       );
  //       return value;
  //     } else {
  //       let value =
  //         Math.floor(
  //           (budgetSheetInfo[index].monthlyReport?.reduce(
  //             (prev, next) => prev + (next.revenue ?? 0),
  //             0
  //           ) /
  //             (budgetSheetInfo[index].monthlyReport?.reduce(
  //               (prev, next) => prev + (next.rooms ?? 0),
  //               0
  //             ) === 0
  //               ? 1
  //               : budgetSheetInfo[index].monthlyReport?.reduce(
  //                   (prev, next) => prev + (next.rooms ?? 0),
  //                   0
  //                 ))) *
  //             100
  //         ) / 100;
  //       return value;
  //     }
  //   },
  //   [budgetSheetInfo,pastdata]
  // );

  const calculateTotal = useCallback(
    (index, tag) => {
      const monthlyReport = budgetSheetInfo[index]?.monthlyReport || [];
      const actualReport = pastdata[index]?.monthlyReport || [];

      switch (tag) {
        case "rooms":
          return monthlyReport.reduce(
            (total, item) => total + (item.rooms ?? 0),
            0
          );
        case "revenue":
          return (
            Math.floor(
              monthlyReport.reduce(
                (total, item) => total + (item.revenue ?? 0),
                0
              ) * 100
            ) / 100
          );

        case "actual_revenue":
          if (actualReport.every((item) => item?.revenue === -1)) return "-";
          return (
            Math.floor(
              actualReport.reduce(
                (total, item) => total + Math.max(item.revenue, 0),
                0
              ) * 100
            ) / 100
          );

        case "actual_arr":
          if (actualReport.every((item) => item?.arr === -1)) return "-";
          return (
            Math.floor(
              actualReport.reduce(
                (total, item) => total + Math.max(item.arr, 0),
                0
              ) * 100
            ) / 100
          );

        case "actual_rooms":
          if (actualReport.every((item) => item?.noOfRoomsBooked === -1))
            return "-";
          return (
            Math.floor(
              actualReport.reduce(
                (total, item) => total + Math.max(item.noOfRoomsBooked, 0),
                0
              ) * 100
            ) / 100
          );

        case "past_total_rooms":
          if (
            pastdata.every((item) =>
              item?.monthlyReport?.every(
                (report) => report.noOfRoomsBooked === -1
              )
            )
          )
            return "-";
          return (
            Math.floor(
              pastdata.reduce(
                (total, item) =>
                  total +
                  item?.monthlyReport?.reduce(
                    (acc, curr) => acc + Math.max(curr.noOfRoomsBooked, 0),
                    0
                  ),
                0
              ) * 100
            ) / 100
          );

        case "past_total_revenue":
          if (
            pastdata.every((item) =>
              item?.monthlyReport?.every((report) => report.revenue === -1)
            )
          )
            return "-";
          return (
            Math.floor(
              pastdata.reduce(
                (total, item) =>
                  total +
                  item?.monthlyReport?.reduce(
                    (acc, curr) => acc + Math.max(curr.revenue, 0),
                    0
                  ),
                0
              ) * 100
            ) / 100
          );

        case "past_total_occ":
          if (
            pastdata.every((item) =>
              item?.monthlyReport?.every(
                (report) => report.noOfRoomsBooked === -1
              )
            )
          )
            return "-";
          return (
            Math.floor(
              (pastdata.reduce(
                (total, item) =>
                  total +
                  item?.monthlyReport?.reduce(
                    (acc, curr) => acc + Math.max(curr.noOfRoomsBooked, 0),
                    0
                  ),
                0
              ) /
                roomAdjustmentData.reduce(
                  (total, item) => total + Math.max(item?.roomsToSell, 1),
                  0
                )) *
                10000
            ) / 100
          );

        case "past_total_adr":
          if (
            pastdata.every((item) =>
              item?.monthlyReport?.every((report) => report.revenue === -1)
            )
          )
            return "-";
          return (
            Math.floor(
              (pastdata.reduce(
                (total, item) =>
                  total +
                  item?.monthlyReport?.reduce(
                    (acc, curr) => acc + Math.max(curr.revenue, 0),
                    0
                  ),
                0
              ) /
                pastdata.reduce(
                  (total, item) =>
                    total +
                    item?.monthlyReport?.reduce(
                      (acc, curr) => acc + Math.max(curr.noOfRoomsBooked, 0),
                      0
                    ),
                  0
                )) *
                100
            ) / 100
          );

        case "past_total_revpar":
          if (
            pastdata.every((item) =>
              item?.monthlyReport?.every((report) => report.revenue === -1)
            )
          )
            return "-";
          return (
            Math.floor(
              (pastdata.reduce(
                (total, item) =>
                  total +
                  item?.monthlyReport?.reduce(
                    (acc, curr) => acc + Math.max(curr.revenue, 0),
                    0
                  ),
                0
              ) /
                roomAdjustmentData.reduce(
                  (total, item) => total + Math.max(item?.roomsToSell, 1),
                  0
                )) *
                100
            ) / 100
          );

        case "total_budget_occ":
          return (
            Math.floor(
              (budgetSheetInfo
                ?.map((val) =>
                  val?.monthlyReport.reduce((prv, next) => prv + next.rooms, 0)
                )
                .reduce((prv, next) => prv + next, 0) /
                pastbudgetData?.reduce(
                  (prv, next) => prv + next.roomsToSell,
                  0
                )) *
                10000
            ) / 100
          );

        case "total_budget_rooms":
          return budgetSheetInfo
            ?.map((val) =>
              val.monthlyReport.reduce((prv, next) => prv + next.rooms, 0)
            )
            .reduce((prv, next) => prv + next, 0);

        case "total_budget_adr":
          return (
            Math.floor(
              budgetSheetInfo
                ?.map((val) =>
                  val.monthlyReport.reduce((prv, next) => prv + next.rooms, 0)
                )
                .reduce((prv, next) => prv + next, 0) === 0
                ? 0
                : (budgetSheetInfo
                    .map((val) =>
                      val.monthlyReport.reduce(
                        (prv, next) => prv + next.revenue,
                        0
                      )
                    )
                    .reduce((prv, next) => prv + next, 0) /
                    budgetSheetInfo
                      ?.map((val) =>
                        val.monthlyReport.reduce(
                          (prv, next) => prv + next.rooms,
                          0
                        )
                      )
                      .reduce((prv, next) => prv + next, 0)) *
                    100
            ) / 100
          );

        case "total_budget_revenue":
          return budgetSheetInfo
            .map((val) =>
              val.monthlyReport.reduce((prv, next) => prv + next.revenue, 0)
            )
            .reduce((prv, next) => prv + next, 0);

        case "total_budget_revpar":
          return (
            Math.floor(
              (budgetSheetInfo
                .map((val) =>
                  val.monthlyReport.reduce((prv, next) => prv + next.revenue, 0)
                )
                .reduce((prv, next) => prv + next, 0) /
                pastbudgetData?.reduce(
                  (prv, next) => prv + next.roomsToSell,
                  0
                )) *
                100
            ) / 100
          );

        default:
          const totalRevenue = monthlyReport.reduce(
            (total, item) => total + (item.revenue ?? 0),
            0
          );
          const totalRooms = monthlyReport.reduce(
            (total, item) => total + (item.rooms ?? 0),
            0
          );
          return (
            Math.floor((totalRevenue / Math.max(totalRooms, 1)) * 100) / 100
          );
      }
    },
    [budgetSheetInfo, pastdata, roomAdjustmentData]
  );

  const calVariance = useCallback((val1, val2) => {
    if (val2 === "-") {
      return { val: val1, color: "green" };
    }

    if (val1 > val2) {
      return { val: val1 - val2, color: "green" };
    } else if (val1 < val2) {
      return { val: val1 - val2, color: "red" };
    }

    return { val: 0, color: "green" };
  }, []);

  const TotalColumnPerMonth = useCallback(
    (index, tag) => {
      if (tag === "PastOcc") {
        if (
          pastdata.every(
            (everyValue) =>
              everyValue.monthlyReport[index]?.noOfRoomsBooked === -1
          )
        ) {
          return "-";
        } else {
          return `${
            Math.floor(
              (pastdata?.reduce(
                (prev, next) =>
                  prev +
                  (next?.monthlyReport[index]?.noOfRoomsBooked !== undefined
                    ? next?.monthlyReport[index]?.noOfRoomsBooked !== -1
                      ? next?.monthlyReport[index]?.noOfRoomsBooked
                      : 0
                    : 0),
                0
              ) /
                ((pastbudgetData[index]?.roomsToSell ?? 0) <= 0
                  ? 1
                  : pastbudgetData[index]?.roomsToSell ?? 1)) *
                10000
            ) / 100
          } %`;
        }
      } else if (tag === "PastRoom") {
        if (
          pastdata.every(
            (everyValue) =>
              everyValue.monthlyReport[index]?.noOfRoomsBooked === -1
          )
        ) {
          return "-";
        } else {
          return pastdata?.reduce(
            (prev, next) =>
              prev +
              (next.monthlyReport[index]?.noOfRoomsBooked !== undefined
                ? next.monthlyReport[index]?.noOfRoomsBooked !== -1
                  ? next?.monthlyReport[index]?.noOfRoomsBooked
                  : 0
                : 0),
            0
          );
        }
      } else if (tag === "PastADR") {
        if (
          pastdata.every(
            (everyValue) =>
              everyValue.monthlyReport[index]?.noOfRoomsBooked === -1 &&
              everyValue.monthlyReport[index]?.revenue === -1
          )
        ) {
          return "-";
        } else {
          return (
            Math.floor(
              (pastdata?.reduce(
                (prev, next) =>
                  prev +
                  (next.monthlyReport[index]?.revenue !== undefined
                    ? next?.monthlyReport[index]?.revenue !== -1
                      ? next?.monthlyReport[index]?.revenue
                      : 0
                    : 0),
                0
              ) /
                (pastdata?.reduce(
                  (prev, next) =>
                    prev + (next.monthlyReport[index]?.noOfRoomsBooked ?? 0),
                  0
                ) === 0
                  ? 1
                  : pastdata?.reduce(
                      (prev, next) =>
                        prev +
                        (next.monthlyReport[index]?.noOfRoomsBooked ?? 0),
                      0
                    ))) *
                100
            ) / 100
          );
        }
      } else if (tag === "PastRevenue") {
        if (
          pastdata.every(
            (everyValue) => everyValue.monthlyReport[index]?.revenue === -1
          )
        ) {
          return "-";
        } else {
          return (
            Math.floor(
              pastdata?.reduce(
                (prev, next) =>
                  prev +
                  (next.monthlyReport[index]?.revenue !== undefined
                    ? next?.monthlyReport[index]?.revenue !== -1
                      ? next?.monthlyReport[index]?.revenue
                      : 0
                    : 0),
                0
              ) * 100
            ) / 100
          );
        }
      } else if (tag === "PastRevPar") {
        if (
          pastdata.every(
            (everyValue) => everyValue.monthlyReport[index]?.revenue === -1
          )
        ) {
          return "-";
        } else {
          return (
            Math.floor(
              (pastdata?.reduce(
                (prev, next) =>
                  prev +
                  (next.monthlyReport[index]?.revenue !== undefined
                    ? next?.monthlyReport[index]?.revenue !== -1
                      ? next?.monthlyReport[index]?.revenue
                      : 0
                    : 0),
                0
              ) /
                ((roomAdjustmentData[index]?.roomsToSell ?? 0) <= 0
                  ? 1
                  : roomAdjustmentData[index]?.roomsToSell)) *
                100
            ) / 100
          );
        }
      } else if (tag === "occ") {
        return (
          Math.floor(
            (budgetSheetInfo?.reduce(
              (prev, next) => prev + (next.monthlyReport[index]?.rooms ?? 0),
              0
            ) /
              ((budgetData[index]?.roomsToSell ?? 0) <= 0
                ? 1
                : budgetData[index]?.roomsToSell ?? 1)) *
              10000
          ) / 100
        );
      } else if (tag === "rooms") {
        return budgetSheetInfo.reduce(
          (prev, next) => prev + (next.monthlyReport[index]?.rooms ?? 0),
          0
        );
      } else if (tag === "adr") {
        return (
          Math.floor(
            (budgetSheetInfo.reduce(
              (prev, next) => prev + (next.monthlyReport[index]?.revenue ?? 0),
              0
            ) /
              (budgetSheetInfo.reduce(
                (prev, next) => prev + (next.monthlyReport[index]?.rooms ?? 0),
                0
              ) === 0
                ? 1
                : budgetSheetInfo.reduce(
                    (prev, next) =>
                      prev + (next.monthlyReport[index]?.rooms ?? 0),
                    0
                  ))) *
              100
          ) / 100
        );
      } else if (tag === "revenue") {
        return (
          Math.floor(
            budgetSheetInfo.reduce(
              (prev, next) => prev + (next.monthlyReport[index]?.revenue ?? 0),
              0
            ) * 100
          ) / 100
        );
      } else if (tag === "RevPAR") {
        return (
          Math.floor(
            (budgetSheetInfo.reduce(
              (prev, next) => prev + (next.monthlyReport[index]?.revenue ?? 0),
              0
            ) /
              ((budgetData[index]?.roomsToSell ?? 0) <= 0
                ? 1
                : budgetData[index]?.roomsToSell)) *
              100
          ) / 100
        );
      }
    },
    [budgetSheetInfo, pastbudgetData, pastdata]
  );

  const canUserUnlockBudget = useMemo(() => {
    if (!permission.length) return false;

    return Boolean(permission?.find((permission) => permission.id === 50));
  }, [permission]);

  const canUserEditPastBudget = useMemo(() => {
    if (!permission.length) return false;

    if (lockBudget) return false;

    return Boolean(
      YEAR === new Date().getFullYear() ||
        permission?.find((permission) => permission.id === 51)
    );
  }, [permission, lockBudget, YEAR]);

  const toggleLockBudget = useCallback(async () => {
    const { put } = await authFetch({
      path: `/hotel/${hotelId}/budget-sheet-monthly/${YEAR}/toggle-lock`,
    });

    const { data, response } = await put({
      isLocked: !lockBudget,
    });

    if (response.status === 204) {
      setLockBudget((prv) => !prv);
    } else {
      console.log(response.error);
      setnetworkMsg(data?.messageToUser || "Something went wrong");
      setOpen(true);
    }
  }, [YEAR, authFetch, hotelId, lockBudget]);

  const isBudgetUploaded = useCallback(async () => {
    try {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/check-budget-sheet-monthly/${YEAR}`,
      });
      const { data } = await get();
      if (data.isAvailable !== "" && data.isAvailable === true) {
        setIsBudgetFound(true);
      } else {
        setOpenSelectBar(true);
        setToggle(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [YEAR, authFetch, hotelId]);

  return useMemo(
    () => ({
      totalShow,
      TotalColumnPerMonth,
      calculateTotal,
      budgetSheetInfo,
      setBudgetSheetInfo,
      MONTHS,
      totalRows,
      pastdata,
      isLeftDisabled,
      setIsLeftDisabled,
      isRightDisabled,
      setIsRightDisabled,
      YEAR,
      setSelectYear,
      maxYEAR,
      toggleLeft,
      toggleRight,
      setstateArray,
      stateArray,
      initialState,
      newList,
      haveData,
      budgetData,
      pastbudgetData,
      Loading,
      totalRevenue,
      totalRooms,
      totalRoomsToSell,
      handleChange,
      handleClick,
      HandlePastData,
      HandlePastRevenue,
      HandleBudgetMonth,
      HandleBudgetRevenue,
      PresentOccupancy,
      PastOccupancy,
      HandleBudgetADR,
      HandlePastDataADR,
      HandleBudgetRevPAR,
      HandlePastRevPAR,
      calculateSumRoom,
      calculateSumRevenue,
      calculateSumADR,
      totalRevPAR,
      checked,
      setChecked,
      handleEdit,
      networkMsg,
      open,
      handleCloseStatus,
      saveBudgetSheetData,
      totalMonths,
      budgetingPeriod,
      optionValue,
      setPastYearValue,
      openSelectbar,
      setOpenSelectBar,
      fileHandler,
      fileGetter,
      removeFile,
      filename,
      handleToggle,
      toggle,
      setToggle,
      snackBarColor,
      handleNext,
      isOpenDialog,
      handleCloseDialog,
      handleLeavePage,
      handleDialogSave,
      formEdit,
      setformEdit,
      calVariance,
      lockBudget,
      canUserUnlockBudget,
      canUserEditPastBudget,
      toggleLockBudget,
      openConfirmDialog,
      setOpenConfirmDialog,
      isBudgetUploaded,
      isBudgetFound,
      setIsBudgetFound,
    }),
    [
      totalShow,
      TotalColumnPerMonth,
      calculateTotal,
      budgetSheetInfo,
      setBudgetSheetInfo,
      MONTHS,
      totalRows,
      pastdata,
      isLeftDisabled,
      setIsLeftDisabled,
      isRightDisabled,
      setIsRightDisabled,
      YEAR,
      setSelectYear,
      maxYEAR,
      toggleLeft,
      toggleRight,
      setstateArray,
      stateArray,
      initialState,
      newList,
      haveData,
      budgetData,
      pastbudgetData,
      Loading,
      totalRevenue,
      totalRooms,
      totalRoomsToSell,
      handleChange,
      handleClick,
      HandlePastData,
      HandlePastRevenue,
      HandleBudgetMonth,
      HandleBudgetRevenue,
      PresentOccupancy,
      PastOccupancy,
      HandleBudgetADR,
      HandlePastDataADR,
      HandleBudgetRevPAR,
      HandlePastRevPAR,
      calculateSumRoom,
      calculateSumRevenue,
      calculateSumADR,
      totalRevPAR,
      checked,
      setChecked,
      handleEdit,
      networkMsg,
      open,
      handleCloseStatus,
      saveBudgetSheetData,
      totalMonths,
      budgetingPeriod,
      optionValue,
      setPastYearValue,
      openSelectbar,
      setOpenSelectBar,
      fileHandler,
      fileGetter,
      removeFile,
      filename,
      handleToggle,
      toggle,
      setToggle,
      snackBarColor,
      handleNext,
      isOpenDialog,
      handleCloseDialog,
      handleLeavePage,
      handleDialogSave,
      formEdit,
      setformEdit,
      calVariance,
      lockBudget,
      canUserUnlockBudget,
      canUserEditPastBudget,
      toggleLockBudget,
      openConfirmDialog,
      setOpenConfirmDialog,
      isBudgetUploaded,
      isBudgetFound,
      setIsBudgetFound,
    ]
  );
}
