import { CloseRounded } from "@mui/icons-material";
import { Box, Typography, Table as Tb } from "@mui/material";
import { styled } from "@mui/system";
import { PrimaryButton } from "../../sdk";

export const Mybtn = styled(PrimaryButton)`
  width: 60px;
  height: 20px;
  background: #322277 0% 0% no-repeat padding-box;
  border-radius: 5px;
`;
export const MybtnDisabled = styled(PrimaryButton)`
  width: 60px;
  height: 20px;
  background: #322277 0% 0% no-repeat padding-box;
  opacity: 0.38;
  border-radius: 5px;
`;

export const Model = styled(Box)`
  top: 316px;
  left: 535px;
  width: 484px;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 15px;
`;

export const P = styled(Typography)`
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #000000;
  padding-right: 8px;
`;
export const ButtonSection = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 28px;
`;
export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const DayBDL = styled(Typography)`
  font: normal normal normal 14px/18px Roboto;
  letter-spacing: 0px;
  color: #000;
  margin-right: 24px;
`;
export const Cross = styled(Typography)`
  width: 12px;
  height: 12px;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;
export const Wrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const AddContact = styled(Box)`
  font: normal normal bold 12px/15px Roboto;
  letter-spacing: 0px;
  color: #322277;
  margin-right: 32px;
  &:hover {
    cursor: pointer;
  }
`;
export const EventContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 40px;
`;
export const Label = styled(Typography)`
  margin-right: 10px;
  font: normal normal bold 12px/15px Roboto;
  letter-spacing: 0px;
  color: #000000;
`;
export const Text = styled(Typography)`
  font: normal normal normal 18px/22px Roboto;
  letter-spacing: 0px;
  color: #3d3d3d;
  clear: both;
  display: inline-block;
  overflow: hidden;
  width: inherit;
  text-align: left;
  word-wrap: break-word;
`;

export const TextArea = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;

  overflow: hidden;
`;
export const Dot = styled(Box)`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 5px;
  background: #fff;
  margin-top: 8px;
`;
export const SingleEventContainerSelected = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  &:hover {
    cursor: pointer;
  }
  width: 100%;
  background: #e2e2e2 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 0px 6px 0px 6px;
`;
export const SingleEventContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  &:hover {
    cursor: pointer;
  }
  border-radius: 4px;
  width: 100%;
  padding: 0px 6px 0px 6px;
`;
export const DatePickerSection = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;
export const DeleteEvent = styled(Box)`
  width: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  padding-top: 5px;
`;

export const EventWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items:center &:hover {
    cursor: pointer;
  }
  width: 100%;
`;

export const DatePicker = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  color: #281e53;
`;
export const EventSection = styled(Box)`
  padding-top: 20px;
  width: 100%;
`;

export const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(7, 12vw);
  grid-template-rows: repeat(autofill, 8vh);
  height: 100%;
`;
export const Cell = styled(Box)`
  padding: 8px;
  padding-top: 4px;
  background: white;
  height: 13vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  border-radius: 10px;
  margin: 4px;
  box-shadow: 0px 0px 10px #00000030;
`;
export const Day = styled(Box)`
  font: normal normal normal 18px/22px Roboto;
  letter-spacing: 0px;
  color: #fff;
`;
export const DmdLvl = styled(Typography)`
  font: normal normal normal 18px/22px Roboto;
  letter-spacing: 0px;
  color: #fff;
`;
export const DmdLvlText = styled(Box)`
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Info = styled(Typography)`
  font: normal normal normal 12px/15px Roboto;
  letter-spacing: 0px;
  color: #fff;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const OTBSection = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  width: 100%;
`;

export const OTB = styled(Box)`
  font: normal normal normal 12px/15px Roboto;
  color: #fff;
  display: flex;
  align-items: center;
`;

export const AutomatedDLContainer = styled(Box)`
  border: 1px solid #414141;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AutomatedDL = styled(Typography)`
  text-align: left;
  font: normal normal normal 12px/15px Roboto;
  color: #fff;
`;

export const Description = styled(Box)`
  font: normal normal normal 12px/15px Roboto;
  letter-spacing: 0px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow-wrap: auto;
`;
export const DescriptionMore = styled(Box)`
  font: normal normal normal 12px/13px Roboto;
  letter-spacing: 0px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow-wrap: auto;
`;
export const DotBDL = styled(Box)`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  padding-left: 5px;
  margin-right: 5px;
  background: #fff;
`;

export const SingleEventContainerBDL = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
`;
export const Events = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 75%;
  width: 100%;
`;
export const Week = styled(Box)`
  display: grid;
  grid-template-columns: repeat(7, 12vw);
  grid-template-rows: 3vh;
`;
export const Name = styled(Box)`
  background: transparent;
  font: normal bold normal 14px/16px Roboto;
  color: #1d1d1d;
  text-align: center;
`;
export const Calender = styled(Box)`
  margin: 18px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const EventItem = styled(Box)`
  font: normal normal normal 12px/15px Roboto;
  letter-spacing: 0px;
  color: #fff;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const UpperCell = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30px;
`;
export const LowerCell = styled(Box)`
  font: normal normal normal 12px/15px Roboto;
  letter-spacing: 0px;
  color: #fff;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-wrap: wrap;
  width: 100%;
  overflow: hidden;
  padding-top: 2px;
`;

export const Table = styled(Tb)`
  thead {
    th {
      font: normal normal bold 16px/20px Roboto;
      letter-spacing: 0px;
      color: #130453;
      border-right: 30px solid transparent;
    }
  }
  tbody {
    td {
      text-align: left;
      font: normal normal normal 18px/22px Roboto;
      letter-spacing: 0px;
      color: #fff;
      > div {
        margin: 15px 30px 15px 0;
      }
    }
  }
`;

export const DmdLevel = styled(Box)`
  width: 80px;
`;

export const ModalContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

export const BtnContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 32px;
`;
export const SubmitBtn = styled(PrimaryButton)`
  box-shadow: 0px 3px 6px #0000002f;
  border-radius: 4px;
  width: 88px;
  height: 28px;
  font: normal normal 600 12px/15px Roboto;
  color: #ffffff;
`;
export const CancelBtn = styled(PrimaryButton)`
  background: #d9d9d9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000019;
  border-radius: 4px;
  border: none;
  width: 88px;
  height: 28px;
  margin-right: 25px;
  font: normal normal 600 12px/15px Roboto;
  color: #575757;
`;
export const HeadCFH = styled(Box)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 20px;
`;
export const CrossCFH = styled(CloseRounded)`
  width: 16px;
  height: 16px;
  :hover {
    cursor: pointer;
  }
`;

export const ModalContainerIM = styled(Box)`
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 650px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

export const HeaderIM = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 15%;
`;

export const Title = styled(Typography)`
  text-align: left;
  font: normal normal bold 16px/20px Roboto;
  color: #000000;
`;
export const CrossIM = styled(CloseRounded)`
  width: 16px;
  height: 16px;
  :hover {
    cursor: pointer;
  }
`;

export const LabelIM = styled(Typography)`
  display: flex;
  text-align: left;
  font: normal normal normal 12px/15px Roboto;
  letter-spacing: 0px;
  color: #000;
  padding-right: 4px;
  white-space: nowrap;
`;

export const B = styled(Typography)`
  margin-right: 10px;
  font-weight: bolder;
`;

export const Body = styled(Box)`
  width: 100%;
  height: 50%;
  margin: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Line = styled(Box)`
  border-bottom: 1px solid #110641;
  width: 35px;
  margin: 0 8px;
`;

export const CellIM = styled(Box)`
  padding: 8px;

  height: 150px;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: #6198c8;

  margin: 0 6px;
  border-radius: 10px;
  box-shadow: 0 0 10px #00000030;
`;
export const LCell = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
`;
export const RCell = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
`;

export const EventsIM = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 80%;
`;

export const UpperCellIM = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20%;
`;

export const DayIM = styled(Box)`
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #fff;
`;
export const DmdLvlIM = styled(Typography)`
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #fff;
`;

export const LowerCellIM = styled(Box)`
  font: normal normal normal 12px/15px Roboto;
  letter-spacing: 0px;
  color: #fff;
  padding-top: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  width: 100%;
`;
export const LowerCellLeft = styled(Box)`
  font: normal normal normal 12px/15px Roboto;
  letter-spacing: 0px;
  color: #fff;
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  width: 100%;
`;

export const DescriptionIM = styled(Box)`
  font: normal normal normal 12px/15px Roboto;
  letter-spacing: 0px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow-wrap: break-word;
`;

export const SingleEventContainerIM = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
`;

export const DotIM = styled(Box)`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  padding-left: 5px;
  margin-right: 5px;
  background: #fff;
`;

export const EventItemIM = styled(Box)`
  font: normal normal bold 12px/15px Roboto;
  letter-spacing: 0px;
  color: #fff;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const OTBSectionIM = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20%;
`;

export const OTBIM = styled(Box)`
  font: normal bold normal 12px/15px Roboto;
  color: #fff;
  display: flex;
  align-items: center;
`;

export const AutomatedDLContainerIM = styled(Box)`
  border: 1px solid #414141;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #004283;
`;

export const AutomatedDLIM = styled(Typography)`
  text-align: left;
  font: normal normal bold 12px/15px Roboto;
  color: #fff;
`;
export const Footer = styled(Box)`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
`;

export const IndexContainer = styled(Box)`
  display: flex;

  flex-direction: column;
  align-items: flex-start;
`;
export const Space = styled(Box)`
  padding-left: 55px;
`;

export const Index = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 12px;
`;

export const IndexKey = styled(Box)`
  border: 1px solid #707070;
  opacity: 1;
  width: 25px;
  height: 25px;
  margin-right: 8px;
`;
