import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  styled,
  tableCellClasses,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { CalenderIcon } from "..";
import DatePicker from "react-modern-calendar-datepicker";
import ConfirmationDialog from "./ConfirmationDialog";

export default function PushRatesDialog({
  open,
  handleClose,
  pushRateFromDate,
  setPushRateFromDate,
  pushRateToDate,
  setPushRateToDate,
  pushRatesToChannel,
  recommendedData,
  discount,
}) {
  const [step, setStep] = useState(1);
  const [isChecked, setIsChecked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(
    false
  );

  const minimumDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };

  useEffect(() => {
    if (pushRateFromDate) {
      setPushRateToDate(pushRateFromDate);
    }
  }, [pushRateFromDate, setPushRateToDate]);

  const formatInputValueDate = (date) => {
    if (date) {
      const value =
        `${date.day < 10 ? "0" + date.day : date.day}` +
        "/" +
        `${date.month < 10 ? "0" + date.month : date.month}` +
        "/" +
        `${date.year}`;
      return value;
    }
  };

  const renderCustomInputFromDate = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValueDate(pushRateFromDate)}
      style={{
        textAlign: "left",
        fontSize: "14px",
        borderRadius: "10px",
        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );

  const renderCustomInputToDate = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValueDate(pushRateToDate)}
      style={{
        textAlign: "left",
        fontSize: "14px",
        borderRadius: "10px",
        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );

  const handleCheckboxChange = useCallback(
    (event) => {
      if (event.target.checked) {
        const lastDay = new Date(
          pushRateFromDate.year,
          pushRateFromDate.month,
          0
        ); // Get last day of the month
        setPushRateToDate({ ...pushRateFromDate, day: lastDay.getDate() });
      }
    },
    [pushRateFromDate, setPushRateToDate]
  );

  function formatDate(date) {
    const year = date.year;
    const month = date.month.toString().padStart(2, "0");
    const day = date.day.toString().padStart(2, "0");
    return `${year}-${month}-${day}T00:00:00Z`;
  }
  const getTableData = useCallback(() => {
    const startIndex = recommendedData?.pricingDetails?.findIndex(
      (item) => item.date === formatDate(pushRateFromDate)
    );
    const endIndex = recommendedData?.pricingDetails?.findIndex(
      (item) => item.date === formatDate(pushRateToDate)
    );
    const slicedData = recommendedData?.pricingDetails?.slice(
      startIndex,
      endIndex + 1
    );

    if (slicedData && slicedData.length > 0) {
      const newData = slicedData.map((item) => {
        return {
          ...item,
          pushRate: Math.round((item.hotelPrice * 100) / discount),
        };
      });
      return setTableData(newData);
    }

    setTableData(slicedData);
  }, [
    discount,
    pushRateFromDate,
    pushRateToDate,
    recommendedData.pricingDetails,
  ]);

  const handleChangePushRate = useCallback(
    (event, index) => {
      const { value } = event.target;
      const positiveNumberRegex = /^(?:[0-9]+(?:\.[0-9]*)?|\.[0-9]+)?$/;

      if (positiveNumberRegex.test(value)) {
        let newValue = value;
        if (value === "") {
          newValue = "0";
        }

        const updatedTableData = [...tableData];
        updatedTableData[index] = {
          ...updatedTableData[index],
          pushRate: newValue,
        };
        setTableData(updatedTableData);
      }
    },
    [tableData, setTableData]
  );

  return (
    <StyledChannelDialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="content">
        Push Rates to Channel manager
      </DialogTitle>

      <DialogContent className="content">
        {step === 1 && (
          <Stack gap="24px" alignItems="center">
            <Stack direction="row" gap="40px">
              <label>
                <StyledCalenderWrapper>
                  <DatePicker
                    value={pushRateFromDate}
                    onChange={setPushRateFromDate}
                    renderInput={renderCustomInputFromDate}
                    calendarClassName="myCustomCalendar"
                    minimumDate={minimumDate}
                  />
                  <CalenderIcon />
                </StyledCalenderWrapper>
              </label>

              <label>
                <StyledCalenderWrapper>
                  <DatePicker
                    value={pushRateToDate}
                    onChange={setPushRateToDate}
                    renderInput={renderCustomInputToDate}
                    calendarClassName="myCustomCalendar"
                    minimumDate={pushRateFromDate}
                  />
                  <CalenderIcon />
                </StyledCalenderWrapper>
              </label>
            </Stack>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: "#163A90",
                    "&.Mui-checked": {
                      color: "#163A90",
                    },
                  }}
                  checked={isChecked}
                  onChange={(e) => {
                    setIsChecked(e.target.checked);
                    handleCheckboxChange(e);
                  }}
                />
              }
              label="Select complete month"
            />

            <Button
              variant="contained"
              onClick={() => {
                setStep(2);
                getTableData();
              }}
            >
              Next
            </Button>
          </Stack>
        )}
        {step === 2 && (
          <>
            <Box sx={{ maxHeight: "500px", overflow: "auto", mb: "8px" }}>
              <Table>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell>Recommended Rates</StyledTableCell>
                    <StyledTableCell>Push Rates</StyledTableCell>
                    <StyledTableCell>Discount</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {tableData?.map((data, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        {data.date.slice(0, 10)}
                      </StyledTableCell>
                      <StyledTableCell>{data?.hotelPrice}</StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          variant="standard"
                          value={data?.pushRate}
                          onChange={(e) => handleChangePushRate(e, index)}
                          type="number"
                        />
                      </StyledTableCell>
                      <StyledTableCell>{discount}%</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Button
              variant="contained"
              onClick={() => setIsConfirmationDialogOpen(true)}
            >
              Submit
            </Button>
          </>
        )}
      </DialogContent>

      <CloseIcon className="closeButton" onClick={handleClose} />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        handleCloseConfirmation={() => {
          setIsConfirmationDialogOpen(false);
          handleClose();
        }}
        pushRatesToChannel={pushRatesToChannel}
        tableData={tableData}
      />
    </StyledChannelDialog>
  );
}

const StyledChannelDialog = styled(Dialog)(() => ({
  ".MuiPaper-root": {
    overflow: "visible",
  },
  ".closeButton": {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
  },
  ".content": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "visible",
  },
}));

const StyledCalenderWrapper = styled(Box)((theme) => ({
  borderRadius: "8px",
  boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
  "&:hover>*": {
    color: "#ffffff",
    ">input": {
      backgroundColor: "#306fbc",
      color: "#ffffff !important",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#130453",
    textAlign: "center",
    font: "normal normal bold 16px/20px Roboto",
    padding: "16px",
    letterSpacing: "0px",
    width: "25%",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "center",
    width: "25%",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
