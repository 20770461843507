import { CalendarToday } from "@mui/icons-material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import React, { useEffect, useState } from "react";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { useHistory, useParams } from "react-router-dom";
import { styled } from "@mui/system";
import { getISODate, useAuth } from "../../sdk";
import { HeaderCard } from "./Styles";
import { Box, Typography } from "@mui/material";

export const PickUpPage = styled(Box)`
  padding: 60px 30px 0px 100px;
  width: 100%;
`;

export const CalenderIcon = styled(CalendarToday)`
  position: absolute;

  width: 20px;
  height: 22px;
  color: #306fbc;
  z-index: 101;
  margin-left: 60px;
  margin-top: -1px;
  cursor: pointer;
`;

export const CalenderIconToDate = styled(CalendarToday)`
  position: absolute;

  width: 20px;
  height: 22px;
  color: #306fbc;
  z-index: 101;
  margin-left: 63px;
  margin-top: -1px;
  cursor: pointer;
`;

const DATE = styled(Box)`
  box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 0px 12px 0px 16px;
`;

export const LabelNew = styled(Typography)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #281e53;
  padding-right: 22px;
`;

export const Header = styled(Box)`
  margin: 26px 0;
  width: 100%;
`;

export const PickUp = ({ setPageHeader }) => {
  useEffect(() => {
    setPageHeader("PickUp");
  }, [setPageHeader]);

  const { hotelId, FROMDATE, TODATE } = useParams();
  const history = useHistory();

  const [fromDate, setFromDate] = useState(() => {
    if (!isNaN(new Date(FROMDATE).getTime())) {
      const [year, mon, day] = FROMDATE.split("-");

      if (year && mon && day) {
        if (day && day.length === 1) {
          const validDate = [year, mon, "0" + day].join("-");
          return new Date(validDate);
        } else {
          return new Date(FROMDATE);
        }
      } else {
        return new Date(new Date().setDate(new Date().getDate() - 1));
      }
    } else {
      return new Date(new Date().setDate(new Date().getDate() - 1));
    }
  });

  const [toDate, setToDate] = useState(() => {
    if (!isNaN(new Date(TODATE).getTime())) {
      const [year, mon, day] = TODATE.split("-");

      if (year && mon && day) {
        if (day && day.length === 1) {
          const validDate = [year, mon, "0" + day].join("-");
          return new Date(validDate);
        } else {
          return new Date(TODATE);
        }
      } else {
        return new Date();
      }
    } else {
      return new Date();
    }
  });

  const { token, permission } = useAuth();
  let first = getISODate(fromDate);
  const fromDateDefaultValue = {
    year: new Date(first).getFullYear(),
    month: new Date(first).getMonth(),
    day: new Date(first).getDate(),
  };
  let second = getISODate(toDate);
  const toDateDefaultValue = {
    year: new Date(second).getFullYear(),
    month: new Date(second).getMonth(),
    day: new Date(second).getDate(),
  };

  const [fromDateNew, setFromDateNew] = useState(fromDateDefaultValue);
  const [toDateNew, settoDateNew] = useState(toDateDefaultValue);

  const [maximumDate, setMaximumDate] = useState(fromDateDefaultValue);

  const formatInputValue = () => {
    if (fromDateNew) {
      const value =
        `${fromDateNew.day < 10 ? "0" + fromDateNew.day : fromDateNew.day}` +
        "/" +
        `${
          fromDateNew.month < 10 ? "0" + fromDateNew.month : fromDateNew.month
        }` +
        "/" +
        `${fromDateNew.year}`;
      return value;
    }
  };

  const formatInputValueToDate = () => {
    if (toDateNew) {
      const value =
        `${toDateNew.day < 10 ? "0" + toDateNew.day : toDateNew.day}` +
        "/" +
        `${toDateNew.month < 10 ? "0" + toDateNew.month : toDateNew.month}` +
        "/" +
        `${toDateNew.year}`;
      return value;
    }
  };

  const maximumToDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };

  useEffect(() => {
    if (token && hotelId) {
      window.scrollTo(0, 0);
    } else {
      return;
    }
  }, [token, hotelId]);

  useEffect(() => {
    history.replace(
      `/hotel/${hotelId}/forecast/pickup/${
        getISODate(fromDate).split("T")[0]
      }/${getISODate(toDate).split("T")[0]}`
    );
  }, [fromDate, toDate, hotelId, history]);

  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValue()}
      style={{
        textAlign: "left",

        fontSize: "14px",

        borderRadius: "10px",

        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
      }}
      className="my-custom-input-class"
    />
  );

  const renderCustomInputToDate = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValueToDate()}
      style={{
        textAlign: "left",

        fontSize: "14px",

        borderRadius: "10px",

        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );

  return (
    <PickUpPage style={{ background: "#fff", minHeight: "100vh" }}>
      {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
      <Header>
        <DATE>
          <HeaderCard>
            <LabelNew style={{ paddingRight: "0px" }}>From</LabelNew>
            <DatePicker
              value={fromDateNew}
              onChange={setFromDateNew}
              renderInput={renderCustomInput}
              calendarClassName="myCustomCalendar"
              maximumDate={maximumDate}
            />
            <CalenderIcon />
          </HeaderCard>
          <HeaderCard>
            <LabelNew>To</LabelNew>
            <DatePicker
              value={toDateNew}
              onChange={settoDateNew}
              renderInput={renderCustomInputToDate}
              calendarClassName="myCustomCalendar"
              maximumDate={maximumToDate}
            />
            <CalenderIconToDate />
          </HeaderCard>
        </DATE>
      </Header>
      {/* </LocalizationProvider> */}
    </PickUpPage>
  );
};
