import { useCallback, useEffect, useState } from "react";
import { onUniqueProp, useAuth } from "../../sdk";
const initialValue = [false, false, false, false, false, false];
const DemandLevels = [
  {
    symbol: "EH",
    value: 0,
  },
  {
    symbol: "H+",
    value: 0,
  },
  {
    symbol: "H",
    value: 0,
  },
  {
    symbol: "M",
    value: 0,
  },
  {
    symbol: "L",
    value: 0,
  },
  {
    symbol: "D",
    value: 0,
  },
];
export function useDemandLevel(hotelId, copyFromHotelId, DL, isManual) {
  const [demandLevels, setDemandLevels] = useState([]);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isCityDLEditMode, setIsCityDLInEditMode] = useState(false);
  const [isHotelDLEditMode, setIsHotelDLInEditMode] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [open, setOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [editHotelDemandLevel, setHotelDemandLevel] = useState([]);
  const [editCityDemandLevel, setCityDemandLevel] = useState();
  const [checkCityDLValidation, setCityDLValidation] = useState(false);
  const [checkHotelDLValidation, setHotelDLValidation] = useState(false);
  const demandLevelType = isManual ? "city-demand-level" : "hotel-demand-level";

  const handleCloseStatus = () => {
    setOpen(false);
  };

  const [networkMsg, setnetworkMsg] = useState(null);
  const { token, authFetch } = useAuth();
  useEffect(() => {
    if (!token) return;
    refreshDemandLevels();
  }, [token, hotelId]);

  useEffect(() => {
    if (isSaved) {
      updateDemandLevels();
    } else if (isCancelled) {
      refreshDemandLevels();
    }
  }, [isInEditMode]);
  useEffect(() => {
    if (isCityDLEditMode) {
      const allValidEntries = demandLevels.every(isValidCityDL);

      if (allValidEntries) {
        setCityDLValidation(false);
      } else setCityDLValidation(true);
    }
  }, [isCityDLEditMode, demandLevels]);

  useEffect(() => {
    if (isHotelDLEditMode) {
      const allValidEntries = demandLevels.every(isValidHotelDL);
      if (allValidEntries) {
        setHotelDLValidation(false);
      } else setHotelDLValidation(true);
    }
  }, [isHotelDLEditMode, demandLevels]);
  function isIterable(obj) {
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === "function";
  }

  useEffect(() => {
    if (copyFromHotelId === null && !DL) return;
    if (DL) refreshDemandLevels(copyFromHotelId);
  }, [DL]);

  function isValidCityDL(demandLevels) {
    return demandLevels.value !== "" && demandLevels.value !== null;
  }
  function isValidHotelDL(demandLevels) {
    return demandLevels.value !== "" && demandLevels.value !== null;
  }

  async function refreshDemandLevels(anotherHotelId = false) {
    const hotelIdToUse = anotherHotelId ? anotherHotelId : hotelId;
    setLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelIdToUse}/${demandLevelType}`,
    });
    const { data, error } = await get();
    if (error) {
      setnetworkMsg(
        isManual ? "Can't Fetch Demand Level" : "Can't Fetch City Demand Level"
      );
      setOpen(true);
      console.log(error);
    }
    setIsCancelled(false);
    if (data && isIterable(data)) {
      setDemandLevels(
        [...data, ...DemandLevels].filter(onUniqueProp("symbol"))
      );
      setHotelDemandLevel(
        [
          ...new Array(
            [...data, ...DemandLevels].filter(onUniqueProp("symbol")).length
          ),
        ].map(() => false)
      );
      setCityDemandLevel(
        [
          ...new Array(
            [...data, ...DemandLevels].filter(onUniqueProp("symbol")).length
          ),
        ].map(() => false)
      );
    } else {
      setDemandLevels([...DemandLevels].filter(onUniqueProp("symbol")));
      setHotelDemandLevel(
        [
          ...new Array([...DemandLevels].filter(onUniqueProp("symbol")).length),
        ].map(() => false)
      );
      setCityDemandLevel(
        [
          ...new Array([...DemandLevels].filter(onUniqueProp("symbol")).length),
        ].map(() => false)
      );
    }
    if (anotherHotelId) {
      setIsInEditMode(true);
      setIsCityDLInEditMode(true);
      setIsHotelDLInEditMode(true);
    }
    setLoading(false);
  }
  async function updateDemandLevels() {
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/${demandLevelType}/all`,
    });
    const { error, data, response } = await post(
      demandLevels.map((dl) => {
        if (!Boolean(dl.value)) {
          return {
            ...dl,
            value: null,
          };
        } else {
          return dl;
        }
      })
    );
    if (!response?.ok) {
      setnetworkMsg(
        data?.messageToUser || isManual
          ? "Can't Update Demand Level"
          : "Can't Update City Demand Level"
      );
      setOpen(true);
      console.log(error);
    }
    setIsSaved(false);
    await refreshDemandLevels();
  }

  function changeHandler(index, key, value) {
    setDemandLevels((prevState) => {
      return prevState.map((row, idx) =>
        idx === index
          ? {
              ...row,
              [key]: value,
            }
          : row
      );
    });
  }
  const handleEditChange = (index) => {
    const result = editHotelDemandLevel.map((data, pos) => {
      if (pos === index) {
        return (editHotelDemandLevel[index] = !editHotelDemandLevel[index]);
      } else {
        return editHotelDemandLevel[pos];
      }
    });
    const checkEditMode = result.every((d) => d === false);
    if (checkEditMode) setIsHotelDLInEditMode(false);
    else setIsHotelDLInEditMode(true);
    setHotelDemandLevel(result);
  };

  const handleEditCityDLChange = useCallback(
    (indexNew) => {
      const result = editCityDemandLevel.map((data, pos) => {
        if (pos === indexNew) {
          return (editCityDemandLevel[indexNew] = !editCityDemandLevel[
            indexNew
          ]);
        } else {
          return editCityDemandLevel[pos];
        }
      });
      const checkEditMode = result.every((d) => d === false);
      if (checkEditMode) setIsCityDLInEditMode(false);
      else setIsCityDLInEditMode(true);
      setCityDemandLevel(result);
    },
    [editCityDemandLevel]
  );

  return {
    demandLevels,
    dlIsInEditMode: isInEditMode,
    setDlIsInEditMode: setIsInEditMode,
    dlIsCancelled: isCancelled,
    dlSetIsCancelled: setIsCancelled,
    dlIsSaved: isSaved,
    dlSetIsSaved: setIsSaved,
    dlChangeHandler: changeHandler,
    dlopen: open,
    dlhandleCloseStatus: handleCloseStatus,
    dlnetworkMsg: networkMsg,
    dlLoading: Loading,
    editHotelDemandLevel,
    handleEditChange,
    isCityDLEditMode,
    isHotelDLEditMode,
    handleEditCityDLChange,
    editCityDemandLevel,
    setIsHotelDLInEditMode,
    setIsCityDLInEditMode,
    checkCityDLValidation,
    checkHotelDLValidation,
  };
}
