import {
  Paper,
  Select,
  Snackbar,
  SnackbarContent,
  Table,
  TableRow,
  Modal,
  TableBody,
  TableCell,
  TableHead,
  Box,
  Stack,
  Tooltip,
} from "@mui/material";
import { Publish } from "@mui/icons-material";
import React, { useEffect } from "react";
import { styled } from "@mui/system";
import {
  getDateDDMM,
  getDay,
  LoadingPage,
  MonthPicker,
  Nodata,
  useAuth,
} from "../../sdk";
import usePastForecast from "../hooks/usePastForecast";
import { ModelBody } from "./UploadModal.js";
import { PreciumDisabledPage } from "../../sdk/components/PreciumDiabledPage";

export const TRow = styled(TableRow)`
  &.MuiTableRow-root {
    padding: 10px 0;
    &:nth-of-type(odd) {
      background: #fff;
      border: 1px solid rgba(48, 111, 188, 1);
    }
  }
`;
export const TCell = styled(TableCell)`
  &.MuiTableCell-root {
    width: 100px;
    font: normal normal normal 24px/28px Roboto;
    letter-spacing: 0px;
    color: #000;
    padding: 0px 0px;
    text-align: ${(props) => (props.isInEditMode ? "left" : "left")};
    text-transform: capitalize;
    white-space: nowrap;
  }
`;
export const Tabhead = styled(TableCell)`
  &.MuiTableCell-root {
    text-align: left;
    font: normal normal bold 14px/23px Roboto;
    letter-spacing: 0px;
    color: #fff;
    padding: 0px 0px;
    border: none;
    background: rgba(48, 111, 188, 1);
    text-transform: capitalize;
    height: 56px;
  }
`;
export const THead = styled(TableHead)`
  &.MuiTableHead-root {
    tr.MuiTableRow-root {
      background: rgba(48, 111, 188, 1);
      width: 100%;
    }
  }
`;
const DateContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const Container = styled(Box)`
  padding: 80px 20px 0px 80px;
  width: 100%;
`;
const Header = styled(Box)`
  font: normal normal bold 14px/18px Roboto;
  padding: 4px 8px;
  height: 36px;
  display: flex;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg.MuiSelect-icon {
    color: white;
  }
`;
const Cell = styled(Box)`
  font: normal normal normal 14px/20px Roboto;
  padding: 4px 8px;
  height: 36px;
  width: 100px;
  border-bottom: 1.5px solid #1106411a;
  text-align: center;
`;
const MonthPickerContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal bold 22px/28px Roboto;
`;
export const AppBar = styled(Box)`
  top: 76px;
  left: 80px;
  height: 52px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  margin-bottom: 20px;
  padding: 0 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const TableWrapper = styled(Box)`
  height: calc(100vh - 160px);
  margin-bottom: 20px;
  overflow: auto;
  background-color: #ffffff;
`;

export const HorizontalLine = styled(Box)`
  background-color: #110641;
  width: 100%;
`;

export const PickupContainer = styled(Box)`
  display: flex;
  align-items: center;
  outline: 1px solid #1106411a;
`;
export const PickupName = styled(Box)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  white-space: wrap;
  font: normal normal bold 16px/20px Roboto;
`;
export const HeaderContainer = styled(Box)`
  flex: 1;
  outline: 1px solid #1106411a;
`;
export const UploadContainer = styled(Box)`
  display: flex;
  justify-content: space-around;
  background: #ffffff 0% 0% no-repeat padding-box;
  cursor: pointer;
  font: normal normal bold 16px/20px Roboto;
  align-items: flex-end;
`;
export const Options = styled(Select)`
  .MuiSelect-select.MuiSelect-select {
    font: normal normal bold 14px/18px Roboto;
    min-width: 180px;
    color: white;
  }
`;
const TextOnlyTooltipSegment = styled(Tooltip)(({ theme }) => ({
  ".tooltip": {
    color: "#fff",
    font: "normal normal normal 16px/20px Roboto",
    boxShadow: "0px 4px 8px #00000035",
    background: "#110641 0% 0% no-repeat padding-box",
    minWidth: 300,
    textAlign: "left",
    marginLeft: "-10px",
  },
  ".arrow": {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "#110641",
  },
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  ".sticky": {
    position: "sticky",
    left: 0,
    background: "#fff",
    zIndex: "8",
  },
  ".stickyRight": {
    position: "sticky",
    left: "53.5px",
    background: "#fff",
    zIndex: "8",
  },
}));

export const PastForecast = ({ setPageHeader }) => {
  const {
    forecastSheetData,
    comparitiveDate,
    setComparitiveDate,
    Loading,
    dates,
    setOpen,
    networkMsg,
    open,
    fileHandler,
    fileGetter,
    setOpenSelectBar,
    removeFile,
    filename,
    handleToggle,
    toggle,
    openSelectbar,
  } = usePastForecast();
  useEffect(() => {
    setPageHeader("Forecast Past Data");
    return () => {
      setPageHeader("");
    };
  }, []);

  const { currentHotel } = useAuth();

  return currentHotel?.isPreciumEnabled ? (
    <StyledContainer>
      {/* <AppBar> */}
      <Stack
        direction={"row"}
        spacing={4}
        justifyContent="space-between"
        style={{ marginBottom: "10px" }}
      >
        <Paper
          style={{
            height: "40px",
            width: "200px",
            marginLeft: "40px",
            padding: "6px 0 0 6px",
          }}
        >
          <Stack direction={"row"}>
            <MonthPickerContainer>
              <MonthPicker
                value={comparitiveDate}
                onChange={setComparitiveDate}
              />
            </MonthPickerContainer>
          </Stack>
        </Paper>
        <Paper
          style={{
            height: "40px",
            width: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(48, 111, 188, 1)",
          }}
        >
          <Stack
            direction={"row"}
            onClick={() => {
              setOpenSelectBar(true);
            }}
            alignItems="center"
            spacing={2}
            style={{
              color: "#fff",
              fontFamily: "Roboto ,Helvetica ,Arial,sans-serif",
              fontSize: "14px",
            }}
          >
            <Box>Upload</Box>
            <Publish style={{ color: "#fff" }} />
          </Stack>
        </Paper>
      </Stack>
      {/* </AppBar> */}
      {!Loading && forecastSheetData != null ? (
        <>
          <TableWrapper>
            <Table stickyHeader>
              <THead>
                <TRow>
                  <Tabhead
                    style={{
                      padding: "4px 8px",
                      zIndex: "10",
                      color: "#fff",
                      borderRight: "1.5px solid rgba(48, 111, 188, 1)",
                    }}
                    rowSpan={2}
                    className="sticky"
                  >
                    Items
                  </Tabhead>
                  <Tabhead
                    style={{
                      padding: "4px 8px",
                      zIndex: "10",
                      textAlign: "center",
                      color: "#fff",
                    }}
                    rowSpan={2}
                    className="stickyRight"
                  >
                    Events
                  </Tabhead>

                  {dates?.map((date, index) => {
                    return (
                      <Tabhead key={index}>
                        <DateContainer>
                          <TCell
                            style={{
                              padding: "4px 8px",
                              border: "none",
                              font: "normal normal 14px/28px Roboto",
                              textAlign: "center",
                              background: "rgba(48, 111, 188, 1)",
                              color: "#fff",
                            }}
                          >
                            {getDateDDMM(date)}
                            <br />
                            {getDay(date)}
                          </TCell>
                        </DateContainer>
                      </Tabhead>
                    );
                  })}
                  <Tabhead
                    style={{
                      padding: "4px 8px",
                      zIndex: "10",
                      textAlign: "center",
                      color: "#fff",
                    }}
                  >
                    Total
                  </Tabhead>
                </TRow>
              </THead>
              <TableBody>
                {forecastSheetData?.map((item, segmentIndex) => {
                  // console.log(item)
                  return (
                    <React.Fragment key={segmentIndex}>
                      <TRow key={segmentIndex}>
                        <TextOnlyTooltipSegment
                          disableFocusListener
                          disableTouchListener
                          title={item.description ? item.description : ""}
                          placement="right"
                          arrow
                          style={{ cursor: "default" }}
                        >
                          <TCell
                            style={{
                              padding: "0 10px",
                              font: "normal normal bold 14px/23px Roboto",
                              borderBottom: "2px solid #1106411a",
                              borderLeft: "1.5px solid #1106411a",
                              borderRight: "1px solid #1106411a",
                              height: "108px",
                            }}
                            className="sticky"
                          >
                            <p
                              style={{
                                writingMode: "tb-rl",
                                transform: "rotate(-180deg)",
                                margin: "auto",
                                maxHeight: "100px",
                                textOverflow: "ellipsis",
                                cursor: "default",
                              }}
                            >
                              {item.segmentName}
                            </p>
                          </TCell>
                        </TextOnlyTooltipSegment>
                        <TCell
                          className="stickyRight"
                          style={{
                            borderLeft: "1.5px solid #1106411a",
                            borderRight: "2.5px solid #1106411a",
                          }}
                        >
                          <Header
                            style={{
                              borderBottom: "1.5px solid #1106411a",
                              display: "flex",
                              justifyContent: "start",
                            }}
                          >
                            Room Nights
                          </Header>
                          <Header
                            style={{
                              borderBottom: "1.5px solid #1106411a",
                              display: "flex",
                              justifyContent: "start",
                            }}
                          >
                            ADR
                          </Header>
                          <Header
                            style={{
                              borderBottom: "1.5px solid #1106411a",
                              display: "flex",
                              justifyContent: "start",
                            }}
                          >
                            Revenue
                          </Header>
                        </TCell>
                        {item?.arr?.map((budget, index) => {
                          return (
                            <TCell
                              key={index}
                              style={{
                                background:
                                  segmentIndex % 2 === 0
                                    ? "#fff"
                                    : "rgb(214,226,242)",
                              }}
                            >
                              <TransFormRoomNights
                                data={budget.actualRoomsBooked}
                              />
                              <TransForm data={budget.averageRoomRate} />
                              <TransFormRevenue
                                roomNights={budget.actualRoomsBooked}
                                arr={budget.averageRoomRate}
                              />
                            </TCell>
                          );
                        })}
                        <TCell
                          style={{
                            background:
                              segmentIndex % 2 === 0
                                ? "#fff"
                                : "rgb(214,226,242)",
                            borderLeft: "2px solid rgb(231,230,236)",
                          }}
                        >
                          <Cell>
                            {forecastSheetData[segmentIndex]?.arr.reduce(
                              (prev, next) =>
                                prev +
                                (next.actualRoomsBooked === -1
                                  ? 0
                                  : next.actualRoomsBooked),
                              0
                            )}
                          </Cell>
                          <Cell>
                            {forecastSheetData[segmentIndex]?.arr
                              .reduce(
                                (prev, next) =>
                                  prev +
                                  (next.averageRoomRate === -1
                                    ? 0
                                    : next.averageRoomRate),
                                0
                              )
                              .toFixed(2)}
                          </Cell>
                          <Cell>
                            {forecastSheetData[segmentIndex]?.arr
                              .reduce(
                                (prev, next) =>
                                  prev +
                                  (next.actualRoomsBooked === -1
                                    ? 0
                                    : next.actualRoomsBooked
                                  ).toFixed(2) *
                                    (next.averageRoomRate === -1
                                      ? 0
                                      : next.averageRoomRate
                                    ).toFixed(2),
                                0
                              )
                              .toFixed(2)}
                          </Cell>
                        </TCell>
                      </TRow>
                    </React.Fragment>
                  );
                })}

                <TRow>
                  <TextOnlyTooltipSegment
                    disableFocusListener
                    disableTouchListener
                    placement="right"
                    arrow
                    style={{ cursor: "default" }}
                  >
                    <TCell
                      style={{
                        padding: "0 10px",
                        font: "normal normal bold 14px/23px Roboto",
                        borderBottom: "2px solid #1106411a",
                        borderLeft: "1.5px solid #1106411a",
                        borderRight: "1px solid #1106411a",
                        height: "108px",
                      }}
                      className="sticky"
                    >
                      <p
                        style={{
                          writingMode: "tb-rl",
                          transform: "rotate(-180deg)",
                          margin: "auto",
                          maxHeight: "100px",
                          textOverflow: "ellipsis",
                          cursor: "default",
                        }}
                      >
                        Total
                      </p>
                    </TCell>
                  </TextOnlyTooltipSegment>
                  <TCell
                    className="stickyRight"
                    style={{
                      borderLeft: "1.5px solid #1106411a",
                      borderRight: "2.5px solid #1106411a",
                    }}
                  >
                    <Header
                      style={{
                        borderBottom: "1.5px solid #1106411a",
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      Room Nights
                    </Header>
                    <Header
                      style={{
                        borderBottom: "1.5px solid #1106411a",
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      ADR
                    </Header>
                    <Header
                      style={{
                        borderBottom: "1.5px solid #1106411a",
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      Revenue
                    </Header>
                  </TCell>
                  {forecastSheetData?.map((item, segmentIndex) => {
                    return (
                      <TCell>
                        <Cell>
                          {forecastSheetData
                            ?.reduce(
                              (prev, next) =>
                                prev +
                                (next.arr[segmentIndex].actualRoomsBooked === -1
                                  ? 0
                                  : next.arr[segmentIndex].actualRoomsBooked),
                              0
                            )
                            .toFixed(2)}
                        </Cell>
                        <Cell>
                          {forecastSheetData
                            ?.reduce(
                              (prev, next) =>
                                prev +
                                (next.arr[segmentIndex].averageRoomRate === -1
                                  ? 0
                                  : next.arr[segmentIndex].averageRoomRate),
                              0
                            )
                            .toFixed(2)}
                        </Cell>
                        <Cell>
                          {forecastSheetData
                            ?.reduce(
                              (prev, next) =>
                                prev +
                                (next.arr[segmentIndex].actualRoomsBooked === -1
                                  ? 0
                                  : next.arr[segmentIndex].actualRoomsBooked) *
                                  (next.arr[segmentIndex].averageRoomRate === -1
                                    ? 0
                                    : next.arr[segmentIndex].averageRoomRate),
                              0
                            )
                            .toFixed(2)}
                        </Cell>
                      </TCell>
                    );
                  })}
                  <TCell style={{ borderLeft: "2px solid rgb(231,230,236)" }}>
                    <Cell>
                      {forecastSheetData?.reduce(
                        (prev, next) =>
                          prev +
                          next.arr.reduce(
                            (prev, next) =>
                              prev +
                              (next.actualRoomsBooked === -1
                                ? 0
                                : next.actualRoomsBooked),
                            0
                          ),
                        0
                      )}
                    </Cell>
                    <Cell>
                      {forecastSheetData
                        ?.reduce(
                          (prev, next) =>
                            prev +
                            next.arr.reduce(
                              (prev, next) =>
                                prev +
                                (next.averageRoomRate === -1
                                  ? 0
                                  : next.averageRoomRate),
                              0
                            ),
                          0
                        )
                        .toFixed(2)}
                    </Cell>
                    <Cell>
                      {forecastSheetData
                        ?.reduce(
                          (prev, next) =>
                            prev +
                            next.arr.reduce(
                              (prev, next) =>
                                prev +
                                (next.actualRoomsBooked === -1
                                  ? 0
                                  : next.actualRoomsBooked) *
                                  (next.averageRoomRate === -1
                                    ? 0
                                    : next.averageRoomRate),
                              0
                            ),
                          0
                        )
                        .toFixed(2)}
                    </Cell>
                  </TCell>
                </TRow>
              </TableBody>
            </Table>
          </TableWrapper>
        </>
      ) : forecastSheetData === null && Loading ? (
        <LoadingPage />
      ) : (
        <Nodata />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        {open && (
          <SnackbarContent
            style={{
              backgroundColor:
                networkMsg === "Upload Successful" ? "#228b22" : "#CA3433",
            }}
            message={networkMsg}
          />
        )}
      </Snackbar>
      <Modal open={openSelectbar} onClose={() => setOpenSelectBar(false)}>
        <div>
          <ModelBody
            fileHandler={fileHandler}
            fileGetter={fileGetter}
            setOpenSelectBar={setOpenSelectBar}
            removeFile={removeFile}
            filename={filename}
            handleToggle={handleToggle}
            toggle={toggle}
          />
        </div>
      </Modal>
    </StyledContainer>
  ) : (
    <PreciumDisabledPage />
  );
};

function TransForm({ data }) {
  if (data === -1) {
    return <Cell>-</Cell>;
  }
  return <Cell>{parseFloat(data).toFixed(2)}</Cell>;
}

function TransFormRoomNights({ data }) {
  if (data === -1) {
    return <Cell>-</Cell>;
  }
  return <Cell>{parseInt(data)}</Cell>;
}

function TransFormRevenue({ arr, roomNights }) {
  if (arr === -1 || roomNights === -1) {
    return <Cell>-</Cell>;
  }
  return <Cell>{parseFloat(arr * roomNights).toFixed(2)}</Cell>;
}
