import {
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  Snackbar,
  SnackbarContent,
  Stack,
  Tab,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CalendarToday,
  ChevronLeft,
  KeyboardBackspace,
  Schedule,
} from "@mui/icons-material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { useHistory, useParams } from "react-router-dom";
import { styled } from "@mui/system";
import {
  ErrorPage,
  getISODate,
  LoadingPage,
  NoAccess,
  Nodata,
  useAuth,
} from "../sdk";

import {
  Header,
  HeaderCard,
  Options,
  PacePage,
  TableCard,
  ClassWrapper,
  ScheduleIconDisabled,
  Select,
} from "./components/Styles";

import { PreciumDisabledPage } from "../sdk/components/PreciumDiabledPage";
import PickupCalculation from "./components/PickupCalculation";
import usePickup from "./hooks/usePickup";

export const SubHeaderNew = styled(Box)`
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 24px;
`;
export const DATE = styled(Box)`
  box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 0px 12px 0px 16px;
`;

export const PickupLabel = styled(Typography)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
  letter-spacing: 0px;
  padding-right: 17px;
  padding-left: 8px;
  white-space: nowrap;
`;

export const HeaderCardPickup = styled(Box)`
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  flex: 0;
  color: #281e53;
  box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
  margin-left: 16px;
  padding-right: 12px;
`;

export const LabelNew = styled(Typography)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #281e53;
  padding-right: 22px;
`;

export const CalenderIcon = styled(CalendarToday)`
  position: absolute;
  width: 20px;
  height: 22px;
  color: #306fbc;
  z-index: 98;
  margin-left: -32px;
  margin-top: 4px;
  cursor: pointer;
`;

export const HeaderCardCenterNew = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  margin-left: 16px;
  padding-left: 24px;
  padding-right: 24px;
  height: 32px;
  button.MuiButtonBase-root {
    opacity: 1;
    min-width: 100px;
    span.MuiTab-wrapper {
      font: normal normal bold 16px / 20px Roboto;
      color: #281e53;
    }
  }
`;

const typesOfPickup = [
  { value: "occPickup", name: "Occupancy Pickup" },
  { value: "arrPickup", name: "ADR Pickup" },
  { value: "revPickup", name: "Revenue Pickup" },
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const isSameDate = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export default function PickupAll({ setPageHeader }) {
  const { hotelId, FROMDATE, TODATE } = useParams();
  const { hotelSelect, authFetch, currentHotel } = useAuth();
  const history = useHistory();
  const { token, permission } = useAuth();
  const [fromDate, setFromDate] = useState(() => {
    if (!isNaN(new Date(FROMDATE).getTime())) {
      const [year, mon, day] = FROMDATE.split("-");

      if (year && mon && day) {
        if (day && day.length === 1) {
          const validDate = [year, mon, "0" + day].join("-");
          return new Date(validDate);
        } else {
          return new Date(FROMDATE);
        }
      } else {
        return new Date(new Date().setDate(new Date().getDate() - 1));
      }
    } else {
      return new Date(new Date().setDate(new Date().getDate() - 1));
    }
  });
  const [toDate, setToDate] = useState(() => {
    if (!isNaN(new Date(TODATE).getTime())) {
      const [year, mon, day] = TODATE.split("-");

      if (year && mon && day) {
        if (day && day.length === 1) {
          const validDate = [year, mon, "0" + day].join("-");
          return new Date(validDate);
        } else {
          return new Date(TODATE);
        }
      } else {
        return new Date();
      }
    } else {
      return new Date();
    }
  });

  const [fromDateFiles, setFromDateFiles] = useState([]);
  const [toDateFiles, setToDateFiles] = useState([]);
  const [fromFileId, setFromFileId] = useState(0);
  const [toFileId, setToFileId] = useState(0);
  const handleChange = (newValue, type = "from") => {
    if (type === "from") {
      setFromFileId(newValue);
    } else {
      setToFileId(newValue);
    }
  };

  let first = getISODate(fromDate).split("T")[0];
  const fromDateDefaultValue = {
    year: parseInt(first?.split("-")[0]),
    month: parseInt(first?.split("-")[1]),
    day: parseInt(first?.split("-")[2]),
  };
  let second = getISODate(toDate).split("T")[0];
  const toDateDefaultValue = {
    year: parseInt(second?.split("-")[0]),
    month: parseInt(second?.split("-")[1]),
    day: parseInt(second?.split("-")[2]),
  };

  const [fromDateNew, setFromDateNew] = useState(fromDateDefaultValue);
  const [toDateNew, settoDateNew] = useState(toDateDefaultValue);

  const [pickup, setPickup] = useState(
    Math.round((toDate - fromDate) / (1000 * 60 * 60 * 24))
  );

  const [selectedPickup, setSelectedPickup] = useState("occPickup");
  let PUReport = null;
  const {
    monthlyBookings,
    setMonthlyBookings,
    open,
    setOpen,
    setnetworkMsg,
    handleCloseStatus,
    networkMsg,
    Loading,
    Error,
    ids,
    setSelectedIds,
    hotelOptions,
  } = usePickup({
    fromDate,
    toDate,
    selectedPickup,
    fromFileId,
    toFileId,
  });

  const [maximumDate, setMaximumDate] = useState(fromDateDefaultValue);
  const maximumToDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  const [popoverData, setPopOverData] = useState({});
  const [selectedImages, setSelectedImages] = useState([]);
  const [remarkData, setRemarkData] = useState([]);

  useEffect(() => {
    if (token && hotelId) {
      window.scrollTo(0, 0);
    } else {
      return;
    }
  }, [token, hotelId]);
  useEffect(() => {
    setPageHeader("Pick-Up Report");
    return () => {
      setPageHeader("");
    };
  }, []);

  useEffect(() => {
    history.replace(
      `/hotel/${hotelId}/pickup_all/${getISODate(fromDate).split("T")[0]}/${
        getISODate(toDate).split("T")[0]
      }`
    );
  }, [fromDate, toDate, hotelId, history]);

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "PUReport") {
        PUReport = permission[key];
      }
    }
  }
  const [selectedDate, setSelectedDate] = useState(() => {
    if (!isNaN(new Date(DATE).getTime())) {
      const [year, mon, day] = DATE.split("-");
      if (year && mon && day) {
        if (day && day.length === 1) {
          const validDate = [year, mon, "0" + day].join("-");
          return new Date(validDate);
        } else {
          return new Date(DATE);
        }
      } else {
        return new Date();
      }
    } else {
      return new Date();
    }
  });

  const RemarkData = async () => {
    const yyyymmdd = getISODate(fromDate).split("T")[0];
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/remark/${yyyymmdd}`,
    });
    const { data, error } = await get();
    if (data) {
      setRemarkData(data);
    } else {
      console.log(error);
    }
  };

  useEffect(() => {
    RemarkData();
  }, [token]);

  const uploadImage = async (images, date) => {
    const UploadSuccess = await Promise.all(
      images.map((file) => {
        return new Promise(async (res) => {
          try {
            if (!(date instanceof Date)) {
              date = new Date(date);
            }
            const yyyymmdd = getISODate(date).split("T")[0];
            const formData = new FormData();
            formData.append("date", yyyymmdd);
            formData.append("image", file);
            const { post } = await authFetch({
              path: `/hotel/${hotelId}/upload-image`,
            });
            const { data, error } = await post(formData);
            if (error) {
              throw new Error("Failed to upload image");
            }
            res({ id: data.id, imageURL: data.imageURL });
          } catch (error) {
            res({ id: "", imageURL: "" });
          }
        });
      })
    );
    return UploadSuccess;
  };

  async function sendRemark(date, popOver) {
    const data = {
      description: popOver?.remark?.remark ?? "",
      imageId: [
        ...(!!selectedImages?.length ? selectedImages : []),
        ...(!!popOver?.images?.length ? popOver?.images : []),
      ].map((item) => item.id),
      date: date,
    };

    const { post } = await authFetch({
      path: `/hotel/${hotelId}/remark`,
    });
    const { data: responseData, error } = await post(data);
    if (data) {
      RemarkData();
      // setnetworkMsg("Upload Successful")
      // setOpen(true);
    }
  }

  useEffect(() => {
    if (toDateNew) {
      let dateNew = new Date(
        [
          toDateNew?.year,
          toDateNew?.month,
          toDateNew?.day.length === 1 ? "0" + toDateNew?.day : toDateNew?.day,
        ].join("/")
      );
      let first = getISODate(
        new Date(dateNew.getTime() - pickup * 24 * 60 * 60 * 1000)
      ).split("T")[0];
      const Value = {
        year: parseInt(first?.split("-")[0]),
        month: parseInt(first?.split("-")[1]),
        day: parseInt(first?.split("-")[2]),
      };
      let checkValue = new Date(
        [
          toDateNew?.year,
          toDateNew?.month,
          toDateNew?.day.length === 1 ? "0" + toDateNew?.day : toDateNew?.day,
        ].join("/")
      );
      let dateCheck = new Date(
        new Date(checkValue).setDate(new Date(checkValue).getDate() - 1)
      );
      let second = getISODate(dateCheck).split("T")[0];

      const fromDateMaximumValue = {
        year: parseInt(second?.split("-")[0]),
        month: parseInt(second?.split("-")[1]),
        day: parseInt(second?.split("-")[2]),
      };
      setToDate(dateNew);
      setFromDate(new Date(dateNew.getTime() - pickup * 24 * 60 * 60 * 1000));
      setMaximumDate(fromDateMaximumValue);
      setFromDateNew(Value);
    }
  }, [toDateNew]);

  useEffect(() => {
    if (fromDateNew) {
      let dateNew = new Date(
        [
          toDateNew?.year,
          toDateNew?.month,
          toDateNew?.day.length === 1 ? "0" + toDateNew?.day : toDateNew?.day,
        ].join("/")
      );

      let datevar = new Date(
        [
          fromDateNew?.year,
          fromDateNew?.month,
          fromDateNew?.day.length === 1
            ? "0" + fromDateNew?.day
            : fromDateNew?.day,
        ].join("/")
      );
      const diffDays = Math.round((dateNew - datevar) / (1000 * 60 * 60 * 24));

      setPickup(diffDays);
      setFromDate(datevar);
    }
  }, [fromDateNew]);
  function handlePickup(value) {
    if (value === "") {
      setPickup(value);
      setFromDate(
        new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate() - 1)
      );

      return;
    }
    if (value < 0) {
      value = value * -1;
    }
    if (value === "0" || value === "-0") {
      value = 1;
    }
    setPickup(value);
    setFromDate(new Date(toDate.getTime() - value * 24 * 60 * 60 * 1000));
    let first = getISODate(
      new Date(toDate.getTime() - value * 24 * 60 * 60 * 1000)
    ).split("T")[0];
    const Value = {
      year: parseInt(first?.split("-")[0]),
      month: parseInt(first?.split("-")[1]),
      day: parseInt(first?.split("-")[2]),
    };
    setFromDateNew(Value);
  }

  const formatInputValue = () => {
    if (fromDateNew) {
      const value =
        `${fromDateNew.day < 10 ? "0" + fromDateNew.day : fromDateNew.day}` +
        "/" +
        `${
          fromDateNew.month < 10 ? "0" + fromDateNew.month : fromDateNew.month
        }` +
        "/" +
        `${fromDateNew.year}`;
      return value;
    }
  };

  const formatInputValueToDate = () => {
    if (toDateNew) {
      const value =
        `${toDateNew.day < 10 ? "0" + toDateNew.day : toDateNew.day}` +
        "/" +
        `${toDateNew.month < 10 ? "0" + toDateNew.month : toDateNew.month}` +
        "/" +
        `${toDateNew.year}`;
      return value;
    }
  };
  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValue()}
      style={{
        textAlign: "left",
        marginBottom: "2px",
        fontSize: "14px",

        borderRadius: "10px",

        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );

  const renderCustomInputToDate = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValueToDate()}
      style={{
        textAlign: "left",
        marginBottom: "2px",
        fontSize: "14px",

        borderRadius: "10px",

        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );
  const StyledCalenderWrapper = styled(Box)((theme) => ({
    borderRadius: "8px",
    boxShadow: "0px 0px 4px  rgba(48, 111, 188, 0.2)",
    "&:hover>*": {
      color: "#ffffff",
      ">input": {
        backgroundColor: "#306fbc",
        color: "#ffffff !important",
      },
    },
  }));
  const fromDateAbsent = new Date(fromDate);
  const toDateAbsent = new Date(toDate);
  const uniqueHotelNames = new Set(
    monthlyBookings?.bobDataAbsent?.map((item) => item?.name)
  );
  const uniqueHotelCount = uniqueHotelNames?.size;

  const difference = ids?.length - uniqueHotelCount;
  console.log({ uniqueHotelCount });
  const fromDateData = monthlyBookings?.bobDataAbsent?.filter((item) =>
    isSameDate(new Date(item.date), fromDateAbsent)
  );
  const toDateData = monthlyBookings?.bobDataAbsent?.filter((item) =>
    isSameDate(new Date(item.date), toDateAbsent)
  );

  return currentHotel?.isPreciumEnabled ? (
    !Error && PUReport ? (
      <PacePage>
        <ClassWrapper>
          <Header>
            <SubHeaderNew>
              <Box
                display={"flex"}
                onClick={() => {
                  history.push(`/hotel/${currentHotel?.id}/pickup_report`);
                }}
                sx={{
                  padding: "0px",
                  fontSize: "12px",
                  color: "#163A90",
                  cursor: "pointer",
                }}
              >
                <ChevronLeft
                  sx={{
                    padding: "0px",
                  }}
                />
                <Typography>Back</Typography>
              </Box>

              <HeaderCardPickup
                sx={{
                  paddingRight: "0px !important",
                  marginRight: "10px",
                }}
              >
                <FormControl
                  size="small"
                  sx={{
                    background: "#FFF",
                    borderRadius: "8px",

                    width: "250px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFF !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFF !important",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFF !important",
                    },
                    "& .MuiInputBase-root": {
                      height: "30px",
                      lineHeight: "30px",
                    },
                  }}
                >
                  <InputLabel
                    sx={{
                      color: "black",
                      "&.Mui-focused": {
                        color: "black",
                      },
                    }}
                  >
                    Select Hotels
                  </InputLabel>
                  <Select
                    multiple
                    value={ids}
                    placeholder="Select Hotels"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.includes("all")) {
                        setSelectedIds(
                          ids.length === hotelOptions.length
                            ? []
                            : hotelOptions.map((option) => option.id)
                        );
                      } else {
                        setSelectedIds(value);
                      }
                    }}
                    renderValue={(selected) =>
                      hotelOptions
                        ?.filter((option) => selected.includes(option.id))
                        ?.map((option) => option.name)
                        ?.join(", ") ?? ""
                    }
                    MenuProps={{
                      PaperProps: {
                        style: {
                          width: "250px",
                          maxHeight: 200,
                          overflowY: "auto",
                        },
                      },
                    }}
                  >
                    {hotelOptions?.length > 0 ? (
                      <MenuItem
                        value="all"
                        onClick={() => {
                          setSelectedIds(
                            ids.length === hotelOptions.length
                              ? []
                              : hotelOptions.map((option) => option.id)
                          );
                        }}
                      >
                        <Checkbox
                          checked={ids.length === hotelOptions.length}
                        />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                    ) : null}

                    {hotelOptions?.map((option) => {
                      return (
                        <MenuItem
                          disabled={!option?.forecastFileStatus}
                          key={option?.id}
                          value={option?.id}
                        >
                          <Checkbox checked={ids.indexOf(option?.id) > -1} />
                          <ListItemText
                            sx={{
                              whiteSpace: "normal",
                            }}
                            primary={option?.name}
                          />
                        </MenuItem>
                      );
                    })}

                    {hotelOptions.length === 0 && (
                      <MenuItem disabled>
                        <ListItemText primary="No Options" />
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </HeaderCardPickup>
              <DATE>
                <HeaderCard>
                  <LabelNew style={{ paddingRight: "0px" }}>From</LabelNew>
                  <label>
                    <StyledCalenderWrapper>
                      <DatePicker
                        value={fromDateNew}
                        onChange={setFromDateNew}
                        renderInput={renderCustomInput}
                        calendarClassName="myCustomCalendar"
                        maximumDate={maximumToDate}
                      />
                      <CalenderIcon />
                    </StyledCalenderWrapper>
                  </label>
                </HeaderCard>

                <HeaderCard
                  sx={{
                    ml: "16px",
                  }}
                >
                  <LabelNew>To</LabelNew>
                  <label>
                    <StyledCalenderWrapper>
                      <DatePicker
                        value={toDateNew}
                        onChange={settoDateNew}
                        renderInput={renderCustomInputToDate}
                        calendarClassName="myCustomCalendar"
                        maximumDate={maximumToDate}
                      />
                      <CalenderIcon />
                    </StyledCalenderWrapper>
                  </label>
                </HeaderCard>
              </DATE>
              <HeaderCardPickup>
                <PickupLabel>Pickup Days</PickupLabel>
                <TextField
                  type="number"
                  variant="standard"
                  value={pickup}
                  onChange={(e) => {
                    handlePickup(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "54px" }}
                  InputProps={{
                    className: "input",
                    disableUnderline: true,
                    inputProps: { min: 1, max: 100 },
                  }}
                />
              </HeaderCardPickup>
              <HeaderCard
                style={{
                  justifyContent: "flex-start",
                  marginLeft: "16px",
                  boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                  borderRadius: "8px",
                  flex: 0,
                }}
              >
                <Options
                  disableUnderline
                  variant="standard"
                  value={typesOfPickup.name}
                  defaultValue="occPickup"
                  onChange={(e) => {
                    setSelectedPickup(e.target.value);
                    setMonthlyBookings([]);
                  }}
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",

                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "16px",

                    color: "#000000",
                  }}
                >
                  {typesOfPickup.map((pickup, index) => (
                    <MenuItem
                      style={{ fontFamily: "Roboto" }}
                      value={pickup.value}
                      key={index}
                    >
                      {pickup.name}
                    </MenuItem>
                  ))}
                </Options>
              </HeaderCard>
            </SubHeaderNew>
          </Header>
          {monthlyBookings?.bobDataAbsent?.length > 0 ? (
            <Stack
              direction={"row"}
              width={"100%"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography
                sx={{
                  color: "#FF4040",
                }}
              >
                Showing data for {difference} out of {ids?.length} properties.{" "}
                {fromDateData?.length > 0 && (
                  <Tooltip
                    arrow
                    placement="bottom"
                    disableFocusListener
                    interactive
                    sx={{
                      "& .MuiTooltip-tooltip": {
                        backgroundColor: "#333",
                        color: "#f5f5f5 !important",
                        borderRadius: "6px",
                      },
                      "& .MuiTooltip-arrow": {
                        color: "#333",
                      },
                    }}
                    title={
                      <Stack py={1} px={2} gap={1}>
                        {fromDateData?.map((item) => (
                          <Link
                            sx={{
                              color: "#f5f5f5 !important",
                              fontSize: "16px",
                            }}
                            href={`/hotel/${item?.id}/business_on_books`}
                          >
                            {item?.name}
                          </Link>
                        ))}
                      </Stack>
                    }
                  >
                    Click here to view properties with missing data on{" "}
                    {getISODate(fromDate).split("T")[0]}.{" "}
                  </Tooltip>
                )}
                {toDateData?.length > 0 && (
                  <Tooltip
                    arrow
                    placement="bottom"
                    disableFocusListener
                    interactive
                    sx={{
                      "& .MuiTooltip-tooltip": {
                        backgroundColor: "#333",
                        color: "#f5f5f5 !important",
                        borderRadius: "6px",
                      },
                      "& .MuiTooltip-arrow": {
                        color: "#333",
                      },
                    }}
                    title={
                      <Stack py={1} px={2} gap={1}>
                        {toDateData?.map((item) => (
                          <Link
                            sx={{
                              color: "#f5f5f5 !important",
                              fontSize: "16px",
                            }}
                            href={`/hotel/${item?.id}/business_on_books`}
                          >
                            {item?.name}
                          </Link>
                        ))}
                      </Stack>
                    }
                  >
                    Click here to view properties with missing data on{" "}
                    {getISODate(toDate).split("T")[0]}.
                  </Tooltip>
                )}
              </Typography>
            </Stack>
          ) : null}
          {!Loading ? (
            monthlyBookings && monthlyBookings?.pickUpResponse?.length > 0 ? (
              <>
                <TableCard>
                  {
                    <PickupCalculation
                      uploadImage={uploadImage}
                      sendRemark={sendRemark}
                      selectedImages={selectedImages}
                      setSelectedImages={setSelectedImages}
                      remarkData={remarkData}
                      setRemarkData={setRemarkData}
                      popoverData={popoverData}
                      setPopOverData={setPopOverData}
                      fromDate={fromDate}
                      toDate={toDate}
                      monthlyBookings={monthlyBookings}
                      selectedPickup={selectedPickup}
                    />
                  }
                </TableCard>
                <Snackbar
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  open={open}
                  autoHideDuration={3000}
                  onClose={handleCloseStatus}
                >
                  <SnackbarContent
                    style={{ backgroundColor: "#CA3433" }}
                    message={networkMsg}
                  />
                </Snackbar>
              </>
            ) : (
              <Nodata />
            )
          ) : (
            <LoadingPage />
          )}
        </ClassWrapper>
      </PacePage>
    ) : !PUReport ? (
      <NoAccess />
    ) : (
      <ErrorPage />
    )
  ) : (
    <PreciumDisabledPage />
  );
}
