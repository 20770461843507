import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  Popover,
  Snackbar,
  SnackbarContent,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { ArrowForwardIos, ViewList, ViewModule } from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useRoomAdjustment from "../hooks/useRoomAdjustment.js";
import { Card, MonthPicker } from "../../sdk/index.js";
import { RoomAdjustmentCalender } from "./RoomAdjustmentCalender.js";
import { AddRoomModal } from "./AddRoomModal.js";
import { format } from "date-fns";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { AdjustRoomsModal } from "./AdjustRoomsModal.js";

const ClassWrapper = styled(Box)(() => ({
  ".dropdownStyle": {
    borderRadius: 4,
    font: "normal normal normal 14px/20px Roboto",
  },
  ".container": {
    overflowX: "auto",
    minWidth: 500,
    marginTop: "6px",
    padding: "20px 20px 5px 20px",
  },
  ".colorPrimary": {
    backgroundColor: "#FFFFFF",
  },
  ".underline": {
    font: "normal normal 600 14px/20px Roboto",
    width: "100%",
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  ".inputCenter": {
    textAlign: "center",
    fontWeight: "400",
  },
  ".selectBox": {
    boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
    borderRadius: "8px",
  },
}));

const StyleTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: " #306FBC",
    color: "white",
  },
  [`&.${tableCellClasses.body}`]: {
    padding: 8,
    borderRight: "solid 2px #FFFFFF",
    font: "normal normal 600 14px/20px Roboto",
    height: "30px",
    fontWeight: "400",
    textAlign: "center",
  },
}));
export const MyCard = styled(Card)`
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: auto;
  flex-wrap: nowrap;
  margin: 0px;
  margin-top: 16px;
  padding: 20px;
  padding-top: 10px;
  height: calc(100vh - 118px);
`;

const SelectNew1 = styled(Select)({
  [` &.MuiOutlinedInput-root`]: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
    borderRadius: "8px",
  },
  "& fieldset": {
    display: "none",
    border: "none",
  },
  border: "none",
  outline: "none",
  "& :focus": {
    border: "none",
  },
  "& svg": {
    color: "#306FBC",
    fontSize: "30px",
  },
});

export default function RoomAdjustmentTable() {
  const {
    hotelId,
    budgetInfo,
    networkMsg,
    open,
    MONTHS,
    handleCloseStatus,
    handleYearChange,
    year,
    curr_year,
    budgetingPeriod,
    GetBudget,
    handleChangeNew,
    view,
    handleNextButtonClick,
    isLoading,
    openAddRoomModal,
    setOpenAddRoomModal,
    selectedMonthYear,
    setSelectedMonthYear,
    apiResponse,
    setApiResponse,
    openModalTrue,
    setOpenModalTrue,
    openSnackbar,
    setOpenSnackbar,
  } = useRoomAdjustment();

  return (
    <ClassWrapper className="container">
      <Stack direction={"row"} justifyContent="space-between">
        <Stack direction={"row"} spacing={4}>
          <Box sx={{ width: 140 }} stickyHeader>
            <FormControl fullWidth>
              <SelectNew1
                label="Select Year"
                value={year}
                classes={{
                  paper: "dropdownStyle",
                }}
                onChange={(e) => handleYearChange(e.target.value)}
              >
                <MenuItem
                  style={{ font: "normal normal normal 16px/20px Roboto" }}
                  value={curr_year - 4}
                >
                  {budgetingPeriod === "Jan-Dec"
                    ? curr_year - 4
                    : curr_year - 4 + "-" + (curr_year - 3)}
                </MenuItem>
                <MenuItem
                  style={{ font: "normal normal normal 16px/20px Roboto" }}
                  value={curr_year - 3}
                >
                  {budgetingPeriod === "Jan-Dec"
                    ? curr_year - 3
                    : curr_year - 3 + "-" + (curr_year - 2)}
                </MenuItem>
                <MenuItem
                  style={{ font: "normal normal normal 16px/20px Roboto" }}
                  value={curr_year - 2}
                >
                  {budgetingPeriod === "Jan-Dec"
                    ? curr_year - 2
                    : curr_year - 2 + "-" + (curr_year - 1)}
                </MenuItem>
                <MenuItem
                  style={{ font: "normal normal normal 16px/20px Roboto" }}
                  value={curr_year - 1}
                >
                  {budgetingPeriod === "Jan-Dec"
                    ? curr_year - 1
                    : curr_year - 1 + "-" + curr_year}
                </MenuItem>
                <MenuItem
                  style={{ font: "normal normal normal 16px/20px Roboto" }}
                  value={curr_year}
                >
                  {budgetingPeriod === "Jan-Dec"
                    ? curr_year
                    : curr_year + "-" + (curr_year + 1)}
                </MenuItem>
                <MenuItem
                  style={{ font: "normal normal normal 16px/20px Roboto" }}
                  value={curr_year + 1}
                >
                  {budgetingPeriod === "Jan-Dec"
                    ? curr_year + 1
                    : curr_year + 1 + "-" + (curr_year + 2)}
                </MenuItem>
              </SelectNew1>
            </FormControl>
          </Box>
          <Paper className="monthPicker" elevation={2}>
            <Stack
              height="100%"
              direction={"row"}
              alignItems="center"
              justifyContent="space-evenly"
            >
              <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                  <div style={{ height: "100%" }}>
                    <Button
                      {...bindTrigger(popupState)}
                      sx={{ height: "100%" }}
                    >
                      {selectedMonthYear
                        ? format(new Date(selectedMonthYear), "MMM yyyy")
                        : "Select Month"}
                    </Button>
                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      style={{ width: "450px" }}
                    >
                      <Grid container padding={2}>
                        {budgetInfo?.map((item) => (
                          <Grid item md={3}>
                            <Button
                              sx={{ color: "#281E53" }}
                              variant={
                                selectedMonthYear === item.month
                                  ? "outlined"
                                  : "text"
                              }
                              onClick={(e) => {
                                setSelectedMonthYear(item.month);
                                popupState.close();
                              }}
                            >
                              {format(new Date(item.month), "MMM")}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </Popover>
                  </div>
                )}
              </PopupState>
            </Stack>
          </Paper>
          <Paper className="monthPicker" elevation={2}>
            <Button
              sx={{ height: "100%" }}
              onClick={() => {
                setOpenAddRoomModal(true);
              }}
            >
              Add available rooms
            </Button>
          </Paper>
          <Paper className="monthPicker" elevation={2}>
            <Button
              sx={{ height: "100%" }}
              onClick={() => {
                setOpenModalTrue(true);
              }}
            >
              Add out of order rooms
            </Button>
          </Paper>
        </Stack>
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleChangeNew}
          color="primary"
        >
          <ToggleButton value="list" aria-label="list">
            <ViewList style={{ color: "#306fbc" }} />
          </ToggleButton>
          <ToggleButton value="module" aria-label="module">
            <ViewModule style={{ color: "#306fbc" }} />
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      {/* --------------- */}
      {view === "list" ? (
        <>
          <TableContainer
            component={Paper}
            style={{ marginTop: 14, maxHeight: `calc(100vh - 300px)` }}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <StyleTableCell align="center">Month</StyleTableCell>
                  <StyleTableCell align="center">Number of Days</StyleTableCell>
                  <StyleTableCell align="center">
                    Rooms Available
                  </StyleTableCell>
                  <StyleTableCell align="center">Out of Order</StyleTableCell>
                  <StyleTableCell align="center">
                    Actual Rooms Available
                  </StyleTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {budgetInfo &&
                  budgetInfo?.map((data, index) => (
                    <TableRow key={index}>
                      <StyleTableCell>
                        <div
                          value={index}
                          style={{
                            height: "30px",
                            cursor: "pointer",
                            color: "#fff",
                            background: "rgba(48, 111, 188, 1)",
                            textAlign: "center",
                            borderRadius: "10px",
                            paddingTop: "5px",
                          }}
                        >
                          {MONTHS[new Date(data.month).getMonth()] +
                            "-" +
                            new Date(data.month).getFullYear()}
                          {""}
                        </div>
                      </StyleTableCell>
                      <StyleTableCell>
                        <div>{data.days} </div>
                      </StyleTableCell>
                      <StyleTableCell>
                        <div>{data.roomsAvailable} </div>
                      </StyleTableCell>
                      <StyleTableCell>
                        <div>{data.adjustment}</div>
                      </StyleTableCell>
                      <StyleTableCell>
                        <div>{data.roomsToSell}</div>
                      </StyleTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            onClick={() => {
              handleNextButtonClick();
            }}
            variant="contained"
            sx={{ margin: 2, width: "100px" }}
          >
            Next
          </Button>
        </>
      ) : (
        <RoomAdjustmentCalender
          budgetInfo={budgetInfo}
          GetBudget={GetBudget}
          isLoading={isLoading}
          selectedMonthYear={selectedMonthYear}
        />
      )}

      {openAddRoomModal && (
        <AddRoomModal
          open={openAddRoomModal}
          handleClose={() => setOpenAddRoomModal(false)}
          GetBudget={GetBudget}
        />
      )}
      {openModalTrue && (
        <AdjustRoomsModal
          open={openModalTrue}
          handleClose={() => setOpenModalTrue(false)}
          GetBudget={GetBudget}
          setApiResponse={setApiResponse}
          setOpen={setOpenSnackbar}
        />
      )}

      {apiResponse.msg ? (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
        >
          <SnackbarContent
            style={{ backgroundColor: apiResponse.color }}
            message={apiResponse.msg}
          />
        </Snackbar>
      ) : null}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseStatus}
      >
        {networkMsg?.open && (
          <SnackbarContent
            style={{ backgroundColor: networkMsg?.color }}
            message={networkMsg?.msg}
          />
        )}
      </Snackbar>
    </ClassWrapper>
  );
}
