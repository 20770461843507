import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "react-modern-calendar-datepicker";
import { CalenderIcon } from "../../Pace";
import { useAuth } from "../../sdk";
import { convertLength } from "@mui/material/styles/cssUtils";

function displayNames(namesArray) {
  const nameCount = namesArray?.length;

  if (nameCount <= 4) {
    return (
      <Box>
        {namesArray?.map((name, index) => (
          <Typography
            sx={{
              color: "#747474",
              opacity: "0.5",
            }}
            key={index}
          >
            {name}
          </Typography>
        ))}
      </Box>
    );
  } else {
    const firstFiveNames = namesArray?.slice(0, 4);
    const remainingCount = nameCount - 4;

    return (
      <Box>
        {firstFiveNames?.map((name, index) => (
          <Typography
            sx={{
              color: "#747474",
              opacity: "0.5",
            }}
            key={index}
          >
            {name}
          </Typography>
        ))}
        <Typography
          sx={{
            color: "#747474",
            opacity: "0.5",
          }}
        >{`and ${remainingCount} more`}</Typography>
      </Box>
    );
  }
}

export default function DateModal({
  dateDialog,
  setDateDialog,
  selectedEmails,
  setSelectedEmails,
  setDownloadButton,
  downloadButton,
  userName,
  setUserName,
}) {
  const parsedDate = new Date();
  const futureDate = new Date();
  futureDate.setDate(parsedDate.getDate() + 7);

  const defaultToValue = {
    year: futureDate.getFullYear(),
    month: futureDate.getMonth() + 1,
    day: futureDate.getDate(),
  };

  const defaultValue = {
    year: parsedDate.getFullYear(),
    month: parsedDate.getMonth() + 1,
    day: parsedDate.getDate(),
  };

  const [adjustFromDate, setAdjustFromDate] = useState(defaultValue);
  const [adjustToDate, setAdjustToDate] = useState(defaultToValue);

  const { authFetch } = useAuth();

  const formatInputValueDate = (date) => {
    if (date) {
      const value =
        `${date.day < 10 ? "0" + date.day : date.day}` +
        "/" +
        `${date.month < 10 ? "0" + date.month : date.month}` +
        "/" +
        `${date.year}`;
      return value;
    }
  };

  const renderCustomFromInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValueDate(adjustFromDate)}
      style={{
        textAlign: "left",
        fontSize: "14px",
        borderRadius: "10px",
        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );
  const renderCustomToInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValueDate(adjustToDate)}
      style={{
        textAlign: "left",
        fontSize: "14px",
        borderRadius: "10px",
        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );

  const exportFile = useCallback(async () => {
    const { post } = await authFetch({
      path: `/user/rate-shop-log`,
    });

    const body = {
      emailList: selectedEmails,
      startdate: `${
        adjustFromDate.year
      }-${adjustFromDate.month
        ?.toString()
        ?.padStart(2, "0")}-${adjustFromDate.day
        ?.toString()
        ?.padStart(2, "0")}T00:00:00Z`,
      endDate: `${adjustToDate.year}-${adjustToDate.month
        ?.toString()
        ?.padStart(2, "0")}-${adjustToDate.day
        ?.toString()
        ?.padStart(2, "0")}T00:00:00Z`,
    };

    const response = await post(body);
    const blob = await response.response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Rateshop-Logs.xlsx`);
    document.body.appendChild(link);
    link.click();
    setSelectedEmails([]);
    setUserName([]);
    setDownloadButton((prev) => !prev);
    setDateDialog(false);
  }, [
    adjustFromDate.day,
    adjustFromDate.month,
    adjustFromDate.year,
    adjustToDate.day,
    adjustToDate.month,
    adjustToDate.year,
    authFetch,
    selectedEmails,
    setDateDialog,
    setDownloadButton,
    setSelectedEmails,
    setUserName,
  ]);

  return (
    <StyledDialog
      open={dateDialog}
      onClose={() => {
        setSelectedEmails([]);
        setUserName([]);
        setDateDialog(false);
        setDownloadButton((prev) => !prev);
      }}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle
        sx={{
          paddingBottom: "0px",
        }}
        className="content"
      >
        Select Date
      </DialogTitle>
      <CloseIcon
        className="closeButton"
        onClick={() => {
          setSelectedEmails([]);
          setUserName([]);
          setDateDialog(false);
          setDownloadButton((prev) => !prev);
        }}
      />
      <DialogContent
        sx={{
          paddingTop: "5px",
        }}
        className="content"
      >
        <Typography
          sx={{
            fontSize: "16px",
          }}
          mb={2}
        >
          {" "}
          Please select date for following users{" "}
        </Typography>
        {displayNames(userName)}

        <Stack direction="row" gap="40px" pb={2}>
          <Stack alignItems="center" justifyContent="space-between">
            <Typography className="heading">Start Date</Typography>
            <label>
              <StyledWrapper>
                <DatePicker
                  value={adjustFromDate}
                  onChange={setAdjustFromDate}
                  renderInput={renderCustomFromInput}
                  calendarClassName="myCustomCalendar"
                />
                <CalenderIcon />
              </StyledWrapper>
            </label>
          </Stack>
          <Stack alignItems="center" justifyContent="space-between">
            <Typography className="heading">End Date</Typography>
            <label>
              <StyledWrapper>
                <DatePicker
                  value={adjustToDate}
                  onChange={setAdjustToDate}
                  renderInput={renderCustomToInput}
                  calendarClassName="myCustomCalendar"
                />
                <CalenderIcon />
              </StyledWrapper>
            </label>
          </Stack>
        </Stack>
        <Button
          disabled={!adjustFromDate || !adjustToDate}
          onClick={() => {
            exportFile();
          }}
          variant="contained"
        >
          Save
        </Button>
      </DialogContent>
    </StyledDialog>
  );
}

const StyledDialog = styled(Dialog)(() => ({
  ".MuiPaper-root": {
    overflow: "visible",
  },
  ".closeButton": {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
  },
  ".content": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "visible",
  },
  ".heading": {
    fontSize: "20px",
    fontWeight: 600,
    color: "#306FBC",
  },
}));

const StyledWrapper = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
  "&:hover>*": {
    color: "#ffffff",
    ">input": {
      backgroundColor: "#306fbc",
      color: "#ffffff !important",
    },
  },
}));
