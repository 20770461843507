import React, { useEffect } from "react";
import {
  Card,
  Container,
  LabelNew,
  Page,
  SimpleGraphCard,
  TextOnlyTooltip,
} from "./styles";
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import NightPerformanceCard from "./NightPerformanceCard";
import { Bar } from "react-chartjs-2";
import MonthlyCard from "./MonthlyCard";
import useDashboardAll from "./useDashboardAll";
import YearlyCard from "./YearlyCard";
import Chart from "chart.js";
import chartDataLabels from "chartjs-plugin-datalabels";
import { positions } from "@mui/system";
import { ChevronLeft, KeyboardBackspace } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../sdk";

Chart.plugins.unregister(chartDataLabels);

export default function DashboardAll({ setPageHeader }) {
  const {
    data,
    setData,
    selectedYear,
    setSelectedYear,
    open,
    setOpen,
    HaveData,
    setHaveData,
    Error,
    setError,
    networkMsg,
    setNetworkMsg,
    yearOptions,
    setYearOptions,
    pickup,
    pickupOptions,
    ids,
    setSelectedIds,
    hotelOptions,
  } = useDashboardAll();
  const history = useHistory();
  const { currentHotel } = useAuth();
  useEffect(() => {
    setPageHeader("Dashboard");
    return () => {
      setPageHeader("");
    };
  }, []);

  const difference = ids?.length - data?.bobDataAbsent?.length;

  return (
    <Page>
      <Stack position={"relative"} direction="row" alignItems="center">
        {/* Item at the start */}
        <Stack direction="row" gap={3} alignItems="center">
          <Box
            display="flex"
            onClick={() => {
              history.push(`/hotel/${currentHotel?.id}/dashboard`);
            }}
            sx={{
              padding: "0px",
              fontSize: "12px",
              color: "#163A90",
              cursor: "pointer",
            }}
          >
            <ChevronLeft sx={{ padding: "0px" }} />
            <Typography>Back</Typography>
          </Box>
          <FormControl
            size="small"
            sx={{
              background: "#FFF",
              width: "250px",
            }}
          >
            <InputLabel>Select Hotels</InputLabel>
            <Select
              multiple
              value={ids}
              label="Select Hotels"
              onChange={(e) => {
                const value = e.target.value;
                if (value.includes("all")) {
                  setSelectedIds(
                    ids.length === hotelOptions.length
                      ? []
                      : hotelOptions.map((option) => option.id)
                  );
                } else {
                  setSelectedIds(value);
                }
              }}
              renderValue={(selected) =>
                hotelOptions
                  ?.filter((option) => selected.includes(option.id))
                  ?.map((option) => option.name)
                  ?.join(", ") ?? ""
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    width: "250px",
                    maxHeight: 200,
                    overflowY: "auto",
                  },
                },
              }}
            >
              {hotelOptions?.length > 0 ? (
                <MenuItem
                  value="all"
                  onClick={() => {
                    setSelectedIds(
                      ids.length === hotelOptions.length
                        ? []
                        : hotelOptions.map((option) => option.id)
                    );
                  }}
                >
                  <Checkbox checked={ids.length === hotelOptions.length} />
                  <ListItemText primary="Select All" />
                </MenuItem>
              ) : null}

              {hotelOptions?.map((option) => (
                <MenuItem
                  disabled={!option?.forecastFileStatus}
                  key={option?.id}
                  value={option?.id}
                >
                  <Checkbox checked={ids.indexOf(option?.id) > -1} />
                  <ListItemText
                    sx={{
                      whiteSpace: "normal",
                    }}
                    primary={option?.name}
                  />
                </MenuItem>
              ))}

              {hotelOptions.length === 0 && (
                <MenuItem disabled>
                  <ListItemText primary="No Options" />
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Stack>

        {data?.bobDataAbsent?.length > 0 && (
          <Stack
            position={"absolute"}
            left={"35%"}
            direction="row"
            alignItems="start"
          >
            <Tooltip
              arrow
              placement="right"
              disableFocusListener
              interactive
              sx={{
                "& .MuiTooltip-tooltip": {
                  backgroundColor: "#333",
                  color: "#f5f5f5 !important",
                  borderRadius: "6px",
                },
                "& .MuiTooltip-arrow": {
                  color: "#333",
                },
              }}
              title={
                <Stack py={1} px={2} gap={1}>
                  {data?.bobDataAbsent?.map((item) => (
                    <Link
                      sx={{
                        color: "#f5f5f5 !important",
                        fontSize: "16px",
                      }}
                      href={`/hotel/${item?.id}/business_on_books`}
                    >
                      {item?.name}
                    </Link>
                  ))}
                </Stack>
              }
            >
              <Typography
                sx={{
                  color: "#FF4040",
                }}
              >
                Showing data for {difference} out of {ids?.length} properties.
                Click here to view properties with missing data.
              </Typography>
            </Tooltip>
          </Stack>
        )}
      </Stack>

      <Container>
        <NightPerformanceCard data={data} />
        <SimpleGraphCard
          style={{
            boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
          }}
        >
          <Box
            style={{
              width: "96%",
              height: "92%",
              position: "relative",
            }}
          >
            <Box mb={1} style={{ display: "flex", justifyContent: "center" }}>
              <LabelNew>Pick-Up by Month</LabelNew>
            </Box>
            <Bar
              data={pickup}
              Pic
              options={pickupOptions}
              plugins={[chartDataLabels]}
            />
          </Box>
        </SimpleGraphCard>
        <MonthlyCard
          selectedMonthYear={selectedYear}
          setSelectedMonthYear={setSelectedYear}
          data={data}
          yearOptions={yearOptions}
        />
        <YearlyCard
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          data={data}
          yearOptions={yearOptions}
        />
      </Container>
    </Page>
  );
}
