import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Snackbar,
  SnackbarContent,
  TextField as TF,
  Typography,
  debounce,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { styled } from "@mui/system";
import ClearIcon from "@mui/icons-material/Clear";
import { IconButton } from "@mui/material";

import {
  ErrorPage,
  LoadingPage,
  NoAccess,
  PrimaryButton,
  useAuth,
} from "../sdk";
import UserListTable from "./components/UserListTable";
import { Stack } from "@mui/material";

const TextField = styled(TF)`
  input {
    font: normal normal normal 16px/20px Roboto;
    letter-spacing: 0px;
    color: #000000;
  }
`;

const PageTitle = styled(Box)`
  position: fixed;
  top: 13px;
  left: 80px;
  color: white;
  z-index: 999;
`;

const H = styled(Typography)`
  font: normal normal bold 24px/30px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
`;
const Label = styled(Box)`
  font-family: Roboto;
  white-space: nowrap;
`;
const UserName = styled(Box)`
  font-family: Roboto;
`;
const Cross = styled(CloseRounded)`
  width: 20px;
  height: 20px;
  margin-left: 15px;
  :hover {
    cursor: pointer;
  }
`;

const TableContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: 70px;
  padding-left: 76px;
  padding-right: 25px;
`;

export const HeadSection = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
`;

export default function UserListPage() {
  const { permission, hotelSelect, token, authFetch } = useAuth();
  const [userList, setUserList] = useState();
  const [newUserList, setNewUserList] = useState(undefined);
  const { hotelId } = useParams();
  const [hotels, setHotels] = useState();
  const [Error, setError] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Nodata, setNodata] = useState(false);
  const [refeshData, setRefreshData] = useState(false);
  const [managingOrganizations, setManagingOrganizations] = useState([]);
  const [selectedChainId, setSelectedChainId] = useState();
  const [selectedChain, setSelectedChain] = useState();
  const [selectedOrganizationId, setSelectedOrganizationId] = useState();
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [allRoles, setAllRoles] = useState();
  const [chains, setChains] = useState([]);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [open, setOpen] = useState(false);
  const [networkMsg, setNetworkMsg] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [userId, setUserId] = useState(null);
  const [userNameToDelete, setUserNameToDelete] = useState(null);
  const [userName, setUserName] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);

  const [downloadButton, setDownloadButton] = useState(false);
  const [dateDialog, setDateDialog] = useState(false);

  const handleCloseSatus = () => {
    setOpen(false);
  };
  const All = "All";
  const organizationId = hotelSelect?.organizationId;
  const managedBy = hotelSelect?.managedBy;
  let userRightsView = null,
    acrossHotel = null;
  const defaultOrgProps = {
    options: [
      ...managingOrganizations.map((org) => {
        return org.name;
      }),
    ],
    getOptionLabel: (option) => option,
  };
  const defaultChainProps = {
    options: [
      ...chains.map((chain) => {
        return chain.name;
      }),
      All,
    ],
    getOptionLabel: (option) => option,
  };

  useEffect(() => {
    if (token && hotelId) {
      window.scrollTo(0, 0);
      getRoles();
    } else {
      return;
    }
  }, [token, hotelId]);
  useEffect(() => {
    if (token && acrossHotel) {
      getManagingOrganization();
      setSelectedOrganizationId(hotelSelect?.managedBy);
      // // setSelectedOrganization(hotelSelect?.managedByName);
      setSelectedChainId(hotelSelect?.organizationId);
      setSelectedChain(All);
    }
  }, [token]);

  useEffect(() => {
    if (token && acrossHotel) {
      if (!selectedOrganizationId) {
        return;
      } else {
        getChains();
      }
    }
  }, [token, selectedOrganizationId]);

  useEffect(() => {
    if (token && acrossHotel) {
      if (!selectedOrganizationId || !selectedChainId) {
        return;
      } else {
        getUser();
        getHotels();
      }
    } else if (token && !acrossHotel) {
      getUser();
      getHotels();
    }
  }, [token, selectedChainId, selectedOrganizationId]);

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "userRightsView") {
        userRightsView = permission[key];
      }
      if (permission[key].name === "acrossHotel") {
        acrossHotel = permission[key];
      }
    }
  }

  async function getManagingOrganization() {
    setLoading(true);
    const { get } = await authFetch({
      path: `/all-managing-org`,
    });
    const { data, error } = await get();
    if (data) {
      setManagingOrganizations(data);
    } else {
      console.log(error);
    }
    setLoading(false);
  }

  async function getChains() {
    setLoading(true);
    const managingId = acrossHotel ? selectedOrganizationId : managedBy;
    const { get } = await authFetch({
      path: `/managing-org/${managingId}/chains`,
    });
    const { data, error } = await get();
    if (data) {
      setChains(data);
    } else {
      console.log(error);
    }
    setLoading(false);
  }
  async function getRoles() {
    setLoading(true);
    const { get } = await authFetch({
      path: `/all-roles`,
    });
    const { data, error } = await get();
    if (data) {
      setAllRoles(data);
    } else {
      console.log(error);
    }
    setLoading(false);
  }

  const handleOrgChange = (value) => {
    const orgId = managingOrganizations.find((org) => org.name === value);
    if (orgId) {
      setSelectedOrganizationId(orgId.id);
      setSelectedOrganization(orgId.name);
      setSelectedChainId(All.toLowerCase());
      setSelectedChain(All);
    } else {
      setSelectedOrganizationId(null);
    }
  };

  const handleChainChange = (value) => {
    if (value === All) {
      setSelectedChainId(All.toLowerCase());
      setSelectedChain(All);
      setUserList([]);
    } else {
      const chainId = chains.find((chain) => chain.name === value);
      if (chainId) {
        setSelectedChainId(chainId.id);
        setSelectedChain(chainId.name);
        setUserList([]);
      } else {
        setSelectedChainId(null);
      }
    }
  };

  const deleteUser = async (id) => {
    if (id) {
      setLoading(true);
      const { del } = await authFetch({
        path: `/deactivate-user/${id}`,
      });
      await del();
      getUser();

      setNetworkMsg("User Deleted Successfully");
      setOpen(true);
    }
  };

  const history = useHistory();
  const location = useLocation();

  const getUser = useCallback(
    async (value) => {
      setLoading(true);
      const managingId = acrossHotel ? selectedOrganizationId : managedBy;
      const id = acrossHotel ? selectedChainId : organizationId;
      const { get } = await authFetch({
        // path: `/managing-org/${managingId}/chains/${id}/users`,
        path: `/all-users?search=${value ?? ""}`,
      });
      const { data, error } = await get();

      if (data) {
        let hotelList;
        let i = 0;

        const users = data.map((user) => {
          // let permissionList = [];

          // if (user.permission?.canCreateUser === true) {
          //   permissionList.push("Can Create User");
          // }
          // if (user.permission?.canUploadData === true) {
          //   permissionList.push("Can Upload Data");
          // }
          // if (user.permission?.canCreateAlgorithm === true) {
          //   permissionList.push("Can Create Algorithm");
          // }
          // if (user.permission?.canCreateEvent === true) {
          //   permissionList.push("Can Create Event");
          //   i++;
          // }

          hotelList = user.hotels
            ?.map((hotel) => {
              return hotel.name;
            })
            .join(", ");
          return {
            ...user,
            hotelArray: user.hotels,
            hotels: hotelList,
          };
        });
        setUserList(users);
      } else if (data === null) {
        setUserList([]);
      } else {
        console.log(error);
      }
      setLoading(false);
    },
    [authFetch]
  );

  async function getHotels() {
    const managingId = acrossHotel ? selectedOrganizationId : managedBy;
    const id = acrossHotel ? selectedChainId : organizationId;
    const { get } = await authFetch({
      path: `/managing-org/all/chains/all/hotels/1`,
    });
    const { data, error } = await get();
    if (data) {
      setHotels(data);
      setError(false);
    } else {
      setError(true);
      console.log(error);
    }
  }

  function confirmation() {
    const val = window.confirm("Please confirm again");
    if (val) {
      deleteUser(userId);
      setUserId(null);
    }
    return;
  }

  const defaultUserNameProps = {
    options: userList,
    getOptionLabel: (option) => {
      return `${option?.name === undefined ? "" : option?.name} ${
        option?.username === undefined ? "" : `(${option?.username})`
      }`;
    },
  };

  const handleUserName = useCallback(
    (newList) => {
      const currentSearchParams = new URLSearchParams(history.location.search);
      currentSearchParams.set("search", newList.name.toString());
      history.replace({
        pathname: history.location.pathname,

        search: currentSearchParams.toString(),
      });
      setUserName(newList?.name);
      // setSearchParams(newList);
      if (newList === null) {
        setNewUserList(undefined);
      } else {
        setNewUserList([newList]);
      }
    },
    [userName]
  );

  const debouncer = useCallback(debounce(getUser, 500), [getUser]);

  const handleSearchChange = useCallback(
    (event) => {
      const newSearchTerm = event.target.value;
      setSearchInput(newSearchTerm);
      debouncer(newSearchTerm);
    },
    [debouncer]
  );

  const handleClearSearch = useCallback(() => {
    setSearchInput("");
    debouncer("");
  }, [debouncer]);

  useEffect(() => {
    if (!newUserList) return;
    const selectedUser = userList.filter((user) =>
      newUserList.find(({ id }) => user.id === id)
    );
    setNewUserList(selectedUser);
  }, [userList]);

  useEffect(() => {
    if (newUserList !== undefined) {
      setNewUserList(undefined);
      setUserName(undefined);
    }
  }, [selectedChain]);

  const handleCheckboxChange = (email, username) => {
    setSelectedEmails((prevSelectedRows) => {
      const isSelected = prevSelectedRows?.some((row) => row === email);
      if (isSelected) {
        return prevSelectedRows?.filter((row) => row !== email);
      } else {
        return [...prevSelectedRows, email];
      }
    });
    setUserName((prevSelectedRows) => {
      const isSelected = prevSelectedRows?.some((row) => row === username);
      if (isSelected) {
        return prevSelectedRows?.filter((row) => row !== username);
      } else {
        return [...prevSelectedRows, username];
      }
    });
  };

  // useEffect(() => {
  //   const searchTimeout = setTimeout(() => {
  //     getUser(searchInput);
  //     console.log(`Searching for: ${searchInput}`);
  //   }, 300);
  //   return () => clearTimeout(searchTimeout);
  // }, [searchInput]);

  return !Error ? (
    <>
      {userRightsView ? (
        <div>
          <PageTitle>
            <H>Users</H>
          </PageTitle>

          <TableContainer>
            {acrossHotel && (
              <HeadSection>
                <Dialog
                  open={shouldBlockNavigation}
                  onClose={() => setShouldBlockNavigation(false)}
                  maxWidth="lg"
                >
                  <DialogTitle>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Label>{`Are you sure you want to delete user - ${userNameToDelete} ?`}</Label>
                      <Cross onClick={() => setShouldBlockNavigation(false)}>
                        &times;
                      </Cross>
                    </div>
                  </DialogTitle>
                  <DialogActions
                    style={{
                      justifyContent: "space-evenly",
                      paddingBottom: 16,
                    }}
                  >
                    <PrimaryButton
                      autoFocus
                      next
                      onClick={() => {
                        setShouldBlockNavigation(false);
                        confirmation();
                      }}
                    >
                      Yes
                    </PrimaryButton>
                    <PrimaryButton
                      next
                      onClick={() => {
                        setShouldBlockNavigation(false);
                        setUserId(null);
                      }}
                    >
                      No
                    </PrimaryButton>
                  </DialogActions>
                </Dialog>
                <Stack
                  direction={"row"}
                  style={{ width: "60%" }}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  {/* user name Autocomplete */}
                  {/* <Autocomplete
                      {...defaultUserNameProps}
                      value={userName}
                      style={{ width: "25%", padding: "0 8px 0 8px" }}
                      freeSolo={true}
                      disableClearable={false}
                      onChange={(event, newValue) => {
                        handleUserName(newValue);
                      }}
                      classes={{
                        paper: classes.dropdownStyle,
                      }}
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            label="Users"
                            variant="standard"
                          />
                        </>
                      )}
                    /> */}
                  <Stack direction={"row"}>
                    <TextField
                      id="standard-basic"
                      label="Search"
                      variant="standard"
                      value={searchInput}
                      onChange={handleSearchChange}
                      sx={{ width: "400px" }}
                    />
                    <IconButton onClick={handleClearSearch}>
                      <ClearIcon />
                    </IconButton>
                  </Stack>

                  {downloadButton ? (
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: "18px",
                        lineHeight: "1.25",
                        color: "#333333",
                      }}
                    >
                      Please select users
                    </Typography>
                  ) : null}
                </Stack>
              </HeadSection>
            )}
            {!Loading ? (
              <UserListTable
                userList={newUserList === undefined ? userList : newUserList}
                chains={chains}
                hotels={hotels}
                setError={setError}
                setLoading={setLoading}
                setRefreshData={setRefreshData}
                getUser={getUser}
                selectedChain={selectedChain}
                allRoles={allRoles}
                deleteUser={deleteUser}
                setUserId={setUserId}
                setShouldBlockNavigation={setShouldBlockNavigation}
                setUserNameToDelete={setUserNameToDelete}
                setOpen={setOpen}
                setNetworkMsg={setNetworkMsg}
                setSearchInput={setSearchInput}
                selectedEmails={selectedEmails}
                setSelectedEmails={setSelectedEmails}
                handleCheckboxChange={handleCheckboxChange}
                downloadButton={downloadButton}
                setDownloadButton={setDownloadButton}
                dateDialog={dateDialog}
                setDateDialog={setDateDialog}
                userName={userName}
                setUserName={setUserName}
              />
            ) : (
              <LoadingPage></LoadingPage>
            )}
          </TableContainer>
        </div>
      ) : (
        <NoAccess></NoAccess>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseSatus}
      >
        {networkMsg !== "User Added Successfully" &&
        networkMsg !== "User Updated Successfully" &&
        networkMsg !== "User Deleted Successfully" ? (
          <SnackbarContent
            style={{ backgroundColor: "#CA3433" }}
            message={networkMsg}
          />
        ) : (
          <SnackbarContent
            style={{ backgroundColor: "#228b22" }}
            message={networkMsg}
          />
        )}
      </Snackbar>
    </>
  ) : (
    <ErrorPage></ErrorPage>
  );
}
