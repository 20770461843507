import {
  Box,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
} from "@mui/material";
import { styled } from "@mui/system";
import { Edit } from "@mui/icons-material";
import React from "react";
import { SecondaryButton, TableLoading } from "./../../sdk";
import {
  CancelButton,
  CardContainer,
  Header,
  SaveButton,
  Tabhead,
  TCell,
  TContainer,
  TextFieldSmall,
  THead,
  TRow,
} from "./../Styles";

const EditButton = styled(Edit)`
  height: 18px;
  width: 18px;
  color: #306fbc;
`;
export const DayOfWeekCard = ({
  daysOfWeek,
  isInEditMode,
  setIsInEditMode,
  isCancelled,
  setIsCancelled,
  isSaved,
  setIsSaved,
  onChange,
  open,
  handleCloseStatus,
  networkMsg,
  dowLoading,
  isEditDaysofWeek,
  handleEditDaysOfWeekChange,
}) => {
  const Days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return (
    <>
      <CardContainer>
        <Header>
          <Box className="title">Day Of Week</Box>
          {!isInEditMode ? (
            <SecondaryButton style={{ border: "none" }}></SecondaryButton>
          ) : (
            <Box>
              <CancelButton
                onClick={() => {
                  setIsCancelled(!isCancelled);
                  setIsInEditMode(!isInEditMode);
                }}
              >
                Cancel
              </CancelButton>
              <SaveButton
                onClick={() => {
                  setIsSaved(!isSaved);
                  setIsInEditMode(!isInEditMode);
                }}
              >
                Save
              </SaveButton>
            </Box>
          )}
        </Header>

        {!dowLoading ? (
          <TContainer style={{ maxHeight: "50vh" }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{
                width: "100%",
                border: "1px solid rgba(0, 0, 0, 0.12)",

                borderRadius: "5px",
              }}
            >
              <THead style={{ height: "1px", padding: "0px" }}>
                <TRow>
                  <Tabhead $isineditmode={isInEditMode}>Day</Tabhead>
                  <Tabhead $isineditmode={isInEditMode}>Value</Tabhead>
                  <Tabhead $isineditmode={isInEditMode}>Actions</Tabhead>
                </TRow>
              </THead>
              <TableBody>
                {daysOfWeek.map((row, idx) => (
                  <TRow key={idx}>
                    <TCell $isineditmode={isInEditMode}>{Days[row.day]}</TCell>
                    <TCell $isineditmode={isInEditMode}>
                      {isEditDaysofWeek[idx] ? (
                        <TextFieldSmall
                          value={row.value}
                          type="number"
                          onChange={(e) =>
                            onChange(
                              idx,
                              "value",
                              e.target.value && parseFloat(e.target.value)
                            )
                          }
                        />
                      ) : !row.value ? (
                        0
                      ) : (
                        row.value
                      )}
                    </TCell>
                    <TCell
                      style={{
                        backgroundColor: "none",
                        color: "#306fbc",
                      }}
                    >
                      <EditButton
                        onClick={() => handleEditDaysOfWeekChange(idx)}
                      />
                    </TCell>
                  </TRow>
                ))}
              </TableBody>
            </Table>
          </TContainer>
        ) : (
          <TableLoading />
        )}
      </CardContainer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseStatus}
      >
        <SnackbarContent
          style={{ backgroundColor: "#CA3433" }}
          message={networkMsg}
        />
      </Snackbar>
    </>
  );
};
