import React, { useState } from "react";
import {
  ChooseBtn,
  Cross,
  CrossSmall,
  Done,
  Filename,
  HeadUM as Head,
  Section,
  Title,
  UploadSection,
} from "../Styles";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

function getModalStyle() {
  const top = 8;
  const right = 4;

  return {
    top: `${top}%`,
    right: `${right}%`,
    transform: `translate(-${top}, -${right})`,
  };
}
const ClassWrapper = styled(Box)((theme) => ({
  ".paper": {
    position: "absolute",
    width: 400,
    height: 300,
    backgroundColor: "#fff",
    boxShadow: 5,
  },
}));
export function ModelBody({
  fileHandler,
  fileGetter,
  setOpenSelectBar,
  removeFile,
  filename,
  toggle,
}) {
  const [modalStyle] = useState(getModalStyle);
  return (
    <ClassWrapper>
      <Section style={modalStyle} className="paper">
        <Head>
          <Title>Upload Past Data</Title>
          <Cross onClick={() => setOpenSelectBar(false)}>&times;</Cross>
        </Head>
        <ChooseBtn
          style={toggle ? { display: "none" } : { display: "block" }}
          htmlFor="upload"
        >
          Choose File
          <input
            type="file"
            id="upload"
            onChange={(e) => fileGetter(e.target.files)}
            style={{ display: "none" }}
          />
        </ChooseBtn>

        <UploadSection
          style={toggle ? { display: "flex" } : { display: "none" }}
        >
          <img
            src="/assets/excel.svg"
            alt="Excel Icon"
            width="36px"
            height="36px"
          />
          <Filename>{filename}</Filename>
          <CrossSmall
            onClick={() => removeFile()}
            style={toggle ? { display: "block" } : { display: "none" }}
          >
            &times;
          </CrossSmall>
        </UploadSection>
        <Done disabled={!toggle} next onClick={fileHandler} type="button">
          Upload
        </Done>
      </Section>
    </ClassWrapper>
  );
}
