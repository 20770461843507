import {
  Button,
  Box,
  TableCell,
  TableContainer,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/system";
import { getDateDDMM, getDay, LoadingPage } from "../../sdk";
import {
  Bottom,
  DATE,
  DAY,
  MyCard,
  Table,
  Tbody,
  Thead,
  Tr,
  Wrapper,
} from "../Styles";
import DataPopOver from "./Datapopover";
import moment from "moment";

const ClassWrapper = styled(Box)(() => ({
  ".root": {
    width: "100%",
  },
  ".container": {
    height: "calc(100% - 40px)",
    minWidth: 400,
  },
  ".arrow": {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  ".rowHover": {
    "&:hover": {
      color: "red",

      backgroundColor: "rgba(48, 81, 221,0.4) !important",
    },
  },
  ".button": {
    "&:hover": {
      backgroundColor: "#163A90",
      color: "#fff",
    },
  },
}));

const MyCardNew = styled(Box)`
  width: 100%;
  // height: 122px;
  borderradius: 8px;
`;

export const Td = styled(TableCell)`
  &.MuiTableCell-body {
    color: #333333;
    border: none;
    height: 32px;
    text-align: center;
    padding: 0px;

    width: 130px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;

const DateText = styled(Box)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #212121;
  padding: 8px;
`;
const DayNew = styled(Box)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: #212121;
  padding: 8px;
`;
const Text = styled(Box)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 10px;

  color: #212121;
  padding: 4px;
`;
const DataNew = styled(Box)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 10px;

  color: #212121;
  padding: 4px;
`;
const MyCardCalender = styled(MyCard)`
  width: 228px;
  padding-top: 4px;
  padding-bottom: 18px;
  height: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 0px;
`;

const P = styled(Typography)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
`;
const Header = styled(Button)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  width: 100%;
`;
const HeaderNew = styled(Box)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  box-shadow: 1px 4px 10px rgb(48 111 188 / 20%);
  border-radius: 4px;

  padding: 8px;

  color: #000000;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
  text-align: center;
  display: flex;
  height: 60px;
  min-width: 100px;
`;
const BottomNew = styled(Bottom)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: #000000;
`;
const Detail = styled(Box)`
  box-shadow: 1px 4px 10px rgb(48 111 188 / 20%);
  border-radius: 4px;
  color: #757575;
  padding: 8px;

  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: space-between;
  text-align: center;
  margin-left: 20px;
  font-family: Roboto;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  min-width: 100px;
`;
const DetailNew = styled(Box)`
  color: #757575;

  padding: 8px;
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: center;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;
const Total = styled(Box)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;

  color: #000000;
`;

export const Week = styled(Box)`
  display: grid;
  grid-template-columns: repeat(7, 10vw);
  grid-template-rows: 3vh;
  gap: 8px;
  background-color: rgb(48, 111, 188);
  top: 0;
  position: sticky;
  padding: 0.25%;
  border-radius: 8px;
  margin-bottom: 0.5%;
`;

export const Name = styled(Box)`
  background: transparent;
  font: normal bold normal 14px/16px Roboto;
  color: white;
  text-align: center;
  letter-spacing: 1px;
`;
export const Day = styled(Box)`
  background: transparent;
  font: normal bold normal 12px/14px Roboto;
  color: #1d1d1d;
  text-align: center;
`;
export const Calender = styled(Box)`
  margin: 12px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const CardNew = styled(Box)`
  width: 100%;
  height: 104px;
  borderradius: 8px;
`;

export const RecommHeadNew = styled(Typography)`
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #000000;
  padding: 0px 0 8px 0;
`;

export const Th = styled(TableCell)`
  &.MuiTableCell-head {
    vertical-align: bottom;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    background-color: #306fbc;
    border: none;
    text-align: center;
    padding: 10px 0px;
  }
`;

export const CalenderNew = styled(Box)`
  margin: 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;
export const WeekNew = styled(Box)`
  display: grid;
  grid-template-columns: repeat(7, 27px);
  grid-template-rows: 3vh;
  gap: 3px;
`;

export const TextOnlyTooltipPopover = styled(Tooltip)(({ theme }) => ({
  ".tooltip": {
    font: "normal normal normal 12px/16px Roboto",
    marginLeft: -8,
    minWidth: "80px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",

    padding: "4px",
    background: " #ffffff 0% 0% no-repeat padding-box",
    border: "1px solid #A0A0A0",
  },
  ".arrow": {
    "&:before": {
      border: "1px solid #A0A0A0",
    },
    color: "#ffffff",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1} style={{ padding: "0 8px" }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}
const monthsnew = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const PricingRecommends = ({
  bookings,
  fileId,
  allFilesForDay,
  view,
  Loading,
}) => {
  const [bobData, setbobData] = useState([]);
  useEffect(() => {
    if (bookings && bookings[0]) {
      setbobData(modifiedBooking(bookings[0]));
    }
  }, [bookings]);

  return (
    <ClassWrapper>
      <Box
        style={
          view === "list"
            ? {
                display: "flex",
                flexDirection: "column",
                overflowX: "hidden",
              }
            : {
                display: "flex",
                flexDirection: "column",
                background: "#FFFFFF",
                boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                borderRadius: "8px",
                marginTop: "10px",
                marginLeft: "7px",
              }
        }
      >
        {view === "module" ? (
          <>
            {bookings?.length !== 0 ? (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "calc(100vh - 150px)",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    marginTop: "16px",
                    padding: "0px",
                    display: "flex",
                    flexWrap: "wrap",

                    marginBottom: "5px",

                    bordeRadius: "8px",
                  }}
                >
                  <Box
                    style={{
                      background: "white",
                      padding: "4px",
                      marginBottom: "8px",
                      display: "flex",
                      width: "80%",
                      marginLeft: "7px",
                      justifyContent: "flex-start",
                    }}
                  >
                    <HeaderNew>
                      {bobData?.length &&
                        months[new Date(bobData[0]?.date).getMonth()]}{" "}
                      {bobData && new Date(bobData[0]?.date).getFullYear()}
                    </HeaderNew>
                    <Detail>
                      <Box>Room Nights </Box>
                      <BottomNew>
                        {bobData?.length &&
                        bobData?.[bobData?.length - 1]?.totalRooms === -1
                          ? "-"
                          : bobData &&
                            bobData?.[bobData?.length - 1]?.totalRooms}
                      </BottomNew>
                    </Detail>

                    <Detail>
                      <Box>ADR</Box>
                      <BottomNew style={{ color: "#000000" }}>
                        {bobData?.[bobData?.length - 1]?.totalArr !== -1
                          ? bobData?.[bobData?.length - 1]?.totalRooms !== 0
                            ? Math.round(
                                parseFloat(
                                  bobData?.[bobData?.length - 1]?.totalArr /
                                    bobData?.[bobData?.length - 1]?.totalRooms
                                )
                              )
                            : "0"
                          : "-"}
                      </BottomNew>
                    </Detail>

                    <Detail>
                      <Box>Revenue</Box>
                      <BottomNew style={{ color: "#000000" }}>
                        {bobData?.[bobData?.length - 1]?.totalArr !== -1 ||
                        (bobData?.length &&
                          bobData?.[bobData?.length - 1]?.totalRooms !== -1)
                          ? Math.round(
                              parseFloat(
                                (bobData?.length &&
                                  bobData &&
                                  bobData[bobData.length - 1]?.totalRooms) *
                                  (bobData[bobData.length - 1]?.totalRooms !== 0
                                    ? Math.round(
                                        parseFloat(
                                          bobData[bobData.length - 1]
                                            ?.totalArr /
                                            bobData[bobData.length - 1]
                                              ?.totalRooms
                                        )
                                      )
                                    : "0")
                              )
                            ).toLocaleString("en-IN")
                          : "-"}
                      </BottomNew>
                    </Detail>
                  </Box>
                  {allFilesForDay.map((file, idx) => (
                    <TabPanel
                      value={fileId}
                      key={idx}
                      index={file.id}
                      style={{
                        overflow: "auto",
                        height: "calc(100% - 75px)",
                        overflowX: "hidden",
                      }}
                    >
                      <Calender>
                        <Week>
                          <Name>Monday</Name>
                          <Name>Tuesday</Name>
                          <Name>Wednesday</Name>
                          <Name>Thursday</Name>
                          <Name>Friday</Name>
                          <Name>Saturday</Name>
                          <Name>Sunday</Name>
                        </Week>
                        <Box
                          style={{
                            width: "100%",

                            display: "grid",
                            gridTemplateColumns: "repeat(7, 10vw)",
                            gridTemplateRows: "repeat(autofill, 8vh)",
                            height: "100%",
                            gap: "8px",
                          }}
                        >
                          {Array(
                            bobData[0]?.date &&
                              new Date(bobData[0]?.date)?.getDay() === 0
                              ? 6
                              : parseInt(
                                  new Date(bobData[0]?.date)?.getDay() - 1
                                ) || 0
                          )
                            .fill()
                            .map((data, idx) => (
                              <CardNew></CardNew>
                            ))}
                          {bobData
                            ?.slice(0, bobData.length - 1)
                            .map((booking, index) => (
                              <MyCardNew
                                sx={
                                  booking?.color
                                    ? {
                                        background: `linear-gradient(${booking?.color}, #FFFFFF)`,
                                        borderRight: "solid 1px #FFFFFF",

                                        borderRadius: "8px",
                                        // height: "104px",
                                      }
                                    : {
                                        background: "rgba(48, 81, 221,0.09)",
                                        borderRight: "solid 1px #FFFFFF",

                                        borderRadius: "8px",
                                        // height: "104px",
                                      }
                                }
                                key={index}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Box
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <DateText>
                                      {getDateDDMM(booking?.date).split(
                                        "/"
                                      )[0] +
                                        " " +
                                        monthsnew[
                                          new Date(booking?.date)?.getMonth()
                                        ]}
                                    </DateText>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: {
                                        xs: "column",
                                        md: "row",
                                      },
                                      justifyContent: "space-between",
                                      padding: "3px",
                                    }}
                                  >
                                    <Text>Occ%</Text>
                                    <DataNew>
                                      {booking?.occupancyPercentage === -1
                                        ? "-"
                                        : booking?.occupancyPercentage?.toFixed(
                                            2
                                          )}
                                    </DataNew>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: {
                                        xs: "column",
                                        md: "row",
                                      },
                                      justifyContent: "space-between",
                                      padding: "3px",
                                    }}
                                  >
                                    <Text>Room Nights</Text>
                                    <DataNew>
                                      {booking?.noOfRoomsBooked === -1
                                        ? "-"
                                        : booking?.noOfRoomsBooked}
                                    </DataNew>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: {
                                        xs: "column",
                                        md: "row",
                                      },
                                      justifyContent: "space-between",
                                      padding: "3px",
                                    }}
                                  >
                                    <Text>ADR</Text>
                                    <DataNew>
                                      {booking.averageRoomRate === -1
                                        ? "-"
                                        : Math.round(
                                            parseFloat(booking?.averageRoomRate)
                                          )}
                                    </DataNew>
                                  </Box>
                                </Box>
                              </MyCardNew>
                            ))}
                        </Box>
                      </Calender>
                    </TabPanel>
                  ))}
                </Box>
                <Box style={{ height: "calc(100vh - 150px)" }}>
                  <Wrapper
                    style={{
                      flexDirection: "column",
                      overflowY: "auto",
                      height: "calc(100% - 20px)",

                      overflowX: "hidden",

                      boxShadow: "-4px 0 4px -4px rgb(48 111 188 / 20%)",
                    }}
                  >
                    {bookings.map((booking, index) => (
                      <MyCardCalender key={index}>
                        <Header
                          className="button"
                          onClick={() => {
                            setbobData(booking);
                          }}
                        >
                          {months[new Date(booking[0].date).getMonth()]}{" "}
                          {new Date(booking[0].date).getFullYear()}
                        </Header>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <DetailNew>
                            <Box>Room Nights</Box>
                            <Total>
                              {booking[booking.length - 1].totalRooms === -1
                                ? "-"
                                : booking[booking.length - 1].totalRooms}
                            </Total>
                          </DetailNew>
                          <DetailNew>
                            <Box>ADR</Box>
                            <Total>
                              {booking[booking.length - 1].totalArr !== -1
                                ? booking[booking.length - 1].totalRooms !== 0
                                  ? Math.round(
                                      parseFloat(
                                        booking[booking.length - 1].totalArr /
                                          booking[booking.length - 1].totalRooms
                                      )
                                    )
                                  : "0"
                                : "-"}
                            </Total>
                          </DetailNew>
                        </Box>
                        <CalenderNew>
                          <WeekNew>
                            <Day>Mon</Day>
                            <Day>Tue</Day>
                            <Day>Wed</Day>
                            <Day>Thu</Day>
                            <Day>Fri</Day>
                            <Day>Sat</Day>
                            <Day>Sun</Day>
                          </WeekNew>
                        </CalenderNew>
                        <Box
                          style={{
                            gap: "3px",
                            boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                            bordeRadius: "8px",

                            padding: "8px",
                            display: "grid",
                            gridTemplateColumns: "repeat(7,27px)",
                            paddingLeft: "4px",
                            paddingRight: "4px",
                          }}
                        >
                          {Array(
                            booking[0]?.date &&
                              new Date(booking[0]?.date)?.getDay() === 0
                              ? 6
                              : parseInt(
                                  new Date(booking[0]?.date)?.getDay() - 1
                                ) || 0
                          )
                            .fill()
                            .map((data, idx) => (
                              <P></P>
                            ))}
                          {booking
                            .slice(0, booking.length - 1)
                            .map((data, idx) => (
                              <TextOnlyTooltipPopover
                                TransitionComponent={Zoom}
                                TransitionProps={{
                                  timeout: 400,
                                }}
                                disableFocusListener
                                disableTouchListener
                                placement="right-start"
                                arrow
                                interactive
                                key={idx}
                                title={
                                  <DataPopOver
                                    index={index}
                                    date={data?.date}
                                    roomNight={
                                      data.noOfRoomsBooked === -1
                                        ? "-"
                                        : data.noOfRoomsBooked
                                    }
                                    Arr={
                                      data.averageRoomRate === -1
                                        ? "-"
                                        : parseFloat(data.averageRoomRate)
                                    }
                                  />
                                }
                              >
                                <Box
                                  style={
                                    data.color
                                      ? {
                                          background: `linear-gradient(${data.color}, #FFFFFF)`,
                                          borderRight: "solid 1px #FFFFFF",
                                          width: "22px",
                                          height: "24px",
                                          display: "flex",
                                          justifyContent: "center",
                                          paddingTop: "3px",
                                          borderRadius: "4px",
                                        }
                                      : {
                                          background: "rgba(48, 81, 221,0.09)",
                                          borderRight: "solid 1px #FFFFFF",
                                          borderRadius: "4px",
                                          width: "22px",
                                          height: "24px",
                                          paddingTop: "3px",
                                          display: "flex",
                                          justifyContent: "center",
                                        }
                                  }
                                >
                                  <P>{getDateDDMM(data.date).split("/")[0]} </P>
                                </Box>
                              </TextOnlyTooltipPopover>
                            ))}
                        </Box>
                      </MyCardCalender>
                    ))}
                  </Wrapper>
                </Box>
              </Box>
            ) : (
              <LoadingPage />
            )}
          </>
        ) : (
          <Box
            style={{
              width: "100%",
              marginTop: "0px",
              padding: "0px",
            }}
          >
            {allFilesForDay.map((file, idx) => (
              <TabPanel value={fileId} key={idx} index={file.id}>
                <Wrapper
                  style={{
                    height: "calc(100vh - 132px)",
                  }}
                >
                  {bookings.map((booking, index) => (
                    <MyCard key={index}>
                      <RecommHeadNew>
                        {months[new Date(booking[0].date).getMonth()]}{" "}
                        {new Date(booking[0].date).getFullYear()}
                      </RecommHeadNew>

                      <TableContainer className="container">
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          style={{
                            width: "100%",
                            border: "1px solid rgba(0, 0, 0, 0.12)",

                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            borderRadius: "5px",
                          }}
                        >
                          <Thead>
                            <Tr>
                              <Th
                                style={{
                                  borderRadius: "4px 0px 0px 0px",
                                }}
                              >
                                Date
                              </Th>
                              <Th>Room Nights</Th>
                              <Th
                                style={{
                                  borderRadius: "0px 4px 0px 0px",
                                }}
                              >
                                ADR
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {booking
                              .slice(0, booking.length - 1)
                              .map((data, idx) => (
                                <Tr
                                  key={idx}
                                  style={
                                    idx % 2 === 0
                                      ? {
                                          backgroundColor: "white",
                                        }
                                      : {}
                                  }
                                >
                                  <Td
                                    style={{
                                      textAlign: "center",
                                      maxWidth: "50px",
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                    }}
                                  >
                                    <Box
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontFamily: "Roboto",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "20px",

                                        color: "#212121",
                                      }}
                                    >
                                      <DATE>
                                        <Box>{getDateDDMM(data.date)} </Box>
                                        <DAY>{getDay(data.date)}</DAY>
                                      </DATE>
                                    </Box>
                                  </Td>
                                  <Td
                                    style={
                                      data.color
                                        ? {
                                            background: `linear-gradient(${data.color}, #FFFFFF)`,
                                            borderRight: "solid 1px #FFFFFF",
                                            fontFamily: "Roboto",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            lineHeight: "20px",

                                            color: "#212121",
                                            borderBottom:
                                              "1px solid rgba(0, 0, 0, 0.12)",
                                          }
                                        : {
                                            borderBottom:
                                              "1px solid rgba(0, 0, 0, 0.12)",
                                          }
                                    }
                                  >
                                    {data.noOfRoomsBooked === -1
                                      ? "-"
                                      : data.noOfRoomsBooked}
                                  </Td>

                                  <Td
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                    }}
                                  >
                                    {data.averageRoomRate === -1
                                      ? "-"
                                      : Math.round(
                                          parseFloat(data.averageRoomRate)
                                        )}
                                  </Td>
                                </Tr>
                              ))}
                          </Tbody>
                          <Tbody>
                            <Tr
                              style={
                                (booking.length - 1) % 2 === 0
                                  ? {
                                      backgroundColor: "#306fbc",
                                    }
                                  : {
                                      backgroundColor: "#306fbc",
                                    }
                              }
                            >
                              <Td
                                style={{
                                  padding: "0px 16px",
                                  fontWeight: "normal",
                                }}
                              >
                                <Bottom
                                  style={{
                                    color: "white",
                                  }}
                                >
                                  Total
                                </Bottom>
                              </Td>
                              <Td>
                                <Bottom
                                  style={{
                                    color: "white",
                                  }}
                                >
                                  {booking[booking.length - 1].totalRooms === -1
                                    ? "-"
                                    : booking[booking.length - 1].totalRooms}
                                </Bottom>
                              </Td>
                              <Td>
                                <Bottom
                                  style={{
                                    color: "white",
                                  }}
                                >
                                  {booking[booking.length - 1].totalArr !== -1
                                    ? booking[booking.length - 1].totalRooms !==
                                      0
                                      ? Math.round(
                                          parseFloat(
                                            booking[booking.length - 1]
                                              .totalArr /
                                              booking[booking.length - 1]
                                                .totalRooms
                                          )
                                        )
                                      : "0"
                                    : "-"}
                                </Bottom>
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </MyCard>
                  ))}
                </Wrapper>
              </TabPanel>
            ))}
          </Box>
        )}
      </Box>
    </ClassWrapper>
  );
};
export default PricingRecommends;

function getDatesArray(startDate) {
  const dates = [];
  const date = new Date(startDate);
  const currentDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const endDateObj = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  while (currentDate <= endDateObj) {
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const dateString = `${year}-${month}-${day}`;
    dates.push(dateString);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}
function modifiedBooking(booking) {
  if (!booking) return [];
  const monthDates = getDatesArray(booking?.[0]?.date);
  const bookingByDate = booking?.reduce((acc, curr) => {
    if (!curr.date) {
      return acc;
    }
    const date = moment(curr.date).format("yyyy-MM-DD");
    return { ...acc, [date]: curr };
  }, {});
  const modifiedBooking = monthDates?.map((date) => {
    const booking = bookingByDate[date];
    if (!booking) {
      return {
        date: new Date(date).toISOString(),
        averageRoomRate: -1,
        noOfRoomsBooked: -1,
        occupancyPercentage: -1,
      };
    }
    return booking;
  });
  console.log({ modifiedBooking });
  return [...modifiedBooking, booking?.at(-1)];
}
