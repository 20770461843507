import React from "react";
import { Link } from "react-router-dom";
import {
  ErrorInfo,
  FormC,
  Header,
  Logo,
  LogoHeader,
  Precium,
  Tagline,
  Text,
} from "./Styles";

export default function EmailSent() {
  return (
    <FormC>
      <Header>
        <div>
          <LogoHeader>
            <Logo>
              <img
                src="/assets/logo-white.svg"
                alt="Logo"
                width="37px"
                height="49px"
              />
            </Logo>
            <Precium>Precium</Precium>
          </LogoHeader>
          <Tagline>Right Price Every Time</Tagline>
        </div>
      </Header>
      <ErrorInfo>
        The link to reset your password has been sent to your email address
      </ErrorInfo>
      <Link
        to="/"
        style={{
          textDecoration: "none",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Text style={{ color: "#FFFFFF" }}>Return to login screen</Text>
      </Link>
      <Link
        to="#"
        style={{
          textDecoration: "none",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Text style={{ color: "#FFFFFF" }}>Contact Us</Text>
      </Link>
    </FormC>
  );
}
