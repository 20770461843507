/*global google*/
import React, { useCallback, useState } from "react";
import { GOOGLE_MAPS_API_KEY } from "../sdk";
import {
  Circle,
  GoogleMap,
  GoogleMapProps,
  InfoWindowF,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  styled,
} from "@mui/material";

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.getDate(); // No padding needed
  const month = monthNames[date.getMonth()]; // Get month name
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

export default function MapView({
  center,
  radius,
  currentHotel,
  markers,
  ...props
}) {
  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [centerMarker, setCenterMarker] = useState(false);

  const handleOnLoad = useCallback(
    (map) => {
      if (markers && markers.length > 0) {
        const bounds = new google.maps.LatLngBounds();
        markers.eventDetails.forEach(({ position }) => bounds.extend(position));
        markers.competitorDetails.forEach(({ position }) =>
          bounds.extend(position)
        );
        map.fitBounds(bounds);
      }
    },
    [markers]
  );

  if (!isLoaded) return <CircularProgress />;

  const RadiusCircle = {
    strokeColor: "#163A90",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#163A90",
    fillOpacity: 0.06,
    center: center,
    radius: radius,
  };

  const createSvgMarker = (color) => ({
    path:
      "M216-96q-29.7 0-50.85-21.5Q144-139 144-168v-528q0-29 21.15-50.5T216-768h72v-96h72v96h240v-96h72v96h72q29.7 0 50.85 21.5Q816-725 816-696v528q0 29-21.15 50.5T744-96H216Zm0-72h528v-360H216v360Zm0-432h528v-96H216v96Zm0 0v-96 96Zm264.21 216q-15.21 0-25.71-10.29t-10.5-25.5q0-15.21 10.29-25.71t25.5-10.5q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.29 25.71t-25.5 10.5Zm-156 0q-15.21 0-25.71-10.29t-10.5-25.5q0-15.21 10.29-25.71t25.5-10.5q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.29 25.71t-25.5 10.5Zm312 0q-15.21 0-25.71-10.29t-10.5-25.5q0-15.21 10.29-25.71t25.5-10.5q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.29 25.71t-25.5 10.5Zm-156 144q-15.21 0-25.71-10.29t-10.5-25.5q0-15.21 10.29-25.71t25.5-10.5q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.29 25.71t-25.5 10.5Zm-156 0q-15.21 0-25.71-10.29t-10.5-25.5q0-15.21 10.29-25.71t25.5-10.5q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.29 25.71t-25.5 10.5Zm312 0q-15.21 0-25.71-10.29t-10.5-25.5q0-15.21 10.29-25.71t25.5-10.5q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.29 25.71t-25.5 10.5Z",
    fillColor: color,
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 0.02,
    anchor: new google.maps.Point(16, 32),
  });

  const hotelMarker = {
    path:
      "M144-96v-504h192v-72l144-144 144 144v216h192v360H144Zm72-72h72v-72h-72v72Zm0-144h72v-72h-72v72Zm0-144h72v-72h-72v72Zm228 288h72v-72h-72v72Zm0-144h72v-72h-72v72Zm0-144h72v-72h-72v72Zm0-144h72v-72h-72v72Zm228 432h72v-72h-72v72Zm0-144h72v-72h-72v72Z",
    fillColor: "#000",
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 0.02,
    anchor: new google.maps.Point(16, 32),
  };

  const blueMarker = {
    path:
      "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z", // Default marker shape
    fillColor: "#163A90",
    fillOpacity: 1,
    strokeWeight: 1,
    strokeColor: "#ffffff",
    scale: 1.5,
    anchor: new google.maps.Point(12, 24), // Align the marker to the position
  };

  return (
    <ClassWrapper>
      <GoogleMap
        zoom={12}
        center={center}
        onLoad={handleOnLoad}
        options={{
          zoomControl: true,
          disableDoubleClickZoom: false,
          fullscreenControl: false,
          mapId: "b824879bf6ae62ff",
          disableDefaultUI: true,
        }}
        {...props}
      >
        {center && (
          <MarkerF
            position={center}
            onMouseOver={() => setCenterMarker(true)}
            onMouseOut={() => setCenterMarker(false)}
          >
            {centerMarker && (
              <InfoWindowF position={center}>
                <Typography>{currentHotel?.name}</Typography>
              </InfoWindowF>
            )}
          </MarkerF>
        )}
        <Circle
          options={{
            fillColor: "#163A90",
            strokeColor: "#163A90",
            fillOpacity: 0.09,
            strokeWeight: 0.5,
            editable: false,
            draggable: false,
          }}
          center={center}
          radius={radius * 1000}
        />

        {markers?.eventDetails?.map(
          ({ id, name, position, venue, date, color }) => (
            <MarkerF
              key={id}
              position={position}
              icon={createSvgMarker(color || "blue")}
              onMouseOver={() => setHoveredMarker(id)}
              onMouseOut={() => setHoveredMarker(null)}
            >
              {hoveredMarker === id && (
                <InfoWindowF
                  options={{
                    maxWidth: 320,
                  }}
                  position={position}
                >
                  <Stack>
                    <Typography color="#163A90">{name}</Typography>
                    <Typography color="#163A90">{formatDate(date)}</Typography>
                    <Typography color="#163A90">{venue}</Typography>
                  </Stack>
                </InfoWindowF>
              )}
            </MarkerF>
          )
        )}
        {markers?.competitorDetails?.map(({ id, name, position }) => (
          <MarkerF
            key={id}
            position={position}
            icon={blueMarker}
            onMouseOver={() => setHoveredMarker(id)}
            onMouseOut={() => setHoveredMarker(null)}
          >
            {hoveredMarker === id && (
              <InfoWindowF position={position}>
                <Typography>{name}</Typography>
              </InfoWindowF>
            )}
          </MarkerF>
        ))}
      </GoogleMap>
    </ClassWrapper>
  );
}

const ClassWrapper = styled(Box)(() => ({
  ".gm-style-iw ": {
    "& .gm-ui-hover-effect": {
      display: "none !important",
    },
  },

  ".gmnoprint a, .gmnoprint span": {
    display: "none",
  },
  ".gmnoprint div": {
    background: "none !important",
  },
  ".gm-style-cc:last-child": {
    display: "none !important",
  },
}));
