import {
  Dialog,
  DialogContent,
  IconButton,
  TableCell,
  TableContainer,
  Typography,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import { getDateDDMM, getDay, getHrMin } from "../../sdk";
import {
  DATE,
  DAY,
  MonthCard,
  RemarkButton,
  Table,
  Tbody,
  Thead,
  Tr,
  Wrapper,
} from "./Styles";
import { Box as BX } from "@mui/material";
import Popover from "@mui/material/Popover";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Stack, TextField } from "@mui/material";
import { useState } from "react";
import Draggable from "react-draggable";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Cancel,
  DeleteOutline,
} from "@mui/icons-material";

const Box = styled(BX)`
  &.MuiBox-root {
    padding: 10px;
  }
`;

export const Th = styled(TableCell)`
  &.MuiTableCell-head {
    vertical-align: bottom;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    background-color: #306fbc;
    border: none;
    text-align: center;
    padding: 10px 0px;
  }
`;

export const Td = styled(TableCell)`
  &.MuiTableCell-body {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #212121;

    border: none;
    max-height: 10px;
    padding: 0 16px;
    text-align: center;
    max-width: 100px;
    width: 70px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

export const MonthHeaderNew = styled(Typography)`
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #000000;
  padding: 0px 0 8px 0;
`;
export function getDateDDMMYYYY(date) {
  const formattedDate = new Date(date);
  const day = String(formattedDate.getDate()).padStart(2, "0");
  const month = String(formattedDate.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const year = String(formattedDate.getFullYear()).slice(-2); // Get last two digits of the year

  return `${day}/${month}/${year}`;
}
export default function PaceCalculation({
  fromDate,
  toDate,
  monthlyBookings,
  selectedPickup,
  uploadImage,
  sendRemark,
  selectedImages,
  setSelectedImages,
  remarkData,
  remarksData,
  setRemarksData,
}) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let noOfLastRows =
    selectedPickup === "occPickup" ? 2 : selectedPickup === "revPickup" ? 1 : 1;
  const [isEditing, setIsEditing] = useState(false);
  const [popOver, setPopOver] = useState(undefined);
  const [noData, setNoData] = useState(false);
  const [today, setToday] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popOverDate, setPopOverDate] = useState("");
  const [disablePrevDate, setDisablePrevDate] = useState(false);
  const [futureRemark, setFutureRemark] = useState(false);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedRemarkImages, setSelectedRemarkImages] = useState([]);
  const id = !!anchorEl ? "simple-popover" : undefined;

  let previousLength = 0;

  const handleInput = (event) => {
    const bullet = "\u2022";
    const newLength = event.target.value.length;
    const characterCode = event.target.value.substr(-1).charCodeAt(0);

    if (newLength > previousLength) {
      if (characterCode === 10) {
        event.target.value = `${event.target.value}${bullet} `;
      } else if (newLength === 1) {
        event.target.value = `${bullet} ${event.target.value}`;
      }
    }
    previousLength = newLength;
  };
  const handleOpenImageDialog = (images, index) => {
    setSelectedRemarkImages(images);
    setCurrentImageIndex(index);
    setOpenImageDialog(true);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === selectedRemarkImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? selectedRemarkImages.length - 1 : prevIndex - 1
    );
  };

  const convertImageToBlob = async (image) => {
    const response = await fetch(image.uri);
    const blob = await response.blob();
    return blob;
  };

  const handleImagePreview = async (e, idx) => {
    if (e.target.files) {
      const images = [];
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        if (file.type.startsWith("image")) {
          const image = URL.createObjectURL(file);
          const blob = await convertImageToBlob({ uri: image });
          images.push(blob);
        } else {
          alert("Only image files are allowed.");
          return;
        }
      }

      const imageUrls = await uploadImage(images, popOverDate);

      // setSelectedImages((prevState) => [...prevState, ...imageUrls]);

      setPopOver((prevPopOver) => ({
        ...prevPopOver,
        remark: prevPopOver.remark.map((rem, index) =>
          index === idx
            ? {
                ...rem,
                remarkUpload: [
                  ...(rem?.remarkUpload || []),
                  ...imageUrls.map((img) => ({
                    url: img.imageURL,
                    uploadId: img.id,
                  })),
                ],
              }
            : rem
        ),
      }));
    }
  };
  const handleDeleteImage = (index, deletedImage) => {
    // setSelectedImages((prevSelectedImages) =>
    //   prevSelectedImages.filter((img) => img !== deletedImage?.url)
    // );

    setPopOver((prevPopOver) => ({
      ...prevPopOver,
      remark: prevPopOver.remark.map((rem) =>
        rem.remarkUpload?.some((upload) => upload.url === deletedImage?.url)
          ? {
              ...rem,
              remarkUpload: rem.remarkUpload.filter(
                (val) => val?.url !== deletedImage?.url
              ),
            }
          : rem
      ),
    }));
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedImages([]);
  };

  const handleSaveChanges = () => {
    setIsEditing(false);
    sendRemark(popOverDate, popOver);
    setAnchorEl(null);
  };

  const handleDeleteRemark = (indexToDelete) => {
    setPopOver((prevPopOver) => ({
      ...prevPopOver,
      remark: prevPopOver.remark.filter((_, index) => index !== indexToDelete),
    }));
  };

  // const handleDiscard = () => {
  //   setIsEditing(false);
  //   const data = remarksData.find((item) => {
  //     return (
  //       String(new Date(item?.date).toLocaleDateString()) ===
  //       String(new Date(popOverDate).toLocaleDateString())
  //     );
  //   });
  //   setPopOver(data);
  //   setSelectedImages([]);
  // };

  const handleRowClick = (date, e) => {
    setPopOverDate(date);
    const data = remarksData.find((item) => {
      return (
        String(new Date(item?.date).toLocaleDateString()) ===
        String(new Date(date)?.toLocaleDateString())
      );
    });

    if (data) {
      setPopOver(data);
      setToday(
        new Date()?.toLocaleDateString() ===
          new Date(date)?.toLocaleDateString()
      );
      setNoData(false);
    } else {
      setPopOver(undefined);
      setNoData(true);
    }
    setAnchorEl(e.currentTarget);
    setDisablePrevDate(
      String(new Date()?.toLocaleDateString()) ===
        String(new Date(date)?.toLocaleDateString())
        ? false
        : new Date() > new Date(date)
    );
    setFutureRemark(new Date() < new Date(date));
  };

  const handleAddRemark = () => {
    setPopOver((prevPopOver) => ({
      ...prevPopOver,
      remark: [
        ...(prevPopOver?.remark || []),
        {
          createdBy: "",
          createdAt: "",
          remark: "",
          remarkUpload: [],
        },
      ],
    }));
    setIsEditing(true);
  };

  console.log({ today });

  return (
    <>
      <Wrapper>
        {monthlyBookings?.map((monthList, index) => {
          return (
            <MonthCard key={index}>
              <MonthHeaderNew>
                {selectedPickup === "occPickup"
                  ? months[new Date(monthList?.date).getMonth()]
                  : months[new Date(monthList?.[0]?.date).getMonth()]}{" "}
                {selectedPickup === "occPickup"
                  ? new Date(monthList?.date).getFullYear()
                  : new Date(monthList?.[0]?.date).getFullYear()}
              </MonthHeaderNew>
              <TableContainer sx={{ height: "calc(100% - 50px)", width: 480 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  style={{
                    width: "100%",
                    border: "1px solid rgba(0, 0, 0, 0.12)",

                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "5px",
                  }}
                >
                  <Thead>
                    <Tr>
                      <Th style={{ borderRadius: "4px 0px 0px 0px" }}>Date</Th>
                      <Th>{getDateDDMM(fromDate)}</Th>
                      <Th>{getDateDDMM(toDate)}</Th>
                      <Th
                        style={
                          selectedPickup === "occPickup"
                            ? {}
                            : { borderRadius: "0px 4px 0px 0px" }
                        }
                      >
                        Pickup
                      </Th>
                      {selectedPickup === "occPickup" && (
                        <Th
                          style={{
                            textAlign: "center",
                            borderRadius: "0px 4px 0px 0px",
                          }}
                        >
                          Occ%
                        </Th>
                      )}
                    </Tr>
                  </Thead>
                  {selectedPickup === "occPickup" ? (
                    <>
                      <Tbody>
                        {monthList?.output?.map((monthDate, idx) => (
                          <Tr
                            key={idx}
                            style={
                              idx % 2 === 0 ? { backgroundColor: "white" } : {}
                            }
                          >
                            <Td>
                              <DATE>
                                <div
                                  onClick={(e) => {
                                    handleRowClick(monthDate.date, e);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {getDateDDMM(monthDate.date)}{" "}
                                </div>
                                <DAY>{getDay(monthDate.date)}</DAY>
                              </DATE>
                            </Td>
                            <Td>
                              {monthDate.fromActuals === -1 ? (
                                "-"
                              ) : selectedPickup === "arrPickup" ? (
                                <p
                                  style={
                                    monthDate.fromActuals < 0
                                      ? {
                                          color: `red`,
                                        }
                                      : {}
                                  }
                                >
                                  {" "}
                                  {parseFloat(monthDate.fromActuals).toFixed(2)}
                                </p>
                              ) : (
                                <p
                                  style={
                                    monthDate.fromActuals < 0
                                      ? {
                                          color: `red`,
                                        }
                                      : {}
                                  }
                                >
                                  {monthDate.fromActuals}
                                </p>
                              )}
                            </Td>
                            <Td>
                              {monthDate.toActuals === -1 ? (
                                "-"
                              ) : selectedPickup === "arrPickup" ? (
                                <p
                                  style={
                                    monthDate.toActuals < 0
                                      ? {
                                          color: `red`,
                                        }
                                      : {}
                                  }
                                >
                                  {parseFloat(monthDate.toActuals).toFixed(2)}
                                </p>
                              ) : (
                                <p
                                  style={
                                    monthDate.toActuals < 0
                                      ? {
                                          color: `red`,
                                        }
                                      : {}
                                  }
                                >
                                  {monthDate.toActuals}
                                </p>
                              )}
                            </Td>
                            <Td>
                              {monthDate.fromActuals === -1 ||
                              monthDate.toActuals === -1 ? (
                                "-"
                              ) : selectedPickup === "arrPickup" ? (
                                <p
                                  style={
                                    monthDate.pickup < 0
                                      ? {
                                          color: `red`,
                                        }
                                      : {}
                                  }
                                >
                                  {parseFloat(monthDate.pickup).toFixed(2)}
                                </p>
                              ) : (
                                <p
                                  style={
                                    monthDate.pickup < 0
                                      ? {
                                          color: `red`,
                                        }
                                      : {}
                                  }
                                >
                                  {monthDate.pickup}
                                </p>
                              )}
                            </Td>
                            {selectedPickup === "occPickup" && (
                              <Td
                                style={
                                  monthDate.occupancyColor
                                    ? {
                                        background: `linear-gradient(${monthDate.occupancyColor},#FFFFFF)`,
                                      }
                                    : {}
                                }
                              >
                                {monthDate.toOccupancy === -1
                                  ? "-"
                                  : `${parseFloat(
                                      monthDate.toOccupancy
                                    ).toFixed(2)}%`}
                              </Td>
                            )}
                          </Tr>
                        ))}
                      </Tbody>
                      <Tbody>
                        <Tr
                          style={{
                            backgroundColor: "#306fbc",
                            height: "36px",
                            position: "sticky",
                            bottom: "36px",
                            color: "#ffffff",
                          }}
                        >
                          <Td
                            style={
                              selectedPickup === "occPickup"
                                ? {
                                    color: "#ffffff",
                                    borderRadius: monthList[
                                      monthList.length - (noOfLastRows - 1)
                                    ]
                                      ? "0px"
                                      : "0px 0px 0px 4px",
                                  }
                                : {
                                    color: "#ffffff",
                                    borderRadius: monthList[
                                      monthList.length - (noOfLastRows - 1)
                                    ]
                                      ? "0px"
                                      : "0px 0px 0px 4px",
                                  }
                            }
                          >
                            <b>Total</b>
                          </Td>
                          <Td style={{ color: "#ffffff" }}>
                            <b>
                              {monthList?.totalFromActuals === -1
                                ? "-"
                                : monthList?.totalFromActuals}
                            </b>
                          </Td>
                          <Td style={{ color: "#ffffff" }}>
                            <b>
                              {monthList?.totalToActuals === -1
                                ? "-"
                                : monthList?.totalToActuals}
                            </b>
                          </Td>
                          <Td
                            style={{
                              color: "#ffffff",
                              borderRadius: monthList[
                                monthList.length - (noOfLastRows - 1)
                              ]
                                ? "0px"
                                : "0px 0px 4px 0px",
                            }}
                          >
                            <b>
                              {monthList?.totalPickup === "NaN" ||
                              monthList?.totalPickup === undefined ? (
                                ""
                              ) : monthList?.totalToActuals === -1 ||
                                monthList?.totalFromActuals === -1 ? (
                                "-"
                              ) : monthList?.totalPickup < 0 ? (
                                <p
                                  style={{
                                    color: `white`,
                                  }}
                                >
                                  -(
                                  {Math.abs(monthList?.totalPickup)})
                                </p>
                              ) : (
                                monthList?.totalPickup
                              )}
                            </b>
                          </Td>

                          <Td></Td>
                        </Tr>
                      </Tbody>

                      <Tbody>
                        <Tr
                          style={{
                            backgroundColor: "#306fbc",
                            height: "36px",
                            position: "sticky",
                            bottom: "0px",
                          }}
                        >
                          <Td style={{ borderRadius: "0px 0px 0px 4px" }}></Td>
                          <Td style={{ color: "#ffffff" }}>
                            <b>
                              {parseFloat(
                                monthList?.fromActualPercentage
                              ).toFixed(2) + "%"}
                            </b>
                          </Td>
                          <Td style={{ color: "#ffffff" }}>
                            <b>
                              {parseFloat(
                                monthList?.toActualPercentage
                              ).toFixed(2) + "%"}
                            </b>
                          </Td>
                          <Td style={{ color: "#ffffff" }}>
                            <b>
                              {parseFloat(
                                monthList?.totalPickUpPercentage
                              ).toFixed(2) + "%"}
                            </b>
                          </Td>
                          <Td style={{ borderRadius: "0px 0px 4px 0px" }}></Td>
                        </Tr>
                      </Tbody>
                    </>
                  ) : (
                    <>
                      <Tbody>
                        {monthList
                          .slice(0, monthList.length - noOfLastRows)
                          ?.map((monthDate, idx) => (
                            <Tr
                              key={idx}
                              style={
                                idx % 2 === 0
                                  ? { backgroundColor: "white" }
                                  : {}
                              }
                            >
                              <Td>
                                <DATE>
                                  <div
                                    onClick={(e) => {
                                      handleRowClick(monthDate.date, e);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {getDateDDMM(monthDate.date)}{" "}
                                  </div>
                                  <DAY>{getDay(monthDate.date)}</DAY>
                                </DATE>
                              </Td>
                              <Td>
                                {monthDate.fromActuals === -1 ? (
                                  "-"
                                ) : selectedPickup === "arrPickup" ? (
                                  <p
                                    style={
                                      monthDate.fromActuals < 0
                                        ? {
                                            color: `red`,
                                          }
                                        : {}
                                    }
                                  >
                                    {" "}
                                    {parseFloat(monthDate.fromActuals).toFixed(
                                      2
                                    )}
                                  </p>
                                ) : (
                                  <p
                                    style={
                                      monthDate.fromActuals < 0
                                        ? {
                                            color: `red`,
                                          }
                                        : {}
                                    }
                                  >
                                    {monthDate.fromActuals}
                                  </p>
                                )}
                              </Td>
                              <Td>
                                {monthDate.toActuals === -1 ? (
                                  "-"
                                ) : selectedPickup === "arrPickup" ? (
                                  <p
                                    style={
                                      monthDate.toActuals < 0
                                        ? {
                                            color: `red`,
                                          }
                                        : {}
                                    }
                                  >
                                    {parseFloat(monthDate.toActuals).toFixed(2)}
                                  </p>
                                ) : (
                                  <p
                                    style={
                                      monthDate.toActuals < 0
                                        ? {
                                            color: `red`,
                                          }
                                        : {}
                                    }
                                  >
                                    {monthDate.toActuals}
                                  </p>
                                )}
                              </Td>
                              <Td>
                                {monthDate.fromActuals === -1 ||
                                monthDate.toActuals === -1 ? (
                                  "-"
                                ) : selectedPickup === "arrPickup" ? (
                                  <p
                                    style={
                                      monthDate.pickup < 0
                                        ? {
                                            color: `red`,
                                          }
                                        : {}
                                    }
                                  >
                                    {parseFloat(monthDate.pickup).toFixed(2)}
                                  </p>
                                ) : (
                                  <p
                                    style={
                                      monthDate.pickup < 0
                                        ? {
                                            color: `red`,
                                          }
                                        : {}
                                    }
                                  >
                                    {monthDate.pickup}
                                  </p>
                                )}
                              </Td>
                              {selectedPickup === "occPickup" && (
                                <Td
                                  style={
                                    monthDate.occupancyColor
                                      ? {
                                          background: `linear-gradient(${monthDate.occupancyColor},#FFFFFF)`,
                                        }
                                      : {}
                                  }
                                >
                                  {monthDate.toOccupancy === -1
                                    ? "-"
                                    : `${parseFloat(
                                        monthDate.toOccupancy
                                      ).toFixed(2)}%`}
                                </Td>
                              )}
                            </Tr>
                          ))}
                      </Tbody>
                      {monthList[monthList.length - noOfLastRows] && (
                        <Tbody>
                          <Tr
                            style={
                              (monthList.length - noOfLastRows) % 2 === 0
                                ? {
                                    backgroundColor: "#306fbc",
                                    height: "36px",
                                    position: "sticky",
                                    bottom: monthList[
                                      monthList.length - (noOfLastRows - 1)
                                    ]
                                      ? "36px"
                                      : "0px",

                                    color: "#ffffff",
                                  }
                                : {
                                    backgroundColor: "#306fbc",
                                    height: "36px",
                                    position: "sticky",
                                    bottom: monthList[
                                      monthList.length - (noOfLastRows - 1)
                                    ]
                                      ? "36px"
                                      : "0px",
                                    color: "#ffffff",
                                  }
                            }
                          >
                            <Td
                              style={
                                selectedPickup === "occPickup"
                                  ? {
                                      color: "#ffffff",
                                      borderRadius: monthList[
                                        monthList.length - (noOfLastRows - 1)
                                      ]
                                        ? "0px"
                                        : "0px 0px 0px 4px",
                                    }
                                  : {
                                      color: "#ffffff",
                                      borderRadius: monthList[
                                        monthList.length - (noOfLastRows - 1)
                                      ]
                                        ? "0px"
                                        : "0px 0px 0px 4px",
                                    }
                              }
                            >
                              <b>Total</b>
                            </Td>
                            <Td style={{ color: "#ffffff" }}>
                              <b>
                                {monthList[monthList.length - noOfLastRows]
                                  .totalFromActuals === -1
                                  ? "-"
                                  : selectedPickup === "arrPickup"
                                  ? parseFloat(
                                      monthList[monthList.length - noOfLastRows]
                                        .totalFromActuals
                                    ).toFixed(2)
                                  : monthList[monthList.length - noOfLastRows]
                                      .totalFromActuals}
                              </b>
                            </Td>
                            <Td style={{ color: "#ffffff" }}>
                              <b>
                                {monthList[monthList.length - noOfLastRows]
                                  .totalToActuals === -1
                                  ? "-"
                                  : selectedPickup === "arrPickup"
                                  ? parseFloat(
                                      monthList[monthList.length - noOfLastRows]
                                        .totalToActuals
                                    ).toFixed(2)
                                  : monthList[monthList.length - noOfLastRows]
                                      .totalToActuals}
                              </b>
                            </Td>
                            <Td
                              style={{
                                color: "#ffffff",
                                borderRadius: monthList[
                                  monthList.length - (noOfLastRows - 1)
                                ]
                                  ? "0px"
                                  : "0px 0px 4px 0px",
                              }}
                            >
                              <b>
                                {monthList[monthList.length - noOfLastRows]
                                  .totalPickup === "NaN" ||
                                monthList[monthList.length - noOfLastRows]
                                  .totalPickup === undefined ? (
                                  ""
                                ) : monthList[monthList.length - noOfLastRows]
                                    .totalToActuals === -1 ||
                                  monthList[monthList.length - noOfLastRows]
                                    .totalFromActuals === -1 ? (
                                  "-"
                                ) : monthList[monthList.length - noOfLastRows]
                                    .totalPickup < 0 ? (
                                  <p
                                    style={{
                                      color: `white`,
                                    }}
                                  >
                                    -(
                                    {Math.abs(
                                      monthList[monthList.length - noOfLastRows]
                                        .totalPickup
                                    )}
                                    )
                                  </p>
                                ) : selectedPickup === "arrPickup" ? (
                                  parseFloat(
                                    monthList[monthList.length - noOfLastRows]
                                      .totalPickup
                                  )?.toFixed(2)
                                ) : (
                                  monthList[monthList.length - noOfLastRows]
                                    .totalPickup
                                )}
                              </b>
                            </Td>
                            {monthList[
                              monthList.length - (noOfLastRows - 1)
                            ] && <Td></Td>}
                          </Tr>
                        </Tbody>
                      )}
                      {monthList[monthList.length - (noOfLastRows - 1)] && (
                        <Tbody>
                          <Tr
                            style={
                              (monthList.length - (noOfLastRows - 1)) % 2 === 0
                                ? {
                                    backgroundColor: "#306fbc",
                                    height: "36px",
                                    position: "sticky",
                                    bottom: "0px",
                                  }
                                : {
                                    backgroundColor: "#306fbc",
                                    height: "36px",
                                    position: "sticky",
                                    bottom: "0px",
                                  }
                            }
                          >
                            <Td
                              style={{ borderRadius: "0px 0px 0px 4px" }}
                            ></Td>
                            <Td style={{ color: "#ffffff" }}>
                              <b>
                                {monthList[
                                  monthList.length - (noOfLastRows - 1)
                                ].totalFromOccupancy === -1 ||
                                monthList[monthList.length - (noOfLastRows - 1)]
                                  .totalFromOccupancy === undefined
                                  ? "-"
                                  : monthList[
                                      monthList.length - (noOfLastRows - 1)
                                    ].totalFromOccupancy + "%"}
                              </b>
                            </Td>
                            <Td style={{ color: "#ffffff" }}>
                              <b>
                                {monthList[
                                  monthList.length - (noOfLastRows - 1)
                                ].totalToOccupancy === -1 ||
                                monthList[monthList.length - (noOfLastRows - 1)]
                                  .totalToOccupancy === undefined
                                  ? "-"
                                  : monthList[
                                      monthList.length - (noOfLastRows - 1)
                                    ].totalToOccupancy + "%"}
                              </b>
                            </Td>
                            <Td style={{ color: "#ffffff" }}>
                              <b>
                                {monthList[
                                  monthList.length - (noOfLastRows - 1)
                                ].totalToOccupancy === -1 ||
                                monthList[monthList.length - (noOfLastRows - 1)]
                                  .totalFromOccupancy === -1
                                  ? "-"
                                  : monthList[
                                      monthList.length - (noOfLastRows - 1)
                                    ].totalPickupOccupancy + "%"}
                              </b>
                            </Td>
                            <Td
                              style={{ borderRadius: "0px 0px 4px 0px" }}
                            ></Td>
                          </Tr>
                        </Tbody>
                      )}
                    </>
                  )}
                </Table>
              </TableContainer>
            </MonthCard>
          );
        })}
      </Wrapper>

      <Draggable>
        {noData || today ? (
          disablePrevDate ? (
            <Popover
              id={id}
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              sx={{
                "& .MuiPopover-paper": {
                  border: "1px solid #000000",
                  maxWidth: "587px",
                  maxHeight: "435px",
                },
              }}
            >
              <Stack direction={"row"} p={2} gap={4}>
                <Typography>No Remark</Typography>
                <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
              </Stack>
            </Popover>
          ) : futureRemark ? (
            <>
              <div style={{ cursor: "default" }}></div>
            </>
          ) : (
            <Popover
              id={id}
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              sx={{
                "& .MuiPopover-paper": {
                  border: "1px solid #000000",
                  maxWidth: "587px",
                  maxHeight: "550px",
                  paddingBottom: "16px",
                  minWidth: "500px",
                },
              }}
            >
              <>
                <Stack
                  direction="row"
                  sx={{
                    position: "sticky",
                    top: 0,
                    py: "12px",
                    background: "white",
                    zIndex: 5,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      flexGrow: 1,
                      textAlign: "center",
                      cursor: "move",
                    }}
                  >
                    Remark of{" "}
                    {getDateDDMMYYYY(
                      popOver?.date ? popOver?.date : popOverDate
                    )}{" "}
                  </Typography>
                  <CloseIcon
                    sx={{ cursor: "pointer", mr: "10px" }}
                    onClick={handleClose}
                  />
                </Stack>

                <Stack gap={1} mb={2} px={2}>
                  {popOver?.remark?.map((item, idx) => (
                    <Stack
                      sx={{
                        border: "1px solid #7474744D",
                        borderRadius: "8px",
                        position: "relative",
                      }}
                      gap={"10px"}
                      pl={2}
                      pr={"20px"}
                    >
                      {!item?.id && (
                        <DeleteOutline
                          sx={{
                            cursor: "pointer",
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            color: "#f1592b",
                          }}
                          onClick={() => handleDeleteRemark(idx)}
                        />
                      )}
                      {item?.createdAt && (
                        <Stack direction="row" justifyContent={"space-between"}>
                          <Typography
                            sx={{ fontWeight: "500", fontSize: "14px" }}
                          >
                            {item?.createdBy ?? ""}{" "}
                          </Typography>
                          <Typography
                            sx={{ fontWeight: "500", fontSize: "14px" }}
                          >
                            {getDateDDMMYYYY(popOver?.date)}{" "}
                            {getHrMin(item?.createdAt ?? "")}{" "}
                          </Typography>
                        </Stack>
                      )}
                      <TextField
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                        multiline
                        fullWidth
                        value={item?.remark}
                        onChange={(e) => {
                          const updatedRemarkText = e.target.value;
                          setPopOver((prevPopOver) => ({
                            ...prevPopOver,
                            remark: prevPopOver.remark.map((rem, index) =>
                              index === idx
                                ? { ...rem, remark: updatedRemarkText }
                                : rem
                            ),
                          }));
                        }}
                        variant="standard"
                        placeholder="Type your remark..."
                        InputProps={{ disableUnderline: "true" }}
                        // onInput={handleInput}
                      />

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: "row",
                          gap: "8px",
                        }}
                      >
                        {[
                          ...(!!item?.remarkUpload?.length
                            ? item?.remarkUpload
                            : []),
                        ].map((img, index) => {
                          return (
                            <Box
                              // mt={3}
                              key={index}
                              style={{
                                position: "relative",
                                padding: "0px",
                              }}
                            >
                              <CloseIcon
                                style={{
                                  cursor: "pointer",
                                  position: "absolute",
                                  top: "-4px",
                                  right: "-8px",
                                  color: "white",
                                  backgroundColor: "#6C7270",
                                  borderRadius: "50%",
                                  height: "17px",
                                  width: "18px",
                                }}
                                onClick={() => handleDeleteImage(index, img)}
                              />

                              <img
                                src={img?.url}
                                alt="Preview"
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  objectFit: "cover",
                                  margin: 1,
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleOpenImageDialog(
                                    item?.remarkUpload,
                                    index
                                  )
                                }
                              />
                            </Box>
                          );
                        })}

                        <label>
                          <Stack
                            direction="row"
                            ml={2}
                            // mt={"4px"}
                            height={"100%"}
                            alignItems={"center"}
                          >
                            <AddPhotoAlternateIcon
                              onClick={(e) => handleImagePreview(e, idx)}
                              sx={{ color: "grey", cursor: "pointer" }}
                            />
                            <Typography
                              sx={{
                                fontWeight: 500,
                                color: "grey",
                                ml: 1,
                                cursor: "pointer",
                              }}
                            >
                              Add Image
                            </Typography>
                            <TextField
                              type="file"
                              InputProps={{
                                disableUnderline: true,
                              }}
                              sx={{ visibility: "hidden", width: "50px" }}
                              variant="standard"
                              onChange={(e) => handleImagePreview(e, idx)}
                              multiple
                            />
                          </Stack>
                        </label>
                      </div>
                    </Stack>
                  ))}
                  <RemarkButton onClick={handleAddRemark}>
                    + Add Remark
                  </RemarkButton>
                </Stack>
                <Stack
                  px={2}
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveChanges}
                    sx={{
                      borderRadius: 2,
                      textTransform: "none",
                      height: 40,
                      fontSize: "14px",
                    }}
                  >
                    Save Changes
                  </Button>
                </Stack>
              </>
            </Popover>
          )
        ) : (
          <Popover
            id={id}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            sx={{
              "& .MuiPopover-paper": {
                border: "1px solid #000000",
                maxWidth: "587px",
                maxHeight: "550px",
                minHeight: "400px",
                minWidth: "500px",
              },
            }}
          >
            <>
              <Stack
                sx={{
                  position: "sticky",
                  top: 0,
                  py: "4px",
                  background: "white",
                  zIndex: 5,
                }}
                direction="row"
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    flexGrow: 1,
                    textAlign: "center",
                    cursor: "move",
                  }}
                >
                  Remark of{" "}
                  {getDateDDMMYYYY(popOver?.date ? popOver?.date : popOverDate)}{" "}
                </Typography>
                <CloseIcon
                  sx={{ cursor: "pointer", mr: "10px" }}
                  onClick={handleClose}
                />
              </Stack>

              <Stack gap={1} my={2} px={2}>
                {popOver?.remark?.map((item, idx) => (
                  <Stack
                    sx={{ border: "1px solid #7474744D", borderRadius: "8px" }}
                    gap={1}
                    px={2}
                  >
                    <Stack direction="row" justifyContent={"space-between"}>
                      <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
                        {item?.createdBy ?? ""}{" "}
                      </Typography>
                      <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
                        {getDateDDMMYYYY(popOver?.date)}{" "}
                        {getHrMin(item?.createdAt ?? "")}{" "}
                      </Typography>
                    </Stack>
                    <TextField
                      disabled={!isEditing}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                      multiline
                      fullWidth
                      value={item?.remark}
                      onChange={(e) => {
                        const updatedRemarkText = e.target.value;
                        setPopOver((prevPopOver) => ({
                          ...prevPopOver,
                          remark: prevPopOver.remark.map((rem, index) =>
                            index === idx
                              ? { ...rem, remark: updatedRemarkText }
                              : rem
                          ),
                        }));
                      }}
                      variant="standard"
                      placeholder="Type your remark..."
                      InputProps={{ disableUnderline: "true" }}
                      // onInput={handleInput}
                    />

                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        gap: "8px",
                      }}
                    >
                      {[
                        ...(!!item?.remarkUpload?.length
                          ? item?.remarkUpload
                          : []),
                      ].map((img, index) => {
                        console.log({ img });
                        return (
                          <Box
                            // mt={3}
                            key={index}
                            style={{
                              position: "relative",
                              padding: "0px",
                            }}
                          >
                            {isEditing && (
                              <CloseIcon
                                style={{
                                  cursor: "pointer",
                                  position: "absolute",
                                  top: "-4px",
                                  right: "-8px",
                                  color: "white",
                                  backgroundColor: "#6C7270",
                                  borderRadius: "50%",
                                  height: "17px",
                                  width: "18px",
                                }}
                                onClick={() => handleDeleteImage(index, img)}
                              />
                            )}

                            <img
                              src={img?.url}
                              alt="Preview"
                              style={{
                                height: "50px",
                                width: "50px",
                                objectFit: "cover",
                                margin: 1,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleOpenImageDialog(item?.remarkUpload, index)
                              }
                            />
                          </Box>
                        );
                      })}
                      {isEditing && (
                        <label>
                          <Stack
                            direction="row"
                            ml={2}
                            // mt={"4px"}
                            height={"100%"}
                            alignItems={"center"}
                          >
                            <AddPhotoAlternateIcon
                              onClick={(e) => handleImagePreview(e, idx)}
                              sx={{ color: "grey", cursor: "pointer" }}
                            />
                            <Typography
                              sx={{
                                fontWeight: 500,
                                color: "grey",
                                ml: 1,
                                cursor: "pointer",
                              }}
                            >
                              Add Image
                            </Typography>
                            <TextField
                              type="file"
                              InputProps={{
                                disableUnderline: true,
                              }}
                              sx={{ visibility: "hidden", width: "50px" }}
                              variant="standard"
                              onChange={(e) => handleImagePreview(e, idx)}
                              multiple
                            />
                          </Stack>
                        </label>
                      )}
                    </div>
                  </Stack>
                ))}
              </Stack>
            </>
          </Popover>
        )}
      </Draggable>
      <Dialog
        open={openImageDialog}
        onClose={handleCloseImageDialog}
        fullWidth
        height="400px"
        maxWidth="sm"
      >
        <DialogContent sx={{ position: "relative" }}>
          <IconButton
            sx={{ position: "absolute", top: 8, right: 8 }}
            onClick={handleCloseImageDialog}
          >
            <CloseIcon />
          </IconButton>

          <Stack direction="row" alignItems="center" justifyContent="center">
            <IconButton onClick={handlePrevImage}>
              <ArrowBackIos />
            </IconButton>

            <Box
              component="img"
              src={selectedRemarkImages[currentImageIndex]?.url}
              alt="Selected Preview"
              sx={{
                height: "300px",
                width: "500px",
                objectFit: "contain",
                borderRadius: 1,
              }}
            />

            <IconButton onClick={handleNextImage}>
              <ArrowForwardIos />
            </IconButton>
          </Stack>

          <Typography variant="caption" display="block" align="center" mt={2}>
            {currentImageIndex + 1} / {selectedRemarkImages.length}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}
