import { Box, Snackbar, SnackbarContent } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../sdk";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import { Main } from "./components/Styles";

export default function ForgotPasswordPage() {
  const history = useHistory();
  const [networkMsg, setnetworkMsg] = useState(null);
  const [open, setOpen] = useState(false);
  const [iswrongEmail, setWrongEmail] = useState(false);

  const password = async function sendPassword({ email, username }) {
    try {
      await fetch(`${BASE_URL}/forget-password`, {
        method: "POST",
        body: JSON.stringify({ email: email, username: username }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((res) => {
          if (res.ok) {
            history.push(`/link_sent_to_email`);
          } else {
            setWrongEmail(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      setnetworkMsg("Invalid Details");
      setOpen(true);

      console.log(e);
    }
  };

  function handleCloseStatus() {
    setOpen(false);
  }

  return (
    <Main>
      <Box>
        <ForgotPasswordForm
          password={password}
          iswrongEmail={iswrongEmail}
          setWrongEmail={setWrongEmail}
        />
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseStatus}
      >
        <SnackbarContent
          style={{ backgroundColor: "#CA3433" }}
          message={networkMsg}
        />
      </Snackbar>
    </Main>
  );
}
