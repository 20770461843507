import {
  Box,
  Checkbox,
  Input,
  ListItemText,
  MenuItem,
  Typography,
  Table as Tb,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { Select, TextFieldSmall } from "../Styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker as KDP } from "@mui/lab";
import { Index } from "../../PrimarySetups/components/Styles";
import { getISODate, GhostButton } from "../../sdk";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const TextFieldBig = styled(TextFieldSmall)`
  width: 200px;
`;
const KeyboardDatePicker = styled(KDP)`
  button.MuiButtonBase-root.MuiIconButton-root {
    padding: 4px;
  }
  (Box).MuiFormControl-root.MuiTextField-root {
    min-width: 132px;
  }
  &&& {
    margin-right: 70px;
  }
`;

const HeadNFooter = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled(Typography)`
  font: normal normal 600 16px/20px Roboto;
  letter-spacing: 0px;
  color: #333333;
`;

const Table = styled(Tb)`
  border-collapse: separate;
  border-spacing: 10px;
  margin: auto;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.25;
`;

const Th = styled(TableHead)`
  vertical-align: top;
  text-align: left;
  font: normal normal bold 16px/20px Roboto;
  color: #130453;
  margin: 5px;
`;
const Td = styled(TableCell)`
  text-align: left;
  width: 50px;
  margin: 5px;
`;
const Tr = styled(TableRow)``;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const StyledBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  Width: 1100,
  height: 326,
  backgroundColor: "#fff",
  boxShadow: 5,
  borderRadius: 14,
  padding: 2,
}));

const Div = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;
const Days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export function BaseDemandModel({
  setOpenModel,
  state,
  addRow,
  patchRowValue,
  setFromSetModel,
}) {
  const [modalStyle] = React.useState(getModalStyle);

  const handleSubmit = async (e) => {
    setFromSetModel(true);
    setOpenModel(false);
  };

  return (
    <StyledBox style={modalStyle}>
      <HeadNFooter>
        <Text>Set base demand level</Text>
        <Box
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            setOpenModel(false);
          }}
        >
          &#10060;
        </Box>
      </HeadNFooter>

      <form pricing id="form">
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
        <Table>
          <TableHead>
            <Tr>
              <Th></Th>
              <Th>Start date</Th>
              <Th>End date</Th>
              <Th>Days Selection</Th>
              <Th>
                Base Demand
                <br />
                Level
              </Th>
              <Th>Reason</Th>
            </Tr>
          </TableHead>
          {state.rows.map((row, idx) => (
            <TableBody>
              <Tr>
                <Td>
                  <Index>{idx + 1}.</Index>
                </Td>
                <Td>
                  <KeyboardDatePicker
                    variant="inline"
                    autoOk
                    format="dd/MM/yyyy"
                    value={row.startDate}
                    onChange={(startDate) =>
                      patchRowValue("startDate", getISODate(startDate), idx)
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    style={{ width: "130px" }}
                  />
                </Td>
                <Td>
                  {/* <KeyboardDatePicker
                      variant="inline"
                      autoOk
                      format="dd/MM/yyyy"
                      value={row.endDate}
                      onChange={(endDate) =>
                        patchRowValue("endDate", getISODate(endDate), idx)
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      style={{ width: "130px" }}
                    /> */}
                </Td>
                <Td>
                  <Select
                    label="Days"
                    multiple
                    value={row.daysSelecton}
                    onChange={(e) => {
                      patchRowValue("daysSelecton", e.target.value, idx);
                    }}
                    input={<Input />}
                    MenuProps={{ getContentAnchorEl: null }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Placeholder</em>;
                      }

                      return selected.join(", ");
                    }}
                    style={{ minWidth: "130px" }}
                  >
                    {Days.map((name, index) => (
                      <MenuItem key={name} value={name}>
                        <ListItemText primary={name} />
                        <Checkbox
                          checked={row.daysSelecton.indexOf(name) > -1}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </Td>
                <Td>
                  <Select
                    value={row.baseDemandLevel}
                    style={{ minWidth: "130px" }}
                    onChange={(e) =>
                      patchRowValue("baseDemandLevel", e.target.value, idx)
                    }
                  >
                    <MenuItem value={"EH"}>EH</MenuItem>
                    <MenuItem value={"H+"}>H+</MenuItem>
                    <MenuItem value={"H"}>H</MenuItem>
                    <MenuItem value={"M"}>M</MenuItem>
                    <MenuItem value={"L"}>L</MenuItem>
                    <MenuItem value={"D"}>D</MenuItem>
                  </Select>
                </Td>
                <Td>
                  <TextFieldBig
                    multiline
                    value={row.reason}
                    onChange={(e) =>
                      patchRowValue("reason", e.target.value, idx)
                    }
                    style={{ width: "200px" }}
                  />
                </Td>
              </Tr>
            </TableBody>
          ))}
        </Table>
        {/* </LocalizationProvider> */}
      </form>
      <HeadNFooter>
        <GhostButton onClick={addRow}>+ Add</GhostButton>
        <GhostButton onClick={handleSubmit}>Save</GhostButton>
      </HeadNFooter>
    </StyledBox>
  );
}
