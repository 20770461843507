import React from "react";
import { styled } from "@mui/system";
import { TextField } from "@mui/material";

const InputComp = styled(TextField)`
  width: 300px;
  height: 52px;
  &&& {
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 8px;
    font: normal Regular 24px/28px "Roboto", sans-serif;
    letter-spacing: 0px;
    color: #9a9a9a;
    padding: 0 24px;
  }
  .MuiInput-underline:after,
  .MuiInput-underline:before {
    border: none;
    content: none;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
    content: none;
  }
  .MuiInputBase-input {
    padding: 0;
    height: 50px;
    &::placeholder {
      font: 300 Italic 24px "Roboto", sans-serif;
    }
  }
`;

export const Input = (props) => {
  return <InputComp {...props} />;
};
