import {
  Table,
  TableContainer,
  Tooltip,
  Typography,
  Box,
  tooltipClasses,
} from "@mui/material";
import { Delete as DEL } from "@mui/icons-material";
import React from "react";
import { styled } from "@mui/system";
import { Row } from "../../../sdk";
import { Tbody, Td, Th, Thead } from "../Styles";
import Popover from "./Popover.js";

const StyledTableBox = styled(Box)(() => ({
  ".root": {
    width: "100%",
  },
  ".container": {
    maxHeight: 780,
    minWidth: 500,
  },
  ".arrow": {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  ".rowHover": {
    "&:hover": {
      color: "red",

      backgroundColor: "rgba(48, 81, 221,0.4) !important",
    },
  },
}));

function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  if (parseInt(hours) <= 9) {
    hours = "0" + hours;
  }
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime.toUpperCase();
}

const TextOnlyTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff !important",
    color: "#000",
    fontSize: "12px",
    lineHeight: "16px",
    fontFamily: "Roboto, sans-serif",
    width: "268px",
    padding: "15px",
    border: "1px solid #A0A0A0",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#ffffff",
    "&:before": {
      border: "1px solid #A0A0A0",
    },
  },
}));

const Dots = styled(Typography)`
  :hover {
    cursor: pointer;
  }
`;

const Days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export default function UploadTable({
  data,
  setShouldBlockNavigation,
  setFile,
  setFileType,
  fileUploadedDelete,
}) {
  return (
    <StyledTableBox>
      <Table>
        <Thead>
          <Row>
            <Th style={{ paddingLeft: "16px" }}>Date</Th>
            <Th style={{ paddingLeft: "10px" }}>Business on Books</Th>
            <Th style={{ paddingLeft: "6px" }}>Competitor Price</Th>
            <Th style={{ paddingLeft: "6px" }}>Last Night Actuals</Th>
          </Row>
        </Thead>
        <Tbody />
      </Table>
      <TableContainer className="container" style={{ maxHeight: "58vh" }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ position: "relative" }}
        >
          <Thead />
          {data?.map((log, index) => (
            <Tbody key={index}>
              <Row
                key={log.id}
                style={
                  index % 2 === 0
                    ? { backgroundColor: "rgba(48, 81, 221,0.09)" }
                    : {}
                }
              >
                <Td sx={{ borderBottom: "none" }}>
                  <div style={{ paddingRight: "16px" }}>
                    {new Date(log.date).toLocaleDateString("en-GB")}
                  </div>
                  <div>{Days[new Date(log.date).getDay()]}</div>
                </Td>
                {log.forecastFile ? (
                  <Td sx={{ borderBottom: "none", marginTop: "12px" }}>
                    <div style={{ paddingRight: "10px" }}>
                      {new Date(log.forecastFile[0].time).toLocaleDateString(
                        "en-GB"
                      )}
                    </div>
                    <div style={{ paddingRight: "10px" }}>
                      {formatAMPM(
                        new Date(
                          log.forecastFile[log.forecastFile.length - 1].time
                        )
                      )}
                    </div>
                    <div>{log.forecastFile[0].username}</div>
                    {log.forecastFile.length > 1 ? (
                      <TextOnlyTooltip
                        disableFocusListener
                        disableTouchListener
                        interactive
                        placement="right-start"
                        onHover={() => setFileType("forecast")}
                        arrow
                        title={
                          <Popover
                            header={"Business on Books"}
                            date={log.date}
                            logs={log.forecastFile}
                            setShouldBlockNavigation={setShouldBlockNavigation}
                            setFile={setFile}
                            setFileType={setFileType}
                            filetype={"forecast"}
                            file={log.forecastFile[0]}
                            fileUploadedDelete={fileUploadedDelete}
                          />
                        }
                      >
                        <Dots>&nbsp;...</Dots>
                      </TextOnlyTooltip>
                    ) : (
                      fileUploadedDelete && (
                        <Dots
                          onClick={() => {
                            setFileType("forecast");
                            setFile(log.forecastFile[0]);
                            setShouldBlockNavigation(true);
                          }}
                        >
                          <DEL
                            style={{
                              fontSize: 18,
                              marginLeft: 2,
                              marginBottom: 3,
                            }}
                          />
                        </Dots>
                      )
                    )}
                  </Td>
                ) : (
                  <Td sx={{ borderBottom: "none" }}>-</Td>
                )}
                {log.competitorFile ? (
                  <Td sx={{ borderBottom: "none", marginTop: "12px" }}>
                    <div style={{ paddingRight: "10px" }}>
                      {new Date(log.competitorFile[0].time).toLocaleDateString(
                        "en-GB"
                      )}
                    </div>
                    <div style={{ paddingRight: "10px" }}>
                      {formatAMPM(new Date(log.competitorFile[0].time))}
                    </div>
                    <div>{log.competitorFile[0].username}</div>
                    {log.competitorFile.length > 1 ? (
                      <TextOnlyTooltip
                        disableFocusListener
                        disableTouchListener
                        interactive
                        placement="right-start"
                        onHover={() => setFileType("competitor")}
                        arrow
                        title={
                          <Popover
                            header={"Competitor Price"}
                            date={log.date}
                            logs={log.competitorFile}
                            setShouldBlockNavigation={setShouldBlockNavigation}
                            setFile={setFile}
                            setFileType={setFileType}
                            filetype={"competitor"}
                            file={log.competitorFile[0]}
                            fileUploadedDelete={fileUploadedDelete}
                          />
                        }
                      >
                        <Dots>&nbsp;...</Dots>
                      </TextOnlyTooltip>
                    ) : (
                      fileUploadedDelete && (
                        <Dots
                          onClick={() => {
                            setFileType("competitor");
                            setFile(log.competitorFile[0]);
                            setShouldBlockNavigation(true);
                          }}
                        >
                          <DEL
                            style={{
                              fontSize: 18,
                              marginLeft: 2,
                              marginBottom: 3,
                            }}
                          />
                        </Dots>
                      )
                    )}
                  </Td>
                ) : (
                  <Td sx={{ borderBottom: "none" }}>-</Td>
                )}
                {log.arr ? (
                  <Td sx={{ borderBottom: "none" }}>
                    <div style={{ paddingRight: "10px" }}>
                      {new Date(log.arr[0].time).toLocaleDateString("en-GB")}
                    </div>

                    <div style={{ paddingRight: "10px" }}>
                      {formatAMPM(new Date(log.arr[0].time))}
                    </div>
                    <div>{log.arr[0].username}</div>
                    {log.arr.length > 1 && (
                      <TextOnlyTooltip
                        disableFocusListener
                        disableTouchListener
                        placement="right-start"
                        arrow
                        title={
                          <Popover
                            header={"ADR"}
                            date={log.date}
                            logs={log.arr}
                          />
                        }
                      >
                        <Dots>&nbsp;...</Dots>
                      </TextOnlyTooltip>
                    )}
                  </Td>
                ) : (
                  <Td sx={{ borderBottom: "none" }}>
                    <div style={{ paddingRight: "16px" }}>-</div>
                    <div />
                  </Td>
                )}
              </Row>
            </Tbody>
          ))}
        </Table>
      </TableContainer>
    </StyledTableBox>
  );
}
