import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";

const CircularProgressIcon = styled(CircularProgress)`
  color: white;
`;

const Btn = styled(Button)`
  background: ${(props) =>
    props.next
      ? "#130453 0% 0% no-repeat padding-box"
      : "#FFFFFF 0% 0% no-repeat padding-box"};
  border-radius: 5px;
  width: 100px;
  height: 32px;
  font: normal normal bold 12px/15px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  border: none;
  outline: none;
  color: ${(props) => (props.next ? "#FFFFFF" : "#130453")};
  &:hover {
    cursor: pointer;
  }
`;

export const PrimaryButton = ({ children, ...props }) => {
  return (
    <Btn
      {...props}
      style={{
        backgroundColor: "#306fbc",
        fontSize: "14px",
        height: "32px",
        maxWidth: "100%",
        minWidth: "78px",
        color: "#ffffff",
      }}
    >
      {props.loading ? (
        <CircularProgressIcon
          aria-busy={true}
          size={20}
          thickness={6}
          aria-describedby="loading"
        />
      ) : (
        children
      )}
    </Btn>
  );
};

export const SecondaryButton = styled(Button)`
  width: 60px;
  height: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #322277;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
  }
  outline: none;
`;

export const GhostButton = styled(Btn)`
  &&& {
    width: auto;
    border: none;
    background-color: transparent;
    color: #281e53;
    padding: 5px 10px;
    box-shadow: none;
    cursor: pointer;
    &:hover {
      background-color: #f7f7f7;
      border-radius: 2px;
      color: #000000;
    }
    &:disabled {
      background-color: transparent;
      cursor: not-allowed;
      color: ${({ disabled }) => (disabled ? "#6f6f6f" : "#ffffff")};
    }

    &:active {
      background-color: ${({ disabled }) =>
        disabled ? "transparent" : "#f1f2fe"};
    }
  }
`;

export const SaveNEditBtn = styled(Button)`
  &&& {
    width: 60px;
    height: 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #322277;
    border-radius: 5px;

    &:hover {
      border-radius: 2px;
      cursor: pointer;
    }

    background-color: ${(props) => (props.isInEditMode ? "#210D79" : "")};
    color: ${(props) => (props.isInEditMode ? "white" : "")};
    font-weight: ${(props) => (props.isInEditMode ? "bold" : "")};
    font-family: ${(props) => (props.isInEditMode ? "Roboto" : "")};

    outline: none;
  }
`;
