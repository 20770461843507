import { Table, TableContainer } from "@mui/material";
import React from "react";
import { Tbody } from "../../PricingRecomendation/Styles";
import { getDateDDMM } from "../../sdk";
import {
  DATE,
  DAY,
  Tabhead,
  TCell as Td,
  TextOnlyTooltip,
  THead,
  TRow,
} from "../Styles.js";

const Days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export default function LeadTimevsPickupTable({ selectedData }) {
  const competitors = selectedData.find((data) => data.compSet);
  let competitorDetails;
  if (competitors) {
    let { compSet } = competitors;
    competitorDetails = compSet;
  }

  return (
    <TableContainer>
      <Table>
        <THead>
          <TRow>
            <Tabhead>As of</Tabhead>
            <Tabhead>Lead Time</Tabhead>
            <Tabhead>Pick-Up</Tabhead>
            <Tabhead>Current Price</Tabhead>
            <Tabhead>System Price</Tabhead>
            <Tabhead>Override Price</Tabhead>
            <Tabhead>Price Difference</Tabhead>
            <Tabhead>Override Reason</Tabhead>
            {competitorDetails &&
              competitorDetails.map((comp, index) => (
                <Tabhead key={index}> {comp.competitorName}</Tabhead>
              ))}
          </TRow>
        </THead>
        <Tbody>
          {selectedData.map((data, idx) => (
            <TRow
              key={idx}
              style={
                idx % 2 === 0
                  ? { backgroundColor: "rgba(48, 81, 221,0.09)" }
                  : {}
              }
            >
              <Td>
                <DATE style={{ alignItems: "center" }}>
                  <div>{getDateDDMM(data.date)}</div>

                  <DAY>{Days[new Date(data.date).getDay()]}</DAY>
                </DATE>
              </Td>
              <Td>{data.leadTime}</Td>
              <Td
                style={{
                  color:
                    Math.round(data.pickup) <= -1 &&
                    Math.round(data.pickup) !== -1000
                      ? "red"
                      : "",
                }}
              >
                {data.pickup === -1000 ? "-" : data.pickup}
              </Td>
              <Td>
                {data.currentPrice === 0
                  ? "-"
                  : data.currentPrice === -1
                  ? "Sold Out"
                  : parseInt(data.currentPrice)}
              </Td>
              <Td>
                {data.systemPrice === 0 ? "-" : parseInt(data.systemPrice)}
              </Td>
              <Td>
                {data.overridePrice === 0 ? "-" : parseInt(data.overridePrice)}
              </Td>
              <Td>
                {data.overridePrice === 0 ? "-" : parseInt(data.difference)}
              </Td>
              <TextOnlyTooltip
                disableFocusListener
                disableTouchListener
                title={data.overrideReason}
                arrow
              >
                <Td style={{ minWidth: "80px", width: "100px" }}>
                  {
                    <div
                      style={{
                        height: "30px",
                        maxWidth: "60px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        lineHeight: "30px",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data.overrideReason ? data.overrideReason : "-"}
                    </div>
                  }
                </Td>
              </TextOnlyTooltip>
              {data?.compSet?.map((comp, idx) => (
                <Td key={idx}>
                  {comp.price === -1
                    ? "Sold Out"
                    : comp.price === -999
                    ? "-"
                    : comp.price}
                </Td>
              ))}
            </TRow>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
