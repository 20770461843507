import {
  Table,
  TableContainer,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { Delete as DEL } from "@mui/icons-material";
import React from "react";
import { styled } from "@mui/system";
import { Row } from "../../../sdk";
import { Tbody, Td, Th, Thead } from "../Styles";
import Popover from "./Popover.js";

function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  if (parseInt(hours) <= 9) {
    hours = "0" + hours;
  }
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime.toUpperCase();
}
const TextOnlyTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff !important",
    color: "#000",
    fontSize: "12px",
    lineHeight: "16px",
    fontFamily: "Roboto, sans-serif",
    width: "268px",
    padding: "15px",
    border: "1px solid #A0A0A0",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#ffffff",
    "&:before": {
      border: "1px solid #A0A0A0",
    },
  },
}));

const Dots = styled(Typography)`
  :hover {
    cursor: pointer;
  }
`;

const Days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export default function UploadTable({
  data,
  setShouldBlockNavigation,
  setFile,
  setFileType,
  fileUploadedDelete,
}) {
  return (
    <div>
      <Table>
        <Thead>
          <Row>
            <Th style={{ paddingLeft: "16px" }}>Date</Th>
            <Th style={{ paddingLeft: "10px" }}>Business on Books</Th>
            <Th style={{ paddingLeft: "6px" }}>Competitor Price</Th>
            <Th style={{ paddingLeft: "6px" }}>Last Night Actuals</Th>
          </Row>
        </Thead>
        <Tbody />
      </Table>
      <TableContainer sx={{ maxHeight: "58vh", minWidth: 500 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ width: "100%", position: "relative" }}
        >
          <Thead />
          {data?.map((log, index) => (
            <Tbody key={index}>
              <Row
                key={log.id}
                style={
                  index % 2 === 0
                    ? { backgroundColor: "rgba(48, 81, 221,0.09)" }
                    : {}
                }
              >
                <Td sx={{ borderBottom: "none" }}>{log.name}</Td>
                {log.forecastFile.forecastFileDetails ? (
                  <Td sx={{ borderBottom: "none", marginTop: "12px" }}>
                    <div style={{ paddingRight: "10px" }}>
                      {new Date(
                        log.forecastFile.forecastFileDetails[0].time
                      ).toLocaleDateString("en-GB")}
                    </div>
                    <div style={{ paddingRight: "10px" }}>
                      {formatAMPM(
                        new Date(log.forecastFile.forecastFileDetails[0].time)
                      )}
                    </div>
                    <div>
                      {log.forecastFile.forecastFileDetails[0].username}
                    </div>

                    {log.forecastFile.forecastFileDetails.length > 1 ? (
                      <TextOnlyTooltip
                        disableFocusListener
                        disableTouchListener
                        interactive
                        placement="right-start"
                        onHover={() => setFileType("forecast")}
                        arrow
                        title={
                          <Popover
                            header={"Business on Books"}
                            name={log.name}
                            logs={log.forecastFile.forecastFileDetails}
                            setShouldBlockNavigation={setShouldBlockNavigation}
                            setFile={setFile}
                            setFileType={setFileType}
                            filetype={"forecast"}
                            file={log.forecastFile[0]}
                            fileUploadedDelete={fileUploadedDelete}
                          />
                        }
                      >
                        <Dots>&nbsp;...</Dots>
                      </TextOnlyTooltip>
                    ) : fileUploadedDelete ? (
                      <Dots>
                        <DEL
                          style={{
                            fontSize: 18,
                            marginBottom: 3,
                            marginLeft: 2,
                          }}
                          onClick={() => {
                            setFileType("forecast");
                            setFile(log.forecastFile.forecastFileDetails[0]);
                            setShouldBlockNavigation(true);
                          }}
                        />
                      </Dots>
                    ) : (
                      ""
                    )}
                  </Td>
                ) : (
                  <Td sx={{ borderBottom: "none" }}>-</Td>
                )}

                {log.competitorFile.competitorFileDetails ? (
                  <Td sx={{ borderBottom: "none", marginTop: "12px " }}>
                    <div style={{ paddingRight: "10px" }}>
                      {new Date(
                        log.competitorFile.competitorFileDetails[0].time
                      ).toLocaleDateString("en-GB")}
                    </div>
                    <div style={{ paddingRight: "10px" }}>
                      {formatAMPM(
                        new Date(
                          log.competitorFile.competitorFileDetails[0].time
                        )
                      )}
                    </div>
                    <div>
                      {log.competitorFile.competitorFileDetails[0].username}
                    </div>
                    {log.competitorFile.competitorFileDetails.length > 1 ? (
                      <TextOnlyTooltip
                        disableFocusListener
                        disableTouchListener
                        placement="right-start"
                        onHover={() => setFileType("competitor")}
                        arrow
                        title={
                          <Popover
                            header={"Competitor Price"}
                            name={log.name}
                            logs={log.competitorFile.competitorFileDetails}
                            setShouldBlockNavigation={setShouldBlockNavigation}
                            setFile={setFile}
                            setFileType={setFileType}
                            filetype={"competitor"}
                            file={log.competitorFile[0]}
                            fileUploadedDelete={fileUploadedDelete}
                          />
                        }
                      >
                        <Dots>&nbsp;...</Dots>
                      </TextOnlyTooltip>
                    ) : fileUploadedDelete ? (
                      <Dots>
                        <DEL
                          style={{
                            fontSize: 18,
                            marginBottom: 3,
                            marginLeft: 2,
                          }}
                          onClick={() => {
                            setFileType("competitor");
                            setFile(
                              log.competitorFile.competitorFileDetails[0]
                            );
                            setShouldBlockNavigation(true);
                          }}
                        />
                      </Dots>
                    ) : (
                      ""
                    )}
                  </Td>
                ) : (
                  <Td sx={{ borderBottom: "none" }}>-</Td>
                )}
                {log.arr.arrDetails ? (
                  <Td sx={{ borderBottom: "none" }}>
                    <div style={{ paddingRight: "10px" }}>
                      {new Date(log.arr.arrDetails[0].time).toLocaleDateString(
                        "en-GB"
                      )}
                    </div>

                    <div style={{ paddingRight: "10px" }}>
                      {formatAMPM(new Date(log.arr.arrDetails[0].time))}
                    </div>
                    <div>{log.arr.arrDetails[0].username}</div>
                    {log.arr.length > 1 && (
                      <TextOnlyTooltip
                        disableFocusListener
                        disableTouchListener
                        placement="right-start"
                        arrow
                        title={
                          <Popover
                            header={"ADR"}
                            name={log.name}
                            logs={log.arr.arrDetails}
                          />
                        }
                      >
                        <Dots>&nbsp;...</Dots>
                      </TextOnlyTooltip>
                    )}
                  </Td>
                ) : (
                  <Td sx={{ borderBottom: "none" }}>
                    <div style={{ paddingRight: "16px" }}>-</div>
                    <div />
                  </Td>
                )}
              </Row>
            </Tbody>
          ))}
        </Table>
      </TableContainer>
    </div>
  );
}
