import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { useWarning } from "../../Provider/context";
import { useAuth } from "../../sdk/hooks/useAuth";

export default function useMarketSegment() {
  const [isSaved, setIsSaved] = useState(true);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [dialogSave, setDialogSave] = useState(false);
  const { token, authFetch } = useAuth();
  const { hotelId } = useParams();
  const EMPTY_DATA = {
    systemName: "",
    pmsName: "",
    description: "",
    sequence: 0,
  };
  const [enableButton, setEnableButton] = useState();
  const [marketData, setMarketData] = useState([]);
  const [networkMsg, setnetworkMsg] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [time, setTime] = useState(10);
  const [disable, setDisable] = useState(false);
  const [originalMarketData, setOriginalMarketData] = useState(-1);
  const history = useHistory();
  const { formEdit, setformEdit, handleRedirect } = useWarning();

  const CheckValidation = useCallback(async () => {
    if (marketData.length > 0) {
      const isSystemNameEmpty = marketData.filter((v) => v.systemName === "");
      const isPmsNameEmpty = marketData.filter((v) => v.pmsName === "");
      const isSequenceEmpty = marketData.filter((v) => v.sequence === "");
      if (
        isSystemNameEmpty.length === 0 &&
        isPmsNameEmpty.length === 0 &&
        isSequenceEmpty.length === 0
      )
        setEnableButton(true);
      else setEnableButton(false);
    } else setEnableButton(false);
  }, [marketData, enableButton]);

  const GetMarket = useCallback(async () => {
    setIsLoading(true);
    setDisable(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/market-segments`,
    });
    const { data, error, response } = await get();
    if (data.Message !== "Market Segment Data not available") {
      setMarketData([
        ...data
          .sort((a, b) => a.sequence - b.sequence)
          .map((val) => {
            return {
              ...val,
            };
          }),
      ]);
    } else {
      setMarketData([]);
    }
    setDisable(false);
    setIsLoading(false);
  }, [authFetch, hotelId]);
  useEffect(() => {
    if (hotelId && token) {
      GetMarket();
    }
  }, [token, hotelId]);

  useEffect(() => {
    CheckValidation();
  }, [marketData, CheckValidation]);

  const addDetail = () => {
    setformEdit(true);
    setMarketData([
      ...marketData,
      {
        ...EMPTY_DATA,
        sequence: marketData?.length + 1,
      },
    ]);

    setDisable(true);
    setOriginalMarketData(originalMarketData + 1);
  };

  const handleChange = useDebouncedCallback(
    (index, key, value, data) => {
      setformEdit(true);
      setIsSaved(false);
      setMarketData(
        marketData.map((data, indx) => {
          if (index === indx) {
            return {
              ...data,
              [key]: value,
            };
          }
          return data;
        })
      );
    },
    time,
    []
  );

  async function saveMarketData() {
    if (marketData.some((data) => data.sequence === "")) {
      setnetworkMsg("Sequence Should not be empty");
      setOpen(true);
    }
    setIsLoading(true);
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/market-segments`,
    });
    const { data, response } = await post(marketData);

    if (response?.ok) {
      setIsSaved(true);
      setformEdit(false);
      setnetworkMsg("Saved Successfully");
      GetMarket();
      setOpen(true);
      if (dialogSave) {
        history.push(`/hotel/${hotelId}/setup/budget-setup`);
      }
    } else {
      setnetworkMsg(data?.messageToUser || "Something went wrong");
      setOpen(true);
    }
    setIsLoading(false);
    setDisable(false);
  }
  const handleCloseStatus = () => {
    setOpen(false);
  };

  const handleLeavePage = useCallback(() => {
    history.push(`/hotel/${hotelId}/setup/budget-setup`);
  }, []);

  const handleModalSave = useCallback(() => {
    setDialogSave(true);
  }, [dialogSave]);

  useEffect(() => {
    if (dialogSave) {
      saveMarketData();
    }
  }, [dialogSave]);

  const handleCloseDialog = useCallback(() => {
    setIsOpenDialog(false);
  }, [isOpenDialog]);

  const handleNext = useCallback(() => {
    if (!isSaved) {
      setIsOpenDialog(true);
    } else {
      history.push(`/hotel/${hotelId}/setup/budget-setup`);
    }
  }, [isSaved, isOpenDialog]);

  const reorder = (list, startIndex, endIndex, sourceData, DestinationData) => {
    const [removed] = list.splice(startIndex, 1);

    list.splice(endIndex, 0, removed);

    reorderDataApi(sourceData, startIndex + 1, endIndex + 1);
    return [...list];
  };

  const reorderDataApi = useCallback(
    async (sourceData, formerIdx, latterIdx) => {
      setDisable(true);
      const { put } = await authFetch({
        path: `/hotel/${hotelId}/market-segments/sequence`,
      });

      const { data, response } = await put({
        formerId: sourceData?.segmentId,
        formerSequence: formerIdx,
        latterSequence: latterIdx,
      });

      if (response.status === 204) {
        GetMarket();
      } else {
        setnetworkMsg(data?.messageToUser || "Something went wrong");
        setOpen(true);
      }
      setDisable(false);
    },
    [marketData, authFetch, hotelId]
  );

  const handleDeleteRow = useCallback(
    async (data, index) => {
      if (data?.segmentId !== undefined) {
        setMarketData([
          ...marketData.slice(0, index),
          ...marketData.slice(index + 1, marketData.length),
        ]);

        const { del } = await authFetch({
          path: `/hotel/${hotelId}/market-segments/${data.segmentId}`,
        });

        const { response } = await del();
        if (response.status === 204) {
          GetMarket();
        }
      } else {
        setOriginalMarketData(originalMarketData - 1);
        if (originalMarketData === 0) {
          setDisable(false);
        }
        setMarketData([
          ...marketData.slice(0, index),
          ...marketData.slice(index + 1, marketData.length),
        ]);
      }
    },
    [marketData, originalMarketData, authFetch, hotelId, GetMarket]
  );

  return {
    marketData,
    setMarketData,
    addDetail,
    handleChange,
    saveMarketData,
    networkMsg,
    open,
    handleCloseStatus,
    isLoading,
    enableButton,
    reorder,
    handleDeleteRow,
    disable,
    handleNext,
    isOpenDialog,
    handleCloseDialog,
    handleLeavePage,
    handleModalSave,
    formEdit,
    handleRedirect,
  };
}
