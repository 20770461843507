import React from "react";
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import { LoadingPage } from "./LoadingPage";
import { useAuth } from "../hooks";

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15%;
`;

const Message = styled(Typography)`
  // width: 350px;
  text-align: center;
  font: normal normal 300 18px/21px Roboto;
  letter-spacing: 0px;
  color: #000000;
  margin-top: 32px;
`;
export const PreciumDisabledPage = () => {
  const { currentHotel } = useAuth();
  return currentHotel === null ? (
    <LoadingPage />
  ) : (
    <Wrapper>
      <img src="/assets/logo-purple.svg" alt="Logo" height="150px" />
      <Message>
        ** WELCOME TO THE WORLD OF NEXT-GEN REVENUE MANAGEMENT ** <br />
        <br />
        You have not subscribed to this service. To Subscribe to Revenue
        Management system,
        <br /> please contact us on:{" "}
        <a href="mailto:Sales@precium.in">sales@precium.in</a>
      </Message>
    </Wrapper>
  );
};
