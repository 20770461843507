import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getISODate } from "../../sdk";
import { useAuth } from "../../sdk/hooks/useAuth";

export default function useForecastSheet() {
  const date = new Date();
  const [forecastSheetData, setForecastSheetData] = useState(null);
  const [forecastSheetDailyData, setForecastSheetDailyData] = useState(null);
  const { token, authFetch } = useAuth();
  const { hotelId } = useParams();
  const [comparitiveDate, setComparitiveDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [showPickupRequireToAchieve, setShowPickupRequireToAchieve] = useState([
    false,
    false,
    false,
  ]);
  const [Loading, setLoading] = useState(false);
  const [LoadingDailyForecast, setLoadingDailyForecast] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  useEffect(() => {
    getForecastData();
    getForecastDailyData();
  }, [comparitiveDate, token, hotelId, selectedDate]);

  function handleDateChange(date) {
    setSelectedDate(new Date(date));
  }
  async function getForecastData() {
    setLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/budget-forecast-monthly/${getISODate(
        new Date(comparitiveDate.getFullYear(), comparitiveDate.getMonth(), 1)
      )}`,
    });

    const { data } = await get();

    if (data && data.length) {
      setForecastSheetData(transformData(data));
    } else {
      setShowPickupRequireToAchieve(null);
      setForecastSheetData(null);
    }
    setLoading(false);
  }
  async function getForecastDailyData() {
    setLoadingDailyForecast(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/budget-forecast-daily-date/${getISODate(
        new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)
      )}`,
    });
    const { data, error } = await get();
    if (data && data.length) {
      const sementWithTotal = makeData(data);
      setForecastSheetDailyData(sementWithTotal);
    } else {
    }
    setLoadingDailyForecast(false);
  }
  function makeData(data) {
    const budgetModel = [
      {
        arrActual: data.reduce((acc, curr) => {
          if (curr.budgetModel[0].arrActual === -1) {
            return acc;
          }
          return (acc += curr.budgetModel[0].arrActual);
        }, 0),
        arrBudget: data.reduce((acc, curr) => {
          if (curr.budgetModel[0].arrBudget === -1) {
            return acc;
          }
          return (acc += curr.budgetModel[0].arrBudget);
        }, 0),
        arrLY: data.reduce((acc, curr) => {
          if (curr.budgetModel[0].arrLY === -1) {
            return acc;
          }
          return (acc += curr.budgetModel[0].arrLY);
        }, 0),
        arrLocked: data.reduce((acc, curr) => {
          if (curr.budgetModel[0].arrLocked === -1) {
            return acc;
          }
          return (acc += curr.budgetModel[0].arrLocked);
        }, 0),
        arrOTB: data.reduce((acc, curr) => {
          if (curr.budgetModel[0].arrOTB === -1) {
            return acc;
          }
          return (acc += curr.budgetModel[0].arrOTB);
        }, 0),
        date: "2021-08-01T00:00:00Z",
        revenueActual: data.reduce((acc, curr) => {
          if (curr.budgetModel[0].revenueActual === -1) {
            return acc;
          }
          return (acc += curr.budgetModel[0].revenueActual);
        }, 0),
        revenueBudget: data.reduce((acc, curr) => {
          if (curr.budgetModel[0].revenueBudget === -1) {
            return acc;
          }
          return (acc += curr.budgetModel[0].revenueBudget);
        }, 0),
        revenueLY: data.reduce((acc, curr) => {
          if (curr.budgetModel[0].revenueLY === -1) {
            return acc;
          }
          return (acc += curr.budgetModel[0].revenueLY);
        }, 0),
        revenueLocked: data.reduce((acc, curr) => {
          if (curr.budgetModel[0].revenueLocked === -1) {
            return acc;
          }
          return (acc += curr.budgetModel[0].revenueLocked);
        }, 0),
        revenueOTB: data.reduce((acc, curr) => {
          if (curr.budgetModel[0].revenueOTB === -1) {
            return acc;
          }
          return (acc += curr.budgetModel[0].revenueOTB);
        }, 0),
        roomsActual: data.reduce((acc, curr) => {
          if (curr.budgetModel[0].roomsActual === -1) {
            return acc;
          }
          return (acc += curr.budgetModel[0].roomsActual);
        }, 0),
        roomsBudget: data.reduce((acc, curr) => {
          if (curr.budgetModel[0].roomsBudget === -1) {
            return acc;
          }
          return (acc += curr.budgetModel[0].roomsBudget);
        }, 0),
        roomsLY: data.reduce((acc, curr) => {
          if (curr.budgetModel[0].roomsLY === -1) {
            return acc;
          }
          return (acc += curr.budgetModel[0].roomsLY);
        }, 0),
        roomsLocked: data.reduce((acc, curr) => {
          if (curr.budgetModel[0].roomsLocked === -1) {
            return acc;
          }
          return (acc += curr.budgetModel[0].roomsLocked);
        }, 0),
        roomsOTB: data.reduce((acc, curr) => {
          if (curr.budgetModel[0].roomsOTB === -1) {
            return acc;
          }
          return (acc += curr.budgetModel[0].roomsOTB);
        }, 0),
        segmentID: 21,
      },
    ];
    const total = {
      description: "",
      systemName: "Total",
      budgetModel,
    };
    data.push(total);
    return data;
  }

  function transformData(data, fn) {
    const roomNight = data.map((data) => {
      return {
        date: data.date,
        roomsActual: checkIfMinusOne(data.roomsActual),
        roomsLocked: checkIfMinusOne(data.roomsLocked),
        roomsLY: checkIfMinusOne(data.roomsLY),
        roomsOTB: checkIfMinusOne(data.roomsOTB),
        roomsBudget: checkIfMinusOne(data.roomsBudget),
      };
    });
    const horizontalTotalRoomNight = {
      date: new Date(),
      roomsActual: data.reduce((acc, curr) => {
        if (curr.roomsActual === -1) {
          return (acc += 0);
        } else {
          return (acc += curr.roomsActual);
        }
      }, 0),
      roomsLocked: data.reduce((acc, curr) => {
        if (curr.roomsLocked === -1) {
          return (acc += 0);
        } else {
          return (acc += curr.roomsLocked);
        }
      }, 0),
      roomsLY: data.reduce((acc, curr) => {
        if (curr.roomsLY === -1) {
          return (acc += 0);
        } else {
          return (acc += curr.roomsLY);
        }
      }, 0),
      roomsOTB: data.reduce((acc, curr) => {
        if (curr.roomsOTB === -1) {
          return (acc += 0);
        } else {
          return (acc += curr.roomsOTB);
        }
      }, 0),
      roomsBudget: data.reduce((acc, curr) => {
        if (curr.roomsBudget === -1) {
          return (acc += 0);
        } else {
          return (acc += curr.roomsBudget);
        }
      }, 0),
    };
    roomNight.push(horizontalTotalRoomNight);
    const ADR = data.map((data) => {
      return {
        date: data.date,
        arrActual: checkIfMinusOne(data.arrActual),
        arrLocked: checkIfMinusOne(data.arrLocked),
        arrLY: checkIfMinusOne(data.arrLY),
        arrOTB: checkIfMinusOne(data.arrOTB),
        arrBudget: checkIfMinusOne(data.arrBudget),
      };
    });
    const horizontalTotalADR = {
      date: new Date(),
      arrActual: data.reduce((acc, curr) => {
        if (curr.arrActual === -1) {
          return (acc += 0);
        } else {
          return (acc += curr.arrActual);
        }
      }, 0),
      arrLocked: data.reduce((acc, curr) => {
        if (curr.arrLocked === -1) {
          return (acc += 0);
        } else {
          return (acc += curr.arrLocked);
        }
      }, 0),
      arrLY: data.reduce((acc, curr) => {
        if (curr.arrLY === -1) {
          return (acc += 0);
        } else {
          return (acc += curr.arrLY);
        }
      }, 0),
      arrOTB: data.reduce((acc, curr) => {
        if (curr.arrOTB === -1) {
          return (acc += 0);
        } else {
          return (acc += curr.arrOTB);
        }
      }, 0),
      arrBudget: data.reduce((acc, curr) => {
        if (curr.arrBudget === -1) {
          return (acc += 0);
        } else {
          return (acc += curr.arrBudget);
        }
      }, 0),
    };
    ADR.push(horizontalTotalADR);
    const Revenue = data.map((data) => {
      return {
        date: data.date,
        revenueActual: checkIfMinusOne(data.revenueActual),
        revenueLocked: checkIfMinusOne(data.revenueLocked),
        revenueLY: checkIfMinusOne(data.revenueLY),
        revenueOTB: checkIfMinusOne(data.revenueOTB),
        revenueBudget: checkIfMinusOne(data.revenueBudget),
      };
    });
    const horizontalTotalRevenue = {
      date: new Date(),
      revenueActual: data.reduce((acc, curr) => {
        if (curr.revenueActual === -1) {
          return (acc += 0);
        } else {
          return (acc += curr.revenueActual);
        }
      }, 0),
      revenueLocked: data.reduce((acc, curr) => {
        if (curr.revenueLocked === -1) {
          return (acc += 0);
        } else {
          return (acc += curr.revenueLocked);
        }
      }, 0),
      revenueLY: data.reduce((acc, curr) => {
        if (curr.revenueLY === -1) {
          return (acc += 0);
        } else {
          return (acc += curr.revenueLY);
        }
      }, 0),
      revenueOTB: data.reduce((acc, curr) => {
        if (curr.revenueOTB === -1) {
          return (acc += 0);
        } else {
          return (acc += curr.revenueOTB);
        }
      }, 0),
      revenueBudget: data.reduce((acc, curr) => {
        if (curr.revenueBudget === -1) {
          return acc;
        } else {
          return (acc += curr.revenueBudget);
        }
      }, 0),
    };
    Revenue.push(horizontalTotalRevenue);

    return {
      roomNight,
      ADR,
      Revenue,
    };
  }
  function checkIfMinusOne(num) {
    if (num === -1) {
      return NaN;
    }
    return num;
  }

  function handleShowPickupRequiredRow(index) {
    setShowPickupRequireToAchieve([
      ...showPickupRequireToAchieve.filter((_, idx) => idx < index),
      !showPickupRequireToAchieve[index],
      ...showPickupRequireToAchieve.filter((_, idx) => idx > index),
    ]);
  }

  return {
    forecastSheetData,
    comparitiveDate,
    setComparitiveDate,
    showPickupRequireToAchieve,
    handleShowPickupRequiredRow,
    Loading,
    selectedDate,
    handleDateChange,
    forecastSheetDailyData,
    LoadingDailyForecast,
  };
}
