import {
  Button,
  MenuItem,
  Snackbar,
  SnackbarContent,
  Tooltip,
  Modal,
  Tab,
  Box,
} from "@mui/material";
import { CalendarToday, Publish, Schedule } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { useHistory, useParams } from "react-router-dom";
import { ErrorPage, LoadingPage, NoAccess, Nodata, useAuth } from "../sdk";
import { getISODate } from "../sdk/common-utils";
import PricingTable from "./components/PricingTable";
import { ModelBody } from "./components/UploadModel";
import { styled } from "@mui/system";
import {
  CompetitorPage,
  NetworkPrompt,
  ScheduleIconDisabled,
  Select,
  Wrapper,
} from "./Styles";
import { PreciumDisabledPage } from "../sdk/components/PreciumDiabledPage";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const HeaderCardLeftNew = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-left: 20px;
  box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
`;

export const HeaderCardCenterNew = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
  margin-left: 16px;
  padding-left: 24px;
  padding-right: 24px;
  height: 32px;
  button.MuiButtonBase-root {
    opacity: 1;
    span.MuiTab-wrapper {
      font: normal normal bold 16px / 20px Roboto;
      color: #281e53;
    }
  }
`;

export const HeaderNew = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 99%;
  height: 52px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  margin: auto;
  padding-left: 32px;
`;

export const HeaderCardRightNew = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0;
  background-color: #306fbc;
  box-shadow: 0px 4px 4px rgba(3, 4, 94, 0.2);
  border-radius: 8px;
  color: #ffffff;
  margin-left: 16px;
`;

export const UploadBtnNew = styled(Button)`
  text-align: right;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #343434;
  display: flex;
  align-items: flex-end;
  text-transform: capitalize;
  padding: 6px 16px 6px 16px;
  height: 32px;
`;
export const CalenderIcon = styled(CalendarToday)`
  position: absolute;
  width: 20px;
  height: 22px;
  color: #306fbc;
  z-index: 101;
  margin-left: -32px;
  margin-top: 5px;
  cursor: pointer;
`;

export const StyledCalenderWrapper = styled(Box)((theme) => ({
  borderRadius: "8px",
  boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
  "&:hover>*": {
    color: "#ffffff",
    ">input": {
      backgroundColor: "#306fbc",
      color: "#ffffff !important",
    },
  },
}));

const CompetitorPrice = ({ setPageHeader }) => {
  const { token, permission, authFetch, currentHotel } = useAuth();
  const { hotelId } = useParams();
  const [competitorsData, setCompetitorsdata] = useState([]);
  const [competitors, setCompetitors] = useState([]);
  const [ratepingFilename, setRatepingFileName] = useState("");
  const [expediaFilename, setExpediaFileName] = useState("");
  const [travelClickFileName, setTravelClickFileName] = useState("");
  const [rateGainFileName, setRateGainFileName] = useState("");
  const [genericFileName, setGenericFileName] = useState("");
  const [otaFilename, setOtaFileName] = useState("");
  const formdata = useRef(new FormData());
  const [openSelectbar, setOpenSelectBar] = useState(false);
  const [ratepingToggle, setRatepingToggle] = useState(false);
  const [expediaToggle, setExpediaToggle] = useState(false);
  const [genericToggle, setGenericToggle] = useState(false);
  const [otaToggle, setOtaToggle] = useState(false);
  const [rategainToggle, setRateGainToggle] = useState(false);
  const [travelClickFileToggle, setTravelClickFileToggle] = useState(false);
  const { DATE, FILEID } = useParams();
  const [Loading, setLoading] = useState(false);
  const [haveData, setHaveData] = useState(true);
  const [Error, setError] = useState(false);
  const [popoverData, setPopOverData] = useState({});
  const [selectedImages, setSelectedImages] = useState([]);
  const [remarkData, setRemarkData] = useState([]);
  let compPriceUpload = null,
    compPriceView = null,
    compPriceUploadForSpecificDate = false;
  const [fileId, setFileId] = useState(() => {
    if (FILEID) {
      return parseInt(FILEID);
    } else {
      return 0;
    }
  });
  useEffect(() => {
    setPageHeader("Rate Comparison");
    return () => {
      setPageHeader("");
    };
  }, []);

  const [hotelSelect, setHotelSelect] = useState();
  //api call
  useEffect(() => {
    async function AllDetailsSetup() {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}`,
      });
      const { data, error } = await get();
      if (data && !error) {
        setHotelSelect(data);
      }
    }
    AllDetailsSetup();
  }, [token]);

  const maximumDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValue()}
      style={{
        textAlign: "left",

        fontSize: "14px",

        borderRadius: "10px",

        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );
  const [selectedDate, setSelectedDate] = useState(() => {
    if (!isNaN(new Date(DATE).getTime())) {
      const [year, mon, day] = DATE.split("-");
      if (year && mon && day) {
        if (day && day.length === 1) {
          const validDate = [year, mon, "0" + day].join("/");
          return new Date(validDate);
        } else {
          return new Date(DATE);
        }
      } else {
        return new Date();
      }
    } else {
      return new Date();
    }
  });

  const defaultValue = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  const [selectedDateNew, setSelectedDateNew] = useState(defaultValue);
  const formatInputValue = () => {
    if (selectedDateNew) {
      const value =
        `${
          selectedDateNew.day < 10
            ? "0" + selectedDateNew.day
            : selectedDateNew.day
        }` +
        "/" +
        `${
          selectedDateNew.month < 10
            ? "0" + selectedDateNew.month
            : selectedDateNew.month
        }` +
        "/" +
        `${selectedDateNew.year}`;
      return value;
    } else {
      if (!isNaN(new Date(DATE).getTime())) {
        const [year, mon, day] = DATE.split("-");

        if (year && mon && day) {
          if (day && day.length === 1) {
            const validDate = "0" + day + "/" + mon + "/" + year;
            return validDate;
          } else {
            const validDate = day + "/" + mon + "/" + year;
            return validDate;
          }
        }
      }
    }
  };
  useEffect(() => {
    if (selectedDateNew) {
      setSelectedDate(
        new Date(
          [
            selectedDateNew.year,
            selectedDateNew.month,
            selectedDateNew.day.length === 1
              ? "0" + selectedDateNew.day
              : selectedDateNew.day,
          ].join("/")
        )
      );
    }
  }, [selectedDateNew]);
  const history = useHistory();
  useEffect(() => {
    if (token && hotelId) {
      window.scrollTo(0, 0);
    } else {
      return;
    }
  }, [token, hotelId]);
  useEffect(() => {
    history.replace(
      `/hotel/${hotelId}/rate_comparison/${
        getISODate(selectedDate).split("T")[0]
      }/${fileId}`
    );
  }, [selectedDate, fileId, hotelId]);
  const [open, setOpen] = React.useState(false);
  const [allFilesForDay, setAllFilesForDay] = useState([]);
  const [uploadActualDate, setUploadActualDate] = useState(new Date());

  const handleChange = (newValue) => {
    setFileId(newValue);
  };
  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "compPriceUpload") {
        compPriceUpload = permission[key];
      }
      if (permission[key].name === "compPriceView") {
        compPriceView = permission[key];
      }
      if (permission[key].name === "compPriceUploadForSpecificDate") {
        compPriceUploadForSpecificDate = true;
      }
    }
  }
  async function fetchAllFilesForDay() {
    if (token) {
      setLoading(true);
      setHaveData(true);
      const yyyymmdd = getISODate(selectedDate).split("T")[0];

      const { get } = await authFetch({
        path: `/hotel/${hotelId}/competitor-price/file/${yyyymmdd}`,
      });
      const { data } = await get();
      if (data != null && data.length !== 0) {
        setAllFilesForDay(
          data
            .sort((a, b) => new Date(b.uploadedOn) - new Date(a.uploadedOn))
            .map((file) => {
              let hrs = new Date(
                new Date(file.uploadedOn).getTime()
              ).getHours();
              let mins = new Date(
                new Date(file.uploadedOn).getTime()
              ).getMinutes();
              if (mins < 10) {
                mins = "0" + mins;
              }
              if (hrs / 12 > 1) {
                return {
                  ...file,
                  time: (hrs % 12) + ":" + mins + " PM ",
                };
              } else if (hrs / 12 === 1) {
                return {
                  ...file,
                  time: hrs + ":" + mins + " PM ",
                };
              } else {
                return {
                  ...file,
                  time: hrs + ":" + mins + " AM ",
                };
              }
            })
        );
        if (!data.some((file) => file.id === parseInt(FILEID))) {
          setFileId(
            [...data].sort(
              (a, b) => new Date(b.uploadedOn) - new Date(a.uploadedOn)
            )[0].id
          );
        } else {
          setFileId(parseInt(FILEID));
        }
      } else {
        setHaveData(false);
        setCompetitorsdata([]);
        setFileId(0);
      }
    }
    setLoading(false);
  }
  async function fetchAllFilesForDayOnUpload() {
    if (token) {
      setLoading(true);
      setHaveData(true);
      const yyyymmdd = getISODate(selectedDate).split("T")[0];

      const { get } = await authFetch({
        path: `/hotel/${hotelId}/competitor-price/file/${yyyymmdd}`,
      });
      const { data, error } = await get();
      console.log(error);
      if (data != null && data.length !== 0) {
        setAllFilesForDay(
          data
            .sort((a, b) => new Date(b.uploadedOn) - new Date(a.uploadedOn))
            .map((file) => {
              let hrs = new Date(
                new Date(file.uploadedOn).getTime()
              ).getHours();
              let mins = new Date(
                new Date(file.uploadedOn).getTime()
              ).getMinutes();
              if (mins < 10) {
                mins = "0" + mins;
              }
              if (hrs / 12 > 1) {
                return {
                  ...file,
                  time: (hrs % 12) + ":" + mins + " PM ",
                };
              } else {
                return {
                  ...file,
                  time: hrs + ":" + mins + " AM ",
                };
              }
            })
        );
        setFileId(
          [...data].sort(
            (a, b) => new Date(b.uploadedOn) - new Date(a.uploadedOn)
          )[0].id
        );
      } else {
        setAllFilesForDay([]);
        setFileId(0);
        setHaveData(false);
      }
    }
    setLoading(false);
  }
  useEffect(() => {
    if (token) {
      refreshPriceRecommendations();
    }
  }, [token, fileId, hotelId]);

  useEffect(() => {
    if (token) {
      fetchAllFilesForDay();

      async function GetCompetitors() {
        const { get } = await authFetch({
          path: `/hotel/${hotelId}/competitors`,
        });
        const { data } = await get();
        if (data) {
          setCompetitors(data);
        }
      }
      GetCompetitors();
    }
  }, [token, selectedDate, hotelId]);
  const handleCloseStatus = () => {
    setOpen(false);
  };
  const [networkMsg, setnetworkMsg] = useState(null);

  async function refreshPriceRecommendations() {
    setLoading(true);
    if (fileId) {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/competitor-price/by-file/${fileId}`,
      });
      const { data, error } = await get();
      if (data) {
        setCompetitorsdata([...data].sort((a, b) => a.id - b.id));
      }
    }
    setLoading(false);
  }

  const RemarkData = async () => {
    const yyyymmdd = getISODate(selectedDate).split("T")[0];
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/remark/${yyyymmdd}`,
    });
    const { data, error } = await get();
    if (data) {
      setRemarkData(data);
      console.log(data, data.length);
    } else {
      console.log(error);
    }
  };

  useEffect(() => {
    RemarkData();
  }, [token]);

  const uploadImage = async (images) => {
    const UploadSuccess = await Promise.all(
      images.map((file) => {
        return new Promise(async (res) => {
          try {
            const yyyymmdd = getISODate(selectedDate).split("T")[0];
            const formData = new FormData();
            formData.append("date", yyyymmdd);
            formData.append("image", file);
            const { post } = await authFetch({
              path: `/hotel/${hotelId}/upload-image`,
            });
            const { data, response, error } = await post(formData);
            if (error) {
              throw new Error("Failed to upload image");
            }
            if (data?.messageToUser) {
              setnetworkMsg(data.messageToUser);
              setOpen(true);
            }
            res({ id: data.id, imageURL: data.imageURL });
          } catch (error) {
            res({ id: "", imageURL: "" });
          }
        });
      })
    );
    return UploadSuccess;
  };

  async function sendRemark(date, popOver) {
    const data = {
      description: popOver?.remark?.remark ?? "",
      imageId: [
        ...(!!selectedImages?.length ? selectedImages : []),
        ...(!!popOver?.images?.length ? popOver?.images : []),
      ].map((item) => item.id),
      date: date,
    };

    const { post } = await authFetch({
      path: `/hotel/${hotelId}/remark`,
    });
    const { data: responseData, error } = await post(data);
    if (responseData.status === 200 || responseData.status === 201) {
      RemarkData();
      setnetworkMsg("Upload Successful");
      setOpen(true);
    } else {
      setnetworkMsg(responseData?.messageToUser || "Something went wrong");
    }
  }

  const ratepingFileGetter = (ratepingFile) => {
    handleRatepingToggle();
    setRatepingFileName(ratepingFile[0].name);
    formdata.current.append("ratepingFile", ratepingFile[0]);
  };
  const expediaFileGetter = (expediaFile) => {
    handleExpediaToggle();
    setExpediaFileName(expediaFile[0].name);
    formdata.current.append("expediaFile", expediaFile[0]);
  };
  const travelClickGetter = (travelClickFile) => {
    handleTravelClickToggle();
    setTravelClickFileName(travelClickFile[0].name);
    formdata.current.append("travelClickFile", travelClickFile[0]);
  };
  const rateGainGetter = (rateGainFile) => {
    handleRateGainToggle();
    setRateGainFileName(rateGainFile[0].name);
    formdata.current.append("rateGainFile", rateGainFile[0]);
  };
  const otaFileGetter = (otaFile) => {
    handleOtaToggle();
    setOtaFileName(otaFile[0].name);
    formdata.current.append("otaFile", otaFile[0]);
  };
  const genericFileGetter = (genericFile) => {
    handleGenericToggle();
    setGenericFileName(genericFile[0].name);
    formdata.current.append("genericFile", genericFile[0]);
  };
  async function fileHandler() {
    setLoading(true);
    setOpenSelectBar(false);
    setExpediaToggle(false);
    setRatepingToggle(false);
    setTravelClickFileToggle(false);
    setOtaToggle(false);
    setRateGainToggle(false);
    setGenericToggle(false);
    let year = uploadActualDate.getFullYear();
    let month = uploadActualDate.getMonth() + 1;
    let dt = uploadActualDate.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    formdata.current.append("uploadedFor", year + "-" + month + "-" + dt);

    const { post } = await authFetch({
      path: `/hotel/${hotelId}/competitor-price/file-upload`,
    });
    const { data, response, error } = await post(formdata.current);
    // if (data?.message === "inappropriate file" || data?.message) {
    if (
      data &&
      (response.status === 500 ||
        response.status === 400 ||
        response.status === 501)
    ) {
      setnetworkMsg(data?.messageToUser || "Invalid File");
      // const messg = data?.message || data || "Invalid File";
      // setnetworkMsg(messg);
      setOpen(true);
      setError(false);
    } else if (response.status === 200) {
      setnetworkMsg("Upload Successful");
      setOpen(true);

      setError(false);
    }
    fetchAllFilesForDayOnUpload();
    formdata.current = new FormData();

    setLoading(false);
  }
  const handleRatepingToggle = () => {
    setRatepingToggle(!ratepingToggle);
  };
  const handleExpediaToggle = () => {
    setExpediaToggle(!expediaToggle);
  };
  const handleTravelClickToggle = () => {
    setTravelClickFileToggle(!travelClickFileToggle);
  };
  const handleRateGainToggle = () => {
    setRateGainToggle(!rategainToggle);
  };
  const handleGenericToggle = () => {
    setGenericToggle(!genericToggle);
  };
  const handleOtaToggle = () => {
    setOtaToggle(!otaToggle);
  };
  const removeRatepingFile = () => {
    setRatepingFileName("");
    formdata.current.delete("ratepingFile");

    setRatepingToggle(!ratepingToggle);
  };
  const removeExpediaFile = () => {
    setExpediaFileName("");
    formdata.current.delete("expediaFile");

    setExpediaToggle(!expediaToggle);
  };
  const removeOtaFile = () => {
    setOtaFileName("");
    formdata.current.delete("otaFile");

    setOtaToggle(!otaToggle);
  };
  const removeTravelClickFile = () => {
    setTravelClickFileName("");
    formdata.current.delete("travelClickFile");

    setTravelClickFileToggle(!travelClickFileToggle);
  };
  const removeRateGainFile = () => {
    setRateGainFileName("");
    formdata.current.delete("rateGainFile");
  };
  const removeGenericFile = () => {
    setGenericFileName("");
    formdata.current.delete("genericFile");
  };

  const StyledCalenderWrapper = styled(Box)((theme) => ({
    borderRadius: "8px",
    boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
    "&:hover>*": {
      color: "#ffffff",
      ">input": {
        backgroundColor: "#306fbc",
        color: "#ffffff !important",
      },
    },
  }));

  return currentHotel?.isPreciumEnabled ? (
    !Error && compPriceView ? (
      <CompetitorPage>
        <HeaderNew>
          <HeaderCardLeftNew>
            <label>
              <StyledCalenderWrapper>
                <DatePicker
                  value={selectedDateNew}
                  onChange={setSelectedDateNew}
                  renderInput={renderCustomInput}
                  calendarClassName="myCustomCalendar"
                  maximumDate={maximumDate}
                />
                <CalenderIcon />
              </StyledCalenderWrapper>
            </label>
          </HeaderCardLeftNew>
          <HeaderCardCenterNew>
            <Select
              disableUnderline
              variant="standard"
              IconComponent="none"
              value={fileId}
              onChange={(e) => handleChange(e.target.value)}
            >
              {allFilesForDay.map((file, index) => (
                <MenuItem value={file.id} key={index}>
                  <Tab
                    style={{ marginRight: "10px" }}
                    label={file.time}
                    value={file.id}
                    {...a11yProps(file.id)}
                  />
                </MenuItem>
              ))}
            </Select>

            {competitorsData.length > 0 ? (
              <Schedule
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#306FBC",
                  marginLeft: "-42px",
                }}
              />
            ) : (
              <ScheduleIconDisabled
                style={{
                  color: "#6f6f6f",
                  width: "20px",
                  height: "20px",
                }}
              />
            )}
          </HeaderCardCenterNew>

          {!currentHotel?.rateShopEnabled && (
            <HeaderCardRightNew>
              {compPriceUpload ? (
                <UploadBtnNew
                  onClick={() => setOpenSelectBar(true)}
                  style={{ color: "#FFFFFF", whiteSpace: "nowrap" }}
                >
                  Upload Competitor <Publish />
                </UploadBtnNew>
              ) : (
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title={"Access Denied"}
                  placement="right-end"
                  arrow
                >
                  <UploadBtnNew
                    next
                    style={{
                      opacity: "0.38",
                      cursor: "not-allowed",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Upload Competitor <Publish />
                  </UploadBtnNew>
                </Tooltip>
              )}
            </HeaderCardRightNew>
          )}
        </HeaderNew>
        <Modal open={openSelectbar} onClose={() => setOpenSelectBar(false)}>
          <Box>
            <ModelBody
              hotelSelect={hotelSelect}
              fileHandler={fileHandler}
              ratepingFileGetter={ratepingFileGetter}
              expediaFileGetter={expediaFileGetter}
              travelClickGetter={travelClickGetter}
              setOpenSelectBar={setOpenSelectBar}
              removeRatepingFile={removeRatepingFile}
              removeExpediaFile={removeExpediaFile}
              ratepingFilename={ratepingFilename}
              expediaFilename={expediaFilename}
              ratepingToggle={ratepingToggle}
              expediaToggle={expediaToggle}
              uploadActualDate={uploadActualDate}
              setUploadActualDate={setUploadActualDate}
              otaToggle={otaToggle}
              travelClickFileToggle={travelClickFileToggle}
              otaFilename={otaFilename}
              travelClickFileName={travelClickFileName}
              removeOtaFile={removeOtaFile}
              removeTravelClickFile={removeTravelClickFile}
              otaFileGetter={otaFileGetter}
              rategainToggle={rategainToggle}
              rateGainFileName={rateGainFileName}
              rateGainGetter={rateGainGetter}
              removeRateGainFile={removeRateGainFile}
              genericToggle={genericToggle}
              genericFileName={genericFileName}
              genericFileGetter={genericFileGetter}
              removeGenericFile={removeGenericFile}
              compPriceUploadForSpecificDate={compPriceUploadForSpecificDate}
            />
          </Box>
        </Modal>
        <NetworkPrompt>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseStatus}
          >
            {networkMsg !== "Upload Successful" ? (
              <SnackbarContent
                style={{ backgroundColor: "#CA3433" }}
                message={networkMsg}
              />
            ) : (
              <SnackbarContent
                style={{ backgroundColor: "#228b22" }}
                message={networkMsg}
              />
            )}
          </Snackbar>
        </NetworkPrompt>
        {!Loading ? (
          haveData ? (
            <>
              <Wrapper>
                <PricingTable
                  array={competitorsData}
                  competitors={competitors}
                  fileId={fileId}
                  allFilesForDay={allFilesForDay}
                  handleChange={handleChange}
                  uploadImage={uploadImage}
                  sendRemark={sendRemark}
                  selectedImages={selectedImages}
                  setSelectedImages={setSelectedImages}
                  remarkData={remarkData}
                  setRemarkData={setRemarkData}
                  popoverData={popoverData}
                  setPopOverData={setPopOverData}
                />
              </Wrapper>
            </>
          ) : (
            <Nodata />
          )
        ) : (
          <LoadingPage />
        )}
      </CompetitorPage>
    ) : !compPriceView ? (
      <NoAccess />
    ) : (
      <ErrorPage />
    )
  ) : (
    <PreciumDisabledPage />
  );
};
export default CompetitorPrice;
