import React, { useCallback } from "react";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Popover, Box, Stack, TableBody, Typography } from "@mui/material";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../sdk";
import { styled } from "@mui/system";

let rows = new Array(25).fill({
  date: "02/02/2023",
  marketDemandPercent: 33,
  marketDemandValue: "M",
  hotel1: 456,
  hotel2: 456,
  hotel3: 456,
  hotel4: 456,
  alert: 0,
});
let alerts = new Array(5).fill("Hotel Lalit went 3% down your rate");
let popoverTable = new Array(6).fill({
  property: "Movenpick Hotel & Residence",
  rate: "345",
  room: "Superior Room",
});

const ClassWrapper = styled(Box)(({ theme }) => ({
  overflow: "auto",
  maxHeight: "196px",
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#163A90",
    borderRadius: "6px",
  },
}));

const GraphTable = ({ tableData: { competitors, data } }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { currentHotel } = useAuth();
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const [openAlert, setOpenAlert] = React.useState(false);
  const openAlertDialog = () => {
    setOpenAlert(true);
  };

  const closeAlertDialog = () => {
    setOpenAlert(false);
  };
  const history = useHistory();
  const location = useLocation();

  const onChangeSelectedDate = useCallback(
    (date) => {
      const params = new URLSearchParams({ date });
      history.push({
        pathname: location.pathname,
        search: params.toString(),
      });
    },
    [history, location.pathname]
  );
  return (
    <>
      <Box
        sx={{
          background: "white",
          width: "95%",
          borderRadius: "5px",
          mt: 7,
          border: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <ClassWrapper>
          <Table
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
            }}
            size="small"
          >
            <TableHead
              sx={{
                borderTop: "'1px solid rgba(0, 0, 0, 0.12)'",
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "'1px solid rgba(0, 0, 0, 0.12)'",
                },
              }}
            >
              <TableRow
                sx={{ height: "52px" }}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <TableCell>Date</TableCell>
                <TableCell align="center">Market Demand %</TableCell>
                <TableCell align="center">Market Demand Value</TableCell>
                <TableCell align="center" sx={{ color: "#0012B1" }}>
                  Trademark Hotel
                </TableCell>
                {competitors?.map((item) => (
                  <TableCell align="center">
                    {item?.hotelName?.length ? item?.hotelName : "--"}
                  </TableCell>
                ))}
                {/* <TableCell align="right"></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row) => (
                <TableRow
                  key={row.rateDate}
                  sx={{ height: "48px", cursor: "pointer" }}
                  onClick={() => onChangeSelectedDate(row.rateDate)}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                      color: "black",
                    }}
                  >
                    {row?.rateDate}
                  </TableCell>
                  <TableCell align="center">
                    {row?.baseDemandLevels?.occupancyPercentage
                      ? row?.baseDemandLevels?.occupancyPercentage?.toFixed(2)
                      : "--"}
                  </TableCell>
                  <TableCell align="center">
                    {row?.baseDemandLevels?.systemDemandLevelSymbol
                      ? row?.baseDemandLevels?.systemDemandLevelSymbol
                      : "--"}
                  </TableCell>
                  <TableCell align="center" sx={{ color: "#0012B1" }}>
                    {row?.hotelResult?.minPrice
                      ? (currentHotel.RSCurrency === "INR" ? "₹" : "$") +
                        row?.hotelResult?.minPrice
                      : row?.hotelResult?.minPrice === 0
                      ? "Sold Out"
                      : "--"}
                  </TableCell>
                  {row?.competitorsResults?.map((item) => (
                    <TableCell align="center">
                      {!item?.minPrice
                        ? item?.minPrice === 0
                          ? "Sold Out"
                          : "--"
                        : (currentHotel.RSCurrency === "INR" ? "₹" : "$") +
                          item?.minPrice}
                    </TableCell>
                  ))}
                  {/* <TableCell
                                        align="right"
                                        onClick={openAlertDialog}
                                        sx={{ cursor: "pointer" }}
                                    >
                                        {row.alert === 0 ? (
                                            <>
                                                <Stack
                                                    direction={"row"}
                                                    spacing={0.5}
                                                >
                                                    <WarningAmberRoundedIcon
                                                        sx={{
                                                            color: "#F43535",
                                                            fontSize: "medium",
                                                        }}
                                                    />
                                                    <Typography
                                                        style={{
                                                            fontSize: "10px",
                                                            fontWeight: 500,
                                                            marginTop: "3px",
                                                        }}
                                                    >
                                                        2 Alerts
                                                    </Typography>
                                                </Stack>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassWrapper>
      </Box>

      {/* on hover show popover */}
      <Popover
        sx={{
          pointerEvents: "none",
          "& .MuiPopover-paper": {
            borderRadius: "35px",
            background: "#FFFFFF",
            border: "1px solid #525252",
            minWidth: "689px",
            minHeight: "401px",
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ p: 4 }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            Wednesday 01 February 2023{" "}
          </Typography>

          <Table sx={{ mt: 1 }} size={"small"}>
            <TableHead
              sx={{
                borderTop: "none",
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableRow>
                <TableCell>Property</TableCell>
                <TableCell align="center">Rate</TableCell>
                <TableCell align="center">Room</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              {popoverTable?.map((row, index) => (
                <TableRow key={row.index}>
                  <TableCell component="th" scope="row">
                    {row.property}
                  </TableCell>
                  <TableCell align="center">
                    {(currentHotel.RSCurrency === "INR" ? "₹" : "$") + row.rate}
                  </TableCell>
                  <TableCell align="right">{row.room}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Popover>

      {/* alert dialog */}
      <Dialog
        PaperProps={{
          sx: {
            minHeight: "270px",
            width: "557px",
            background: "#FFFFFF",
            borderRadius: "8px",
          },
        }}
        open={openAlert}
        onClose={closeAlertDialog}
      >
        <DialogContent>
          <Stack direction={"row"} justifyContent={"space-between"} pb={2}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "22px",
                lineHeight: "20px",
                color: "#FE0808",
              }}
            >
              5 Alerts:
            </Typography>
            <CloseIcon
              sx={{
                color: "#4F5A6A",
                fontSize: "small",
                cursor: "pointer",
              }}
              onClick={closeAlertDialog}
            />
          </Stack>
          {alerts?.map((item, index) => (
            <Typography
              key={item}
              sx={{
                fontSize: "22px",
                fontWeight: 400,
                lineHeight: "20px",
                color: "#000000",
                mt: 3,
                ml: 4,
              }}
            >
              {index + 1}. {item}
            </Typography>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GraphTable;
