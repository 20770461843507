import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  TextField,
  styled,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

const DraggingRow = styled(TableCell)(({ theme }) => ({
  background: `white`,
}));

const TableData = styled(TableCell)(({ theme }) => ({
  background: `white`,
  color: "black",
  alignItems: "center",
  padding: "4px",
}));

const Styledbutton = styled(IconButton)(({ theme }) => ({
  borderRadius: "4px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "rgba(48, 111, 188, 1)",
  width: "50%",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  ".MuiOutlinedInput-input": {
    padding: "5px",
  },
}));

export const DraggableTableRow = ({
  row,
  handleChange,
  disable,
  onDragEnd,
  setDeleteData,
  setDeleteIndex,
  handleDeleteModal,
}) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({
    id: row.original.sequence,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    cursor: "pointer",
  };
  const renderCell = (cell, i) => {
    switch (i) {
      case 0:
        return (
          <TableData {...cell.getCellProps()}>
            <Stack direction="row" justifyContent="center">
              {/* <DragHandle {...attributes} {...listeners} /> */}
              <span>{cell.render("Cell")}</span>
            </Stack>
          </TableData>
        );
      case 1:
        return (
          <TableData {...cell.getCellProps()}>
            <StyledTextField
              value={cell.value}
              onChange={(e) =>
                handleChange(
                  row?.index,
                  "systemName",
                  e.target.value,
                  row?.original
                )
              }
              onMouseDown={(e) => e.stopPropagation()}
            />
          </TableData>
        );
      case 2:
        return (
          <TableData {...cell.getCellProps()}>
            <StyledTextField
              value={cell.value}
              onChange={(e) => {
                handleChange(
                  row?.index,
                  "pmsName",
                  e.target.value,
                  row?.original
                );
              }}
              onMouseDown={(e) => e.stopPropagation()}
            />
          </TableData>
        );
      case 3:
        return (
          <TableData {...cell.getCellProps()}>
            <StyledTextField
              value={cell.value}
              onChange={(e) => {
                handleChange(
                  row?.index,
                  "description",
                  e.target.value,
                  row?.original
                );
              }}
              onMouseDown={(e) => e.stopPropagation()}
            />
          </TableData>
        );
      default:
        break;
    }
  };

  const handleMouseDown = (event) => {
    if (disable) return;
    listeners.onMouseDown(event);
  };

  return (
    <TableRow
      ref={setNodeRef}
      style={style}
      {...row.getRowProps()}
      onMouseDown={handleMouseDown}
    >
      {isDragging ? (
        <DraggingRow colSpan={row.cells.length}>&nbsp;</DraggingRow>
      ) : (
        row.cells.map((cell, i) => renderCell(cell, i))
      )}
      {!isDragging && (
        <>
          <TableCell align="center" sx={{ width: "10%", padding: "4px" }}>
            <Styledbutton
              onClick={() => {
                setDeleteData(row?.original);
                setDeleteIndex(row?.index);
                handleDeleteModal("open");
              }}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <Delete />
            </Styledbutton>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};
