import {
  Checkbox,
  Snackbar,
  SnackbarContent,
  Paper,
  Table,
  TableBody,
  Grid,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import { SaveNEditBtn } from "../../sdk";
import {
  CardContainer,
  Head,
  HeaderCard,
  ScrollSection,
  TCell as TC,
  TContainer,
  TextFieldSmall,
  THead,
  TRow,
} from "./../Styles";

const TCell = styled(TC)`
  &.MuiTableCell-root {
    &:nth-child(2) {
      padding: ${(props) =>
        props.isInEditMode ? "0 20px 0 0" : "5px 20px 5px 0"};
    }
  }
`;

export function OccupancyMultiplier({
  occupancies,
  isInEditMode,
  setIsInEditMode,
  onChange,
  handleMultiplier,
  multipliers,
  open,
  handleCloseStatus,
  networkMsg,
}) {
  return (
    <Grid cols={2}>
      <CardContainer>
        <HeaderCard>
          <Head>
            <div className="title">Occupancy</div>
            <Checkbox
              checked={multipliers.isOccupancy}
              onChange={(e) =>
                handleMultiplier("isOccupancy", e.target.checked)
              }
              style={{ color: "#210D79" }}
            />
          </Head>
          <SaveNEditBtn
            onClick={() => setIsInEditMode(!isInEditMode)}
            $isineditmode={isInEditMode}
          >
            {isInEditMode ? "Save" : "Edit"}
          </SaveNEditBtn>
        </HeaderCard>
        <ScrollSection>
          <TContainer component={Paper}>
            <Table aria-label="customized table">
              <THead>
                <TRow>
                  <TCell align="left">Min</TCell>
                  <TCell align="left">Max</TCell>
                  <TCell align="right">Weekday</TCell>
                  <TCell align="right">Weekend</TCell>
                </TRow>
              </THead>
              <TableBody>
                {occupancies &&
                  occupancies.map((row, idx) => (
                    <TRow key={idx}>
                      <TCell align="left" $isineditmode={isInEditMode}>
                        {isInEditMode ? (
                          <TextFieldSmall
                            type="number"
                            value={row.min}
                            onChange={(e) =>
                              onChange(idx, "min", parseFloat(e.target.value))
                            }
                          />
                        ) : (
                          row.min
                        )}
                      </TCell>
                      <TCell align="left" $isineditmode={isInEditMode}>
                        {isInEditMode ? (
                          <TextFieldSmall
                            type="number"
                            value={row.max}
                            onChange={(e) =>
                              onChange(idx, "max", parseFloat(e.target.value))
                            }
                          />
                        ) : (
                          row.max
                        )}
                      </TCell>
                      <TCell align="center" $isineditmode={isInEditMode}>
                        {isInEditMode ? (
                          <TextFieldSmall
                            type="number"
                            value={row.mwValue}
                            onChange={(e) =>
                              onChange(
                                idx,
                                "mwValue",
                                parseFloat(e.target.value)
                              )
                            }
                          />
                        ) : row.mwValue ? (
                          Math.round((row.mwValue + Number.EPSILON) * 100) / 100
                        ) : (
                          row.mwValue
                        )}
                      </TCell>
                      <TCell align="center" $isineditmode={isInEditMode}>
                        {isInEditMode ? (
                          <TextFieldSmall
                            type="number"
                            value={row.weValue}
                            onChange={(e) =>
                              onChange(
                                idx,
                                "weValue",
                                parseFloat(e.target.value)
                              )
                            }
                          />
                        ) : row.weValue ? (
                          Math.round((row.weValue + Number.EPSILON) * 100) / 100
                        ) : (
                          row.weValue
                        )}
                      </TCell>
                    </TRow>
                  ))}
              </TableBody>
            </Table>
          </TContainer>
        </ScrollSection>
      </CardContainer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseStatus}
      >
        <SnackbarContent
          style={{ backgroundColor: "#CA3433" }}
          message={networkMsg}
        />
      </Snackbar>
    </Grid>
  );
}
