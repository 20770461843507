import {
  MenuItem,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
  Paper,
} from "@mui/material";
import React, { Fragment } from "react";
import { DatePicker } from "../../sdk";
import {
  AddButtonIcon,
  CardContainer,
  RemoveButtonIcon,
  Select,
  Tabhead,
  TCell,
  TContainer,
  THead,
  TRow,
} from "./Styles";

const seasons = {
  high: "High",
  low: "Low",
};

export default function SeasonDatesTable({
  seasonDetails,
  isInEditMode,
  onChange,
  open,
  handleCloseStatus,
  networkMsg,
  onRemoveRowHandler,
  onAddRowHandler,
}) {
  return (
    <div>
      <CardContainer>
        <TContainer style={{ maxHeight: "50vh" }} component={Paper}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{
              width: "100%",
              border: "1px solid rgba(0, 0, 0, 0.12)",

              borderRadius: "8px 0px 0px 0px",
            }}
          >
            <THead style={{ height: "1px", padding: "0px" }}>
              <TRow>
                <Tabhead>Start Date</Tabhead>
                <Tabhead>End Date</Tabhead>
                <Tabhead $isineditmode={isInEditMode}>Season Dates</Tabhead>
                {isInEditMode && <Tabhead>Actions</Tabhead>}
              </TRow>
            </THead>
            <TableBody>
              {seasonDetails.map((row, idx) => (
                <Fragment key={idx}>
                  <TRow key={idx}>
                    <TCell $isineditmode={isInEditMode}>
                      {isInEditMode ? (
                        <DatePicker
                          date={row.startDate}
                          onChange={(sDate) =>
                            onChange(idx, "startDate", sDate)
                          }
                        />
                      ) : (
                        row.startDate.toLocaleDateString("en-GB")
                      )}
                    </TCell>
                    <TCell $isineditmode={isInEditMode}>
                      {isInEditMode ? (
                        <DatePicker
                          date={row.endDate}
                          onChange={(eDate) => onChange(idx, "endDate", eDate)}
                        />
                      ) : (
                        row.endDate.toLocaleDateString("en-GB")
                      )}
                    </TCell>
                    <TCell $isineditmode={isInEditMode}>
                      {isInEditMode ? (
                        <Select
                          value={row.season || ""}
                          onChange={(e) =>
                            onChange(idx, "season", e.target.value)
                          }
                        >
                          <MenuItem value={seasons.high.toLowerCase()}>
                            {seasons.high}
                          </MenuItem>
                          <MenuItem value={seasons.low.toLowerCase()}>
                            {seasons.low}
                          </MenuItem>
                        </Select>
                      ) : row.season === "high" ? (
                        seasons.high
                      ) : (
                        row.season === "low" && seasons.low
                      )}
                    </TCell>

                    {isInEditMode && (
                      <TCell>
                        <AddButtonIcon
                          onClick={() => onAddRowHandler(idx)}
                          style={{ width: "20px", height: "20px" }}
                        />

                        <RemoveButtonIcon
                          onClick={() => onRemoveRowHandler(idx)}
                          style={{ width: "20px", height: "20px" }}
                        />
                      </TCell>
                    )}
                  </TRow>
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TContainer>
      </CardContainer>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseStatus}
      >
        <SnackbarContent
          style={{ backgroundColor: "#CA3433" }}
          message={networkMsg}
        />
      </Snackbar>
    </div>
  );
}
