import { Box, Snackbar, SnackbarContent } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GhostButton, PrimaryButton } from "../../sdk";
import useMarketSegment from "../hooks/useMarketSegment";
import { CompetitorPage, NextBtnContainer } from "./Styles";
import DragableTable from "./DraggableTable/Table";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MarketSegment() {
  const {
    marketData,
    addDetail,
    handleChange,
    saveMarketData,
    networkMsg,
    open,
    handleCloseStatus,
    isLoading,
    enableButton,
    reorder,
    handleDeleteRow,
    disable,
    handleNext,
    isOpenDialog,
    handleCloseDialog,
    handleLeavePage,
    handleModalSave,
    formEdit,
    handleRedirect,
  } = useMarketSegment();

  const [items, setItems] = useState(marketData);
  const [deleteData, setDeleteData] = useState(undefined);
  const [deleteIndex, setDeleteIndex] = useState(undefined);
  const { hotelId } = useParams();

  useEffect(() => {
    setItems(marketData);
  }, [marketData]);

  const onDragEnd = ({ destination, source }) => {
    if (destination < 0 || destination > items.length - 1) return;
    if (destination === items.length) destination = 0;

    const newItems = reorder(
      items,
      source,
      destination,
      items?.[source],
      items?.[destination]
    );
    setItems([...newItems]);
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteModal = useCallback(
    (tag) => {
      setDeleteModal(!deleteModal);
      if (tag === "confirm") {
        if (deleteData !== undefined && deleteIndex !== undefined) {
          handleDeleteRow(deleteData, deleteIndex);
        }
      }
    },
    [deleteModal, deleteData, deleteIndex, handleDeleteRow]
  );

  return (
    <>
      <CompetitorPage
        style={{
          overflow: "auto",

          borderRadius: "16px",
          boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
        }}
      >
        <DragableTable
          data={items}
          setData={setItems}
          handleChange={handleChange}
          disable={disable}
          onDragEnd={onDragEnd}
          reorder={reorder}
          setDeleteData={setDeleteData}
          setDeleteIndex={setDeleteIndex}
          handleDeleteModal={handleDeleteModal}
        />

        <Dialog
          open={deleteModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            handleDeleteModal("cancel");
          }}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{" Delete the Row"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              If you want to delete this row, Please confirm.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleDeleteModal("cancel");
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleDeleteModal("confirm");
              }}
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={isOpenDialog} onClose={handleCloseDialog}>
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent={"space-between"}
            p={2}
          >
            <Typography variant="h5" fontWeight={700}>
              Warning
            </Typography>
            <IconButton onClick={handleCloseDialog}>
              <CancelIcon
                sx={{
                  color: "#306fbc",
                }}
              />
            </IconButton>
          </Stack>
          <DialogContent
            sx={{
              width: 600,
            }}
          >
            <Typography variant="h6" fontWeight={700} textAlign={"center"}>
              Do you want to save your changes?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              spacing={3}
            >
              <PrimaryButton
                loading={isLoading}
                sx={{
                  width: "100%",
                }}
                onClick={handleLeavePage}
              >
                No
              </PrimaryButton>
              <PrimaryButton
                loading={isLoading}
                sx={{
                  width: "100%",
                }}
                onClick={handleModalSave}
              >
                Yes
              </PrimaryButton>
            </Stack>
          </DialogActions>
        </Dialog>

        <NextBtnContainer style={{ flexDirection: "row" }}>
          {marketData.length !== 0 ? (
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <PrimaryButton
                type="submit"
                loading={isLoading}
                disabled={!enableButton || !formEdit}
                onClick={saveMarketData}
                sx={{
                  backgroundColor: formEdit ? "#306fbc" : "#a8a4a4 !important",
                }}
              >
                Save
              </PrimaryButton>
              <PrimaryButton
                disabled={!enableButton}
                style={
                  enableButton
                    ? {
                        textTransform: "none",
                        font: "normal normal bold 12px/15px Roboto",
                        color: "white",
                        backgroundColor: "#306FBC",
                      }
                    : {
                        cursor: "not-allowed",
                        disabled: "true",
                        textTransform: "none",
                        font: "normal normal bold 12px/15px Roboto",
                        color: "white",
                        backgroundColor: "#306FBC",
                      }
                }
                onClick={() => {
                  handleRedirect(`/hotel/${hotelId}/setup/budget-setup`);
                }}
              >
                Next
              </PrimaryButton>
            </Stack>
          ): <Box/>}

          <GhostButton onClick={addDetail} style={{ color: "#306FBC" }}>
            + Add Market Segment
          </GhostButton>
        </NextBtnContainer>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={open}
          autoHideDuration={3000}
          onClose={handleCloseStatus}
        >
          {networkMsg !== "Saved Successfully" ? (
            <SnackbarContent
              style={{ backgroundColor: "#CA3433" }}
              message={networkMsg}
            />
          ) : (
            <SnackbarContent
              style={{ backgroundColor: "#228B22" }}
              message={networkMsg}
            />
          )}
        </Snackbar>
      </CompetitorPage>
    </>
  );
}
