import { BASE_URL } from "./constants";

export function canRetryRequest(status) {
  if (status === 502) {
    // Bad Gateway: possibly due to deployment, should resolve itself
    return true;
  } else if (status === 504) {
    // Gateway Timeout: deployment, or overwhelmed service
    return true;
  } else if (status === 429) {
    // Rate limit, slow things down just a tad
    return true;
  } else {
    return false;
  }
}

export const logout = () => {
  localStorage.clear();
  window.history.replaceState(null, "", "/");
  window.location.href = "/";
};

export const isObject = (obj) =>
  Object.prototype.toString.call(obj) === "[object Object]" ||
  Object.prototype.toString.call(obj) === "[object Array]";

const fetchArgsDefaults = {
  customOptions: {
    retries: 3,
    retryDelay: 1000,
    timeout: 3000000,
    path: "",
    url: BASE_URL.replace(/\/$/, ""),
    interceptors: {},
    onAbort: () => {},
    onTimeout: () => {},
  },
  requestInit: { headers: new Headers() },
  defaults: {
    data: undefined,
  },
};

export const apiFetchArgsDefaults = Object.entries(fetchArgsDefaults).reduce(
  (acc, [key, value]) => {
    if (isObject(value)) {
      return { ...acc, ...value };
    } else {
      return { ...acc, [key]: value };
    }
  },
  {}
);

export async function routeAndOptions(
  initalOptions,
  url,
  path,
  method,
  controller,
  routeOrBody,
  requestInterceptor
) {
  const route = (() => {
    if (routeOrBody instanceof URLSearchParams) {
      return `?${routeOrBody}`;
    } else if (typeof routeOrBody === "string") {
      return routeOrBody;
    } else {
      return "";
    }
  })();

  const body = (() => {
    if (routeOrBody instanceof FormData) {
      return routeOrBody;
    } else if (isObject(routeOrBody)) {
      return JSON.stringify(routeOrBody);
    } else if (isObject(initalOptions.body)) {
      return JSON.stringify(initalOptions.body);
    } else {
      return null;
    }
  })();

  const headers = (() => {
    const initalHeaders = initalOptions.headers || {};
    const contentType = initalHeaders.hasOwnProperty("Content-Type")
      ? initalHeaders["Content-Type"]
      : "";
    const shouldAddContentType =
      !!contentType ||
      ([HTTPMethod.POST, HTTPMethod.PUT, HTTPMethod.PATCH].includes(method) &&
        !(body instanceof FormData));

    const headers = new Headers({ ...initalOptions.headers });
    if (shouldAddContentType) {
      headers.append("Content-Type", contentType || "application/json");
    } else if (Object.keys(headers).length === 0) {
      return null;
    }
    return headers;
  })();

  const options = await (async () => {
    const opts = {
      ...initalOptions,
      method,
      signal: controller.signal,
    };

    if (headers === null) {
      opts.headers = new Headers();
    } else {
      opts.headers = headers;
    }

    if (body !== null) {
      opts.body = body;
    }

    if (requestInterceptor) {
      return await requestInterceptor(opts, url, path, route);
    }
    return opts;
  })();

  return {
    route,
    options,
  };
}

export function getField(field, options) {
  if (isObject(options) && options[field]) {
    return options[field];
  }
  return apiFetchArgsDefaults[field];
}

export function parseRequestInit(options) {
  if (!options) {
    return {};
  }
  const requestInitFields = [
    "body",
    "cache",
    "credentials",
    "headers",
    "integrity",
    "keepalive",
    "method",
    "mode",
    "redirect",
    "referrer",
    "referrerPolicy",
    "signal",
    "window",
  ];
  return requestInitFields.reduce((acc, key) => {
    if (key in options) {
      acc[key] = options[key];
    }
    return acc;
  }, {});
}

export const isEmpty = (x) => x === undefined || x === null;

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const HTTPMethod = {
  DELETE: "DELETE",
  GET: "GET",
  HEAD: "HEAD",
  OPTIONS: "OPTIONS",
  PATCH: "PATCH",
  POST: "POST",
  PUT: "PUT",
};
