import React from "react";
import { styled } from "@mui/system";
import { formatNumber, useAuth } from "../../sdk";
import {
  ARR,
  Body,
  Bookings,
  Card,
  CardDate,
  CardName,
  Details,
  Header,
  Info,
  Revenue,
  RevPAR,
  Title,
  Value,
} from "./Styles";

import { Typography, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
const MONTHSARRAY = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const Head = styled(Box)``;
const Occupancy = styled(Typography)`
  text-align: left;
  font: normal normal normal 32px/36px Roboto;
  letter-spacing: 0px;
  color: #1d1d1d;
  display: flex;
`;
const CardNameNew = styled(CardName)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;

  text-transform: capitalize;

  color: #303030;
`;
const TitleNew = styled(Title)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
`;
const ValueNew = styled(Value)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;

  text-transform: capitalize;

  color: #000000;
`;

const CardDateNew = styled(CardDate)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;

  text-transform: capitalize;

  color: #777777;
`;
const BorderLIne = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.25);
  transform: rotate(180deg);
  height: 118px;
`;
const HeaderNew = styled(Header)`
  margin-bottom: 4px;
  margin-top: -4px;
`;

export default function LastNightCard({ data }) {
  const [lastNightDay, lastNightMonth, lastNightYear] = new Date(
    new Date(data?.date).setDate(new Date(data?.date).getDate() - 1)
  )
    .toLocaleDateString("en-GB")
    .split("/");

  const lastNightDate =
    lastNightDay +
    " " +
    MONTHSARRAY[parseInt(lastNightMonth) - 1] +
    " " +
    lastNightYear;
  const CircularProgressBox = () => {
    return (
      <Box style={{ position: "relative" }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: "#E3E3E3",
          }}
          size={110}
          thickness={3}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          sx={{
            color: "#306FBC",
            position: "absolute",
            left: 0,
          }}
          size={110}
          thickness={3}
          value={
            parseFloat(data?.occupancyPercentage).toFixed(2) >= 100
              ? 100
              : parseFloat(data?.occupancyPercentage).toFixed(2)
          }
        />
        <Box
          style={{
            top: 0,
            left: 0,
            marginLeft: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Typography variant="caption" component="Box" color="text.secondary">
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "20px",
                color: "#000000 ",
                textTransform: "capitalize",
              }}
            >
              {`${parseFloat(data?.occupancyPercentage).toFixed(2)}%`}
            </Box>
            <Head>
              <Title
                style={{
                  width: "114px",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "12px",
                  lineHeight: "14px",

                  textTransform: "capitalize",

                  color: "#000000 ",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Occupancy
              </Title>
            </Head>
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Card>
      <HeaderNew>
        <CardNameNew>Last Night Performance</CardNameNew>
        <CardDateNew>{lastNightDate}</CardDateNew>
      </HeaderNew>
      <Body>
        <Info style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Details>
            <Box>
              <CircularProgressBox />
            </Box>
          </Details>
          <Details>
            <BorderLIne />
          </Details>
          <Details>
            <Bookings>
              <TitleNew>Rooms Sold</TitleNew>
              <ValueNew>
                {formatNumber(parseInt(data?.lastNight?.booking))}
              </ValueNew>
            </Bookings>
            <ARR>
              <TitleNew>ADR</TitleNew>
              <ValueNew>
                {formatNumber(parseFloat(data?.lastNight?.arr).toFixed(2))}
              </ValueNew>
            </ARR>
            <Revenue>
              <TitleNew>Revenue</TitleNew>
              <ValueNew>{formatNumber(data?.lastNight?.revenue)}</ValueNew>
            </Revenue>
            <RevPAR>
              <TitleNew>RevPAR</TitleNew>
              <ValueNew>
                {formatNumber(parseFloat(data?.lastNight?.revPAR).toFixed(2))}
              </ValueNew>
            </RevPAR>
          </Details>
        </Info>
      </Body>
    </Card>
  );
}
