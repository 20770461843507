import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  ButtonContainer,
  Container,
  Eye,
  FormContainerBox,
  Header,
  Logo,
  LogoHeader,
  PasswordLookup,
  Text,
  TextBox,
  TextField,
} from "./Styles";
import { Box } from "@mui/material";

const initialData = {
  username: "",
  password: "",
};

function LoginForm({ login, isLoading }) {
  const [data, setData] = useState(initialData);
  const [visibility, setVisibility] = useState(false);
  function handleSubmit(e) {
    e.preventDefault();
    login(data);
  }

  function togglePassword() {
    if (data?.password?.length) {
      setVisibility(!visibility);
    }
  }

  return (
    <Box>
      <Header>
        <Box>
          <LogoHeader>
            <Logo>
              <img
                src="/assets/PreciumLogo.svg"
                alt="Logo"
                width="215px"
                height="85px"
              />
            </Logo>
          </LogoHeader>
        </Box>
      </Header>
      <Container>
        <TextBox pb={4}>Sign in</TextBox>
        <FormContainerBox component={"form"} onSubmit={handleSubmit}>
          <PasswordLookup>
            <TextField
              type="text"
              placeholder="Username"
              InputProps={{
                disableUnderline: true,
              }}
              name="username"
              value={data.username}
              onChange={(e) => {
                setData({
                  ...data,
                  [e.target.name]: e.target.value,
                });
              }}
            />
          </PasswordLookup>

          <PasswordLookup mt={1.5}>
            <TextField
              placeholder="Password"
              type={visibility ? "text" : "password"}
              name="password"
              value={data.password}
              onChange={(e) => {
                setData({
                  ...data,
                  [e.target.name]: e.target.value,
                });
              }}
              InputProps={{
                disableUnderline: true,
              }}
            />

            <Eye>
              <img
                src={
                  visibility
                    ? "assets/visibility_off-24px.svg"
                    : "assets/visibility-24px.svg"
                }
                alt="Visibility Icon"
                onClick={togglePassword}
                style={{
                  opacity: data?.password?.length ? "1" : "0.5",
                  width: "24px",
                  height: "24px",
                }}
              />
            </Eye>
          </PasswordLookup>

          <ButtonContainer mt={1.5}>
            <Button
              sx={{
                minWidth: "250px !important",
                textTransform: "none",
              }}
              type="submit"
              loading={isLoading}
              disabled={isLoading}
            >
              Login
            </Button>
          </ButtonContainer>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <Link
              to="#"
              style={{
                textDecoration: "none",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Text>Contact Us</Text>
            </Link>
            <Link to="/forgot_password" style={{ textDecoration: "none" }}>
              <Text>Forgot Password</Text>
            </Link>
          </Box>
        </FormContainerBox>
      </Container>
    </Box>
  );
}

export default LoginForm;
