import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  SnackbarContent,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import useScheduling from "../hooks/useScheduling";
import { Close, CloseRounded } from "@mui/icons-material";
import { DialogLabel } from "../../BusinessOnBooks/Styles";
import { PrimaryButton } from "../../sdk";

export default function Scheduling() {
  const {
    hotelId,
    formEdit,
    allSources,
    nights,
    guests,
    handleChange,
    handleRedirect,
    handleSaveRateShop,
    handleSaveScheduling,
    openSnackbar,
    setOpenSnackbar,
    snackBarMsg,
    myError,
    scheduleError,
    rateshopError,
    rateshopName,
    handleAddRateShop,
    schedulingName,
    setSchedulingName,
    handleAddScheduling,
    handleSchedulingChange,
    handleDeleteScheduling,
    isLoading,
    isSaving,
    week,
    rateshopAlerts,
    competitors,
    handleAddContactForAlert,
    handleAddSoldOutAlert,
    handleAddPriceAlert,
    handleContactAlertChange,
    handleSoldOutAlertChange,
    handlePriceAlertChange,
    handleSaveAlert,
    handleDeleteContact,
    fieldError,
    handleDeletePriceAlert,
    handleRemoveNewPriceAlert,
    confirmationDialog,
    setConfirmationDialog,
    changedRateShops,
  } = useScheduling();

  return (
    <>
      <SchedulingPageStack>
        <Typography fontSize="16px" fontWeight="600" paddingLeft="16px">
          RateShop
        </Typography>
        {isLoading && (
          <Stack justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        )}

        {rateshopName.map((item, index) => (
          <Stack className="borderStack" key={index}>
            {/* <IconButton>
            <EditIcon />
          </IconButton> */}
            <Stack direction="row" justifyContent="space-between" gap="24px">
              <TextField
                fullWidth
                label="Name"
                placeholder="Enter Rate Shop Name"
                value={item?.name}
                onChange={(e) => handleChange(e, "name", index)}
                error={myError && !item?.name}
              />

              <FormControl fullWidth>
                <InputLabel error={myError && !item?.occupancy}>
                  Guest
                </InputLabel>
                <Select
                  labelId="demo-checkbox-label"
                  id="demo-checkbox"
                  value={item?.occupancy}
                  onChange={(e) => handleChange(e, "occupancy", index)}
                  error={myError && !item?.occupancy}
                  input={<OutlinedInput label="Guest" />}
                  renderValue={(selected) => selected}
                >
                  {guests.map((guest) => (
                    <MenuItem key={guest} value={guest}>
                      <Checkbox
                        checked={item?.occupancy?.indexOf(guest) > -1}
                      />
                      <ListItemText primary={guest} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel error={myError && !item?.los}>LOS</InputLabel>
                <Select
                  labelId="demo-checkbox-label"
                  id="demo-checkbox"
                  value={item?.los}
                  onChange={(e) => handleChange(e, "los", index)}
                  error={myError && !item?.los}
                  input={<OutlinedInput label="LOS" />}
                  renderValue={(selected) => selected}
                >
                  {nights.map((night) => (
                    <MenuItem key={night} value={night}>
                      <Checkbox checked={item?.los?.indexOf(night) > -1} />
                      <ListItemText primary={night} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap="24px"
            >
              <Stack flexDirection="row" gap="24px" width="100%">
                <TextField
                  label="No. Of Days"
                  type="number"
                  value={item?.days}
                  error={myError && !item?.days}
                  onChange={(e) => handleChange(e, "days", index)}
                  sx={{ width: "34.4%" }}
                />
                <FormControl sx={{ width: "34.4%" }}>
                  <InputLabel error={myError && !item?.sources?.length > 0}>
                    OTA
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={item?.sources?.map((source) => source?.id)}
                    error={myError && !item?.sources?.length > 0}
                    onChange={(e) => handleChange(e, "sources", index)}
                    input={<OutlinedInput label="OTA" />}
                    renderValue={(selectedIds) => {
                      const selectedNames = selectedIds.map((id) => {
                        const source = allSources.find(
                          (source) => source?.id === id
                        );
                        return source?.isMobile
                          ? `${source?.name} (Mobile)`
                          : `${source?.name} (Desktop)`;
                      });
                      return selectedNames.join(", ");
                    }}
                  >
                    {allSources.map((source) => (
                      <MenuItem key={source?.id} value={source?.id}>
                        <Checkbox
                          checked={
                            item?.sources
                              ?.map((source) => source?.id)
                              .indexOf(source?.id) > -1
                          }
                        />
                        <ListItemText
                          primary={
                            source?.isMobile
                              ? `${source?.name} (Mobile)`
                              : `${source?.name} (Desktop)`
                          }
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>

              <LoadingButton
                variant="contained"
                sx={{ height: "40px", width: "90px" }}
                onClick={() => handleSaveRateShop(index)}
                disabled={!(changedRateShops.includes(index) && formEdit)}
              >
                save
              </LoadingButton>
            </Stack>
          </Stack>
        ))}

        <Stack direction="row" justifyContent="space-between" padding="24px">
          <Stack direction="row" gap="16px">
            <Button variant="contained" onClick={handleAddRateShop}>
              + Add RateShop
            </Button>

            <Button
              variant="contained"
              onClick={() =>
                handleRedirect(`/hotel/${hotelId}/setup/competitors`)
              }
            >
              Next
            </Button>
          </Stack>
        </Stack>

        <Typography fontSize="16px" fontWeight="600" paddingLeft="16px">
          Scheduling
        </Typography>

        {schedulingName.map((item, index) => (
          <Stack
            className="borderStack"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            position="relative"
          >
            <Stack width="95%" gap="24px">
              {item.scheduledId && (
                <IconButton
                  sx={{
                    position: "absolute",
                    bottom: "20px",
                    right: "20px",
                    color: "#1976D2",
                  }}
                  onClick={() => setConfirmationDialog(item?.scheduledId)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
              <Stack direction="row" justifyContent="space-between">
                <FormControl sx={{ width: "32%" }}>
                  <InputLabel error={scheduleError && !item?.rateshop}>
                    Select Rate Shop
                  </InputLabel>
                  <Select
                    labelId="demo-select-label"
                    id="demo-select"
                    value={item?.rateshop}
                    onChange={(e) =>
                      handleSchedulingChange(e, "rateshop", index)
                    }
                    input={<OutlinedInput label="Select Rate Shop" />}
                    error={scheduleError && !item?.rateshop}
                    renderValue={(rateshop) => {
                      return rateshop;
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                  >
                    {rateshopName.map((item, index) =>
                      item?.rateShopId ? (
                        <MenuItem key={index} value={item}>
                          {item?.name}
                        </MenuItem>
                      ) : null
                    )}
                  </Select>
                </FormControl>
                <TextField
                  sx={{ width: "32%" }}
                  label="Email"
                  type="email"
                  value={item?.emails?.join(",")} // Convert array to string with commas
                  onChange={(e) => handleSchedulingChange(e, "emails", index)}
                  error={scheduleError && !(item?.emails?.length > 0)}
                />
                <FormControl sx={{ width: "32%" }}>
                  <InputLabel error={scheduleError && !item?.dow}>
                    DOW
                  </InputLabel>
                  <Select
                    labelId="demo-select-label"
                    id="demo-select"
                    multiple
                    value={item?.dow ?? []}
                    onChange={(e) => handleSchedulingChange(e, "dow", index)}
                    input={<OutlinedInput label="DOW" />}
                    renderValue={(selectedDays) => {
                      const daystoDisplay = selectedDays.map((day) =>
                        week.find((_, index) => index === day)
                      );
                      return daystoDisplay.join(", ");
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                  >
                    {week.map((day, index) => (
                      <MenuItem key={index} value={index}>
                        <Checkbox checked={item?.dow?.includes(index)} />
                        <ListItemText primary={day} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>

              <Stack direction="row" gap="24px" alignItems="center">
                <FormControl sx={{ width: "200px" }}>
                  <InputLabel error={scheduleError && !item?.hour}>
                    Hour
                  </InputLabel>
                  <Select
                    labelId="demo-select-label"
                    id="demo-select"
                    value={item?.hour}
                    onChange={(e) => handleSchedulingChange(e, "hour", index)}
                    error={scheduleError && !item?.hour}
                    input={<OutlinedInput label="Hour" />}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                  >
                    {[...Array(24)].map((_, index) => {
                      const hour = index;
                      const time = `${hour < 10 ? "0" : ""}${hour}:00`;
                      return (
                        <MenuItem key={time} value={time}>
                          {time}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "200px" }}>
                  <InputLabel error={scheduleError && !item?.minute}>
                    Minutes
                  </InputLabel>
                  <Select
                    labelId="demo-select-label"
                    id="demo-select"
                    value={item?.minute}
                    onChange={(e) => handleSchedulingChange(e, "minute", index)}
                    error={scheduleError && !item?.minute}
                    input={<OutlinedInput label="Minutes" />}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                  >
                    {[...Array(60)].map((_, index) => {
                      const minute = index;
                      const time = `00:${minute < 10 ? "0" : ""}${minute}`;
                      return (
                        <MenuItem key={time} value={time}>
                          {time}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <LoadingButton
                  variant="contained"
                  sx={{
                    height: "40px",
                    width: "90px",
                  }}
                  onClick={() => handleSaveScheduling(index)}
                  disabled={item?.scheduledId || scheduleError || !formEdit}
                >
                  save
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
        ))}

        <Stack direction="row" padding="24px">
          <Button variant="contained" onClick={handleAddScheduling}>
            + Add Scheduling
          </Button>
        </Stack>
        <Typography fontSize="16px" fontWeight="600" paddingLeft="16px">
          Alert Setup
        </Typography>
        <Stack className="borderStack">
          <Typography fontSize="16px" fontWeight="500" paddingLeft="16px">
            Alert if competitor price is changed
          </Typography>
          <Stack gap={2} py={2} pl={6}>
            {rateshopAlerts?.competitorPriceAlerts?.map((alert, idx) => (
              <Stack gap={1} direction="row" alignItems={"center"}>
                <Typography>Alert me If</Typography>
                <TextField
                  select
                  sx={{ width: "150px" }}
                  value={alert?.competitorId}
                  variant="standard"
                  error={fieldError && !alert?.competitorId}
                  onChange={(e) =>
                    handlePriceAlertChange(
                      idx,
                      "competitorId",
                      e?.target?.value
                    )
                  }
                >
                  {competitors.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.displayKey}
                    </MenuItem>
                  ))}
                </TextField>
                <Typography>Hotel rate changes by</Typography>
                <TextField
                  sx={{ width: "10ch" }}
                  type="number"
                  value={alert?.alterPrice}
                  error={fieldError && alert?.alterPrice === ""}
                  onChange={(e) =>
                    handlePriceAlertChange(idx, "alterPrice", e?.target?.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                  variant="standard"
                />
                <Typography>(+,-)</Typography>
                <Checkbox
                  sx={{
                    ml: "40px",
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                  checked={alert?.priceAlertEnabled}
                  onChange={(e) =>
                    handlePriceAlertChange(
                      idx,
                      "priceAlertEnabled",
                      e?.target?.checked
                    )
                  }
                />
                {alert?.isNew ? (
                  <IconButton
                    sx={{
                      ml: "40px",
                    }}
                    onClick={() => handleRemoveNewPriceAlert(idx)}
                    disableRipple
                  >
                    <Close />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{
                      color: "#1976D2",
                      ml: "40px",
                    }}
                    disableRipple
                    onClick={() =>
                      handleDeletePriceAlert(
                        alert?.competitorId,
                        alert?.alterPrice,
                        alert?.priceAlertEnabled
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Stack>
            ))}
            <Button
              sx={{
                width: "max-content",
              }}
              size="small"
              variant="contained"
              onClick={handleAddPriceAlert}
            >
              Add Alert
            </Button>
          </Stack>
          <Typography fontSize="16px" fontWeight="500" paddingLeft="16px">
            Alert if competitor Hotel is Sold Out
          </Typography>
          <Stack gap={2} py={2} pl={6}>
            {rateshopAlerts?.competitorSoldOutAlerts?.map((alert, idx) => (
              <Stack gap={1} direction="row" alignItems={"center"}>
                <Typography>Alert me If</Typography>
                <TextField
                  select
                  sx={{ width: "150px" }}
                  value={alert?.competitorId}
                  variant="standard"
                  error={fieldError && !alert?.competitorId}
                  onChange={(e) =>
                    handleSoldOutAlertChange(
                      idx,
                      "competitorId",
                      e?.target?.value
                    )
                  }
                >
                  {competitors
                    .filter(
                      (option) =>
                        !rateshopAlerts?.competitorSoldOutAlerts.some(
                          (existingAlert) =>
                            existingAlert.competitorId === option.id
                        ) || option.id === alert.competitorId
                    )
                    .map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.displayKey}
                      </MenuItem>
                    ))}
                </TextField>
                <Typography>is sold out</Typography>

                <Checkbox
                  sx={{
                    ml: "40px",
                  }}
                  checked={alert?.soldOutAlertEnabled}
                  onChange={(e) =>
                    handleSoldOutAlertChange(
                      idx,
                      "soldOutAlertEnabled",
                      e?.target?.checked
                    )
                  }
                />
              </Stack>
            ))}
            {competitors?.some(
              (option) =>
                !rateshopAlerts?.competitorSoldOutAlerts?.some(
                  (alert) => alert.competitorId === option.id
                )
            ) && (
              <Button
                sx={{
                  width: "max-content",
                }}
                size="small"
                variant="contained"
                onClick={handleAddSoldOutAlert}
              >
                Add Alert
              </Button>
            )}
          </Stack>
          <Typography fontSize="16px" fontWeight="500" paddingLeft="16px">
            Alert Contact Details
          </Typography>
          <Stack gap={2} py={2} pl={6}>
            {rateshopAlerts?.contactForAlerts?.map((alert, idx) => (
              <Stack alignItems={"center"} direction="row" gap={3}>
                <TextField
                  label="Name"
                  type="text"
                  value={alert?.name}
                  onChange={(e) =>
                    handleContactAlertChange(idx, "name", e?.target?.value)
                  }
                />
                <TextField
                  label="Email"
                  type="email"
                  value={alert?.email}
                  error={fieldError && !alert?.email}
                  onChange={(e) =>
                    handleContactAlertChange(idx, "email", e?.target?.value)
                  }
                />
                <TextField
                  label="Phone no."
                  type="number"
                  value={alert?.contactNumber}
                  onChange={(e) =>
                    handleContactAlertChange(
                      idx,
                      "contactNumber",
                      e?.target?.value
                    )
                  }
                />
                {alert?.id && (
                  <IconButton
                    sx={{
                      color: "#1976D2",
                    }}
                    disableRipple
                    onClick={() => handleDeleteContact(alert.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Stack>
            ))}
            <Button
              sx={{
                width: "max-content",
              }}
              size="small"
              variant="text"
              onClick={handleAddContactForAlert}
            >
              + Add Contact
            </Button>
          </Stack>
          <Stack direction={"row"} justifyContent={"end"}>
            <LoadingButton
              variant="contained"
              sx={{
                height: "40px",
                width: "90px",
              }}
              onClick={handleSaveAlert}
              disabled={!formEdit}
            >
              save
            </LoadingButton>
          </Stack>
        </Stack>
      </SchedulingPageStack>
      <Dialog
        open={confirmationDialog}
        onClose={() => setConfirmationDialog(null)}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginTop: "20px",
            }}
          >
            <DialogLabel style={{ textAlign: "center" }}>
              Are you sure you want to remove this rateshop schedule ?
            </DialogLabel>
            <Cross
              style={{ position: "absolute", top: "10px", right: "10px" }}
              onClick={() => setConfirmationDialog(null)}
            >
              &times;
            </Cross>
          </div>{" "}
        </DialogTitle>
        <DialogActions
          style={{
            justifyContent: "center",
            gap: "16px",
            paddingBottom: 16,
            marginBottom: "20px",
          }}
        >
          <PrimaryButton
            autoFocus
            next
            onClick={() => {
              handleDeleteScheduling(confirmationDialog);
            }}
          >
            Yes
          </PrimaryButton>
          <PrimaryButton
            autoFocus
            next
            onClick={() => {
              setConfirmationDialog(null);
            }}
          >
            No
          </PrimaryButton>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      >
        <SnackbarContent
          style={{
            backgroundColor:
              snackBarMsg === "Saved Successfully" ||
              snackBarMsg === "Edited Successfully"
                ? "#228B22"
                : "#CA3433",
          }}
          message={snackBarMsg}
        />
      </Snackbar>
    </>
  );
}

const SchedulingPageStack = styled(Stack)(() => ({
  width: " 100%",
  background: "#ffffff",
  border: "none",
  padding: "20px",
  outline: "none",
  height: "calc(100vh - 160px)",
  overflowY: "scroll",
  overflowX: "hidden",
  boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
  gap: "24px",
  ".borderStack": {
    gap: "16px",
    padding: "24px",
    boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
  },
}));
const Cross = styled(CloseRounded)`
  width: 20px;
  height: 20px;
  :hover {
    cursor: pointer;
  }
`;
