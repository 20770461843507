import React from "react";
import { styled } from "@mui/system";
import { FormControl } from "@mui/material";

const Wrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.pricing ? "flex-start" : "space-around"};
  align-items: flex-start;
  width: 1120px;
  min-height: 360px;

  background-color: #ffffff;
  padding: 20px;
`;

export const FormWrapper = ({ children, ...props }) => {
  console.log();
  return <Wrapper {...props}>{children}</Wrapper>;
};
