import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { Route, useHistory, useLocation, useParams } from "react-router-dom";
import DemandLevelFactors from "../../DemandLevel";
import Multiplier from "../../Multipliers";
import { useAuth } from "../../sdk";
import {
  AlgorithmHeader as Header,
  HeaderCard,
  Heading,
  Padding,
  TabNav,
} from "./Styles";

export const Algorithm = () => {
  const [openSelectbarDLF, setOpenSelectBarDLF] = React.useState(false);
  const [openSelectbarMUL, setOpenSelectBarMUL] = React.useState(false);
  const { hotelId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { permission } = useAuth();
  let multiplier = null,
    DLF = null;
  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "DLF") {
        DLF = permission[key];
      }
      if (permission[key].name === "multiplier") {
        multiplier = permission[key];
      }
    }
  }
  useEffect(() => {
    if (DLF) {
      history.replace(`/hotel/${hotelId}/setup/algorithm/dlf`);
    } else {
      history.replace(`/hotel/${hotelId}/setup/algorithm/multiplier`);
    }
  }, [DLF, hotelId, history]);
  useEffect(() => {
    if (location.pathname === `/hotel/${hotelId}/setup/algorithm`) {
      history.replace(location.pathname + "/dlf");
    }
  }, [location.pathname, history, hotelId]);
  return (
    <>
      <Header>
        <HeaderCard>
          <TabNav
            exact
            to={`/hotel/${hotelId}/setup/algorithm/dlf`}
            activeStyle={{
              color: "rgb(48,111,188)",
              borderLeft: "4px solid rgb(48,111,188)",
            }}
          >
            <Padding>
              <Heading>Calculation Factors</Heading>

              {location.pathname === `/hotel/${hotelId}/setup/algorithm/dlf` ? (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setOpenSelectBarDLF(true)}
                >
                  Copy Settings
                </Button>
              ) : (
                <div></div>
              )}
            </Padding>
          </TabNav>
        </HeaderCard>

        <HeaderCard>
          <TabNav
            exact
            to={`/hotel/${hotelId}/setup/algorithm/multiplier`}
            activeStyle={{
              color: "rgb(48,111,188)",
              borderLeft: "4px solid rgb(48,111,188)",
            }}
          >
            <Padding>
              <Heading>Multipliers</Heading>

              {location.pathname ===
              `/hotel/${hotelId}/setup/algorithm/multiplier` ? (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setOpenSelectBarMUL(true)}
                >
                  Copy Settings
                </Button>
              ) : (
                <div></div>
              )}
            </Padding>
          </TabNav>
        </HeaderCard>
      </Header>
      <div>
        <Route exact path="/hotel/:hotelId/setup/algorithm/dlf">
          <DemandLevelFactors
            openSelectbar={openSelectbarDLF}
            setOpenSelectBar={setOpenSelectBarDLF}
          />
        </Route>
        <Route exact path="/hotel/:hotelId/setup/algorithm/multiplier">
          <Multiplier
            openSelectbar={openSelectbarMUL}
            setOpenSelectBar={setOpenSelectBarMUL}
          />
        </Route>
      </div>
    </>
  );
};
