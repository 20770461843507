import { Box, Modal } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { useParams } from "react-router";
import {
  AutomatedDLContainerIM as AutomatedDLContainer,
  AutomatedDLIM as AutomatedDL,
  B,
  Body,
  CellIM as Cell,
  CrossIM as Cross,
  DayIM as Day,
  DescriptionIM as Description,
  DotIM as Dot,
  EventItemIM as EventItem,
  EventsIM as Events,
  Footer,
  HeaderIM as Header,
  Index,
  IndexContainer,
  IndexKey,
  LabelIM as Label,
  LCell,
  Line,
  LowerCellIM as LowerCell,
  LowerCellLeft,
  ModalContainerIM as ModalContainer,
  OTBIM as OTB,
  OTBSectionIM as OTBSection,
  RCell,
  SingleEventContainerIM as SingleEventContainer,
  Space,
  Title,
  UpperCellIM as UpperCell,
} from "./style";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const ClassWrapper = styled(Box)(({ theme }) => ({
  ".paper": {
    position: "absolute",
    outline: 0,
    width: 400,
    height: 600,
    backgroundColor: "grey",
    border: "none",
    boxShadow: 5,
    padding: 2,
  },
}));

export function IndexModal({ isOpen, setOpen, onClose }) {
  const [modalStyle] = React.useState(getModalStyle);
  const { hotelId } = useParams();
  return (
    <ClassWrapper>
      <Modal open={isOpen} onClose={onClose}>
        <ModalContainer style={modalStyle} className="paper">
          <Header pt={4} px={4}>
            <Title>Index</Title>
            <Cross
              onClick={() => {
                setOpen(false);
              }}
            ></Cross>
          </Header>
          <Body>
            <LCell>
              <Events>
                <UpperCell
                  style={{ justifyContent: "flex-end", paddingTop: "15px" }}
                >
                  <Label>Date</Label>
                  <Line />
                </UpperCell>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    paddingTop: "10px",
                    width: "100%",
                  }}
                >
                  <LowerCellLeft style={{ justifyContent: "flex-end" }}>
                    <Label>Events</Label>
                    <Line />
                  </LowerCellLeft>
                </Box>
              </Events>
              <OTBSection style={{ paddingBottom: "15px" }}>
                <Label>On the books & Hotel DL</Label>
                <Line />
              </OTBSection>
            </LCell>
            <Cell>
              <Events>
                <UpperCell>
                  <Day>27</Day>
                  <Day>M</Day>
                </UpperCell>
                <LowerCell>
                  <Description>
                    <SingleEventContainer>
                      <Dot />
                      <EventItem>Event Name 1</EventItem>
                    </SingleEventContainer>
                    <SingleEventContainer>
                      <Dot />
                      <EventItem>Event Name 2</EventItem>
                    </SingleEventContainer>
                  </Description>
                </LowerCell>
              </Events>
              <OTBSection>
                <OTB>
                  83%
                  <AutomatedDLContainer
                    style={{ backgroundColor: "#5072C7", marginLeft: "4px" }}
                  >
                    <AutomatedDL>H</AutomatedDL>
                  </AutomatedDLContainer>
                </OTB>
                <AutomatedDLContainer>
                  <AutomatedDL>H+</AutomatedDL>
                </AutomatedDLContainer>
              </OTBSection>
            </Cell>

            <RCell>
              <UpperCell
                style={{ justifyContent: "flex-start", paddingTop: "10px" }}
              >
                <Line />
                <Label>
                  City Demand
                  <br /> Level
                </Label>
              </UpperCell>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  paddingTop: "10px",
                  width: "100%",
                }}
              >
                <LowerCellLeft
                  style={{ height: "30px", paddingBottom: "20px" }}
                >
                  <Line />
                  <Label>
                    Automated Demand <br />
                    Level
                  </Label>
                </LowerCellLeft>
              </Box>
            </RCell>
          </Body>
          <Footer>
            <Box style={{ width: "80%" }}>
              <Label style={{ paddingBottom: "20px", fontWeight: "bold" }}>
                Demand Level Index
              </Label>
              <IndexContainer>
                <Index>
                  <IndexKey style={{ backgroundColor: "#004283" }} />
                  <Label>
                    <B>EH</B>
                    <Box marginTop={0.5}>
                      {" "}
                      – Extreme High Demand – Major Conference in the City / Big
                      <br />
                      Project movement etc. Forecasted Occupancy is above 95%
                    </Box>
                  </Label>
                </Index>
                <Index>
                  <IndexKey style={{ backgroundColor: "#316899" }} />
                  <Label>
                    <B>H+</B>
                    <Box marginTop={0.5}>
                      {" "}
                      – High Demand Levels Plus– Days where Forecasted Occupancy
                      is above 85%-95%
                    </Box>
                  </Label>
                </Index>
                <Index>
                  <IndexKey style={{ backgroundColor: "#5072C7" }} />
                  <Label>
                    <B>H</B>
                    <Box marginTop={0.5}>
                      {" "}
                      – High Demand Levels – Days where Forecasted Occupancy is
                      60%-84%
                    </Box>
                  </Label>
                </Index>
                <Index>
                  <IndexKey style={{ backgroundColor: "#6198C8" }} />
                  <Label>
                    <B>M</B>
                    <Box marginTop={0.5}>
                      {" "}
                      – Medium Demand – Days where Forecasted Occupancy is
                      around 46-59%
                    </Box>
                  </Label>
                </Index>
                <Index width={"100%"}>
                  <IndexKey style={{ backgroundColor: "#E3728A" }} />
                  <Label>
                    <B>L</B>
                    <Box marginTop={0.5}>
                      {" "}
                      – Low Demand – Days where Forecasted Occupancy is around
                      30-45%
                    </Box>
                  </Label>
                </Index>
                <Index>
                  <IndexKey style={{ backgroundColor: "#D72D3C" }} />
                  <Label>
                    <B>D</B>
                    <Box marginTop={0.5}>
                      {" "}
                      – Distress Demand – Days where Forecasted Occupancy is
                      around 0-29%
                    </Box>
                  </Label>
                </Index>
                <Index>
                  <IndexKey style={{ backgroundColor: "rgb(119,136,153)" }} />
                  <Label>
                    <Box marginTop={0.5}>No DemandsLevels Present</Box>
                  </Label>
                </Index>
              </IndexContainer>
            </Box>
          </Footer>
        </ModalContainer>
      </Modal>
    </ClassWrapper>
  );
}
