import { useEffect, useState } from "react";
import { useAuth } from "../../sdk";

const EMPTY_ROW = {
  value: null,
  min: -100,
  max: 100,
};
export function useYOYVariance(hotelId, copyFromHotelId, YOY) {
  const [variances, setVariances] = useState([]);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [editYOYVariance, setEditYOYVariance] = useState([]);
  const [checkYOYVarianceValidation, setCheckYOYVarianceValidation] = useState(
    false
  );
  const [isCancelled, setIsCancelled] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [open, setOpen] = useState(false);
  const [Loading, setLoading] = useState(false);

  const handleCloseStatus = () => {
    setOpen(false);
  };

  const [networkMsg, setnetworkMsg] = useState(null);
  const { token, authFetch } = useAuth();
  useEffect(() => {
    if (!token) return;
    refreshVariances();
  }, [token, hotelId]);
  useEffect(() => {
    if (isInEditMode) {
      const allValidEntries = variances.every(isValid);
      const lastEntry = variances.length
        ? variances[variances.length - 1]
        : {
            max: -101,
          };
      const roomLeftForEntries = lastEntry.max < 100;
      if (allValidEntries) setCheckYOYVarianceValidation(false);
      else setCheckYOYVarianceValidation(true);

      if (roomLeftForEntries && allValidEntries) {
        setVariances([
          ...variances,
          {
            ...EMPTY_ROW,
            min: lastEntry.max + 1,
          },
        ]);
        setEditYOYVariance([
          ...editYOYVariance,
          (editYOYVariance[variances.length] = true),
        ]);
      }
    } else if (isSaved) {
      updateVariances();
    } else if (isCancelled) {
      refreshVariances();
    }
  }, [variances, isInEditMode]);
  useEffect(() => {
    if (copyFromHotelId === null && !YOY) return;
    if (YOY) refreshVariances(copyFromHotelId);
  }, [YOY]);

  function isValid(variance) {
    return (
      variance.min !== "" &&
      variance.max !== "" &&
      variance.value !== null &&
      variance.value !== "" &&
      variance.min <= variance.max
    );
  }
  async function refreshVariances(anotherHotelId = false) {
    const hotelIdToUse = anotherHotelId ? anotherHotelId : hotelId;
    setLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelIdToUse}/yoy-variance`,
    });
    const { data, error } = await get();
    if (error) {
      setnetworkMsg("Can't Fetch YOY");
      setOpen(true);
      console.log(error);
    }
    setIsCancelled(false);
    if (data) {
      setVariances(data.sort((a, b) => a.min - b.min));
      setEditYOYVariance([...new Array(data?.length)].map(() => false));
      if (data.length === 0) {
        setVariances([EMPTY_ROW]);
        setEditYOYVariance([false]);
        setIsInEditMode(false);
      }
    } else {
      setVariances([]);
    }
    if (anotherHotelId) {
      setIsInEditMode(true);
    }
    setLoading(false);
  }

  async function updateVariances() {
    const filteredVariances = variances.filter(isValid);
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/yoy-variance/all`,
    });
    const { error, response, data } = await post(filteredVariances);
    if (!response?.ok) {
      setnetworkMsg(data?.messageToUser || "Can't Update YOY");
      setOpen(true);
      console.log(error);
    }
    setIsSaved(false);
    refreshVariances();
  }
  function changeHandler(index, key, value) {
    setVariances((prevState) => {
      return prevState.map((row, idx) =>
        idx === index
          ? {
              ...row,
              [key]: value,
            }
          : row
      );
    });
  }
  const onRemoveRowHandler = (index) => {
    setVariances((prevState) => {
      return prevState.filter((_, idx) => idx !== index);
    });
    setEditYOYVariance((prevState) => {
      return prevState.filter((_, idx) => idx !== index);
    });
    setIsInEditMode(true);
  };

  const onAddRowHandler = (index) => {
    setVariances([
      ...variances.filter((_, idx) => idx <= index),
      {
        value: "",
        min: "",
        max: "",
      },
      ...variances.filter((_, idx) => idx > index),
    ]);
    setEditYOYVariance([
      ...editYOYVariance.slice(0, index + 1),
      true,
      ...editYOYVariance.slice(index + 1),
    ]);
    setIsInEditMode(true);
  };

  const handleEditYOYVarianceChange = (index) => {
    const result = editYOYVariance.map((data, pos) => {
      if (pos === index) {
        return (editYOYVariance[index] = !editYOYVariance[index]);
      } else {
        return editYOYVariance[pos];
      }
    });
    const checkEditMode = result.every((d) => d === false);
    if (checkEditMode) setIsInEditMode(false);
    else setIsInEditMode(true);
    setEditYOYVariance(result);
  };

  return {
    variances,
    vIsInEditMode: isInEditMode,
    setVIsInEditMode: setIsInEditMode,
    vIsCancelled: isCancelled,
    vSetIsCancelled: setIsCancelled,
    vIsSaved: isSaved,
    vSetIsSaved: setIsSaved,
    vChangeHandler: changeHandler,
    vopen: open,
    vhandleCloseStatus: handleCloseStatus,
    vnetworkMsg: networkMsg,
    yonRemoveRowHandler: onRemoveRowHandler,
    yonAddRowHandler: onAddRowHandler,
    yLoading: Loading,
    editYOYVariance,
    handleEditYOYVarianceChange,
    checkYOYVarianceValidation,
  };
}
