import {
  Box,
  Dialog,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { KeyButton, MainContainer, TB, TableWrapper, Td, Th } from "./styles";
import useBroadcast from "./useBroadcast";
import { useAuth } from "../sdk";
import Markdown from "markdown-to-jsx";
import ComposeDialog from "./ComposeDialog";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { KeyboardBackspace } from "@mui/icons-material";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export default function BroadCast() {
  const {
    messageList,
    count,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    openDialog,
    setOpenDialog,
    handleSaveMessage,
    setNewMessage,
    newMessage,
    loading,
  } = useBroadcast();
  const history = useHistory();

  return (
    <MainContainer>
      <Stack gap={2} className="contentContainer">
        <Stack direction="row" justifyContent={"space-between"}>
          <Stack alignItems={"center"} direction={"row"} gap={3}>
            <IconButton
              disableRipple
              onClick={() => {
                history.push("/managing_organization");
              }}
              sx={{
                padding: "0px",
                color: "#808080",
              }}
            >
              <KeyboardBackspace fontSize="large" />
            </IconButton>
            <Typography className="heading" fontSize={20} fontWeight={500}>
              Broadcast Messages
            </Typography>
          </Stack>

          <KeyButton onClick={() => setOpenDialog(true)}>+ Compose</KeyButton>
        </Stack>

        <TableWrapper>
          <TB
            stickyHeader
            aria-label="sticky table"
            style={{
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <TableHead
              sx={{
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <TableRow>
                <Th
                  style={{
                    borderRadius: "8px 0px 0px 0px",
                  }}
                >
                  Title
                </Th>
                <Th>Description</Th>
                <Th>Sent Date</Th>
                <Th
                  style={{
                    borderRadius: "0px 8px 0px 0px",
                  }}
                >
                  No. of Users
                </Th>
              </TableRow>
            </TableHead>
            <TableBody>
              {messageList?.length === 0 && (
                <TableRow
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height={"200px"}
                >
                  <TableCell
                    colSpan={12}
                    sx={{
                      textAlign: "center",
                      border: "0px",
                    }}
                  >
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      width="100%"
                    >
                      <Typography ml={1}>No Messages Found</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {messageList?.length > 0 &&
                messageList?.map((info) => (
                  <TableRow
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.push(`${history.location.pathname}/${info.id}`);
                    }}
                    key={info.id}
                  >
                    <Td
                      sx={{
                        maxWidth: "130px",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {info?.title}
                    </Td>
                    <Td
                      sx={{
                        textAlign: "left",
                        maxWidth: "150px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Markdown options={{ overrides: plainTextRenderers }}>
                        {info?.description}
                      </Markdown>
                    </Td>
                    <Td
                      sx={{
                        maxWidth: { sm: "100px", lg: "150px" },
                      }}
                    >
                      {formatDate(info?.createdAt)}
                    </Td>
                    <Td
                      sx={{
                        maxWidth: { sm: "100px", lg: "150px" },
                      }}
                    >
                      {info?.users?.length}
                    </Td>
                  </TableRow>
                ))}
            </TableBody>
          </TB>
        </TableWrapper>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            overflow: "visible",
          }}
        />
      </Stack>
      <ComposeDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        handleSaveMessage={handleSaveMessage}
        setNewMessage={setNewMessage}
        newMessage={newMessage}
        loading={loading}
      />
    </MainContainer>
  );
}

const plainTextRenderers = {
  h1: ({ children }) => <>{children}</>,
  h2: ({ children }) => <>{children}</>,
  h3: ({ children }) => <>{children}</>,
  h4: ({ children }) => <>{children}</>,
  h5: ({ children }) => <>{children}</>,
  h6: ({ children }) => <>{children}</>,
  p: ({ children }) => <>{children}</>,
  strong: ({ children }) => <>{children}</>,
  em: ({ children }) => <>{children}</>,
  del: ({ children }) => <>{children}</>,
  blockquote: ({ children }) => <>{children}</>,
  ul: ({ children }) => <>{children}</>,
  ol: ({ children }) => <>{children}</>,
  li: ({ children }) => <>{children}</>,
  code: ({ children }) => <>{children}</>,
  pre: ({ children }) => <>{children}</>,
  a: ({ children }) => <>{children}</>,
  img: () => null,
  table: ({ children }) => <>{children}</>,
  thead: ({ children }) => <>{children}</>,
  tbody: ({ children }) => <>{children}</>,
  tr: ({ children }) => <>{children}</>,
  th: ({ children }) => <>{children}</>,
  td: ({ children }) => <>{children}</>,
  hr: () => null,
  br: () => null,
};
