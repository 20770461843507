import { Tooltip, Typography } from "@mui/material";
import { CallReceived } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { styled } from "@mui/system";

function getModalStyle() {
  const top = 60;
  const left = 60;

  return {
    top: `80px`,
    left: `80px`,
    transform: `translate(-${top}, -${left})`,
  };
}

const Section = styled(Typography)`
  height: 85%;
  width: 92%;
  padding: 30px;
`;
const Cross = styled(CallReceived)`
  background-color: #e0e0e0;

  :hover {
    cursor: pointer;
  }
`;

const StyledSection = styled(Section)(({ theme }) => ({
  position: "absolute",
  backgroundColor: "#fff",
  boxShadow: 5,
}));

const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const colors = ["#0D2667", "#1068FA", "#FBBD33", "#B68D40", "#5B306F"];

export function ModelBody({
  data,
  setOpenSelectBar,
  dayToShowOnPopup,
  color,
  month,
}) {
  const [modalStyle] = useState(getModalStyle);
  const [graphData, setGraphData] = useState();

  const pickupOptions = React.useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 8,
          right: 8,
          top: 0,
          bottom: 8,
        },
      },
      title: {
        text: ` DoW Pick-Up Performance - ${month}`,
        display: true,
        fontFamily: "Roboto",
        fontSize: 20,
        fontColor: "black",
        fontStyle: "normal",
      },
      legend: {
        position: "bottom",
        align: "left",
        labels: {
          fontFamily: "Roboto",
          fontSize: 18,
          boxWidth: 14,
          filter: function (item, chart) {
            return !item.text.includes("no_label");
          },
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              fontFamily: "Roboto",
              fontColor: "black",
              fontSize: 14,

              suggestedMin: -10,
              suggestedMax: 20,
            },
            gridLines: {
              display: true,
              drawTicks: true,
              drawOnChartArea: true,
              color: "grey",
              zeroLineColor: "black",
            },
            scaleLabel: {
              display: true,
              labelString: "Pick-Up",
              fontColor: "black",
              fontFamily: "Roboto",
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
              drawTicks: true,
              drawOnChartArea: false,
              color: "grey",
              zeroLineColor: "grey",
            },
            ticks: {
              fontFamily: "Roboto",
              fontColor: "black",
              fontSize: 14,
            },
            maxBarThickness: 40,
            barThickness: 50,
          },
        ],
      },
    };
  }, []);

  function dataForGraph() {
    if (data) {
      const occData = {
        labels: data.map((currData) => {
          return new Date(currData.date).toLocaleDateString("en-GB");
        }),
        datasets: [
          {
            label: `${dayToShowOnPopup}`,
            backgroundColor: `${color}`,
            data: data.map((currData) => {
              return currData.pickup;
            }),
          },
        ],
      };
      setGraphData(occData);
    }
  }

  useEffect(() => {
    if (data.length) {
      dataForGraph();
    }
  }, [data]);

  return (
    <StyledSection style={modalStyle}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Tooltip
          disableFocusListener
          interactive
          disableTouchListener
          arrow
          title={"Close"}
        >
          <Cross onClick={() => setOpenSelectBar(false)}>&#x274C;</Cross>
        </Tooltip>
      </div>
      <Bar data={graphData} options={pickupOptions} />
    </StyledSection>
  );
}
