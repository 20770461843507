import { Cancel } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useCallback, useMemo, useState } from "react";
import DatePicker, { utils } from "react-modern-calendar-datepicker";
import { CalenderIcon } from "../../Pace";
import { StyledCalenderWrapper } from "../Styles";

export function AddEmail({
  open,
  handleClose,
  renderCustomInput,
  renderCustomInputToDate,
  fromDateNew,
  setFromDateNew,
  toDateNew,
  settoDateNew,
  handleSubmit,
}) {
  const [emails, setEmails] = useState("");
  const [error, setError] = useState("");
  const handleEmailChange = (event) => {
    setEmails(event.target.value);
  };

  const disableSubmit = useMemo(() => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set time to 00:00:00:000 for comparison
    const fromDate = new Date(
      `${fromDateNew.year}/${fromDateNew.month
        ?.toString()
        ?.padStart(2, "0")}/${fromDateNew.day?.toString()?.padStart(2, "0")}`
    );
    const toDate = new Date(
      `${toDateNew.year}/${toDateNew.month
        .toString()
        .padStart(2, "0")}/${toDateNew.day?.toString()?.padStart(2, "0")}`
    );
    if (
      fromDate.getTime() >= currentDate.getTime() &&
      toDate.getTime() >= currentDate.getTime()
    ) {
      return false;
    } else {
      return true;
    }
  }, [fromDateNew, toDateNew]);

  const handleFormClose = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const handleEmailSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const emailInput = event.target.email.value;
      const emails = emailInput.split(",").map((email) => email.trim());
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      const validEmails = emails.filter((email) => emailRegex.test(email));

      if (validEmails.length === emails.length) {
        handleSubmit({
          email: validEmails,
          refreshDate: "",
        });
        handleFormClose();
        setEmails("");
        setError("");
      } else {
        setError("Invalid email(s) entered");
      }
    },
    [handleFormClose, handleSubmit]
  );

  const formatDateObject = (dateObject) => {
    return {
      year: dateObject.getFullYear(),
      month: dateObject.getMonth() + 1,
      day: dateObject.getDate(),
    };
  };

  const calculateMaxDate = () => {
    const { year, month, day } = fromDateNew;
    const fromDate = new Date(year, month - 1, day);
    const maxDate = new Date(fromDate);
    maxDate.setDate(maxDate.getDate() + 90);
    return formatDateObject(maxDate);
  };

  return (
    <Dialog
      open={open}
      onClose={handleFormClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          overflow: "visible",
        },
      }}
    >
      <Box
        sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }}
        onClick={handleFormClose}
      >
        <Cancel />
      </Box>
      <DialogTitle id="alert-dialog-title" textAlign="center">
        Add Email
      </DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        <Stack component="form" onSubmit={handleEmailSubmit} rowGap={2} mt={1}>
          <Typography
            textAlign="center"
            color="#747474"
            paddingLeft={3}
            paddingRight={3}
          >
            Please enter your email address. Once the requested data is
            generated, we will send you an Excel sheet containing the
            information.
          </Typography>
          <StyledStack>
            <Stack className="dateStack">
              <Box className="dateBox">
                <label>
                  <StyledCalenderWrapper>
                    <DatePicker
                      style={{ zIndex: 98 }}
                      value={fromDateNew}
                      onChange={setFromDateNew}
                      renderInput={renderCustomInput}
                      calendarClassName="myCustomCalendar"
                      minimumDate={utils().getToday()}
                    />
                    <CalenderIcon />
                  </StyledCalenderWrapper>
                </label>
              </Box>
            </Stack>

            <Typography className="to-text">To</Typography>
            <Stack className="dateStack">
              <Box className="dateBox">
                <label>
                  <StyledCalenderWrapper>
                    <DatePicker
                      value={toDateNew}
                      onChange={settoDateNew}
                      renderInput={renderCustomInputToDate}
                      calendarClassName="myCustomCalendar"
                      minimumDate={fromDateNew}
                      maximumDate={calculateMaxDate()}
                    />
                    <CalenderIcon />
                  </StyledCalenderWrapper>
                </label>
              </Box>
            </Stack>
          </StyledStack>

          <TextField
            id="email"
            name="email"
            type="text"
            label="Email"
            variant="outlined"
            fullWidth
            value={emails}
            onChange={handleEmailChange}
            error={!!error}
            helperText={error}
          />

          <DialogActions sx={{ justifyContent: "center" }}>
            <Button type="submit" variant="contained" disabled={disableSubmit}>
              Submit
            </Button>
          </DialogActions>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

const StyledStack = styled(Stack)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  ".dateBox": {
    padding: "5px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "0px 4px 7px 0px  rgba(48, 111, 188, 0.2)",
    zIndex: 101,
  },
  ".dateStack": {
    display: "flex",
    flexDirection: "row",
    width: "50%",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    color: "#281e53",
  },
  ".to-text": {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "16px",
    letterSpacing: "0px",
    color: "#281e53",
    paddingRight: "15px",
  },
}));
