import React, { memo, useEffect, useState } from "react";
import { PricingForm } from "./components";
import AddCompetitorData from "./components/AddCompetitorData";
import { Algorithm } from "./components/Algorithm";
import PrimaryDetailForm from "./components/PrimaryDetailForm";
import { PrimarySetup } from "./components/PrimarySetup";

import { Route, useParams } from "react-router-dom";
import { styled } from "@mui/system";
import { useAuth } from "../sdk";
import BudgetAllocation from "./components/BudgetAllocation";
import BudgetSheet from "./components/BudgetSheet";
import MarketSegment from "./components/MarketSegment";
import { Box } from "@mui/material";
import Scheduling from "./components/Scheduling";
import ChannelManager from "./components/ChannelManager";
import RoomAdjustmentTable from "./components/RoomAdjustmentTable";
import RoomConfig from "./components/RoomConfig";

const Wrapper = styled(Box)``;
const SetupPage = styled(Box)`
  padding: 80px 0px 0px 80px;
  width: 99%;
  position: fixed;
`;

const EMPTY_DETAIL = {
  name: "",
  postheld: "",
  email: "",
  phoneNumber: "",
  isSendEmail: false,
  invalidName: false,
  invalidPost: false,
  invalidEmail: false,
  invalidPhone: false,
};

const Setup = memo(
  ({ handleNavbarRefresh, setPageHeader, versionFlag, setVersionFlag }) => {
    const { token, authFetch } = useAuth();
    const [actualFileType, setActualFileType] = useState([]);
    const [competitorFileType, setCompetitorFileType] = useState([]);
    const { hotelId } = useParams();
    const [contactDetails, setContactDetails] = useState([EMPTY_DETAIL]);
    const [sendContact, setSendContact] = useState(false);
    const [checkHeight, setCheckHeight] = useState(false);
    const [rateShopAppEnabled, setRateShopAppEnabled] = useState(false);

    const onRemoveContactDetail = (idx) => {
      setSendContact(true);
      setContactDetails((prevState) =>
        prevState.filter((item, index) => index !== idx)
      );
    };

    const onCheckContactDetail = (idx) => {
      setSendContact(true);
      setContactDetails((prevState) =>
        prevState.map((item, index) => {
          if (index === idx) {
            return { ...item, isSendEmail: !item.isSendEmail };
          } else {
            return item;
          }
        })
      );
    };

    useEffect(() => {
      if (token) {
        GetFileType();
        GetCompetitorFileType();
        if (!!hotelId) {
          GetContacts();
        }
      }
    }, [token, hotelId]);

    async function GetFileType() {
      const { get } = await authFetch({
        path: `/file-type`,
      });
      const { data, error } = await get();
      if (data) {
        setActualFileType(data);
      } else {
        console.log(error);
      }
    }

    async function GetCompetitorFileType() {
      const { get } = await authFetch({
        path: `/competitor-file-type`,
      });
      const { data, error } = await get();
      if (data) {
        setCompetitorFileType(data);
      } else {
        console.log(error);
      }
    }

    async function GetContacts() {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/contact`,
      });
      const { data, error } = await get();
      if (data) {
        setContactDetails(data);
      } else {
        setContactDetails([EMPTY_DETAIL]);
        console.log(error);
      }
    }

    useEffect(() => {
      setPageHeader("Setup");
      return () => {
        setPageHeader("");
      };
    }, []);

    return (
      <SetupPage>
        <PrimarySetup rateShopAppEnabled={rateShopAppEnabled} />
        <Wrapper>
          <Route exact path="/hotel">
            <PrimaryDetailForm
              actualFileType={actualFileType}
              competitorFileType={competitorFileType}
              handleNavbarRefresh={handleNavbarRefresh}
              contactDetails={contactDetails}
              onRemoveContactDetail={onRemoveContactDetail}
              onCheckContactDetail={onCheckContactDetail}
              setContactDetails={setContactDetails}
              sendContact={sendContact}
              setSendContact={setSendContact}
              checkHeight={true}
              rateShopAppEnabled={rateShopAppEnabled}
              setRateShopAppEnabled={setRateShopAppEnabled}
            />
          </Route>
          <Route exact path="/hotel/:hotelId/setup">
            <PrimaryDetailForm
              actualFileType={actualFileType}
              competitorFileType={competitorFileType}
              handleNavbarRefresh={handleNavbarRefresh}
              contactDetails={contactDetails}
              onRemoveContactDetail={onRemoveContactDetail}
              onCheckContactDetail={onCheckContactDetail}
              setContactDetails={setContactDetails}
              sendContact={sendContact}
              setSendContact={setSendContact}
              checkHeight={false}
              rateShopAppEnabled={rateShopAppEnabled}
              setRateShopAppEnabled={setRateShopAppEnabled}
            />
          </Route>
          <Route exact path="/hotel/:hotelId/setup/hotel-pricing">
            <PricingForm />
          </Route>
          <Route exact path="/hotel/:hotelId/setup/market-segment">
            <MarketSegment />
          </Route>
          <Route exact path="/hotel/:hotelId/setup/room-adjustment">
            <RoomAdjustmentTable />
          </Route>
          <Route exact path="/hotel/:hotelId/setup/budget-setup">
            <BudgetSheet />
          </Route>
          <Route exact path="/hotel/:hotelId/setup/budget-allocation">
            <BudgetAllocation />
          </Route>
          <Route exact path="/hotel/:hotelId/setup/competitors">
            <AddCompetitorData
              rateShopAppEnabled={rateShopAppEnabled}
              setRateShopAppEnabled={setRateShopAppEnabled}
            />
          </Route>
          <Route path="/hotel/:hotelId/setup/scheduling">
            <Scheduling />
          </Route>
          <Route path="/hotel/:hotelId/setup/channel-manager">
            <ChannelManager />
          </Route>
          <Route path="/hotel/:hotelId/setup/room-mapping">
            <RoomConfig />
          </Route>
          <Route path="/hotel/:hotelId/setup/algorithm">
            <Algorithm />
          </Route>
        </Wrapper>
      </SetupPage>
    );
  }
);

export default Setup;
