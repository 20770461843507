import { ArrowRight } from "@mui/icons-material";
import React, { memo } from "react";
import { useParams } from "react-router";
import { CalcHeader, DayText } from "../Styles";
const DatePopOver = memo(({ date, day, eventArray }) => {
  return (
    <>
      {eventArray.map((data, idx) => (
        <div key={date}>
          <CalcHeader
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginRight: "0px",
            }}
          >
            <ArrowRight
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                color: "#fffff",

                marginLeft: "-8px",
              }}
            />
            <DayText
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                color: "#ffffff",
                fontSize: "14px",
              }}
            >
              {data}
            </DayText>
          </CalcHeader>
        </div>
      ))}
    </>
  );
});
export default DatePopOver;
