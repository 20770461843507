import { useEffect, useState } from "react";
import { useAuth, usePrevious } from "../../sdk";
const EMPTY_ROW = {
  min: "",
  max: "",
  symbol: null,
  value: null,
};
export function useDemandLevel(hotelId, copyFromHotelId, DL) {
  const [demandLevels, setDemandLevels] = useState([]);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [editDemandLevels, setEditDemandLevels] = useState([]);
  const [checkDemandLevelValidation, setDemandLevelValidation] = useState(
    false
  );
  const [isCancelled, setIsCancelled] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const saveButtonClicked = usePrevious(isInEditMode);
  const [Loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const handleCloseStatus = () => {
    setOpen(false);
  };

  const [networkMsg, setnetworkMsg] = useState(null);
  const { token, authFetch } = useAuth();
  useEffect(() => {
    if (!token) return;
    refreshDemandLevels();
  }, [token, hotelId]);

  function isValid(adl) {
    return (
      adl.min !== null &&
      adl.max !== null &&
      adl.value !== null &&
      adl.symbol !== null
    );
  }
  useEffect(() => {
    if (isInEditMode) {
      const allValidEntries = demandLevels.every(isValid);

      if (allValidEntries) {
        setDemandLevels([...demandLevels, EMPTY_ROW]);
        setEditDemandLevels([...editDemandLevels, false]);
      }
    } else if (isSaved) {
      updateDemandLevels();
    } else if (isCancelled) {
      refreshDemandLevels();
    }
  }, [demandLevels, isInEditMode]);

  useEffect(() => {
    if (copyFromHotelId === null && !DL) return;
    if (DL) refreshDemandLevels(copyFromHotelId);
  }, [DL]);

  async function refreshDemandLevels(anotherHotelId = false) {
    const hotelIdToUse = anotherHotelId ? anotherHotelId : hotelId;
    setLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelIdToUse}/actual-demand-lvl-multiplier`,
    });
    const { data, error } = await get();
    if (error) {
      setnetworkMsg("Can't Fetch Demand Level");
      setOpen(true);
      console.log(error);
    }
    setIsCancelled(false);
    if (data) {
      setDemandLevels(data);
      setEditDemandLevels([...new Array(data?.length)].map(() => false));
    } else {
      setDemandLevels([EMPTY_ROW]);
      setEditDemandLevels([false]);
      setIsInEditMode(false);
    }
    if (anotherHotelId) {
      setIsInEditMode(true);
    }
    setLoading(false);
  }
  async function updateDemandLevels() {
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/actual-demand-lvl-multiplier/all`,
    });
    const { error, response, data } = await post(demandLevels.filter(isValid));
    if (!response?.ok) {
      setnetworkMsg(data?.messageToUser || "Can't Update Demand Level");
      setOpen(true);
      console.log(error);
    }
    setIsSaved(false);
    await refreshDemandLevels();
  }

  function changeHandler(index, key, value) {
    setDemandLevels((prevState) => {
      return prevState.map((row, idx) =>
        idx === index
          ? {
              ...row,
              [key]: value,
            }
          : row
      );
    });
  }
  const handleEditDemandsLevel = (index) => {
    const result = editDemandLevels.map((data, pos) => {
      if (pos === index) {
        return (editDemandLevels[index] = !editDemandLevels[index]);
      } else {
        return editDemandLevels[pos];
      }
    });
    const checkEditMode = result.every((d) => d === false);
    if (checkEditMode) setIsInEditMode(false);
    else setIsInEditMode(true);
    setEditDemandLevels(result);
  };

  return {
    demandLevels,
    dlIsInEditMode: isInEditMode,
    setDlIsInEditMode: setIsInEditMode,
    dlIsCancelled: isCancelled,
    dlSetIsCancelled: setIsCancelled,
    dlIsSaved: isSaved,
    dlSetIsSaved: setIsSaved,
    dlChangeHandler: changeHandler,
    dlopen: open,
    dlhandleCloseStatus: handleCloseStatus,
    dlnetworkMsg: networkMsg,
    dlLoading: Loading,
    editDemandLevels,
    handleEditDemandsLevel: handleEditDemandsLevel,
    checkDemandLevelValidation,
  };
}
