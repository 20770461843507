import { styled } from "@mui/system";
import { MenuItem, Select, LinearProgress, Box, Tooltip } from "@mui/material";
import React from "react";
import { formatNumber } from "../sdk";
import {
  ARR,
  Body,
  Bookings,
  Card,
  CardName,
  Details,
  Header,
  Info,
  Revenue,
  RevPAR,
  Title,
} from "./styles";

const TextOnlyTooltipSegment = styled(Tooltip)(({ theme }) => ({
  ".tooltip": {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "12px",
    marginTop: "0px",
    marginLeft: "-60px",
    background: "#1E90FF",
    color: "#fffff",
  },
  ".arrow": {
    color: "#1E90FF",
  },
}));

const YEAR = styled(Box)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;

  text-transform: capitalize;

  color: #000000;
`;
const CardNameNew = styled(CardName)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;

  text-transform: capitalize;

  color: #303030;
`;
const TitleNew = styled(Title)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
`;
const YEARBAR = styled(Box)`
  height: 14px;
  width: 4px;
  margin: auto;
  background: rgba(48, 111, 188, 0.3);
  transform: rotate(-90deg);
`;
const CURRENTYEARBAR = styled(Box)`
  height: 14px;
  width: 4px;
  margin: auto;
  background: #306fbc;
  transform: rotate(-90deg);
`;
const Container = styled(Box)`
  display: "flex";
  flex-direction: "column";
`;
const IncreaseData = styled(Box)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;

  text-transform: capitalize;

  color: #42ae40;
  padding-left: 8px;
`;

const DecreaseData = styled(Box)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;

  text-transform: capitalize;

  color: #e13a3a;
  padding-left: 8px;
`;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 7,

  "& .MuiLinearProgress-colorPrimary": {
    backgroundColor: "red",
  },
  "& .MuiLinearProgress-barColorPrimary": {
    background: "#306FBC",

    borderRadius: "0px 3px 3px 0px",
    "&:hover": {
      background: "#0d54ab",

      transition: "opacity .5s ease-in",
    },
  },

  width: "180px",
  backgroundColor: "white",
}));
const BorderLinearProgressNew = styled(LinearProgress)(({ theme }) => ({
  height: 7,

  "& .MuiLinearProgress-bar": {
    background: "rgba(48, 111, 188, 0.3)",
    borderRadius: "0px 3px 3px 0px",
    "&:hover": {
      background: "rgba(48, 111, 188, 0.5)",

      transition: "opacity .5s ease-in",
    },
  },
  width: "180px",
  backgroundColor: "white",
}));
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export default function YearlyCard({
  data,
  selectedYear,
  setSelectedYear,
  yearOptions,
}) {
  const normaliseRS = () => {
    let diff =
      parseInt(data?.currentYear?.booking) -
      parseInt(data?.previousYear?.booking);
    if (diff > 0) return 100;
    else {
      let a =
        parseInt(data?.previousYear?.booking) -
        parseInt(data?.currentYear?.booking);
      let result = (a / data?.previousYear?.booking) * 100;
      return (100 - result).toFixed(2);
    }
  };
  const normaliseRS1 = () => {
    let diff =
      parseInt(data?.previousYear?.booking) -
      parseInt(data?.currentYear?.booking);
    if (diff > 0) return 100;
    else {
      let a =
        parseInt(data?.currentYear?.booking) -
        parseInt(data?.previousYear?.booking);
      let result = (a / data?.currentYear?.booking) * 100;
      return (100 - result).toFixed(2);
    }
  };

  const normaliseARR = () => {
    let diff =
      parseInt(data?.currentYear?.arr) - parseInt(data?.previousYear?.arr);
    if (diff > 0) return 100;
    else {
      let a =
        parseInt(data?.previousYear?.arr) - parseInt(data?.currentYear?.arr);
      let result = (a / data?.previousYear?.arr) * 100;
      return (100 - result).toFixed(2);
    }
  };

  const normaliseARR1 = () => {
    let diff =
      parseInt(data?.previousYear?.arr) - parseInt(data?.currentYear?.arr);
    if (diff > 0) return 100;
    else {
      let a =
        parseInt(data?.currentYear?.arr) - parseInt(data?.previousYear?.arr);
      let result = (a / data?.currentYear?.arr) * 100;
      return (100 - result).toFixed(2);
    }
  };
  const normaliseRevenue = () => {
    let diff =
      parseInt(data?.currentYear?.revenue) -
      parseInt(data?.previousYear?.revenue);
    if (diff > 0) return 100;
    else {
      let a =
        parseInt(data?.previousYear?.revenue) -
        parseInt(data?.currentYear?.revenue);
      let result = (a / data?.previousYear?.revenue) * 100;
      return (100 - result).toFixed(2);
    }
  };

  const normaliseRevenue1 = () => {
    let diff =
      parseInt(data?.previousYear?.revenue) -
      parseInt(data?.currentYear?.revenue);
    if (diff > 0) return 100;
    else {
      let a =
        parseInt(data?.currentYear?.revenue) -
        parseInt(data?.previousYear?.revenue);
      let result = (a / data?.currentYear?.revenue) * 100;
      return (100 - result).toFixed(2);
    }
  };

  const normaliseRevPAR1 = () => {
    let diff =
      parseInt(data?.previousYear?.revPAR) -
      parseInt(data?.currentYear?.revPAR);
    if (diff > 0) return 100;
    else {
      let a =
        parseInt(data?.currentYear?.revPAR) -
        parseInt(data?.previousYear?.revPAR);
      let result = (a / data?.currentYear?.revPAR) * 100;
      return (100 - result).toFixed(2);
    }
  };
  const normaliseRevPAR = () => {
    let diff =
      parseInt(data?.currentYear?.revPAR) -
      parseInt(data?.previousYear?.revPAR);
    if (diff > 0) return 100;
    else {
      let a =
        parseInt(data?.previousYear?.revPAR) -
        parseInt(data?.currentYear?.revPAR);
      let result = (a / data?.previousYear?.revPAR) * 100;
      return (100 - result).toFixed(2);
    }
  };

  return (
    <Card
      sx={{
        boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
        height: "100% !important",
      }}
    >
      <Header>
        <CardNameNew
          style={{
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          Year to Date Vs{" "}
          {parseInt(selectedYear) === new Date().getFullYear() - 1
            ? "Last Year"
            : selectedYear}
        </CardNameNew>
      </Header>
      <Body
        sx={{
          height: "100%",
        }}
      >
        <Info>
          <Details style={{ paddingTop: "15px" }}>
            <Bookings>
              <TitleNew>Rooms Sold</TitleNew>
              <Container>
                <Box>
                  <TextOnlyTooltipSegment
                    title={formatNumber(parseInt(data?.currentYear?.booking))}
                    placement="right"
                    arrow
                    style={{ cursor: "default" }}
                  >
                    <BorderLinearProgress
                      variant="determinate"
                      value={normaliseRS()}
                    />
                  </TextOnlyTooltipSegment>
                </Box>
                <Box>
                  <TextOnlyTooltipSegment
                    title={formatNumber(parseInt(data?.previousYear?.booking))}
                    placement="right"
                    arrow
                    style={{ cursor: "default" }}
                  >
                    <BorderLinearProgressNew
                      variant="determinate"
                      value={normaliseRS1()}
                    />
                  </TextOnlyTooltipSegment>
                </Box>
              </Container>
              <Box style={{ display: "flex", paddingLeft: "12px" }}>
                {parseInt(data?.currentYear?.booking) -
                  parseInt(data?.previousYear?.booking) >=
                0 ? (
                  <>
                    <img src="/assets/inc-icon.svg" alt="Increase Icon" />
                    <IncreaseData>
                      {formatNumber(
                        parseInt(data?.currentYear?.booking) -
                          parseInt(data?.previousYear?.booking)
                      )}
                    </IncreaseData>
                  </>
                ) : (
                  <>
                    <img src="/assets/dec-icon.svg" alt="Decrease Icon" />
                    <DecreaseData>
                      {formatNumber(
                        parseInt(data?.previousYear?.booking) -
                          parseInt(data?.currentYear?.booking)
                      )}
                    </DecreaseData>
                  </>
                )}
                {}
              </Box>
            </Bookings>

            <ARR>
              <TitleNew>ADR</TitleNew>
              <Container>
                <Box>
                  <TextOnlyTooltipSegment
                    title={formatNumber(
                      parseFloat(data?.currentYear?.arr).toFixed(2)
                    )}
                    placement="right"
                    arrow
                    style={{ cursor: "default" }}
                  >
                    <BorderLinearProgress
                      variant="determinate"
                      value={normaliseARR()}
                    />
                  </TextOnlyTooltipSegment>
                </Box>
                <Box>
                  <TextOnlyTooltipSegment
                    title={formatNumber(
                      parseFloat(data?.previousYear?.arr).toFixed(2)
                    )}
                    placement="right"
                    arrow
                    style={{ cursor: "default" }}
                  >
                    <BorderLinearProgressNew
                      variant="determinate"
                      value={normaliseARR1()}
                    />
                  </TextOnlyTooltipSegment>
                </Box>
              </Container>
              <Box style={{ display: "flex", paddingLeft: "12px" }}>
                {parseFloat(data?.currentYear?.arr).toFixed(2) -
                  parseFloat(data?.previousYear?.arr).toFixed(2) >=
                0 ? (
                  <>
                    <img src="/assets/inc-icon.svg" alt="Increase Icon" />
                    <IncreaseData>
                      {formatNumber(
                        (
                          parseFloat(data?.currentYear?.arr).toFixed(2) -
                          parseFloat(data?.previousYear?.arr).toFixed(2)
                        ).toFixed(2)
                      )}
                    </IncreaseData>
                  </>
                ) : (
                  <>
                    <img src="/assets/dec-icon.svg" alt="Decrease Icon" />
                    <DecreaseData>
                      {formatNumber(
                        (
                          parseInt(data?.previousYear?.arr).toFixed(2) -
                          parseInt(data?.currentYear?.arr).toFixed(2)
                        ).toFixed(2)
                      )}
                    </DecreaseData>
                  </>
                )}
                {}
              </Box>
            </ARR>
            <Revenue>
              <TitleNew>Revenue</TitleNew>
              <Container>
                <Box>
                  <TextOnlyTooltipSegment
                    title={formatNumber(parseInt(data?.currentYear?.revenue))}
                    placement="right"
                    arrow
                    style={{ cursor: "default" }}
                  >
                    <BorderLinearProgress
                      variant="determinate"
                      value={normaliseRevenue()}
                    />
                  </TextOnlyTooltipSegment>
                </Box>
                <Box>
                  <TextOnlyTooltipSegment
                    title={formatNumber(parseInt(data?.previousYear?.revenue))}
                    placement="right"
                    arrow
                    style={{ cursor: "default" }}
                  >
                    <BorderLinearProgressNew
                      variant="determinate"
                      value={normaliseRevenue1()}
                    />
                  </TextOnlyTooltipSegment>
                </Box>
              </Container>
              <Box style={{ display: "flex", paddingLeft: "12px" }}>
                {parseInt(data?.currentYear?.revenue) -
                  parseInt(data?.previousYear?.revenue) >=
                0 ? (
                  <>
                    <img src="/assets/inc-icon.svg" alt="Increase Icon" />
                    <IncreaseData>
                      {formatNumber(
                        parseInt(data?.currentYear?.revenue) -
                          parseInt(data?.previousYear?.revenue)
                      )}
                    </IncreaseData>
                  </>
                ) : (
                  <>
                    <img src="/assets/dec-icon.svg" alt="Decrease Icon" />
                    <DecreaseData>
                      {formatNumber(
                        parseInt(data?.previousYear?.revenue) -
                          parseInt(data?.currentYear?.revenue)
                      )}
                    </DecreaseData>
                  </>
                )}
                {}
              </Box>
            </Revenue>
            <RevPAR>
              <TitleNew>RevPAR</TitleNew>
              <Container>
                <Box>
                  <TextOnlyTooltipSegment
                    title={formatNumber(
                      parseFloat(data?.currentYear?.revPAR).toFixed(2)
                    )}
                    placement="right"
                    arrow
                    style={{ cursor: "default" }}
                  >
                    <BorderLinearProgress
                      variant="determinate"
                      value={normaliseRevPAR()}
                    />
                  </TextOnlyTooltipSegment>
                </Box>
                <Box>
                  <TextOnlyTooltipSegment
                    title={formatNumber(
                      parseFloat(data?.previousYear?.revPAR).toFixed(2)
                    )}
                    placement="right"
                    arrow
                    style={{ cursor: "default" }}
                  >
                    <BorderLinearProgressNew
                      variant="determinate"
                      value={normaliseRevPAR1()}
                    />
                  </TextOnlyTooltipSegment>
                </Box>
              </Container>
              <Box style={{ display: "flex", paddingLeft: "12px" }}>
                {parseFloat(data?.currentYear?.revPAR).toFixed(2) -
                  parseFloat(data?.previousYear?.revPAR).toFixed(2) >=
                0 ? (
                  <>
                    <img src="/assets/inc-icon.svg" alt="Increase Icon" />
                    <IncreaseData>
                      {formatNumber(
                        (
                          parseFloat(data?.currentYear?.revPAR).toFixed(2) -
                          parseFloat(data?.previousYear?.revPAR).toFixed(2)
                        ).toFixed(2)
                      )}
                    </IncreaseData>
                  </>
                ) : (
                  <>
                    <img src="/assets/dec-icon.svg" alt="Decrease Icon" />
                    <DecreaseData>
                      {formatNumber(
                        (
                          parseFloat(data?.previousYear?.revPAR).toFixed(2) -
                          parseFloat(data?.currentYear?.revPAR).toFixed(2)
                        ).toFixed(2)
                      )}
                    </DecreaseData>
                  </>
                )}
                {}
              </Box>
            </RevPAR>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                width: "50%",
                margin: "auto",
                paddingTop: "12px",
              }}
            >
              <YEARBAR />
              <Select
                value={selectedYear}
                variant="standard"
                disableUnderline
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 70,
                      scrollbarWidth: "none",
                    },
                  },
                }}
                onChange={(e) => setSelectedYear(e.target.value)}
                sx={{
                  background: "transparent",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  border: "none",
                  height: "14px",
                }}
              >
                {yearOptions?.map((year) => (
                  <MenuItem
                    sx={{
                      background: "transparent",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      border: "none",
                      height: "25px",
                    }}
                    value={year}
                  >
                    {year}
                  </MenuItem>
                ))}
              </Select>
              <CURRENTYEARBAR />
              <YEAR>{new Date().getFullYear()}</YEAR>
            </Box>
          </Details>
        </Info>
      </Body>
    </Card>
  );
}
