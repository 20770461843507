import {
  Input as Inpt,
  Select,
  Table,
  TableRow,
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  Tooltip,
  Box,
} from "@mui/material";
import { ArrowDropDownCircle } from "@mui/icons-material";
import { Paper, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { styled } from "@mui/system";
import {
  DatePicker,
  getDateDDMM,
  getDay,
  LoadingPage,
  MonthPicker,
} from "../../sdk";
import useForecastSheet from "../hooks/useForecastSheetMonthly";
export const TRow = styled(TableRow)`
  &.MuiTableRow-root {
    padding: 10px 0;
    background-color: white;
    &:nth-of-type(odd) {
      background: #f1f2fe;
    }
  }
`;
export const TCell = styled(TableCell)`
  &.MuiTableCell-root {
    font: normal normal normal 22px/28px Roboto;
    letter-spacing: 0px;
    color: #000;
    background: #fff;
    padding: 0px 0px;
    border: none;
    width: 80px;
    text-align: ${(props) => (props.isInEditMode ? "left" : "left")};
    text-transform: capitalize;
    white-space: nowrap;
  }
`;
export const Tabhead = styled(TableCell)`
  &.MuiTableCell-root {
    text-align: left;
    font: normal normal bold 14px/23px Roboto;
    letter-spacing: 0px;
    color: #fff;
    background: rgba(48, 111, 188, 1);
    padding: 0px 0px;
    border: none;
    text-transform: capitalize;
    border: 1px solid rgba(48, 111, 188, 1);
    text-align: center;
  }
`;
export const THead = styled(TableHead)`
  &.MuiTableHead-root {
    tr.MuiTableRow-root {
      background: transparent;
      width: 100%;
    }
  }
`;
const DateContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const Container = styled(Box)`
  padding: 80px 20px 0px 80px;
  width: 100%;
`;
const Header = styled(Box)`
  font: normal normal bold 14px/18px Roboto;
  font-weight: 400;
  padding: 4px 2px;
  height: 24px;
  border-bottom: 0.1px solid #1106411a;
  display: flex;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg.MuiSelect-icon {
    color: white;
  }
`;
const HeaderBottom = styled(Box)`
  font: normal normal bold 14px/18px Roboto;
  padding: 4px 2px;
  height: 24px;
  display: flex;
  width: 120px;
  display: flex;

  justify-content: center;
  svg.MuiSelect-icon {
    color: white;
  }
`;
const Cell = styled(Box)`
  font: normal normal normal 14px/20px Roboto;
  padding: 4px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  border-bottom: 1px solid #1106411a;
  min-width: 54px;
  text-align: center;
`;
const CellBottom = styled(Box)`
  font: normal normal normal 14px/24px Roboto;
  padding: 4px 2px;
  height: 24px;
  min-width: 90px;
  text-align: center;
`;
const MonthPickerContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal bold 22px/28px Roboto;
`;
export const AppBar = styled(Box)`
  top: 76px;
  left: 80px;
  height: 52px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  margin-bottom: 20px;
  padding: 0 80px;
  display: flex;
  align-items: center;
`;

export const PageTitle = styled(Box)`
  font: normal normal bold 18px/24px Roboto;
  letter-spacing: 0px;
  color: #110641;
  margin-right: 20px;
`;

export const TableWrapper = styled(Box)`
  height: calc(100vh - 180px);
  margin-bottom: 20px;
  overflow: auto;
  background-color: #ffffff;
`;

export const HorizontalLine = styled(Box)`
  background-color: #110641;
  width: 100%;
`;

export const PickupContainer = styled(Box)`
  display: flex;
  align-items: center;
  outline: 1px solid #1106411a;
`;
export const PickupName = styled(Box)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  white-space: wrap;
  font: normal normal bold 16px/20px Roboto;
`;
export const HeaderContainer = styled(Box)`
  flex: 1;
  outline: 1px solid #1106411a;
`;
const Input = styled(Inpt)`
  input.MuiInputBase-input {
    padding: 0;
    font: 500 16px/20px Roboto;
    letter-spacing: 0px;
    color: #333333;
    font: normal normal normal 16px/20px Roboto;
    border: none;
  }
  &&&.MuiInput-underline::before {
    border-bottom: none !important;
  }
`;
export const Options = styled(Select)`
  .MuiSelect-select.MuiSelect-select {
    font: normal normal bold 14px/18px Roboto;
    min-width: 180px;
    color: white;
  }
`;

const StyledContainer = styled(Container)(({ theme }) => ({
  ".sticky": {
    position: "sticky",
    left: 0,
    background: "#f1f2fe",
  },
  ".stickyRight": {
    position: "sticky",
    left: "53.5px",
    background: "#f1f2fe",
  },
  ".stickyRightNoBgColor": {
    position: "sticky",
    left: "62.5px",
    background: "inherit",
  },
  ".stickyDate": {
    position: "sticky",
    left: "204.5px",
    background: "#f1f2fe",
  },
  ".bottomTableHeader": {
    position: "sticky",
    left: "204.5px",
    background: "#f1f2fe",
    top: "70px",
  },
  ".maxLeftSticky": {
    position: "sticky",
    left: "404.5px",
  },
}));

const TextOnlyTooltipSegment = styled(Tooltip)(({ theme }) => ({
  ".tooltip": {
    color: "#fff",
    font: "normal normal normal 16px/20px Roboto",

    boxShadow: "0px 4px 8px #00000035",
    background: "#110641 0% 0% no-repeat padding-box",
    textAlign: "left",
    marginLeft: "-10px",
  },
  ".arrow": {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "#110641",
  },
}));

export const ForecastSheetMonthly = ({ setPageHeader }) => {
  const {
    forecastSheetData,
    comparitiveDate,
    setComparitiveDate,
    showPickupRequireToAchieve,
    handleShowPickupRequiredRow,
    Loading,
    selectedDate,
    handleDateChange,
    forecastSheetDailyData,
    LoadingDailyForecast,
  } = useForecastSheet();
  useEffect(() => {
    setPageHeader("Forecast Sheet Monthly");
    return () => {
      setPageHeader("");
    };
  }, []);

  return (
    <StyledContainer>
      <Paper
        elevation={1}
        style={{
          width: "200px",
          height: "45px",
          marginBottom: "16px",
          marginLeft: "40px",
        }}
      >
        <Stack
          direction="row"
          style={{
            padding: "10px",
          }}
        >
          <MonthPickerContainer>
            <MonthPicker
              value={comparitiveDate}
              onChange={setComparitiveDate}
            />
          </MonthPickerContainer>
        </Stack>
      </Paper>

      {!Loading ? (
        <>
          <TableWrapper>
            <Table className="table" stickyHeader>
              <THead>
                <TRow>
                  <Tabhead
                    style={{ padding: "4px 8px", zIndex: "10" }}
                    rowSpan={2}
                    className="sticky"
                  >
                    Items
                  </Tabhead>
                  <Tabhead
                    style={{ padding: "4px 8px", zIndex: "10" }}
                    rowSpan={2}
                    className="stickyRight"
                  >
                    Events
                  </Tabhead>

                  {forecastSheetData &&
                    forecastSheetData?.roomNight.map((item, index) => {
                      return forecastSheetData?.roomNight.length !==
                        index + 1 ? (
                        <Tabhead>
                          <DateContainer>
                            <TCell
                              style={{
                                padding: "4px 2px",
                                border: "none",
                                borderBottom: "1px solid #1106411A",
                                font: "normal normal 14px/23px Roboto",
                                textAlign: "center",
                                background: "rgba(48, 111, 188, 1)",
                                color: "#fff",
                                height: "56px",
                              }}
                            >
                              {getDateDDMM(item.date)}
                              <br />
                              {getDay(item.date)}
                            </TCell>
                          </DateContainer>
                        </Tabhead>
                      ) : (
                        <Tabhead style={{ paddingLeft: "10px" }}>Total</Tabhead>
                      );
                    })}
                </TRow>
              </THead>
              <TableBody>
                <>
                  <TRow>
                    <TCell
                      style={{
                        padding: "0 10px",
                        font: "normal normal bold 14px/23px Roboto",
                      }}
                      className="sticky"
                    >
                      <p
                        style={{
                          writingMode: "tb-rl",
                          transform: "rotate(-180deg)",
                          margin: "auto",
                        }}
                      >
                        Rooms Nights
                      </p>
                    </TCell>
                    <TCell className="stickyRight">
                      <Header
                        style={{
                          height: "36px",
                          borderRight: "1.5px solid #1106411a",
                          borderLeft: "1.5px solid #1106411a",
                        }}
                      >
                        LY
                      </Header>
                      <Header
                        style={{
                          height: "36px",
                          borderRight: "1.5px solid #1106411a",
                          borderLeft: "1.5px solid #1106411a",
                        }}
                      >
                        Budget
                      </Header>
                      <Header
                        style={{
                          height: "36px",
                          borderRight: "1.5px solid #1106411a",
                          borderLeft: "1.5px solid #1106411a",
                        }}
                      >
                        Locked Forecast
                      </Header>
                      <Header
                        style={{
                          height: "36px",
                          borderRight: "1.5px solid #1106411a",
                          borderLeft: "1.5px solid #1106411a",
                        }}
                      >
                        BOB
                      </Header>
                      <Header
                        style={{
                          height: "36px",
                          borderRight: "1.5px solid #1106411a",
                          borderLeft: "1.5px solid #1106411a",
                        }}
                      >
                        New Forecast
                      </Header>
                    </TCell>
                    {forecastSheetData?.roomNight.map((budget, index) => {
                      return (
                        <TCell style={{ height: "180px" }}>
                          <TransFormRoomInt data={budget.roomsLY} />
                          <TransFormRoomInt data={budget.roomsBudget} />
                          <TransFormRoomInt data={budget.roomsLocked} />
                          <TransFormRoomInt data={budget.roomsOTB} />
                          <TransFormRoomInt data={budget.roomsActual} />
                        </TCell>
                      );
                    })}
                  </TRow>
                  <TRow
                    style={{
                      background: "rgba(48, 111, 188, 1)",
                      height: "5px",
                    }}
                  >
                    <TCell
                      style={{
                        zIndex: "1",
                        background: "rgba(48, 111, 188, 1)",
                      }}
                      className="sticky"
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          position: "relative",
                          background: "white",
                          zIndex: "999",
                        }}
                      >
                        <ArrowDropDownCircle
                          style={{
                            fontSize: "22px",
                            position: "absolute",
                            cursor: "pointer",
                            background: "white",
                            left: "0",
                            color: "rgba(48, 111, 188, 1)",
                            transform: showPickupRequireToAchieve[0]
                              ? "rotate(180deg)"
                              : "none",
                          }}
                          onClick={() => handleShowPickupRequiredRow(0)}
                        />
                      </span>
                    </TCell>
                    <TCell
                      style={{
                        height: "8px",
                        background: "rgba(48, 111, 188, 1)",
                      }}
                      colSpan="100%"
                    ></TCell>
                  </TRow>
                  {showPickupRequireToAchieve[0] && (
                    <>
                      <TRow
                        style={{
                          background: "white",
                        }}
                      >
                        <TCell
                          style={{
                            padding: "0 10px",
                            font: "normal normal bold 12px/18px Roboto",
                            background: "white",
                          }}
                          className="sticky"
                        >
                          <p
                            style={{
                              writingMode: "tb-rl",
                              transform: "rotate(-180deg)",
                              margin: "auto",
                              fontSize: "14px",
                            }}
                          >
                            Variance To <br /> New Forecast
                          </p>
                        </TCell>
                        <TCell
                          className="stickyRight"
                          style={{
                            background: "white",
                          }}
                        >
                          <Header
                            style={{
                              height: "36px",
                              borderRight: "1.5px solid #1106411a",
                              borderLeft: "1.5px solid #1106411a",
                            }}
                          >
                            Vs LY
                          </Header>
                          <Header
                            style={{
                              height: "36px",
                              borderRight: "1.5px solid #1106411a",
                              borderLeft: "1.5px solid #1106411a",
                            }}
                          >
                            Vs Budget
                          </Header>
                          <Header
                            style={{
                              height: "36px",
                              borderRight: "1.5px solid #1106411a",
                              borderLeft: "1.5px solid #1106411a",
                            }}
                          >
                            Vs Locked Forecast
                          </Header>
                          <Header
                            style={{
                              height: "36px",
                              borderRight: "1.5px solid #1106411a",
                              borderLeft: "1.5px solid #1106411a",
                            }}
                          >
                            To Pickup
                          </Header>
                        </TCell>
                        {forecastSheetData?.roomNight.map((budget, index) => {
                          return (
                            <TCell>
                              <TransFormPickup
                                data={budget.roomsActual - budget.roomsLY}
                              />
                              <TransFormPickup
                                data={budget.roomsActual - budget.roomsBudget}
                              />
                              <TransFormPickup
                                data={budget.roomsActual - budget.roomsLocked}
                              />

                              <TransFormPickup
                                data={budget.roomsActual - budget.roomsOTB}
                              />
                            </TCell>
                          );
                        })}
                      </TRow>
                      <TRow
                        style={{
                          background: "rgba(48, 111, 188, 1)",
                          height: "8px",
                        }}
                      >
                        <td colSpan="100%"></td>
                      </TRow>
                    </>
                  )}
                </>
                <>
                  <TRow>
                    <TCell
                      style={{
                        padding: "0 10px",
                        font: "normal normal bold 14px/23px Roboto",
                      }}
                      className="sticky"
                    >
                      <p
                        style={{
                          writingMode: "tb-rl",
                          transform: "rotate(-180deg)",
                          margin: "auto",
                        }}
                      >
                        ADR
                      </p>
                    </TCell>
                    <TCell className="stickyRight">
                      <Header
                        style={{
                          height: "36px",
                          borderRight: "1.5px solid #1106411a",
                          borderLeft: "1.5px solid #1106411a",
                        }}
                      >
                        LY
                      </Header>
                      <Header
                        style={{
                          height: "36px",
                          borderRight: "1.5px solid #1106411a",
                          borderLeft: "1.5px solid #1106411a",
                        }}
                      >
                        Budget
                      </Header>
                      <Header
                        style={{
                          height: "36px",
                          borderRight: "1.5px solid #1106411a",
                          borderLeft: "1.5px solid #1106411a",
                        }}
                      >
                        Locked Forecast
                      </Header>
                      <Header
                        style={{
                          height: "36px",
                          borderRight: "1.5px solid #1106411a",
                          borderLeft: "1.5px solid #1106411a",
                        }}
                      >
                        BOB
                      </Header>
                      <Header
                        style={{
                          height: "36px",
                          borderRight: "1.5px solid #1106411a",
                          borderLeft: "1.5px solid #1106411a",
                        }}
                      >
                        New Forecast
                      </Header>
                    </TCell>
                    {forecastSheetData?.ADR.map((budget, index) => {
                      return (
                        <TCell>
                          <TransForm data={budget.arrLY} />
                          <TransForm data={budget.arrBudget} />
                          <TransForm data={budget.arrLocked} />
                          <TransForm data={budget.arrOTB} />
                          <TransForm data={budget.arrActual} />
                        </TCell>
                      );
                    })}
                  </TRow>
                  <TRow style={{ background: "#110641", height: "5px" }}>
                    <TCell
                      style={{
                        zIndex: "1",
                        background: "rgba(48, 111, 188, 1)",
                        height: "8px",
                      }}
                      className="sticky"
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          position: "relative",
                          background: "white",
                          zIndex: "999",
                        }}
                      >
                        <ArrowDropDownCircle
                          style={{
                            fontSize: "22px",
                            position: "absolute",
                            cursor: "pointer",
                            background: "white",
                            color: "rgba(48, 111, 188, 1)",
                            left: "0",
                            transform: showPickupRequireToAchieve[1]
                              ? "rotate(180deg)"
                              : "none",
                          }}
                          onClick={() => handleShowPickupRequiredRow(1)}
                        />
                      </span>
                    </TCell>
                    <TCell
                      style={{ background: "rgba(48, 111, 188, 1)" }}
                      colSpan="100%"
                    ></TCell>
                  </TRow>
                  {showPickupRequireToAchieve[1] && (
                    <>
                      <TRow
                        style={{
                          background: "white",
                        }}
                      >
                        <TCell
                          style={{
                            padding: "0 10px",
                            font: "normal normal bold 12px/18px Roboto",
                            background: "white",
                          }}
                          className="sticky"
                        >
                          <p
                            style={{
                              writingMode: "tb-rl",
                              transform: "rotate(-180deg)",
                              margin: "auto",
                              fontSize: "14px",
                            }}
                          >
                            Variance To <br /> New Forecast
                          </p>
                        </TCell>
                        <TCell
                          className="stickyRight"
                          style={{
                            background: "white",
                          }}
                        >
                          <Header
                            style={{
                              height: "36px",
                              borderRight: "1.5px solid #1106411a",
                              borderLeft: "1.5px solid #1106411a",
                            }}
                          >
                            Vs LY
                          </Header>
                          <Header
                            style={{
                              height: "36px",
                              borderRight: "1.5px solid #1106411a",
                              borderLeft: "1.5px solid #1106411a",
                            }}
                          >
                            Vs Budget
                          </Header>
                          <Header
                            style={{
                              height: "36px",
                              borderRight: "1.5px solid #1106411a",
                              borderLeft: "1.5px solid #1106411a",
                            }}
                          >
                            Vs Locked Forecast
                          </Header>
                          <Header
                            style={{
                              height: "36px",
                              borderRight: "1.5px solid #1106411a",
                              borderLeft: "1.5px solid #1106411a",
                            }}
                          >
                            To Pickup
                          </Header>
                        </TCell>
                        {forecastSheetData?.ADR.map((budget, index) => {
                          return (
                            <TCell>
                              <TransFormPickup
                                data={budget.arrActual - budget.arrLY}
                              />
                              <TransFormPickup
                                data={budget.arrActual - budget.arrBudget}
                              />
                              <TransFormPickup
                                data={budget.arrActual - budget.arrLocked}
                              />

                              <TransFormPickup
                                data={budget.arrActual - budget.arrOTB}
                              />
                            </TCell>
                          );
                        })}
                      </TRow>
                      <TRow
                        style={{
                          background: "rgba(48, 111, 188, 1)",
                          height: "8px",
                        }}
                      >
                        <td colSpan="100%"></td>
                      </TRow>
                    </>
                  )}
                </>
                <>
                  <TRow>
                    <TCell
                      style={{
                        padding: "0 10px",
                        font: "normal normal bold 14px/23px Roboto",
                      }}
                      className="sticky"
                    >
                      <p
                        style={{
                          writingMode: "tb-rl",
                          transform: "rotate(-180deg)",
                          margin: "auto",
                        }}
                      >
                        Revenue
                      </p>
                    </TCell>
                    <TCell className="stickyRight">
                      <Header
                        style={{
                          height: "36px",
                          borderRight: "1.5px solid #1106411a",
                          borderLeft: "1.5px solid #1106411a",
                        }}
                      >
                        LY
                      </Header>
                      <Header
                        style={{
                          height: "36px",
                          borderRight: "1.5px solid #1106411a",
                          borderLeft: "1.5px solid #1106411a",
                        }}
                      >
                        Budget
                      </Header>
                      <Header
                        style={{
                          height: "36px",
                          borderRight: "1.5px solid #1106411a",
                          borderLeft: "1.5px solid #1106411a",
                        }}
                      >
                        Locked Forecast
                      </Header>
                      <Header
                        style={{
                          height: "36px",
                          borderRight: "1.5px solid #1106411a",
                          borderLeft: "1.5px solid #1106411a",
                        }}
                      >
                        BOB
                      </Header>
                      <Header
                        style={{
                          height: "36px",
                          borderRight: "1.5px solid #1106411a",
                          borderLeft: "1.5px solid #1106411a",
                        }}
                      >
                        New Forecast
                      </Header>
                    </TCell>
                    {forecastSheetData?.Revenue.map((budget, index) => {
                      return (
                        <TCell>
                          <TransForm data={budget.revenueLY} />
                          <TransForm data={budget.revenueBudget} />
                          <TransForm data={budget.revenueLocked} />
                          <TransForm data={budget.revenueOTB} />
                          <TransForm data={budget.revenueActual} />
                        </TCell>
                      );
                    })}
                  </TRow>
                  <TRow style={{ background: "#110641", height: "5px" }}>
                    <TCell
                      style={{
                        zIndex: "999",
                        background: "rgba(48, 111, 188, 1)",
                        height: "8px",
                      }}
                      className="sticky"
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          position: "relative",
                          background: "white",
                          zIndex: "1",
                        }}
                      >
                        <ArrowDropDownCircle
                          style={{
                            fontSize: "22px",
                            position: "absolute",
                            cursor: "pointer",
                            background: "white",
                            color: "rgba(48, 111, 188, 1)",
                            left: "0",
                            transform: showPickupRequireToAchieve[2]
                              ? "rotate(180deg)"
                              : "none",
                          }}
                          onClick={() => handleShowPickupRequiredRow(2)}
                        />
                      </span>
                    </TCell>
                    <TCell
                      style={{ background: "rgba(48, 111, 188, 1)" }}
                      colSpan="100%"
                    ></TCell>
                  </TRow>
                  {showPickupRequireToAchieve[2] && (
                    <>
                      <TRow
                        style={{
                          background: "white",
                        }}
                      >
                        <TCell
                          style={{
                            padding: "0 10px",
                            font: "normal normal bold 12px/18px Roboto",
                            background: "white",
                          }}
                          className="sticky"
                        >
                          <p
                            style={{
                              writingMode: "tb-rl",
                              transform: "rotate(-180deg)",
                              margin: "auto",
                              fontSize: "14px",
                            }}
                          >
                            Variance To <br /> New Forecast
                          </p>
                        </TCell>
                        <TCell
                          className="stickyRight"
                          style={{
                            background: "white",
                          }}
                        >
                          <Header
                            style={{
                              height: "36px",
                              borderRight: "1.5px solid #1106411a",
                              borderLeft: "1.5px solid #1106411a",
                            }}
                          >
                            Vs LY
                          </Header>
                          <Header
                            style={{
                              height: "36px",
                              borderRight: "1.5px solid #1106411a",
                              borderLeft: "1.5px solid #1106411a",
                            }}
                          >
                            Vs Budget
                          </Header>
                          <Header
                            style={{
                              height: "36px",
                              borderRight: "1.5px solid #1106411a",
                              borderLeft: "1.5px solid #1106411a",
                            }}
                          >
                            Vs Locked Forecast
                          </Header>
                          <Header
                            style={{
                              height: "36px",
                              borderRight: "1.5px solid #1106411a",
                              borderLeft: "1.5px solid #1106411a",
                            }}
                          >
                            To Pickup
                          </Header>
                        </TCell>
                        {forecastSheetData?.Revenue.map((budget, index) => {
                          return (
                            <TCell>
                              <TransFormPickup
                                data={budget.revenueActual - budget.revenueLY}
                              />
                              <TransFormPickup
                                data={
                                  budget.revenueActual - budget.revenueBudget
                                }
                              />
                              <TransFormPickup
                                data={
                                  budget.revenueActual - budget.revenueLocked
                                }
                              />

                              <TransFormPickup
                                data={budget.revenueActual - budget.revenueOTB}
                              />
                            </TCell>
                          );
                        })}
                      </TRow>
                      <TRow
                        style={{
                          background: "rgba(48, 111, 188, 1)",
                          height: "8px",
                        }}
                      >
                        <td colSpan="100%"></td>
                      </TRow>
                    </>
                  )}
                  <TRow style={{ background: "#ffff", height: "20px" }}>
                    <td colSpan="100%"></td>
                  </TRow>
                  <TRow>
                    <TCell
                      className="sticky"
                      style={{ background: "white", border: "none" }}
                    ></TCell>
                    <TCell
                      className="stickyRight"
                      style={{ background: "#f5f4f8", border: "none" }}
                    >
                      <Paper elevation={5} style={{ height: "50px" }}>
                        <Stack
                          style={{
                            paddingTop: "10px",
                            paddingLeft: "20px",
                          }}
                        >
                          <DatePicker
                            date={selectedDate}
                            onChange={(date) => handleDateChange(date)}
                          />
                        </Stack>
                      </Paper>
                    </TCell>
                    {LoadingDailyForecast && (
                      <TCell style={{ background: "white", border: "none" }}>
                        <CircularProgress
                          style={{ color: "#110641", fontSize: "16px" }}
                        />
                      </TCell>
                    )}

                    <TCell
                      colSpan="100%"
                      style={{ background: "white", border: "none" }}
                    ></TCell>
                  </TRow>
                  <TRow style={{ background: "#ffff", height: "20px" }}>
                    <td colSpan="100%"></td>
                  </TRow>
                </>
              </TableBody>
            </Table>
            <Table className="table">
              <THead>
                {forecastSheetDailyData && (
                  <TRow
                    style={{
                      position: "sticky",
                      top: "0",
                      background: "white",
                    }}
                  >
                    <TCell
                      style={{
                        position: "sticky",
                        top: "0",
                        left: "0",
                        background: "#ffff",
                        zIndex: "10",
                      }}
                    >
                      <Header
                        style={{
                          border: "none",
                          zIndex: "9999",
                          background: "rgba(48, 111, 188, 1)",
                          color: "#fff",
                          fontSize: "14px",
                          height: "56px",
                          borderRight: "1px solid #fff",
                        }}
                      >
                        Market Segments
                      </Header>
                    </TCell>
                    <TCell colSpan={5}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          height: "56px",
                        }}
                      >
                        <Header
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            background: "rgba(48, 111, 188, 1)",
                            color: "#fff",
                            fontSize: "14px",
                            height: "28px",
                            borderBottom: "1px solid #fff",
                            borderRight: "1px solid #fff",
                          }}
                        >
                          Room Nights
                        </Header>
                        <div
                          style={{
                            display: "flex",
                            background: "rgba(48, 111, 188, 1)",
                            color: "#fff",
                            fontSize: "14px",
                            height: "28px",
                            borderRight: "1px solid #fff",
                          }}
                        >
                          <HeaderBottom style={{ fontWeight: "400" }}>
                            LY
                          </HeaderBottom>
                          <HeaderBottom style={{ fontWeight: "400" }}>
                            Budget
                          </HeaderBottom>
                          <HeaderBottom style={{ fontWeight: "400" }}>
                            Locked Forecast
                          </HeaderBottom>
                          <HeaderBottom style={{ fontWeight: "400" }}>
                            BOB
                          </HeaderBottom>
                          <HeaderBottom style={{ fontWeight: "400" }}>
                            Actual/Forecast
                          </HeaderBottom>
                        </div>
                      </div>
                    </TCell>

                    <TCell colSpan={5}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Header
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            background: "rgba(48, 111, 188, 1)",
                            color: "#fff",
                            fontSize: "14px",
                            height: "28px",
                            borderBottom: "1px solid #fff",
                            borderRight: "1px solid #fff",
                          }}
                        >
                          ADR
                        </Header>
                        <div
                          style={{
                            display: "flex",
                            background: "rgba(48, 111, 188, 1)",
                            color: "#fff",
                            fontSize: "14px",
                            height: "28px",
                            borderRight: "1px solid #fff",
                          }}
                        >
                          <HeaderBottom style={{ fontWeight: "400" }}>
                            LY
                          </HeaderBottom>
                          <HeaderBottom style={{ fontWeight: "400" }}>
                            Budget
                          </HeaderBottom>
                          <HeaderBottom style={{ fontWeight: "400" }}>
                            Locked Forecast
                          </HeaderBottom>
                          <HeaderBottom style={{ fontWeight: "400" }}>
                            BOB
                          </HeaderBottom>
                          <HeaderBottom style={{ fontWeight: "400" }}>
                            Actual/Forecast
                          </HeaderBottom>
                        </div>
                      </div>
                    </TCell>

                    <TCell colSpan={5}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Header
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            background: "rgba(48, 111, 188, 1)",
                            color: "#fff",
                            fontSize: "14px",
                            height: "28px",
                            borderBottom: "1px solid #fff",
                          }}
                        >
                          Revenue
                        </Header>
                        <div
                          style={{
                            display: "flex",
                            background: "rgba(48, 111, 188, 1)",
                            color: "#fff",
                            fontSize: "14px",
                            height: "28px",
                          }}
                        >
                          <HeaderBottom style={{ fontWeight: "400" }}>
                            LY
                          </HeaderBottom>
                          <HeaderBottom style={{ fontWeight: "400" }}>
                            Budget
                          </HeaderBottom>
                          <HeaderBottom style={{ fontWeight: "400" }}>
                            Locked Forecast
                          </HeaderBottom>
                          <HeaderBottom style={{ fontWeight: "400" }}>
                            BOB
                          </HeaderBottom>
                          <HeaderBottom style={{ fontWeight: "400" }}>
                            Actual/Forecast
                          </HeaderBottom>
                        </div>
                      </div>
                    </TCell>
                  </TRow>
                )}
              </THead>
              <TableBody>
                {forecastSheetDailyData?.map((data) => (
                  <TRow>
                    <TextOnlyTooltipSegment
                      disableFocusListener
                      disableTouchListener
                      title={data.description ? data.description : ""}
                      placement="right"
                      arrow
                      style={{ cursor: "default" }}
                    >
                      <TCell
                        className="sticky"
                        style={{
                          position: "sticky",
                          left: "0",
                          top: "62px",
                          zIndex: "1",
                          height: "36px",
                          borderRight: "1.5px solid #e7e6ec",
                          borderBottom: "1.5px solid #e7e6ec",
                        }}
                      >
                        <CellBottom
                          style={{
                            cursor: "default",
                            zIndex: "1",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          {data.systemName}
                        </CellBottom>
                      </TCell>
                    </TextOnlyTooltipSegment>
                    <TCell style={{ borderBottom: "1.5px solid #e7e6ec" }}>
                      <TransFormBottomInt data={data?.budgetModel[0].roomsLY} />
                    </TCell>
                    <TCell style={{ borderBottom: "1.5px solid #e7e6ec" }}>
                      <TransFormBottomInt
                        data={data?.budgetModel[0].roomsBudget}
                      />
                    </TCell>
                    <TCell style={{ borderBottom: "1.5px solid #e7e6ec" }}>
                      <TransFormBottomInt
                        data={data?.budgetModel[0].roomsLocked}
                      />
                    </TCell>
                    <TCell style={{ borderBottom: "1.5px solid #e7e6ec" }}>
                      <TransFormBottomInt
                        data={data?.budgetModel[0].roomsOTB}
                      />
                    </TCell>
                    <TCell style={{ borderBottom: "1.5px solid #e7e6ec" }}>
                      <TransFormBottomInt
                        data={data?.budgetModel[0].roomsActual}
                      />
                    </TCell>
                    <TCell style={{ borderBottom: "1.5px solid #e7e6ec" }}>
                      <TransFormBottom data={data?.budgetModel[0].arrLY} />
                    </TCell>
                    <TCell style={{ borderBottom: "1.5px solid #e7e6ec" }}>
                      <TransFormBottom data={data?.budgetModel[0].arrBudget} />
                    </TCell>
                    <TCell style={{ borderBottom: "1.5px solid #e7e6ec" }}>
                      <TransFormBottom data={data?.budgetModel[0].arrLocked} />
                    </TCell>
                    <TCell style={{ borderBottom: "1.5px solid #e7e6ec" }}>
                      <TransFormBottom data={data?.budgetModel[0].arrOTB} />
                    </TCell>
                    <TCell style={{ borderBottom: "1.5px solid #e7e6ec" }}>
                      <TransFormBottom data={data?.budgetModel[0].arrActual} />
                    </TCell>
                    <TCell style={{ borderBottom: "1.5px solid #e7e6ec" }}>
                      <TransFormBottomInt
                        data={data?.budgetModel[0].revenueLY}
                      />
                    </TCell>
                    <TCell style={{ borderBottom: "1.5px solid #e7e6ec" }}>
                      <TransFormBottomInt
                        data={data?.budgetModel[0].revenueBudget}
                      />
                    </TCell>
                    <TCell style={{ borderBottom: "1.5px solid #e7e6ec" }}>
                      <TransFormBottomInt
                        data={data?.budgetModel[0].revenueLocked}
                      />
                    </TCell>
                    <TCell style={{ borderBottom: "1.5px solid #e7e6ec" }}>
                      <TransFormBottomInt
                        data={data?.budgetModel[0].revenueOTB}
                      />
                    </TCell>
                    <TCell style={{ borderBottom: "1.5px solid #e7e6ec" }}>
                      <TransFormBottomInt
                        data={data?.budgetModel[0].revenueActual}
                      />
                    </TCell>
                  </TRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        </>
      ) : (
        <LoadingPage />
      )}
    </StyledContainer>
  );
};

function TransForm({ data }) {
  if (isNaN(data)) {
    return <Cell>-</Cell>;
  }
  return <Cell>{parseFloat(data).toFixed(2)}</Cell>;
}
function TransFormBottom({ data }) {
  if (isNaN(data) || data === -1) {
    return <CellBottom>-</CellBottom>;
  }
  return <CellBottom>{parseFloat(data).toFixed(2)}</CellBottom>;
}
function TransFormBottomInt({ data }) {
  if (isNaN(data) || data === -1) {
    return <CellBottom>-</CellBottom>;
  }
  return <CellBottom>{parseInt(data)}</CellBottom>;
}

function TransFormRoomInt({ data }) {
  if (isNaN(data)) {
    return <Cell>-</Cell>;
  }
  return <Cell>{parseInt(data)}</Cell>;
}

function TransFormPickup({ data }) {
  if (isNaN(data) || data === Infinity || data === -Infinity) {
    return <Cell>-</Cell>;
  }

  if (data >= 0) {
    return (
      <Cell style={{ color: "green" }}>{parseFloat(data).toFixed(2)}</Cell>
    );
  }
  return <Cell style={{ color: "red" }}>{parseFloat(data).toFixed(2)}</Cell>;
}
