import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";

import { Bar } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import { getISODate, LoadingPage, MonthPicker, Nodata, useAuth } from "../sdk";
import DoWPickupPerformanceTable from "./components/DoWPickupPerformanceTable";
import { ModelBody } from "./components/GraphPopover";
import {
  Body,
  BottomRow,
  Header,
  Page,
  SubHeader,
  TableCard,
  WeeklyCard,
} from "./Styles";
import { PreciumDisabledPage } from "../sdk/components/PreciumDiabledPage";

const input = [
  {
    date: new Date(),
    day: "Monday",
    price: 100,
  },

  {
    date: new Date(new Date().setDate(new Date().getDate() - 1)),
    day: "Tuesday",
    price: 120,
  },
  {
    date: new Date(new Date().setDate(new Date().getDate() - 2)),
    day: "Monday",
    price: 154,
  },
  {
    date: new Date(new Date().setDate(new Date().getDate() - 3)),
    day: "Tuesday",
    price: 89,
  },
  {
    date: new Date(new Date().setDate(new Date().getDate() - 4)),
    day: "Wednesday",
    price: 200,
  },
  {
    date: new Date(new Date().setDate(new Date().getDate() - 5)),
    day: "Thursday",
    price: 170,
  },
  {
    date: new Date(new Date().setDate(new Date().getDate() - 6)),
    day: "Friday",
    price: 108,
  },
  {
    date: new Date(new Date().setDate(new Date().getDate() - 7)),
    day: "Saturday",
    price: 98,
  },
  {
    date: new Date(new Date().setDate(new Date().getDate() - 8)),
    day: "Sunday",
    price: 67,
  },
];

export const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function DoWPickupPerformance({ setPageHeader }) {
  const [selectedDate, setSelectedDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );
  const [Loading, setLoading] = useState();
  const [comparitiveDate, setComparitiveDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 2))
  );
  const [selectedData, setSelectedData] = useState();
  const [daywiseData, setDaywiseData] = useState();
  const [comparitiveData, setComparitiveData] = useState();
  const [daywiseComparitiveData, setDaywiseDataComparativeData] = useState();
  const [dayToShowOnPopup, setDayToShowOnPopup] = useState("");
  const [graphData, setGraphData] = useState({ datasets: [], labels: [] });
  const { token, authFetch, currentHotel } = useAuth();
  const { hotelId } = useParams();
  const [openSelectbar, setOpenSelectBar] = useState(false);
  const [popupGraphData, setPopupGraphData] = useState();
  const [color, setColor] = useState();
  const [month, setMonth] = useState();
  useEffect(() => {
    if (dayToShowOnPopup) {
      if (color !== "rgb(166, 166, 166)") {
        setPopupGraphData(daywiseData[dayToShowOnPopup]);
      } else {
        setPopupGraphData(daywiseComparitiveData[dayToShowOnPopup]);
      }
    }
  }, [dayToShowOnPopup, color]);

  const pickupOptions = React.useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 8,
          right: 8,
          top: 0,
          bottom: 8,
        },
      },
      onHover: (event, chartElement) => {
        event.target.style.cursor = chartElement[0] ? "pointer" : "default";
      },
      title: {
        text: "DoW Pick-Up Performance",
        display: true,
        fontFamily: "Roboto",
        fontSize: 20,
        fontColor: "black",
        fontStyle: "normal",
      },
      legend: {
        position: "bottom",
        align: "left",
        labels: {
          fontFamily: "Roboto",
          fontSize: 14,
          boxWidth: 14,
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              fontFamily: "Roboto",
              fontColor: "black",
              fontSize: 10,

              suggestedMin: -10,
              suggestedMax: 20,
            },
            gridLines: {
              display: true,
              drawTicks: true,
              drawOnChartArea: false,
              color: "grey",
              zeroLineColor: "black",
            },
            scaleLabel: {
              display: true,
              labelString: "Pick-Up",
              fontColor: "black",
              fontFamily: "Roboto",
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
              drawTicks: true,
              drawOnChartArea: false,
              color: "grey",
              zeroLineColor: "grey",
            },
            ticks: {
              fontFamily: "Roboto",
              fontColor: "black",
              fontSize: 10,
            },
            maxBarThickness: 40,
            barThickness: 30,
          },
        ],
      },
    };
  }, []);

  useEffect(() => {
    if (!token) {
      return;
    }
    if (token && hotelId && selectedDate && comparitiveDate) {
      fetchSelectedData();
      fetchComparitiveData();
    }
  }, [token, hotelId, selectedDate, comparitiveDate]);

  useEffect(() => {
    if (selectedData && comparitiveData) {
      dataForGraph();
    }
  }, [selectedData, comparitiveData]);

  function generateData(input) {
    let totalMondayPickup = 0;
    let totalMondays = 0;
    let totalTuesdayPickup = 0;
    let totalTuesdays = 0;
    let totalWednesdayPickup = 0;
    let totalWednesdays = 0;
    let totalThursdayPickup = 0;
    let totalThursdays = 0;
    let totalFridayPickup = 0;
    let totalFridays = 0;
    let totalSaturdayPickup = 0;
    let totalSaturdays = 0;
    let totalSundayPickup = 0;
    let totalSundays = 0;

    let DayWiseMonthlyData = {
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
      Sunday: [],
    };

    input.forEach((value) => {
      if (value.day === "Monday") {
        totalMondayPickup = totalMondayPickup + value.pickup;
        if (value.isValid) {
          totalMondays = totalMondays + 1;
        }

        DayWiseMonthlyData.Monday.push({
          date: value.date,
          pickup: value.pickup,
        });
      }
      if (value.day === "Tuesday") {
        totalTuesdayPickup = totalTuesdayPickup + value.pickup;

        if (value.isValid) {
          totalTuesdays = totalTuesdays + 1;
        }

        DayWiseMonthlyData.Tuesday.push({
          date: value.date,
          pickup: value.pickup,
        });
      }
      if (value.day === "Wednesday") {
        totalWednesdayPickup = totalWednesdayPickup + value.pickup;

        if (value.isValid) {
          totalWednesdays = totalWednesdays + 1;
        }
        DayWiseMonthlyData.Wednesday.push({
          date: value.date,
          pickup: value.pickup,
        });
      }
      if (value.day === "Thursday") {
        totalThursdayPickup = totalThursdayPickup + value.pickup;

        if (value.isValid) {
          totalThursdays = totalThursdays + 1;
        }

        DayWiseMonthlyData.Thursday.push({
          date: value.date,
          pickup: value.pickup,
        });
      }
      if (value.day === "Friday") {
        totalFridayPickup = totalFridayPickup + value.pickup;

        if (value.isValid) {
          totalFridays = totalFridays + 1;
        }
        DayWiseMonthlyData.Friday.push({
          date: value.date,
          pickup: value.pickup,
        });
      }
      if (value.day === "Saturday") {
        totalSaturdayPickup = totalSaturdayPickup + value.pickup;

        if (value.isValid) {
          totalSaturdays = totalSaturdays + 1;
        }
        DayWiseMonthlyData.Saturday.push({
          date: value.date,
          pickup: value.pickup,
        });
      }
      if (value.day === "Sunday") {
        totalSundayPickup = totalSundayPickup + value.pickup;

        if (value.isValid) {
          totalSundays = totalSundays + 1;
        }

        DayWiseMonthlyData.Sunday.push({
          date: value.date,
          pickup: value.pickup,
        });
      }
    });

    if (!totalMondays) {
      totalMondays = 1;
    }
    if (!totalTuesdays) {
      totalTuesdays = 1;
    }
    if (!totalWednesdays) {
      totalWednesdays = 1;
    }
    if (!totalThursdays) {
      totalThursdays = 1;
    }
    if (!totalFridays) {
      totalFridays = 1;
    }
    if (!totalSaturdays) {
      totalSaturdays = 1;
    }
    if (!totalSundays) {
      totalSundays = 1;
    }

    const totalPriceArray = [
      {
        day: "Monday",
        pickup: totalMondayPickup / totalMondays,
      },
      {
        day: "Tuesday",
        pickup: totalTuesdayPickup / totalTuesdays,
      },
      {
        day: "Wednesday",
        pickup: totalWednesdayPickup / totalWednesdays,
      },
      {
        day: "Thursday",
        pickup: totalThursdayPickup / totalThursdays,
      },
      {
        day: "Friday",
        pickup: totalFridayPickup / totalFridays,
      },
      {
        day: "Saturday",
        pickup: totalSaturdayPickup / totalSaturdays,
      },
      {
        day: "Sunday",
        pickup: totalSundayPickup / totalSundays,
      },
    ];
    return [totalPriceArray, DayWiseMonthlyData];
  }

  async function fetchSelectedData() {
    setLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/pickup-day-of-week/month/${
        getISODate(selectedDate).split("T")[0]
      }`,
    });
    const { data, error } = await get();
    if (data) {
      setSelectedData(generateData(data)[0]);
      setDaywiseData(generateData(data)[1]);
    }
    setLoading(false);
  }
  async function fetchComparitiveData() {
    setLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/pickup-day-of-week/month/${
        getISODate(comparitiveDate).split("T")[0]
      }`,
    });
    const { data, error } = await get();
    if (data) {
      setComparitiveData(generateData(data)[0]);
      setDaywiseDataComparativeData(generateData(data)[1]);
    }
    setLoading(false);
  }

  function dataForGraph() {
    if (selectedData) {
      const occData = {
        labels: selectedData.map((data) => {
          return data.day;
        }),
        datasets: [
          {
            label: `${
              Months[new Date(selectedDate).getMonth()] +
              " " +
              new Date(selectedDate).getFullYear()
            }`,
            backgroundColor: "#1E90FF",
            data: selectedData.map((data) => {
              return parseFloat(data.pickup).toFixed(2);
            }),
          },
          {
            label: `${
              Months[new Date(comparitiveDate).getMonth()] +
              " " +
              new Date(comparitiveDate).getFullYear()
            }`,
            backgroundColor: "#B7B7B7",
            data: comparitiveData.map((data) => {
              return parseFloat(data.pickup).toFixed(2);
            }),
          },
        ],
      };
      setGraphData(occData);
    }
  }

  const getElementAtEvent = (element) => {
    if (element.length) {
      setDayToShowOnPopup(element[0]?._model.label);
      setColor(element[0]?._model.backgroundColor);
      setMonth(element[0]?._model.datasetLabel);
      setOpenSelectBar(true);
    }
  };

  useEffect(() => {
    setPageHeader("DoW Pick-Up Performance");
    return () => {
      setPageHeader("");
    };
  }, []);

  return currentHotel?.isPreciumEnabled ? (
    <Page>
      <Body>
        <Header>
          <SubHeader>
            <MonthPicker value={selectedDate} onChange={setSelectedDate} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  color: "#110641",
                  fontSize: "14px",
                  fontFamily: "Roboto",
                }}
              >
                Compare with
              </p>
            </div>
            <MonthPicker
              value={comparitiveDate}
              onChange={setComparitiveDate}
            />
          </SubHeader>
        </Header>
        {selectedData || comparitiveData ? (
          <BottomRow>
            <WeeklyCard>
              <div style={{ height: "100%", width: "100%" }}>
                <Bar
                  data={graphData}
                  options={pickupOptions}
                  getElementAtEvent={getElementAtEvent}
                />
              </div>
            </WeeklyCard>
            <TableCard>
              <DoWPickupPerformanceTable
                selectedDate={selectedDate}
                comparitiveDate={comparitiveDate}
                selectedData={selectedData}
                comparitiveData={comparitiveData}
                daywiseData={daywiseData}
                daywiseComparitiveData={daywiseComparitiveData}
              />
            </TableCard>
            <Modal open={openSelectbar} onClose={() => setOpenSelectBar(false)}>
              <div>
                <ModelBody
                  setOpenSelectBar={setOpenSelectBar}
                  data={popupGraphData}
                  dayToShowOnPopup={dayToShowOnPopup}
                  color={color}
                  month={month}
                />
              </div>
            </Modal>
          </BottomRow>
        ) : Loading ? (
          <LoadingPage />
        ) : (
          <Nodata />
        )}
      </Body>
    </Page>
  ) : (
    <PreciumDisabledPage />
  );
}
