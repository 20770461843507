const agoda = "/assets/agoda.png"
const booking = "/assets/booking.svg"
const cleartrip = "/assets/cleartrip.svg"
const expedia = "/assets/expedia.svg"
const goibibo = "/assets/goibibo.svg"
const makemytrip = "/assets/makemytrip.svg"

const channelLogs = {
    "3786": agoda,
    "3784": booking,
    "3783": expedia,
    "3787": goibibo,
    "3785": makemytrip,
    "5": agoda,
    "2": booking,
    "4": cleartrip,
    "1": expedia,
    "7": goibibo,
    "3": makemytrip,
}

export { channelLogs }