import { Box, Snackbar, SnackbarContent } from "@mui/material";
import React, { useState } from "react";
import ResetPassword from "./components/ResetPassword";
import { Main } from "./components/Styles";

export default function ResetLinkPage() {
  const [networkMsg, setnetworkMsg] = useState(null);
  const [open, setOpen] = useState(false);
  const handleCloseStatus = () => {
    setOpen(false);
  };

  return (
    <Main>
      <Box>
        <ResetPassword />
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseStatus}
      >
        <SnackbarContent
          style={{ backgroundColor: "#CA3433" }}
          message={networkMsg}
        />
      </Snackbar>
    </Main>
  );
}
