import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField, styled } from '@mui/material'
import React, { useCallback, useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from '../../sdk';


const AddRoleModal = ({ open, handleClose, getRolesAndPermissions, setnetworkMsg
}) => {
    const { authFetch } = useAuth();

    const [isSaving, setIsSaving] = useState(false)
    const [data, setData] = useState({
        name: '', description: ''
    })

    const handleSave = useCallback(async () => {
        try {
            setIsSaving(true)
            const { post } = await authFetch({
                path: `/role`
            });
            const { response } = await post(data);
            if (response.status === 200) {
                handleClose()
                getRolesAndPermissions()
                setnetworkMsg({
                    msg: 'Role added successfully',
                    color: 'green',
                    open: true
                })
            }
        } catch (error) {
            console.log(error);
            handleClose()
            setnetworkMsg({
                msg: 'Something went wrong',
                color: 'red',
                open: true
            })
        } finally {
            setIsSaving(false)
        }
    }, [authFetch, data, getRolesAndPermissions, handleClose, setnetworkMsg])

    return (
        <StyledModal
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle className="title">Add New Role</DialogTitle>
            <CloseIcon className="closeButton" onClick={handleClose} />

            <DialogContent className="content">
                <Stack direction='row' gap={2}>
                    <TextField
                        variant="outlined"
                        type="text"
                        label='Role name'
                        value={data.name}
                        onChange={(e) => setData({
                            ...data, name: e.target.value.trim()
                        })}
                    />
                    <TextField
                        variant="outlined"
                        type="text"
                        label='Description'
                        value={data.description}
                        onChange={(e) => setData({
                            ...data, description: e.target.value
                        })}
                    />
                </Stack>
                <Button sx={{ marginTop: '16px' }} variant='contained'
                    disabled={!data.name || !data.description || isSaving} onClick={handleSave}>
                    Save
                </Button>

            </DialogContent>

        </StyledModal>
    )
}

export default AddRoleModal

const StyledModal = styled(Dialog)(() => ({
    padding: '16px',
    ".closeButton": {
        position: "absolute",
        right: "10px",
        top: "10px",
        cursor: "pointer",
    },
    ".title": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textTransform: 'capitalize'
    },
    ".content": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },

}));
