import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getISODate } from "../../sdk";
import { useAuth } from "../../sdk/hooks/useAuth";

export default function usePastForecast() {
  const date = new Date();
  const [forecastSheetData, setForecastSheetData] = useState(null);
  const { token, authFetch } = useAuth();
  const { hotelId } = useParams();
  const [comparitiveDate, setComparitiveDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [networkMsg, setnetworkMsg] = useState("");
  const [open, setOpen] = useState();
  const [Loading, setLoading] = useState(false);
  const [dates, setDates] = useState(null);
  const [openSelectbar, setOpenSelectBar] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [filename, setFileName] = useState();
  const formdata = useRef(new FormData());

  const getForecastData = useCallback(async () => {
    setLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/past-arr-budget/${getISODate(
        new Date(comparitiveDate.getFullYear(), comparitiveDate.getMonth(), 1)
      )}`,
    });

    const { data, response } = await get();

    if (response.status === 200) {
      setForecastSheetData(data);
      // setOpen(true);
    } else {
      // setOpen(true);
      setForecastSheetData(null);
    }

    setLoading(false);
  }, [authFetch, comparitiveDate, hotelId]);

  function generateDate(date) {
    const DateArray = [];
    var firstDay = 1;
    var lastDay = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();
    for (firstDay = 1; firstDay <= lastDay; firstDay++) {
      DateArray.push(new Date(date.getFullYear(), date.getMonth(), firstDay));
    }
    setDates(DateArray);
  }
  async function fileHandler() {
    setOpenSelectBar(false);
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/upload-past-data-budget`,
    });
    const { data, response } = await post(formdata.current);
    if (response.status === 200) {
      setnetworkMsg("Upload Successful");
      setOpen(true);
      setToggle(false);
      setForecastSheetData(null);
      getForecastData();
    } else {
      setnetworkMsg(data?.messageToUser || "Invalid File");
      setOpen(true);
    }
    formdata.current = new FormData();
  }
  const removeFile = () => {
    setFileName("");
    formdata.current.delete("file");
    setToggle(!toggle);
  };

  const fileGetter = (file) => {
    setFileName(file[0].name);
    handleToggle();
    formdata.current.append("file", file[0]);
  };
  const handleToggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    setForecastSheetData(null);
    getForecastData();
    generateDate(comparitiveDate);
  }, [comparitiveDate, token, hotelId, getForecastData]);

  return {
    forecastSheetData,
    comparitiveDate,
    setComparitiveDate,
    Loading,
    dates,
    setOpen,
    networkMsg,
    open,
    fileHandler,
    fileGetter,
    setOpenSelectBar,
    removeFile,
    filename,
    handleToggle,
    toggle,
    openSelectbar,
  };
}
