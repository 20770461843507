import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth, usePrevious } from "../../sdk";
const EMPTY_ROW = {
  occupancyMin: 0,
  occupancyMax: 100,
  basePriceMW: null,
  basePriceWE: null,
};

const SEASONS = {
  HIGH: "high",
  LOW: "low",
};

export function useSeasonPricing(
  copyFromHotelId,
  HighPrices,
  LowPrices,
  openSelectbar
) {
  const [lsPrices, setLSPrices] = useState([]);
  const [hsPrices, setHSPrices] = useState([]);
  const [isLSInEditMode, setIsLSInEditMode] = useState(false);
  const [isHSInEditMode, setIsHSInEditMode] = useState(false);
  const [isLSCancelled, setIsLSCancelled] = useState(false);
  const [isHSCancelled, setIsHSCancelled] = useState(false);
  const [isLSSaved, setIsLSSaved] = useState(false);
  const [isHSSaved, setIsHSSaved] = useState(false);
  const [lsLoading, setlsLoading] = useState(false);
  const [hsLoading, sethsLoading] = useState(false);
  const [checkLSPricesValidation, setLSPricesValidation] = useState(false);
  const [checkHSPricesValidation, setHSPricesValidation] = useState(false);
  const [Error, setError] = useState(false);
  const [open, setOpen] = useState();
  const { hotelId } = useParams();
  const { token, authFetch } = useAuth();
  const saveLSClicked = usePrevious(isLSInEditMode);
  const saveHSClicked = usePrevious(isHSInEditMode);
  const [networkMsg, setnetworkMsg] = useState();
  const [errorMsg, setErrorMsg] = useState();

  useEffect(() => {
    if (!token) return;
    refreshHSPrices();
    refreshLSPrices();
  }, [token, hotelId]);
  useEffect(() => {
    if (isLSInEditMode) {
      const allValidEntries = lsPrices.every(isValid);
      if (allValidEntries) setLSPricesValidation(false);
      else setLSPricesValidation(true);
      const lastEntry = lsPrices.length
        ? lsPrices[lsPrices.length - 1]
        : {
            occupancyMax: null,
          };
      if (
        allValidEntries &&
        lastEntry.occupancyMax < 100 &&
        lastEntry.occupancyMax > 0
      ) {
        setLSPrices((prevState) => [
          ...prevState,
          {
            ...EMPTY_ROW,
            occupancyMin: lastEntry.occupancyMax + 1,
          },
        ]);
      } else if (lastEntry.occupancyMax === null) {
        setLSPrices((prevState) => [
          ...prevState,
          {
            ...EMPTY_ROW,
          },
        ]);
      }
    } else if (isLSSaved) {
      updateLSPrice();
    } else if (isLSCancelled) {
      refreshLSPrices();
    }
  }, [lsPrices, isLSInEditMode]);

  useEffect(() => {
    if (isHSInEditMode) {
      const allValidEntries = hsPrices.every(isValid);
      if (allValidEntries) setHSPricesValidation(false);
      else setHSPricesValidation(true);
      const lastEntry = hsPrices.length
        ? hsPrices[hsPrices.length - 1]
        : {
            occupancyMax: null,
          };
      if (
        allValidEntries &&
        lastEntry.occupancyMax < 100 &&
        lastEntry.occupancyMax > 0
      ) {
        setHSPrices((prevState) => [
          ...prevState,
          {
            ...EMPTY_ROW,
            occupancyMin: lastEntry.occupancyMax + 1,
          },
        ]);
      } else if (lastEntry.occupancyMax === null) {
        setHSPrices((prevState) => [
          ...prevState,
          {
            ...EMPTY_ROW,
          },
        ]);
      }
    } else if (isHSSaved) {
      updateHSPrice();
    } else if (isHSCancelled) {
      refreshHSPrices();
    }
  }, [hsPrices, isHSInEditMode]);

  useEffect(() => {
    if (copyFromHotelId === null && !HighPrices) return;
    if (!openSelectbar) refreshHSPrices(copyFromHotelId);
  }, [HighPrices]);

  useEffect(() => {
    if (copyFromHotelId === null && !LowPrices) return;
    if (!openSelectbar) refreshLSPrices(copyFromHotelId);
  }, [LowPrices]);

  async function updateHSPrice() {
    const validHSRows = maxLimit(hsPrices);
    const validHSPrices = validHSRows
      .map((row, index) => {
        if (index === validHSRows.length - 1) {
          const { occupancyMax } = row;
          return !occupancyMax
            ? {
                ...row,
                occupancyMax: -1,
              }
            : row;
        }
        return row;
      })
      .filter(isValid);

    const sortedHSPrices = validHSPrices.sort(
      (a, b) => a.occupancyMin - b.occupancyMin
    );

    const { post } = await authFetch({
      path: `/hotel/${hotelId}/pricing-setup/high/all`,
    });
    const { error, response, data } = await post(validHSPrices);
    if (!response?.ok) {
      setnetworkMsg(data?.messageToUser || "Can't Update High Price Setup");
      setOpen(true);
    }
    setIsHSSaved(false);
    refreshHSPrices();
  }
  async function updateLSPrice() {
    const validLSRows = maxLimit(lsPrices);
    const validLSPrices = validLSRows
      .map((row, index) => {
        if (index === validLSRows.length - 1) {
          const { occupancyMax } = row;
          return !occupancyMax
            ? {
                ...row,
                occupancyMax: -1,
              }
            : row;
        }
        return row;
      })
      .filter(isValid);

    const sortedLSPrices = validLSPrices.sort(
      (a, b) => a.occupancyMin - b.occupancyMin
    );
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/pricing-setup/low/all`,
    });
    const { error, response, data } = await post(validLSPrices);
    if (!response?.ok) {
      setnetworkMsg(data?.messageToUser || "Can't Update High Price Setup");
      setOpen(true);
    }

    setIsLSSaved(false);
    refreshLSPrices();
  }
  async function refreshLSPrices(anotherHotelId = false) {
    const hotelIdToUse = anotherHotelId ? anotherHotelId : hotelId;
    setlsLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelIdToUse}/pricing-setup/low/all`,
    });
    const { data, error } = await get();
    if (data) {
      setLSPrices(data.sort((a, b) => a.occupancyMin - b.occupancyMin));
      setError(false);
    }

    if (error) {
      setError(true);
      console.log(error);
    }
    setIsLSCancelled(false);

    if (anotherHotelId) {
      setIsLSInEditMode(true);
    }
    setlsLoading(false);
  }
  async function refreshHSPrices(anotherHotelId = false) {
    const hotelIdToUse = anotherHotelId ? anotherHotelId : hotelId;
    sethsLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelIdToUse}/pricing-setup/high/all`,
    });
    const { data, error } = await get();
    if (data) {
      setHSPrices(data.sort((a, b) => a.occupancyMin - b.occupancyMin));
      setError(false);
    }

    if (error) {
      setError(true);
      console.log(error);
    }
    setIsHSCancelled(false);
    if (anotherHotelId) {
      setIsHSInEditMode(true);
    }
    sethsLoading(false);
  }

  function maxLimit(prices) {
    let validRows = prices;
    prices.map((row, idx) => {
      const { occupancyMax } = row;
      if (!occupancyMax) validRows = prices.slice(0, idx + 1);
    });
    return validRows;
  }

  function isValid(price) {
    return (
      price.occupancyMin !== null &&
      price.occupancyMax !== null &&
      price.basePriceMW !== null &&
      price.basePriceWE !== null &&
      price.occupancyMin !== "" &&
      price.occupancyMax !== "" &&
      price.basePriceMW !== "" &&
      price.basePriceWE !== "" &&
      (price.occupancyMax >= price.occupancyMin || price.occupancyMax === -1)
    );
  }
  function onLSPriceChange(index, key, value) {
    setLSPrices((prevState) => {
      return prevState.map((price, idx) =>
        idx === index
          ? {
              ...price,
              [key]: value,
            }
          : price
      );
    });
  }
  function onHSPriceChange(index, key, value) {
    setHSPrices((prevState) => {
      return prevState.map((price, idx) =>
        idx === index
          ? {
              ...price,
              [key]: value,
            }
          : price
      );
    });
  }

  function toggleLSEdit() {
    setIsLSInEditMode(!isLSInEditMode);
  }
  function toggleHSEdit() {
    setIsHSInEditMode(!isHSInEditMode);
  }

  function handleCloseStatus() {
    setOpen(false);
  }

  const lonRemoveRowHandler = (index) => {
    setLSPrices((prevState) => {
      return prevState.filter((_, idx) => idx !== index);
    });
  };

  const lonAddRowHandler = (index) => {
    setLSPrices([
      ...lsPrices.filter((_, idx) => idx <= index),
      {
        occupancyMin: "",
        occupancyMax: "",
        basePriceMW: "",
        basePriceWE: "",
      },
      ...lsPrices.filter((_, idx) => idx > index),
    ]);
  };

  const honRemoveRowHandler = (index) => {
    setHSPrices((prevState) => {
      return prevState.filter((_, idx) => idx !== index);
    });
  };

  const honAddRowHandler = (index) => {
    setHSPrices([
      ...hsPrices.filter((_, idx) => idx <= index),
      {
        occupancyMin: "",
        occupancyMax: "",
        basePriceMW: "",
        basePriceWE: "",
      },
      ...hsPrices.filter((_, idx) => idx > index),
    ]);
  };

  return {
    lsPrices,
    hsPrices,
    isLSInEditMode,
    isHSInEditMode,
    setIsLSInEditMode,
    setIsHSInEditMode,
    isLSCancelled,
    isHSCancelled,
    setIsLSCancelled,
    setIsHSCancelled,
    isLSSaved,
    isHSSaved,
    setIsLSSaved,
    setIsHSSaved,
    onLSPriceChange,
    onHSPriceChange,
    toggleLSEdit,
    toggleHSEdit,
    pOpen: open,
    errorMsg,
    setErrorMsg,
    pHandleCloseStatus: handleCloseStatus,
    pNetworkMsg: networkMsg,
    lonRemoveRowHandler,
    lonAddRowHandler,
    honRemoveRowHandler,
    honAddRowHandler,
    SeasonPricingError: Error,
    lsLoading,
    hsLoading,
    checkLSPricesValidation,
    checkHSPricesValidation,
  };
}
