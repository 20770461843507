import {
  Box,
  LinearProgress,
  Paper,
  Popover,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import React, { Fragment, useState } from "react";
import { styled } from "@mui/system";
import {
  Description,
  DescriptionMore,
  DotBDL as Dot,
  EventItem,
  Info,
  SingleEventContainerBDL as SingleEventContainer,
} from "../../EventCalender/components/style";
import AddEventsModel from "../../EventCalender/components/AddEventsModel";
import DescriptionPopOver from "../../EventCalender/components/DescriptionPopOver";
import {
  Nodata,
  getDateDDMM,
  getDateDDMMYYYY,
  getDay,
  getHrMin,
} from "../../sdk";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";
import { Button, TextField } from "@mui/material";
import Draggable from "react-draggable";

export const Calendar = styled(Box)`
  margin: 18px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Week = styled(Box)`
  display: grid;
  grid-template-columns: repeat(7, 12vw);
  grid-template-rows: 3vh;
`;
export const Name = styled(Box)`
  background: transparent;
  font: normal bold normal 14px/16px Roboto;
  color: #1d1d1d;
  text-align: center;
`;

export const GridBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(7, 12vw);
  grid-template-rows: repeat(autofill, 10vh);
  height: 100%;
  max-height: 70vh;
  overflow: auto;
`;

export const Cell = styled(Box)`
  padding: 6px;
  padding-top: 4px;
  font: normal bold normal 14px/16px Roboto;
  text-align: center;
  background: white;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  border-radius: 10px;
  box-shadow: 0px 0px 10px white !important;
`;

export const TextOnlyTooltipDescriptionPopover = styled(Tooltip)(
  ({ theme }) => ({
    ".tooltip": {
      font: "normal normal normal 14px/8px Roboto",
      lineHeight: "16px",
      marginLeft: 0,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
      padding: "4px",
      background: " #163A90 0% 0% no-repeat padding-box",
      boxShadow: "0px 4px 8px #00000026",
      color: "#ffffff",
      marginBottom: "2px",
    },
    ".arrow": {
      "&:before": {
        border: "1px solid #E6E8ED",
      },
      color: "#163A90",
    },
  })
);
Date.prototype.monthDays = function () {
  let d = new Date(this.getFullYear(), this.getMonth() + 1, 0);
  return d.getDate();
};

export function MonthDayCard({
  baseDemandLevels,
  demandLevels,
  onChange,
  generateBatchBaseDemandLevels,
  setLoading,
  addEvent,
  addDemandLevel,
  addCityDemandLevel,
  isApiHit,
  isMonthUpdated,
  hasData,
  Loading,
  getData,
  uploadImage,
  sendRemark,
  selectedImages,
  setSelectedImages,
  remarkData,
}) {
  const [popOverIdx, setPopOverIdx] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);
  const [popOver, setPopOver] = useState(undefined);
  const [noData, setNoData] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popOverDate, setPopOverDate] = useState("");
  const [disablePrevDate, setDisablePrevDate] = useState(false);
  const [futureRemark, setFutureRemark] = useState(false);

  const id = !!anchorEl ? "simple-popover" : undefined;

  let previousLength = 0;

  const handleInput = (event) => {
    const bullet = "\u2022";
    const newLength = event.target.value.length;
    const characterCode = event.target.value.substr(-1).charCodeAt(0);

    if (newLength > previousLength) {
      if (characterCode === 10) {
        event.target.value = `${event.target.value}${bullet} `;
      } else if (newLength === 1) {
        event.target.value = `${bullet} ${event.target.value}`;
      }
    }
    previousLength = newLength;
  };

  const convertImageToBlob = async (image) => {
    const response = await fetch(image.uri);
    const blob = await response.blob();
    return blob;
  };

  const handleImagePreview = async (e) => {
    if (e.target.files) {
      const images = [];
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        if (file.type.startsWith("image")) {
          const image = URL.createObjectURL(file);
          const blob = await convertImageToBlob({ uri: image });
          images.push(blob);
        } else {
          alert("Only image files are allowed.");
          break;
        }
      }
      const imageUrl = await uploadImage(images);
      setSelectedImages((prevState) => [...prevState, ...imageUrl]);
      setPopOver((prevState) => ({
        ...prevState,
        images: [...prevState?.images, ...imageUrl],
      }));
    }
  };

  const handleDeleteImage = (index, deletedImage) => {
    setSelectedImages((prevSelectedImages) =>
      prevSelectedImages.filter((img, i) => i !== index)
    );
    setPopOver((prevState) => ({
      ...prevState,
      images: prevState.images?.filter(
        (item) => item.imageURL !== deletedImage.imageURL
      ),
    }));
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedImages([]);
  };

  const handleSaveChanges = () => {
    setIsEditing(false);
    sendRemark(popOverDate, popOver);
    setAnchorEl(null);
  };

  const handleEditBtn = () => {
    setIsEditing(true);
  };

  const handleDiscard = () => {
    setIsEditing(false);
    const data = remarkData.find((item) => {
      return (
        String(new Date(item.remark.date).toLocaleDateString()) ===
        String(new Date(popOverDate).toLocaleDateString())
      );
    });
    setPopOver(data);
    setSelectedImages([]);
  };

  const handleRowHover = (date, e) => {
    setPopOverDate(date);
    const data = remarkData.find((item) => {
      return (
        String(new Date(item.remark.date).toLocaleDateString()) ===
        String(new Date(date).toLocaleDateString())
      );
    });
    if (data) {
      setPopOver(data);
      setNoData(false);
    } else {
      setPopOver(undefined);
      setNoData(true);
    }
    setAnchorEl(e.currentTarget);
    setDisablePrevDate(
      String(new Date().toLocaleDateString()) ===
        String(new Date(date).toLocaleDateString())
        ? false
        : new Date() > new Date(date)
    );
    setFutureRemark(new Date() < new Date(date));
  };
  return (
    <>
      <Calendar>
        {hasData && (
          <Week>
            <Name>Monday</Name>
            <Name>Tuesday</Name>
            <Name>Wednesday</Name>
            <Name>Thursday</Name>
            <Name>Friday</Name>
            <Name>Saturday</Name>
            <Name>Sunday</Name>
          </Week>
        )}

        {!isMonthUpdated ? (
          hasData ? (
            <GridBox>
              {baseDemandLevels &&
                baseDemandLevels?.map((val, index) => {
                  const desc = val?.events?.map((item) => item.name) ?? [];
                  const arr = val?.manualEvents ?? [];
                  const eventArray = [...desc, ...arr];

                  return val?.id !== -1 ? (
                    <PopupState
                      variant="popover"
                      popupId={`${index}`}
                      key={index}
                    >
                      {(popupState) => (
                        <Fragment>
                          {eventArray.length > 0 ? (
                            <TextOnlyTooltipDescriptionPopover
                              TransitionComponent={Zoom}
                              TransitionProps={{ timeout: 300 }}
                              disableFocusListener
                              disableTouchListener
                              placement="top"
                              arrow
                              interactive
                              title={
                                <DescriptionPopOver
                                  index={index}
                                  eventArray={eventArray}
                                />
                              }
                            >
                              <Cell
                                {...bindTrigger(popupState)}
                                style={{
                                  cursor: "pointer",
                                  userSelect: "none",
                                }}
                                onClick={() => setPopOverIdx(index)}
                              >
                                <Paper
                                  elevation={5}
                                  style={{
                                    height: "121px",
                                    background: val?.cityColor
                                      ? val?.cityColor
                                      : "#778899",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <Stack
                                    direction={"column"}
                                    style={{
                                      padding: "8px 10px",
                                    }}
                                    justifyContent="space-evenly"
                                  >
                                    <Stack
                                      direction={"row"}
                                      justifyContent="space-between"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: "18px",
                                          color: "#fff",
                                        }}
                                      >
                                        {val?.date.slice(8, 10)}
                                      </Typography>
                                      <Typography
                                        style={{
                                          fontSize: "18px",
                                          color: "#fff",
                                        }}
                                      >
                                        {val?.cityDemandLevelSymbol}
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      direction={"column"}
                                      alignItems="flex-start"
                                      style={{ height: "52px" }}
                                      textAlign="center"
                                    >
                                      <Description
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {eventArray?.map((event, idx) =>
                                          event ? (
                                            <Fragment key={idx}>
                                              {idx < 1 && (
                                                <SingleEventContainer>
                                                  <Dot></Dot>
                                                  <EventItem
                                                    style={{
                                                      marginTop: "2px",
                                                    }}
                                                  >
                                                    {event}
                                                  </EventItem>
                                                </SingleEventContainer>
                                              )}
                                            </Fragment>
                                          ) : (
                                            <div key={idx}></div>
                                          )
                                        )}
                                      </Description>
                                      <DescriptionMore
                                        style={{
                                          margin: "5px 0px 0px 6px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {eventArray.length > 1 && (
                                          <Info>
                                            {eventArray.length - 1} more...
                                          </Info>
                                        )}
                                      </DescriptionMore>
                                    </Stack>
                                    <Stack
                                      direction={"row"}
                                      justifyContent="space-between"
                                      alignItems={"center"}
                                    >
                                      <Stack
                                        direction="row"
                                        alignItems={"center"}
                                        spacing={0.5}
                                      >
                                        <Typography
                                          style={{
                                            fontSize: "12px",
                                            color: "#fff",
                                          }}
                                        >
                                          {val?.occupancyPercentage.toFixed(2)}%
                                        </Typography>
                                        {val?.hotelDemandLevelSymbol !==
                                          undefined &&
                                        val?.hotelDemandLevelSymbol.length >
                                          0 ? (
                                          <Box
                                            style={{
                                              borderRadius: "50%",
                                              background: val?.color
                                                ? val?.color
                                                : "rgb(80,114,199)",
                                              width: "25px",
                                              height: "25px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              border:
                                                "1px solid rgb(76,95,115)",
                                            }}
                                          >
                                            <p
                                              style={{
                                                fontSize: "12px",
                                                color: "#fff",
                                              }}
                                            >
                                              {val?.hotelDemandLevelSymbol}
                                            </p>
                                          </Box>
                                        ) : (
                                          ""
                                        )}
                                      </Stack>
                                      {val?.systemDemandLevelSymbol !==
                                        undefined &&
                                      val?.systemDemandLevelSymbol.length >
                                        0 ? (
                                        <Box
                                          style={{
                                            borderRadius: "50%",
                                            background: val?.systemDemandLevelColor
                                              ? val?.systemDemandLevelColor
                                              : "rgb(80,114,199)",
                                            width: "25px",
                                            height: "25px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            border: "1px solid rgb(76,95,115)",
                                          }}
                                        >
                                          <p
                                            style={{
                                              fontSize: "12px",
                                              color: "#fff",
                                            }}
                                          >
                                            {val?.systemDemandLevelSymbol}
                                          </p>
                                        </Box>
                                      ) : (
                                        ""
                                      )}
                                    </Stack>
                                  </Stack>
                                </Paper>
                              </Cell>
                            </TextOnlyTooltipDescriptionPopover>
                          ) : (
                            <Cell
                              key={index}
                              {...bindTrigger(popupState)}
                              onClick={() => setPopOverIdx(index)}
                              style={{
                                cursor: "pointer",
                                userSelect: "none",
                              }}
                            >
                              <Paper
                                elevation={5}
                                style={{
                                  height: "121px",
                                  background: val?.cityColor,
                                  borderRadius: "10px",
                                }}
                              >
                                <Stack
                                  direction={"column"}
                                  style={{
                                    padding: "8px 10px",
                                  }}
                                  justifyContent="space-evenly"
                                >
                                  <Stack
                                    direction={"row"}
                                    justifyContent="space-between"
                                  >
                                    <Typography
                                      style={{
                                        fontSize: "18px",
                                        color:
                                          val?.isActual === false
                                            ? "black"
                                            : "#fff",
                                      }}
                                      onMouseEnter={(e) => {
                                        handleRowHover(val.date, e);
                                      }}
                                    >
                                      {val?.date.slice(8, 10)}
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: "18px",
                                        color: "#fff",
                                      }}
                                    >
                                      {val?.cityDemandLevelSymbol}
                                    </Typography>
                                  </Stack>
                                  <Stack
                                    direction={"column"}
                                    alignItems="flex-start"
                                    style={{ height: "52px" }}
                                    textAlign="center"
                                  >
                                    <Description
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      {val?.description
                                        .split("####")
                                        .map((event, idx) =>
                                          event ? (
                                            <Fragment key={idx}>
                                              {idx < 1 && (
                                                <SingleEventContainer>
                                                  <Dot></Dot>
                                                  <EventItem
                                                    style={{
                                                      marginTop: "2px",
                                                    }}
                                                  >
                                                    {event}
                                                  </EventItem>
                                                </SingleEventContainer>
                                              )}
                                            </Fragment>
                                          ) : (
                                            <div key={idx}></div>
                                          )
                                        )}
                                    </Description>
                                    <DescriptionMore
                                      style={{
                                        margin: "5px 0px 0px 6px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {val.description.split("####").length >
                                        1 && (
                                        <Info>
                                          {val.description.split("####")
                                            .length - 1}{" "}
                                          more...
                                        </Info>
                                      )}
                                    </DescriptionMore>
                                  </Stack>
                                  <Stack
                                    direction={"row"}
                                    justifyContent="space-between"
                                    alignItems={"center"}
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems={"center"}
                                      spacing={0.5}
                                    >
                                      <Typography
                                        style={{
                                          fontSize: "12px",
                                          color: "#fff",
                                        }}
                                      >
                                        {val?.occupancyPercentage.toFixed(2)}%
                                      </Typography>
                                      {val?.hotelDemandLevelSymbol !==
                                        undefined &&
                                      val?.hotelDemandLevelSymbol.length > 0 ? (
                                        <Box
                                          style={{
                                            borderRadius: "50%",
                                            background: val?.color
                                              ? val?.color
                                              : "rgb(80,114,199)",
                                            width: "25px",
                                            height: "25px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            border: "1px solid rgb(76,95,115)",
                                          }}
                                        >
                                          <p
                                            style={{
                                              fontSize: "12px",
                                              color: "#fff",
                                            }}
                                          >
                                            {val?.hotelDemandLevelSymbol}
                                          </p>
                                        </Box>
                                      ) : (
                                        ""
                                      )}
                                    </Stack>
                                    {val?.systemDemandLevelSymbol !==
                                      undefined &&
                                    val?.systemDemandLevelSymbol.length > 0 ? (
                                      <Box
                                        style={{
                                          borderRadius: "50%",
                                          background: val.systemDemandLevelColor
                                            ? val.systemDemandLevelColor
                                            : "rgb(80,114,199)",
                                          width: "25px",
                                          height: "25px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          border: "1px solid rgb(76,95,115)",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize: "12px",
                                            color: "#fff",
                                          }}
                                        >
                                          {val?.systemDemandLevelSymbol}
                                        </p>
                                      </Box>
                                    ) : (
                                      ""
                                    )}
                                  </Stack>
                                </Stack>
                              </Paper>
                            </Cell>
                          )}
                          {addEvent && (
                            <Popover
                              {...bindPopover(popupState)}
                              open={popOverIdx === index}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "center",
                                horizontal: "center",
                              }}
                              key={popOverIdx}
                            >
                              <AddEventsModel
                                generateBatchBaseDemandLevels={
                                  generateBatchBaseDemandLevels
                                }
                                isApiHit={isApiHit}
                                demandLevels={demandLevels}
                                popOverIdx={popOverIdx}
                                onChange={onChange}
                                setPopOverIdx={setPopOverIdx}
                                item={val}
                                addDemandLevel={addDemandLevel}
                                addCityDemandLevel={addCityDemandLevel}
                                Loading={Loading}
                                getData={getData}
                                eventArray={eventArray}
                              />
                            </Popover>
                          )}
                        </Fragment>
                      )}
                    </PopupState>
                  ) : (
                    <Cell
                      key={index}
                      onClick={() => setPopOverIdx(index)}
                      style={{ cursor: "pointer", userSelect: "none" }}
                    >
                      {/* <Paper
                          elevation={5}
                          style={{
                            height: "121px",
                            background: val?.cityColor,
                            borderRadius: "10px",
                          }}
                        ></Paper> */}
                    </Cell>
                  );
                })}
            </GridBox>
          ) : (
            <Nodata />
          )
        ) : (
          <Stack
            justifyContent={"center"}
            sx={{
              width: "60%",
              height: "calc(100vh - 250px)",
            }}
          >
            <LinearProgress />
          </Stack>
        )}
      </Calendar>

      <Draggable>
        {noData ? (
          disablePrevDate ? (
            <Popover
              id={id}
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              sx={{
                "& .MuiPopover-paper": {
                  border: "1px solid #000000",
                  maxWidth: "587px",
                  maxHeight: "435px",
                },
              }}
            >
              <Stack direction={"row"} p={2} gap={4}>
                <Typography>No Remark</Typography>
                <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
              </Stack>
            </Popover>
          ) : futureRemark ? (
            <>
              <div style={{ cursor: "default" }}></div>
            </>
          ) : (
            <Popover
              id={id}
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              sx={{
                "& .MuiPopover-paper": {
                  border: "1px solid #000000",
                  maxWidth: "587px",
                  maxHeight: "435px",
                },
              }}
            >
              <>
                <Stack direction="row" mt={3}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      flexGrow: 1,
                      textAlign: "center",
                      cursor: "move",
                    }}
                  >
                    Remark of{" "}
                    {getDateDDMMYYYY(popOver?.remark?.date ?? popOverDate)}{" "}
                  </Typography>
                  <CloseIcon
                    sx={{ cursor: "pointer", mr: "10px" }}
                    onClick={handleClose}
                  />
                </Stack>

                <Stack direction="row" gap={"141px"} mt={3} ml={3} mr={3}>
                  <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
                    Last Edited By: {popOver?.userName}
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
                    Last edited Time:{" "}
                  </Typography>
                </Stack>

                <TextField
                  sx={{ ml: 3, mt: 2, mr: 3 }}
                  multiline
                  fullWidth
                  value={popOver?.remark?.remark}
                  onChange={(e) => {
                    setPopOver({
                      ...popOver,
                      remark: { ...popOver?.remark, remark: e.target.value },
                    });
                  }}
                  variant="standard"
                  placeholder="Type your remark..."
                  InputProps={{ disableUnderline: "true" }}
                  onInput={handleInput}
                />

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    padding: "1px",
                  }}
                >
                  {selectedImages?.map((img, index) => (
                    <Box
                      mt={3}
                      key={index}
                      style={{
                        position: "relative",
                        height: "100px",
                        width: "100px",
                        marginLeft: "20px",
                      }}
                    >
                      <CloseIcon
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          top: "7px",
                          right: "-17px",
                          color: "white",
                          backgroundColor: "#6C7270",
                          borderRadius: "50%",
                          height: "17px",
                          width: "18px",
                        }}
                        onClick={() => handleDeleteImage(index)}
                      />
                      <img
                        src={img.imageURL}
                        alt="Preview"
                        style={{
                          height: "100px",
                          width: "100px",
                          objectFit: "cover",
                          margin: 1,
                        }}
                      />
                    </Box>
                  ))}
                </div>

                <Stack direction="row" style={{ display: "flex" }} mt={7} p={2}>
                  <label>
                    <TextField
                      type="file"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      sx={{ visibility: "hidden" }}
                      variant="standard"
                      onChange={handleImagePreview}
                      multiple
                    />
                    <Stack direction="row">
                      <AddPhotoAlternateIcon
                        onClick={handleImagePreview}
                        sx={{ color: "grey", cursor: "pointer" }}
                      />
                      <Typography
                        sx={{
                          fontWeight: 500,
                          color: "grey",
                          ml: 1,
                          cursor: "pointer",
                        }}
                      >
                        Add Image
                      </Typography>
                    </Stack>
                  </label>
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSaveChanges}
                      sx={{
                        mt: 4,
                        borderRadius: 2,
                        textTransform: "none",
                        height: 40,
                        alignSelf: "end",
                        fontSize: "14px",
                      }}
                    >
                      Save Changes
                    </Button>
                  </>
                </Stack>
              </>
            </Popover>
          )
        ) : (
          <Popover
            id={id}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{
              "& .MuiPopover-paper": {
                border: "1px solid #000000",
                maxWidth: "587px",
                maxHeight: "712px",
                p: 2,
              },
            }}
          >
            <>
              <Stack direction="row" mt={1}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    flexGrow: 1,
                    textAlign: "center",
                    cursor: "move",
                  }}
                >
                  Remark of {getDateDDMMYYYY(popOver?.remark?.date)}{" "}
                </Typography>
                <CloseIcon
                  sx={{ cursor: "pointer", mr: "10px" }}
                  onClick={handleClose}
                />
              </Stack>

              <Stack
                direction="row"
                mt={3}
                ml={1}
                mr={1}
                gap={"141px"}
                justifyContent={"space-between"}
              >
                <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
                  Last Edited By: {popOver?.userName ?? ""}{" "}
                </Typography>
                <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
                  Last edited Time: {getHrMin(popOver?.remark?.createdAt ?? "")}{" "}
                </Typography>
              </Stack>

              <TextField
                disabled={!isEditing}
                sx={{
                  ml: 1,
                  mt: 2,
                  mr: 1,
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                multiline
                fullWidth
                value={
                  popOver?.remark?.remark === ""
                    ? "   "
                    : popOver?.remark?.remark
                }
                onChange={(e) => {
                  setIsEditing(true);
                  setPopOver({
                    ...popOver,
                    remark: { ...popOver?.remark, remark: e.target.value },
                  });
                }}
                variant="standard"
                placeholder="Type your remark..."
                InputProps={{ disableUnderline: "true" }}
                onInput={handleInput}
              />

              {isEditing ? (
                <label>
                  <TextField
                    type="file"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    sx={{ visibility: "hidden" }}
                    variant="standard"
                    onChange={handleImagePreview}
                    multiple
                  />
                  <Stack direction="row" justifyContent={"end"}>
                    <AddPhotoAlternateIcon
                      onClick={handleImagePreview}
                      sx={{ color: "grey", cursor: "pointer" }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 500,
                        color: "grey",
                        ml: 1,
                        cursor: "pointer",
                      }}
                    >
                      Add Image
                    </Typography>
                  </Stack>
                </label>
              ) : (
                ""
              )}

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  padding: "1px",
                }}
              >
                {[...(!!popOver?.images?.length ? popOver?.images : [])].map(
                  (img, index) => (
                    <Box
                      mt={3}
                      key={index}
                      style={{
                        position: "relative",
                        height: "260px",
                        width: "500px",
                        // marginLeft: "20px",
                      }}
                    >
                      {isEditing ? (
                        <CloseIcon
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: "7px",
                            right: "-17px",
                            color: "white",
                            backgroundColor: "#6C7270",
                            borderRadius: "50%",
                            height: "17px",
                            width: "18px",
                          }}
                          onClick={() => handleDeleteImage(index, img)}
                        />
                      ) : (
                        ""
                      )}
                      <img
                        src={img.imageURL}
                        alt="Preview"
                        style={{
                          height: "258px",
                          width: isEditing ? "500px" : "480px",
                          objectFit: "cover",
                          margin: 1,
                        }}
                      />
                    </Box>
                  )
                )}
              </div>

              {isEditing ? (
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDiscard}
                    sx={{
                      mt: 4,
                      borderRadius: 2,
                      textTransform: "none",
                      height: 40,
                      fontSize: "14px",
                    }}
                  >
                    Discard
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveChanges}
                    sx={{
                      mt: 4,
                      borderRadius: 2,
                      textTransform: "none",
                      height: 40,
                      fontSize: "14px",
                    }}
                  >
                    Save Changes
                  </Button>
                </Stack>
              ) : (
                !disablePrevDate && (
                  <Stack direction="row" mt={7} sx={{ justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleEditBtn}
                      sx={{
                        mt: 4,
                        borderRadius: 2,
                        textTransform: "none",
                        height: 40,
                        fontSize: "14px",
                      }}
                    >
                      Edit
                    </Button>
                  </Stack>
                )
              )}
            </>
          </Popover>
        )}
      </Draggable>
    </>
  );
}
