import React, { useEffect } from "react";
import { Box, IconButton, styled } from "@mui/material";
import { useWarning } from "../Provider/context";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../sdk";
import { KeyboardBackspace } from "@mui/icons-material";

const Test = styled(Box)`
  &:-webkit-any-link {
    text-decoration: none;
  }
  font: normal normal bold 14px/18px Roboto;
  letter-spacing: 0px;

  &:hover {
    cursor: pointer;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
  color: #306fbc;
`;
const TabNav = (props) => {
  const { formEdit, setformEdit, handleRedirect } = useWarning();
  const { children, to, activeStyle } = props;

  return (
    <Test
      onClick={() => {
        handleRedirect(to);
      }}
      style={{
        ...activeStyle,
      }}
    >
      {children}
    </Test>
  );
};
const Header = styled(Box)`
  display: flex;

  align-items: center;
  width: 100%;
  height: 40px;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  margin: auto;
  margin-bottom: 20px;
  gap: 20px;
`;
const HeaderCard = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: white;
  height: 40px;
  width: 120px;
  border-radius: 8px;
`;

export const PrimarySetup = () => {
  const { hotelId } = useParams();
  const { permission, token, version, currentHotel } = useAuth();
  const { formEdit, setformEdit, handleRedirect } = useWarning();
  const history = useHistory();
  let viewEventColours = null,
    viewAllEvents = null;
  useEffect(() => {
    if (token && hotelId) {
      window.scrollTo(0, 0);
    } else {
      return;
    }
  }, [token, hotelId]);
  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "viewEventColours") {
        viewEventColours = true;
      }
      if (permission[key].name === "viewAllEvents") {
        viewAllEvents = true;
      }
    }
  }

  return (
    <Header>
      <IconButton
        disableRipple
        onClick={() => {
          history.push("/managing_organization");
        }}
        sx={{
          padding: "0px",
          color: "#808080",
        }}
      >
        <KeyboardBackspace fontSize="large" />
      </IconButton>
      <HeaderCard>
        <TabNav
          exact
          to={`/event-setup/keywords`}
          activeStyle={
            history.location.pathname === `/event-setup/keywords`
              ? {
                  color: "#ffffff",
                  background: "#306FBC",
                  boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                  borderRadius: "8px",
                }
              : {}
          }
        >
          Keywords
        </TabNav>
      </HeaderCard>

      {viewEventColours && (
        <HeaderCard>
          <TabNav
            exact
            to={`/event-setup/event-colours`}
            activeStyle={
              history.location.pathname === `/event-setup/event-colours`
                ? {
                    color: "#ffffff",
                    background: "#306FBC",
                    boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                    borderRadius: "8px",
                  }
                : {}
            }
          >
            Event Colours
          </TabNav>
        </HeaderCard>
      )}
      {viewAllEvents && (
        <HeaderCard>
          <TabNav
            exact
            to={`/event-setup/all-events`}
            activeStyle={
              history.location.pathname === `/event-setup/all-events`
                ? {
                    color: "#ffffff",
                    background: "#306FBC",
                    boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                    borderRadius: "8px",
                  }
                : {}
            }
          >
            All Events
          </TabNav>
        </HeaderCard>
      )}
    </Header>
  );
};
