import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import moment from "moment";
import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import { useQueryParam } from "../../sdk";
import { Page } from "../Styles";
import GraphTable from "./GraphTable";

const labels = Array.from({ length: 15 }, (_, i) => (i + 1) * 2)?.map(String);
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const hotelColorPalette = [
  "#886F6F",
  "#EE33BB",
  "#D67D3E",
  "#B8405E",
  "#BF9270",
  "#694E4E",
  "#5B2C6F",
  "#7FFF00",
  "#455A64",
];

export const GraphRate = ({ tableData, pastDateData }) => {
  const { competitors } = tableData;
  const { date } = useQueryParam();

  const modifiedPastDateData = useMemo(() => {
    const customData = {};
    const customHotel = {};
    pastDateData.forEach((element) => {
      const date = element?.createdAt.slice(0, 10);
      const hotelCode = element?.hotelCode;
      customHotel[hotelCode] = { ...element };
      if (customData[hotelCode] && !customData[hotelCode][date]) {
        customData[hotelCode] = {
          ...customData[hotelCode],
          [date]: { ...element },
        };
      } else {
        customData[hotelCode] = { [date]: { ...element } };
      }
    });
    return { hotelDetail: customHotel, data: customData };
  }, [pastDateData]);

  console.log(modifiedPastDateData);

  const graphLabels = useMemo(() => {
    const label = [];
    const graphDate = [];
    for (let i = 0; i < 30; i++) {
      const selectedDate = date ? new Date(date) : new Date();
      selectedDate.setDate(selectedDate.getDate() - i);
      const month = selectedDate.getMonth();
      label.push(
        `0${selectedDate.getDate()}`.slice(-2) + "/" + `0${month + 1}`.slice(-2)
      );
      graphDate.push(selectedDate);
    }
    return { label: label.reverse(), graphDate: graphDate.reverse() };
  }, [date]);

  const max = useMemo(() => {
    let max = 0;
    Object.entries(modifiedPastDateData?.data).forEach(
      ([key, value], index) => {
        graphLabels.graphDate.forEach((date) => {
          const formatDate = date.toISOString().slice(0, 10);
          const price = value[formatDate]?.onsiteRate;
          if (price > max) max = price;
        });
      }
    );
    if (max > 2000) {
      const a = Math.floor(max / 2000);
      max = 2000 * (a + 1);
    }
    return max;
  }, [modifiedPastDateData, graphLabels]);

  const options = useMemo(
    () => ({
      responsive: true,
      legend: {
        position: "bottom",
        labels: {
          fontFamily: "Roboto",
          padding: 40,
          fontSize: 14,
          fontWeight: 500,
          fontColor: "black",
          boxWidth: 15,
        },
      },
      onHover: (event, chartElement) => {
        event.target.style.cursor = chartElement[0] ? "pointer" : "default";
      },
      elements: {
        line: {
          fill: false, //fills color in legend box
          // tension: 0, makes graph lines pointed
        },
      },
      maintainAspectRatio: false,
      // tooltips: {
      //     bodyFontFamily: "Roboto",
      //     backgroundColor: "black",
      //     callbacks: {
      //         label: 'isha'
      //     },
      // },
      //used to hide tooltips that are displayed on hovering over the canvas or graph
      tooltips: { enabled: false },
      scales: {
        xAxes: [
          {
            offset: true,
            display: true,
            gridLines: {
              display: true,
              drawTicks: true,
              drawOnChartArea: false,
            },
            ticks: {
              fontFamily: "Roboto",
              fontColor: "#777777",
              fontSize: 13,
              fontWeight: 400,
            },
            scaleLabel: {
              display: true,
              labelString: "Lead Time",
              fontSize: 14,
              fontWeight: 400,
              fontColor: "#777777",
              fontFamily: "Roboto",
            },
          },
        ],
        yAxes: [
          {
            type: "linear",
            display: true,
            position: "left",
            ticks: {
              beginAtZero: false,
              fontFamily: "Roboto",
              fontColor: "#777777",
              fontSize: 13,
              fontWeight: 400,
              // suggestedMin: 120,
              // suggestedMax: 180,
              min: 40,
              max: max > 2000 ? max : 2000,
            },
            scaleLabel: {
              display: true,
              labelString: "Rate",
              fontSize: 14,
              fontWeight: 400,
              fontColor: "#777777",
              fontFamily: "Roboto",
            },
          },
        ],
      },
    }),
    [max]
  );

  const graphData = useMemo(
    () => ({
      labels: graphLabels.label,
      datasets: Object.entries(modifiedPastDateData?.data)
        .map(([key, value], index) => {
          const hotel = modifiedPastDateData?.hotelDetail[key];
          return {
            label: hotel?.hotelName,
            borderColor: hotel?.isMainHotel
              ? "#0012B1"
              : hotel?.isMainHotel
              ? "#0012B1"
              : hotelColorPalette[index],
            isMainHotel: hotel?.isMainHotel,
            pointBorderColor: hotel?.isMainHotel
              ? "#0012B1"
              : hotelColorPalette[index],
            pointBackgroundColor: hotel?.isMainHotel
              ? "#0012B1"
              : hotelColorPalette[index],
            data: graphLabels.graphDate?.map((date) => {
              const formatDate = date.toISOString().slice(0, 10);
              return value[formatDate]?.onsiteRate;
            }),
          };
        })
        .sort((a1, a2) => (a1.isMainHotel ? -1 : 1)),
    }),
    [graphLabels, modifiedPastDateData]
  );
  return (
    <Page>
      <Box
        sx={{
          background: "white",
          width: "95%",
          boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
        }}
      >
        <Stack
          direction="row"
          p={"12px"}
          justifyContent={"flex-end"}
          spacing={80}
        >
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: 18,
              fontColor: "#212121",
              fontWeight: 500,
              fontStyle: "normal",
            }}
          >
            Rates Evolution
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: 14,
              fontWeight: 700,
              fontColor: "black",
              fontStyle: "normal",
            }}
          >
            {moment(date ?? new Date()).format("DD/MM/yyyy")}
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          background: "white",
          height: "339px",
          width: "95%",
          boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
        }}
      >
        <Line options={options} data={graphData} height={339} width={null} />
      </Box>
      <GraphTable tableData={tableData} />
    </Page>
  );
};
