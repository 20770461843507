import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import NetworkError from "../NetworkError";
import {
  apiFetchArgsDefaults,
  canRetryRequest,
  getField,
  HTTPMethod,
  isEmpty,
  isObject,
  logout,
  parseRequestInit,
  routeAndOptions,
  sleep,
} from "../NetworkUtils";
import { useAuth } from "./useAuth";

export const useFetch = (token) => {
  const history = useHistory();
  const [cloneMessage, setCloneMessage] = useState(null);
  const globalInterceptors = React.useMemo(
    () => ({
      request: (options, url, path, route) => {
        if (token) {
          options.headers.append("token", token);
        }
        return options;
      },
    }),
    [token]
  );

  const apiFetchArgs = React.useCallback(
    (urlOrOptions) => {
      const url = (() => {
        if (typeof urlOrOptions === "string") {
          return urlOrOptions;
        } else if (isObject(urlOrOptions) && urlOrOptions.url) {
          return urlOrOptions.url;
        } else {
          return apiFetchArgsDefaults.url;
        }
      })();

      const data = getField("data", urlOrOptions);
      const path = getField("path", urlOrOptions);
      const timeout = getField("timeout", urlOrOptions);
      const retries = getField("retries", urlOrOptions);
      const retryDelay = getField("retryDelay", urlOrOptions);
      const onAbort = getField("onAbort", urlOrOptions);
      const onTimeout = getField("onTimeout", urlOrOptions);

      const interceptors = (() => {
        const attachTokenHeaderInterceptor = { ...globalInterceptors };
        if (isObject(urlOrOptions) && isObject(urlOrOptions.interceptors)) {
          if (urlOrOptions.interceptors.request) {
            attachTokenHeaderInterceptor.request =
              urlOrOptions.interceptors.request;
          }
          if (urlOrOptions.interceptors.response) {
            attachTokenHeaderInterceptor.response =
              urlOrOptions.interceptors.response;
          }
        }
        return attachTokenHeaderInterceptor;
      })();

      const requestInit = (() => {
        const requestInitOptions = isObject(urlOrOptions) ? urlOrOptions : {};

        const requestInit = parseRequestInit(requestInitOptions);

        return {
          ...requestInit,
          headers: {
            ...requestInit.headers,
          },
        };
      })();

      return {
        customOptions: {
          url,
          path,
          interceptors,
          timeout,
          retries,
          retryDelay,
          onAbort,
          onTimeout,
        },
        requestInit,
        defaults: {
          data,
        },
      };
    },
    [globalInterceptors]
  );

  const apiFetch = React.useCallback(
    (...args) => {
      const { customOptions, requestInit, defaults } = apiFetchArgs(...args);
      const {
        url,
        path,
        interceptors,
        timeout,
        retries,
        retryDelay,
        onTimeout,
        onAbort,
      } = customOptions;
      //                      todo----remove it before commit----------------------

      let error, controller;
      let res = {};
      let timedout = false;
      let attempts = 0;
      let data = defaults.data;

      const makeFetch = (method) => {
        // eslint-disable-next-line complexity, max-statements
        const doFetch = async (routeOrBody) => {
          try {
            controller = new window.AbortController();
            controller.signal.onabort = onAbort;
          } catch {
            // swallow this error, non-standard browsers may not support abortcontroller.
            // I did add a pollyfill but this is a last ditch attempt.
          }

          const { route, options } = await routeAndOptions(
            requestInit,
            url,
            path,
            method,
            controller,
            routeOrBody,
            interceptors.request
          );

          const timer =
            timeout > 0 &&
            setTimeout(() => {
              timedout = true;
              controller.abort();
              if (onTimeout) {
                onTimeout();
              }
            }, timeout);

          try {
            const finalUrl = path.includes("https://")
              ? path
              : `${url}${path}${route}`;
            const response = await fetch(finalUrl, options);
            let parsedData;

            res = response.clone();

            if ([401, 403].includes(response.status)) {
              let parsedData = {};
              try {
                if (response.status === 401) {
                  logout();
                }
                parsedData = await response.json();
              } catch (e) {
                parsedData = {};
              }
              throw new NetworkError(
                response.status === 401 ? "Unauthorized" : "Status",
                customOptions,
                res,
                parsedData?.message
              );
            }

            if (
              (response.status >= 401 || response.status < 200) &&
              response.status !== 500
            ) {
              let message = await res.text();
              try {
                message = JSON.parse(message);
              } catch {
                // Swollow the error. It's not json
              }

              throw Object.assign(
                new NetworkError("Status", customOptions, res),
                { message }
              );
              //throw new NetworkError('Status', customOptions, res)
            }

            if (response.status === 301) {
              setCloneMessage(
                "Cloning in Process, Please Wait for Sometime Before Accessing this Hotel"
              );
              window.history.replaceState(null, "", "/");
              window.location.href = "/managing_organization";
            }

            try {
              parsedData = await response.json();
            } catch (err) {
              parsedData = {};
            }

            parsedData =
              defaults.data && isEmpty(parsedData) ? defaults.data : parsedData;
            res.data = parsedData;

            res = interceptors.response ? interceptors.response(res) : res;

            data = res.data;
          } catch (err) {
            if (canRetryRequest(err?.response?.status) && retries > attempts) {
              return await retry(retryDelay, routeOrBody);
            }

            if (attempts >= retries && timedout) {
              error = {
                name: "AbortError",
                message: "Timeout Error",
                response: res,
              };
            }
            error = err;
            throw err;
          } finally {
            timedout = false;
            if (timer) {
              clearTimeout(timer);
            }
          }

          if (attempts === retries) {
            attempts = 0;
          }

          return { data, response: res, error };
        };

        async function retry(delay = 200, routeOrBody) {
          attempts += 1;
          await sleep(delay);
          return await doFetch(routeOrBody);
        }

        return doFetch;
      };

      const request = {
        get: makeFetch(HTTPMethod.GET),
        post: makeFetch(HTTPMethod.POST),
        patch: makeFetch(HTTPMethod.PATCH),
        put: makeFetch(HTTPMethod.PUT),
        del: makeFetch(HTTPMethod.DELETE),
        delete: makeFetch(HTTPMethod.DELETE),
        abort: () => controller && controller.abort(),
      };

      return { ...request };
    },
    [apiFetchArgs]
  );

  return {
    apiFetch,
    cloneMessage,
    setCloneMessage,
  };
};

// // Anytime a promise is rejected without a specific catch, like an api call
// // this will catch possible 400 errors and log a use out if needed.
// const onUnhandledRejection = (event) => {
//   if (event.reason instanceof NetworkError) {
//     const { response } = event.reason;
//     if (response && response.status === 401) {
//       logout();
//     }
//   }
// };

// window.addEventListener("unhandledrejection", (event) =>
//   onUnhandledRejection(event)
// );
