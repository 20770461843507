import {
  Button,
  Snackbar,
  SnackbarContent,
  Modal,
  Box as BX,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRight,
  Publish,
  ViewList,
  ViewModule,
} from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/system";
import { ErrorPage, LoadingPage, NoAccess, Nodata, useAuth } from "../sdk";
import PricingRecommends from "./components/PricingRecommends";
import { ModelBody } from "./components/UploadModal.js";
import {
  ActualsPage,
  CalenderIcon,
  Header,
  PRWrapper,
  UploadContainer,
} from "./Styles";
import { PreciumDisabledPage } from "../sdk/components/PreciumDiabledPage";
export const UploadBtnNew = styled(Button)`
  text-align: right;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #343434;
  display: flex;
  align-items: flex-end;
  text-transform: capitalize;
  padding: 6px 16px 6px 16px;
  height: 32px;
  background: #306fbc;
  margin-right: 100px;
  &:hover {
    background: #306fbc;
  }
`;
export const ToggleBox = styled(BX)`
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;
  box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  color: #306fbc;
`;

export const DisplayDate = styled(BX)`
  width: 175px;
  font: normal normal normal 20px/25px Roboto;
  letter-spacing: 0px;
  color: #110641;
  margin-left: 16px;
`;

export const Box = styled(BX)`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 9px 0px 9px 12px;
  margin-right: 16px;
  height: 38px;
`;

export const Text = styled(BX)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
  margin: auto;
  margin-left: 26px;
  padding-top: 2px;
  white-space: nowrap;
  width: 100%;
`;

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const PastData = ({ setPageHeader }) => {
  const [open, setOpen] = useState(false);
  const handleCloseStatus = () => {
    setOpen(false);
  };
  const [Loading, setLoading] = useState(false);
  const [haveData, setHaveData] = useState(true);
  const [networkMsg, setnetworkMsg] = useState(null);
  const { token, permission, authFetch, currentHotel } = useAuth();
  const [openSelectbar, setOpenSelectBar] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [filename, setFileName] = useState();
  const formdata = useRef(new FormData());
  const { hotelId } = useParams();
  const [booking, setBooking] = useState([]);
  const [Error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [checkSelectedData, setSelectedData] = useState(false);
  let currdate =
    new Date().getFullYear() +
    " " +
    (new Date().getMonth() + 1 < 10
      ? "0" + (new Date().getMonth() + 1)
      : new Date().getMonth() + 1);

  const [curr_index, setCurrIndex] = useState(-1);
  let dataHashmap = new Map();
  const [view, setView] = useState("list");

  const handleChangeNew = (nextView) => {
    setView(nextView);
  };
  const [month, setMonth] = useState(0);
  const [isLeftDisabled, setIsLeftDisabled] = useState(false);
  const [isRightDisabled, setIsRightDisabled] = useState(false);
  const [maxMonth, setmaxMonth] = useState(0);
  const [perMonthEvents, setPerMonthsEvents] = useState([[]]);

  const toggleleft = () => {
    setMonth((prev) => {
      if (prev > 0) {
        setIsLeftDisabled(false);
        setIsRightDisabled(false);
        return prev - 1;
      } else {
        setIsLeftDisabled(true);
        setIsRightDisabled(false);
        return prev;
      }
    });
  };
  const toggleright = () => {
    setMonth((prev) => {
      if (prev < maxMonth) {
        setIsRightDisabled(false);
        setIsLeftDisabled(false);
        return prev + 1;
      } else {
        setIsRightDisabled(true);
        setIsLeftDisabled(false);
        return prev;
      }
    });
  };

  let pastDataUpload = null,
    pastDataView = null;
  useEffect(() => {
    if (token && hotelId) {
      window.scrollTo(0, 0);
    } else {
      return;
    }
  }, [token, hotelId]);

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "pastDataUpload") {
        pastDataUpload = permission[key];
      }
      if (permission[key].name === "pastDataView") {
        pastDataView = permission[key];
      }
    }
  }
  async function refreshData() {
    setLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/past-arr`,
    });
    const { data } = await get();
    if (data) {
      if (data.length > 0) {
        setHaveData(true);
        const bookingData = data?.sort((a, b) => a.date - b.date);
        var currMonth = new Date(bookingData[0].date).getMonth();
        var bookingArray = [];
        var i = 0;
        let totalRooms = 0;
        let noValueForRooms = true;
        let totalRevenue = 0;
        let noValueForRevenue = true;
        bookingArray.push([]);
        bookingData.forEach((element, index) => {
          const data = {
            ...element,
            revenue:
              element.actualRoomsBooked === -1 || element.averageRoomRate === -1
                ? -1
                : Math.round(
                    element.actualRoomsBooked * element.averageRoomRate
                  ),
            averageRoomRate:
              element.averageRoomRate === -1
                ? -1
                : parseFloat(element.averageRoomRate).toFixed(2),
          };
          if (new Date(data.date).getMonth() === currMonth) {
            bookingArray[i].push(data);
            totalRooms =
              totalRooms +
              (element.actualRoomsBooked === -1
                ? 0
                : element.actualRoomsBooked);
            if (element.actualRoomsBooked !== -1) {
              noValueForRooms = false;
            }
            totalRevenue =
              totalRevenue +
              (element.actualRoomsBooked === -1 ||
              element.averageRoomRate === -1
                ? 0
                : element.actualRoomsBooked * element.averageRoomRate);
            if (
              element.actualRoomsBooked !== -1 ||
              element.averageRoomRate !== -1
            ) {
              noValueForRevenue = false;
            }
            if (bookingData.length - 1 === index) {
              bookingArray[i].push({
                totalRooms: noValueForRooms === true ? -1 : totalRooms,
                totalRevenue:
                  noValueForRevenue === true ? -1 : Math.round(totalRevenue),
                totalARR:
                  noValueForRevenue === true || noValueForRooms === true
                    ? -1
                    : parseFloat(totalRevenue / totalRooms).toFixed(2),
              });
            }
          } else {
            bookingArray[i].push({
              totalRooms: noValueForRooms === true ? -1 : totalRooms,
              totalRevenue:
                noValueForRevenue === true ? -1 : Math.round(totalRevenue),
              totalARR:
                noValueForRevenue === true || noValueForRooms === true
                  ? -1
                  : parseFloat(totalRevenue / totalRooms).toFixed(2),
            });
            currMonth = new Date(element.date).getMonth();
            totalRooms = 0;
            noValueForRooms = true;
            totalRooms =
              totalRooms +
              (element.actualRoomsBooked === -1
                ? 0
                : element.actualRoomsBooked);
            totalRevenue = 0;
            noValueForRevenue = true;
            if (element.actualRoomsBooked !== -1) {
              noValueForRooms = false;
            }
            if (
              element.actualRoomsBooked !== -1 ||
              element.averageRoomRate !== -1
            ) {
              noValueForRevenue = false;
            }
            totalRevenue =
              totalRevenue +
              (element.actualRoomsBooked === -1 ||
              element.averageRoomRate === -1
                ? 0
                : element.actualRoomsBooked * element.averageRoomRate);
            bookingArray.push([]);
            i++;
            bookingArray[i].push(data);
            if (bookingData.length - 1 === index) {
              bookingArray[i].push({
                totalRooms: noValueForRooms === true ? -1 : totalRooms,
                totalRevenue:
                  noValueForRevenue === true ? -1 : Math.round(totalRevenue),
                totalARR:
                  noValueForRevenue === true || noValueForRooms === true
                    ? -1
                    : parseFloat(totalRevenue / totalRooms).toFixed(2),
              });
            }
          }
        });
        setBooking(bookingArray);
        setPerMonthsEvents(bookingArray);

        let position = 0;
        bookingArray.map((data, idx) => {
          dataHashmap[
            data[0]?.date.split("-")[0] + " " + data[0]?.date.split("-")[1]
          ] = idx;
          if (
            currdate ===
            data[0]?.date.split("-")[0] + " " + data[0]?.date.split("-")[1]
          ) {
            setMonth(idx);
            position = idx;
          }
        });
        setmaxMonth(bookingArray.length - 1);
        let dateSelected = (
          new Date(bookingArray[position][0]?.date).getFullYear() +
          " " +
          (new Date(bookingArray[position][0]?.date).getMonth() + 1 < 10
            ? "0" + (new Date(bookingArray[position][0]?.date).getMonth() + 1)
            : new Date(bookingArray[position][0]?.date).getMonth() + 1)
        ).toString();
        if (dataHashmap[dateSelected] >= 0) {
          setSelectedData(true);
          setCurrIndex(dataHashmap[dateSelected]);
        } else {
          setSelectedData(false);
          setCurrIndex(-1);
        }
        setData(dataHashmap);
      } else {
        setHaveData(false);
      }
    }
    setLoading(false);
  }
  useEffect(() => {
    if (token) {
      refreshData();
    }
  }, [token, hotelId]);
  const fileGetter = (file) => {
    setFileName(file[0].name);
    handleToggle();
    formdata.current.append("file", file[0]);
  };
  const handleToggle = () => {
    setToggle(!toggle);
  };
  async function fileHandler() {
    setOpenSelectBar(false);
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/upload-past-actuals`,
    });
    const { data } = await post(formdata.current);
    if (data && Object.keys(data).length !== 0) {
      setnetworkMsg("Upload Successful");
      setOpen(true);
      setToggle(false);
      refreshData();
      setError(false);
    } else {
      setnetworkMsg(data?.Message || "Invalid File");
      setOpen(true);
      setError(false);
    }
    formdata.current = new FormData();
  }
  const removeFile = () => {
    setFileName("");
    formdata.current.delete("file");
    setToggle(!toggle);
  };
  useEffect(() => {
    setPageHeader("Past Data");
    return () => {
      setPageHeader("");
    };
  }, []);

  useEffect(() => {
    let dateSelected = (
      new Date(perMonthEvents[month][0]?.date).getFullYear() +
      " " +
      (new Date(perMonthEvents[month][0]?.date).getMonth() + 1 < 10
        ? "0" + (new Date(perMonthEvents[month][0]?.date).getMonth() + 1)
        : new Date(perMonthEvents[month][0]?.date).getMonth() + 1)
    ).toString();
    if (data.length !== 0) {
      if (data[dateSelected] >= 0) {
        setSelectedData(true);
        setCurrIndex(data[dateSelected]);
      } else {
        setSelectedData(false);
        setCurrIndex(-1);
      }
    }
  }, [month]);
  return currentHotel?.isPreciumEnabled ? (
    !Error && pastDataView ? (
      <ActualsPage>
        {pastDataUpload && (
          <Header>
            <div style={{ display: "flex" }}>
              <ToggleButtonGroup
                value={view}
                exclusive
                color="primary"
                style={{
                  marginTop: view === "module" ? "6px" : "0px",
                  marginLeft: "4px",
                  height: "32px",
                  boxShadow: "0px 4px 4px rgba(3, 4, 94, 0.2)",
                  borderRadius: "8px",
                }}
              >
                <ToggleButton
                  value="list"
                  aria-label="list"
                  onClick={() => handleChangeNew("list")}
                >
                  <ViewList style={{ color: "#306fbc" }} />
                </ToggleButton>
                <ToggleButton
                  value="module"
                  aria-label="module"
                  onClick={() => handleChangeNew("module")}
                >
                  <ViewModule style={{ color: "#306fbc" }} />
                </ToggleButton>
              </ToggleButtonGroup>

              {view === "module" && (
                <div style={{ display: "flex" }}>
                  <DisplayDate>
                    <Box>
                      <CalenderIcon />
                      {perMonthEvents[month] && perMonthEvents[month][0] ? (
                        <Text>
                          {
                            months[
                              parseInt(
                                perMonthEvents[month][0].date.split("-")[1]
                              ) - 1
                            ]
                          }
                          {"  "}
                          {perMonthEvents[month][0].date.split("-")[0]}
                        </Text>
                      ) : (
                        ""
                      )}
                    </Box>
                  </DisplayDate>
                  <ToggleBox>
                    {!isLeftDisabled ? (
                      <ChevronLeft
                        onClick={toggleleft}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <ChevronLeft
                        style={{ cursor: "not-allowed" }}
                        color="disabled"
                      />
                    )}
                    {!isRightDisabled ? (
                      <ChevronRight
                        onClick={toggleright}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <ChevronRight
                        style={{ cursor: "not-allowed" }}
                        color="disabled"
                      />
                    )}
                  </ToggleBox>
                </div>
              )}
            </div>

            {view === "module" && (
              <UploadBtnNew
                onClick={() => setOpenSelectBar(true)}
                style={{ color: "#FFFFFF", whiteSpace: "nowrap" }}
              >
                Past Data Uploads <Publish />
              </UploadBtnNew>
            )}
            {view === "list" && (
              <UploadContainer
                style={{ borderRadius: "8px" }}
                onClick={() => setOpenSelectBar(true)}
              >
                <span
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "20px",
                    color: "#306fbc",
                  }}
                >
                  Past Data Uploads
                </span>
                <Publish style={{ color: "#306fbc" }} />
              </UploadContainer>
            )}
          </Header>
        )}

        {!Loading ? (
          haveData ? (
            <PRWrapper>
              <PricingRecommends
                bookings={booking}
                view={view}
                checkSelectedData={checkSelectedData}
                curr_index={curr_index}
              />
            </PRWrapper>
          ) : (
            <Nodata />
          )
        ) : (
          <LoadingPage />
        )}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={open}
          autoHideDuration={3000}
          onClose={handleCloseStatus}
        >
          {networkMsg === "Upload Successful" ? (
            <SnackbarContent
              style={{ backgroundColor: "#228b22" }}
              message={networkMsg}
            />
          ) : (
            <SnackbarContent
              style={{ backgroundColor: "#CA3433" }}
              message={networkMsg}
            />
          )}
        </Snackbar>
        <Modal open={openSelectbar} onClose={() => setOpenSelectBar(false)}>
          <div>
            <ModelBody
              fileHandler={fileHandler}
              fileGetter={fileGetter}
              setOpenSelectBar={setOpenSelectBar}
              removeFile={removeFile}
              filename={filename}
              handleToggle={handleToggle}
              toggle={toggle}
            />
          </div>
        </Modal>
      </ActualsPage>
    ) : !pastDataView ? (
      <NoAccess />
    ) : (
      <ErrorPage />
    )
  ) : (
    <PreciumDisabledPage />
  );
};
export default PastData;
