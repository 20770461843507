import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "./Styles";
import {
  ButtonContainer,
  Container,
  ErrorInfo,
  Form,
  FormC,
  FormContainerBox,
  Header,
  LoginMessage,
  Logo,
  LogoHeader,
  PassWordContainer,
  Text,
  TextField,
} from "./Styles";

export default function ForgotPasswordForm({
  password,
  iswrongEmail,
  setWrongEmail,
}) {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    password({ email, username });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormC>
        <Header>
          <div>
            <LogoHeader>
              <Logo>
                <img
                  src="/assets/PreciumLogo.svg"
                  alt="Logo"
                  width="215px"
                  height="85px"
                />
              </Logo>
            </LogoHeader>
          </div>
        </Header>
        {iswrongEmail ? (
          <ErrorInfo>
            Looks like you entered the wrong details. Please enter a valid email
            address and username to get the password reset link
          </ErrorInfo>
        ) : (
          <LoginMessage>Enter your registered details</LoginMessage>
        )}
        <Container>
          <FormContainerBox style={{ marginTop: "0px" }}>
            <PassWordContainer>
              <TextField
                type="text"
                placeholder="Email"
                name="email"
                inputProps={{
                  disableUnderline: true,
                  autocomplete: "new-password",
                  form: {
                    autocomplete: "off",
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                value={email}
                onFocus={(e) => {
                  setWrongEmail(false);
                }}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </PassWordContainer>
            <PassWordContainer style={{ marginTop: "3px" }}>
              <TextField
                type="text"
                placeholder="Username"
                name="username"
                InputProps={{
                  disableUnderline: true,
                }}
                value={username}
                onFocus={(e) => {
                  setWrongEmail(false);
                }}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </PassWordContainer>
            <ButtonContainer>
              <Button type="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </ButtonContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Text>Return to login screen</Text>
              </Link>
              <Link
                to="#"
                style={{
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Text>Contact Us</Text>
              </Link>
            </div>
          </FormContainerBox>
        </Container>
      </FormC>
    </Form>
  );
}
