import React, { useState } from "react";
import { Route, useParams } from "react-router-dom";
import { Chains } from "./components/chains";
import { ChooseHotels } from "./components/chooseHotels";
import { ManagingOrganizationPage } from "./components/managingOrganization";
export const Org = () => {
  const { ORGID, CHAINID } = useParams();
  const [selectedChainName, setSelectedChainName] = useState();
  const [selectedOrganizationName, setSelectedOrganizationName] = useState();
  const [networkMsg, setnetworkMsg] = useState(null);
  const [open, setOpen] = useState(null);

  return (
    <div>
      <Route exact path="/managing_organization">
        <ManagingOrganizationPage
          setSelectedOrganizationName={setSelectedOrganizationName}
          setnetworkMsg={setnetworkMsg}
          setOpen={setOpen}
        />
      </Route>
      <Route exact path="/managing_organization/:ORGID/chain">
        <Chains
          selectedOrganizationName={selectedOrganizationName}
          setSelectedChainName={setSelectedChainName}
        />
      </Route>
      <Route
        exact
        path="/managing_organization/:ORGID/chain/:CHAINID/choose_hotel"
      >
        <ChooseHotels
          selectedOrganizationName={selectedOrganizationName}
          selectedChainName={selectedChainName}
          setSelectedOrganizationName={setSelectedOrganizationName}
          setSelectedChainName={setSelectedChainName}
        />
      </Route>
    </div>
  );
};
