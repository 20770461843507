import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "../sdk";

export default function useBroadcast() {
  const { authFetch } = useAuth();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [networkMsg, setnetworkMsg] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newMessage, setNewMessage] = useState({
    title: "",
    description: "",
  });

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const getMessages = useCallback(async () => {
    try {
      const body = {
        limit: rowsPerPage,
        page: page,
      };
      const { get } = await authFetch({
        path: `/broadcast-message`,
      });
      const { data } = await get(`?${new URLSearchParams(body)}`);
      if (data) {
        setMessageList(data?.BroadcastMessage);
        setCount(data?.totalCount);
      }
    } catch (err) {
      console.log(err);
    }
  }, [authFetch, page, rowsPerPage]);
  const handleSaveMessage = useCallback(async () => {
    setLoading(true);
    try {
      const { post } = await authFetch({
        path: `/broadcast-message`,
      });
      const { data, response } = await post(newMessage);
      if (response.status === 200) {
        setnetworkMsg("Sent Successfully");
        getMessages();
      } else {
        setnetworkMsg(data?.messageToUser || "Something Went Wrong");
      }
    } catch (error) {
      console.log(error);
    }

    setNewMessage({
      title: "",
      description: "",
    });
    setLoading(false);
    setOpenDialog(false);
  }, [authFetch, getMessages, newMessage]);

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  return {
    messageList,
    page,
    rowsPerPage,
    count,
    handleChangePage,
    handleChangeRowsPerPage,
    openDialog,
    setOpenDialog,
    handleSaveMessage,
    setNewMessage,
    newMessage,
    loading,
  };
}
