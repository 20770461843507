import { Snackbar, SnackbarContent } from "@mui/material";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { BASE_URL } from "../../sdk";
import {
  Button,
  ButtonContainer,
  Container,
  Form,
  FormC,
  FormContainerBox,
  Header,
  Logo,
  LogoHeader,
  PassWordContainer,
  Text,
  TextBox,
  TextField,
} from "./Styles";
import { styled } from "@mui/system";
import { Box } from "@mui/material";

const initialData = {
  confirmPassword: "",
  password: "",
};

const Eye = styled(Box)`
  position: absolute;
  margin-left: 200px;
  margin-top: 18px;
  z-index: 999;
  &:hover {
    cursor: pointer;
  }
`;

function ResetPassword() {
  const [data, setData] = useState(initialData);
  const [visibility1, setVisibility1] = useState(false);
  const [visibility2, setVisibility2] = useState(false);

  const query = new URLSearchParams(window.location.search);
  const token = query.get("sessionID");

  const history = useHistory();
  const [networkMsg, setnetworkMsg] = useState(null);
  const [open, setOpen] = useState(false);
  const handleCloseStatus = () => {
    setOpen(false);
  };
  async function resetPassword(e) {
    e.preventDefault();
    try {
      await fetch(`${BASE_URL}/reset-password`, {
        method: "PUT",
        body: JSON.stringify({ ...data }),
        headers: {
          token,
        },
      })
        .then((res) => {
          if (res.ok) {
            history.push(`/`);
          } else {
            setnetworkMsg("Something went wrong");
            setOpen(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  }

  function togglePassword1() {
    setVisibility1(!visibility1);
  }
  function togglePassword2() {
    setVisibility2(!visibility2);
  }

  return (
    <Form onSubmit={resetPassword}>
      <FormC>
        <Header>
          <div>
            <LogoHeader>
              <Logo>
                <img
                  src="/assets/PreciumLogo.svg"
                  alt="Logo"
                  width="215px"
                  height="85px"
                />
              </Logo>
            </LogoHeader>
          </div>
        </Header>
        <Container>
          <TextBox style={{ marginTop: "15px" }}>Reset Password</TextBox>
          <FormContainerBox>
            <PassWordContainer>
              <TextField
                placeholder="Password"
                type={visibility1 ? "text" : "password"}
                name="password"
                value={data.password}
                InputProps={{
                  disableUnderline: true,
                }}
                onChange={(e) => {
                  setData({ ...data, [e.target.name]: e.target.value });
                }}
              />

              <Eye>
                <img
                  src={
                    visibility1
                      ? "assets/visibility_off-24px.svg"
                      : "assets/visibility-24px.svg"
                  }
                  onClick={togglePassword1}
                  alt=""
                />
              </Eye>
            </PassWordContainer>
            <PassWordContainer style={{ marginTop: "3px" }}>
              <TextField
                placeholder="Re-enter Password"
                type={visibility2 ? "text" : "password"}
                name="confirmPassword"
                value={data.confirmPassword}
                InputProps={{
                  disableUnderline: true,
                }}
                onChange={(e) => {
                  setData({ ...data, [e.target.name]: e.target.value });
                }}
              />

              <Eye>
                <img
                  src={
                    visibility2
                      ? "assets/visibility_off-24px.svg"
                      : "assets/visibility-24px.svg"
                  }
                  onClick={togglePassword2}
                  alt=""
                />
              </Eye>
            </PassWordContainer>
            <ButtonContainer>
              <Button type="submit" onClick={resetPassword}>
                Submit
              </Button>
            </ButtonContainer>

            <Link
              to="#"
              style={{
                textDecoration: "none",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Text style={{ marginTop: "16px" }}>Contact Us</Text>
            </Link>
          </FormContainerBox>
        </Container>
      </FormC>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseStatus}
      >
        <SnackbarContent
          style={{ backgroundColor: "#CA3433" }}
          message={networkMsg}
        />
      </Snackbar>
    </Form>
  );
}

export default ResetPassword;
