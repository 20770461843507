import { Snackbar, SnackbarContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import { LoadingPage, MonthPicker, Nodata, useAuth } from "../sdk";
import DOWTable from "./components/DOWTable.js";
import useDOWAnalysis from "./hooks/useDOWAnalysis.js";
import {
  ARRGraph,
  Body,
  BottomRow,
  Header,
  OccupancyGraph,
  Page,
  RevPARGraph,
  SubHeader,
  TableCard,
  WeeklyBody,
  WeeklyCard,
} from "./Styles";
import { PreciumDisabledPage } from "../sdk/components/PreciumDiabledPage";

export default function DOWAnalysis({ setPageHeader }) {
  const { token, currentHotel } = useAuth();
  const { hotelId } = useParams();
  const [networkMsg, setNetworkMsg] = useState();
  const [selectedDate, setSelectedDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 28)
  );
  const [Loading, setLoading] = useState();
  const [comparitiveDate, setComparitiveDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 28)
  );

  useEffect(() => {
    if (!token && !hotelId) {
      return;
    } else {
      window.scrollTo(0, 0);
    }
  }, [token, hotelId]);

  const getIndexAsKey = () => {
    return Math.random();
  };

  const {
    selectedData,
    comparitiveData,
    occupancy,
    arr,
    revpar,
    occupancyOptions,
    arrOptions,
    revenueOptions,
    open,
    handleCloseStatus,
  } = useDOWAnalysis(selectedDate, comparitiveDate, setNetworkMsg, setLoading);
  useEffect(() => {
    setPageHeader("Day Of Week Analysis");
    return () => {
      setPageHeader("");
    };
  }, []);
  return currentHotel?.isPreciumEnabled ? (
    <Page>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        onClose={handleCloseStatus}
      >
        <SnackbarContent
          style={{ backgroundColor: "#CA3433" }}
          message={networkMsg}
        />
      </Snackbar>
      <Body>
        <Header>
          <SubHeader>
            <MonthPicker
              value={selectedDate}
              onChange={setSelectedDate}
              disableFutureMonths={true}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  color: "#110641",
                  fontSize: "14px",
                  fontFamily: "Roboto",
                }}
              >
                Compare with
              </p>
            </div>
            <MonthPicker
              value={comparitiveDate}
              onChange={setComparitiveDate}
              disableFutureMonths={true}
            />
          </SubHeader>
        </Header>
        {!Loading ? (
          Object.entries(selectedData).length !== 0 &&
          Object.entries(comparitiveData).length !== 0 ? (
            <BottomRow>
              <WeeklyCard>
                <WeeklyBody>
                  <OccupancyGraph>
                    <Bar
                      data={occupancy}
                      options={occupancyOptions}
                      datasetKeyProvider={getIndexAsKey}
                    />
                  </OccupancyGraph>
                  <ARRGraph>
                    <Bar
                      data={arr}
                      options={arrOptions}
                      datasetKeyProvider={getIndexAsKey}
                    />
                  </ARRGraph>
                  <RevPARGraph>
                    <Bar
                      data={revpar}
                      options={revenueOptions}
                      datasetKeyProvider={getIndexAsKey}
                    />
                  </RevPARGraph>
                </WeeklyBody>
              </WeeklyCard>
              <TableCard>
                <DOWTable
                  selectedDate={selectedDate}
                  comparitiveDate={comparitiveDate}
                  selectedData={selectedData}
                  comparitiveData={comparitiveData}
                />
              </TableCard>
            </BottomRow>
          ) : (
            <Nodata />
          )
        ) : (
          <LoadingPage />
        )}
      </Body>
    </Page>
  ) : (
    <PreciumDisabledPage />
  );
}
