import { TableCell, TableContainer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { getDateDDMM, getDay, Nodata } from "../../sdk";
import { Box as BX } from "@mui/material";
import {
  DATE,
  DAY,
  MyCard,
  Table,
  Tbody,
  Thead,
  Tr,
  Wrapper,
  ClassWrapper,
} from "../Styles";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const Th = styled(TableCell)`
  &.MuiTableCell-head {
    vertical-align: bottom;

    color: #130453;

    border: none;
    text-align: center;
    padding: 10px 0px;
    background: #306fbc;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    color: #ffffff;
  }
`;

export const Td = styled(TableCell)`
  &.MuiTableCell-body {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #212121;
    border: none;
    height: 32px;
    padding: 0 15px;
    text-align: center;
    max-width: 100px;
    width: 100px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
`;
export const Bottom = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: no-wrap;
  font: normal normal normal 16px/20px Roboto;
  letter-spacing: 0px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  color: #333333;
  width: 100%;
`;

const BottomNew = styled(Bottom)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: #000000;
`;
export const Header = styled(Typography)`
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;

  color: #212121;
  letter-spacing: 0px;
  color: #000000;
  padding-bottom: 8px;
`;

export const Calender = styled(BX)`
  margin: 12px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
`;

export const Week = styled(BX)`
  display: grid;
  grid-template-columns: repeat(7, 10vw);
  grid-template-rows: 3vh;
  gap: 8px;
`;

export const Name = styled(BX)`
  background: transparent;
  font: normal bold normal 14px/16px Roboto;
  color: #1d1d1d;
  text-align: center;
`;

const CardNew = styled(BX)`
  width: 100%;
  height: 104px;
  borderradius: 8px;
`;
const MyCardNew = styled(BX)`
  width: 100%;
  height: 122px;
  borderradius: 8px;
`;

const DateText = styled(BX)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #212121;
  padding: 8px;
`;

const Text = styled(BX)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  color: #212121;
  padding: 8px;
`;
const DataNew = styled(BX)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #212121;
  padding: 7px 8px 7px;
`;
const WrapperNew = styled(Wrapper)`
  background: #ffffff;
  box-shadow: 1px 4px 10px rgba(48, 111, 188, 0.2);
  width: 90vw;
  overflow: auto;

  margin-top: 27px;
  height: calc(100vh - 80px);
`;

const Box = styled(BX)`
  display: flex;
  flex-direction: column;
  padding-top: 18px;
`;
const HeaderNew = styled(BX)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  box-shadow: 1px 4px 10px rgb(48 111 188 / 20%);
  border-radius: 4px;

  padding: 8px;

  color: #000000;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
  text-align: center;
  display: flex;
  height: 60px;
  min-width: 100px;
`;

const Detail = styled(BX)`
  box-shadow: 1px 4px 10px rgb(48 111 188 / 20%);
  border-radius: 4px;
  color: #757575;
  padding: 8px;

  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: space-between;
  text-align: center;
  margin-left: 20px;
  font-family: Roboto;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  min-width: 100px;
`;

const Container = styled(BX)`
  display: flex;
  padding: 4px;
  margin-bottom: 8px;

  width: 80%;
  marginleft: 7px;
  justifycontent: flex-start;
`;
const monthsnew = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const PricingRecommends = ({
  bookings,
  view,
  checkSelectedData,
  curr_index,
}) => {
  const [pastData, setPastData] = useState([]);
  const [haveData, setHaveData] = useState(false);

  useEffect(() => {
    if (checkSelectedData && curr_index !== -1) {
      setPastData(bookings[curr_index]);

      setHaveData(true);
    } else {
      setPastData([]);
      setHaveData(false);
    }
  }, [checkSelectedData, curr_index]);
  return (
    <ClassWrapper>
      {view === "list" ? (
        <WrapperNew style={{ width: "calc(100vw - 70px)" }}>
          {bookings
            ?.slice()
            .reverse()
            .map((booking, index) => (
              <MyCard key={index}>
                <Header>
                  {months[new Date(booking[0].date).getMonth()]}{" "}
                  {new Date(booking[0].date).getFullYear()}
                </Header>

                <TableContainer className="container">
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                      boxSizing: "border-box",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "5px",
                    }}
                  >
                    <Thead>
                      <Tr>
                        <Th style={{ borderRadius: "4px 0px 0px 0px" }}>
                          Date
                        </Th>
                        <Th style={{ width: "130px" }}>Room Nights</Th>
                        <Th>ADR</Th>
                        <Th style={{ borderRadius: "0px 4px 0px 0px" }}>
                          Revenue
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {booking.slice(0, booking.length - 1).map((data, idx) => (
                        <Tr
                          key={idx}
                          style={
                            idx % 2 === 0 ? { backgroundColor: "white" } : {}
                          }
                        >
                          <Td>
                            <DATE>
                              <div>{getDateDDMM(data.date)} </div>
                              <DAY>{getDay(data.date)}</DAY>
                            </DATE>
                          </Td>
                          <Td>
                            {data.actualRoomsBooked === -1
                              ? "-"
                              : data.actualRoomsBooked}
                          </Td>
                          <Td>
                            {data.averageRoomRate === -1
                              ? "-"
                              : data.averageRoomRate}
                          </Td>
                          <Td>{data.revenue === -1 ? "-" : data.revenue}</Td>
                        </Tr>
                      ))}
                    </Tbody>

                    <Tbody>
                      <Tr
                        style={
                          (booking.length - 1) % 2 === 0
                            ? { backgroundColor: "#306fbc" }
                            : { backgroundColor: "#306fbc" }
                        }
                      >
                        <Td
                          style={{
                            color: "white",
                          }}
                        >
                          <b>Total</b>
                        </Td>
                        <Td
                          style={{
                            color: "white",
                          }}
                        >
                          <b>
                            {booking[booking.length - 1].totalRooms === -1
                              ? "-"
                              : booking[booking.length - 1].totalRooms}
                          </b>
                        </Td>
                        <Td
                          style={{
                            color: "white",
                          }}
                        >
                          <b>
                            {isNaN(booking[booking.length - 1].totalARR)
                              ? 0
                              : booking[booking.length - 1].totalARR === -1
                              ? "-"
                              : booking[booking.length - 1].totalARR}
                          </b>
                        </Td>
                        <Td
                          style={{
                            color: "white",
                          }}
                        >
                          <b>
                            {booking[booking.length - 1].totalRevenue === -1
                              ? "-"
                              : booking[
                                  booking.length - 1
                                ].totalRevenue.toLocaleString("en-IN")}
                          </b>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </MyCard>
            ))}
        </WrapperNew>
      ) : (
        <WrapperNew
          style={{
            width: "calc(100vw - 70px)",
            height: "calc(100vh - 120px)",
            marginTop: "80px",
          }}
        >
          {haveData ? (
            <Box>
              <Container>
                <HeaderNew>
                  {pastData?.length &&
                    months[new Date(pastData[0]?.date).getMonth()]}{" "}
                  {pastData && new Date(pastData[0]?.date).getFullYear()}
                </HeaderNew>
                <Detail>
                  <div>Room Nights</div>
                  <BottomNew>
                    {pastData?.length &&
                    pastData[pastData.length - 1]?.totalRooms === -1
                      ? "-"
                      : pastData && pastData[pastData.length - 1]?.totalRooms}
                  </BottomNew>
                </Detail>
                <Detail>
                  <div>ADR</div>
                  <BottomNew style={{ color: "#000000" }}>
                    {isNaN(pastData[pastData.length - 1].totalARR)
                      ? 0
                      : pastData[pastData.length - 1].totalARR === -1
                      ? "-"
                      : pastData[pastData.length - 1].totalARR}
                  </BottomNew>
                </Detail>
                <Detail>
                  <div>Revenue</div>
                  <BottomNew style={{ color: "#000000" }}>
                    {pastData[pastData.length - 1].totalRevenue === -1
                      ? "-"
                      : pastData[
                          pastData.length - 1
                        ].totalRevenue.toLocaleString("en-IN")}
                  </BottomNew>
                </Detail>
              </Container>
              <Calender>
                <Week>
                  <Name>Monday</Name>
                  <Name>Tuesday</Name>
                  <Name>Wednesday</Name>
                  <Name>Thursday</Name>
                  <Name>Friday</Name>
                  <Name>Saturday</Name>
                  <Name>Sunday</Name>
                </Week>
                <div
                  style={{
                    width: "100%",

                    display: "grid",
                    gridTemplateColumns: "repeat(7, 10vw)",
                    gridTemplateRows: "repeat(autofill, 8vh)",

                    gap: "8px",
                  }}
                >
                  {Array(
                    pastData[0]?.date &&
                      new Date(pastData[0]?.date)?.getDay() === 0
                      ? 6
                      : parseInt(new Date(pastData[0]?.date)?.getDay() - 1) || 0
                  )
                    .fill()
                    .map((data, idx) => (
                      <CardNew></CardNew>
                    ))}
                  {pastData
                    ?.slice(0, pastData.length - 1)
                    .map((booking, index) => (
                      <MyCardNew
                        style={{
                          background: "#E7F3FF",
                          borderRight: "solid 1px #FFFFFF",

                          borderRadius: "8px",
                          height: "104px",
                        }}
                        key={index}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <DateText>
                              {getDateDDMM(booking?.date).split("/")[0] +
                                " " +
                                monthsnew[new Date(booking?.date).getMonth()]}
                            </DateText>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "10px",
                            }}
                          >
                            <Text>Room Nights</Text>
                            <DataNew>
                              {booking?.actualRoomsBooked === -1
                                ? "-"
                                : booking.actualRoomsBooked}
                            </DataNew>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "-4px",
                            }}
                          >
                            <Text>ADR</Text>
                            <DataNew>
                              {booking.averageRoomRate === -1
                                ? "-"
                                : Math.round(
                                    parseFloat(booking.averageRoomRate)
                                  )}
                            </DataNew>
                          </div>
                        </div>
                      </MyCardNew>
                    ))}
                </div>
              </Calender>
            </Box>
          ) : (
            <Nodata />
          )}
        </WrapperNew>
      )}
    </ClassWrapper>
  );
};
export default PricingRecommends;
