import React from "react";
import { styled } from "@mui/system";
import { Row, Table as T } from "../../sdk";
import { Months } from "../index";
import { Td, Th } from "../Styles.js";

const Table = styled(T)`
  background-color: white;
  width: 100%;
  height: 100%;
  margin: 0px;
`;

export default function DoWPickupPerformanceTable({
  selectedDate,
  comparitiveDate,
  selectedData,
  comparitiveData,
  daywiseComparitiveData,
  daywiseData,
}) {
  return (
    <Table>
      <thead>
        <Row>
          <Th>Day of Week</Th>
          <Th>
            {Months[selectedDate?.getMonth()]} {selectedDate?.getFullYear()}
          </Th>
          <Th>
            {Months[comparitiveDate?.getMonth()]}{" "}
            {comparitiveDate?.getFullYear()}
          </Th>
        </Row>
      </thead>
      <tbody>
        {selectedData?.map((value, index) => (
          <Row
            key={index}
            style={
              index % 2 === 0
                ? { backgroundColor: "rgba(48, 81, 221,0.09)" }
                : {}
            }
          >
            <Td>{value?.day}</Td>
            <Td style={{ color: value?.pickup <= -1 ? "red" : "" }}>
              {value?.pickup ? parseInt(value?.pickup) : "-"}
            </Td>
            <Td
              style={{
                color:
                  comparitiveData &&
                  (comparitiveData[index]?.pickup <= -1 ? "red" : ""),
              }}
            >
              {comparitiveData &&
                (comparitiveData[index]?.pickup
                  ? parseInt(comparitiveData[index]?.pickup)
                  : "-")}
            </Td>
          </Row>
        ))}
      </tbody>
    </Table>
  );
}
