import {
  Checkbox,
  MenuItem,
  Paper,
  Snackbar,
  SnackbarContent,
  TableContainer,
  Table,
  CircularProgress,
  DialogTitle,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import { ArrowLeft, ArrowRight, Cancel, FileUpload } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { LoadingPage, Nodata, PrimaryButton } from "../../sdk";
import useBudgetSheet from "../hooks/useBudgetSheet";
import { Select } from "./Styles";
import { styled } from "@mui/system";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Modal,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ModelBody } from "./BudgetSetupModalUpload";
import {
  DATE,
  INPUT,
  Message,
  NextBtnContainer,
  SECTION,
  TEXT2,
  TITLE,
} from "./Styles";
import ConfirmationDialog from "./ConfirmationDialog";
const ClassWrapper = styled(Box)(({ theme }) => ({
  ".MuiSelect-icon": {
    color: "white",
  },
  ".container": {
    overflowY: "auto",
    height: "fit-content",
  },
  ".box": {
    width: "186px",
    height: "90px",
    background: "#ffffff 0% 0% no-repeat padding-box",
    textAlign: " center",
    font: "normal normal bold 12px/23px Roboto",
    borderRight: "1px solid #1106411a",
    letterSpacing: "0px",
    color: "#110641",
    opacity: 1,
    paddingLeft: "48px",
    paddingRight: "48px",
    paddingTop: "12px",
  },
  ".root": {
    cursor: "pointer",
    marginTop: "6px",
    width: "42px",
    height: "42px",

    color: "#110641",
    opacity: 1,
  },
  ".heading": {
    background: "#ffffff 0% 0% no-repeat padding-box",
    textAlign: "center",
    font: "normal normal bold 12px/20px Roboto",
    borderRight: "1px solid #1106411a",
    letterSpacing: "0px",
    color: "#110641",
    opacity: 1,
    paddingLeft: "4px",
    position: "sticky",
    top: 0,
    zIndex: 10,
  },
  ".dropdownStyle": {
    borderRadius: 4,
    font: "normal normal normal 12px/20px Roboto",
  },
  ".underline": {
    width: "100%",
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  ".inputCenter": {
    textAlign: "center",
    font: "normal normal bold 14px/20px Roboto",
    fontWeight: "400",
  },
  ".sticky": {
    position: "sticky",
    left: 0,
    top: 0,
    background: "#fff",
    zIndex: "999",
  },
  ".stickyRight": {
    position: "sticky",
    left: "120px",
    background: "#fff",
    zIndex: "999",
    top: "0",
  },
}));

const Options = styled(Select)`
  .MuiSelect-select.MuiSelect-select {
    padding-right: 32px;
    color: #fff;
  }
  .MuiSelect-icon {
    color: #fff;
  }
`;

const StyledTable = styled(Table)({
  ".TableHeadCell": {
    color: "#fff",
  },
  ".marketSegmentCell": {
    borderRight: "1px solid rgb(224,224,224)",
    borderLeft: "1px solid rgb(224,224,224)",
  },
  ".KPICell": {
    borderRight: "1px solid rgb(224,224,224)",
    padding: "0",
    width: "120px",
  },
  ".KPICellStack": {
    margin: "0 5px",
  },
  ".KPICellBox": {
    height: "26px",
  },
  ".BudgetMonthlyDataCell": {
    padding: "0px",
  },
  ".BudgetMonthlyDataStack": {
    padding: "0px",
  },
  ".TotalBudgetCell": {
    borderLeft: "1px solid rgb(224,224,224)",
  },
});
export const Upload = styled(PrimaryButton)`
  width: 108px !important;
  height: 48px !important;
  background: #130453 0% 0% no-repeat padding-box;
  border-radius: 8px;
  font: normal normal bold 14px/15px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
`;

export default function BudgetSheet() {
  const {
    TotalColumnPerMonth,
    budgetSheetInfo,
    MONTHS,
    pastdata,
    isLeftDisabled,
    isRightDisabled,
    YEAR,
    toggleLeft,
    toggleRight,
    stateArray,
    haveData,
    pastbudgetData,
    Loading,
    handleChange,
    handleClick,
    checked,
    handleEdit,
    networkMsg,
    open,
    handleCloseStatus,
    saveBudgetSheetData,
    budgetingPeriod,
    calculateTotal,
    setPastYearValue,
    optionValue,
    openSelectbar,
    setOpenSelectBar,
    fileHandler,
    fileGetter,
    removeFile,
    filename,
    handleToggle,
    toggle,
    setToggle,
    snackBarColor,
    totalShow,
    handleNext,
    isOpenDialog,
    handleCloseDialog,
    handleLeavePage,
    handleDialogSave,
    formEdit,
    setformEdit,
    calVariance,
    lockBudget,
    canUserUnlockBudget,
    canUserEditPastBudget,
    toggleLockBudget,
    openConfirmDialog, setOpenConfirmDialog,
    isBudgetUploaded, isBudgetFound, setIsBudgetFound
  } = useBudgetSheet();


  const [monthArray, setMonthArray] = useState([]);

  useEffect(() => {
    const getAns = () => {
      const ans = budgetSheetInfo?.find((x) => x?.monthlyReport?.length === 12);
      setMonthArray(ans);
    };
    getAns();
  }, [budgetSheetInfo]);

  useEffect(() => { }, [budgetSheetInfo, pastdata]);

  return (
    <ClassWrapper>
      <Box style={{ margin: "10px 0", backgroundColor: "none" }}>
        <Stack
          direction={"row"}
          spacing={4}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Stack direction={"row"}>
            <Paper
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "fit-content",
                padding: "0 15px",
                marginRight: "20px",
              }}
            >
              <Box
                style={{
                  color: "rgb(48,111,188)",
                  fontWeight: "500",
                  cursor: "default",
                  fontFamily: "Roboto",
                }}
              >
                {`Budget Period - `}
                <span
                  style={{
                    color: "#000",
                    cursor: "default",
                  }}
                >
                  {budgetingPeriod ? (
                    budgetingPeriod
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress
                        style={{
                          color: "#110641",
                          width: "15px",
                          height: "15px",
                        }}
                      />
                    </div>
                  )}
                </span>
              </Box>
            </Paper>

            <Paper
              elevation={1}
              style={{
                display: "flex",
                padding: "0px 10px",
              }}
            >
              <div style={{ marginLeft: "0px" }}>
                <TITLE
                  style={{
                    marginTop: "8px",
                    color: "rgb(48,111,188)",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Budget
                </TITLE>
              </div>
              <SECTION
                style={{
                  marginTop: "-4px",
                  marginRight: "-20px",
                  marginLeft: "-20px",
                }}
              >
                {!isLeftDisabled ? (
                  <ArrowLeft
                    className="root"
                    onClick={toggleLeft}
                    style={{
                      marginTop: "6px",
                      width: "42px",
                      height: "42px",
                      color: "#306fbc",
                    }}
                  />
                ) : (
                  <ArrowLeft
                    style={{
                      cursor: "not-allowed",
                      marginTop: "6px",
                      width: "42px",
                      height: "42px",
                      color: "#306fbc",
                    }}
                    color="disabled"
                  />
                )}
                <div>
                  <TEXT2
                    style={{
                      margin: "13px 8px 0 8px",
                      color: "#000",
                      fontWeight: "500",
                    }}
                  >
                    {budgetingPeriod !== "Jan-Dec"
                      ? YEAR + "-" + (parseInt(YEAR.toString().substr(-2)) + 1)
                      : YEAR}
                  </TEXT2>
                </div>

                {!isRightDisabled ? (
                  <ArrowRight
                    className="root"
                    onClick={toggleRight}
                    style={{
                      marginTop: "6px",
                      width: "42px",
                      height: "42px",
                      color: "#306fbc",
                    }}
                  />
                ) : (
                  <ArrowRight
                    style={{
                      cursor: "not-allowed",
                      marginTop: "6px",
                      width: "42px",
                      height: "42px",
                      color: "#306fbc",
                    }}
                    color="disabled"
                  />
                )}
              </SECTION>
            </Paper>

            <div
              style={{ display: "flex", marginLeft: "30px", marginTop: "-2px" }}
            >
              <Paper
                elevation={1}
                style={{
                  display: "flex",
                  padding: "0 10px",
                }}
              >
                <Checkbox
                  style={{
                    width: "27px",
                    height: "27px",
                    marginTop: "12.5px",
                    color: "#306fbc",
                  }}
                  checked={checked}
                  onChange={handleChange}
                />
                <Message
                  style={{
                    color: "rgb(48,111,188)",
                    fontWeight: "500",
                    marginLeft: "0px",
                  }}
                >
                  View all previous data
                </Message>
              </Paper>
            </div>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Upload
              onClick={() => setOpenConfirmDialog(true)}
              disabled={lockBudget ? !canUserUnlockBudget : false}
              sx={{
                backgroundColor:
                  (lockBudget && canUserUnlockBudget) || !lockBudget
                    ? lockBudget ? "#a90f0f !important" : "#130453"
                    : "#a8a4a4 !important",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  letterSpacing: "1px",
                }}
              >
                {lockBudget ? "UNLOCK" : "LOCK"}
              </Typography>
            </Upload>
            <Upload
              onClick={() => isBudgetUploaded()}
              disabled={!(!lockBudget && canUserEditPastBudget)}
              sx={{
                backgroundColor:
                  (!lockBudget && canUserEditPastBudget)
                    ? "#130453"
                    : "#a8a4a4 !important",
              }}
            >
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent="center"
                alignItems={"center"}
                sx={{ padding: "0 8px" }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    letterSpacing: "1px",
                    paddingLeft: "15px",
                  }}
                >
                  Upload
                </Typography>
                <FileUpload sx={{ paddingRight: "10px" }} />
              </Stack>
            </Upload>
          </Stack>
        </Stack>
      </Box>
      <Modal open={openSelectbar} onClose={() => setOpenSelectBar(false)}>
        <div>
          <ModelBody
            fileHandler={fileHandler}
            fileGetter={fileGetter}
            setOpenSelectBar={setOpenSelectBar}
            removeFile={removeFile}
            filename={filename}
            handleToggle={handleToggle}
            toggle={toggle}
            YEAR={YEAR}
          />
        </div>
      </Modal>
      <ConfirmationDialog
        open={openConfirmDialog}
        handleCloseConfirmation={() => setOpenConfirmDialog(false)}
        confirmationText={`Are you sure you want to ${lockBudget ? "unlock" : "lock"} budget?`}
        onSuccess={() => {
          toggleLockBudget()
          setOpenConfirmDialog(false)
        }}
      />
      <ConfirmationDialog
        open={isBudgetFound}
        handleCloseConfirmation={() => setIsBudgetFound(false)}
        confirmationText={`Do you want to overwrite the previous budget data?`}
        onSuccess={() => {
          setOpenSelectBar(true);
          setToggle(false);
          setIsBudgetFound(false)
        }}
      />
      {!Loading && haveData ? (
        <>
          <Paper
            elevation={0}
            sx={{ minWidth: "calc(100vh - 200px)", overflow: "hidden" }}
          >
            <TableContainer
              style={{ maxHeight: "calc(100vh - 290px)", maxWidth: "100vw" }}
            >
              <StyledTable stickyHeader aria-label="sticky table">
                <TableHead style={{ height: "90px" }}>
                  <TableCell
                    className="sticky"
                    style={{
                      background: "rgba(48, 111, 188, 1)",
                      color: "#fff",
                      zIndex: "9999",
                    }}
                  >
                    Market Segment
                  </TableCell>
                  <TableCell
                    className="stickyRight"
                    style={{
                      background: "rgba(48, 111, 188, 1)",
                      color: "#fff",
                      zIndex: "9999",
                    }}
                  >
                    KPI
                  </TableCell>
                  {monthArray?.monthlyReport?.map((data, k) => {
                    return (
                      <>
                        <TableCell
                          className="TableHeadCell"
                          style={{ background: "rgba(48, 111, 188, 1)" }}
                        >
                          <Stack direction={"row"}>
                            <Stack direction="column">
                              <p>Budget</p>
                              <DATE
                                style={
                                  stateArray[k]
                                    ? {
                                      marginTop: "-3px",
                                      color: "#fff",
                                      fontSize: "14px",
                                    }
                                    : { color: "#fff", fontSize: "14px" }
                                }
                              >
                                {MONTHS[new Date(data.month)?.getMonth()]}
                              </DATE>
                            </Stack>
                            <Stack>
                              {!stateArray[k] && (
                                <img
                                  style={{
                                    width: "16px",
                                    height: "16px",
                                    left: "10px",
                                    position: "relative",
                                    top: "10px",
                                  }}
                                  onClick={() => handleClick(k)}
                                  src="/assets/Show.svg"
                                  alt="Logo"
                                />
                              )}
                            </Stack>
                          </Stack>
                        </TableCell>
                        {stateArray[k] && (
                          <>
                            <TableCell
                              className="TableHeadCell"
                              style={{ background: "rgba(48, 111, 188, 1)" }}
                            >
                              <p>Actual</p>
                              <Options
                                value={optionValue}
                                className="dropdownStyle"
                                onChange={(e) =>
                                  setPastYearValue(e.target.value)
                                }
                                style={{
                                  width: "70px",
                                  height: "20px",
                                  font: "normal normal normal 12px/20px Roboto",
                                  marginTop: "12px",
                                  marginRight: "2px",
                                  marginLeft: "0px",
                                  paddingLeft: "0px",
                                  color: "#ffffff",
                                  border: "1px solid #fff",
                                  background: "inherit",
                                }}
                                disableUnderline
                                required={true}
                              >
                                <MenuItem
                                  value={0}
                                  style={{
                                    font:
                                      "normal normal normal 12px/20px Roboto",
                                  }}
                                >
                                  {"Actual-" +
                                    MONTHS[new Date(data?.month).getMonth()] +
                                    "-" +
                                    new Date(data?.month)
                                      .getFullYear()
                                      .toString()
                                      .substr(-2)}
                                </MenuItem>
                                <MenuItem
                                  value={1}
                                  style={{
                                    font:
                                      "normal normal normal 12px/20px Roboto",
                                  }}
                                >
                                  {"Actual-" +
                                    MONTHS[new Date(data?.month).getMonth()] +
                                    "-" +
                                    (new Date(data?.month)
                                      .getFullYear()
                                      .toString()
                                      .substr(-2) -
                                      1)}
                                </MenuItem>
                                <MenuItem
                                  value={2}
                                  style={{
                                    font:
                                      "normal normal normal 12px/20px Roboto",
                                  }}
                                >
                                  {"Actual-" +
                                    MONTHS[new Date(data?.month).getMonth()] +
                                    "-" +
                                    (new Date(data?.month)
                                      .getFullYear()
                                      .toString()
                                      .substr(-2) -
                                      2)}
                                </MenuItem>
                                <MenuItem
                                  value={3}
                                  style={{
                                    font:
                                      "normal normal normal 12px/20px Roboto",
                                  }}
                                >
                                  {"Actual-" +
                                    "-" +
                                    MONTHS[new Date(data?.month).getMonth()] +
                                    "-" +
                                    (new Date(data?.month)
                                      .getFullYear()
                                      .toString()
                                      .substr(-2) -
                                      3)}
                                </MenuItem>
                              </Options>
                            </TableCell>
                            <TableCell
                              className="TableHeadCell"
                              style={{ background: "rgba(48, 111, 188, 1)" }}
                            >
                              <Stack direction={"row"} spacing={4}>
                                <p>Variance</p>
                                <img
                                  style={{
                                    width: "16px",
                                    height: "16px",
                                    marginLeft: "10px",
                                    position: "relative",
                                    marginTop: "2px",
                                  }}
                                  onClick={() => handleClick(k)}
                                  src="/assets/Hide.svg"
                                  alt="Logo"
                                />
                              </Stack>
                            </TableCell>
                          </>
                        )}
                      </>
                    );
                  })}
                  <TableCell
                    className="TableHeadCell"
                    style={{ background: "rgba(48, 111, 188, 1)" }}
                  >
                    <Stack direction="row" alignItems="cen">
                      Budget Total
                      <Stack>
                        {!stateArray[12] && (
                          <img
                            style={{
                              width: "16px",
                              height: "16px",
                              left: "10px",
                              position: "relative",
                              top: "10px",
                            }}
                            onClick={() => handleClick(12)}
                            src="/assets/Show.svg"
                            alt="Logo"
                          />
                        )}
                      </Stack>
                    </Stack>
                  </TableCell>
                  {stateArray[12] && (
                    <>
                      <TableCell
                        className="TableHeadCell"
                        style={{ background: "rgba(48, 111, 188, 1)" }}
                      >
                        <p>Actual</p>
                        <Options
                          value={optionValue}
                          className="dropdownStyle"
                          onChange={(e) => setPastYearValue(e.target.value)}
                          style={{
                            width: "70px",
                            height: "20px",
                            font: "normal normal normal 12px/20px Roboto",
                            marginTop: "12px",
                            marginRight: "2px",
                            marginLeft: "0px",
                            paddingLeft: "0px",
                            color: "#ffffff",
                            border: "1px solid #fff",
                            background: "inherit",
                          }}
                          disableUnderline
                          required={true}
                        >
                          <MenuItem
                            value={0}
                            style={{
                              font: "normal normal normal 12px/20px Roboto",
                            }}
                          >
                            {"Year" +
                              "-" +
                              new Date().getFullYear().toString().substr(-2)}
                          </MenuItem>
                          <MenuItem
                            value={1}
                            style={{
                              font: "normal normal normal 12px/20px Roboto",
                            }}
                          >
                            {"Year" +
                              "-" +
                              (new Date().getFullYear().toString().substr(-2) -
                                1)}
                          </MenuItem>
                          <MenuItem
                            value={2}
                            style={{
                              font: "normal normal normal 12px/20px Roboto",
                            }}
                          >
                            {"Year" +
                              "-" +
                              (new Date().getFullYear().toString().substr(-2) -
                                2)}
                          </MenuItem>
                          <MenuItem
                            value={3}
                            style={{
                              font: "normal normal normal 12px/20px Roboto",
                            }}
                          >
                            {"Year" +
                              "-" +
                              (new Date().getFullYear().toString().substr(-2) -
                                3)}
                          </MenuItem>
                        </Options>
                      </TableCell>
                      <TableCell
                        className="TableHeadCell"
                        style={{ background: "rgba(48, 111, 188, 1)" }}
                      >
                        <Stack direction={"row"} spacing={4}>
                          <p>Variance</p>
                          <img
                            style={{
                              width: "16px",
                              height: "16px",
                              marginLeft: "10px",
                              position: "relative",
                              marginTop: "2px",
                            }}
                            onClick={() => handleClick(12)}
                            src="/assets/Hide.svg"
                            alt="Logo"
                          />
                        </Stack>
                      </TableCell>
                    </>
                  )}
                </TableHead>
                <TableBody>
                  {budgetSheetInfo?.map((data, index) => {
                    return (
                      <TableRow
                        style={
                          index % 2 === 0
                            ? {
                              background: "#ffffff",
                            }
                            : {
                              background: "rgba(48, 111, 188, 0.2)",
                            }
                        }
                      >
                        <TableCell
                          className="sticky"
                          title={budgetSheetInfo[index]?.description}
                          style={{
                            borderRight: "1px solid rgb(224,224,224)",
                            borderLeft: "1px solid rgb(224,224,224)",
                            minWidth: "120px",
                            cursor: "pointer",
                          }}
                        >
                          {budgetSheetInfo[index]?.systemName}
                        </TableCell>
                        <TableCell
                          className="stickyRight"
                          style={{
                            borderRight: "1px solid rgb(224,224,224)",
                            padding: "0",
                            width: "120px",
                            minWidth: "120px",
                          }}
                        >
                          <Stack
                            className="KPICellStack"
                            direction={"column"}
                            spacing={1.3}
                          >
                            <Box className="KPICellBox">Room Nights</Box>
                            <Box className="KPICellBox">ADR</Box>
                            <Box className="KPICellBox">Revenue</Box>
                          </Stack>
                        </TableCell>
                        {data?.monthlyReport?.map((value, k) => {
                          return (
                            <>
                              <TableCell className="BudgetMonthlyDataCell">
                                <Stack
                                  className="BudgetMonthlyDataStack"
                                  direction={"column"}
                                  spacing={1}
                                >
                                  <Box style={{ padding: "0px", margin: "0" }}>
                                    <INPUT
                                      classes={{
                                        underline: "underline",
                                        input: "inputCenter",
                                      }}
                                      disableUnderline
                                      type="numeric"
                                      value={value?.rooms}
                                      disabled={YEAR < new Date().getFullYear()}
                                      onChange={(e) => {
                                        if (
                                          !isNaN(e.target.value) &&
                                          !lockBudget &&
                                          canUserEditPastBudget
                                        ) {
                                          setformEdit(true);
                                          handleEdit(
                                            index,
                                            k,
                                            "rooms",
                                            e.target.value &&
                                            parseInt(e.target.value)
                                          );
                                        }
                                      }}
                                      style={
                                        index % 2 === 0
                                          ? {
                                            height: "26px",

                                            color: "black",
                                            fontWeight: "400",
                                          }
                                          : {
                                            height: "26px",

                                            color: "black",
                                            fontWeight: "400",
                                          }
                                      }
                                    ></INPUT>
                                  </Box>
                                  <Box>
                                    <INPUT
                                      classes={{
                                        underline: "underline",
                                        input: "inputCenter",
                                      }}
                                      disableUnderline
                                      type="numeric"
                                      value={value?.arr ? value?.arr : 0}
                                      disabled={YEAR < new Date().getFullYear()}
                                      onChange={(e) => {
                                        if (
                                          !isNaN(e.target.value) &&
                                          !lockBudget &&
                                          canUserEditPastBudget
                                        ) {
                                          setformEdit(true);
                                          handleEdit(
                                            index,
                                            k,
                                            "arr",
                                            e.target.value &&
                                            parseInt(e.target.value)
                                          );
                                        }
                                      }}
                                      style={
                                        index % 2 === 0
                                          ? {
                                            height: "36px",

                                            color: "black",
                                            fontWeight: "400",
                                          }
                                          : {
                                            height: "36px",

                                            color: "black",
                                            fontWeight: "400",
                                          }
                                      }
                                    ></INPUT>
                                  </Box>

                                  <Box
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {" "}
                                    {value?.revenue}
                                  </Box>
                                </Stack>
                              </TableCell>

                              {/* Actual and Variance data */}
                              {stateArray[k] && (
                                <>
                                  <TableCell>
                                    <>
                                      <Stack direction={"column"} spacing={2}>
                                        <Box style={{ textAlign: "center" }}>
                                          {pastdata[index]?.monthlyReport[k]
                                            ?.noOfRoomsBooked !== undefined
                                            ? pastdata[index]?.monthlyReport[k]
                                              ?.noOfRoomsBooked === -1
                                              ? "-"
                                              : pastdata[index]?.monthlyReport[
                                                k
                                              ]?.noOfRoomsBooked
                                            : 0}
                                        </Box>
                                        <Box style={{ textAlign: "center" }}>
                                          {pastdata[index]?.monthlyReport[k]
                                            ?.arr !== undefined
                                            ? pastdata[index]?.monthlyReport[k]
                                              ?.arr === -1
                                              ? "-"
                                              : Math.floor(
                                                pastdata[index]
                                                  ?.monthlyReport[k]?.arr *
                                                100
                                              ) / 100
                                            : 0}
                                        </Box>
                                        <Box style={{ textAlign: "center" }}>
                                          {pastdata[index]?.monthlyReport[k]
                                            ?.revenue !== undefined
                                            ? pastdata[index]?.monthlyReport[k]
                                              ?.noOfRoomsBooked !== -1 &&
                                              pastdata[index]?.monthlyReport[k]
                                                ?.arr !== -1
                                              ? pastdata[index]?.monthlyReport[
                                                k
                                              ]?.revenue === -1
                                                ? 0
                                                : Math.floor(
                                                  pastdata[index]
                                                    ?.monthlyReport[k]
                                                    ?.revenue * 100
                                                ) / 100
                                              : "-"
                                            : 0}
                                        </Box>
                                      </Stack>
                                    </>
                                  </TableCell>
                                  <TableCell>
                                    <Stack direction={"column"} spacing={2}>
                                      <Box
                                        style={{
                                          textAlign: "center",
                                          color:
                                            pastdata[index]?.monthlyReport[k]
                                              ?.noOfRoomsBooked !== undefined
                                              ? (pastdata[index]?.monthlyReport[
                                                k
                                              ]?.noOfRoomsBooked < 0
                                                ? value.rooms -
                                                pastdata[index]
                                                  ?.monthlyReport[k]
                                                  .noOfRoomsBooked *
                                                0
                                                : value.rooms -
                                                pastdata[index]
                                                  ?.monthlyReport[k]
                                                  .noOfRoomsBooked) < 0
                                                ? "red"
                                                : "green"
                                              : "green",
                                        }}
                                      >
                                        {pastdata[index]?.monthlyReport[k]
                                          ?.noOfRoomsBooked !== undefined
                                          ? pastdata[index]?.monthlyReport[k]
                                            ?.noOfRoomsBooked < 0
                                            ? value?.rooms -
                                            pastdata[index]?.monthlyReport[k]
                                              ?.noOfRoomsBooked *
                                            0
                                            : value?.rooms -
                                            pastdata[index]?.monthlyReport[k]
                                              ?.noOfRoomsBooked
                                          : 0}
                                      </Box>
                                      <Box
                                        style={{
                                          textAlign: "center",
                                          color:
                                            pastdata[index]?.monthlyReport[k]
                                              ?.arr !== undefined
                                              ? (pastdata[index]?.monthlyReport[
                                                k
                                              ]?.arr === -1
                                                ? value?.arr -
                                                pastdata[index]
                                                  ?.monthlyReport[k]?.arr *
                                                0
                                                : value?.arr -
                                                pastdata[index]
                                                  ?.monthlyReport[k]?.arr) <
                                                0
                                                ? "red"
                                                : "green"
                                              : "green",
                                        }}
                                      >
                                        {pastdata[index]?.monthlyReport[k]
                                          ?.arr !== undefined
                                          ? pastdata[index]?.monthlyReport[k]
                                            ?.arr === -1
                                            ? value?.arr -
                                            pastdata[index]?.monthlyReport[k]
                                              ?.arr *
                                            0
                                            : Math.floor(
                                              (value?.arr -
                                                pastdata[index]
                                                  ?.monthlyReport[k]?.arr) *
                                              100
                                            ) / 100
                                          : 0}
                                      </Box>
                                      <Box
                                        style={{
                                          textAlign: "center",
                                          color:
                                            pastdata[index]?.monthlyReport[k]
                                              ?.revenue !== undefined
                                              ? (pastdata[index]?.monthlyReport[
                                                k
                                              ]?.revenue < 0
                                                ? value?.revenue -
                                                pastdata[index]
                                                  ?.monthlyReport[k]
                                                  ?.revenue *
                                                0
                                                : value?.revenue -
                                                pastdata[index]
                                                  ?.monthlyReport[k]
                                                  ?.revenue) < 0
                                                ? "red"
                                                : "green"
                                              : "green",
                                        }}
                                      >
                                        {pastdata[index]?.monthlyReport[k]
                                          ?.revenue !== undefined
                                          ? pastdata[index]?.monthlyReport[k]
                                            ?.revenue < 0
                                            ? value?.revenue -
                                            pastdata[index]?.monthlyReport[k]
                                              ?.revenue *
                                            0
                                            : Math.floor(
                                              (value?.revenue -
                                                pastdata[index]
                                                  ?.monthlyReport[k]
                                                  ?.revenue) *
                                              100
                                            ) / 100
                                          : 0}
                                      </Box>
                                    </Stack>
                                  </TableCell>
                                </>
                              )}
                            </>
                          );
                        })}
                        <TableCell className="TotalBudgetCell">
                          <Stack direction={"column"} spacing={1.7}>
                            <Box style={{ textAlign: "center" }}>
                              {calculateTotal(index, "rooms")}
                            </Box>
                            <Box style={{ textAlign: "center" }}>
                              {calculateTotal(index, "arr")}
                            </Box>
                            <Box style={{ textAlign: "center" }}>
                              {calculateTotal(index, "revenue")}
                            </Box>
                          </Stack>
                        </TableCell>
                        <>
                          {stateArray[12] && (
                            <>
                              <TableCell>
                                <Stack spacing={2}>
                                  <Box style={{ textAlign: "center" }}>
                                    {calculateTotal(index, "actual_rooms")}
                                  </Box>
                                  <Box style={{ textAlign: "center" }}>
                                    {calculateTotal(index, "actual_adr")}
                                  </Box>
                                  <Box style={{ textAlign: "center" }}>
                                    {calculateTotal(index, "actual_revenue")}
                                  </Box>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                <Stack spacing={2}>
                                  <Box
                                    style={{ textAlign: "center" }}
                                    color={
                                      calVariance(
                                        calculateTotal(index, "rooms"),
                                        calculateTotal(index, "actual_rooms")
                                      ).color
                                    }
                                  >
                                    {
                                      calVariance(
                                        calculateTotal(index, "rooms"),
                                        calculateTotal(index, "actual_rooms")
                                      ).val
                                    }
                                  </Box>
                                  <Box
                                    style={{ textAlign: "center" }}
                                    color={
                                      calVariance(
                                        calculateTotal(index, "arr"),
                                        calculateTotal(index, "actual_adr")
                                      ).color
                                    }
                                  >
                                    {
                                      calVariance(
                                        calculateTotal(index, "arr"),
                                        calculateTotal(index, "actual_adr")
                                      ).val
                                    }
                                  </Box>
                                  <Box
                                    style={{ textAlign: "center" }}
                                    color={
                                      calVariance(
                                        calculateTotal(index, "revenue"),
                                        calculateTotal(index, "actual_revenue")
                                      ).color
                                    }
                                  >
                                    {
                                      calVariance(
                                        calculateTotal(index, "revenue"),
                                        calculateTotal(index, "actual_revenue")
                                      ).val
                                    }
                                  </Box>
                                </Stack>
                              </TableCell>
                            </>
                          )}
                        </>
                      </TableRow>
                    );
                  })}

                  {totalShow && (
                    <TableRow
                      style={{
                        backgroundColor: "rgb(48,111,188)",
                      }}
                    >
                      {!Loading && haveData && (
                        <>
                          <TableCell
                            className="sticky"
                            style={{
                              borderRight: "1px solid rgb(224,224,224)",
                              borderLeft: "1px solid rgb(224,224,224)",
                              minWidth: "120px",
                              backgroundColor: "rgb(48,111,188)",
                              color: "#fff",
                              fontWeight: "500",
                            }}
                          >
                            Total
                          </TableCell>
                          <TableCell
                            className="stickyRight"
                            style={{
                              borderRight: "1px solid rgb(224,224,224)",
                              padding: "0",
                              width: "120px",
                              minWidth: "120px",
                              backgroundColor: "rgb(48,111,188)",
                              color: "#fff",
                              fontWeight: "500",
                            }}
                          >
                            <Stack
                              className="KPICellStack"
                              direction="column"
                              spacing={1.3}
                            >
                              <Box className="KPICellBox">Occ%</Box>
                              <Box className="KPICellBox">Room Nights</Box>
                              <Box className="KPICellBox">ADR</Box>
                              <Box className="KPICellBox">Revenue</Box>
                              <Box className="KPICellBox">RevPAR</Box>
                            </Stack>
                          </TableCell>
                        </>
                      )}
                      {monthArray?.monthlyReport?.map((data, index) => {
                        return (
                          <>
                            <TableCell>
                              <Stack direction="column" spacing={2}>
                                <Box
                                  style={{
                                    textAlign: "center",
                                    color: "#fff",
                                    fontWeight: "500",
                                  }}
                                >
                                  {TotalColumnPerMonth(index, "occ")} %
                                </Box>
                                <Box
                                  style={{
                                    textAlign: "center",
                                    color: "#fff",
                                    fontWeight: "500",
                                  }}
                                >
                                  {TotalColumnPerMonth(index, "rooms")}
                                </Box>
                                <Box
                                  style={{
                                    textAlign: "center",
                                    color: "#fff",
                                    fontWeight: "500",
                                  }}
                                >
                                  {TotalColumnPerMonth(index, "adr")}
                                </Box>
                                <Box
                                  style={{
                                    textAlign: "center",
                                    color: "#fff",
                                    fontWeight: "500",
                                  }}
                                >
                                  {TotalColumnPerMonth(index, "revenue")}
                                </Box>
                                <Box
                                  style={{
                                    textAlign: "center",
                                    color: "#fff",
                                    fontWeight: "500",
                                  }}
                                >
                                  {TotalColumnPerMonth(index, "RevPAR")}
                                </Box>
                              </Stack>
                            </TableCell>

                            {stateArray[index] && (
                              <>
                                <TableCell>
                                  <Stack direction={"column"} spacing={2}>
                                    <Box
                                      style={{
                                        textAlign: "center",
                                        color: "#fff",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {TotalColumnPerMonth(index, "PastOcc")}
                                    </Box>
                                    <Box
                                      style={{
                                        textAlign: "center",
                                        color: "#fff",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {TotalColumnPerMonth(index, "PastRoom")}
                                    </Box>
                                    <Box
                                      style={{
                                        textAlign: "center",
                                        color: "#fff",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {TotalColumnPerMonth(index, "PastADR")}
                                    </Box>
                                    <Box
                                      style={{
                                        textAlign: "center",
                                        color: "#fff",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {TotalColumnPerMonth(
                                        index,
                                        "PastRevenue"
                                      )}
                                    </Box>
                                    <Box
                                      style={{
                                        textAlign: "center",
                                        color: "#fff",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {TotalColumnPerMonth(index, "PastRevPar")}
                                    </Box>
                                  </Stack>
                                </TableCell>
                                <TableCell>
                                  <Stack direction={"column"} spacing={2}>
                                    <Box
                                      style={{
                                        textAlign: "center",
                                        color: "#fff",
                                        fontWeight: "500",
                                      }}
                                    >
                                      -
                                    </Box>
                                    <Box
                                      style={{
                                        textAlign: "center",
                                        color: "#fff",
                                        fontWeight: "500",
                                      }}
                                    >
                                      -
                                    </Box>
                                    <Box
                                      style={{
                                        textAlign: "center",
                                        color: "#fff",
                                        fontWeight: "500",
                                      }}
                                    >
                                      -
                                    </Box>
                                    <Box
                                      style={{
                                        textAlign: "center",
                                        color: "#fff",
                                        fontWeight: "500",
                                      }}
                                    >
                                      -
                                    </Box>
                                    <Box
                                      style={{
                                        textAlign: "center",
                                        color: "#fff",
                                        fontWeight: "500",
                                      }}
                                    >
                                      -
                                    </Box>
                                  </Stack>
                                </TableCell>
                              </>
                            )}
                          </>
                        );
                      })}
                      {!Loading && haveData && (
                        <TableCell className="TotalBudgetCell">
                          <Stack direction={"column"} spacing={2}>
                            <Box
                              style={{
                                textAlign: "center",
                                color: "#fff",
                                fontWeight: "500",
                              }}
                            >
                              {Math.floor(
                                (budgetSheetInfo
                                  ?.map((val) =>
                                    val?.monthlyReport.reduce(
                                      (prv, next) => prv + next.rooms,
                                      0
                                    )
                                  )
                                  .reduce((prv, next) => prv + next, 0) /
                                  pastbudgetData?.reduce(
                                    (prv, next) => prv + next.roomsToSell,
                                    0
                                  )) *
                                10000
                              ) / 100}{" "}
                              %
                            </Box>
                            <Box
                              style={{
                                textAlign: "center",
                                color: "#fff",
                                fontWeight: "500",
                              }}
                            >
                              {budgetSheetInfo
                                ?.map((val) =>
                                  val.monthlyReport.reduce(
                                    (prv, next) => prv + next.rooms,
                                    0
                                  )
                                )
                                .reduce((prv, next) => prv + next, 0)}
                            </Box>
                            <Box
                              style={{
                                textAlign: "center",
                                color: "#fff",
                                fontWeight: "500",
                              }}
                            >
                              {Math.floor(
                                budgetSheetInfo
                                  ?.map((val) =>
                                    val.monthlyReport.reduce(
                                      (prv, next) => prv + next.rooms,
                                      0
                                    )
                                  )
                                  .reduce((prv, next) => prv + next, 0) === 0
                                  ? 0
                                  : (budgetSheetInfo
                                    .map((val) =>
                                      val.monthlyReport.reduce(
                                        (prv, next) => prv + next.revenue,
                                        0
                                      )
                                    )
                                    .reduce((prv, next) => prv + next, 0) /
                                    budgetSheetInfo
                                      ?.map((val) =>
                                        val.monthlyReport.reduce(
                                          (prv, next) => prv + next.rooms,
                                          0
                                        )
                                      )
                                      .reduce((prv, next) => prv + next, 0)) *
                                  100
                              ) / 100}
                            </Box>
                            <Box
                              style={{
                                textAlign: "center",
                                color: "#fff",
                                fontWeight: "500",
                              }}
                            >
                              {budgetSheetInfo
                                .map((val) =>
                                  val.monthlyReport.reduce(
                                    (prv, next) => prv + next.revenue,
                                    0
                                  )
                                )
                                .reduce((prv, next) => prv + next, 0)}
                            </Box>
                            <Box
                              style={{
                                textAlign: "center",
                                color: "#fff",
                                fontWeight: "500",
                              }}
                            >
                              {Math.floor(
                                (budgetSheetInfo
                                  .map((val) =>
                                    val.monthlyReport.reduce(
                                      (prv, next) => prv + next.revenue,
                                      0
                                    )
                                  )
                                  .reduce((prv, next) => prv + next, 0) /
                                  pastbudgetData?.reduce(
                                    (prv, next) => prv + next.roomsToSell,
                                    0
                                  )) *
                                100
                              ) / 100}
                            </Box>
                          </Stack>
                        </TableCell>
                      )}
                      {stateArray[12] && (
                        <>
                          <TableCell className="TotalBudgetCell">
                            <Stack direction={"column"} spacing={2}>
                              <Box
                                style={{
                                  textAlign: "center",
                                  color: "#fff",
                                  fontWeight: "500",
                                }}
                              >
                                {calculateTotal(-1, "past_total_occ")}%
                              </Box>
                              <Box
                                style={{
                                  textAlign: "center",
                                  color: "#fff",
                                  fontWeight: "500",
                                }}
                              >
                                {calculateTotal(-1, "past_total_rooms")}
                              </Box>
                              <Box
                                style={{
                                  textAlign: "center",
                                  color: "#fff",
                                  fontWeight: "500",
                                }}
                              >
                                {calculateTotal(-1, "past_total_adr")}
                              </Box>
                              <Box
                                style={{
                                  textAlign: "center",
                                  color: "#fff",
                                  fontWeight: "500",
                                }}
                              >
                                {calculateTotal(-1, "past_total_revenue")}
                              </Box>
                              <Box
                                style={{
                                  textAlign: "center",
                                  color: "#fff",
                                  fontWeight: "500",
                                }}
                              >
                                {calculateTotal(-1, "past_total_revpar")}
                              </Box>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Stack spacing={2}>
                              <Box
                                style={{ textAlign: "center" }}
                                color={
                                  calVariance(
                                    calculateTotal(-1, "total_budget_occ"),
                                    calculateTotal(-1, "past_total_occ")
                                  ).color
                                }
                              >
                                {
                                  calVariance(
                                    calculateTotal(-1, "total_budget_occ"),
                                    calculateTotal(-1, "past_total_occ")
                                  ).val
                                }
                              </Box>
                              <Box
                                style={{ textAlign: "center" }}
                                color={
                                  calVariance(
                                    calculateTotal(-1, "total_budget_rooms"),
                                    calculateTotal(-1, "past_total_rooms")
                                  ).color
                                }
                              >
                                {
                                  calVariance(
                                    calculateTotal(-1, "total_budget_rooms"),
                                    calculateTotal(-1, "past_total_rooms")
                                  ).val
                                }
                              </Box>
                              <Box
                                style={{ textAlign: "center" }}
                                color={
                                  calVariance(
                                    calculateTotal(-1, "total_budget_adr"),
                                    calculateTotal(-1, "past_total_adr")
                                  ).color
                                }
                              >
                                {
                                  calVariance(
                                    calculateTotal(-1, "total_budget_adr"),
                                    calculateTotal(-1, "past_total_adr")
                                  ).val
                                }
                              </Box>
                              <Box
                                style={{ textAlign: "center" }}
                                color={
                                  calVariance(
                                    calculateTotal(-1, "total_budget_revenue"),
                                    calculateTotal(-1, "past_total_revenue")
                                  ).color
                                }
                              >
                                {
                                  calVariance(
                                    calculateTotal(-1, "total_budget_revenue"),
                                    calculateTotal(-1, "past_total_revenue")
                                  ).val
                                }
                              </Box>
                              <Box
                                style={{ textAlign: "center" }}
                                color={
                                  calVariance(
                                    calculateTotal(-1, "total_budget_revpar"),
                                    calculateTotal(-1, "past_total_revpar")
                                  ).color
                                }
                              >
                                {
                                  calVariance(
                                    calculateTotal(-1, "total_budget_revpar"),
                                    calculateTotal(-1, "past_total_revpar")
                                  ).val
                                }
                              </Box>
                            </Stack>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  )}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </Paper>
          <StyledDialog open={isOpenDialog}>
            <DialogTitle className="content">
              <Typography variant="h6" fontWeight="500">
                Warning
              </Typography>
              <WarningIcon sx={{ color: "#a90f0f", ml: 1 }} />
            </DialogTitle>
            <DialogContent className="content">
              <IconButton
                className="closeButton"
                onClick={() => handleCloseDialog()}
              >
                <CancelIcon
                  sx={{
                    color: "#306fbc",
                  }}
                />
              </IconButton>
              <Typography
                sx={{ color: "black" }}
                variant="subtitle1"
                fontWeight={500}
                textAlign={"center"}
              >
                {/* You have unsaved changes. Do you wish to continue? */}
                Do you want to save your changes?
                <Typography
                  sx={{ color: "black" }}
                  variant="subtitle1"
                  fontWeight={500}
                  textAlign={"center"}
                >
                  Click “Yes” to save the changes or Click “No” to continue
                  without saving.
                </Typography>
              </Typography>
            </DialogContent>
            <DialogActions>
              <Stack
                direction={"row"}
                justifyContent={"center"}
                sx={{
                  width: "100%",
                  margin: "1%",
                  marginTop: "0",
                }}
                spacing={3}
              >
                <PrimaryButton
                  sx={{
                    width: "100%",
                    padding: "1%",
                    width: "75px",
                    height: "32px",
                  }}
                  onClick={() => {
                    handleDialogSave();
                  }}
                >
                  Yes
                </PrimaryButton>

                <PrimaryButton
                  sx={{
                    width: "100%",
                    padding: "1%",
                    width: "75px",
                    height: "32px",
                  }}
                  onClick={() => {
                    handleLeavePage();
                  }}
                >
                  No
                </PrimaryButton>
              </Stack>
            </DialogActions>
          </StyledDialog>
          <NextBtnContainer>
            <Stack direction={"row"} spacing={2}>
              <PrimaryButton
                type="submit"
                sx={{
                  backgroundColor:
                    formEdit && !lockBudget && canUserEditPastBudget
                      ? "#130453"
                      : "#a8a4a4 !important",
                }}
                onClick={saveBudgetSheetData}
                disabled={
                  !formEdit ? true : lockBudget || !canUserEditPastBudget
                }
              >
                Save
              </PrimaryButton>
              <PrimaryButton onClick={handleNext}>Next</PrimaryButton>
            </Stack>
          </NextBtnContainer>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={open}
            autoHideDuration={3000}
            onClose={handleCloseStatus}
          >
            <SnackbarContent
              style={{ backgroundColor: snackBarColor ? "#228b22" : "#CA3433" }}
              message={networkMsg}
            />
          </Snackbar>
        </>
      ) : Loading ? (
        <LoadingPage />
      ) : (
        <Nodata />
      )}

    </ClassWrapper>
  );
}

const StyledDialog = styled(Dialog)(() => ({
  ".closeButton": {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
  },
  ".content": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));