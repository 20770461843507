import { Modal } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import React, { useEffect, useState } from "react";
import { Prompt } from "react-router";
import { useParams } from "react-router-dom";
import { useAuth } from "../sdk";
import {
  ActualDemandLevelMultiplier,
  DayOfWeekMultiplier,
  LeadTimeMultiplier,
  ModelBody,
} from "./components";
import { useDayOfWeek, useDemandLevel, useLeadTime } from "./hooks";
import { Container, PageContainer } from "./Styles";

const initialMultipliers = {
  isDemandLevel: false,
  isDayOfWeek: false,
  isLeadTime: false,
};

const MULToUpdate = {
  ActualDemandLevel: false,
  DayOfWeek: false,
  LeadTime: false,
};

export default function ({ openSelectbar, setOpenSelectBar }) {
  const { hotelId } = useParams();
  const [copyFromHotelId, setCopyFromHotelId] = useState(null);
  const [copyHotelName, setCopyHotelName] = useState("");
  const [updateCheckbox, setUpdateCheckbox] = useState(false);
  const { token, authFetch, permission, hotels } = useAuth();
  const [updateSelectedMul, setupdateSelectedMul] = useState(MULToUpdate);
  const [selectMUL, setSelectMUL] = useState([]);
  const [allHotels, setAllHotels] = useState();

  useEffect(() => {
    if (token) {
      getAllHotels();
    } else {
      return;
    }
  }, [token]);

  let isAdmin = false;
  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "acrossHotel") {
        isAdmin = true;
      }
    }
  }

  async function getAllHotels() {
    if (isAdmin) {
      const { get } = await authFetch({
        path: `/hotel`,
      });
      const { data, error } = await get();
      if (data) {
        setAllHotels(data);
      } else {
        console.log(error);
      }
    } else {
      setAllHotels(hotels);
    }
  }
  const handleMULChange = (val) => {
    setSelectMUL(val);
  };
  const updateDLF = () => {
    setOpenSelectBar(false);
    setupdateSelectedMul({
      ActualDemandLevel: selectMUL.some((val) => val === "ActualDemandLevel"),
      DayOfWeek: selectMUL.some((val) => val === "DayOfWeek"),

      LeadTime: selectMUL.some((val) => val === "LeadTime"),
    });
  };

  const {
    demandLevels,
    dlIsInEditMode,
    setDlIsInEditMode,
    dlIsCancelled,
    dlSetIsCancelled,
    dlIsSaved,
    dlSetIsSaved,
    dlChangeHandler,
    dlopen,
    dlhandleCloseStatus,
    dlnetworkMsg,
    dlLoading,
    editDemandLevels,
    handleEditDemandsLevel,
    checkDemandLevelValidation,
  } = useDemandLevel(
    hotelId,
    copyFromHotelId,
    updateSelectedMul.ActualDemandLevel
  );
  const {
    daysOfWeek,
    dowIsInEditMode,
    setDowIsInEditMode,
    dowIsCancelled,
    dowSetIsCancelled,
    dowIsSaved,
    dowSetIsSaved,
    dowChangeHandler,
    dowopen,
    dowhandleCloseStatus,
    downetworkMsg,
    dowLoading,
    editDaysOfWeek,
    handleEditDaysOfWeekMultiplierChange,
  } = useDayOfWeek(hotelId, copyFromHotelId, updateSelectedMul.DayOfWeek);
  const {
    leadTimes,
    ltIsInEditMode,
    setLtIsInEditMode,
    ltIsCancelled,
    ltSetIsCancelled,
    ltIsSaved,
    ltSetIsSaved,
    ltChangeHandler,
    ltopen,
    lthandleCloseStatus,
    ltnetworkMsg,
    ltonRemoveRowHandler,
    ltonAddRowHandler,
    ltLoading,
    editLeadTimeMultiplier,
    handleEditLeadTimeMultiplierChange,
    checkLeadTimeMultiplierValidation,
  } = useLeadTime(hotelId, copyFromHotelId, updateSelectedMul.LeadTime);

  const [multipliers, setMultipliers] = useState(initialMultipliers);

  const handleMultiplier = (key, value) => {
    setMultipliers({
      ...multipliers,
      [key]: value,
    });
    setUpdateCheckbox((prev) => !prev);
  };

  useEffect(() => {
    async function GetMultipliers() {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/hotel-pricing-multiplier`,
      });
      const { data, error } = await get();
      if (data) {
        setMultipliers(data);
      } else {
        console.log(error);
      }
    }
    if (token) {
      GetMultipliers();
    }
  }, [token]);

  useEffect(() => {
    if (multipliers.id) {
      async function UpdateMultipliers() {
        const { put } = await authFetch({
          path: `/hotel/${hotelId}/hotel-pricing-multiplier`,
        });
        const { data, error } = await put(multipliers);
        setMultipliers(multipliers);
        if (data) {
        } else {
          console.log(error);
        }
      }
      UpdateMultipliers();
    }
  }, [updateCheckbox]);

  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);

  useEffect(() => {
    setShouldBlockNavigation(
      ltIsInEditMode || dowIsInEditMode || dlIsInEditMode
    );
  }, [ltIsInEditMode, dowIsInEditMode, dlIsInEditMode]);
  useEffect(() => {
    if (openSelectbar) setupdateSelectedMul(MULToUpdate);
  }, [openSelectbar]);
  return (
    <PageContainer>
      <Prompt
        when={shouldBlockNavigation}
        message="You have unsave changes, are you sure want to leave"
      />
      {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
      <Modal
        open={openSelectbar}
        onClose={() => {
          setOpenSelectBar(false);
        }}
      >
        <div>
          <ModelBody
            copyFromHotelId={copyFromHotelId}
            setCopyFromHotelId={setCopyFromHotelId}
            copyHotelName={copyHotelName}
            setCopyHotelName={setCopyHotelName}
            handleMULChange={handleMULChange}
            selectMUL={selectMUL}
            updateDLF={updateDLF}
            setOpenSelectBar={setOpenSelectBar}
            allHotels={allHotels}
            hotelId={hotelId}
          />
        </div>
      </Modal>
      <Container>
        <ActualDemandLevelMultiplier
          demandLevels={demandLevels}
          isInEditMode={dlIsInEditMode}
          setIsInEditMode={setDlIsInEditMode}
          isCancelled={dlIsCancelled}
          setIsCancelled={dlSetIsCancelled}
          isSaved={dlIsSaved}
          setIsSaved={dlSetIsSaved}
          onChange={dlChangeHandler}
          multipliers={multipliers}
          handleMultiplier={handleMultiplier}
          open={dlopen}
          handleCloseStatus={dlhandleCloseStatus}
          networkMsg={dlnetworkMsg}
          dlLoading={dlLoading}
          editDemandLevels={editDemandLevels}
          handleEditDemandsLevel={handleEditDemandsLevel}
          checkDemandLevelValidation={checkDemandLevelValidation}
        />
        <DayOfWeekMultiplier
          daysOfWeek={daysOfWeek}
          isInEditMode={dowIsInEditMode}
          setIsInEditMode={setDowIsInEditMode}
          isCancelled={dowIsCancelled}
          setIsCancelled={dowSetIsCancelled}
          isSaved={dowIsSaved}
          setIsSaved={dowSetIsSaved}
          onChange={dowChangeHandler}
          multipliers={multipliers}
          handleMultiplier={handleMultiplier}
          open={dowopen}
          handleCloseStatus={dowhandleCloseStatus}
          networkMsg={downetworkMsg}
          dowLoading={dowLoading}
          editDaysOfWeek={editDaysOfWeek}
          handleEditDaysOfWeekMultiplierChange={
            handleEditDaysOfWeekMultiplierChange
          }
        />
        <LeadTimeMultiplier
          leadTimes={leadTimes}
          isInEditMode={ltIsInEditMode}
          setIsInEditMode={setLtIsInEditMode}
          isCancelled={ltIsCancelled}
          setIsCancelled={ltSetIsCancelled}
          isSaved={ltIsSaved}
          setIsSaved={ltSetIsSaved}
          onChange={ltChangeHandler}
          multipliers={multipliers}
          handleMultiplier={handleMultiplier}
          open={ltopen}
          handleCloseStatus={lthandleCloseStatus}
          networkMsg={ltnetworkMsg}
          ltonRemoveRowHandler={ltonRemoveRowHandler}
          ltonAddRowHandler={ltonAddRowHandler}
          ltLoading={ltLoading}
          editLeadTimeMultiplier={editLeadTimeMultiplier}
          handleEditLeadTimeMultiplierChange={
            handleEditLeadTimeMultiplierChange
          }
          checkLeadTimeMultiplierValidation={checkLeadTimeMultiplierValidation}
        />
      </Container>
      {/* </LocalizationProvider> */}
    </PageContainer>
  );
}
