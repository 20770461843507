import {
  Box,
  Button,
  Divider,
  InputLabel,
  Select as SL,
  Table as TB,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CloseRounded, Schedule } from "@mui/icons-material";
import { styled } from "@mui/system";
import { Card, GhostButton as GB, PrimaryButton } from "../sdk";

export const Select = styled(SL)`
  .MuiSelect-root {
    width: 72px;
    color: #281e53;
    font: normal normal bold 16px/20px Roboto;
    height: 30px;
    padding-right: 30px;
    padding-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const ClassWrapper = styled(Box)(({ theme }) => ({
  ".dropdownStyle": {
    background: "#160E39",
    borderRadius: 4,
    color: "white",
  },
  ".icon": {
    fill: "white",
    display: "none",
  },
  ".input": {
    font: "normal normal bold 16px / 20px Roboto",
    color: "#281E53",
    margin: 0,
  },
}));

export const UploadBtn = styled(GB)`
  text-align: right;
  font: Bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #343434;
  display: flex;
  align-items: flex-end;
`;
export const GhostButton = styled(GB)`
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: red;
`;
export const Div2 = styled(Box)`
  display: flex;
  width: 100%;
  margin: 0px;
  padding: 0px;
`;

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 99%;
  height: 52px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  margin: auto;
`;

export const H = styled(Typography)`
  font: normal normal bold 24px/30px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
`;
export const Head = styled(Box)`
  position: fixed;
  top: 13px;
  left: 80px;
  color: white;
  z-index: 999;
`;

export const P1 = styled(Box)`
  font: Bold 24px/30px Roboto;
  letter-spacing: 0px;
  color: #0d0d0d;
  box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
`;
export const BusinessPage = styled(Box)`
  padding: 80px 20px 0px 80px;
  width: 100%;
`;
export const HeaderCard = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: #281e53;
  button.MuiButtonBase-root {
    opacity: 1;
    span.MuiTab-wrapper {
      font: normal normal bold 16px / 20px Roboto;
      color: #281e53;
    }
  }
`;
export const Hr = styled(Divider)`
  color: #f5f4f8;
  width: 1px;
  height: 30px;
`;

export const ScheduleIconDisabled = styled(Schedule)`
  &:hover {
    cursor: not-allowed;
  }
`;

export const Section = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  height: 200px;
`;

export const Filename = styled(Typography)`
  font: normal normal normal 12px/15px Roboto;
  margin-left: 10px;
`;
export const Title = styled(Typography)`
  font: normal normal normal 20px/25px Roboto;
  letter-spacing: 0px;
  color: #333333;
`;

export const CrossSmall = styled(CloseRounded)`
  width: 12px;
  height: 12px;
  margin-top: -30px;
  margin-left: 10px;
  :hover {
    cursor: pointer;
  }
`;

export const ModelHead = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const Label = styled(InputLabel)`
  font: normal normal bold 16px/20px Roboto;
  color: #130453;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 50px;
`;
export const DateSection = styled(Box)`
  display: flex;
  width: 100%;
  place-content: "center";
`;
export const UploadSection = styled(Box)`
  padding: 6px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Done = styled(Button)`
  background: #306fbc 0% 0% no-repeat padding-box;
  padding: 3%;
  width: 5vw;
  border: none;
  border-radius: 5px;
  font: normal normal bold 12px/15px Roboto;
  color: #ffffff;
`;

export const ChooseBtn = styled(InputLabel)`
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;

  line-height: 1.25;

  color: #000000;
  &:hover {
    cursor: pointer;
  }
  height: 24px;
  border: 1px solid #130453;
  border-radius: 5px;
  color: #130453;
  padding: 4px 10px;
`;

export const DialogLabel = styled(Box)`
  font-family: Roboto;
`;
export const ArrSection = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  min-height: 200px;
`;

export const Forecast = styled(Typography)`
  font: normal normal normal 20px/25px Roboto;
  letter-spacing: 0px;
  color: #333333;
`;
export const Cross = styled(CloseRounded)`
  width: 20px;
  height: 20px;
  :hover {
    cursor: pointer;
  }
`;

export const DateLabel = styled(InputLabel)`
  font: normal normal bold 16px/20px Roboto;
  color: #130453;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
`;
export const ArrLabel = styled(InputLabel)`
  font: normal normal bold 16px/20px Roboto;
  color: #130453;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
`;
export const ArrUploadSection = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`;
export const ArrDone = styled(PrimaryButton)`
  width: 80px;
  height: 24px;
  background: #130453 0% 0% no-repeat padding-box;
  border: 1px solid #130453;
  border-radius: 5px;
  font: normal normal bold 12px/15px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 12px;
`;

export const Table = styled(TB)`
  margin: auto;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.25;
`;

export const Th = styled(TableCell)`
  &.MuiTableCell-head {
    vertical-align: bottom;
    font: normal normal bold 16px/20px Roboto;
    color: #130453;
    background-color: white;
    border: none;
    text-align: center;
    padding: 10px 0px;
  }
`;
export const Td = styled(TableCell)`
  &.MuiTableCell-body {
    font: normal normal normal 16px/20px Roboto;
    color: #333333;
    border: none;
    height: 32px;
    text-align: center;
    padding: 0px;

    width: 130px;
  }
`;

export const Tr = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "rgba(48, 81, 221,0.09)",
  },
}));

export const Thead = styled(TableHead)``;
export const Tbody = styled(TableBody)``;

export const MyCard = styled(Card)`
  width: 440px;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 30px;
  height: 100%;
`;
export const RecommHead = styled(Typography)`
  text-align: center;
  font: normal normal normal 20px/25px Roboto;
  letter-spacing: 0px;
  color: #000000;
  padding: 0px 0 8px 0;
`;
export const Wrapper = styled(Box)`
  display: flex;
  margin: 0px;
  padding: 0px;
  overflow: scroll;
  overflow-y: hidden;
  height: calc(100vh - 132px);
`;
export const DATE = styled(Box)`
  display: flex;
`;
export const DAY = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  width: 50px;
`;
export const Bottom = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: no-wrap;
  font: normal normal normal 16px/20px Roboto;
  letter-spacing: 0px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  color: #333333;
  width: 100%;
`;
