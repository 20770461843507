import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getISODate, useAuth } from "../../sdk";

const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function useDOWAnalysis(
  selectedDate,
  comparitiveDate,
  setNetworkMsg,
  setLoading
) {
  const { token, authFetch } = useAuth();
  const { hotelId } = useParams();
  const [selectedData, setSelectedData] = useState({});
  const [comparitiveData, setComparitiveData] = useState({});
  const [occupancy, setOccupancy] = useState({ datasets: [], labels: [] });
  const [arr, setArr] = useState({ datasets: [], labels: [] });
  const [revpar, setRevpar] = useState({ datasets: [], labels: [] });
  const [open, setOpen] = useState(false);

  const weeklyOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    title: {
      display: true,
      fontFamily: "Roboto",
      fontSize: 20,
      fontColor: "black",
      fontStyle: "normal",
    },
    legend: {
      position: "bottom",
      align: "left",
      labels: {
        fontFamily: "Roboto",
        fontSize: 10,
        boxWidth: 10,
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: true,
            drawTicks: true,
            drawOnChartArea: false,
            color: "grey",
            zeroLineColor: "grey",
          },
          ticks: {
            fontFamily: "Roboto",
            fontColor: "black",
            fontSize: 10,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
            drawTicks: true,
            drawOnChartArea: true,
            color: "grey",
            zeroLineColor: "grey",
          },
          ticks: {
            beginAtZero: true,
            fontFamily: "Roboto",
            fontColor: "black",
            fontSize: 10,
          },
        },
      ],
    },
  };

  let occupancyOptions = JSON.parse(JSON.stringify(weeklyOptions));
  occupancyOptions.title = {
    display: true,
    text: "Occupancy%",
    fontFamily: "Roboto",
    fontSize: 15,
    fontColor: "black",
    fontStyle: "bold",
  };
  occupancyOptions.scales = {
    xAxes: [
      {
        gridLines: {
          display: true,
          drawTicks: true,
          drawOnChartArea: false,
          color: "grey",
          zeroLineColor: "grey",
        },
        ticks: {
          fontFamily: "Roboto",
          fontColor: "black",
          fontSize: 10,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
          drawTicks: true,
          drawOnChartArea: true,
          color: "grey",
          zeroLineColor: "grey",
        },
        ticks: {
          beginAtZero: true,
          steps: 10,
          stepValue: 5,
          max: 100,
          fontFamily: "Roboto",
          fontColor: "black",
          fontSize: 10,
          callback: function (value) {
            return value + "%";
          },
        },
      },
    ],
  };
  occupancyOptions.tooltips = {
    callbacks: {
      label: function (tooltipItems, data) {
        return tooltipItems.yLabel + "%";
      },
    },
  };
  let arrOptions = JSON.parse(JSON.stringify(weeklyOptions));
  arrOptions.title = {
    display: true,
    text: "Average Room Rate",
    fontFamily: "Roboto",
    fontSize: 15,
    fontColor: "black",
    fontStyle: "bold",
  };
  let revenueOptions = JSON.parse(JSON.stringify(weeklyOptions));
  revenueOptions.title = {
    display: true,
    text: "RevPAR",
    fontFamily: "Roboto",
    fontSize: 15,
    fontColor: "black",
    fontStyle: "bold",
  };

  useEffect(() => {
    if (!token) {
      return;
    } else {
      if (selectedDate) {
        WeeklySelectedReports();
      }
    }
  }, [token, hotelId, selectedDate]);

  useEffect(() => {
    if (!token) {
      return;
    } else {
      if (comparitiveDate) {
        WeeklyComparitiveReports();
      }
    }
  }, [token, hotelId, comparitiveDate]);

  useEffect(() => {
    if (!selectedData) {
      return;
    }
    SetupWeeklyData();
    dataForTable();
  }, [selectedData, comparitiveData, token, hotelId]);

  function DayNames(day) {
    switch (day) {
      case "monday":
        return "Mon";
        break;
      case "tuesday":
        return "Tue";
        break;
      case "wednesday":
        return "Wed";
        break;
      case "thursday":
        return "Thu";
        break;
      case "friday":
        return "Fri";
        break;
      case "saturday":
        return "Sat";
        break;
      default:
        return "Sun";
    }
  }

  async function WeeklySelectedReports() {
    setLoading(true);
    try {
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/weekly-report`,
      });
      const { data, response, error } = await post({
        month: getISODate(selectedDate),
      });
      if (response?.ok) {
        setSelectedData(data);
      } else {
        setSelectedData({});
        console.log(error);
        setNetworkMsg(
          data.messageToUser ||
            "Data Not Available for " +
              selectedDate.toLocaleString("default", { month: "long" }) +
              " " +
              selectedDate.getFullYear()
        );
        setOpen(true);
      }
    } catch (e) {
      setSelectedData({});
      setNetworkMsg(
        "Data Not Available for " +
          selectedDate.toLocaleString("default", { month: "long" }) +
          " " +
          selectedDate.getFullYear()
      );
      setOpen(true);
    }
    setLoading(false);
  }
  async function WeeklyComparitiveReports() {
    setLoading(true);
    try {
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/weekly-report`,
      });
      const { data, response, error } = await post({
        month: getISODate(comparitiveDate),
      });
      if (response?.ok) {
        setComparitiveData(data);
      } else {
        setComparitiveData({});
        console.log(error);
        setNetworkMsg(
          data?.messageToUser ||
            "Data Not Available for " +
              comparitiveDate.toLocaleString("default", { month: "long" }) +
              " " +
              comparitiveDate.getFullYear()
        );
        setOpen(true);
      }
    } catch (e) {
      setComparitiveData({});
      setNetworkMsg(
        "Data Not Available for " +
          comparitiveDate.toLocaleString("default", { month: "long" }) +
          " " +
          comparitiveDate.getFullYear()
      );
      setOpen(true);
    }
    setLoading(false);
  }

  function SetupWeeklyData() {
    if (Object.entries(selectedData).length !== 0) {
      const occSelKeys = Object.keys(selectedData.occupancy);
      const occData = {
        labels: occSelKeys.map((key) => {
          return DayNames(key);
        }),
        datasets: [
          {
            label: `${
              Months[new Date(selectedDate).getMonth()] +
              " " +
              new Date(selectedDate).getFullYear()
            }`,
            backgroundColor: "#1E90FF",
            data: occSelKeys.map((keys) => {
              return parseFloat(selectedData.occupancy[keys]).toFixed(2);
            }),
          },
          Object.entries(comparitiveData).length !== 0
            ? {
                label: `${
                  Months[new Date(comparitiveDate).getMonth()] +
                  " " +
                  new Date(comparitiveDate).getFullYear()
                }`,
                backgroundColor: "#B7B7B7",
                data: occSelKeys.map((keys) => {
                  return parseFloat(comparitiveData?.occupancy[keys]).toFixed(
                    2
                  );
                }),
              }
            : undefined,
        ].filter(Boolean),
      };
      const arrKeys = Object.keys(selectedData.arr);
      const arrData = {
        labels: arrKeys.map((key) => {
          return DayNames(key);
        }),
        datasets: [
          {
            label: `${
              Months[new Date(selectedDate).getMonth()] +
              " " +
              new Date(selectedDate).getFullYear()
            }`,

            backgroundColor: "#1E90FF",
            data: arrKeys.map((keys) => {
              return parseFloat(selectedData.arr[keys]).toFixed(2);
            }),
          },
          Object.entries(comparitiveData).length !== 0
            ? {
                label: `${
                  Months[new Date(comparitiveDate).getMonth()] +
                  " " +
                  new Date(comparitiveDate).getFullYear()
                }`,
                backgroundColor: "#B7B7B7",
                data: arrKeys.map((keys) => {
                  return parseFloat(comparitiveData?.arr[keys]).toFixed(2);
                }),
              }
            : undefined,
        ].filter(Boolean),
      };
      const revKeys = Object.keys(selectedData.revpar);
      const revData = {
        labels: revKeys.map((key) => {
          return DayNames(key);
        }),
        datasets: [
          {
            label: `${
              Months[new Date(selectedDate).getMonth()] +
              " " +
              new Date(selectedDate).getFullYear()
            }`,
            backgroundColor: "#1E90FF",
            data: revKeys.map((keys) => {
              return parseFloat(selectedData.revpar[keys]).toFixed(2);
            }),
          },
          Object.entries(comparitiveData).length !== 0
            ? {
                label: `${
                  Months[new Date(comparitiveDate).getMonth()] +
                  " " +
                  new Date(comparitiveDate).getFullYear()
                }`,
                backgroundColor: "#B7B7B7",
                data: revKeys.map((keys) => {
                  return parseFloat(comparitiveData?.revpar[keys]).toFixed(2);
                }),
              }
            : undefined,
        ].filter(Boolean),
      };
      setOccupancy(occData);
      setArr(arrData);
      setRevpar(revData);
    }
  }

  function dataForTable() {
    const dataArray = [];
    if (
      Object.entries(selectedData).length !== 0 &&
      Object.entries(comparitiveData).length !== 0
    ) {
      const occKeys = Object.keys(selectedData.occupancy);
      const arrKeys = Object.keys(selectedData.arr);
      const revKeys = Object.keys(selectedData.revpar);
      for (let key of occKeys) {
        dataArray.push([
          selectedData.occupancy[key],
          selectedData.arr[key],
          selectedData.revpar[key],
        ]);
      }
    }
  }

  const handleCloseStatus = () => {
    setOpen(false);
  };

  return {
    selectedData: selectedData,
    comparitiveData: comparitiveData,
    occupancy: occupancy,
    arr: arr,
    revpar: revpar,
    weeklyOptions: weeklyOptions,
    occupancyOptions: occupancyOptions,
    arrOptions: arrOptions,
    revenueOptions: revenueOptions,
    open: open,
    handleCloseStatus: handleCloseStatus,
  };
}
