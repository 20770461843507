import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../sdk";

const useRoleManagement = () => {
  const { authFetch } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [selectRoleData, setSelectedRoleData] = useState(null);
  const [input, setInput] = useState("");
  const [openAddRoleModal, setOpenAddRoleModal] = useState(false);

  const [openPermissionModal, setOpenPermissionModal] = useState(false);
  const [editPermission, setEditPermission] = useState(false);

  const [networkMsg, setnetworkMsg] = useState({
    msg: "",
    color: "",
    open: false,
  });

  const getRolesAndPermissions = useCallback(async () => {
    try {
      const { get } = await authFetch({
        path: `/all-roles-with-permission`,
      });
      const { data, error } = await get();

      if (data) {
        setRoleList(data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [authFetch]);

  useEffect(() => {
    getRolesAndPermissions();
  }, [getRolesAndPermissions]);
  console.log({ input });

  const getAllPermissions = useCallback(async () => {
    try {
      setIsLoading(true);
      const { get } = await authFetch({
        path: `/permission?search=${input}`,
      });
      const { data, error } = await get();
      if (data) {
        setPermissionList(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [authFetch, input]);

  useEffect(() => {
    if (input) {
      getAllPermissions();
    }
  }, [getAllPermissions, input]);

  return {
    isLoading,
    roleList,
    openPermissionModal,
    setOpenPermissionModal,
    selectRoleData,
    setSelectedRoleData,
    permissionList,
    getAllPermissions,
    editPermission,
    setEditPermission,
    getRolesAndPermissions,
    networkMsg,
    setnetworkMsg,
    openAddRoleModal,
    setOpenAddRoleModal,
    input,
    setInput,
  };
};

export default useRoleManagement;
