import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../sdk/hooks/useAuth";
import useForecastSheetUpload from "./useForecastSheetUpload";

export default function useLastNightActualModalUpload() {
  const date = new Date();
  const { token, authFetch } = useAuth();
  const { hotelId } = useParams();
  const { GetDataByDate } = useForecastSheetUpload();
  const [comparitiveDate, setComparitiveDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [networkMsgg, setNetworkMsgg] = useState("");
  const [openn, setOpenn] = useState();
  const [openLastSelectbar, setOpenLastSelectBar] = useState(false);
  const [toggleLast, setToggleLast] = useState(false);
  const [lastfilename, setlastFileName] = useState();
  const formdata = useRef(new FormData());

  async function lastfileHandler() {
    setOpenLastSelectBar(false);
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/upload-past-data-budget`,
    });

    const { data, error, response } = await post(formdata.current);
    // if ((data && Object.keys(data).length !== 0))
    if (response.status === 200) {
      setNetworkMsgg("File Uploaded Successfully");
      GetDataByDate();
      setOpenn(true);
      setToggleLast(false);
    } else {
      setNetworkMsgg(data?.messageToUser || "Invalid File");
      setOpenn(true);
      setToggleLast(false);
    }
    formdata.current = new FormData();
  }

  const removeLastFile = () => {
    setlastFileName("");
    formdata.current.delete("file");
    setToggleLast(!toggleLast);
  };

  const lastfileGetter = (file) => {
    setlastFileName(file[0].name);
    handleLastToggle();
    formdata.current.append("file", file[0]);
  };
  const handleLastToggle = () => {
    setToggleLast(!toggleLast);
  };

  return {
    comparitiveDate,
    setComparitiveDate,
    setOpenn,
    networkMsgg,
    openn,
    lastfileHandler,
    lastfileGetter,
    setOpenLastSelectBar,
    removeLastFile,
    lastfilename,
    handleLastToggle,
    toggleLast,
    openLastSelectbar,
  };
}
