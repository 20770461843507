import React from "react";

import {
  Table,
  TableBody,
  TableCell,
  FormControl,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  AddButtonIcon,
  CardContainer,
  RemoveButtonIcon,
  Tabhead,
  TCell,
  TContainer,
  TextFieldMedium,
  THead,
  TRow,
} from "./Styles";

export const AdvCell = styled(TableCell)`
  &.MuiTableCell-root {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: #000000;
    letter-spacing: 0px;

    padding: 5px 10px;
    text-align: ${(props) => (props.$isineditmode ? "left" : "center")};
    text-transform: capitalize;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

export const TabHead = styled(Tabhead)`
  &.MuiTableCell-root {
    height: 28px;
  }
`;

export default function ManualDLCheckTable({
  tableConfig,
  details,
  isInEditMode,
  onChange,
  onDLAddRowHandler,
  onDLRemoveRowHandler,
  onDLAdvRemoveRowHandle,
  onDLAdvAddRowHandler,
  dlMeedweekValue,
}) {
  return (
    <div>
      <CardContainer>
        <TContainer style={{ maxHeight: "45vh" }} component={Paper}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ width: "100%" }}
          >
            <THead style={{ height: "1px", padding: "0px" }}>
              <TRow>
                {isInEditMode && <TabHead />}

                <TabHead colSpan="2" $isineditmode={isInEditMode}>
                  Advance Days
                </TabHead>
                <TabHead colSpan="2" $isineditmode={isInEditMode}>
                  Occupancy
                </TabHead>
                <TabHead rowSpan="2" $isineditmode={isInEditMode}>
                  Recommended Level
                </TabHead>
                {isInEditMode && <TabHead rowSpan="2">Actions</TabHead>}
              </TRow>
              <TRow>
                {isInEditMode && (
                  <TabHead style={{ top: "30px", borderRadius: "0px" }}>
                    Actions
                  </TabHead>
                )}
                <TabHead
                  $isineditmode={isInEditMode}
                  style={{ top: "30px", borderRadius: "0px" }}
                >
                  From
                </TabHead>
                <TabHead $isineditmode={isInEditMode} style={{ top: "30px" }}>
                  To
                </TabHead>
                <TabHead $isineditmode={isInEditMode} style={{ top: "30px" }}>
                  From
                </TabHead>
                <TabHead
                  $isineditmode={isInEditMode}
                  style={{ top: "30px", borderRadius: "0px" }}
                >
                  To
                </TabHead>
              </TRow>
            </THead>
            <TableBody>
              {details?.map((data, idx) => (
                <TRow key={idx}>
                  {(data.advanceMin === "" ||
                    data.advanceMin === 0 ||
                    data.advanceMin) &&
                    isInEditMode && (
                      <AdvCell
                        rowSpan={tableConfig[data.index]?.rowSpan}
                        style={{ backgroundColor: "white" }}
                      >
                        <AddButtonIcon
                          onClick={() => onDLAdvAddRowHandler(idx)}
                          style={{ width: "20px", height: "20px" }}
                        />

                        <RemoveButtonIcon
                          onClick={() => onDLAdvRemoveRowHandle(idx)}
                          style={{ width: "20px", height: "20px" }}
                        />
                      </AdvCell>
                    )}
                  {(data.advanceMin === "" ||
                    data.advanceMin === 0 ||
                    data.advanceMin) && (
                    <AdvCell
                      rowSpan={tableConfig[data.index]?.rowSpan}
                      style={{ backgroundColor: "white" }}
                    >
                      {isInEditMode ? (
                        <TextFieldMedium
                          type="number"
                          value={data.advanceMin}
                          onChange={(e) => {
                            if (parseInt(e.target.value) > dlMeedweekValue) {
                              onChange(
                                idx,
                                "advanceMin",
                                dlMeedweekValue && parseInt(dlMeedweekValue)
                              );
                            } else {
                              onChange(
                                idx,
                                "advanceMin",
                                e.target.value && parseInt(e.target.value)
                              );
                            }
                          }}
                        />
                      ) : (
                        `${data.advanceMin}`
                      )}
                    </AdvCell>
                  )}
                  {(data.advanceMax === "" ||
                    data.advanceMax === 0 ||
                    data.advanceMax) && (
                    <AdvCell
                      rowSpan={tableConfig[data.index]?.rowSpan}
                      style={{ backgroundColor: "white" }}
                    >
                      {isInEditMode ? (
                        <TextFieldMedium
                          type="number"
                          value={data.advanceMax}
                          onChange={(e) => {
                            if (parseInt(e.target.value) > dlMeedweekValue) {
                              onChange(
                                idx,
                                "advanceMax",
                                dlMeedweekValue && parseInt(dlMeedweekValue)
                              );
                            } else {
                              onChange(
                                idx,
                                "advanceMax",
                                e.target.value && parseInt(e.target.value)
                              );
                            }
                          }}
                        />
                      ) : (
                        `${data.advanceMax}`
                      )}
                    </AdvCell>
                  )}
                  <TCell>
                    {isInEditMode ? (
                      <TextFieldMedium
                        type="number"
                        value={data.occMin}
                        onChange={(e) =>
                          onChange(
                            idx,
                            "occMin",
                            e.target.value && parseInt(e.target.value)
                          )
                        }
                      />
                    ) : (
                      data.occMin && `${data.occMin}%`
                    )}
                  </TCell>
                  <TCell
                    style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}
                  >
                    {isInEditMode ? (
                      <TextFieldMedium
                        type="number"
                        value={data.occMax}
                        onChange={(e) =>
                          onChange(
                            idx,
                            "occMax",
                            e.target.value && parseInt(e.target.value)
                          )
                        }
                      />
                    ) : (
                      data.occMax && `${data.occMax}%`
                    )}
                  </TCell>
                  <TCell>
                    {isInEditMode ? (
                      <FormControl>
                        <Select
                          value={data.demandLevel}
                          onChange={(e) =>
                            onChange(idx, "demandLevel", e.target.value)
                          }
                        >
                          <MenuItem value={"EH"}>EH</MenuItem>
                          <MenuItem value={"H+"}>H+</MenuItem>
                          <MenuItem value={"H"}>H</MenuItem>
                          <MenuItem value={"M"}>M</MenuItem>
                          <MenuItem value={"L"}>L</MenuItem>
                          <MenuItem value={"D"}>D</MenuItem>
                        </Select>
                      </FormControl>
                    ) : (
                      data.demandLevel
                    )}
                  </TCell>
                  {isInEditMode && (
                    <TCell>
                      <AddButtonIcon
                        onClick={() => onDLAddRowHandler(idx)}
                        style={{ width: "20px", height: "20px" }}
                      />

                      <RemoveButtonIcon
                        onClick={() => onDLRemoveRowHandler(idx)}
                        style={{ width: "20px", height: "20px" }}
                      />
                    </TCell>
                  )}
                </TRow>
              ))}
            </TableBody>
          </Table>
        </TContainer>
      </CardContainer>
    </div>
  );
}
