import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "../sdk";

export default function useColors() {
  const [categories, SetCategories] = useState([]);
  const [color, setColor] = useState("");
  const [networkMsg, setnetworkMsg] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { authFetch } = useAuth();
  const [confirmationDialog, setConfirmationDialog] = useState(null);
  const getCategories = useCallback(async () => {
    try {
      const { get } = await authFetch({
        path: `/event-categories`,
      });
      const { data } = await get();
      if (data) {
        SetCategories(data);
      }
    } catch (err) {
      console.log(err);
    }
  }, [authFetch]);

  const handleAddColor = useCallback(
    async (id) => {
      try {
        const { post } = await authFetch({
          path: `/event-categories`,
        });
        const { data, response } = await post({
          id,
          color,
        });
        if (response.status === 200) {
          getCategories();
        } else {
          setnetworkMsg(data?.messageToUser || "Something Went Wrong");
        }
      } catch (error) {
        console.log(error);
      }

      setColor("");
      setSelectedCategory(null);
      setAnchorEl(null);
    },
    [authFetch, color, getCategories]
  );

  const handleDelete = useCallback(
    async (id) => {
      try {
        const { del } = await authFetch({
          path: `/event-categories?id=${id}`,
        });

        const { data, response } = await del();
        if (response.status === 200) {
          getCategories();
        } else {
          setnetworkMsg(data?.messageToUser || "Something Went Wrong");
        }
      } catch (err) {
        console.log(err);
      }
      setConfirmationDialog(null);
    },
    [authFetch, getCategories]
  );

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedCategory(id);
  };

  return {
    categories,
    SetCategories,
    setAnchorEl,
    handleClick,
    anchorEl,
    setColor,
    color,
    networkMsg,
    setnetworkMsg,
    handleAddColor,
    handleDelete,
    selectedCategory,
    setSelectedCategory,
    setConfirmationDialog,
    confirmationDialog,
  };
}
