import React, { useRef } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  SnackbarContent,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  CalendarToday,
  Clear,
  Close,
  KeyboardBackspace,
  LocationOn,
  SaveAlt,
  Search,
} from "@mui/icons-material";
import {
  SearchBox,
  StyledCalenderWrapper,
  TB,
  TableWrapper,
  Td,
  Th,
} from "./styles";
import useAminEvents from "./useAminEvents";
import DatePicker from "react-modern-calendar-datepicker";
export const CalenderIcon = styled(CalendarToday)`
  position: absolute;
  top: "50%";
  width: 20px;
  height: 22px;
  color: #306fbc;
  z-index: 98;
  margin-left: -32px;
  margin-top: 4px;
  cursor: pointer;
`;
const OptionsType = [
  { label: "Generic", value: "generic" },
  { label: "City based", value: "city_based" },
];
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export default function AdminEvents() {
  const {
    dateNew,
    setDateNew,
    setPage,
    openSearch,
    setOpenSearch,
    searchInput,
    setSearchInput,
    eventDetails,
    page,
    count,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    networkMsg,
    setnetworkMsg,
    selectedType,
    setSelectedType,
    eventTypes,
    type,
    setType,
    options,
    selectedKeywords,
    setSelectedKeywords,
    setSearchKeyword,
    cityList,
    city,
    setCity,
    loading,
    downloadFile,
    cityAll,
    cityValue,
    setCityValue,
  } = useAminEvents();

  const handleType = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedType(typeof value === "string" ? value.split(",") : value);

    setPage(0);
  };

  const handleCity = (event) => {
    const {
      target: { value },
    } = event;
    setCity(typeof value === "string" ? value.split(",") : value);
    setPage(0);
  };

  const formatInputValue = () => {
    if (dateNew) {
      let fromValue;
      if (dateNew?.from) {
        fromValue =
          `${
            dateNew.from.day < 10 ? "0" + dateNew.from.day : dateNew.from.day
          }` +
          "/" +
          `${
            dateNew.from.month < 10
              ? "0" + dateNew.from.month
              : dateNew.from.month
          }` +
          "/" +
          `${dateNew.from.year}`;
      }
      let toValue;
      if (dateNew?.to) {
        toValue =
          `${dateNew.to.day < 10 ? "0" + dateNew.to.day : dateNew.to.day}` +
          "/" +
          `${
            dateNew.to.month < 10 ? "0" + dateNew.to.month : dateNew.to.month
          }` +
          "/" +
          `${dateNew.to.year}`;
      }

      return `${fromValue ?? "Start Date"} - ${toValue ?? "End Date"}`;
    }
    return "";
  };

  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValue()}
      style={{
        textAlign: "left",
        marginBottom: "2px",
        fontSize: "12px",

        borderRadius: "10px",

        color: "#000000",
        outline: "none",
        height: "35px",
        border: "none",
        fontWeight: 500,
        font: "500 12px / 14px Roboto",
        cursor: "pointer",
        width: "190px",
        paddingLeft: "10px",
      }}
      className="my-custom-input-class"
    />
  );
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const history = useHistory();
  return (
    <Box
      sx={{
        padding: "10px 10px 10px 10px",
        height: "79vh",
        backgroundColor: "#fff",
      }}
    >
      <Stack mt={1} direction={"row"} width={"100%"} justifyContent={"end"}>
        <Stack direction={"row"} gap={2}>
          <Tooltip arrow placement="top" title="Select Date Range">
            <StyledCalenderWrapper>
              <DatePicker
                value={dateNew}
                onChange={(date) => {
                  setDateNew(date);
                  setPage(0);
                }}
                colorPrimaryLight="#91ABE9"
                colorPrimary="#163A90"
                calendarPopperPosition="bottom" // Set reference to DatePicker
                calendarClassName="myCustomCalendar"
                renderInput={renderCustomInput}
              />
              <CalenderIcon />
            </StyledCalenderWrapper>
          </Tooltip>
          <Stack
            sx={{
              boxShadow: "0px 0px 4px rgba(48, 111, 188, 0.2)",
              borderRadius: "8px",
              overflow: "hidden",
            }}
            direction={"row"}
          >
            {openSearch && (
              <TextField
                placeholder="search"
                variant="outlined"
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e?.target?.value ?? "");
                  setPage(0);
                }}
                InputProps={{
                  endAdornment: searchInput ? (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearchInput("")} edge="end">
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
                sx={{
                  width: openSearch ? "180px" : "0px",
                  opacity: openSearch ? 1 : 0,
                  transition: "width 0.4s ease, opacity 0.3s ease",
                  "& .MuiInputBase-root": {
                    borderRadius: "8px",
                    height: "35px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #fff !important",
                    "&:focus": {
                      border: "1px solid #fff !important",
                      outline: "none",
                    },
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #fff !important",
                  },
                }}
              />
            )}
            <SearchBox
              onClick={() => {
                setOpenSearch((prev) => !prev);
              }}
            >
              <Search />
            </SearchBox>
          </Stack>
          <IconButton
            onClick={downloadFile}
            sx={{
              padding: "0 8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FFFFFF",
              borderRadius: "8px",
              color: "#306fbc",
              boxShadow: "0px 0px 4px rgba(48, 111, 188, 0.2)",
              height: "35px",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <SaveAlt />
          </IconButton>
        </Stack>
      </Stack>

      <Stack
        direction={"row"}
        justifyContent="start"
        alignItems="center"
        mt={1}
        gap={1}
      >
        <FormControl
          variant="outlined"
          sx={{
            width: "250px",
            borderRadius: "8px", // Add border radius to FormControl
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px", // Add border radius to the Select input
            },
          }}
          size="small"
        >
          <InputLabel id="event-category-label">Event Category</InputLabel>
          <Select
            multiple
            value={selectedType}
            onChange={handleType}
            disableUnderline
            label="Event Category"
            classes={{
              paper: "dropdownStyle",
            }}
            renderValue={(selected) =>
              selected
                .map((id) => eventTypes.find((type) => type?.id === id)?.name)
                .join(", ")
            }
            MenuProps={{
              PaperProps: {
                style: {
                  width: "250px",
                  maxHeight: "240px",
                  overflowY: "auto",
                },
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            style={{ color: "#110641" }}
          >
            {eventTypes.map((name) => (
              <MenuItem key={name?.id} value={name?.id}>
                <Checkbox checked={selectedType.includes(name?.id)} />
                <ListItemText
                  classes={{
                    primary: "dropdownStyle",
                  }}
                  sx={{
                    whiteSpace: "normal",
                    // wordBreak: "break-word",
                    // textWrap: "wrap",
                  }}
                  primary={name?.name}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Autocomplete
          multiple
          size="small"
          disableCloseOnSelect
          limitTags={2}
          options={cityAll ?? []}
          getOptionLabel={(option) => option}
          defaultValue={[]}
          value={cityValue}
          onChange={(e, value) => {
            setCityValue(value);
            setPage(0);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Event City"
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: null,
              }}
              sx={{
                label: {
                  background: "white",
                },
                ".MuiAutocomplete-inputRoot": {
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  width: 200,
                  overflowX: "auto",
                  flexWrap: "nowrap",
                  borderRadius: "8px",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  " -ms-overflow-style": "none",
                  " scrollbar-width": "none",
                  input: {
                    minWidth: 100,
                  },
                  fieldset: {
                    display: "none",
                  },
                },
                "& .MuiAutocomplete-inputRoot.Mui-focused": {
                  border: "2px solid #1976d2",
                },
              }}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              <ListItemText primary={option} />
            </li>
          )}
        />
        <TextField
          select
          label="Keyword Type"
          size="small"
          value={type}
          onChange={(e) => {
            setType(e?.target?.value ?? "");
            setSelectedKeywords([]);
            setPage(0);
          }}
          sx={{
            width: "180px",
            "& .MuiInputBase-root": {
              borderRadius: "8px",
            },
          }}
        >
          {OptionsType.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        {type === "city_based" && (
          <Autocomplete
            multiple
            size="small"
            disableCloseOnSelect
            limitTags={2}
            options={cityList ?? []}
            getOptionLabel={(option) => option}
            defaultValue={[]}
            value={city}
            onChange={(e, value) => {
              setCity(value);
              setPage(0);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="City"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: null,
                }}
                sx={{
                  label: {
                    background: "white",
                  },
                  ".MuiAutocomplete-inputRoot": {
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    width: 200,
                    overflowX: "auto",
                    flexWrap: "nowrap",
                    borderRadius: "8px",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    " -ms-overflow-style": "none",
                    " scrollbar-width": "none",
                    input: {
                      minWidth: 100,
                    },
                    fieldset: {
                      display: "none",
                    },
                  },
                  "& .MuiAutocomplete-inputRoot.Mui-focused": {
                    border: "2px solid #1976d2",
                  },
                }}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                <ListItemText primary={option} />
              </li>
            )}
          />
        )}

        <Autocomplete
          multiple
          size="small"
          limitTags={2}
          options={options ?? []}
          getOptionLabel={(option) => option.keywordName}
          defaultValue={[]}
          value={selectedKeywords}
          onChange={(e, value) => {
            setSelectedKeywords(value);
            setPage(0);
          }}
          onInputChange={(e, newInputValue) => setSearchKeyword(newInputValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Keywords"
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: null,
              }}
              sx={{
                label: {
                  background: "white",
                },
                ".MuiAutocomplete-inputRoot": {
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  width: 200,
                  overflowX: "auto",
                  flexWrap: "nowrap",
                  borderRadius: "8px",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  " -ms-overflow-style": "none",
                  " scrollbar-width": "none",
                  input: {
                    minWidth: 100,
                  },
                  fieldset: {
                    display: "none",
                  },
                },
                "& .MuiAutocomplete-inputRoot.Mui-focused": {
                  border: "2px solid #1976d2",
                },
              }}
            />
          )}
        />
      </Stack>
      {loading ? (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <TableWrapper>
            <TB
              stickyHeader
              aria-label="sticky table"
              style={{
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <TableHead
                sx={{
                  borderRadius: "8px 8px 0px 0px",
                }}
              >
                <TableRow>
                  <Th
                    style={{
                      borderRadius: "8px 0px 0px 0px",
                    }}
                  >
                    Event Name
                  </Th>
                  <Th>Event Category</Th>
                  <Th>Event Date</Th>
                  <Th>Event Venue</Th>
                  <Th>Event Address</Th>
                  <Th>Number of Attendees</Th>
                  <Th
                    style={{
                      borderRadius: "0px 8px 0px 0px",
                    }}
                  >
                    Event City
                  </Th>
                </TableRow>
              </TableHead>
              <TableBody>
                {eventDetails?.events?.length === 0 && (
                  <TableRow
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height={"200px"}
                  >
                    <TableCell
                      colSpan={12}
                      sx={{
                        textAlign: "center",
                        border: "0px",
                      }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        width="100%"
                      >
                        <Typography ml={1}>No Events Found</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                {eventDetails?.events?.length > 0 &&
                  eventDetails?.events?.map((event) => (
                    <TableRow key={event.id}>
                      <Td
                        sx={{
                          maxWidth: "150px",
                          textAlign: "left !important",
                        }}
                      >
                        {event?.eventname}
                      </Td>
                      <Td
                        sx={{
                          maxWidth: "120px",
                        }}
                      >
                        {event.category}
                      </Td>
                      <Td>{formatDate(event.startdate)}</Td>
                      <Td
                        sx={{
                          maxWidth: "100px",
                        }}
                      >
                        {event?.venue}
                      </Td>
                      <Td
                        sx={{
                          maxWidth: "170px",
                          textAlign: "left",
                        }}
                      >
                        <Box
                          display={"flex"}
                          justifyContent={event.address ? "start" : "center"}
                          width={"100%"}
                          gap={1}
                        >
                          {event?.latitude !== "None" &&
                            event?.longitude !== "None" && (
                              <Link
                                href={`https://www.google.com/maps/search/?api=1&query=${event?.latitude}%2C${event.longitude}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <LocationOn
                                  sx={{
                                    color: "#163A90",
                                  }}
                                />
                              </Link>
                            )}

                          <Typography
                            textAlign={event.address ? "left" : "center"}
                          >
                            {event.address ? event.address : "N/A"}
                          </Typography>
                        </Box>
                      </Td>
                      <Td>{event?.noOfAttendees ?? "N/A"}</Td>
                      <Td>{event?.city ?? "N/A"}</Td>
                    </TableRow>
                  ))}
              </TableBody>
            </TB>
          </TableWrapper>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={networkMsg}
        autoHideDuration={3000}
        onClose={() => setnetworkMsg(false)}
      >
        {networkMsg && (
          <SnackbarContent
            style={{
              backgroundColor:
                networkMsg === "Event Added Successfully"
                  ? "#228b22"
                  : "#CA3433",
            }}
            message={networkMsg}
          />
        )}
      </Snackbar>
    </Box>
  );
}
