import { Snackbar, SnackbarContent } from "@mui/material";
import React from "react";

export const onUniqueProp = (propName) => (value, index, self) => {
  return self.map((item) => item[propName]).indexOf(value[propName]) === index;
};
export const mapOnUniqueProp = (propName, array) => {
  const hashMap = new Map();
  array.map((data) => {
    const curr = hashMap.get(data[propName]);

    if (curr) {
      let desc = data.description + "####" + curr.description;
      console.log(data.description, "xxxxxx", curr.description);
      desc = Array.from(new Set(desc.split("####")).values())
        .filter((value) => !!value)
        .join("####");
      hashMap.set(data[propName], {
        ...curr,
        description: desc,
      });
    } else {
      hashMap.set(data[propName], data);
    }
  });
  return Array.from(hashMap.values());
};

export function formatNumber(number) {
  const formattedNumber =
    number === -1
      ? -1
      : number === 0
      ? 0
      : parseInt(number).toLocaleString("en-IN");
  return formattedNumber;
}

export function moveMonth(newDate, number) {
  return new Date(
    new Date(newDate).setMonth(new Date(newDate).getMonth() + number)
  ).getMonth();
}

export function getISODate(date) {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString();
}

const Days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export function getDateDDMM(date) {
  return (
    new Date(date).toLocaleDateString("en-GB").split("/")[0] +
    `/` +
    new Date(date).toLocaleDateString("en-GB").split("/")[1]
  );
}
export function getDateDDMMYYYY(date) {
  return (
    new Date(date).toLocaleDateString("en-GB").split("/")[0] +
    `/` +
    new Date(date).toLocaleDateString("en-GB").split("/")[1] +
    "/" +
    (new Date(date).toLocaleDateString("en-GB").split("/")[2] % 100)
  );
}
export function getFullDate(date) {
  return (
    new Date(date).toLocaleDateString("en-GB").split("/")[0] +
    `/` +
    new Date(date).toLocaleDateString("en-GB").split("/")[1] +
    "/" +
    new Date(date).toLocaleDateString("en-GB").split("/")[2]
  );
}
export function getDay(date) {
  return Days[new Date(date).getDay()];
}

export function getHrMin(dateString) {
  const date = new Date(dateString);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const am_pm = hours >= 12 ? "PM" : "AM";
  hours %= 12;
  hours = hours ? hours : 12;

  // return `${hours}:${minutes} ${am_pm}`;
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes} ${am_pm}`;
}

export function searchParamsToUrlQuery(searchParams) {
  const query = {};
  searchParams.forEach((value, key) => {
    if (typeof query[key] === "undefined") {
      query[key] = value;
    } else if (Array.isArray(query[key])) {
      query[key].push(value);
    } else {
      query[key] = [query[key], value];
    }
  });
  return query;
}
