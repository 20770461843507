import { styled } from "@mui/system";
import { CloseRounded } from "@mui/icons-material";
import { Link as LK } from "react-router-dom";
import { PrimaryButton } from "../../sdk";
import { Box, Typography } from "@mui/material";

export const Page = styled(Box)`
  height: 100%;
  width: 100%;
  padding: 60px 100px 60px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const RightSection = styled(Box)`
  display: flex;
  width: 80%;
  justify-content: flex-end;
  align-items: flex-end;

  @media (min-width: 1280px) {
    width: 70%;
  }
`;

export const Link = styled(LK)`
  text-decoration: none;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #322277;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 0px 6px;
  white-space: nowrap;
`;
export const CloneButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin: 0 10px;
  padding: 0px 6px;
  white-space: nowrap;
`;

export const Main = styled(Box)`
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  padding: 32px 0 40px 0;
  justify-content: space-between;
`;

export const OrganizationSection = styled(Box)`
  padding: 0 20px;
  height: 80%;
  width: 100%;
`;
export const OrganizationContainer = styled(LK)`
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  cursor: pointer;
`;
export const OrganizationName = styled(Typography)`
  font: normal normal bold 20px/25px Roboto;
  letter-spacing: 0px;
  color: #585858;
`;

export const HotelSection = styled(Box)`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  height: 80%;
  width: 100%;
`;

export const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-gap: 20px;
`;

export const ClassWrapper = styled(Box)(({ theme }) => ({
  ".paper": {
    outline: 0,
    position: "absolute",
    width: 600,
    height: 300,
    backgroundColor: "#fff",
    boxShadow: 5,
    padding: 16,
  },
}));
export const Div = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;
export const Div1 = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 15px;
`;
export const Mybtn = styled(PrimaryButton)`
  box-shadow: 0px 3px 6px #0000002f;
  border-radius: 4px;
  width: 88px;
  height: 28px;
  font: normal normal 600 12px/15px Roboto;
  color: #ffffff;
`;
export const CancelBtn = styled(PrimaryButton)`
  background: #d9d9d9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000019;
  border-radius: 4px;
  border: none;
  width: 88px;
  height: 28px;
  margin-right: 25px;
  font: normal normal 600 12px/15px Roboto;
  color: #575757;
`;
export const Head = styled(Box)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 20px;
`;
export const Cross = styled(CloseRounded)`
  width: 16px;
  height: 16px;
  :hover {
    cursor: pointer;
  }
`;
