import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const Card = styled(Box)`
  width: auto;
  margin: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Header = styled(Box)`
  display: flex;

  justify-content: space-between;
  align-items: baseline;
  width: auto;
  padding-bottom: 8px;
`;

export const CardName = styled(Typography)`
  text-align: left;
  font: normal normal normal 18px/20px Roboto;
  letter-spacing: 0px;
  color: #000000;
`;

export const CardDate = styled(Typography)`
  text-align: left;
  font: normal normal normal 14px/17px Roboto;
  letter-spacing: 0px;
  color: #000000;
`;
export const Body = styled(Box)``;

export const Info = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

export const Details = styled(Box)`
  justify-content: space-between;
  align-items: center;
  padding-left: 4px;
`;

export const Bookings = styled(Box)`
  display: flex;

  justify-content: flex-start;
  align-items: center;
  padding: 4px 0 4px 0;
`;

export const ARR = styled(Box)`
  display: flex;

  justify-content: flex-start;
  align-items: center;
  padding: 4px 0 4px 0;
  flex: 1;
  text-align: left;
`;
export const Revenue = styled(Box)`
  display: flex;

  justify-content: flex-start;
  align-items: center;

  padding: 4px 0 4px 0;
  flex: 1;
`;
export const RevPAR = styled(Box)`
  display: flex;

  justify-content: flex-start;
  align-items: center;

  padding: 4px 0 4px 0;
  flex: 1;
`;

export const Title = styled(Typography)`
  text-align: left;
  font: normal normal bold 14px/17px Roboto;
  letter-spacing: 0px;
  color: #110641;
  width: 96px;
  padding: 0 4px 0 4px;
`;
export const Value = styled(Typography)`
  text-align: left;
  font: normal normal normal 14px/17px Roboto;
  letter-spacing: 0px;
  color: #1d1d1d;
  padding: 0 4px 0 4px;
`;

export const Year = styled(Typography)`
  text-align: left;
  font: normal bold normal 18px/19px Roboto;
  letter-spacing: 0px;
  color: #000000;
  display: flex;
  justify-content: center;
`;
