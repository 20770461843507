import React from "react";
import { styled } from "@mui/system";
import { TableRow, Table as TB } from "@mui/material";

const Div1 = styled(TB)`
  display: flex;
  flex-direction: column;
  font: normal normal normal 16px/20px Roboto;
  letter-spacing: 0px;
  color: #333333;
  padding: 20px;
  width: 100%;
  margin: 0px auto;
`;
const Div2 = styled(TableRow)`
  display: flex;
  height: 32px;
  color: #333333;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Table = ({ children, ...props }) => {
  return <Div1 {...props}>{children}</Div1>;
};

export const Row = ({ children, ...props }) => {
  return <Div2 {...props}>{children}</Div2>;
};
