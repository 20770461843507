import {
  Button,
  Checkbox,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
} from "@mui/material";
import React from "react";
import { SecondaryButton, TableLoading } from "../../sdk";
import {
  CancelButton,
  CardContainer,
  Head,
  HeaderCard,
  SaveButton,
  Tabhead,
  TCell,
  TContainer,
  TextFieldSmall,
  THead,
  TRow,
} from "../Styles";
import { Edit } from "@mui/icons-material";
import { Select, MenuItem, FormControl } from "@mui/material";
import { DatePicker as DP } from "@mui/lab";
import { styled } from "@mui/system";

const EditButton = styled(Edit)`
  height: 18px;
  width: 18px;
  color: #306fbc;
`;

const GhostButton = styled(Button)`
  &:hover {
    border: 1px solid transparent;
    background-color: #f7f7f7;
    border-radius: 2px;
    color: #000000;
  }
  &:disabled {
  }

  &:active {
  }

  background: ${(props) => (props.isInEditMode ? "red" : "")};
`;
const DatePicker = styled(DP)`
  &.MuiFormControl-root {
    input {
      padding: 0;
      width: 90px;
    }
    .MuiInput-underline.MuiInputBase-formControl {
      height: 29px;
    }
  }
`;

const TextFieldBig = styled(TextFieldSmall)`
  width: 260px;
`;

export function ActualDemandLevelMultiplier({
  demandLevels = [],
  isInEditMode,
  setIsInEditMode,
  isCancelled,
  setIsCancelled,
  isSaved,
  setIsSaved,
  onChange,
  handleMultiplier,
  multipliers,
  open,
  handleCloseStatus,
  networkMsg,
  dlLoading,
  editDemandLevels,
  handleEditDemandsLevel,
  checkDemandLevelValidation,
}) {
  return (
    <>
      <CardContainer>
        <HeaderCard>
          <Head>
            <div className="title">Automated Demand&nbsp;Level</div>
            <Checkbox
              checked={multipliers.isDemandLevel}
              onChange={(e) =>
                handleMultiplier("isDemandLevel", e.target.checked)
              }
              style={{
                color: "rgb(48,111,188)",
                width: "30px",
                height: "30px",
              }}
            />
          </Head>
          {!isInEditMode ? (
            <SecondaryButton style={{ border: "none" }}>
              {/* Edit */}
            </SecondaryButton>
          ) : (
            <div>
              <CancelButton
                onClick={() => {
                  setIsCancelled(!isCancelled);
                  setIsInEditMode(!isInEditMode);
                }}
                style={{ margin: "5px 10px" }}
              >
                Cancel
              </CancelButton>
              <SaveButton
                onClick={() => {
                  setIsSaved(!isSaved);
                  setIsInEditMode(!isInEditMode);
                }}
                disabled={checkDemandLevelValidation}
                style={{ margin: "5px 10px" }}
              >
                Save
              </SaveButton>
            </div>
          )}
        </HeaderCard>
        {!dlLoading ? (
          <TContainer style={{ maxHeight: "50vh" }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{
                width: "100%",
                border: "1px solid rgba(0, 0, 0, 0.12)",

                borderRadius: "5px",
              }}
            >
              <THead>
                <TRow>
                  <Tabhead $isineditmode={isInEditMode}>Symbol</Tabhead>
                  <Tabhead $isineditmode={isInEditMode}>Min</Tabhead>
                  <Tabhead $isineditmode={isInEditMode}>Max</Tabhead>
                  <Tabhead $isineditmode={isInEditMode}>Value</Tabhead>
                  <Tabhead $isineditmode={isInEditMode}>Actions</Tabhead>
                </TRow>
              </THead>

              <TableBody>
                {demandLevels.map((row, idx) => (
                  <TRow key={idx}>
                    <TCell $isineditmode={isInEditMode}>
                      {editDemandLevels[idx] ? (
                        <FormControl>
                          <Select
                            value={row.symbol || ""}
                            onChange={(e) =>
                              onChange(idx, "symbol", e.target.value)
                            }
                          >
                            <MenuItem value={"EH"}>EH</MenuItem>
                            <MenuItem value={"H+"}>H+</MenuItem>
                            <MenuItem value={"H"}>H</MenuItem>
                            <MenuItem value={"M"}>M</MenuItem>
                            <MenuItem value={"L"}>L</MenuItem>
                            <MenuItem value={"D"}>D</MenuItem>
                          </Select>
                        </FormControl>
                      ) : (
                        row.symbol
                      )}
                    </TCell>
                    <TCell $isineditmode={isInEditMode}>
                      {editDemandLevels[idx] ? (
                        <TextFieldSmall
                          type="number"
                          value={row.min}
                          onChange={(e) =>
                            onChange(
                              idx,
                              "min",
                              parseInt(e.target.value) || e.target.value
                            )
                          }
                        />
                      ) : !row.min && row.min !== 0 ? (
                        ""
                      ) : (
                        row.min
                      )}
                    </TCell>
                    <TCell $isineditmode={isInEditMode}>
                      {editDemandLevels[idx] ? (
                        <TextFieldSmall
                          type="number"
                          value={row.max}
                          onChange={(e) =>
                            onChange(
                              idx,
                              "max",
                              parseInt(e.target.value) || e.target.value
                            )
                          }
                        />
                      ) : !row.max && row.max !== 0 ? (
                        ""
                      ) : (
                        row.max
                      )}
                    </TCell>
                    <TCell $isineditmode={isInEditMode}>
                      {editDemandLevels[idx] ? (
                        <TextFieldSmall
                          type="number"
                          value={row.value || ""}
                          onChange={(e) =>
                            onChange(
                              idx,
                              "value",
                              e.target.value && parseFloat(e.target.value)
                            )
                          }
                        />
                      ) : !row.value ? (
                        0
                      ) : (
                        row.value
                      )}
                    </TCell>
                    <TCell>
                      <EditButton onClick={() => handleEditDemandsLevel(idx)} />
                    </TCell>
                  </TRow>
                ))}
              </TableBody>
            </Table>
          </TContainer>
        ) : (
          <TableLoading />
        )}
      </CardContainer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseStatus}
      >
        <SnackbarContent
          style={{ backgroundColor: "#CA3433" }}
          message={networkMsg}
        />
      </Snackbar>
    </>
  );
}
