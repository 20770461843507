import { styled } from "@mui/system";
import { Box, Stack, InputLabel, Typography } from "@mui/material";
import React, { useState } from "react";
import { CloseRounded } from "@mui/icons-material";

import { PrimaryButton, MonthPicker } from "../../sdk";

function getModalStyle() {
  const top = 8;
  const right = 4;

  return {
    top: `${top}%`,
    right: `${right}%`,
    transform: `translate(-${top}, -${right})`,
  };
}

export const MonthPickerContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal bold 22px/28px Roboto;
`;

export const Section = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 8px 24px 8px 24px;
  height: 176px;
`;
export const ChooseBtn = styled(InputLabel)`
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;

  line-height: 1.25;

  color: #000000;
  &:hover {
    cursor: pointer;
  }
  height: 24px;
  border: 1px solid #130453;
  border-radius: 5px;
  color: #130453;
  padding: 4px 10px;
`;
export const Filename = styled(Typography)`
  font: normal normal normal 12px/15px Roboto;
  margin-left: 10px;
`;

export const Cross = styled(CloseRounded)`
  width: 16px;
  height: 16px;
  :hover {
    cursor: pointer;
  }
`;
export const CrossSmall = styled(CloseRounded)`
  width: 12px;
  height: 12px;
  margin-top: -30px;
  margin-left: 10px;
  :hover {
    cursor: pointer;
  }
`;
export const UploadSection = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Done = styled(PrimaryButton)`
  width: 80px;
  height: 24px;
  background: #130453 0% 0% no-repeat padding-box;
  border: 1px solid #130453;
  border-radius: 5px;
  font: normal normal bold 12px/15px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
`;

const StyledSection = styled(Section)(({ theme }) => ({
  position: "absolute",
  width: 370,
  height: 240,
  backgroundColor: "#fff",
  boxShadow: 5,
}));

export function ModelBody({
  networkMsgg,
  setOpenn,
  comparitiveDate,
  setComparitiveDate,
  lastfileHandler,
  lastfileGetter,
  setOpenLastSelectBar,
  removeLastFile,
  lastfilename,
  toggleLast,
}) {
  const [modalStyle] = useState(getModalStyle);
  return (
    <StyledSection style={modalStyle}>
      <Cross
        sx={{ marginRight: "-324px" }}
        onClick={() => setOpenLastSelectBar(false)}
      ></Cross>
      <Stack direction={"row"}>
        <MonthPickerContainer>
          <MonthPicker value={comparitiveDate} onChange={setComparitiveDate} />
        </MonthPickerContainer>
      </Stack>

      <ChooseBtn
        style={toggleLast ? { display: "none" } : { display: "block" }}
        htmlFor="upload"
      >
        Choose Last Night Actuals File
        <input
          type="file"
          id="upload"
          onChange={(e) => lastfileGetter(e.target.files)}
          style={{ display: "none" }}
        />
      </ChooseBtn>

      <UploadSection
        style={toggleLast ? { display: "flex" } : { display: "none" }}
      >
        <img
          src="/assets/excel.svg"
          alt="Excel Icon"
          width="36px"
          height="36px"
        />
        <Filename>{lastfilename}</Filename>
        <CrossSmall
          onClick={() => removeLastFile()}
          style={toggleLast ? { display: "block" } : { display: "none" }}
        >
          &times;
        </CrossSmall>
      </UploadSection>
      <Done disabled={!toggleLast} next onClick={lastfileHandler} type="button">
        Upload
      </Done>
    </StyledSection>
  );
}
