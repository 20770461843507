import { Snackbar, SnackbarContent, Table, TableBody } from "@mui/material";
import { Add, Remove, Edit } from "@mui/icons-material";
import React, { Fragment, memo } from "react";
import { styled } from "@mui/system";
import { SecondaryButton, TableLoading } from "./../../sdk";
import {
  CancelButton,
  CardContainer,
  Header,
  SaveButton,
  Tabhead,
  TCell,
  TContainer,
  TextFieldSmall,
  THead,
  TRow,
} from "./../Styles";

const RemoveButton = styled(Remove)`
  color: #306fbc;
  cursor: pointer;
  width: 16px;
`;
const AddButton = styled(Add)`
  color: #306fbc;
  cursor: pointer;
  width: 16px;
  height: 16px;
`;
const EditButton = styled(Edit)`
  height: 18px;
  width: 18px;
  color: #306fbc;
`;
const TableHead = styled(THead)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const LeadTimeCard = memo(
  ({
    leadTimes,
    isInEditMode,
    setIsInEditMode,
    isCancelled,
    setIsCancelled,
    isSaved,
    setIsSaved,
    onChange,
    open,
    handleCloseStatus,
    networkMsg,
    addRow,
    ltonRemoveRowHandler,
    ltonAddRowHandler,
    ltLoading,
    editLeadTimes,
    handleEditIconChange,
    checkLeadTimeValidation,
  }) => {
    return (
      <>
        <CardContainer>
          <Header>
            <div className="title">Lead Time</div>
            {!isInEditMode ? (
              <SecondaryButton style={{ border: "none" }}></SecondaryButton>
            ) : (
              <div>
                <CancelButton
                  onClick={() => {
                    setIsCancelled(!isCancelled);
                    setIsInEditMode(!isInEditMode);
                  }}
                >
                  Cancel
                </CancelButton>
                <SaveButton
                  onClick={() => {
                    setIsSaved(!isSaved);
                    setIsInEditMode(!isInEditMode);
                  }}
                  disabled={checkLeadTimeValidation}
                >
                  Save
                </SaveButton>
              </div>
            )}
          </Header>
          {!ltLoading ? (
            <TContainer style={{ maxHeight: "40vh" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                style={{
                  width: "100%",
                  border: "1px solid rgba(0, 0, 0, 0.12)",

                  borderRadius: "5px",
                }}
              >
                <THead style={{ height: "1px", padding: "0px" }}>
                  <TRow>
                    <Tabhead $isineditmode={isInEditMode}>Min</Tabhead>
                    <Tabhead $isineditmode={isInEditMode}>Max</Tabhead>
                    <Tabhead $isineditmode={isInEditMode}>Weekday</Tabhead>
                    <Tabhead $isineditmode={isInEditMode}>Weekend</Tabhead>
                    <Tabhead>Actions</Tabhead>
                  </TRow>
                </THead>
                <TableBody>
                  {leadTimes.map((row, idx) => (
                    <Fragment key={idx}>
                      <TRow key={idx}>
                        <TCell $isineditmode={editLeadTimes[idx]}>
                          {editLeadTimes[idx] ? (
                            <TextFieldSmall
                              type="number"
                              value={row.min}
                              inputProps={{
                                min: 0,
                                max: 180,
                              }}
                              onChange={(e) =>
                                onChange(
                                  idx,
                                  "min",
                                  e.target.value && parseInt(e.target.value)
                                )
                              }
                            />
                          ) : !row.min && row.min !== 0 ? (
                            ""
                          ) : (
                            row.min
                          )}
                        </TCell>
                        <TCell $isineditmode={editLeadTimes[idx]}>
                          {editLeadTimes[idx] ? (
                            <TextFieldSmall
                              type="number"
                              value={row.max === -1 ? "" : row.max}
                              inputProps={{
                                min: 0,
                                max: 180,
                              }}
                              onChange={(e) =>
                                onChange(
                                  idx,
                                  "max",
                                  e.target.value && parseInt(e.target.value)
                                )
                              }
                            />
                          ) : row.max === -1 ? (
                            "-"
                          ) : !row.max && row.max !== 0 ? (
                            ""
                          ) : (
                            row.max
                          )}
                        </TCell>
                        <TCell $isineditmode={editLeadTimes[idx]}>
                          {editLeadTimes[idx] ? (
                            <TextFieldSmall
                              type="number"
                              value={row.mwValue}
                              onChange={(e) =>
                                onChange(
                                  idx,
                                  "mwValue",
                                  e.target.value && parseFloat(e.target.value)
                                )
                              }
                            />
                          ) : !row.mwValue && row.mwValue !== 0 ? (
                            ""
                          ) : (
                            row.mwValue
                          )}
                        </TCell>
                        <TCell $isineditmode={editLeadTimes[idx]}>
                          {editLeadTimes[idx] ? (
                            <TextFieldSmall
                              type="number"
                              value={row.weValue}
                              onChange={(e) =>
                                onChange(
                                  idx,
                                  "weValue",
                                  e.target.value && parseFloat(e.target.value)
                                )
                              }
                            />
                          ) : !row.weValue && row.weValue !== 0 ? (
                            ""
                          ) : (
                            row.weValue
                          )}
                        </TCell>

                        <TCell
                          style={{
                            backgroundColor: "none",

                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            height: "40px",
                          }}
                        >
                          <AddButton onClick={() => ltonAddRowHandler(idx)} />
                          <RemoveButton
                            onClick={() => ltonRemoveRowHandler(idx)}
                          />
                          <EditButton
                            onClick={() => handleEditIconChange(idx)}
                          />
                        </TCell>
                      </TRow>
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </TContainer>
          ) : (
            <TableLoading />
          )}{" "}
        </CardContainer>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={open}
          autoHideDuration={3000}
          onClose={handleCloseStatus}
        >
          <SnackbarContent
            style={{ backgroundColor: "#CA3433" }}
            message={networkMsg}
          />
        </Snackbar>
      </>
    );
  }
);
