import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
  SnackbarContent,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import useChannelManager from "../hooks/useChannelManager";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import InputAdornment from "@mui/material/InputAdornment";

export default function ChannelManager() {
  const {
    hotelId,
    handleRedirect,
    propertyId,
    setPropertyId,
    channelManagerData,
    setChannelManagerData,
    roomData,
    handleSavePropertyId,
    handleSaveChannelmanager,
    snackbarMsg,
    setSnackBarMsg,
    isLoading,
    handleClickConnectionButton,
    handlePushDataClick,
    arrData,
    openPropertyIdModal,
    setOpenPropertyIdModal,
    handleSearchPropertyId,
    openWarningModal,
    setOpenWarningModal,
    actionOnConnectionButtonClick,
    formEdit,
    setformEdit,
  } = useChannelManager();

  const isDisabled =
    !(
      channelManagerData?.roomId &&
      channelManagerData?.roomRateGroupCode &&
      propertyId
    ) || !formEdit;

  return (
    <ChannelManagerPageStack>
      <Typography className="pageHeading">Channel Manager</Typography>
      <Stack className="borderStack" gap="16px">
        <Stack className="propertyStack">
          <TextField
            sx={{ width: "33%" }}
            label="Property Id"
            type="number"
            value={propertyId}
            disabled
          />
          <Button
            variant="contained"
            sx={{ height: "40px" }}
            onClick={() => {
              handleSearchPropertyId();
            }}
            disabled={!propertyId}
          >
            Search
          </Button>
        </Stack>
        <Stack direction="row" gap="16px">
          <TextField
            fullWidth
            label="Room Id"
            type="number"
            value={channelManagerData?.roomId}
            onChange={(e) => {
              setformEdit(true);
              setChannelManagerData((prevData) => ({
                ...prevData,
                roomId: e.target.value,
              }));
            }}
          />
          <TextField
            fullWidth
            label="Room Rate Group"
            type="number"
            value={channelManagerData?.roomRateGroupCode}
            onChange={(e) => {
              setformEdit(true);
              setChannelManagerData((prevData) => ({
                ...prevData,
                roomRateGroupCode: e.target.value,
              }));
            }}
          />
          <TextField
            fullWidth
            label="Discount"
            type="number"
            value={channelManagerData?.discount}
            className="discount"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography fontWeight="800">%</Typography>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setformEdit(true);
              if (e.target.value > 100) return;
              setChannelManagerData((prevData) => ({
                ...prevData,
                discount: parseInt(e.target.value),
              }));
            }}
          />
        </Stack>
      </Stack>
      <Stack className="buttonStack">
        <Stack direction="row" gap="16px" justifyContent="end">
          <LoadingButton
            variant="contained"
            onClick={handleSaveChannelmanager}
            disabled={isDisabled}
            loading={isLoading}
          >
            Save
          </LoadingButton>
          <Button
            variant="contained"
            onClick={() =>
              handleRedirect(`/hotel/${hotelId}/setup/competitors`)
            }
          >
            Next
          </Button>
        </Stack>
        <Stack direction="row" gap="16px" justifyContent="space-between">
          <Button
            variant="contained"
            disabled={!channelManagerData?.enablePropertyConnection}
            onClick={handlePushDataClick}
          >
            {channelManagerData?.enablePushData
              ? "Disable Push Data"
              : "Enable Push Data"}
          </Button>
          <Button
            variant="contained"
            onClick={actionOnConnectionButtonClick}
            disabled={
              !(
                channelManagerData?.roomId &&
                channelManagerData?.roomRateGroupCode &&
                !channelManagerData?.enablePushData &&
                propertyId
              )
            }
          >
            {channelManagerData?.enablePropertyConnection
              ? "Disable Connection"
              : "Enable Connection"}
          </Button>
        </Stack>
      </Stack>
      {arrData && (
        <Stack>
          <pre>{JSON.stringify(arrData, null, 2)}</pre>
        </Stack>
      )}
      <StyledDialog
        open={openPropertyIdModal}
        onClose={() => setOpenPropertyIdModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className="content">Mapping Data</DialogTitle>

        <CloseIcon
          className="closeButton"
          onClick={() => setOpenPropertyIdModal(false)}
        />
        <DialogContent className="content">
          {isLoading ? (
            <CircularProgress />
          ) : (
            <pre>{JSON.stringify(roomData, null, 2)}</pre>
          )}
        </DialogContent>
      </StyledDialog>

      <StyledDialog
        open={openWarningModal}
        onClose={() => setOpenWarningModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className="content">
          <Stack direction="row" alignItems="center" gap="8px">
            <Typography fontWeight="600" fontSize="24px">
              Warning
            </Typography>
            <WarningIcon sx={{ color: "#a90f0f" }} />
          </Stack>
        </DialogTitle>

        <CloseIcon
          className="closeButton"
          onClick={() => setOpenWarningModal(false)}
        />
        <DialogContent className="content">
          <Typography textAlign="center">
            Are you sure you want to disable the connection , as this might
            create problem in fetching and pushing the ARR to the channel
            Manager
          </Typography>
          <Stack direction="row" gap="40px" padding="24px">
            <Button
              variant="contained"
              onClick={() => setOpenWarningModal(false)}
            >
              No
            </Button>
            <Button variant="contained" onClick={handleClickConnectionButton}>
              Yes
            </Button>
          </Stack>
        </DialogContent>
      </StyledDialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snackbarMsg.open}
        autoHideDuration={6000}
        onClose={() => {
          setSnackBarMsg({
            ...snackbarMsg,
            open: false,
          });
        }}
      >
        <SnackbarContent
          style={{ backgroundColor: snackbarMsg.color }}
          message={snackbarMsg.msg}
        />
      </Snackbar>
    </ChannelManagerPageStack>
  );
}

const ChannelManagerPageStack = styled(Stack)(() => ({
  width: " 100%",
  background: "#ffffff",
  border: "none",
  padding: "20px",
  outline: "none",
  height: "calc(100vh - 160px)",
  overflowY: "scroll",
  overflowX: "hidden",
  boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
  gap: "24px",

  ".borderStack": {
    gap: "16px",
    padding: "24px",
    boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
  },
  ".pageHeading": {
    fontSize: "16px",
    fontWeight: "600",
    paddingLeft: "16px",
  },
  ".propertyStack": {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
  },
  ".buttonStack": {
    display: "flex",
    // flexDirection: "row",
    gap: "16px",
    justifyContent: "end",
  },
  ".discount": {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
  },
}));

const StyledDialog = styled(Dialog)(() => ({
  ".closeButton": {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
  },
  ".content": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));
