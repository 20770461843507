import { Popover, Tooltip, Zoom } from "@mui/material";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import AddEventsModel from "./AddEventsModel";
import DescriptionPopOver from "./DescriptionPopOver";
import { styled } from "@mui/system";
import {
  AutomatedDL,
  AutomatedDLContainer,
  Calender,
  Cell,
  Day,
  Description,
  DescriptionMore,
  DmdLvl,
  DmdLvlText,
  DotBDL as Dot,
  EventItem,
  Events,
  Grid,
  Info,
  LowerCell,
  Name,
  OTB,
  OTBSection,
  SingleEventContainerBDL as SingleEventContainer,
  UpperCell,
  Week,
} from "./style";

export const TextOnlyTooltipDescriptionPopover = styled(Tooltip)(() => ({
  ".tooltip": {
    font: "normal normal normal 14px/8px Roboto",
    lineHeight: "16px",
    marginLeft: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "4px",
    background: " #163A90 0% 0% no-repeat padding-box",
    boxShadow: "0px 4px 8px #00000026",
    color: "#ffffff",
    marginBottom: "2px",
  },
  ".arrow": {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "#163A90",
  },
}));

Date.prototype.monthDays = function () {
  let d = new Date(this.getFullYear(), this.getMonth() + 1, 0);
  return d.getDate();
};

export function BaseDemandLevelCard({
  baseDemandLevels,
  demandLevels,
  onChange,
  generateBatchBaseDemandLevels,
  setLoading,
  addEvent,
  addDemandLevel,
  addCityDemandLevel,
}) {
  const [copyofbasedemandlvl, setCopyofbasedemandlvl] = useState(
    baseDemandLevels
  );

  useEffect(() => {
    console.log(copyofbasedemandlvl);
  }, [copyofbasedemandlvl]);
  const [popOverIdx, setPopOverIdx] = useState(-1);
  const { hotelId } = useParams();
  const dummyBasedemandtoInsert = (baseDemandLevels) => {
    if (baseDemandLevels && baseDemandLevels.length) {
      setLoading(true);
      const StartDate = parseInt(baseDemandLevels[0].displayDate.split("/")[0]);
      const dummyForStart = [];
      for (let i = 1; i < StartDate; i++) {
        dummyForStart.push({
          demandLevelSymbol: "",
          date: new Date(
            baseDemandLevels[0].date.getFullYear(),
            baseDemandLevels[0].date.getMonth(),
            i
          ),
          description: "",
          displayDate: (i + "").length === 1 ? "0" + i : i + "",
          id: -1,
          month: baseDemandLevels[0].month,
        });
      }
      const lastDay = parseInt(
        baseDemandLevels[baseDemandLevels.length - 1].displayDate.split("/")[0]
      );
      const totaldays = new Date(
        baseDemandLevels[baseDemandLevels.length - 1].date
      ).monthDays();
      const dummyForLast = [];
      for (let i = lastDay + 1; i <= totaldays; i++) {
        dummyForLast.push({
          demandLevelSymbol: "",
          date: new Date(baseDemandLevels[0].date).setDate(
            new Date(baseDemandLevels[0].date).getDate() + i
          ),
          description: "",
          displayDate: (i + "").length === 1 ? "0" + i : i + "",
          id: -1,
          month: baseDemandLevels[0].month,
        });
      }
      const dummytoshiftDay = [];
      if (dummyForStart.length) {
        const day = new Date(dummyForStart[0].date).getDay();
        for (let i = 0; i < (day + 6) % 7; i++) {
          dummytoshiftDay.push({
            demandLevelSymbol: "",
            description: "",
            displayDate: "",
            eventId: -1,
            id: -1,
            month: "",
          });
        }
      } else {
        const day = new Date(baseDemandLevels[0].date).getDay();
        for (let i = 0; i < (day + 6) % 7; i++) {
          dummytoshiftDay.push({
            demandLevelSymbol: "",
            description: "",
            displayDate: "",
            eventId: -1,
            id: -1,
            month: "",
          });
        }
      }

      setCopyofbasedemandlvl([
        ...dummytoshiftDay,
        ...dummyForStart,
        ...baseDemandLevels,
        ...dummyForLast,
      ]);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (baseDemandLevels && baseDemandLevels.length) {
      dummyBasedemandtoInsert(baseDemandLevels);
    }
  }, [baseDemandLevels]);

  function withValidId(demandLevel) {
    return !!demandLevel.id;
  }

  return (
    <Calender>
      <Week>
        <Name>Monday</Name>
        <Name>Tuesday</Name>
        <Name>Wednesday</Name>
        <Name>Thursday</Name>
        <Name>Friday</Name>
        <Name>Saturday</Name>
        <Name>Sunday</Name>
      </Week>
      <Grid>
        {copyofbasedemandlvl &&
          copyofbasedemandlvl.map((item, index) =>
            item.id !== -1 ? (
              <PopupState variant="popover" popupId={`${index}`} key={index}>
                {(popupState) => (
                  <Fragment>
                    {item.description.length > 0 ? (
                      <TextOnlyTooltipDescriptionPopover
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 300 }}
                        disableFocusListener
                        disableTouchListener
                        placement="top"
                        arrow
                        interactive
                        title={
                          <DescriptionPopOver
                            index={index}
                            eventArray={item.description.split("####")}
                          />
                        }
                      >
                        <Cell
                          key={index}
                          {...bindTrigger(popupState)}
                          onClick={() => setPopOverIdx(index)}
                          style={{
                            background: `${item.cityColor}`,
                          }}
                        >
                          <Events>
                            <UpperCell>
                              <Day>{item.displayDate?.split("/")[0]}</Day>
                              <DmdLvlText>
                                <DmdLvl>{item.cityDemandLevelSymbol}</DmdLvl>
                              </DmdLvlText>
                            </UpperCell>
                            <LowerCell>
                              <Description style={{ cursor: "pointer" }}>
                                {item.description
                                  .split("####")
                                  .map((event, idx) =>
                                    event ? (
                                      <Fragment key={idx}>
                                        {idx < 1 && (
                                          <SingleEventContainer>
                                            <Dot></Dot>
                                            <EventItem>{event}</EventItem>
                                          </SingleEventContainer>
                                        )}
                                      </Fragment>
                                    ) : (
                                      <div key={idx}></div>
                                    )
                                  )}
                              </Description>
                              <DescriptionMore
                                style={{
                                  padding: "2px 0px 4px 0px",
                                  cursor: "pointer",
                                }}
                              >
                                {item.description.split("####").length > 1 && (
                                  <Info>
                                    {item.description.split("####").length - 1}{" "}
                                    more...
                                  </Info>
                                )}
                              </DescriptionMore>
                            </LowerCell>
                          </Events>
                          <OTBSection>
                            {
                              <OTB>
                                {item.hotelDemandLevelSymbol ||
                                item.hotelDemandLevelSymbol === ""
                                  ? !item.occupancyPercentage
                                    ? "0.00%"
                                    : `${item?.occupancyPercentage?.toFixed(
                                        2
                                      )}%`
                                  : ""}
                                {item.hotelDemandLevelSymbol && (
                                  <AutomatedDLContainer
                                    style={{
                                      marginLeft: "4px",
                                      backgroundColor: item.color,
                                    }}
                                  >
                                    <AutomatedDL>
                                      {item.hotelDemandLevelSymbol}
                                    </AutomatedDL>
                                  </AutomatedDLContainer>
                                )}
                              </OTB>
                            }
                            {item.systemDemandLevelSymbol && (
                              <AutomatedDLContainer
                                style={{
                                  background: `${
                                    item.systemDemandLevelColor
                                      ? item.systemDemandLevelColor
                                      : "transparent"
                                  }`,
                                }}
                              >
                                <AutomatedDL>
                                  {item.systemDemandLevelSymbol}
                                </AutomatedDL>
                              </AutomatedDLContainer>
                            )}
                          </OTBSection>
                        </Cell>
                      </TextOnlyTooltipDescriptionPopover>
                    ) : (
                      <Cell
                        key={index}
                        {...bindTrigger(popupState)}
                        onClick={() => setPopOverIdx(index)}
                        style={{
                          background: `${item.cityColor}`,
                        }}
                      >
                        <Events>
                          <UpperCell>
                            <Day>{item.displayDate?.split("/")[0]}</Day>
                            <DmdLvlText>
                              <DmdLvl>{item.cityDemandLevelSymbol}</DmdLvl>
                            </DmdLvlText>
                          </UpperCell>
                          <LowerCell>
                            <Description style={{ cursor: "pointer" }}>
                              {item.description
                                .split("####")
                                .map((event, idx) =>
                                  event ? (
                                    <Fragment key={idx}>
                                      {idx < 1 && (
                                        <SingleEventContainer>
                                          <Dot></Dot>
                                          <EventItem>{event}</EventItem>
                                        </SingleEventContainer>
                                      )}
                                    </Fragment>
                                  ) : (
                                    <div key={idx}></div>
                                  )
                                )}
                            </Description>
                            <DescriptionMore
                              style={{
                                padding: "2px 0px 4px 0px",
                                cursor: "pointer",
                              }}
                            >
                              {item.description.split("####").length > 1 && (
                                <Info>
                                  {item.description.split("####").length - 1}{" "}
                                  more...
                                </Info>
                              )}
                            </DescriptionMore>
                          </LowerCell>
                        </Events>
                        <OTBSection>
                          {
                            <OTB>
                              {item.hotelDemandLevelSymbol ||
                              item.hotelDemandLevelSymbol === ""
                                ? !item.occupancyPercentage
                                  ? "0.00%"
                                  : `${item?.occupancyPercentage?.toFixed(2)}%`
                                : ""}
                              {item.hotelDemandLevelSymbol && (
                                <AutomatedDLContainer
                                  style={{
                                    marginLeft: "4px",
                                    backgroundColor: item.color,
                                  }}
                                >
                                  <AutomatedDL>
                                    {item.hotelDemandLevelSymbol}
                                  </AutomatedDL>
                                </AutomatedDLContainer>
                              )}
                            </OTB>
                          }
                          {item.systemDemandLevelSymbol && (
                            <AutomatedDLContainer
                              style={{
                                background: `${
                                  item.systemDemandLevelColor
                                    ? item.systemDemandLevelColor
                                    : "transparent"
                                }`,
                              }}
                            >
                              <AutomatedDL>
                                {item.systemDemandLevelSymbol}
                              </AutomatedDL>
                            </AutomatedDLContainer>
                          )}
                        </OTBSection>
                      </Cell>
                    )}
                    {addEvent && (
                      <Popover
                        {...bindPopover(popupState)}
                        open={popOverIdx === index}
                        anchorOrigin={{
                          vertical: "center",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "center",
                          horizontal: "center",
                        }}
                        key={popOverIdx}
                      >
                        <AddEventsModel
                          generateBatchBaseDemandLevels={
                            generateBatchBaseDemandLevels
                          }
                          demandLevels={demandLevels}
                          popOverIdx={popOverIdx}
                          onChange={onChange}
                          setPopOverIdx={setPopOverIdx}
                          item={item}
                          addDemandLevel={addDemandLevel}
                          addCityDemandLevel={addCityDemandLevel}
                        />
                      </Popover>
                    )}
                  </Fragment>
                )}
              </PopupState>
            ) : (
              <Cell
                key={index}
                style={{ outline: item.displayDate ? "" : "0" }}
              >
                <Events>
                  <Day style={{ color: "rgba(0,0,0,0.3)" }}>
                    {item.displayDate.split("/")[0]}
                  </Day>
                  <DmdLvl></DmdLvl>
                  <Description></Description>
                </Events>
              </Cell>
            )
          )}
      </Grid>
    </Calender>
  );
}
