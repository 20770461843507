import { styled } from "@mui/system";
import { Table as T } from "../sdk";
import { Box, Typography, TableCell, TableHead } from "@mui/material";

export const Table = styled(T)`
  background-color: white;
  width: 100%;
  margin: 0px;
`;
export const Page = styled(Box)`
  width: 100%;
  height: 100%;
  padding: 56px 0px 22px 76px;
`;

export const Head = styled(Box)`
  position: fixed;
  top: 13px;
  left: 80px;
  color: white;
  z-index: 999;
  display: flex;
  align-items: center;
`;

export const H = styled(Typography)`
  font: normal normal bold 24px/30px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  padding-right: 8px;
`;

export const Body = styled(Box)`
  width: inherit;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98.5%;
  min-height: 6%;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  margin: 20px 0;
`;
export const SubHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
`;

export const Label = styled(Typography)`
  font: normal bold normal 14px/20px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  padding-right: 8px;
`;

export const TableCard = styled(Box)`
  display: flex;
  flex-wrap: no-wrap;
  width: 98.5%;
  background: none;
`;

export const Th = styled(TableHead)`
  flex: 1;
  padding-bottom: 20px;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #130453;
  width: 100px;
`;
export const Td = styled(TableCell)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
`;

export const Graph = styled(Box)`
  width: 100%;
  height: 100%;
  background: white;
  padding: 20px;
`;

export const DATE = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
`;
export const DAY = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 70px;
  padding-left: 15px;
`;

export const Segment = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
