import { Box, Table, TableContainer, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import * as React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, useAuth } from "../../sdk";
import { Tbody, Td, Th, Thead } from "./Styles";

const Days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export default function FileUploads() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { authFetch } = useAuth();
  const { hotelId } = useParams();
  const [data, setData] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  async function getUploads() {
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/uploaded-files?page=${
        page + 1
      }&limit=${rowsPerPage}`,
    });

    const { data, error } = await post();
    if (data) {
      const sorted_data = data.sort(function (a, b) {
        return new Date(b.uploaded_date) - new Date(a.uploaded_date);
      });
      setData(sorted_data);
    } else {
      console.log(error);
    }
  }

  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    if (parseInt(hours) <= 9) {
      hours = "0" + hours;
    }
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime.toUpperCase();
  }

  useEffect(() => {
    getUploads();
  }, [page, rowsPerPage]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <Paper elevation={0} sx={{ paddingLeft: "25px", paddingRight: "25px" }}>
        <Table>
          <Thead>
            <Row style={{ height: "40px" }}>
              <Th> Date</Th>
              <Th>Upload Time</Th>
              <Th>Type of File</Th>
              <Th>Name</Th>
              <Th> Uploaded File Source</Th>
              <Th>Download File</Th>
            </Row>
          </Thead>
          <Tbody />
        </Table>
        <TableContainer sx={{ maxHeight: 780, minWidth: 500 }}>
          <Table stickyHeader style={{ position: "relative" }}>
            <Thead />
            {data.length !== 0 &&
              data?.map((row, index) => {
                return (
                  <Tbody key={row.fileName}>
                    <Row
                      style={
                        index % 2 === 0
                          ? {
                              backgroundColor: "rgba(48, 81, 221,0.09)",
                              height: "52px",
                            }
                          : { height: "52px" }
                      }
                    >
                      <Td sx={{ borderBottom: "none" }}>
                        <Box marginRight={2}>
                          {new Date(row.uploaded_date).toLocaleDateString(
                            "en-GB"
                          )}
                        </Box>
                        {Days[new Date(row.uploaded_date).getDay()]}
                      </Td>

                      <Td
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        {formatAMPM(new Date(row.uploaded_date))}
                      </Td>

                      <Td sx={{ borderBottom: "none" }}>{row.type}</Td>

                      <Tooltip title={row.fileName} arrow placement="top">
                        <Td
                          sx={{
                            borderBottom: "none",
                            cursor: "pointer",
                            textAlign: "justify",
                            wordBreak: "break-all",
                          }}
                        >
                          <Box
                            component={"p"}
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "160px",
                            }}
                          >
                            {row.fileName}
                          </Box>
                        </Td>
                      </Tooltip>

                      <Td
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        {row.isEmailUpload === false
                          ? "Via System"
                          : "Via E-mail"}
                      </Td>

                      <Td
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Button
                          sx={{
                            textTransform: "none",
                          }}
                          target="_blank"
                          href={row.url}
                        >
                          Download
                        </Button>
                      </Td>
                    </Row>
                  </Tbody>
                );
              })}

            {data.length === 0 && (
              <Tbody>
                <Row
                  style={{
                    backgroundColor: "rgba(48, 81, 221,0.09)",
                  }}
                >
                  <Td sx={{ borderBottom: "none" }}>-</Td>
                  <Td sx={{ borderBottom: "none" }}>-</Td>
                  <Td sx={{ borderBottom: "none" }}>-</Td>
                  <Td sx={{ borderBottom: "none" }}>-</Td>
                  <Td sx={{ borderBottom: "none" }}>-</Td>
                  <Td sx={{ borderBottom: "none" }}>-</Td>
                </Row>
              </Tbody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={100}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to }) => null}
          nextIconButtonProps={{
            disabled: data.length < rowsPerPage,
          }}
        />
      </Paper>
    </div>
  );
}
