import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth } from "../../sdk";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const columns = [
  { id: "booking_code", label: "Booking Code", align: "center" },
  { id: "name", label: "Name ", minWidth: 100, align: "center" },
  {
    id: "phone_number",
    label: "Phone Number",
    minWidth: 100,
    align: "center",
  },
  {
    id: "email",
    label: "Email",
    align: "center",
  },
  {
    id: "checkin_date - checkout_date",
    label: "CheckIn Date - CheckOut Date",
    minWidth: 170,
    align: "center",
  },
  {
    id: "booking_date",
    label: "Booking Date",
    minWidth: 170,
    align: "center",
  },
  {
    id: "source",
    label: "Source",
    minWidth: 170,
    align: "center",
  },
  {
    id: "total_price",
    label: "Total Price",
    minWidth: 170,
    align: "center",
  },
];

export const useBookingsList = () => {
  const [bookingsList, setBookingsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { authFetch } = useAuth();
  const { hotelId } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0)

  const getBookingsList = useCallback(async () => {
    setIsLoading(true);
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/staah/get-bookings-details?page=${page}&limit=${rowsPerPage}`,
      });

      const { data } = await get();
    if (data?.error) {
      console.log(data?.error);
    } else {
      setBookingsList(data?.bookingsDetails ?? []);
      setTotalCount(data?.totalCount ?? 0)
    }
      
     setIsLoading(false);
  }, [authFetch, hotelId, page, rowsPerPage]);

  useEffect(() => {
    getBookingsList();
  }, [getBookingsList]);

  const rows = useMemo(
    () =>
      bookingsList?.map((booking) => ({
        booking_code: booking.reservationCode,
        name: `${booking.firstName} ${booking.lastName}`,
        phone_number: booking.phoneNumber,
        email: booking.email,
        "checkin_date - checkout_date": `${formatDate(
          booking.checkInAt
        )} - ${formatDate(booking.checkoutAt)}`,
        booking_date: formatDate(booking.date),
        source: booking.bookingSource,
        total_price: booking.totalPrice,
      })),
    [bookingsList]
  );

  return useMemo(() => ({ isLoading, rows, columns, page, setPage, rowsPerPage, setRowsPerPage, totalCount }), [isLoading, page, rows, rowsPerPage, totalCount]);
};
