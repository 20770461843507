import { Paper } from "@mui/material";
import React, { Fragment } from "react";
import {
  AddButtonIcon,
  CardContainer,
  RemoveButtonIcon,
  Tabhead,
  TCell,
  TContainer,
  TextFieldMedium,
  THead,
  TRow,
} from "./Styles";

import { Snackbar, SnackbarContent, Table, TableBody } from "@mui/material";

export default function ({
  isInEditMode,
  onChange,
  prices,
  open,
  handleCloseStatus,
  networkMsgv,
  onRemoveRowHandler,
  onAddRowHandler,
}) {
  return (
    <div>
      <CardContainer>
        <TContainer style={{ maxHeight: "50vh" }} component={Paper}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{
              width: "100%",
              border: "1px solid rgba(0, 0, 0, 0.12)",

              borderRadius: "8px 0px 0px 0px",
            }}
          >
            <THead style={{ height: "1px", padding: "0px" }}>
              <TRow>
                <Tabhead $isineditmode={isInEditMode}>From</Tabhead>
                <Tabhead $isineditmode={isInEditMode}>To</Tabhead>
                <Tabhead $isineditmode={isInEditMode}>Weekday</Tabhead>
                <Tabhead $isineditmode={isInEditMode}>Weekend</Tabhead>
                {isInEditMode && <Tabhead>Actions</Tabhead>}
              </TRow>
            </THead>
            <TableBody>
              {prices.map((row, idx) => (
                <Fragment key={idx}>
                  <TRow key={idx}>
                    <TCell $isineditmode={isInEditMode}>
                      {isInEditMode ? (
                        <TextFieldMedium
                          type="number"
                          value={row.occupancyMin}
                          onChange={(e) =>
                            onChange(
                              idx,
                              "occupancyMin",
                              e.target.value && parseInt(e.target.value)
                            )
                          }
                        />
                      ) : (
                        `${row.occupancyMin}%`
                      )}
                    </TCell>
                    <TCell $isineditmode={isInEditMode}>
                      {isInEditMode ? (
                        <TextFieldMedium
                          type="number"
                          value={
                            row.occupancyMax === -1 ? "" : row.occupancyMax
                          }
                          onChange={(e) =>
                            onChange(
                              idx,
                              "occupancyMax",
                              e.target.value && parseInt(e.target.value)
                            )
                          }
                        />
                      ) : row.occupancyMax === -1 ? (
                        "-"
                      ) : (
                        `${row.occupancyMax}%`
                      )}
                    </TCell>
                    <TCell $isineditmode={isInEditMode}>
                      {isInEditMode ? (
                        <TextFieldMedium
                          type="number"
                          value={row.basePriceMW}
                          onChange={(e) =>
                            onChange(
                              idx,
                              "basePriceMW",
                              e.target.value && parseFloat(e.target.value)
                            )
                          }
                        />
                      ) : (
                        `${row.basePriceMW}`
                      )}
                    </TCell>
                    <TCell $isineditmode={isInEditMode}>
                      {isInEditMode ? (
                        <TextFieldMedium
                          type="number"
                          value={row.basePriceWE}
                          onChange={(e) =>
                            onChange(
                              idx,
                              "basePriceWE",
                              e.target.value && parseFloat(e.target.value)
                            )
                          }
                        />
                      ) : (
                        `${row.basePriceWE}`
                      )}
                    </TCell>
                    {isInEditMode && (
                      <TCell>
                        <AddButtonIcon
                          onClick={() => onAddRowHandler(idx)}
                          style={{ width: "20px", height: "20px" }}
                        />

                        <RemoveButtonIcon
                          onClick={() => onRemoveRowHandler(idx)}
                          style={{ width: "20px", height: "20px" }}
                        />
                      </TCell>
                    )}
                  </TRow>
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TContainer>
      </CardContainer>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseStatus}
      >
        <SnackbarContent
          style={{ backgroundColor: "#CA3433" }}
          message={networkMsgv}
        />
      </Snackbar>
    </div>
  );
}
