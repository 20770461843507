import { TextField } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../sdk";
import { CancelBtn, Div, Div1, Div2, Mybtn, P, PopoverHeader } from "../Styles";

const initialPopupData = {
  overridePrice: 0,
  overrideReason: null,
};
const PopoverContent = ({
  date,
  refreshPriceRecommendations,
  recommendedPrice,
  setPopOverIdx,
  fileId,
  setnetworkMsg,
  setOpen,
}) => {
  const [popupData, setPopupData] = useState(initialPopupData);

  const { authFetch } = useAuth();
  const { hotelId } = useParams();

  const isValid = (data) => {
    return data.overridePrice != null && data.overrideReason != null;
  };

  const overrideHandler = async (id) => {
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/price-override/${id}`,
    });
    const { data, error, response } = await post({
      ...popupData,
      date,
    });
    if (response?.ok) {
      refreshPriceRecommendations();
      console.log(data);
    } else {
      setnetworkMsg(data?.messageToUser ?? "Something went wrong");
      setOpen(true);
      console.log(error);
    }
  };
  const changeHandler = (key, value) => {
    if (typeof value === "number" && value < 0) {
      value = value * -1;
    }
    if (typeof value === "number" && !Number.isInteger(value)) {
      value = Math.round((value + Number.EPSILON) * 100) / 100;
    }
    setPopupData({
      ...popupData,
      [key]: value,
    });
  };
  return (
    <Div>
      <PopoverHeader>
        Override Pricing Recommendation for{" "}
        {new Date(date).toLocaleDateString("en-GB")}
      </PopoverHeader>
      <P>
        Recommended Price &nbsp;&nbsp;&nbsp;{" "}
        {recommendedPrice ? Math.round(recommendedPrice) : "   -"}
      </P>
      <Div2>
        <P>New Price*</P>
        <TextField
          variant="standard"
          id="standard-number"
          type="number"
          required={true}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: "70px" }}
          value={popupData.overridePrice || ""}
          onChange={(e) =>
            changeHandler("overridePrice", parseInt(e.target.value))
          }
        />
      </Div2>
      <Div2>
        <P>Reason*</P>
        <TextField
          variant="standard"
          id="standard-required"
          style={{ width: "285px" }}
          required={true}
          value={popupData.overrideReason || ""}
          onChange={(e) => changeHandler("overrideReason", e.target.value)}
        />
      </Div2>
      <Div1>
        <CancelBtn onClick={() => setPopOverIdx(-1)}>Cancel</CancelBtn>
        <Mybtn
          next
          style={{ color: "#ffffff" }}
          onClick={(e) => {
            if (!isValid(popupData)) return;
            overrideHandler(fileId);
            setPopOverIdx(-1);
          }}
        >
          Confirm
        </Mybtn>
      </Div1>
    </Div>
  );
};
export default PopoverContent;
