import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { CalendarToday } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker as KD } from "@mui/lab";
import React, { useState } from "react";
import { styled } from "@mui/system";
import { getISODate } from "../../sdk";
import {
  BtnContainer,
  CancelBtn,
  CrossCFH as Cross,
  DatePicker,
  DatePickerSection,
  HeadCFH as Head,
  Label,
  ModalContainer,
  SubmitBtn,
} from "./style";
function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: "450px",
    width: "550px",
  };
}

const KeyboardDatePicker = styled(KD)`
  &.MuiFormControl-root {
    &.MuiFormHelperText-root {
      color: #f44336;
      position: absolute;
      top: 24px;
      font-size: 9px;
    }
  }
`;
const StyledModalContainer = styled(ModalContainer)(({ theme }) => ({
  position: "absolute",
  outline: 0,
  width: 500,
  height: 250,
  backgroundColor: "#fff",
  boxShadow: 5,
  padding: 16,
}));

export function CopyFromHotelModal({
  setCopyFromHotelId,
  isOpen,
  setOpen,
  onClose,
  allHotels,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setCheckbox,
  selectDL,
  handleDLChange,
  dLCheckbox,
  setIsChangeDl,
  currentHotelId,
}) {
  const [hotelId, setHotelId] = useState(null);
  const [hotelName, setHotelName] = useState("");
  const [modalStyle] = React.useState(getModalStyle);
  const [checked, setChecked] = useState(false);
  const [isHotelSelect, setIsHotelSelect] = useState(false);

  const DemandsLevels = ["CityDemandLevel", "CityEvent"];
  const handleChange = (event) => {
    if (isHotelSelect) {
      setChecked(event.target.checked);
    }
  };

  const defaultHotelProps = {
    options: [
      ...allHotels?.map((hotel) => {
        return hotel.name + " (" + hotel.organizationName + ")";
      }),
    ],
    getOptionLabel: (option) => option,
  };

  const handleHotelChange = (value) => {
    if (value) {
      const hotelName = value.split("(")[0].trim();
      const selectedHotel = allHotels.find((hotel) => hotel.name === hotelName);

      if (selectedHotel) {
        setHotelId(selectedHotel.id);
        setHotelName(selectedHotel.name);
        setIsHotelSelect(true);
      } else {
        setHotelId(null);
        setIsHotelSelect(false);
      }
    } else {
      setHotelId(null);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <StyledModalContainer style={modalStyle}>
        <Head>
          <Cross onClick={() => setOpen(false)}>&#x274C;</Cross>
        </Head>
        <FormControl variant="outlined" style={{ width: "375px" }}>
          <div>
            <Autocomplete
              {...defaultHotelProps}
              value={hotelName}
              sx={{ width: 380 }}
              freeSolo={true}
              onChange={(event, newValue) => {
                handleHotelChange(newValue);
              }}
              clearIcon={hotelName ? undefined : null}
              renderInput={(params) => (
                <TextField {...params} placeholder="Search Hotel" />
              )}
            />
            {isHotelSelect && (
              <FormControl variant="outlined" style={{ marginTop: "32px" }}>
                <InputLabel>Demand Level Factors</InputLabel>

                <Select
                  multiple
                  value={selectDL}
                  label="Demand Level Factors"
                  onChange={(e) => handleDLChange(e.target.value)}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Placeholder</em>;
                    }

                    return <em>{selected.join(", ")}</em>;
                  }}
                  MenuProps={{ getContentAnchorEl: null }}
                  style={{ width: "250px" }}
                >
                  {DemandsLevels.map((val, idx) => (
                    <MenuItem key={idx} value={val}>
                      <ListItemText primary={val} />
                      <Checkbox
                        style={{ color: "rgb(48,111,188)" }}
                        checked={selectDL.indexOf(val) > -1}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
          {isHotelSelect && (
            <div style={{ display: "flex", marginTop: "24px" }}>
              <Checkbox
                checked={checked}
                onChange={handleChange}
                style={{ color: "rgb(48,111,188)" }}
              />
              <Label style={{ marginTop: "13px", fontSize: "inherit" }}>
                Select Date Range
              </Label>
            </div>
          )}
          <div>
            {checked && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePickerSection
                  style={{
                    display: "flex",
                  }}
                >
                  <DatePicker>
                    <Label
                      style={{ fontSize: "inherit", color: " rgb(48,111,188)" }}
                    >
                      From
                    </Label>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      value={startDate}
                      onChange={(date) => setStartDate(getISODate(date))}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      style={{ width: "162px" }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      keyboardIcon={
                        <CalendarToday
                          style={{
                            color: "rgb(48,111,188)",
                            width: "21px",
                            height: "21px",
                            marginRight: "-1px",
                          }}
                        />
                      }
                    />
                  </DatePicker>

                  <DatePicker style={{ marginLeft: "8px" }}>
                    <Label
                      style={{ fontSize: "inherit", color: " rgb(48,111,188)" }}
                    >
                      To
                    </Label>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      minDate={
                        new Date(
                          new Date(startDate).setDate(
                            new Date(startDate).getDate() + 1
                          )
                        )
                      }
                      value={endDate}
                      style={{ width: "162px", marginLeft: "6px" }}
                      onChange={(date) => setEndDate(getISODate(date))}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      error={false}
                      helperText={null}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      keyboardIcon={
                        <CalendarToday
                          style={{
                            color: "rgb(48,111,188)",
                            width: "21px",
                            height: "21px",
                            marginRight: "-1px",
                          }}
                        />
                      }
                    />
                  </DatePicker>
                </DatePickerSection>
              </LocalizationProvider>
            )}
          </div>
          <BtnContainer style={{ marginTop: "60px" }}>
            <CancelBtn onClick={() => setOpen(false)}>Cancel</CancelBtn>
            <SubmitBtn
              next
              disabled={
                new Date(endDate).setHours(0, 0, 0) <
                  new Date(startDate).setHours(0, 0, 0) ||
                hotelId === null ||
                hotelId === parseInt(currentHotelId)
                  ? true
                  : false
              }
              style={
                new Date(endDate).setHours(0, 0, 0) <
                  new Date(startDate).setHours(0, 0, 0) ||
                hotelId === null ||
                hotelId === parseInt(currentHotelId)
                  ? {
                      color: "#ffffff",
                      cursor: "not-allowed",
                    }
                  : {
                      color: "#ffffff",
                    }
              }
              onClick={() => {
                setCopyFromHotelId(hotelId);
                setOpen(false);
                setCheckbox(checked);
                setIsChangeDl(dLCheckbox);
              }}
            >
              Confirm
            </SubmitBtn>
          </BtnContainer>
        </FormControl>
      </StyledModalContainer>
    </Modal>
  );
}
