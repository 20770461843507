import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../sdk/hooks/useAuth";
import { useWarning } from "../../Provider/context";
import { format, parseISO } from "date-fns";

export default function useRoomAdjustment() {
  const { token, authFetch, version } = useAuth();
  const { formEdit, setformEdit, handleRedirect } = useWarning();
  const { hotelId } = useParams();
  const [budgetInfo, setBudgetInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [view, setView] = useState("module");
  const [budgetingPeriod, setBudgetingPeriod] = useState(null);
  const [apiResponse, setApiResponse] = useState({ msg: null, color: null });
  const [openModalTrue, setOpenModalTrue] = useState(false);
  const [networkMsg, setnetworkMsg] = useState({
    open: false,
    color: "",
    msg: "",
  });
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  let curr_year = new Date().getFullYear();
  const [year, setYear] = useState(curr_year);

  const currentDate = new Date();
  currentDate.setUTCFullYear(
    currentDate.getUTCFullYear(),
    currentDate.getUTCMonth(),
    1
  );
  currentDate.setUTCHours(0, 0, 0, 0);

  const currYear = currentDate.getUTCFullYear();
  const currMonth = String(currentDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getUTCDate()).padStart(2, "0");

  const dateTime = `${currYear}-${currMonth}-${day}T00:00:00Z`;

  const [openAddRoomModal, setOpenAddRoomModal] = useState(false);
  const [selectedMonthYear, setSelectedMonthYear] = useState(dateTime);

  useEffect(() => {
    setSelectedMonthYear(dateTime);
  }, []);

  const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleChangeNew = (event, nextView) => {
    setView(nextView);
  };

  const GetBudget = useCallback(async () => {
    try {
      setIsLoading(true);
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/room-adjustmentv2/${year}`,
      });
      const { data } = await get();
      if (data) {
        setBudgetInfo(data?.data);
        setBudgetingPeriod(data?.budgetingPeriod);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [authFetch, hotelId, year]);

  useEffect(() => {
    if (token) {
      GetBudget();
    }
  }, [GetBudget, token]);

  const handleCloseStatus = useCallback(() => {
    setOpen(false);
    setnetworkMsg({ ...networkMsg, open: false });
  }, [networkMsg]);

  const handleYearChange = (e) => {
    setYear(e);
  };

  const handleNextButtonClick = useCallback(() => {
    if (version === "v2") {
      handleRedirect(`/hotel/${hotelId}/setup/market-segment`);
    } else {
      handleRedirect(`/hotel/${hotelId}/setup/hotel-pricing`);
    }
  }, [handleRedirect, hotelId, version]);

  return {
    hotelId,
    budgetInfo,
    networkMsg,
    open,
    setOpen,
    MONTHS,
    handleCloseStatus,
    handleYearChange,
    year,
    curr_year,
    budgetingPeriod,
    GetBudget,
    formEdit,
    setformEdit,
    handleChangeNew,
    view,
    handleNextButtonClick,
    isLoading,
    openAddRoomModal,
    setOpenAddRoomModal,
    selectedMonthYear,
    setSelectedMonthYear,
    apiResponse,
    setApiResponse,
    openModalTrue,
    setOpenModalTrue,
    openSnackbar,
    setOpenSnackbar,
  };
}
