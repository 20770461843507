import React from "react";
import { styled } from "@mui/system";
import { Box, CircularProgress } from "@mui/material";

const Wrapper = styled(Box)`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const TableLoading = () => {
  return (
    <Wrapper>
      <CircularProgress
        style={{ color: "#110641", width: "25px", height: "25px" }}
      />
    </Wrapper>
  );
};
