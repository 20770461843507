import { Snackbar, SnackbarContent, Table, TableBody } from "@mui/material";
import { Add, Remove, Edit } from "@mui/icons-material";
import React, { useState } from "react";
import { styled } from "@mui/system";
import { SecondaryButton, TableLoading } from "./../../sdk";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import {
  CancelButton,
  CardContainer,
  Header,
  SaveButton,
  Tabhead,
  TCell,
  TContainer,
  TextFieldSmall,
  THead,
  TRow,
} from "./../Styles";

const RemoveButton = styled(Remove)`
  color: #306fbc;
  cursor: pointer;
  width: 16px;
  height: 16px;
`;
const AddButton = styled(Add)`
  color: #306fbc;
  cursor: pointer;
  width: 16px;
`;

const EditButton = styled(Edit)`
  height: 18px;
  width: 18px;
  color: #306fbc;
`;

export function OccupancyCard({
  occupancies,
  isInEditMode,
  setIsInEditMode,
  isCancelled,
  setIsCancelled,
  isSaved,
  setIsSaved,
  onChange,
  open,
  handleCloseStatus,
  networkMsg,
  OonRemoveRowHandler,
  OonAddRowHandler,
  OLoading,
  editOccupancies,
  handleEditOccupancyChange,
  checkOccupancyValidation,
}) {
  const [openAddDialog, setOpenAddDialog] = useState(false);
  return (
    <>
      <CardContainer>
        <Header>
          <div className="title">Occupancy</div>
          {!isInEditMode ? (
            <SecondaryButton style={{ border: "none" }}></SecondaryButton>
          ) : (
            <div>
              <CancelButton
                onClick={() => {
                  setIsCancelled(!isCancelled);
                  setIsInEditMode(!isInEditMode);
                }}
              >
                Cancel
              </CancelButton>
              <SaveButton
                onClick={() => {
                  setIsSaved(!isSaved);
                  setIsInEditMode(!isInEditMode);
                }}
                disabled={checkOccupancyValidation}
              >
                Save
              </SaveButton>
            </div>
          )}
        </Header>
        {!OLoading ? (
          <TContainer style={{ maxHeight: "50vh" }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{
                width: "100%",
                border: "1px solid rgba(0, 0, 0, 0.12)",

                borderRadius: "5px",
              }}
            >
              <THead style={{ height: "1px" }}>
                <TRow>
                  <Tabhead $isineditmode={isInEditMode}>Min</Tabhead>
                  <Tabhead $isineditmode={isInEditMode}>Max</Tabhead>
                  <Tabhead $isineditmode={isInEditMode}>Weekday</Tabhead>
                  <Tabhead $isineditmode={isInEditMode}>Weekend</Tabhead>
                  <Tabhead>Actions</Tabhead>
                </TRow>
              </THead>
              <TableBody>
                {occupancies.map((row, idx) => (
                  <TRow key={idx}>
                    <TCell $isineditmode={isInEditMode}>
                      {editOccupancies[idx] ? (
                        <TextFieldSmall
                          type="number"
                          value={row.min}
                          onChange={(e) =>
                            onChange(
                              idx,
                              "min",
                              e.target.value && parseInt(e.target.value)
                            )
                          }
                        />
                      ) : !row.min && row.min !== 0 ? (
                        ""
                      ) : (
                        row.min
                      )}
                    </TCell>
                    <TCell $isineditmode={isInEditMode}>
                      {editOccupancies[idx] ? (
                        <TextFieldSmall
                          type="number"
                          value={row.max === -1 ? "" : row.max}
                          onChange={(e) =>
                            onChange(
                              idx,
                              "max",
                              e.target.value && parseInt(e.target.value)
                            )
                          }
                        />
                      ) : row.max === -1 ? (
                        "-"
                      ) : !row.max && row.max !== 0 ? (
                        ""
                      ) : (
                        row.max
                      )}
                    </TCell>
                    <TCell $isineditmode={isInEditMode}>
                      {editOccupancies[idx] ? (
                        <TextFieldSmall
                          type="number"
                          value={row.mwValue}
                          onChange={(e) =>
                            onChange(
                              idx,
                              "mwValue",
                              e.target.value && parseFloat(e.target.value)
                            )
                          }
                        />
                      ) : !row.mwValue && row.mwValue !== 0 ? (
                        ""
                      ) : (
                        row.mwValue
                      )}
                    </TCell>
                    <TCell $isineditmode={isInEditMode}>
                      {editOccupancies[idx] ? (
                        <TextFieldSmall
                          type="number"
                          value={row.weValue}
                          onChange={(e) =>
                            onChange(
                              idx,
                              "weValue",
                              e.target.value && parseFloat(e.target.value)
                            )
                          }
                        />
                      ) : !row.weValue && row.weValue !== 0 ? (
                        ""
                      ) : (
                        row.weValue
                      )}
                    </TCell>
                    {
                      <TCell
                        style={{
                          backgroundColor: "none",
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          height: "40px",
                        }}
                      >
                        <AddButton
                          onClick={() => {
                            OonAddRowHandler(idx);
                            if (occupancies.length === 1)
                              setOpenAddDialog(true);
                          }}
                        />

                        <RemoveButton
                          onClick={() => OonRemoveRowHandler(idx)}
                        />

                        <EditButton
                          onClick={() => handleEditOccupancyChange(idx)}
                        />
                      </TCell>
                    }
                  </TRow>
                ))}
              </TableBody>
            </Table>
          </TContainer>
        ) : (
          <TableLoading />
        )}{" "}
      </CardContainer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseStatus}
      >
        <SnackbarContent
          style={{ backgroundColor: "#CA3433" }}
          message={networkMsg}
        />
      </Snackbar>

      <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
        <DialogContent>
          Please edit the values for first row as well.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddDialog(false)}>Okay</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
