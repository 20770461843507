import {
  Dialog,
  DialogTitle,
  styled,
  DialogContent,
  Button,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

export default function ConfirmationDialog({
  open,
  handleCloseConfirmation,
  pushRatesToChannel,
  tableData,
}) {
  return (
    <StyledChannelDialog
      open={open}
      onClose={handleCloseConfirmation}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle className="content">
        Are you sure you want to push rates?
      </DialogTitle>
      <CloseIcon className="closeButton" onClick={handleCloseConfirmation} />
      <DialogContent className="content">
        <Stack direction="row" gap="24px">
          <Button variant="contained" onClick={handleCloseConfirmation}>
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              pushRatesToChannel({ data: tableData, inModal: true });
              handleCloseConfirmation();
            }}
          >
            Yes
          </Button>
        </Stack>
      </DialogContent>
    </StyledChannelDialog>
  );
}

const StyledChannelDialog = styled(Dialog)(() => ({
  ".closeButton": {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
  },
  ".content": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "visible",
  },
}));
