import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "../sdk";

const MAX_VALUE = 50;
const MIN_VALUE = -50;

export default function useDashboardAll() {
  const [data, setData] = useState({});
  const [HaveData, setHaveData] = useState(false);
  const [open, setOpen] = useState(false);
  const [Error, setError] = useState(false);
  const [networkMsg, setNetworkMsg] = useState();
  const [pickup, setPickup] = useState([]);
  const [hotelOptions, setHotelOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([
    new Date().getFullYear() - 1,
  ]);

  const { authFetch, currentHotel } = useAuth();
  const [ids, setSelectedIds] = useState([currentHotel?.id]);
  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear() - 1
  );
  const pickupOptions = React.useMemo(() => {
    let graphMaxLimit = MAX_VALUE;
    let graphMinLimit = MIN_VALUE;
    (data.pickup || []).forEach((item) => {
      graphMaxLimit = Math.max(
        item.yesterday,
        Math.max(item.threeDay, Math.max(item.sevenDay, graphMaxLimit))
      );
      graphMinLimit = Math.min(
        item.yesterday,
        Math.min(item.threeDay, Math.min(item.sevenDay, graphMaxLimit))
      );
    });
    return {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 8,
          right: 8,
          top: 8,
          bottom: 8,
        },
      },
      tooltips: {
        bodyFontFamily: "Roboto",
        backgroundColor: "white",
        bodyFontColor: "#777777",
        bodyFontSize: 14,
        titleFontColor: "#777777",
      },

      plugins: {
        datalabels: {
          display: (context) => {
            const display =
              context.dataset.data[context.dataIndex] === 0 ? false : true;
            return display;
          },
          align: (context) => {
            const align =
              context.dataset.data[context.dataIndex] >= 0 ? "end" : "start";
            return align;
          },
          anchor: (context) => {
            const anchor =
              context.dataset.data[context.dataIndex] >= 0 ? "end" : "start";
            return anchor;
          },
          font: { size: "10", fontFamily: "Roboto" },
        },
      },
      legend: {
        position: "bottom",
        align: "left",
        labels: {
          fontFamily: "Roboto",
          fontSize: 14,
          boxWidth: 10,
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              fontFamily: "Roboto",
              fontColor: "#777777",
              fontSize: 14,

              suggestedMin: 0,
              suggestedMax: 20,
            },
            gridLines: {
              display: true,
              drawTicks: true,
              drawOnChartArea: false,
              color: "grey",
              zeroLineColor: "black",
            },
            scaleLabel: {
              display: true,
              labelString: "Pick-Up",
              fontColor: "#777777",
              fontFamily: "Roboto",
              fontSize: 14,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
              drawTicks: true,
              drawOnChartArea: false,
              color: "grey",
              zeroLineColor: "grey",
            },
            ticks: {
              fontFamily: "Roboto",
              fontColor: "#777777",
              fontSize: 12,
            },
          },
        ],
      },
    };
  }, [data]);

  const PickupSetup = useCallback(() => {
    if (data.pickup && data.pickup.length > 0) {
      let pickup;
      pickup = {
        labels: data.pickup.map((data) => {
          return data.month;
        }),
        datasets: [
          {
            label: "24 Hours",
            data: data.pickup.map((data) => {
              return parseInt(data.yesterday);
            }),

            backgroundColor: "#5DA3FB",
            borderColor: "#5DA3FB",
            borderWidth: 4,
          },
          {
            label: "3 Days",
            data: data.pickup.map((data) => {
              return parseInt(data.threeDay);
            }),
            backgroundColor: "#637BF4",
            borderColor: "#637BF4",
            borderWidth: 4,
          },
          {
            label: "7 Days",
            data: data.pickup.map((data) => {
              return parseInt(data.sevenDay);
            }),
            backgroundColor: "#F6E753",
            borderColor: "#F6E753",
            borderWidth: 4,
          },
        ],
      };
      setPickup(pickup);
    }
  }, [data.pickup]);
  const getHotels = useCallback(async () => {
    try {
      const { get } = await authFetch({
        path: `/managing-org/${currentHotel?.managedBy}/chains/${currentHotel?.organizationId}/hotels/${currentHotel?.id}`,
      });
      const { data, response } = await get();

      if (response?.ok) {
        setHotelOptions(data);
      }
    } catch (err) {
      console.log(err);
    }
  }, [
    authFetch,
    currentHotel?.id,
    currentHotel?.managedBy,
    currentHotel?.organizationId,
  ]);
  useEffect(() => {
    getHotels();
  }, [getHotels]);
  const getYears = useCallback(async () => {
    try {
      const id = ids?.length > 0 ? ids.join() : currentHotel?.id ?? "";
      const { get } = await authFetch({
        path: `/cumulative-years-past-data?ids=${id}`,
      });
      const { data, response } = await get();

      if (response?.ok) {
        setYearOptions(
          data?.years?.length > 0 ? data?.years : [new Date().getFullYear() - 1]
        );
      }
    } catch (err) {
      console.log(err);
    }
  }, [authFetch, currentHotel, ids]);
  useEffect(() => {
    getYears();
  }, [getYears]);

  useEffect(() => {
    if (data) {
      PickupSetup();
    } else {
      return;
    }
  }, [PickupSetup, data]);
  const refreshDashboard = useCallback(async () => {
    const id = ids?.length > 0 ? ids.join() : currentHotel?.id ?? "";
    const { post } = await authFetch({
      path: `/cumulative-dashboard?ids=${id}`,
    });

    const { data } = await post({ year: parseInt(selectedYear) });
    if (data) {
      if (data?.messageToUser) {
        setOpen(true);
        setNetworkMsg(data.messageToUser);
      }
      if (data.message == "No Data available for Today and Yesterday") {
        setHaveData(false);
        setError(false);
        setData(data);
      } else {
        setHaveData(true);
        setError(false);
        setData(data);
      }
    } else {
      setHaveData(false);
      setError(false);
      setData({});
    }
  }, [authFetch, currentHotel, ids, selectedYear]);
  useEffect(() => {
    refreshDashboard();
  }, [refreshDashboard]);
  return {
    data,
    setData,
    selectedYear,
    setSelectedYear,
    open,
    setOpen,
    HaveData,
    setHaveData,
    Error,
    setError,
    networkMsg,
    setNetworkMsg,
    yearOptions,
    setYearOptions,
    pickup,
    pickupOptions,
    ids,
    setSelectedIds,
    hotelOptions,
  };
}
