import {
  TextField as TF,
  Box,
  Typography,
  TableHead,
  TableCell,
} from "@mui/material";
import { styled } from "@mui/system";

export const TextField = styled(TF)`
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export const Wrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 150px;
  width: 100%;
  margin: 0px;
  padding: 0px;
`;
export const MonthCard = styled(Box)`
  // align-content: center;  
  padding-top: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-top: 16px;
  padding: 10px 20px 20px 20px;
  height: calc(100vh - 160px);
`;
export const MonthCardWrapper = styled(Box)`
  display: grid;
  margin-right: 20px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;
export const VarianceCard = styled(Box)`
  padding-top: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-top: 16px;
  padding-bottom: 20px;
  padding: 16px 20px 20px 20px;
  height: calc(100vh - 160px);
`;
export const Th = styled(TableHead)`
  flex: 1;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #130453;
  width: 94px;
`;
export const Td = styled(TableCell)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
`;
export const MonthHeader = styled(Typography)`
  text-align: center;
  font: normal normal normal 16px/25px Roboto;
  letter-spacing: 0px;
  color: #000000;
  padding: 18px 0 0 0;
`;
export const Footer = styled(Typography)`
  text-align: center;
  font-weight: bold;
`;
export const DATE = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90px;
`;
export const DAY = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 64px;
  padding-left: 16px;
`;

export const TableCard = styled(Box)`
  display: flex;
  flex-wrap: no-wrap;
`;
export const PacePage = styled(Box)`
  padding: 80px 10px 20px 80px;
  position: absolute;
  width: 99%;
`;
export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 54px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  margin: auto;
`;
export const SubHeader = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;
export const Grid = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PageTitle = styled(Typography)`
  font: normal normal bold 24px/30px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  position: fixed;
  top: 13px;
  left: 80px;
  color: white;
  z-index: 999;
`;

export const HeaderCard = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  color: #281e53;
  padding-left: 20px;
`;
export const HeaderCardBottom = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: #281e53;
  padding-left: 10px;
`;

export const Label = styled(Typography)`
  font: normal normal normal 16px/20px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  padding-right: 10px;
  white-space: nowrap;
`;
export const ComparedWithLabel = styled(Typography)`
  font: normal normal bold 12px/15px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  padding-right: 10px;
  white-space: nowrap;
`;
