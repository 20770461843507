import { useEffect, useState } from "react";
import { useAuth } from "../../sdk";

const EMPTY_ROW = {
  min: 0,
  max: 100,
  mwValue: null,
  weValue: null,
};
export function useOccupancy(hotelId, copyFromHotelId, OC) {
  const [occupancies, setOccupancies] = useState([]);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [editOccupancies, setEditOccupancies] = useState([]);
  const [checkOccupancyValidation, setOccupancyValidation] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [open, setOpen] = useState(false);
  const [Loading, setLoading] = useState(false);

  const handleCloseStatus = () => {
    setOpen(false);
  };
  const [networkMsg, setnetworkMsg] = useState(null);
  const { token, authFetch } = useAuth();
  useEffect(() => {
    if (!token) return;
    refreshOccupancies();
  }, [token, hotelId]);

  useEffect(() => {
    if (isInEditMode) {
      const allValidEntries = occupancies.every(isValid);
      if (allValidEntries) setOccupancyValidation(false);
      else setOccupancyValidation(true);
      if (allValidEntries) {
        if (
          occupancies.length &&
          occupancies[occupancies.length - 1].max < 100 &&
          occupancies[occupancies.length - 1].max > 0
        ) {
          setOccupancies([
            ...occupancies,
            {
              min: occupancies.length
                ? occupancies[occupancies.length - 1].max + 1
                : 0,
              max: 100,
              mwValue: null,
              weValue: null,
            },
          ]);
          setEditOccupancies([
            ...editOccupancies,
            (editOccupancies[occupancies.length] = true),
          ]);
        } else if (occupancies.length === 0) {
          setOccupancies([
            ...occupancies,
            {
              min: 0,
              max: 100,
              mwValue: null,
              weValue: null,
            },
          ]);
        }
      }
    } else if (isSaved) {
      updateOccupancies();
    } else if (isCancelled) {
      refreshOccupancies();
    }
  }, [occupancies, isInEditMode]);

  useEffect(() => {
    if (copyFromHotelId === null && !OC) return;
    if (OC) refreshOccupancies(copyFromHotelId);
  }, [OC]);
  function isValid(occupancy) {
    return (
      occupancy.min !== null &&
      occupancy.min !== "" &&
      occupancy.max !== null &&
      occupancy.max !== "" &&
      occupancy.mwValue !== null &&
      occupancy.mwValue !== "" &&
      occupancy.weValue !== null &&
      occupancy.weValue !== "" &&
      (occupancy.max >= occupancy.min || occupancy.max === -1)
    );
  }
  async function refreshOccupancies(anotherHotelId = false) {
    const hotelIdToUse = anotherHotelId ? anotherHotelId : hotelId;
    setLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelIdToUse}/occupancy-score`,
    });
    const { data, error } = await get();
    if (error) {
      setnetworkMsg("Can't Fetch Occupancy");
      setOpen(true);
      console.log(error);
    }
    setIsCancelled(false);
    if (data) {
      setOccupancies(data.sort((a, b) => a.min - b.min));
      setEditOccupancies([...new Array(data?.length)].map(() => false));
      if (data.length === 0) {
        setOccupancies([EMPTY_ROW]);
        setEditOccupancies([false]);
        setIsInEditMode(false);
      }
    } else {
      setOccupancies([]);
    }
    if (anotherHotelId) {
      setIsInEditMode(true);
    }
    setLoading(false);
  }

  const updateOccupancies = async () => {
    // const validOccupancyRows = maxLimit();
    // const validOccupancies = validOccupancyRows
    //   .map((row, index) => {
    //     if (index === validOccupancyRows.length - 1) {
    //       const { max } = row;
    //       return !max && max !== 0
    //         ? {
    //             ...row,
    //             max: -1,
    //           }
    //         : row;
    //     }
    //     return row;
    //   })
    //   .filter(isValid);
    const validOccupancies = occupancies.filter(isValid);

    const { post } = await authFetch({
      path: `/hotel/${hotelId}/occupancy-score/all`,
    });
    const { error } = await post(validOccupancies);
    if (error) {
      setnetworkMsg("Can't Update Occupancy");
      setOpen(true);
      console.log(error);
    }
    setIsSaved(false);
    refreshOccupancies();
  };

  // function maxLimit() {
  //   let validOccupancyRows = occupancies;
  //   occupancies.map((row, idx) => {
  //     const { max } = row;
  //     if (!max && max !== 0) validOccupancyRows = occupancies.slice(0, idx + 1);
  //   });
  //   return validOccupancyRows;
  // }

  function changeHandler(index, key, value) {
    if (key === "min" || key === "max") {
      if (value < 0) {
        value = value * -1;
      }
    }
    setOccupancies((prevState) => {
      return prevState.map((row, idx) =>
        idx === index
          ? {
              ...row,
              [key]: value,
            }
          : row
      );
    });
  }

  const onRemoveRowHandler = (index) => {
    setOccupancies((prevState) => {
      return prevState.filter((_, idx) => idx !== index);
    });
    setEditOccupancies((prevState) => {
      return prevState.filter((_, idx) => idx !== index);
    });
    setIsInEditMode(true);
  };

  const onAddRowHandler = (index) => {
    setOccupancies([
      ...occupancies.filter((_, idx) => idx <= index),
      {
        min: "",
        max: "",
        mwValue: "",
        weValue: "",
      },
      ...occupancies.filter((_, idx) => idx > index),
    ]);
    setEditOccupancies([
      ...editOccupancies.slice(0, index + 1),
      true,
      ...editOccupancies.slice(index + 1),
    ]);
    setIsInEditMode(true);
  };

  const handleEditOccupancyChange = (index) => {
    const result = editOccupancies.map((data, pos) => {
      if (pos === index) {
        return (editOccupancies[index] = !editOccupancies[index]);
      } else {
        return editOccupancies[pos];
      }
    });
    const checkEditMode = result.every((d) => d === false);
    if (checkEditMode) setIsInEditMode(false);
    else setIsInEditMode(true);
    setEditOccupancies(result);
  };
  return {
    occupancies,
    oIsInEditMode: isInEditMode,
    setOIsInEditMode: setIsInEditMode,
    oIsCancelled: isCancelled,
    oSetIsCancelled: setIsCancelled,
    oIsSaved: isSaved,
    oSetIsSaved: setIsSaved,
    oChangeHandler: changeHandler,
    oopen: open,
    ohandleCloseStatus: handleCloseStatus,
    onetworkMsg: networkMsg,
    OonRemoveRowHandler: onRemoveRowHandler,
    OonAddRowHandler: onAddRowHandler,
    OLoading: Loading,
    editOccupancies,
    handleEditOccupancyChange: handleEditOccupancyChange,
    checkOccupancyValidation,
  };
}
