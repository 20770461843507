import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";

const Wrapper = styled(Box)`
  position: fixed;
  top: 50%;
  left: 50%;
`;
export const LoadingPage = () => {
  return (
    <Wrapper>
      <CircularProgress style={{ color: "#110641" }} />
    </Wrapper>
  );
};
