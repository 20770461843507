import React from "react";
import { Row } from "../../sdk";
import { Table, Td, Th } from "../Styles.js";

const Days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export default function LeadTimevsBoBTable({ selectedData }) {
  const competitors = selectedData.find((data) => data.compSet);
  let competitorDetails;
  if (competitors) {
    let { compSet } = competitors;
    competitorDetails = compSet;
  }

  return (
    <Table>
      <thead>
        <Row>
          <Th>Lead Time</Th>
          {Days.map((data, index) => (
            <Th key={index}>{data}</Th>
          ))}
        </Row>
      </thead>
      <tbody>
        {selectedData.map((data, idx) => (
          <Row
            key={idx}
            style={
              idx % 2 === 0 ? { backgroundColor: "rgba(48, 81, 221,0.09)" } : {}
            }
          >
            <Td style={{ textAlign: "left" }}>{data.LeadTime}</Td>
            <Td>{Math.round(data.mon)}</Td>
            <Td>{Math.round(data.tue)}</Td>
            <Td>{Math.round(data.wed)}</Td>
            <Td>{Math.round(data.thu)}</Td>
            <Td>{Math.round(data.fri)}</Td>
            <Td>{Math.round(data.sat)}</Td>
            <Td>{Math.round(data.sun)}</Td>
          </Row>
        ))}
      </tbody>
    </Table>
  );
}
