import React from "react";
import EmailSent from "./components/EmailSent";
import { Main } from "./components/Styles";
export default function EmailSentPage() {
  return (
    <Main>
      <div>
        <EmailSent />
      </div>
    </Main>
  );
}
