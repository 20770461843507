import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { getISODate } from "../../sdk";
import { useAuth } from "../../sdk/hooks/useAuth";
const transformData = (data, fn) => {
  return data.map((segment) => {
    return {
      ...segment,
      budgetModel: segment.budgetModel.map((model) => {
        return {
          segmentID: model.segmentID,
          date: model.date,
          roomsActual: fn(model.roomsActual),
          roomsLocked: fn(model.roomsLocked),
          roomsLY: fn(model.roomsLY),
          roomsOTB: fn(model.roomsOTB),
          roomsBudget: fn(model.roomsBudget),
          arrActual: fn(model.arrActual),
          arrLocked: fn(model.arrLocked),
          arrLY: fn(model.arrLY),
          arrOTB: fn(model.arrOTB),
          arrBudget: fn(model.arrBudget),
          revenueActual: fn(model.revenueActual),
          revenueLocked: fn(model.revenueLocked),
          revenueLY: fn(model.revenueLY),
          revenueOTB: fn(model.revenueOTB),
          revenueBudget: fn(model.revenueBudget),
        };
      }),
    };
  });
};
const addHorizontalTotal = (data) => {
  console.log("this is me", data);
  return data.map((segData) => {
    return {
      ...segData,
      budgetModel: [
        ...segData.budgetModel,
        {
          segmentID: 0,
          date: new Date(),
          roomsActual: segData.budgetModel.reduce((acc, curr) => {
            if (curr.roomsActual === -1) {
              return acc;
            }
            return (acc += curr.roomsActual);
          }, 0),
          roomsLocked: segData.budgetModel.reduce((acc, curr) => {
            if (curr.roomsLocked === -1) {
              return acc;
            }
            return (acc += curr.roomsLocked);
          }, 0),
          roomsLY: segData.budgetModel.reduce((acc, curr) => {
            if (curr.roomsLY === -1) {
              return acc;
            }
            return (acc += curr.roomsLY);
          }, 0),
          roomsOTB: segData.budgetModel.reduce((acc, curr) => {
            if (curr.roomsOTB === -1) {
              return acc;
            }
            return (acc += curr.roomsOTB);
          }, 0),
          roomsBudget: segData.budgetModel.reduce((acc, curr) => {
            if (curr.roomsBudget === -1) {
              return acc;
            }
            return (acc += curr.roomsBudget);
          }, 0),
          arrActual: segData.budgetModel.reduce((acc, curr) => {
            if (curr.arrActual === -1) {
              return acc;
            }
            return (acc += curr.arrActual);
          }, 0),
          arrLocked: segData.budgetModel.reduce((acc, curr) => {
            if (curr.arrLocked === -1) {
              return acc;
            }
            return (acc += curr.arrLocked);
          }, 0),
          arrLY:
            segData.budgetModel.reduce((acc, curr) => {
              if (curr.roomsLY === -1) {
                return acc;
              }
              return (acc += curr.roomsLY);
            }, 0) === 0
              ? 0
              : segData.budgetModel.reduce((acc, curr) => {
                  if (curr.revenueLY === -1) {
                    return acc;
                  }
                  return (acc += curr.revenueLY);
                }, 0) /
                segData.budgetModel.reduce((acc, curr) => {
                  if (curr.roomsLY === -1) {
                    return acc;
                  }
                  return (acc += curr.roomsLY);
                }, 0),
          arrOTB:
            segData.budgetModel.reduce((acc, curr) => {
              if (curr.roomsOTB === -1) {
                return acc;
              }
              return (acc += curr.roomsOTB);
            }, 0) === 0
              ? 0
              : segData.budgetModel.reduce((acc, curr) => {
                  if (curr.revenueOTB === -1) {
                    return acc;
                  }
                  return (acc += curr.revenueOTB);
                }, 0) /
                segData.budgetModel.reduce((acc, curr) => {
                  if (curr.roomsOTB === -1) {
                    return acc;
                  }
                  return (acc += curr.roomsOTB);
                }, 0),
          arrBudget:
            segData.budgetModel.reduce((acc, curr) => {
              if (curr.roomsBudget === -1) {
                return acc;
              }
              return (acc += curr.roomsBudget);
            }, 0) === 0
              ? 0
              : segData.budgetModel.reduce((acc, curr) => {
                  if (curr.revenueBudget === -1) {
                    return acc;
                  }
                  return (acc += curr.revenueBudget);
                }, 0) /
                segData.budgetModel.reduce((acc, curr) => {
                  if (curr.roomsBudget === -1) {
                    return acc;
                  }
                  return (acc += curr.roomsBudget);
                }, 0),
          revenueActual: segData.budgetModel.reduce((acc, curr) => {
            if (curr.revenueActual === -1) {
              return acc;
            }
            return (acc += curr.revenueActual);
          }, 0),
          revenueLocked: segData.budgetModel.reduce((acc, curr) => {
            if (curr.revenueLocked === -1) {
              return acc;
            }
            return (acc += curr.revenueLocked);
          }, 0),
          revenueLY: segData.budgetModel.reduce((acc, curr) => {
            if (curr.revenueLY === -1) {
              return acc;
            }
            return (acc += curr.revenueLY);
          }, 0),
          revenueOTB: segData.budgetModel.reduce((acc, curr) => {
            if (curr.revenueOTB === -1) {
              return acc;
            }
            return (acc += curr.revenueOTB);
          }, 0),
          revenueBudget: segData.budgetModel.reduce((acc, curr) => {
            if (curr.revenueBudget === -1) {
              return acc;
            }
            return (acc += curr.revenueBudget);
          }, 0),
        },
      ],
    };
  });
};
const addVerticalTotal = (data) => {
  const budgetModel = data[0].budgetModel.map((segData, index) => ({
    segmentID: 0,
    date: new Date(),
    roomsActual: data.reduce((acc, curr) => {
      if (curr.budgetModel[index].roomsActual === -1) {
        return acc;
      }
      return (acc += curr.budgetModel[index].roomsActual);
    }, 0),
    roomsLocked: data.reduce((acc, curr) => {
      if (curr.budgetModel[index].roomsLocked === -1) {
        return acc;
      }
      return (acc += curr.budgetModel[index].roomsLocked);
    }, 0),
    roomsLY: data.reduce((acc, curr) => {
      if (curr.budgetModel[index].roomsLY === -1) {
        return acc;
      }
      return (acc += curr.budgetModel[index].roomsLY);
    }, 0),
    roomsOTB: data.reduce((acc, curr) => {
      if (curr.budgetModel[index].roomsOTB === -1) {
        return acc;
      }
      return (acc += curr.budgetModel[index].roomsOTB);
    }, 0),
    roomsBudget: data.reduce((acc, curr) => {
      if (curr.budgetModel[index].roomsBudget === -1) {
        return acc;
      }
      return (acc += curr.budgetModel[index].roomsBudget);
    }, 0),
    arrActual: data.reduce((acc, curr) => {
      if (curr.budgetModel[index].arrActual === -1) {
        return acc;
      }
      return (acc += curr.budgetModel[index].arrActual);
    }, 0),
    arrLocked: data.reduce((acc, curr) => {
      if (curr.budgetModel[index].arrLocked === -1) {
        return acc;
      }
      return (acc += curr.budgetModel[index].arrLocked);
    }, 0),
    arrLY: data.reduce((acc, curr) => {
      if (curr.budgetModel[index].arrLY === -1) {
        return acc;
      }
      return (acc += curr.budgetModel[index].arrLY);
    }, 0),
    arrOTB: data.reduce((acc, curr) => {
      if (curr.budgetModel[index].arrOTB === -1) {
        return acc;
      }
      return (acc += curr.budgetModel[index].arrOTB);
    }, 0),
    arrBudget: data.reduce((acc, curr) => {
      if (curr.budgetModel[index].arrBudget === -1) {
        return acc;
      }
      return (acc += curr.budgetModel[index].arrBudget);
    }, 0),
    revenueActual: data.reduce((acc, curr) => {
      if (curr.budgetModel[index].revenueActual === -1) {
        return acc;
      }
      return (acc += curr.budgetModel[index].revenueActual);
    }, 0),
    revenueLocked: data.reduce((acc, curr) => {
      if (curr.budgetModel[index].revenueLocked === -1) {
        return acc;
      }
      return (acc += curr.budgetModel[index].revenueLocked);
    }, 0),
    revenueLY: data.reduce((acc, curr) => {
      if (curr.budgetModel[index].revenueLY === -1) {
        return acc;
      }
      return (acc += curr.budgetModel[index].revenueLY);
    }, 0),
    revenueOTB: data.reduce((acc, curr) => {
      if (curr.budgetModel[index].revenueOTB === -1) {
        return acc;
      }
      return (acc += curr.budgetModel[index].revenueOTB);
    }, 0),
    revenueBudget: data.reduce((acc, curr) => {
      if (curr.budgetModel[index].revenueBudget === -1) {
        return acc;
      }
      return (acc += curr.budgetModel[index].revenueBudget);
    }, 0),
  }));
  const total = {
    segmentID: 0,
    systemName: "Total",
    budgetModel,
  };
  data.push(total);
  return data;
};
export default function useForecastSheet() {
  const date = new Date();
  const [forecastSheetData, setForecastSheetData] = useState(null);
  const { token, authFetch } = useAuth();
  const { hotelId } = useParams();
  const [comparitiveDate, setComparitiveDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [showPickupRequireToAchieve, setShowPickupRequireToAchieve] = useState(
    null
  );
  const [selectedPickup, setSelectedPickup] = useState([0, 0, 0]);
  const [filterDialogBox, setFilterDialoagBox] = useState(false);

  const [networkMsg, setnetworkMsg] = useState();
  const [open, setOpen] = useState();
  const [Loading, setLoading] = useState(false);
  const [competitorData, setCompetitorsData] = useState(null);
  const [dates, setDates] = useState(null);
  const [filterArray, setFilterArray] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const [filterAll, setFilterAll] = useState(true);

  const handleFilterDialogBox = useCallback(() => {
    setFilterDialoagBox(!filterDialogBox);
  }, [filterDialogBox]);

  const handleFilter = useCallback(
    (value, tag) => {
      if (tag === "Last Year") {
        const val = filterArray.map((data, index) => {
          if (index === 0) {
            return value;
          } else {
            return data;
          }
        });
        setFilterArray(val);
        setFilterAll(false);
      } else if (tag === "Budget") {
        const val = filterArray.map((data, index) => {
          if (index === 1) {
            return value;
          } else {
            return data;
          }
        });
        setFilterArray(val);
        setFilterAll(false);
      } else if (tag === "Locked Forecast") {
        const val = filterArray.map((data, index) => {
          if (index === 2) {
            return value;
          } else {
            return data;
          }
        });
        setFilterArray(val);
        setFilterAll(false);
      } else if (tag === "BOB") {
        const val = filterArray.map((data, index) => {
          if (index === 3) {
            return value;
          } else {
            return data;
          }
        });
        setFilterArray(val);
        setFilterAll(false);
      } else if (tag === "Actual") {
        const val = filterArray.map((data, index) => {
          if (index === 4) {
            return value;
          } else {
            return data;
          }
        });
        setFilterArray(val);
        setFilterAll(false);
      } else if (tag === "All") {
        const val = filterArray.map((data, index) => false);
        setFilterArray(val);
        setFilterAll(true);
      }
    },
    [filterArray]
  );

  useEffect(() => {
    setFilterAll(filterArray?.every((value) => value === false));
  }, [filterArray]);

  const getForecastData = useCallback(async () => {
    setLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/budget-forecast-daily/${getISODate(
        new Date(comparitiveDate.getFullYear(), comparitiveDate.getMonth(), 1)
      )}`,
    });

    const { data } = await get();
    console.log({ data });
    if (data && data?.length) {
      const withVorizontalTotal = addHorizontalTotal(data);
      const withVerticalTotal = addVerticalTotal(withVorizontalTotal);
      setShowPickupRequireToAchieve(
        new Array(withVerticalTotal.length + 1).fill(false)
      );
      setForecastSheetData(transformData(withVerticalTotal, checkIfMinusOne));
      const defaultPickups = [1, 1, 1];
      setSelectedPickup(
        new Array(withVerticalTotal.length).fill(defaultPickups)
      );
    } else {
      setOpen(true);
      setnetworkMsg("No Forecast Data");
      setShowPickupRequireToAchieve(null);
      setForecastSheetData(null);
    }
    setLoading(false);
  }, [comparitiveDate, hotelId, authFetch]);

  const postForecastData = useCallback(async () => {
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/post-budget-forecast-daily/${getISODate(
        new Date(comparitiveDate.getFullYear(), comparitiveDate.getMonth(), 1)
      )}`,
    });
    const data1 = forecastSheetData;
    data1.pop();
    const container = data1.map((segData) => ({
      ...segData,
      budgetModel: segData.budgetModel.filter((d) => d.segmentID !== 0),
    }));
    const { data, response } = await post(
      transformData(container, convertToMinusOne)
    );

    if (response?.ok) {
      getForecastData();
    } else {
      setnetworkMsg(data?.messageToUser || "No Forecast Data");
      setOpen(true);
    }
  }, [getForecastData, forecastSheetData, hotelId, comparitiveDate, authFetch]);

  const getCompetitorsData = useCallback(async () => {
    try {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/competitors-monthly-report/${getISODate(
          new Date(comparitiveDate.getFullYear(), comparitiveDate.getMonth(), 1)
        )}`,
      });
      const { data } = await get();
      if (data && Object.keys(data).length) {
        setCompetitorsData(data);
      } else {
        setCompetitorsData(null);
      }
    } catch (e) {
      console.log(e);
    }
  }, [comparitiveDate, authFetch, hotelId]);

  function generateDate(date) {
    const DateArray = [];
    var firstDay = 1;
    var lastDay = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();
    for (firstDay = 1; firstDay <= lastDay; firstDay++) {
      DateArray.push(new Date(date.getFullYear(), date.getMonth(), firstDay));
    }
    setDates(DateArray);
  }

  function checkIfMinusOne(num) {
    if (parseInt(num) === -1) {
      return NaN;
    }
    return num;
  }

  function convertToMinusOne(num) {
    if (isNaN(num)) {
      return -1;
    }
    return num;
  }

  const handleActualChange = useDebouncedCallback(
    (index, segmentIndex, actual, value) => {
      setForecastSheetData(
        forecastSheetData.map((fSD, fSIndex) => {
          if (segmentIndex === fSIndex) {
            return {
              ...fSD,
              budgetModel: fSD.budgetModel.map((bM, bMIndex) => {
                if (bMIndex === index) {
                  return {
                    ...bM,
                    [actual]: value,
                  };
                }
                return bM;
              }),
            };
          }
          return fSD;
        })
      );
    },
    100,
    [forecastSheetData]
  );

  const handleRevenueChange = useDebouncedCallback(
    (index, segmentIndex, tag, value) => {
      const budgetModelLength =
        forecastSheetData[segmentIndex].budgetModel.length;
      const rev =
        tag === "roomsBudget"
          ? forecastSheetData[segmentIndex].budgetModel[index].arrBudget * value
          : forecastSheetData[segmentIndex].budgetModel[index].roomsBudget *
            value;

      const sumValue = forecastSheetData[segmentIndex].budgetModel.reduce(
        (prev, next, ind) => {
          if (index === ind) {
            return prev + value;
          } else {
            return isNaN(next[tag]) ||
              next[tag] <= 0 ||
              ind === budgetModelLength - 1
              ? prev
              : prev + next[tag];
          }
        },
        0
      );
      const sumRev = forecastSheetData[segmentIndex].budgetModel.reduce(
        (prev, next, ind) => {
          if (index === ind) {
            return isNaN(rev) || rev <= 0 || ind === budgetModelLength - 1
              ? prev
              : prev + rev;
          } else {
            return isNaN(next.revenueBudget) ||
              next.revenueBudget <= 0 ||
              ind === budgetModelLength - 1
              ? prev
              : prev + next.revenueBudget;
          }
        },
        0
      );

      setForecastSheetData([
        ...forecastSheetData.slice(0, segmentIndex),
        {
          ...forecastSheetData[segmentIndex],
          budgetModel: [
            ...forecastSheetData[segmentIndex].budgetModel.slice(0, index),
            {
              ...forecastSheetData[segmentIndex].budgetModel[index],
              revenueBudget: rev <= 0 ? 0 : rev,
              [tag]: value,
            },
            ...forecastSheetData[segmentIndex].budgetModel.slice(
              index + 1,
              budgetModelLength - 1
            ),
            {
              ...forecastSheetData[segmentIndex].budgetModel[
                budgetModelLength - 1
              ],
              revenueBudget: sumRev <= 0 ? 0 : sumRev,
              [tag]: sumValue <= 0 ? 0 : sumValue,
            },
          ],
        },
        ...forecastSheetData.slice(segmentIndex + 1),
      ]);
    },
    0,
    [forecastSheetData]
  );

  const handleShowPickupRequiredRow = useCallback(
    (index) => {
      setShowPickupRequireToAchieve(
        showPickupRequireToAchieve.map((item, idx) => {
          if (idx === index) {
            return !item;
          }
          return item;
        })
      );
    },
    [showPickupRequireToAchieve]
  );

  const setPickup = useCallback(
    (segmentIndex, currentIndex, value) => {
      setSelectedPickup(
        selectedPickup.map((pickup, segIndex) => {
          return pickup.map((p, pickupIndex) => {
            return value;

            return p;
          });

          return pickup;
        })
      );
    },
    [selectedPickup]
  );

  useEffect(() => {
    getForecastData();
    getCompetitorsData();
    generateDate(comparitiveDate);
  }, [comparitiveDate, token, hotelId, getCompetitorsData, getForecastData]);
  return {
    forecastSheetData,
    comparitiveDate,
    setComparitiveDate,
    handleActualChange,
    handleRevenueChange,
    showPickupRequireToAchieve,
    handleShowPickupRequiredRow,
    postForecastData,
    setSelectedPickup,
    selectedPickup,
    Loading,
    setPickup,
    competitorData,
    dates,
    setOpen,
    networkMsg,
    open,
    handleFilterDialogBox,
    filterDialogBox,
    handleFilter,
    filterArray,
    filterAll,
  };
}
