import {
  Tooltip,
  TableRow,
  TableCell,
  TableHead,
  Box,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { Table as T } from "../sdk";

export const Page = styled(Box)`
  width: 100%;
  height: 100%;
  padding: 56px 0px 22px 76px;
`;

export const Head = styled(Box)`
  position: fixed;
  top: 13px;
  left: 80px;
  color: white;
  z-index: 999;
  display: flex;
  align-items: center;
`;

export const H = styled(Typography)`
  font: normal normal bold 24px/30px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  padding-right: 8px;
`;

export const Body = styled(Box)`
  width: inherit;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98.5%;
  min-height: 6%;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  margin: 20px 0;
`;
export const SubHeader = styled(Box)`
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
`;

export const Label = styled(Typography)`
  font: normal normal normal 16px/20px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  padding-right: 22px;
`;

export const TableCard = styled(Box)`
  display: flex;
  flex-wrap: no-wrap;
  width: 98.5%;
  background: none;
`;

export const Th = styled(TableHead)`
  flex: 1;
  padding-bottom: 20px;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #130453;
  width: 100px;
`;
export const Td = styled(TableCell)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
`;

export const TRow = styled(TableRow)`
  &.MuiTableRow-root {
    padding: 10px 0;
    &:nth-of-type(odd) {
      background: #f1f2fe;
    }
  }
`;
export const TCell = styled(TableCell)`
  &.MuiTableCell-root {
    font: normal normal normal 16px/20px Roboto;
    color: #333333;
    border: none;
    height: 32px;
    padding: 0 15px;
    text-align: center;
    white-space: nowrap;
  }
`;
export const Tabhead = styled(TableCell)`
  &.MuiTableCell-root {
    vertical-align: bottom;
    font: normal normal bold 16px/20px Roboto;
    color: #130453;
    background-color: white;
    border: none;
    text-align: center;
    padding: 10px 0px;
  }
`;
export const THead = styled(TableHead)`
  &.MuiTableHead-root {
    tr.MuiTableRow-root {
      background: transparent;
      width: 100%;
    }
  }
`;

export const Graph = styled(Box)`
  width: 100%;
  height: 100%;
  background: white;
  padding: 20px;
`;

export const DATE = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
`;
export const DAY = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 70px;
  padding-left: 15px;
`;

export const Segment = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const TextOnlyTooltip = styled(Tooltip)(({ theme }) => ({
  ".tooltip": {
    font: "normal normal normal 12px/16px Roboto",
  },
}));
export const Table = styled(T)`
  background-color: white;
  width: 100%;
  margin: 0px;
`;
