import {
    Dialog,
    DialogTitle,
    styled,
    DialogContent,
    Button,
    Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

export default function ConfirmationDialog({
    open,
    handleCloseConfirmation,
    confirmationText,
    onSuccess,
}) {
    return (
        <StyledChannelDialog
            open={open}
            onClose={handleCloseConfirmation}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle className="content">
                {confirmationText}
            </DialogTitle>
            <CloseIcon className="closeButton" onClick={handleCloseConfirmation} />
            <DialogContent className="content">
                <Stack direction="row" gap="24px">
                    <Button variant="contained" onClick={handleCloseConfirmation}>
                        No
                    </Button>
                    <Button
                        variant="contained"
                        onClick={onSuccess}
                    >
                        Yes
                    </Button>
                </Stack>
            </DialogContent>
        </StyledChannelDialog>
    );
}

const StyledChannelDialog = styled(Dialog)(() => ({
    ".closeButton": {
        position: "absolute",
        right: "10px",
        top: "10px",
        cursor: "pointer",
    },
    ".content": {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: "center",
        overflow: "visible",
        textAlign: 'center'
    },
}));
