import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";

export const StaticTableRow = ({ row }) => {
  return (
    <TableRow {...row.getRowProps()}>
      {row.cells.map((cell, i) => {
        if (i === 0) {
          return (
            <TableCell {...cell.getCellProps()}>
              {/* <DragHandle isDragging /> */}
              <span>{cell.render("Cell")}</span>
            </TableCell>
          );
        }
        return (
          <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>
        );
      })}
      <TableCell>
        <IconButton>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
