import {
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  TextField,
  FormControl,
  Select,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { CloseRounded } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";
import React from "react";
import { PrimaryButton } from "../../sdk";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      minHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const Div = styled(Typography)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;
const Div1 = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 15px;
`;
const Mybtn = styled(PrimaryButton)`
  box-shadow: 0px 3px 6px #0000002f;
  border-radius: 4px;
  width: 88px;
  height: 28px;
  font: normal normal 600 12px/15px Roboto;
  color: #ffffff;
`;
const CancelBtn = styled(PrimaryButton)`
  background: #d9d9d9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000019;
  border-radius: 4px;
  border: none;
  width: 88px;
  height: 28px;
  margin-right: 25px;
  font: normal normal 600 12px/15px Roboto;
  color: #575757;
`;
const Head = styled(Typography)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 20px;
`;
const Cross = styled(CloseRounded)`
  width: 16px;
  height: 16px;
  :hover {
    cursor: pointer;
  }
`;

const StyledDiv = styled(Div)(({ theme }) => ({
  outline: 0,
  position: "absolute",
  width: 400,
  height: 250,
  backgroundColor: "#fff",
  boxShadow: 5,
  padding: 16,
}));

const DemandsLevels = [
  "DayOfWeek",
  "HotelDemandLevel",
  "CityDemandLevel",
  "LeadTime",
  "Occupancy",
  "Pace",
  "YOY",
];

export function ModelBody({
  setCopyFromHotelId,
  copyFromHotelId,
  copyHotelName,
  setCopyHotelName,
  handleDLFChange,
  selectDLF,
  updateDLF,
  setOpenSelectBar,
  allHotels,
  hotelId,
}) {
  const [modalStyle] = React.useState(getModalStyle);
  const defaultHotelProps = {
    options: [
      ...allHotels
        .filter((hotel) => hotel.id !== parseInt(hotelId))
        .map((hotelNew) => {
          return hotelNew.name + " (" + hotelNew.organizationName + ")";
        }),
    ],
    getOptionLabel: (option) => option,
  };
  const handleHotelChange = (value) => {
    if (value) {
      const hotelName = value.split("(")[0].trim();
      const selectedHotel = allHotels.find((hotel) => hotel.name === hotelName);
      if (selectedHotel) {
        setCopyFromHotelId(selectedHotel.id);
        setCopyHotelName(selectedHotel.name);
      } else {
        setCopyFromHotelId(null);
      }
    } else setCopyFromHotelId(null);
  };
  return (
    <StyledDiv style={modalStyle}>
      <Head>
        <Cross
          onClick={() => {
            setOpenSelectBar(false);
          }}
        >
          &#x274C;
        </Cross>
      </Head>
      <Autocomplete
        {...defaultHotelProps}
        value={copyHotelName}
        style={{ width: "65%", font: "Roboto", paddingBottom: "20px" }}
        freeSolo={true}
        onChange={(event, newValue) => {
          handleHotelChange(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder="Search Hotel" />
        )}
      />

      <FormControl variant="outlined">
        <InputLabel>Demand Level Factors</InputLabel>

        <Select
          multiple
          value={selectDLF}
          label="Demand Level Factors"
          onChange={(e) => handleDLFChange(e.target.value)}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Placeholder</em>;
            }

            return <em>{selected.join(", ")}</em>;
          }}
          MenuProps={{ getContentAnchorEl: null }}
          style={{ width: "200px" }}
        >
          {DemandsLevels.map((val, idx) => (
            <MenuItem key={idx} value={val}>
              <ListItemText primary={val} />
              <Checkbox checked={selectDLF.indexOf(val) > -1} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Div1>
        <CancelBtn
          onClick={() => {
            setOpenSelectBar(false);
          }}
        >
          Cancel
        </CancelBtn>
        <Mybtn
          next
          disabled={copyFromHotelId === null ? true : false}
          style={
            copyFromHotelId === null
              ? { color: "#ffffff", cursor: "not-allowed" }
              : { color: "#ffffff" }
          }
          onClick={updateDLF}
        >
          Confirm
        </Mybtn>
      </Div1>
    </StyledDiv>
  );
}
