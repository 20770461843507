import {
  Checkbox,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
} from "@mui/material";
import { Add, Edit, Remove } from "@mui/icons-material";
import React from "react";
import { styled } from "@mui/system";
import { SecondaryButton, TableLoading } from "../../sdk";
import {
  CancelButton,
  CardContainer,
  Head,
  HeaderCard,
  SaveButton,
  Tabhead,
  TCell,
  TContainer,
  TextFieldSmall,
  THead,
  TRow,
} from "./../Styles";
const RemoveButton = styled(Remove)`
  color: #306fbc;
  cursor: pointer;
  width: 16px;
  height: 16px;
`;
const AddButton = styled(Add)`
  color: #306fbc;
  cursor: pointer;
  width: 16px;
`;
const EditButton = styled(Edit)`
  height: 18px;
  width: 18px;
  color: #306fbc;
`;

export function LeadTimeMultiplier({
  leadTimes,
  isInEditMode,
  setIsInEditMode,
  isCancelled,
  setIsCancelled,
  isSaved,
  setIsSaved,
  onChange,
  handleMultiplier,
  multipliers,
  open,
  handleCloseStatus,
  networkMsg,
  ltonRemoveRowHandler,
  ltonAddRowHandler,
  ltLoading,
  editLeadTimeMultiplier,
  handleEditLeadTimeMultiplierChange,
  checkLeadTimeMultiplierValidation,
}) {
  return (
    <>
      <CardContainer>
        <HeaderCard>
          <Head>
            <div className="title">Lead Time</div>
            <Checkbox
              checked={multipliers?.isLeadTime}
              onChange={(e) => handleMultiplier("isLeadTime", e.target.checked)}
              style={{
                color: "rgb(48,111,188)",
                width: "30px",
                height: "30px",
              }}
            />
          </Head>
          {!isInEditMode ? (
            <SecondaryButton style={{ border: "none" }}></SecondaryButton>
          ) : (
            <div>
              <CancelButton
                onClick={() => {
                  setIsCancelled(!isCancelled);
                  setIsInEditMode(!isInEditMode);
                }}
                style={{ margin: "5px 10px" }}
              >
                Cancel
              </CancelButton>
              <SaveButton
                onClick={() => {
                  setIsSaved(!isSaved);
                  setIsInEditMode(!isInEditMode);
                }}
                style={{ margin: "5px 10px" }}
                disabled={checkLeadTimeMultiplierValidation}
              >
                Save
              </SaveButton>
            </div>
          )}
        </HeaderCard>
        {!ltLoading ? (
          <TContainer style={{ maxHeight: "50vh" }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{
                width: "100%",
                border: "1px solid rgba(0, 0, 0, 0.12)",

                borderRadius: "5px",
              }}
            >
              <THead>
                <TRow>
                  <Tabhead $isineditmode={isInEditMode}>Min</Tabhead>
                  <Tabhead $isineditmode={isInEditMode}>Max</Tabhead>
                  <Tabhead $isineditmode={isInEditMode}>Weekday</Tabhead>
                  <Tabhead $isineditmode={isInEditMode}>Weekend</Tabhead>
                  <Tabhead>Actions</Tabhead>
                </TRow>
              </THead>

              <TableBody>
                {leadTimes &&
                  leadTimes.map((row, idx) => (
                    <TRow key={idx}>
                      <TCell $isineditmode={isInEditMode}>
                        {editLeadTimeMultiplier[idx] ? (
                          <TextFieldSmall
                            type="number"
                            value={row.min}
                            onChange={(e) =>
                              onChange(idx, "min", parseFloat(e.target.value))
                            }
                          />
                        ) : !row.min && row.min !== 0 ? (
                          ""
                        ) : (
                          row.min
                        )}
                      </TCell>
                      <TCell $isineditmode={isInEditMode}>
                        {editLeadTimeMultiplier[idx] ? (
                          <TextFieldSmall
                            type="number"
                            value={row.max === -1 ? "" : row.max}
                            onChange={(e) =>
                              onChange(idx, "max", parseFloat(e.target.value))
                            }
                          />
                        ) : row.max === -1 ? (
                          "-"
                        ) : !row.max && row.max !== 0 ? (
                          ""
                        ) : (
                          row.max
                        )}
                      </TCell>
                      <TCell $isineditmode={isInEditMode}>
                        {editLeadTimeMultiplier[idx] ? (
                          <TextFieldSmall
                            type="number"
                            value={row.mwValue}
                            onChange={(e) =>
                              onChange(
                                idx,
                                "mwValue",
                                e.target.value && parseFloat(e.target.value)
                              )
                            }
                          />
                        ) : !row.mwValue && row.mwValue !== 0 ? (
                          ""
                        ) : (
                          row.mwValue
                        )}
                      </TCell>
                      <TCell $isineditmode={isInEditMode}>
                        {editLeadTimeMultiplier[idx] ? (
                          <TextFieldSmall
                            type="number"
                            value={row.weValue}
                            onChange={(e) =>
                              onChange(
                                idx,
                                "weValue",
                                e.target.value && parseFloat(e.target.value)
                              )
                            }
                          />
                        ) : !row.weValue && row.weValue !== 0 ? (
                          ""
                        ) : (
                          row.weValue
                        )}
                      </TCell>

                      <TCell
                        style={{
                          backgroundColor: "none",
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          height: "40px",
                        }}
                      >
                        <AddButton onClick={() => ltonAddRowHandler(idx)} />

                        <RemoveButton
                          onClick={() => ltonRemoveRowHandler(idx)}
                        />
                        <EditButton
                          onClick={() =>
                            handleEditLeadTimeMultiplierChange(idx)
                          }
                        />
                      </TCell>
                    </TRow>
                  ))}
              </TableBody>
            </Table>
          </TContainer>
        ) : (
          <TableLoading />
        )}
      </CardContainer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseStatus}
      >
        <SnackbarContent
          style={{ backgroundColor: "#CA3433" }}
          message={networkMsg}
        />
      </Snackbar>
    </>
  );
}
