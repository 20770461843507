import React, { memo } from "react";
import { styled } from "@mui/system";
import { getDateDDMM } from "../../sdk";
import { Box } from "@mui/material";
const DateText = styled(Box)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  color: #212121;
  padding: 8px;
`;

const Text = styled(Box)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 10px;
  /* identical to box height */

  color: #212121;
  padding-left: 8px;

  padding-top: 2px;
`;
const DataNew = styled(Box)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: #212121;
  padding-left: 8px;
`;
const monthsnew = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const DatePopOver = memo(({ date, roomNight, Arr }) => {
  return (
    <>
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: "-5px",
          }}
        >
          <DateText>
            {getDateDDMM(date).split("/")[0] +
              " " +
              monthsnew[new Date(date).getMonth()]}
          </DateText>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Text>Room Nights</Text>
          <DataNew style={{ color: "#E09D6E" }}>{roomNight}</DataNew>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "auto",
          }}
        >
          <Text>ADR</Text>
          <DataNew style={{ color: "#B11A1A" }}>{Math.round(Arr)}</DataNew>
        </Box>
      </Box>
    </>
  );
});
export default DatePopOver;
