export default class NetworkError extends Error {
  constructor(reason, options, response, apiMessage) {
    const message =
      apiMessage ||
      `${reason} at ${options.url}${options.path}${
        response ? ` with status ${response.status} ${response.statusText}` : ""
      }`;

    super(message);
    this.message = message;
    this.name = "NetworkError";
    this.response = response;

    Object.setPrototypeOf(this, NetworkError.prototype);
  }
}
