import {
  Breadcrumbs,
  Snackbar,
  SnackbarContent,
  TextField as TF,
  TableHead,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ButtonContainer,
  HotelSection,
  Link,
  Main,
  OrganizationSection,
  Page,
  RightSection,
} from "../Admin_Refactored/components/Styles";
import { LoadingPage, useAuth } from "../sdk";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  Search as SearchIcon,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { border, styled } from "@mui/system";
import PropTypes from "prop-types";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box style={{ display: "flex" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
const TextField = styled(TF)`
  input {
    font: normal normal normal 16px/20px Roboto;
    letter-spacing: 0px;
    color: #000000;
  }
`;
const ClassWrapper = styled(Box)(({ theme }) => ({
  ".dropdownStyle": {
    borderRadius: 4,
    font: "normal normal normal 16px/20px Roboto",
  },
  ".rowHover": {
    "&:hover": {
      backgroundColor: "#EEEEEE !important",
      cursor: "pointer",
    },
  },
}));

export const OrganizationContainer = styled(Box)`
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  cursor: pointer;
`;
export const Status = styled(Box)`
  background: #c6f3d2;
  border-radius: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  color: #42ae40;
  padding: 4px 20px 4px 20px;
  width: 78px;
`;
export const LN = styled(Box)`
  height: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #e13a3a;
`;
export const BOB = styled(Box)`
  height: 20px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #42ae40;
`;
export const RateComparsion = styled(Box)`
  height: 20px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #42ae40;
`;
const TableCellHeading = styled(TableCell)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #000000;
`;

const TCell = styled(TableCell)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #000000;
  text-align: -webkit-right;
`;
const Search = styled(Box)`
  justify-content: flex-end;
  width: 324px;
  height: 40px;

  background: #ffffff;
  box-shadow: 1px 2px 4px 1px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
  margin-right: 22px;

  margin-top: 0px;
`;
const SearchBox = styled(TF)`
  height: 20px;
  width: 272px;
  padding-left: 16px;
  padding-top: 8px;
  input {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    color: #777777;
  }
`;
export default function ChooseHotels() {
  const { selectHotel, hotels, token, authFetch, permission } = useAuth();
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const handleCloseStatus = () => {
    setOpen(false);
  };

  const [networkMsg, setnetworkMsg] = useState(null);
  const [Loading, setLoading] = useState();
  const [selectedHotelId, setSelectedHotelId] = useState();
  const [chains, setChains] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [differentOrganization, setDifferentOrganization] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [hotelListNew, setHotelListNew] = useState([]);

  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let listOfOrg = null,
    searchHotel = null,
    addHotel = null;
  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "listOfOrg") {
        listOfOrg = permission[key];
      }
      if (permission[key].name === "searchHotel") {
        searchHotel = permission[key];
      }
      if (permission[key].name === "addHotel") {
        addHotel = permission[key];
      }
    }
  }

  const defaultHotelProps = {
    options: [
      ...hotelList
        .sort((a, b) =>
          String(a.name).toLowerCase() > String(b.name).toLowerCase() ? 1 : -1
        )
        .map((hotel) => {
          return hotel.name;
        }),
    ],
    getOptionLabel: (option) => option,
  };

  useEffect(() => {
    if (token) {
      window.scrollTo(0, 0);
    } else {
      return;
    }
  }, [token]);

  useEffect(() => {
    if (!token) {
      return;
    }
    if (listOfOrg) {
      getAllHotels();
    } else {
      setHotelList(hotels);
      setHotelListNew(hotels);
    }
  }, [token, listOfOrg]);

  const getAllHotels = async () => {
    const { get } = await authFetch({
      path: `/hotel`,
    });
    const { data, error } = await get();
    if (data) {
      setHotelList(data);
      localStorage.setItem("hotels", JSON.stringify(data));
      setHotelListNew(data);
    } else {
      setHotelList([]);
    }
  };
  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    const filteredRows = hotelList.filter((row) => {
      return (
        row?.name.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row?.city.toLowerCase().includes(searchedVal.toLowerCase()) ||
        (row?.hotelReferenceId &&
          row.hotelReferenceId
            .toLowerCase()
            .includes(searchedVal.toLowerCase()))
      );
    });
    setPage(0);

    setHotelListNew(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };
  const handleHotelChange = (value) => {
    const hotelName = value.split("(")[0].trim();
    const selectedHotel = hotelList.find((hotel) => hotel.name === hotelName);

    if (selectedHotel) {
      selectHotel(selectedHotel, true);
      setSelectedHotelId(selectedHotel.id);
    } else {
      selectHotel(null);
      setSelectedHotelId(null);
    }
  };
  useEffect(() => {
    if (!token) {
      return;
    }
    if (token) {
      hotelList.forEach((_, index) => {
        if (
          hotelList[index + 1] &&
          hotelList[index].organizationId !==
            hotelList[index + 1].organizationId
        ) {
          setDifferentOrganization(true);
        }
      });
    }
  }, [token]);
  return (
    <ClassWrapper>
      <Page>
        <HotelSection>
          <Main style={{ height: "10%" }}>
            {
              <Breadcrumbs
                separator="›"
                aria-label="breadcrumb"
                style={{ marginLeft: "20px" }}
              >
                <Link
                  color="inherit"
                  href="/choose_hotel"
                  to="/choose_hotel"
                  onClick={() => {
                    history.push("/choose_hotel");
                    getAllHotels();
                  }}
                >
                  Home
                </Link>
              </Breadcrumbs>
            }
            <RightSection style={{ justifyContent: "flex-end" }}>
              <Search>
                <SearchBox
                  variant="standard"
                  placeholder="Search by Hotel ID, Name or Location"
                  InputProps={{ disableUnderline: true }}
                  value={searched}
                  onChange={(e) => requestSearch(e.target.value)}
                  onCancelSearch={() => cancelSearch()}
                />
                <SearchIcon
                  style={{
                    color: "#306FBC",
                    marginTop: "10px",
                    marginLeft: "0px",
                    position: "absolute",
                  }}
                />
              </Search>

              {addHotel && (
                <ButtonContainer>
                  <Link to="/hotel"> Add Hotel </Link>
                </ButtonContainer>
              )}
            </RightSection>
          </Main>
          {!Loading ? (
            <OrganizationSection>
              <Paper
                style={{
                  overflow: "auto",

                  maxHeight: "calc(100vh - 280px)",
                }}
              >
                <TableContainer style={{ overflowX: "visible" }} stickyHeader>
                  <Table
                    stickyHeader
                    className="table"
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow className="header">
                        <TableCellHeading
                          style={{
                            backgroundColor: "#306fbc",
                            color: "#ffffff",
                          }}
                          align="center"
                        >
                          Hotel ID
                        </TableCellHeading>
                        <TableCellHeading
                          style={{
                            backgroundColor: "#306fbc",
                            color: "#ffffff",
                          }}
                          align="center"
                        >
                          Hotel Name
                        </TableCellHeading>
                        <TableCellHeading
                          style={{
                            backgroundColor: "#306fbc",
                            color: "#ffffff",
                          }}
                          align="center"
                        >
                          City
                        </TableCellHeading>
                        <TableCellHeading
                          style={{
                            backgroundColor: "#306fbc",
                            color: "#ffffff",
                          }}
                          align="center"
                        >
                          BOB
                        </TableCellHeading>
                        <TableCellHeading
                          style={{
                            backgroundColor: "#306fbc",
                            color: "#ffffff",
                          }}
                          align="center"
                        >
                          Rate Comparsion
                        </TableCellHeading>
                        <TableCellHeading
                          style={{
                            backgroundColor: "#306fbc",
                            color: "#ffffff",
                          }}
                          align="center"
                        >
                          Last Night Actual
                        </TableCellHeading>
                        <TableCellHeading
                          style={{
                            backgroundColor: "#306fbc",
                            color: "#ffffff",
                          }}
                          align="center"
                        >
                          Status
                        </TableCellHeading>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? hotelListNew.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : hotelListNew
                      )
                        .sort((a, b) =>
                          String(a.name).toLowerCase() >
                          String(b.name).toLowerCase()
                            ? 1
                            : -1
                        )
                        .map((hotel, hIndex) => (
                          <TableRow
                            key={hIndex}
                            hover
                            className="rowHover"
                            onClick={() => {
                              selectHotel(hotel, true);
                            }}
                          >
                            <TCell
                              component="th"
                              scope="row"
                              style={
                                hotel?.hotelReferenceId
                                  ? { textAlign: "center" }
                                  : { textAlign: "center" }
                              }
                            >
                              {hotel?.hotelReferenceId || "-"}
                            </TCell>
                            <TCell
                              style={{ textAlign: "center", cursor: "pointer" }}
                            >
                              {hotel.name}
                              {differentOrganization
                                ? " (" + hotel.organizationName + ")"
                                : ""}
                            </TCell>

                            <TCell style={{ textAlign: "center" }}>
                              {hotel?.city || ""}
                            </TCell>
                            <TCell style={{ textAlign: "center" }}>
                              {hotel?.forecastFileStatus ? (
                                <BOB style={{ color: "#42AE40" }}>Uploaded</BOB>
                              ) : (
                                <BOB style={{ color: "#E13A3A" }}>Pending</BOB>
                              )}
                            </TCell>
                            <TCell style={{ textAlign: "center" }}>
                              {hotel?.competitorFileStatus ? (
                                <RateComparsion style={{ color: "#42AE40" }}>
                                  Uploaded
                                </RateComparsion>
                              ) : (
                                <RateComparsion style={{ color: "#E13A3A" }}>
                                  Pending
                                </RateComparsion>
                              )}
                            </TCell>
                            <TCell style={{ textAlign: "center" }}>
                              {hotel?.arrStatus ? (
                                <LN style={{ color: "#42AE40" }}>Uploaded</LN>
                              ) : (
                                <LN style={{ color: "#E13A3A" }}>Pending</LN>
                              )}
                            </TCell>

                            <TCell
                              style={{
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Status>Active</Status>
                            </TCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <TablePagination
                sx={{
                  background: "#fff",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={hotelListNew.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  style: { border: "1px solid #000", borderRadius: "4px" },
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </OrganizationSection>
          ) : (
            <LoadingPage />
          )}
        </HotelSection>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={open}
          autoHideDuration={3000}
          onClose={handleCloseStatus}
        >
          {networkMsg !== "Something went wrong" ? (
            <SnackbarContent
              style={{ backgroundColor: "#228b22" }}
              message={networkMsg}
            />
          ) : (
            <SnackbarContent
              style={{ backgroundColor: "#CA3433" }}
              message={networkMsg}
            />
          )}
        </Snackbar>
      </Page>
    </ClassWrapper>
  );
}
